import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getShopByStoresAction } from "../../state/actions/node-actions/home-actions";
import { ImageBackground } from "react-native";


export default function MeeStoresTypes(props) {
    const { navigation, dataReload, dataErrorReload } = props;
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();

    const [AllPartners, setAllPartners] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setAllPartners(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            partnersCall();
        } else {
            await delay(dataErrorReload);
            partnersCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);


    useEffect(() => {
        partnersCall()
    }, []);

    const partnersCall = () => {
        setIsLoading(true);
        dispatch(getShopByStoresAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : ""
        },
            { PageNo: 1, PageSize: 10, SortBy: "STORE_TYPE_NAME", SortOrder: "DESC" }
            , callBackGetData));
    }

    const handleClick = async (data) => {
        navigation('Partners', { Store_Type: data?.Store_Type_Name })
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const styles = StyleSheet.create({
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            // padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10,
            height: 100
        },

        item: {
            borderRadius: 30,
            borderWidth: 0.5,
            borderColor: esiColor.BorderColor,
            padding: 4,
            // maxHeight: 50,
            // maxWidth: 50,
            height: 50,
            width: 50,
            flexDirection: "column",
            alignSelf: 'center', marginTop: 10
        }
    });

    return (AllPartners.length > 0 &&

        <View>
            <FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={AllPartners.slice(0, 10)}
                renderItem={({ item, index, separators }) => (
                    <View key={index} style={{ maxWidth: 65, minWidth: 65 }}>
                        <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                            <ImageBackground style={{
                                // shadowColor: esiColor.brandShadowColor,
                                // shadowOffset: { width: 0, height: 2 },
                                // shadowOpacity: 0.2,
                                // shadowRadius: 5,
                                height: 40,
                                width: 40
                            }}>
                                <Image source={{ cache: "force-cache", uri: item?.Image_Path + `?w=40&h=25` }} style={{ height: 25, resizeMode: 'contain', maxWidth: 40, maxHeight: 40, flex: 1, borderRadius: 100 }}></Image>
                            </ImageBackground>
                        </TouchableOpacity>
                        <View style={{ marginTop: 5, marginLeft: 2 }}>
                            <Text style={{ fontSize: 14, color: esiColor.brandFontColor, textAlign: 'center' }}>{item?.Store_Type_Name.split(" ")[item?.Store_Type_Name.split(" ").length - 1]}</Text>
                        </View>
                    </View>
                )}
            />
        </View>


    )
}


// `?w=40&h=25`