import React, { useEffect, useState } from "react";
import { Platform, TextInput, View } from "react-native";
import { useSelector } from "react-redux";

export default function QuentityField(props) {

    const [count, setCount] = useState(props.count);
  const esiColor = useSelector(state => state.theme);

    useEffect(() => {
        setCount(props.count);
    }, [props.count])

    return (
        Platform.OS === 'web' ?
            <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                style={{ borderWidth: 1, width: 24, borderColor: '#27b6cc', textAlign: 'center',color:esiColor.Text }}
                onChangeText={(value) => {
                    setCount(value)
                }}
                onBlur={(e) => {
                    props.handleOnChangeCount(e.target.value, props.completeProduct, false)
                }}
                value={count}
                maxLength={2}
            />
            :
            <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                style={{ borderWidth: 1, width: 24, borderColor: '#27b6cc', textAlign: 'center',color:esiColor.Text }}
                onChangeText={(value) => {
                    setCount(value)
                }}
                onEndEditing={(e) => {
                    props.handleOnChangeCount(e.nativeEvent.text, props.completeProduct, false)
                }}
                value={String(count)}
                maxLength={2}
            />

    )
}