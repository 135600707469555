import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Searchbar, Text, Surface, Checkbox } from 'react-native-paper';
import { View, ScrollView, RefreshControl, TouchableOpacity, Platform, FlatList } from 'react-native';
import Header from "../header";
import EsiBrandCard from "../../components/custom/brand-card";
import isEmpty from "../../state/validations/is-empty";
import { getBrandsAction } from "../../state/actions/node-actions/home-actions";
import Pagination from "../../components/custom/pagination/pagination";
import { EvilIcons } from "@expo/vector-icons";


export default function AllBrands(props: any) {
  const { item, navigation, ...rest } = props;
  const dispatch = useDispatch();

  const data = props?.route?.params;
  const [brands, setBrands] = useState([]);
  const esiColor = useSelector(state => state.theme);
  const [filters, setFilters] = React.useState({
    "Records_Filter": "FILTER",
    "Brand_Status": "Active",
    "Search_Brand_Name": "",
    "Top_Brands": data?.top == "true" ? 1 : ""
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "BRAND_ID", SortOrder: "DESC" });

  const callBackGetData = async (status, data, pagination) => {
    goToTop();

    setIsLoading(false);
    if (status) {
      setBrands(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 30, SortBy: "BRAND_ID", SortOrder: "DESC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      brandsCall();
    }
  }, [pagination])
  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
      setTestFilters(filters);
      brandsCall();
    }
  }, [filters]);
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const brandsCall = () => {
    setIsLoading(true);
    dispatch(getBrandsAction(filters,
      pagination,
      callBackGetData
    ));
  };

  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  // let scroll;
  const goToTop = () => {
    try {
      scroll.scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }
  const [searchBarOpen, setSearchBarOpen] = React.useState(false);

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
      <Header {...props} />

      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 5 }} >
          <TouchableOpacity style={{ marginLeft: 10, marginTop: 10, flexDirection: "row" }}
            onPress={() => {
              setPageOld(true);
              setPagination({ PageNo: 1, PageSize: 30, SortBy: "BRAND_ID", SortOrder: "DESC" });
              setFilters({
                ...filters,
                Top_Brands: filters.Top_Brands == "" ? 1 : ""
              });
              if (data?.top || data?.top == false || data?.top == true) {
                navigation.setParams({
                  top: filters.Top_Brands == "" ? true : false,
                });
              }
            }}
          >
            <Checkbox.Android
              status={filters.Top_Brands == 1 ? "checked" : "unchecked"}
              color={esiColor.CBCcolor}
              uncheckedColor={esiColor.CBCUcolor}
            />

            <Text style={{
              marginTop: 10,
              fontWeight: 'bold',
              color: esiColor.DescColor
            }}>Top Brands</Text>
          </TouchableOpacity>
        </View>
        <View style={{ flex: 5 }}>

          {searchBarOpen || filters.Search_Brand_Name.length > 0 ?
            <Searchbar style={{ margin: 10, borderColor: esiColor.SBorderColor, borderWidth: 1, maxHeight: 35, backgroundColor: esiColor.BackgroundColor }}
              inputStyle={{ fontSize: 12, marginLeft: -20 }}
              value={filters.Search_Brand_Name}
              autoFocus
              onChangeText={(value) => {
                setPageOld(true);
                setPagination({ PageNo: 1, PageSize: 30, SortBy: "BRAND_ID", SortOrder: "DESC" });
                setFilters({
                  ...filters,
                  Search_Brand_Name: value
                });
              }}
              onBlur={() => {
                setSearchBarOpen(false);
              }}
              placeholderTextColor={esiColor.SPHTextColor}
              multiline={false}
              placeholder="Search"
              theme={{ colors: { text: esiColor.Text } }}
              icon={() => <EvilIcons
                name="search"
                size={20}
                color={"#27b6cc"}
              // style={{marginHorizontal:-15}}
              />}
            />
            :
            <EvilIcons
              name="search"
              size={30}
              color={"#27b6cc"}
              style={{ alignSelf: "flex-end", marginRight: 5, marginTop: 10 }}
              onPress={() => { setSearchBarOpen(true) }}
            />
          }
        </View>
      </View>

      <FlatList
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={brandsCall}
          />
        }
        style={{ flex: 1, marginTop: 5, marginBottom: 10 }}
        data={brands||[]}
        numColumns={1}
        contentContainerStyle={{ marginVertical: 2 }}
        renderItem={({ item, index }) => (
          <Fragment key={index}>
            <EsiBrandCard item={item} navigation={navigation} />
          </Fragment>
        )}
        ListFooterComponent={() =>
          <View >
            <Pagination pagination={pagination} setPagination={setPagination} />
          </View>
        }
        ListEmptyComponent={() => <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
          <Text style={{ color: esiColor.NoDataFound }}>No Brands found!</Text>
        </View>}
      />
    </Surface>
  );
}
