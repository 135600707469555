import React, { useEffect, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../components/custom/product-card";
import isEmpty from "../../state/validations/is-empty";
import { Dimensions } from "react-native";
import { getvisitedProducts } from "../../state/actions/node-actions/home-actions";


export default function RecentlyVisites({ navigation, dataReload, dataErrorReload }) {
    const dispatch = useDispatch();
    const { height, width } = Dimensions.get('window');
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const esiColor = useSelector(state => state.theme);

    const [sEmailId, setSEmailId] = useState("");
    const [visitors, setVisitors] = useState([]);

    const [loading, setLoading] = React.useState(false);
    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setVisitors(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            visiterProductCAll();
        } else {
            await delay(dataErrorReload);
            visiterProductCAll();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const visiterProductCAll = () => {
        setLoading(true);
        dispatch(getvisitedProducts({
            Records_Filter: "FILTER",
            User_Email_Id: EmailID,

        }, { PageNo: 1, PageSize: 20, SortBy: "USER_VISIT_ID", SortOrder: "DESC" }, callBackGetData));
    }

    useEffect(() => {
        if ((EmailID != sEmailId) && EmailID) {
            setSEmailId(EmailID);
            visiterProductCAll();
        }
    }, [EmailID]);

    const handleClick = async (item) => {
        navigation('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id })
    };

    const styles = StyleSheet.create({
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 5,
            // padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10,
            marginBottom: 10
        }
    });

    return ((!isEmpty(visitors) && isAuthenticated) &&
        <View>
            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', marginLeft: 2 }}>Recently Visits</Text>

            <View style={styles.content} >
                <FlatList
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={visitors.slice(0, 10)}
                    renderItem={({ item, index, separators }) => (
                        <ProductCard data={item} navigation={navigation.navigate} handleClick={handleClick} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />
                    )}
                />
            </View>
        </View>
    )

}
