import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput, Text, HelperText, Surface, IconButton, ActivityIndicator, } from 'react-native-paper';
import { StyleSheet, View, ScrollView, TouchableOpacity, Image, RefreshControl } from 'react-native';
import Header from '../header';
import { GetAllComments, AddTicketcommentAction, getTicketsByIdAction, getallTicketByIdAction } from '../../state/actions/ticketActions';
import isEmpty from '../../state/validations/is-empty';
import EsiURLButton from '../../components/custom/linkingfunction/linkingFunction';
import ESITextInput from '../../components/custom-fields/textInput';


function Comment(props: any) {
  const { navigation, valueUpdate, ticketid, ...rest } = props
  const dispatch = useDispatch();

  const [getid, setGetid] = useState("");
  const user = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);
  const [visible, setVisible] = useState(5);
  const isSubmited = useSelector(state => state.ticket?.CommentCreate?.isLoading || false);

  const [reload, setReload] = useState(false);
  const [Comment, setComment] = useState([]);
  const [id, setID] = useState([]);
  useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])


  const callBackGetData = (data) => {
    setReload(false);
    if (data) {
      setComment(data);
    } else {
      setComment([]);
    }
  };
  React.useEffect(() => {
    setGetid(props.route?.params?.ticketid);
    if (props.route?.params?.ticketid) {
      setReload(true);
      const formData = {
        Records_Filter: "FILTER",
        Ticket_Id: props.route?.params?.ticketid,
      };
      dispatch(getTicketsByIdAction(props.route?.params?.ticketid, callBackGetData));
    }
  }, [props.route?.params?.ticketid]);
  React.useEffect(() => {
    getComments()
  }, [user?.Email_Id, getid]);
  const getComments = () => {
    if (user?.Email_Id && getid) {
      let data = {
        Records_Filter: "FILTER",
        Ticket_Id: getid,
      }
      dispatch(GetAllComments(data, callBackGetData))
    }
  }

  //==============================================
  const callBackData = (data) => {
    setReload(false);
    if (data) {
      setID(data);
    }

  };
  React.useEffect(() => {
    getTicketDetails();
  }, [getid]);

  const getTicketDetails = () => {
    if (getid) {
      const formData = {
        Records_Filter: "FILTER",
        User_Ticket_ID: getid
      };
      dispatch(getallTicketByIdAction(formData, callBackData));
    }
  }



  const styles = StyleSheet.create({
    input: {
      backgroundColor: esiColor.BackgroundColor,
      // width: 350,
      // borderWidth: 1,
      // borderRadius: 10,
      marginHorizontal: "5%",
      padding: 5,
      marginBottom: 10
    },
    textbox: {
      marginBottom: 80
    },
    containerm: {
      paddingVertical: 12,
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginLeft: 1,
      borderRadius: 2,
      marginTop: 10,
      padding: 2,
      borderBottomColor: esiColor.SBorderColor,
      borderBottomWidth: 0.5
    },
    content: {
      marginLeft: 16,
      flex: 1,
    },
    contentHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 2
    },
    image: {
      width: 45,
      height: 45,
      borderRadius: 20,
    },

  });

  /*====================================
          Handler
  ====================================*/

  return (

    <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1 }}>
      <Header {...props} />
      <View style={{ flexDirection: 'row', margin: 3 }}>
        {navigation.canGoBack() &&
          <IconButton
            icon="arrow-left"
            size={27}
            color={esiColor.SIconColor}
            onPress={() => navigation.goBack()} />}
        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor, marginTop: 12 }}>
          Comments
        </Text>
      </View>

      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={reload}
            onRefresh={() => { getComments(), getTicketDetails(); }}
          />
        } >
        {!reload &&
          <Formik initialValues={{

            Comment: "",

          }}
            //field validations
            validationSchema={Yup.object().shape({
              Comment: Yup.string().required('Comment  is required.'),
            })}
            onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              setErrors(errors);
              if (values) {
                let formValues = JSON.parse(JSON.stringify(values));
                formValues.Created_By = user?.Email_Id;
                formValues.Ticket_Id = getid;
                dispatch(AddTicketcommentAction(formValues, getid, resetForm, setReload, callBackGetData));

              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View>
                <View style={{ flexDirection: 'column', justifyContent: 'space-between', }}>
                  <View>
                    {id?.length > 0 ?
                      <View >
                        <View style={{
                          paddingTop: 15, marginHorizontal: "5%", borderRadius: 20, padding: 2, margin: 'auto', marginTop: 10,
                          backgroundColor: esiColor.BackgroundColor,
                          borderColor: esiColor.SBorderColor, borderWidth: 1,
                          shadowOpacity: 2,
                          shadowRadius: 2,
                          // elevation: 10,
                          shadowOffset : {width: 1, height: 2},
                          minHeight: 80,
                          width: "90%",
                          shadowColor: esiColor.brandShadowColor,
                          flex: 1, justifyContent: 'space-between'
                        }}>

                          <View style={{ flex: 1, marginBottom: 5, padding: 10 }}>
                            <View style={{ flexDirection: "column", marginLeft: 5 }} >
                              <Text style={{ fontSize: 15, fontWeight: "bold", color: esiColor.Text }}>Ticket ID : <Text style={{ color: esiColor.itemColor }}>{id[0]?.Ticket_Id}</Text> </Text>
                              <Text style={{ fontSize: 15, fontWeight: "bold", marginTop: 10, color: esiColor.Text }}>Subject : <Text style={{ color: esiColor.itemColor }}>{id[0]?.Ticket_Subject}</Text> </Text>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 10 }} >
                              <Text style={{ fontSize: 15, fontWeight: "bold", color: esiColor.Text }}> Attachment :  </Text>
                              <EsiURLButton url={id[0]?.Ticket_Attachment} />
                            </View>
                            <View style={{ flexDirection: "column", marginTop: 10 }} >
                              <Text style={{ fontSize: 15, fontWeight: "bold", color: esiColor.Text }}> Description :  </Text>
                              {id[0]?.Ticket_Description &&

                                <Text style={{ color: esiColor.itemColor }}> {id[0]?.Ticket_Description} </Text>}
                            </View>
                          </View>
                        </View>
                      </View>
                      : <View style={{ marginTop: 20 }} >
                        <Text style={{ fontSize: 16, textAlign: 'center', color: esiColor.NoDataFound }}>
                          No records found.
                        </Text>
                      </View>}
                    <View style={{
                      width: "90%",
                      marginHorizontal: "5%"
                    }}>
                      {Comment.length > 0 && Comment?.slice(0, visible).map((data: any, index: any) => (
                        // : <ActivityIndicator animating={loading} color='#27b6cc' style={{ marginTop: 20 }} />
                        <View key={index} style={styles.containerm}>
                          <TouchableOpacity>
                            <Image style={styles.image} source={data?.Created_By === user?.Email_Id ? { uri: user.Profile_Pic_Path } : require("../../assets/images/img/logo/meePaisa_logo.png")} />
                          </TouchableOpacity>
                          <View style={styles.content}>
                            <View style={styles.contentHeader}>
                              <Text style={{ fontSize: 15, marginBottom: 50, marginLeft: 16, color: esiColor.Text }}>{data?.Comment}</Text>
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
                <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                  {(Comment?.length > visible) == true &&
                    <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: esiColor.GBFColor }} onPress={() => setVisible(visible + 5)}>Show More ▼</Text>
                  }
                </View>

                <View style={styles.textbox}>
                  <ESITextInput
                    multiline
                    numberOfLines={2}
                    placeholder="Add comment...."
                    onChangeText={handleChange('Comment')}
                    style={styles.input}
                    mode={"outlined"}
                    name="Comment"
                    value={values.Comment}
                    disabled={isSubmited || id[0]?.Ticket_Status === "Closed"}
                    error={Boolean(touched.Comment && errors.Comment)}
                    right={<TextInput.Icon disabled={isSubmited || id[0]?.Ticket_Status === "Closed"} name="send" color={esiColor.SIconColor} mode="contained" onPress={handleSubmit} />}

                  />
                  <HelperText type="error" style={{ marginLeft: 10 }} visible={Boolean(touched.Comment && errors.Comment)}>
                    <ErrorMessage name="Comment" />
                  </HelperText>
                </View>
              </View>
            )}
          </Formik>}
      </ScrollView>
    </Surface>
  );
};


export default Comment











