import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getBrandsAction } from "../../state/actions/node-actions/home-actions";


export default function MeeBrands({ navigation, dataReload, dataErrorReload }) {

    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const [brands, setBrands] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setBrands(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            brandsCall();
        } else {
            await delay(dataErrorReload);
            brandsCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);

    useEffect(() => {
        brandsCall();
    }, []);

    const brandsCall = () => {
        setIsLoading(true);
        dispatch(getBrandsAction({
            "Records_Filter": "FILTER"
        },
            { PageNo: 1, PageSize: 10, SortBy: "BRAND_ID", SortOrder: "DESC" },
            callBackGetData
        ));
    }

    const handleClick = async (data) => {
        navigation('BrandDetails', { Brand_Id: data.Brand_Id })
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const styles = StyleSheet.create({
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10
        },

        item: {
            borderRadius: 5,
            borderWidth: 0.2,
            borderColor: esiColor.brandShadowColor,
            marginHorizontal: 5,
            padding: 4,
            maxHeight: 35
        }

    });

    return (brands.length > 0 &&
        <View>
            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', marginLeft: 2 }}> Mee Brands</Text>

            <View style={styles.content}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 5 }}>
                    <TouchableOpacity onPress={() => navigation('Brands')} style={{ position: 'absolute', right: 10 }}>
                        <Text style={{ fontSize: 13, color: esiColor.LCHFontColor, fontWeight: 'bold' }}>View All</Text>
                    </TouchableOpacity>
                </View>
                <FlatList
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={brands.slice(0, 10)}
                    renderItem={({ item, index, separators }) => (
                        <View key={index} style={{ maxWidth: 90, minWidth: 70, paddingHorizontal: 5 }}>

                            <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                                <Image source={{ cache: "force-cache", uri: item?.Brand_Image + `?w=50&h=25` }} style={{ height: 25, resizeMode: 'contain', maxWidth: 50 }}></Image>
                            </TouchableOpacity>
                            <View style={{ marginTop: 5 }}>
                                <Text style={{ fontSize: 12, color: esiColor.itemColor, textAlign: 'center' }}>{item?.Brand_Name}</Text>
                            </View>
                        </View>
                    )}
                />
            </View>
        </View>
    )
}


