import React, { useState } from "react";
import { StyleSheet, TextInput, View, Keyboard, Button, Platform } from "react-native";
import { Feather, Entypo } from "@expo/vector-icons";
import { useSelector } from "react-redux";

const SearchBar = ({ clicked, searchPhrase, setSearchPhrase, setClicked }) => {
    const esiColor = useSelector(state => state.theme);
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = () => {
        setIsFocused(false);
    };
    return (
        <View style={styles.container} onBlur={() => {
            Keyboard.dismiss();
            setClicked(false);
        }}>
            <View
                style={styles.searchBar__unclicked
                }
            >
                {/* search Icon */}
                <Feather
                    name="search"
                    size={10}
                    color={esiColor.Text}
                    style={{ marginLeft: 1 }}
                />
                {/* Input field */}
                <TextInput selectionColor={esiColor.TextSelection}
                    style={[{
                        fontSize: 12,
                        marginLeft: 4,
                        width: "90%",
                        elevation: 0,
                        color: esiColor.Text,

                    }, isFocused && { borderColor: esiColor.esiMainColor }]}
                    placeholder="Search"
                    value={searchPhrase}
                    onChangeText={setSearchPhrase}
                    onFocus={() => {
                        setClicked(true);
                        setIsFocused(true);
                    }}
                    onBlur={handleBlur}
                    cursorColor={esiColor.TextSelection}
                    placeholderTextColor={esiColor.Text}

                />
                {/* cross Icon, depending on whether the search bar is clicked or not */}
                {searchPhrase.length > 0 && (
                    <Entypo name="cross" size={12} color="black" style={{ padding: 1 }} onPress={() => {
                        setSearchPhrase("");
                    }} />
                )}
            </View>
            {/* cancel button, depending on whether the search bar is clicked or not */}
            {/* {clicked && (
                <View>
                    <Button
                        title="Cancel"
                        onPress={() => {
                            Keyboard.dismiss();
                            setClicked(false);
                        }}
                    ></Button>
                </View>
            )} */}
        </View>
    );
};
export default SearchBar;

// styles
const styles = StyleSheet.create({
    container: {
        // margin: 15,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        width: "90%",

    },
    searchBar__unclicked: {
        padding: 10,
        flexDirection: "row",
        width: "95%",
        // backgroundColor: "#d9dbda",
        // borderRadius: 15,
        alignItems: "center",
    },
    searchBar__clicked: {
        padding: 10,
        flexDirection: "row",
        width: "80%",
        backgroundColor: "#d9dbda",
        borderRadius: 15,
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    input: {
        fontSize: 12,
        marginLeft: 4,
        width: "90%",
        elevation: 0
    }
});