import React, { useState } from 'react';
import { View } from 'react-native';
import { TextField } from '@mui/material';


const EsiDateTimePicker = (props: any) => {
    // props variable consist all react text field properties along with valueUpdate function.
    // valueUpdate function will return onChange value of date field.

    // valueUpdate function is a custom property as it dont have in react native textfield properties.
    const { valueUpdate, ...rest } = props

    return (
        <View style={{ marginTop: 10 }} >
            <TextField
                style={[rest.style]}
                {...rest}
                fullWidth
                error={props.error}
                variant='outlined'
                label={props.label}
                type="datetime-local"
                onChange={(e) => props.valueUpdate(e.target.value)}
                value={props.value}
                InputLabelProps={{
                    shrink: true,
                }}

            />
        </View>

    );
};

export default EsiDateTimePicker;