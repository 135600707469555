import React, { useEffect, useState } from "react";
import { View, StyleSheet, Platform, ScrollView, Dimensions, TouchableOpacity, ActivityIndicator, RefreshControl } from 'react-native';
import { TextInput, Text, Button, Surface, TouchableRipple, Avatar, Colors } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux';
import Modal from "react-native-modal";
import SignIN from "../auth/signin";
import { CreateAppReviewsandRatings, getAllAppReviewsandRatingsAction, UpdateAppReviewsandRatings } from '../../state/actions/reviewsandratingsAction';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import EsiTestimonial from "../../components/custom/testimonialcard";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import EsiRating from "../../components/custom/Rating/rating";
import ESITextInput from "../../components/custom-fields/textInput";

export default function ReviewsAndRatings(props: any) {
  const { navigation } = props

  const reviewandratings = useSelector(state => state.reviewandrating.reviewandratings.all);
  const esiColor = useSelector(state => state.theme);


  useEffect(() => {
    refreshControlCall()
  }, []);

  const refreshControlCall = () => {
    let formData = {
      "Records_Filter": "FILTER",
      "Status": "Active",
    };
    dispatch(getAllAppReviewsandRatingsAction(formData))
  }

  // Tost message code.
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }

  //isSubmitted contains the data from Create API using state call
  const isSubmited = useSelector(state => state.reviewandrating.reviewandratingCreate.isLoading);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth?.user?.Email_Id);
  //toast for popup after successful API calls.
  const toast = useToast();
  let existcomment;
  if (reviewandratings) {
    existcomment = reviewandratings.filter(reviewandratings => reviewandratings.User_Email_Id === user).length
  }
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

  const dispatch = useDispatch();
  //displaying Signin pop up
  const signInModalResponse = (status: Boolean) => {
    setModalVisible(!isModalVisible);
    if (status) {
      setReviewModalVisible(!isReviewModalVisible);
    }
  };
  //For add review
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);

  //For edit review
  const [setEditModalVisible] = React.useState(false);
  const [isEditReviewModalVisible, setEditReviewModalVisible] = React.useState(false);

  const [rating, setRating] = useState(0);
  //comment param is used here to take the value from the Comment text box
  const [comment, setComment] = useState("");
  const [title, setTitle] = useState("");
  const [testimonialId, setData] = useState();
  //setCommentError param is used here to display validation error message
  const [commenterror, setCommentError] = useState("");

  const [error, setError] = useState("")
  //setRatingError param is used here to display validation error message
  const [ratingerror, setRatingError] = useState("");
  const [titleerror, setTitleError] = useState("");



  //For Add review
  const toggleModal = () => {
    if (isAuthenticated) {
      setReviewModalVisible(!isReviewModalVisible);
    }
    else {
      setModalVisible(true);
    }

  };

  const [loading, setLoading] = useState(false);

  const addReviewModal = () => {

    if (comment !== "" && title !== "" && Number(rating) > 0) {
      setCommentError("");
      setRatingError("");
      setTitleError("");

      let formData = {
        Created_By: user,
        Description: comment,
        Rating: rating,
        Status: "Active",
        Title: title,
        User_Email_Id: user,
      }
      setLoading(true);
      let formValues = JSON.parse(JSON.stringify(formData));
      dispatch(CreateAppReviewsandRatings(formValues, navigation, returnResponse));
    } else {
      if (comment === "") {
        setCommentError("Review is required.");
      } if (title === "") {
        setTitleError("Title is required.");
      }
      if (rating <= 0) {
        setRatingError("Please select the Rating.");
      }
    }

  }

  const returnResponse = (status: any, message: any) => {
    setLoading(false);
    if (status) {
      toast.show({ message: 'Review & Rating added successfully.', type: 'info', duration: 3000, position: 'top' });
      setReviewModalVisible(!isReviewModalVisible);
      setComment("");
      setRating(0);
      setTitle("");
    } else {
      dropDownAlertFunM('info', 'Failure', message);
    }
  }

  const editToggleModal = () => {
    if (isAuthenticated) {
      setEditReviewModalVisible(!isEditReviewModalVisible);
    }
    else {
      setEditModalVisible(true);
    }

  };
  // Comment validations
  const handleOnChange = (value) => {
    if (existcomment > 0) {
      setError("You have already added review.")
    }
    if (value === "") {
      setError("")
    }
  }
  const editReviewModal = () => {
    if (comment !== "" && title !== "" && Number(rating) > 0) {
      setCommentError("");
      setRatingError("");
      setTitleError("");
      setLoading(true);
      let editData = {
        Modified_By: user,
        Description: comment,
        Rating: rating,
        Status: "Active",
        Title: title,
        User_Email_Id: user,
        Testimonial_Id: testimonialId,
      }
      dispatch(UpdateAppReviewsandRatings(editData, updateResponse));
    } else {
      if (comment === "") {
        setCommentError("Review is required.");
      } if (title === "") {
        setTitleError("Title is required.");
      }
      if (rating <= 0) {
        setRatingError("Please select the Rating.");
      }
    }
  }

  const updateResponse = (status: any, message: any) => {
    setLoading(false);
    if (status) {
      toast.show({ message: 'Review & Rating updated successfully.', type: 'info', duration: 3000, position: 'top' });
      setEditReviewModalVisible(!isEditReviewModalVisible);
      setComment("");
      setRating(0);
      setTitle("");
    } else {
      dropDownAlertFunM('info', 'Failure', message);
    }
  }

  // Edit Onchanges
  const editOnChange = (value) => {
    if (value === "" && value === "") {
      setTitleError("Title is required.")
      setCommentError("Review is required.")

    } else {
      setTitleError("")
      setCommentError("")

    }
  }

  // Tost message code.
  const dropDownAlertFunM = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }

  return (
    <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1 }}>
      <Header {...props} />
      {/* <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => navigation.navigate('Home')}>
        <Ionicons name="arrow-back" size={30} color="#27b6cc" margin='auto' />
      </TouchableOpacity> */}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={refreshControlCall}
          />
        }
      >
        {!(existcomment > 0) &&
          <View style={{ flexDirection: 'row', alignSelf: 'flex-end', margin: 15 }}>

            <Button color={'#27b6cc'} onPress={() => toggleModal()} disabled={existcomment > 0 ? true : false} mode="contained" style={{ marginLeft: 20 }}>
              <Text style={{ color: 'white' }}>Add Review</Text>
            </Button>
          </View>
        }
        {!isEmpty(reviewandratings) ? (
          reviewandratings.Is_Data_Exist === "0" ? (
            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
              <Text>No Reviews and Ratings found!</Text>
            </View>
          ) : (
            reviewandratings.map((data: any, index: any) => (
              <View key={index} style={{ paddingBottom: 15 }}>
                <EsiTestimonial data={data} user={user} editFunction={() => { editToggleModal(); setTitle(data.Title); setRating(parseInt(data.Rating)); setComment(data.Description); setData(data.Testmonial_Id); }} navigation={navigation} />
              </View>
            ))
          )
        )
          : (<ActivityIndicator color='#27b6cc' />
          )}
        <Modal animationIn={"slideInDown"}
          deviceWidth={dimensions.width}
          deviceHeight={dimensions.height}
          style={{ alignItems: 'center' }} isVisible={isModalVisible}>
          <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
            <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
          </View>
        </Modal>
        <Modal animationIn={"slideInDown"}
          deviceWidth={dimensions.width}
          deviceHeight={dimensions.height}
          style={{ alignItems: 'center' }} isVisible={isReviewModalVisible || isEditReviewModalVisible}>
          {/* For Add Review */}
          {isReviewModalVisible &&

            <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 400, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
              <ScrollView>
                <View style={{
                  alignItems: 'flex-end', marginTop: '-6%'
                }}>
                  <TouchableRipple style={{ width: 36 }} onPress={() => setReviewModalVisible(false)}>
                    <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                  </TouchableRipple>
                </View>
                <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>Add Review & Rating</Text>
                <View style={{ margin: '4%' }}>
                  <ESITextInput
                    label='Title'
                    onChangeText={(Text) => { setTitle(Text); handleOnChange(Text); setTitleError("") }}
                    mode='outlined'
                    value={title}
                  />
                  <Text style={{ color: 'red' }}>{titleerror}</Text>

                  <ESITextInput
                    label='Review'
                    mode='outlined'
                    onChangeText={(Text) => { setComment(Text); handleOnChange(Text); setCommentError("") }}
                    multiline={true}
                    numberOfLines={4}
                    value={comment}
                    style={{ maxHeight: 100, marginHorizontal: "auto" }}
                  />
                  <Text style={{ color: 'red' }}>{commenterror}</Text>

                  <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}>
                    <EsiRating
                      startingValue={rating}
                      ratingCount={5}
                      imageSize={30}
                      type='heart'
                      tintColor={esiColor.TintColor}
                      style={{ paddingVertical: 10 }}
                      onFinishRating={(value) => { setRating(value); setRatingError("") }}
                    />
                    {rating <= 0 && ratingerror ? (
                      <Text style={{ color: "red" }}>
                        Please select the Rating.
                      </Text>
                    ) : (null)
                    }
                    <View style={{ width: 100 }}>
                      <Button disabled={loading} style={{ alignItems: 'center', marginTop: 10, backgroundColor: '#27B6CC' }} mode="contained" onPress={() => addReviewModal()}>
                        Submit
                      </Button>
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>}

          {/* For Edit Review */}
          {isEditReviewModalVisible &&
            <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 400, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
              <ScrollView>
                <View style={{
                  alignItems: 'flex-end', marginTop: '-6%'
                }}>
                  <TouchableRipple style={{ width: 36 }} onPress={() => { setEditReviewModalVisible(false), setComment(''), setRating(0) }} >
                    <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                  </TouchableRipple>
                </View>
                <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>Edit Review & Rating</Text>
                <View style={{ margin: '4%' }}>
                  <ESITextInput
                    label='Title'
                    onChangeText={(Text) => { setTitle(Text); handleOnChange(Text); setTitleError("") }}
                    mode='outlined'
                    value={title}
                  />
                  <Text style={{ color: 'red' }}>{titleerror}</Text>
                  <ESITextInput
                    label='Review'
                    mode='outlined'
                    onChangeText={(Text) => { setComment(Text); editOnChange(Text); setCommentError("") }}
                    multiline={true}
                    numberOfLines={4}
                    value={comment}
                    style={{ maxHeight: 100, marginHorizontal: "auto" }}
                  />
                  <Text style={{ color: 'red' }}>{commenterror}</Text>
                  <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}>
                    <EsiRating
                      startingValue={rating}
                      ratingCount={5}
                      imageSize={30}
                      type='heart'
                      tintColor={esiColor.TintColor}
                      style={{ paddingVertical: 10 }}
                      onFinishRating={(value) => { setRating(value); setRatingError("") }}
                    />
                    {rating <= 0 && ratingerror ? (
                      <Text style={{ color: "red", fontSize: 16 }}>
                        Please select the Rating.
                      </Text>
                    ) : (null)
                    }
                    <View style={{ width: 100 }}>
                      <Button disabled={loading} style={{ alignItems: 'center', marginTop: 10, backgroundColor: '#27B6CC' }} mode="contained" onPress={() => editReviewModal()}>
                        Submit
                      </Button>
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>
          }
        </Modal>
      </ScrollView>
    </Surface>
  );
};


const styles = StyleSheet.create({
  rating: {
    marginRight: 50,
    flexDirection: 'row'
  },
  container: {
    flex: 2,
    marginTop: 20,
    alignItems: 'center',

  },
  img: {
    alignItems: 'flex-end',
    marginTop: (Platform.OS === 'web') ? -60 : -50,
  },
  welcome: {
    alignItems: 'flex-end',
    paddingTop: 5,
  },


})