import { ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { HelperText, TextInput } from 'react-native-paper';
import ESITextInput from '../custom-fields/textInput';

const PasswordInput = (props) => {
    const { visible, name, ...rest } = props;
    const copyPasteCutChange = (e) => {
        e.preventDefault();
    };
    const [hidePass, setHidePass] = useState(true);
    return (
        <>
            <ESITextInput
                {...rest}
                name={name}
                secureTextEntry={hidePass ? true : false}
                right={<TextInput.Icon name={hidePass ? 'eye-off' : 'eye'}
                    size={20}
                    onPress={() => setHidePass(!hidePass)}
                />}
                onCut={copyPasteCutChange}
                onCopy={copyPasteCutChange}
                onPaste={copyPasteCutChange}
            />
            <HelperText type="error" visible={visible}>
                <ErrorMessage name={name} />
            </HelperText>
        </>
    )
}

export default PasswordInput;