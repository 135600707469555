import 'react-native-gesture-handler';
import * as React from 'react';
import { View, Platform, TouchableOpacity, ActivityIndicator, ScrollView, Dimensions } from "react-native";
import { Avatar, Checkbox, Colors, HelperText, RadioButton, Searchbar, Surface, Text, TextInput, TouchableRipple } from "react-native-paper";
import * as Location from "expo-location";
import isEmpty from '../../../state/validations/is-empty';
import DraggableMap from '../map/DraggableMap/DraggableMap';
import { getCityLocationsByFilters } from '../../../state/actions/shopbycityActions';
import { useDispatch, useSelector } from 'react-redux';



function PartnerLocation(props: any) {
  //props variable consists of all <List.Accordion> properties along with map data.
  const { isLocationVisible, setIsLocationVisible, filters, setFilters } = props;
  const [locationLoad, setLocationLoad] = React.useState(false);
  const [locationStock, setLocationStock] = React.useState(false);
  const [deviceLocation, setLocation] = React.useState({});
  const esiColor = useSelector(state => state.theme);

  React.useEffect(() => {
    (async () => {
      if (isEmpty(deviceLocation) && !locationStock) {
        setLocationLoad(true);
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          setLocation({ latitude: 12.956161044024359, longitude: 77.69511468708515 });
          setLocationStock(true);
          setLocationLoad(false);
          return;
        }
        const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
        setLocation({ latitude, longitude });
      }
    })();
  })
  const [locationType, setLocationType] = React.useState('geolocation');
  const [manuvalZip, setManuvalZip] = React.useState("");
  const [oldManuvalZip, setOldManuvalZip] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [locationData, setLocationData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const getResponse = (status: any, response: any, errorMessage: any) => {
    setLoading(false);
    if (status) {
      let cityes = [];
      for (let i = 0; i < response.length; i++) {
        let isNotExist = true;
        for (let j = 0; j < cityes.length; j++) {
          if (cityes[j].City_ID == response[i].City_ID) {
            isNotExist = false;
            break;
          }
        }
        if (isNotExist) {
          cityes.push({ City_ID: response[i].City_ID, City_Name: "All in " + response[i].City_Name, isCity: true })
        }
      }
      if (cityes.length > 1) {
        setLocationData(response);
      } else {
        setLocationData(cityes.concat(response));
      }
    } else {
      setLocationData([]);
      setErrorMessage(errorMessage);
    }
  }
  const dispatch = useDispatch();
  const getCityLocations = (filters: any) => {
    setLoading(true);
    setErrorMessage("");
    dispatch(getCityLocationsByFilters(filters, getResponse));
  }

  const [searchValue, setSearchValue] = React.useState("");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));


  React.useEffect(() => {
    if (manuvalZip) {
      if (manuvalZip != oldManuvalZip) {
        setOldManuvalZip(manuvalZip);
        getCityLocations({
          "Pincode": manuvalZip,
          "Records_Filter": "FILTER"
        });
      }
    }
  }, [manuvalZip])

  return (
    <Surface style={{
      // alignItems: 'flex-end',
      justifyContent: 'center',
      borderRadius: 10,
      top: Platform.OS === 'web' ? 10 : 0,
      elevation: 0,

    }} >
      <ScrollView style={{ height: 600 }} >
        <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
          setIsLocationVisible(!isLocationVisible);
        }}>
          <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
        </TouchableRipple>
        <View>
          <View style={{ marginLeft: 10 }}>
            <View style={{ marginBottom: 15 }} >
              <RadioButton.Group onValueChange={newValue => {
                setLocationType(newValue);
                if (newValue == "geolocation") {
                  if (oldManuvalZip) {
                    getCityLocations({
                      "Pincode": oldManuvalZip,
                      "Records_Filter": "FILTER"
                    });
                  }
                } else if (newValue == "zipcode") {
                  if (searchValue)
                    getCityLocations({
                      "Search": searchValue
                    });
                }

              }} value={locationType}>
                <Text style={{ marginHorizontal: 5, fontWeight: "bold" }} >Location Type </Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 2, flexDirection: "row", justifyContent: "center" }} >
                    <Text style={{ marginTop: 10 }} >Map Location</Text>
                    <RadioButton.Android value="geolocation" />
                  </View>
                  <View style={{ flex: 2, flexDirection: "row" }}>
                    <Text style={{ marginTop: 10 }}>Search Location</Text>
                    <RadioButton.Android value="zipcode" />
                  </View>
                </View>
              </RadioButton.Group>
            </View>
            <View style={{ marginHorizontal: 5 }}>
              {locationType == "geolocation" ?
                <View style={{ minWidth: dimensions.width - 30, maxWidth: dimensions.width - 40, maxHeight: (dimensions.height - 100) / 2, minHeight: 300, marginLeft: -5 }} >
                  {(deviceLocation?.latitude && deviceLocation?.longitude) &&
                    <DraggableMap latitude={deviceLocation.latitude} longitude={deviceLocation.longitude} setZipcode={(value) => { setManuvalZip(value) }} />}
                </View> :
                <View  >
                  <View style={{ width: "90%", marginHorizontal: "auto", marginLeft: 0 }}>
                    <Searchbar
                      style={{ width: "100%", margin: "auto", backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                      theme={{
                        colors: {
                          primary: '#27b6cc',
                          text: esiColor.Text
                        }
                      }}
                      selectionColor={esiColor.TextSelection}
                      placeholder="Search"
                      onChangeText={(value) => {
                        setSearchValue(value);
                        getCityLocations({
                          "Search": value
                        });
                      }}
                      value={searchValue}
                      placeholderTextColor='grey'
                      underlineColor="#27b6cc"
                    />
                  </View>
                </View>
              }
              <View style={{ width: "90%", marginHorizontal: "auto", marginLeft: 0 }}>

                {loading ? <ActivityIndicator color='#27b6cc' /> :
                  (locationData.length > 0) ?
                    <ScrollView style={{ minHeight: 200, maxHeight: 400 }} >
                      {locationData.map(item => (
                        <TouchableOpacity key={item?.Location_ID || item.City_ID} style={{ marginRight: 10, flexDirection: "row", marginVertical: 5 }}
                          onPress={() => {
                            if (item?.isCity) {
                              setFilters({ ...filters, Zipcode: "", ZipcodeLocation: "", City_ID: item.City_ID, City_Info: item.City_Name });
                            } else {
                              setFilters({ ...filters, Zipcode: item?.Zipcode, ZipcodeLocation: item?.Location_Name + ", " + item?.Zipcode, City_ID: "", City_Info: "" });
                            }
                            setIsLocationVisible(!isLocationVisible);
                          }}
                        >
                          <Checkbox.Android
                            status={filters?.Zipcode == item?.Zipcode ? 'checked' : "unchecked"}
                          />
                          <Text style={{
                            marginTop: Platform.OS == "web" ? 8 : 10,
                          }}>{item?.isCity ? item?.City_Name : item?.Location_Name + ", " + item?.Zipcode + ", " + item?.City_Name}

                          </Text>
                        </TouchableOpacity>

                      )
                      )}
                    </ScrollView>
                    : (locationType == "geolocation" ? oldManuvalZip.length > 0 : searchValue.length > 0) &&
                    <Text>
                      Current location does not have any stores.
                    </Text>
                }


              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Surface>
  );
}

export default PartnerLocation;