import React from 'react';
import EsiSearchDropdown from '../searchable-dropdown';
import { StyleSheet } from 'react-native';
import axios from 'axios';
import config from '../../../state/actions/config';
import { useSelector } from 'react-redux';
import { nodeGetApi } from '../../../state/actions/commonAxios';

const EsiCity = (props: any) => {
    // props variable consist all react text field properties along with valueUpdate function.
    // valueUpdate function will return onChange value of field.

    // valueUpdate function is a custom property as it dont have in react native textfield properties.
    // CountryCode and StateCode are required.
    const esiColor = useSelector(state => state.theme);
    const { CountryCode, StateCode, ...rest } = props
    const [city, setCity] = React.useState([]);
    React.useEffect(() => {
        if (CountryCode && StateCode) {
            getCitys(CountryCode, StateCode)
        }
    }, [CountryCode, StateCode])

    const getCitys = (CountryCode: string, StateCode: string) => {
        nodeGetApi.post(`/Products/country-state-by-city`, { "CountryCode": CountryCode, 'StateCode': StateCode }, {
            headers: config.nodeUserHeadersCommon
        })
            .then(response => {
                setCity(response.data);
            })
            .catch(error => {
            })
    }


    const styles = StyleSheet.create({

        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        dropdown: {
            height: 58,
            borderColor: esiColor.SBorderColor,
            borderWidth: 1,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,

            // color: "white"

        },
        placeholderStyle: {
            fontSize: 16
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.Text

        },
    })

    return (
        <EsiSearchDropdown
            {...props}
            // data param here is used to assign the data from the function values that will be provided.
            data={city}
            style={styles.dropdown}
            activeColor={esiColor.BackgroundColor}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            containerStyle={{ backgroundColor: esiColor.BackgroundColor }}
            itemContainerStyle={{ backgroundColor: esiColor.BackgroundColor }}
            // Providing styles for icon
            iconStyle={styles.iconStyle}
            // valueField is used to get the value
            valueField={props.valueField}
            // labelField is used to get the label
            labelField={props.labelField}
           
            maxHeight={180}
            dropdownPosition="auto"
        />

    );
};

export default EsiCity;


