import { Dimensions, FlatList, Image, Platform, RefreshControl, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Avatar, Button, Colors, HelperText, IconButton, Surface, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../header";
import { useEffect, useState } from "react";
import { getBiddingServiceDetails } from "../../../../state/actions/node-actions/bid-services-actions";
import ImageCarousel from "../../../../components/custom/image-carousel";
import ordergif from "../../../../assets/images/GIF/ordergif.gif";
import Modal from "react-native-modal";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { updateUserBiddingService } from "../../../../state/actions/boomi-actions/bid-services-actions";





export default function BidServiceDetails(props) {
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
    const esiColor = useSelector(state => state.theme);
    const dimensions = Dimensions.get('window');
    const { height, width } = dimensions;
    const { navigation } = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [cancelMessage, setCancelMessage] = useState("");

    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isConfirmError, setIsConfirmError] = useState("");


    const getProductDetails = () => {
        if (bidding_id) {
            setLoading(true);
            dispatch(getBiddingServiceDetails({ User_Service_Bidding_Id: bidding_id }, getCallback))
        }
    }

    const getCallback = (status, data) => {
        setLoading(false);
        if (status) {
            setProduct(data);
        }
    }
    const bidding_id = props?.route?.params?.id;
    useEffect(() => {
        getProductDetails();
    }, [props?.route?.params, isConfirmLoading])

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View>
                {navigation.canGoBack() &&
                    <IconButton
                        icon="arrow-left"
                        size={27}
                        color={"#27B6CC"}
                        style={{ marginTop: -2, marginLeft: 1 }}
                        onPress={() => props.navigation.goBack()}
                    />}
            </View>
            <ScrollView style={{ maxHeight: dimensions.height - 20 }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => getProductDetails}
                    />
                }
            >
                <View>
                    {product?.Images?.length > 0 &&
                        <View>
                            <ImageCarousel image={product?.Images.map((image) => { return { imageuri: image } })} dimensions={dimensions} />
                        </View>}
                    <View style={{ marginVertical: 5 }} >
                        <Text style={{ textAlign: 'center', color: esiColor.brandFontColor, fontWeight: 'bold' }}>{product?.User_Bid_Service?.Service_Type}</Text>
                    </View>
                    <View style={{ paddingHorizontal: 10 }} >
                        {product?.User_Bid_Service?.Bidding_Service_Start_Date &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Service Date </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>: {product?.User_Bid_Service?.Bidding_Service_Start_Date}</Text>
                                </View>
                            </View>
                        }
                        {product?.User_Bid_Service?.Bidding_Expiry_Date &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Bid last date </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>: {product?.User_Bid_Service?.Bidding_Expiry_Date}</Text>
                                </View>
                            </View>
                        }

                        {product?.User_Bid_Service?.Service_Request_Total_Time_In_Hours > 0 &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Required Hours </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>: {product?.User_Bid_Service?.Service_Request_Total_Time_In_Hours}</Text>
                                </View>
                            </View>
                        }
                        {product?.User_Bid_Service?.Service_Request_Total_Time_In_Days > 0 &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Required days </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>: {product?.User_Bid_Service?.Service_Request_Total_Time_In_Days} </Text>
                                </View>
                            </View>
                        }
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Status </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {product?.User_Bid_Service?.Bidding_Status}</Text>
                                {product?.User_Bid_Service?.Bidding_Status == "INITIATED" &&
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmScreen(true);
                                    }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Cancel </Text>
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Pincode </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {product?.User_Bid_Service?.User_Pincode}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "column" }} >
                            <Text style={{ fontWeight: "bold", color: esiColor.DescColor, textAlign: "center" }}>Description</Text>
                            <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }}>{product?.User_Bid_Service?.Description}</Text>
                        </View>
                    </View>
                    <View style={{ paddingHorizontal: 10 }} >
                        <View style={{ flexDirection: "column" }} >
                            <Text style={{ fontWeight: "bold", textAlign: "center", color: esiColor.DescColor }}>Quotations</Text>
                        </View>
                        {(product?.Partnerbids?.length <= 0 || !product?.Partnerbids) ?
                            <View style={{ alignItems: "center", marginTop: 10 }}>

                                <View>
                                    <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image>
                                    <Text style={{ textAlign: "center", color: esiColor.NoDataFound, marginBottom: 10 }}>Oops! No Quotations's found...</Text>
                                </View>

                            </View>
                            :
                            <View>
                                <FlatList
                                    data={product?.Partnerbids || []}
                                    renderItem={({ item }) => (
                                        <TouchableOpacity onPress={() => {
                                            if (product?.User_Bid_Service?.Finalize_Partner_Bidding_Quote_Id) {
                                                if (product?.User_Bid_Service?.Finalize_Partner_Bidding_Quote_Id == item?.Partner_Bidding_Service_Quote_Id) {
                                                    navigation.navigate('BidPartnerServiceDetails', { id: item?.Partner_Bidding_Service_Quote_Id });
                                                }
                                            } else {
                                                navigation.navigate('BidPartnerServiceDetails', { id: item?.Partner_Bidding_Service_Quote_Id });
                                            }
                                        }}>
                                            <View style={{
                                                shadowColor: product?.User_Bid_Service?.Finalize_Partner_Bidding_Quote_Id == item?.Partner_Bidding_Service_Quote_Id ? esiColor.brandShadowColor : esiColor.brandShadowColor,
                                                // shadowOffset: { width: -2, height: 4 },
                                                shadowOpacity: 5,
                                                shadowRadius: 7,
                                                marginHorizontal: 2, borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                // elevation: 10,
                                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                                minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                                                maxHeight: height > 250 ? 250 : 90,
                                                minHeight: 100,
                                                // borderColor: 'grey',
                                                borderWidth: 0.5
                                            }}>
                                                <View style={{ flex: 1, alignContent: 'center', marginLeft: 10, marginVertical: 5 }} >
                                                    <Image source={{ cache: "force-cache", uri: item?.Logo_Path }} style={{ borderRadius: 8, height: 100, width: 80, resizeMode: 'contain', backgroundColor: "#efefef" }}></Image>
                                                </View>
                                                <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{item?.Name}</Text>
                                                    <View style={{ flexDirection: 'row' }} >
                                                        <View style={{ flex: 4 }}  >
                                                            <Text style={{ color: esiColor.DescColor }}>Amount </Text>

                                                        </View>
                                                        <View style={{ flex: 6 }}  >
                                                            <Text style={{ color: esiColor.itemColor }}>: {item.Quotation_Amount}</Text>

                                                        </View>
                                                    </View>
                                                    {item?.Service_Price_Per_Day > 0 &&
                                                        <View style={{ flexDirection: 'row' }} >
                                                            <View style={{ flex: 4 }}  >
                                                                <Text style={{ color: esiColor.DescColor }} >Day Cost </Text>

                                                            </View>
                                                            <View style={{ flex: 6 }}  >
                                                                <Text style={{ color: esiColor.itemColor }} >: {item.Service_Price_Per_Day}</Text>
                                                            </View>
                                                        </View>}
                                                    {item?.Service_Price_Per_Hour > 0 &&
                                                        <View style={{ flexDirection: 'row' }} >
                                                            <View style={{ flex: 4 }}  >
                                                                <Text style={{ color: esiColor.DescColor }} >Hour Cost </Text>

                                                            </View>
                                                            <View style={{ flex: 6 }}  >
                                                                <Text style={{ color: esiColor.itemColor }} >: {item.Service_Price_Per_Hour}</Text>
                                                            </View>
                                                        </View>}
                                                    <View style={{ flexDirection: 'row' }} >
                                                        <View style={{ flex: 4 }}  >
                                                            <Text style={{ color: esiColor.DescColor }}>Location </Text>

                                                        </View>
                                                        <View style={{ flex: 6 }}  >
                                                            <Text style={{ color: esiColor.itemColor }}>: {item.Partner_Location}</Text>
                                                        </View>
                                                    </View>
                                                    <Text style={{ fontWeight: "bold", color: esiColor.DescColor }} >Info </Text>
                                                    <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }} > {"  " + item?.Partner_Comments.slice(0, 50)}...</Text>
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    )}
                                    keyExtractor={(item, index) => index}
                                />
                            </View>
                        }
                    </View>
                </View>
            </ScrollView >
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }}
                isVisible={isConfirmScreen}
            >
                <View style={{ flex: 1, maxWidth: dimensions.width - 50, minWidth: 300, minHeight: 150, maxHeight: 200, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <View style={{ flexDirection: "column" }} >
                        <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, backgroundColor: "" }} onPress={() => {
                            setIsConfirmScreen(!isConfirmScreen);
                        }}>
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: 170
                        }}>
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "space-around",
                                paddingHorizontal: 10,
                                marginTop: 20
                            }}>
                                <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.Text }} >Cancel Reason:</Text>
                                <EsiTextAreaInput
                                    error={Boolean(errorMessage)}
                                    style={{ height: 60 }}
                                    mode='outlined'
                                    value={cancelMessage}
                                    textareavalueUpdate={value => {
                                        if (value) {
                                            if (value.length < 20) {
                                                setErrorMessage("Cancel reason should be more than 20 characters.")
                                            } else {
                                                setErrorMessage("");
                                            }
                                        } else {
                                            setErrorMessage("Please provide cancel reason.")
                                        }
                                        setCancelMessage(value);
                                    }}
                                    placeholder={'Please provide reason'}
                                    underlineColorAndroid={'transparent'}
                                    theme={{
                                        colors: {
                                            primary: "#27b6cc"
                                        },
                                        roundness: 8
                                    }}
                                    maxLength={500}
                                />
                                <HelperText type="error" visible={Boolean(errorMessage)}>
                                    {errorMessage}
                                </HelperText>
                                <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.Text }} >Are you sure you want to cancel?</Text>
                                <HelperText type="error" style={{ textAlign: "center" }} visible={Boolean(isConfirmError)}>
                                    {isConfirmError}
                                </HelperText>
                            </View>


                            <View style={{
                                flexDirection: "row",
                                justifyContent: "space-around",
                                paddingHorizontal: 5,
                                marginVertical: 5

                            }}>
                                <TouchableOpacity onPress={() => {
                                    setIsConfirmError("");
                                    if (cancelMessage) {
                                        if (cancelMessage.length < 20) {
                                            setErrorMessage("Cancel reason should be more than 20 characters.")
                                        } else {
                                            setErrorMessage("");

                                            setIsConfirmLoading(true);
                                            dispatch(updateUserBiddingService({
                                                User_Service_Bidding_Id: bidding_id,
                                                Bidding_Status: "CANCELLED",
                                                Cancelled_Reason: cancelMessage
                                            }, (status) => {
                                                setIsConfirmLoading(false);
                                                setCancelMessage("");
                                                if (status) {
                                                    setIsConfirmScreen(false);
                                                } else {
                                                    setIsConfirmError("Please try again.");
                                                }
                                            }))

                                        }
                                    } else {
                                        setErrorMessage("Please provide cancel reason.")
                                    }

                                }} style={{ borderColor: esiColor.esiMainColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                    <Text style={{ color: esiColor.esiMainColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Yes </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {
                                    setIsConfirmScreen(false);
                                }} style={{ borderColor: esiColor.light, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                    <Text style={{ color: esiColor.esiMainColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Close </Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View>
                </View>
            </Modal>
        </Surface >
    )
}