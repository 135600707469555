import * as React from 'react';
import { TouchableOpacity, View, Platform, Dimensions } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { Text } from 'react-native-paper';
import { useSelector } from 'react-redux';
import Modal from "react-native-modal";
import SignIN from './auth/signin';
import { useToast } from '../self_modules/react-native-paper-toast/src';


const Footer = ({ props, navigationRef }) => {
    const { navigation, ...rest } = props;
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const esiColor = useSelector(state => state.theme);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [isModalVisible, setModalVisible] = React.useState(false);
    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
    };

    const toast = useToast();

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const checkAnyActiveTab = () => {
        let currentRoute = navigationRef.current?.getCurrentRoute();
        let listTabs = [
            { routeName: "Partners", param: "" },
            { routeName: "Rides", param: "" },
            { routeName: "Services", param: "" },
            { routeName: "MFH", param: "" },
            // { routeName: "Partners", param: "Mee Food" },
        ]
        let isHome = false;
        for (let i = 0; i < listTabs.length; i++) {
            if (selectedTab(currentRoute, listTabs[i].routeName, listTabs[i].param)) {
                isHome = true;
                break;

            }
        }
        return !isHome
    }


    const selectedTab = (currentRoute, routeName, param = "") => {
        let response = false;
        if (currentRoute) {
            if (currentRoute.name === "Main") {
                if (currentRoute.params.screen === "All") {
                    if (currentRoute.params.params.page === routeName) {
                        if (param == "Mee Food") {
                            if (currentRoute.params.params.params?.Store_Type == "Mee Food") {
                                response = true;
                            }

                        } else {
                            response = true;
                        }
                    }
                }
            } else if (currentRoute.name === "All") {
                if (currentRoute.params.page === routeName) {
                    if (param == "Mee Food") {
                        if (currentRoute.params.params?.Store_Type == "Mee Food") {
                            response = true;
                        }

                    } else {
                        response = true;
                    }
                }
            }
            else if (currentRoute.name === routeName) {
                if (param == "Mee Food") {
                    if (currentRoute.params?.Store_Type == "Mee Food") {
                        response = true;
                    }

                } else {
                    response = true;
                }
            }
        }
        return response
    }

    return (<View style={{ backgroundColor: esiColor.BackgroundColor}} >
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', marginBottom: Platform.OS === "ios" ? 30 : 5 }}>

            <TouchableOpacity style={{ flexDirection: "column", justifyContent: "center" }}
                accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "Partners") ? { selected: true } : {}}
                onPress={() => navigation.navigate('HomeMain', { screen: 'Partners' })}>

                <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} name="store-marker-outline" color={selectedTab(navigationRef.current?.getCurrentRoute(), "Partners") ? esiColor.FooterIcon : esiColor.FooterClkIcon} size={28} />
                <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "Partners") ? esiColor.FooterIcon : esiColor.FooterClkIcon }}>Stores</Text>
            </TouchableOpacity>

            {/* <TouchableOpacity
          accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "deals") ? { selected: true } : {}}
          style={{ flexDirection: "column", justifyContent: "center" }} onPress={() => navigation.navigate('Main', { screen: 'All', params: { page: 'deals', filter: 'all' } })}  >
          <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} name="handshake" color={selectedTab(navigationRef.current?.getCurrentRoute(), "deals") ? '#279bcc' : '#32cce3'} size={28} />
          <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "deals") ? '#279bcc' : '#32cce3', fontWeight: 'bold' }}>Deals</Text>
        </TouchableOpacity> */}

            <TouchableOpacity
                accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "Rides") ? { selected: true } : {}}
                style={{ flexDirection: "column", justifyContent: "center" }} onPress={() => {
                    if (isAuthenticated) {
                        navigation.navigate('HomeMain', { screen: 'Rides' })
                    }
                    else {
                        setModalVisible(true);
                    }
                }
                }  >
                <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} name="car-multiple" color={selectedTab(navigationRef.current?.getCurrentRoute(), "Rides") ? esiColor.FooterIcon : esiColor.FooterClkIcon} size={28} />
                <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "Rides") ? esiColor.FooterIcon : esiColor.FooterClkIcon }}>Rides</Text>
            </TouchableOpacity>

            <TouchableOpacity
                accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "Home") || checkAnyActiveTab() ? { selected: true } : {}}
                style={{ flexDirection: "column", justifyContent: "center" }} onPress={() => navigation.navigate('HomeMain', { screen: 'Home' })} >
                <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} name="home" color={selectedTab(navigationRef.current?.getCurrentRoute(), "Home") || checkAnyActiveTab() ? esiColor.FooterIcon : esiColor.FooterClkIcon} size={28} />
                <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "Home") || checkAnyActiveTab() ? esiColor.FooterIcon : esiColor.FooterClkIcon }}>Home</Text>
            </TouchableOpacity>

            <TouchableOpacity
                accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "Services") ? { selected: true } : {}}
                style={{ flexDirection: "column", justifyContent: "center" }} onPress={() => {
                    if (isAuthenticated) {
                        navigation.navigate('HomeMain', { screen: 'Services' })
                    }
                    else {
                        setModalVisible(true);
                    }
                }
                } >
                <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} name="cogs" color={selectedTab(navigationRef.current?.getCurrentRoute(), "Services") ? esiColor.FooterIcon : esiColor.FooterClkIcon} size={28} />
                <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "Services") ? esiColor.FooterIcon : esiColor.FooterClkIcon }}>Services</Text>
            </TouchableOpacity>

            {/* <TouchableOpacity
                accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "FunctionHalls") ? { selected: true } : {}}
                style={{ flexDirection: "column", justifyContent: "center" }} onPress={() => {
                    if (isAuthenticated) {
                        navigation.navigate('HomeMain', { screen: 'FunctionHalls' })
                    }
                    else {
                        setModalVisible(true);
                    }
                }}>
                <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} color={selectedTab(navigationRef.current?.getCurrentRoute(), "FunctionHalls") ? '#279bcc' : '#32cce3'} name="town-hall" size={28} />
                <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "FunctionHalls") ? '#279bcc' : '#32cce3', fontWeight: 'bold' }}>Events</Text>
            </TouchableOpacity> */}

            {/* <TouchableOpacity
                accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "Partners", "Mee Food") ? { selected: true } : {}}
                style={{ flexDirection: "column", justifyContent: "center" }} onPress={() => {
                    if (isAuthenticated) {
                        navigation.navigate('Partners', { Store_Type: "Mee Food" })
                    }
                    else {
                        setModalVisible(true);
                    }
                }}>
                <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} color={selectedTab(navigationRef.current?.getCurrentRoute(), "Partners", "Mee Food") ? esiColor.FooterIcon : esiColor.FooterClkIcon} name="food" size={28} />
                <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "Partners", "Mee Food") ? esiColor.FooterIcon : esiColor.FooterClkIcon }}>Food</Text>
            </TouchableOpacity> */}

            <TouchableOpacity
                accessibilityState={selectedTab(navigationRef.current?.getCurrentRoute(), "MFH") ? { selected: true } : {}}
                style={{ flexDirection: "column", justifyContent: "center" }} onPress={() => {
                    navigation.navigate('HomeMain', { screen: 'MFH' })
                }
                } >
                <MaterialCommunityIcons style={{ margin: "auto", alignSelf: "center" }} name="shopping" color={selectedTab(navigationRef.current?.getCurrentRoute(), "MFH") ? esiColor.FooterIcon : esiColor.FooterClkIcon} size={28} />
                <Text style={{ fontSize: 12, margin: "auto", color: selectedTab(navigationRef.current?.getCurrentRoute(), "MFH") ? esiColor.FooterIcon : esiColor.FooterClkIcon }}>MFH</Text>
            </TouchableOpacity>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>
        </View >
    </View>
    );
}

export default Footer;