import React from "react";
import { Text, Colors, TextInput, TouchableRipple, Avatar, IconButton } from 'react-native-paper';
import { View, StyleSheet, Image, TouchableOpacity, useWindowDimensions, Clipboard, Linking, ScrollView, Platform } from "react-native";
import RenderHTML from "react-native-render-html";
import { adduservisitAction } from "../../state/actions/couponsActions";
import { useDispatch, useSelector } from "react-redux";
import { Feather } from "@expo/vector-icons";

export default function ESICopyCouponModel(props: any) {
    // data is a Coupon object as we use this object to display the info front-end
    // isAuthenticated prop is used here to call authentication
    const { data, isAuthenticated, closeModal, ...rest } = props;
    const esiColor = useSelector(state => state.theme);

    const sampleData =
    {
        Records_Filter: "FILTER",
        Brand_Id: [],
        Partner_Details_Id: [],
        Category_Id: [],
        Coupon_Id: [],
        Discount_Type: "",
        Min_Discount: "",
        Max_Discount: "",
        Esi_Cashback_Type: "",
        Min_Esi_Cashback: "",
        Max_Esi_Cashback: "",
        Is_Top_Coupon: "",
        Is_Upcoming: "",
        Is_Coupon_Expired: "",
        Whats_Hot: "",
        Verified: "",
        Exclusive: "",
        Extra_Cashback: "",
        Expired_Status: "",
        Status: "Active",
        Partner_Type: "",
        Is_Top_Brand: "",
    };
    const dispatch = useDispatch();
    //copied param is used to copy the Coupon Code
    //setCopied param is used to get the Coupon code
    const [copied, setCopied] = React.useState(false)
    const handleClick = async () => {
        const formData = {
            "User_Email_Id": isAuthenticated?.Email_Id,
            "Ip_Address": "192.168. 0.1",
            "Offer_Type": "Coupon",
            "Offer_Id": data?.Coupon_Id,
            "Store_Type": data?.Partner_Type,
            "Store_Id": data?.Partner_Details_Id,
            "Esi_Cashback": data?.Esi_Cashback,
            "Status": data?.Status,
            "Redirection_Url": data?.Mobile_Redirect_Url
        }
        dispatch(adduservisitAction(formData))
        Linking.openURL(data?.Mobile_Redirect_Url);
    }
    const copyCode = () => {
        Clipboard?.setString(data?.Code);
        global.Coupon_Id = data?.Coupon_Id;
        global.Coupon_Code = data?.Code;
        setCopied(true)
    }
    const widthHtml = ((useWindowDimensions().width / 100) * 80) || 200;
    const copy_text = copied ? " Copied" : " Copy Code";

    return (
        <View style={{ flex: 1 }} ><View style={{ alignItems: 'flex-end' }}>
            <TouchableRipple style={{ width: 30 }} onPress={closeModal}>
                <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={30} icon="close" />
            </TouchableRipple>
        </View>
            <ScrollView>
                <View style={{ flexDirection: 'row', justifyContent: "space-around", alignSelf: 'center' }}>
                    <View style={{ flex: 1, alignSelf: 'center', width: 100, height: 100, margin: 5 }} >
                        <View style={{ flexDirection: 'row', justifyContent: "space-between", alignSelf: 'center' }}>
                            <Image source={{ cache: "force-cache", uri: data?.Image_Path + `?w=100&h=100` }} style={{ resizeMode: 'contain', width: 100, height: 100 }} />
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignSelf: "center", margin: 5 }} >
                        <View style={{ flexDirection: 'row', justifyContent: "space-between", alignSelf: 'center' }}>
                            <Image source={{ cache: "force-cache", uri: data?.Logo_Path + `?w=100&h=50` }} style={{ width: 100, height: 50, resizeMode: 'contain', borderWidth: 1, borderColor: '#9ce5f0' }} />
                        </View>
                        <Text style={{ fontSize: 12, textAlign: 'center', fontWeight: 'bold', flexDirection: 'row', justifyContent: "center", color: esiColor.DescColor }}>
                            Status:
                            <Text style={{ color: data?.Expired_Status === "Live" ? "green" : esiColor.itemColor, flexDirection: 'row', fontSize: 12, fontWeight: 'bold' }}>
                                {data?.Expired_Status}
                            </Text>
                        </Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 14, flex: 1, color: esiColor.itemColor }}>{data?.Name?.substring(0, 40)}...</Text>
                    <View style={{ paddingHorizontal: 20 }}>
                        {data?.Description ? <View style={{ flexWrap: "wrap", width: widthHtml, padding: 5, backgroundColor: esiColor.RTBC, borderRadius: 8 }} >
                            <RenderHTML
                                contentWidth={widthHtml}
                                source={{ html: data?.Description }} /></View> : <></>}
                    </View>
                </View>
                <View style={{ flexDirection: 'column', margin: 15 }}>
                    <View style={{ flexDirection: 'row', margin: "auto", justifyContent: "center" }}>
                        <TextInput selectionColor='rgba(0, 0, 0, 0.5)' value={data?.Code} disabled style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontWeight: 'bold', backgroundColor: 'lightblue', borderTopStartRadius: 25, borderBottomStartRadius: 25, height: 40, width: 150 }} />
                        <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <TouchableOpacity disabled={copied ? true : false} onPress={() => { copyCode() }} style={{ justifyContent: 'center', paddingVertical: 10, alignItems: 'center', borderTopEndRadius: 25, borderBottomEndRadius: 25, flexDirection: 'row', backgroundColor: esiColor.globalButtonColor }}>
                                <IconButton style={{ marginLeft: 0 }} icon={"content-copy"} color="white" />
                                <Text style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: -20, color: 'white', fontWeight: 'bold', flexDirection: 'row', width: 100, fontSize: 12 }}>{copy_text}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>
                <View style={styles.Copycodecontainer1}>
                    <TouchableOpacity onPress={() => handleClick()} style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Grab Offer</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </View>
    )
}
const styles = StyleSheet.create({
    Copycodecontainer: {
        backgroundColor: '#99D8F1',
        width: 300,
        margin: 'auto',
        borderRadius: 20
    },
    Copycodecontainer1: {
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        marginTop: '5%',
        margin: "2%"
    },
});