import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, ScrollView, Platform, Dimensions } from 'react-native';
import { Provider, Card, Button, Text, Surface, Modal, ActivityIndicator } from 'react-native-paper';
import Header from '../../header';
import isEmpty from '../../../state/validations/is-empty';
import { getAllContentManagementStaticAction } from '../../../state/actions/contentActions';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function PartnerPrivacypolicy(props) {
    const { navigation } = props;
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const dispatch = useDispatch();
    const [contentsOne, setContentsOne] = useState({});
    const responseFunction = (data) => {
        setContentsOne(data)
    }
    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Name": "Partner_Privacypolicy",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);
    return (
        <Surface style={{ flex: 1 }}>
            <Header {...props} />
            <Text style={{ fontSize: 24, marginTop: 5, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}> Partner Privacy Policy</Text>
            <ScrollView>

                <View style={{
                    flex: 1,
                    marginHorizontal: 2, borderRadius: 25, padding: 5, margin: 'auto', backgroundColor: 'white', flexDirection: 'column', shadowColor: '#000', borderColor: "#CACFD2", borderWidth: 0.5,
                    shadowOffset: { width: 0, height: 2 },
                    marginTop: "2%",
                    marginBottom: "5%",
                    shadowOpacity: 0.2,
                    shadowRadius: 10,
                    elevation: 10,
                    marginLeft: "auto", marginRight: "auto",
                    width: dimensions >= 10000 ? "90%" : "95%",
                }}>
                    <View>
                        {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                            <RenderHTML
                                source={{ html: contentsOne?.Description }} /> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                <Text>No Data found!</Text></View>) :
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                <ActivityIndicator color='#27b6cc' animating={true} style={{ marginTop: 25 }} />
                            </View>}
                    </View>
                </View>
            </ScrollView>
        </Surface>
    );


}
