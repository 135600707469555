// import React, { Fragment } from "react";
import React, { useEffect } from "react";
import { Card, Colors } from "react-native-paper";
import { Image, StyleSheet, Text, View, SafeAreaView, TouchableOpacity, Dimensions, ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Swiper from "react-native-web-swiper";
import Modal from "react-native-modal";
import isEmpty from "../../state/validations/is-empty";
import ESICopyCouponModel from "../../components/custom/copycode-modal";
import ESIDealModel from "../../components/custom/deal-modal";
const { height, width } = Dimensions.get('window');
import SignIN from "../auth/signin";
import { getAllBannersAction, getCouponsAction, getDealsAction, getProductByIdAction } from "../../state/actions/node-actions/home-actions";
import * as Network from 'expo-network';
import api from "../../state/actions/api";
import { useToast } from "../../self_modules/react-native-paper-toast/src";


export default function HomeFoldOne(props) {

    const esiColor = useSelector(state => state.theme);

    const dispatch = useDispatch();
    const { navigation, dataReload, dataErrorReload } = props
    const [open, setOpen] = React.useState(false);
    const [Dopen, setDOpen] = React.useState(false);
    const [isAuth, setAuth] = React.useState(false);

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [allBanners, setAllBanners] = React.useState([]);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 20, SortBy: "TITLE", SortOrder: "DESC" });
    const [loading, setIsLoading] = React.useState(false);
    const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);

    const getBannersResponse = async (status, result, pagination) => {
        setIsLoading(false);
        if (status == "network") {
            let iPAddress = await Network.getIpAddressAsync();
            try {
                api.get(`https://geoapi.meepaisa.com/${iPAddress}`).then(async (response) => {
                    let data = response.data;
                }).catch(async (reason) => {
                });
            } catch (error) {

            }
        }
        if (status == "network") {
            await delay(5000);
            bannersCall();
        }
        else if (status) {
            setAllBanners(result);
            setPagination(pagination);
            await delay(result.length > 0 ? dataReload : dataErrorReload);
            bannersCall();
        } else {
            await delay(dataErrorReload);
            bannersCall();
        }
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [secondsT, setSecondsT] = React.useState(1);
    React.useEffect(() => {
        const timer = setTimeout(() => {
            secondsT > 0 ? setSecondsT(secondsT - 1) : setSecondsT(-1);

        }, 1000)
        return () => clearTimeout(timer)
    }, [secondsT]);

    useEffect(() => {
        bannersCall();
    }, []);


    const bannersCall = () => {
        if (isEmpty(allBanners) && !loading) {
            dispatch(getAllBannersAction(pagination,
                {
                    Records_Filter: "FILTER"
                    , Banner_Status: "Active"
                },
                getBannersResponse));
            setIsLoading(true);
        }
        if (isAuthenticated != isAuth) {
            setAuth(isAuthenticated);
            setSeconds(1);
        }
    }


    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);


    const callBackGetData = (status, data) => {
        if (status && data?.length > 0) {
            navigation('ProductDetails', { masterId: data[0]?.Product_Master_Id, Product_Id: data[0]?.Product_Id, Partner_Details_Id: data[0]?.Partner_Details_Id })
        }
    }

    const callBackGetDealData = (status, data) => {
        if (status) {
            openDModal(data[0]);
            // navigation('Deals', { data: data[0] });
        }
    }
    const [dealData, setDealData] = React.useState('');
    const openDModal = (data) => {
        setDealData(data)
        setDOpen(true);
    };

    const callBackGetCouponData = (status, data) => {
        if (status) {
            openModal(data[0]);
        }
    }
    const [couponData, setCouponData] = React.useState('');
    const openModal = (data) => {
        setCouponData(data)
        setOpen(true);
    };
    const handleClick = (data) => {
        if (data.Banner_Offer_Type === "Product") {
            dispatch(getProductByIdAction(
                {
                    Records_Filter: "FILTER",
                    Product_Id: data.Banner_Offer_Id,
                },
                { PageNo: 1, PageSize: 1, SortBy: "PRODUCT_ID", SortOrder: "ASC" }
                , callBackGetData));
        }
        else if (data.Banner_Offer_Type === "Coupon") {
            if (isAuthenticated) {
                dispatch(getCouponsAction({
                    Records_Filter: "FILTER",
                    Coupon_Id: [data.Banner_Offer_Id]
                }, { PageNo: 1, PageSize: 1, SortBy: "COUPON_ID", SortOrder: "ASC" }, callBackGetCouponData)
                );
            } else {
                setModalVisible(!isModalVisible);
            }
        }
        else if (data.Banner_Offer_Type === "Deal") {
            if (isAuthenticated) {
                dispatch(getDealsAction({
                    Records_Filter: "FILTER",
                    Deal_Id: [data.Banner_Offer_Id],
                    Status: "Active",

                }, { PageNo: 1, PageSize: 1, SortBy: "DEAL_ID", SortOrder: "ASC" }, callBackGetDealData));
            } else {
                setModalVisible(!isModalVisible);
            }
        };
        // statereset();
    }
    const closeModal = () => {
        setOpen(!open);
        setCouponData("")
    };

    const closeDModal = () => {
        setDOpen(!Dopen);
        setDealData("")
    };

    const styles = StyleSheet.create({

        text: {
            fontSize: 22
        },
        container: {
            // flex: 1,
    
            height: 200, maxWidth: 1024,
    
            width: (width > height) ? height : width,
            margin: 2,
            backgroundColor:esiColor.BackgroundColor
        },
        getStartedContainer: {
            alignItems: "center",
        },
    
    });

    const renderSwiner = (allBanners) => {
        return (
            <Swiper
                // from={1}
                minDistanceForAction={0.1}
                timeout={5}

                controlsProps={{
                    dotsTouchable: true,
                    prevPos: 'left',
                    nextPos: 'right',
                    nextTitle: '',
                    dotActiveStyle: {
                        backgroundColor: "#27b6cc"
                    },
                    nextTitleStyle: { color: 'gray', fontSize: 24, fontWeight: 'bold' },
                    PrevComponent: ({ onPress }) => (
                        <TouchableOpacity onPress={onPress}>
                            <Text style={{ color: 'gray', fontSize: 24, fontWeight: 'bold' }}>
                                {''}
                            </Text>
                        </TouchableOpacity>
                    ),
                }}
            >
                {(allBanners.length > 0) && allBanners?.slice(0, 15).map((data, index) => (
                    <View key={index} style={{ alignItems: 'center' }}>
                        <TouchableOpacity onPress={(e) => handleClick(data)}>
                            <Image resizeMode="stretch" style={{
                                height: 200, maxHeight: 200,

                                width: (width > height) ? height : width - 4, maxWidth: 1024
                            }} source={{ cache: "force-cache", uri: data?.Banner_Image }}></Image>
                        </TouchableOpacity>
                    </View>
                )
                )}

            </Swiper>
        )
    }

    const toast = useToast();
    const [isModalVisible, setModalVisible] = React.useState(false);

    const signInModalResponse = (status) => {
        setModalVisible(!isModalVisible);
    };

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    return (allBanners?.length > 0 &&
        <SafeAreaView style={(styles.container, styles.getStartedContainer)}>
            <View style={styles.container}>
                {(secondsT > 0 || loading) ? <ActivityIndicator color='#27b6cc' /> : renderSwiner(allBanners)}
            </View>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={open}>
                <View style={{ flex: 1, minWidth: dimensions.width <= 400 ? 350 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 430, borderRadius: 10, backgroundColor: Colors.white, }}>
                    <ESICopyCouponModel navigation={navigation} openModal={openModal} data={couponData} closeModal={closeModal} />
                </View >
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={Dopen}>
                <View style={{ flex: 1, minWidth: dimensions.width <= 400 ? 350 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 430, borderRadius: 10, backgroundColor: Colors.white, }}>
                    <ESIDealModel navigation={navigation} openModal={openDModal} data={dealData} closeModal={closeDModal} />
                </View >
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: Colors.white }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>

        </SafeAreaView>
    );
}


