import * as React from 'react';
import { Dimensions, Image, Platform, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Avatar, Button, Surface, TouchableRipple } from 'react-native-paper';
import { Text } from '../../components/Themed';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import Modal from "react-native-modal";
import SignIN from '../auth/signin';
import { deleteAccountAction, getLogedInUser, loginOut, sessionCheckAction } from '../../state/actions/authActions';
import { useColorScheme } from 'react-native';
import isEmpty from '../../state/validations/is-empty';
import { getAllNotification } from '../../state/actions/node-actions/home-actions';
import { useAnimatedRef, useSharedValue } from 'react-native-reanimated';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import { FontAwesome5, Fontisto, MaterialCommunityIcons } from '@expo/vector-icons';
import * as Network from 'expo-network';
import { addStatistics } from '../../state/actions/dashboardActions';


const window = Dimensions.get("window");

export default function AllPages(props: any) {


    const esiColor = useSelector(state => state.theme);
    // DEFAULT LIGHT THEME
    const DefaultTheme = {
        dark: false,
        colors: {
            primary: '#27b6cc',
            background: 'rgb(242, 242, 242)',
            card: 'rgb(255, 255, 255)',
            text: 'rgb(28, 28, 30)',
            border: 'rgb(216, 216, 216)',
            notification: 'rgb(255, 59, 48)',
        },
    };

    // DEFAULT DARK THEME
    const DarkTheme = {
        dark: true,
        colors: {
            primary: '#27b6cc',
            background: 'rgb(1, 1, 1)',
            card: 'rgb(18, 18, 18)',
            text: 'rgb(229, 229, 231)',
            border: 'rgb(39, 39, 41)',
            notification: 'rgb(255, 69, 58)',
        },
    };
    const { navigation } = props;
    const dispatch = useDispatch();
    const toast = useToast();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const userPic = useSelector(state => state.auth.user?.Profile_Pic_Path ? { uri: state.auth.user?.Profile_Pic_Path } : require("../../assets/images/icons/userlogo.png"));
    const username = useSelector(state => state.auth.user?.User_Name)
    const user = useSelector((state) => state.auth.user);

    const [isModalVisible, setModalVisible] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [notifications, setNotifications] = React.useState(0);
    const aref = useAnimatedRef();


    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
    };

    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };
    const callBackData = (status: any, data: any) => {
        if (status) {
            setNotifications(data[0].Notification_Count || 0);
        } else {
            setNotifications(0);
        }
    }

    const [logoutLoading, setLogoutLoading] = React.useState(false);
    const setLogoutDisabile = async (status: any, message: any) => {
        setLogoutLoading(false);
        if (!status) {
            toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        } else {
            toast.show({ message: "Logout successfully.", type: 'info', duration: 3000, position: 'top' });
            let iPAddress = await Network.getIpAddressAsync();
            let formdata = {
                "Component": "signout",
                "Email_Id": user?.Email_Id,
                "Event_Context": "SignOut",
                "Event_Name": "SignOut",
                "Ip_Address": iPAddress,
                "Mobile_Number": user?.Mobile,
                "User_Full_Name": user?.User_Name,
                "Origin": Platform.OS
            };
            dispatch(addStatistics(formdata));
        }
    }

    const _handleSignOut = () => {
        setLogoutLoading(true);
        dispatch(loginOut(navigation, user, setLogoutDisabile));
    };

    const _handleDelete = () => {
        setDeleteModel(true);
    };

    const [deleteModel, setDeleteModel] = React.useState(false);

    // Tost message code.
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);
    React.useEffect(() => {
        if (!isEmpty(user) && seconds == -1) {
            setSeconds(20);
            let filter = {
                "Records_Filter": "FILTER",
                "Email_Id": user?.Email_Id
            }
            dispatch(getAllNotification(filter, { PageNo: 1, PageSize: 1, SortBy: "NOTIFICATION_ID", SortOrder: "DESC" }, callBackData))
            dispatch(sessionCheckAction(user));
            dispatch(getLogedInUser(user?.Email_Id, false, () => { }, user?.User_Session_Id));
        }
    }, [user, seconds]);


    const customModels = () => {
        return (<View>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>

            {/* delete Account */}

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={deleteModel}>
                <View style={{ flex: 1, width: dimensions.width - 50, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 250, maxHeight: 350, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 5, shadowRadius: 7 }}>

                    <View style={{ alignItems: 'flex-end' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setDeleteModel(false) }} >
                            <Avatar.Icon color={'black'} style={{ backgroundColor: 'white' }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ flexDirection: "column", marginTop: -20, marginHorizontal: 10 }} >
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />

                        </View>
                        <Text style={{ color: esiColor.itemColor }}>
                            We are sad to see you go, will make sure for better performance.
                            We will keep your data in our records for security reasons and you can also reactivate your account in the future.
                        </Text>
                        <Image source={require("../../assets/images/GIF/do-not-delete.gif")} style={{ width: "100%", height: 150, alignSelf: "center", resizeMode: 'contain' }} />

                    </View>
                    <View style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-around', margin: "auto" }}>

                        <Button style={{ marginRight: 10, backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => {
                            setDeleteModel(false);
                            dispatch(deleteAccountAction(navigation, user, setLogoutDisabile));
                        }}>
                            <Text style={{ color: esiColor.itemColor }}>Delete</Text>

                        </Button>
                        <Button mode="contained" style={{ backgroundColor: esiColor.globalButtonColor }} onPress={() => {
                            setDeleteModel(false);
                        }}>
                            <Text style={{ color: esiColor.itemColor }}>Cancel</Text>
                        </Button>

                    </View>

                </View>
            </Modal>
        </View>
        )
    }

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <View style={{ backgroundColor: esiColor.BackgroundColor, padding: 10, flexDirection: 'row', paddingTop: Platform.OS === 'web' ? 10 : 50, borderBottomWidth: 1, borderBottomColor: esiColor.BorderColor }}>

                {!isAuthenticated ?
                    <View style={{ justifyContent: 'center' }}>
                        <TouchableOpacity onPress={toggleModal} >
                            <Text style={{ color: '#27b6cc', fontSize: 18, fontWeight: 'bold' }}>Log In</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start" }}>
                        <TouchableOpacity onPress={() => navigation.navigate('ProfileDetails')}>
                            <Image source={userPic} style={{ height: 50, width: 50, borderRadius: 50, marginTop: 10, backgroundColor: esiColor.esiMainColor }}></Image>
                        </TouchableOpacity>
                        <Text style={[styles.profile_name, { alignSelf: 'flex-end', flex: 1, paddingLeft: 7, marginBottom: 10, color: esiColor.itemColor }]}>Hi, {username}</Text>
                    </View>
                  
                }

            </View>
         
            <ScrollView ref={aref} >

                <SafeAreaView style={{ flexDirection: 'column' }}>
                    <View style={{ padding: 10 }}>
                        <ScrollView showsVerticalScrollIndicator={false}>
                        <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.DescColor }}>My Account</Text>
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Dashboard')} >
                                    <FontAwesome name="th-large" size={16} style={{ margin: 11 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Dashboard</Text>
                                </TouchableOpacity>
                            }
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Withdrawals')}>
                                    <FontAwesome name="money" size={17} style={{ margin: 10 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Wallet</Text>
                                </TouchableOpacity>
                            }
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('MyOrders')}>
                                    <FontAwesome name="inbox" size={20} style={{ margin: 11 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Orders</Text>
                                </TouchableOpacity>
                            }
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('MyRides')}>
                                    <MaterialCommunityIcons name="car-multiple" size={20} style={{ margin: 12 }} color='#27b6cc'></MaterialCommunityIcons>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Rides</Text>
                                </TouchableOpacity>}
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('MyServices')}>
                                    <FontAwesome name="cogs" size={20} style={{ margin: 12 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Services</Text>
                                </TouchableOpacity>}

                            {/* {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('FunctionHalls')}>
                                    <MaterialCommunityIcons name="town-hall" size={20} style={{ margin: 12 }} color='#27b6cc'></MaterialCommunityIcons>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>My Events</Text>
                                </TouchableOpacity>} */}
                            <TouchableOpacity style={styles.item_styles} onPress={() => { !isAuthenticated ? toggleModal() : navigation.navigate('GiftCard') }}>
                                <FontAwesome name="gift" size={21} style={{ margin: 11 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Gift Card</Text>
                            </TouchableOpacity>

                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('WishList')} >
                                    <FontAwesome name="heart" size={18} style={{ margin: 11 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Wish List</Text>
                                </TouchableOpacity>
                            }

                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('earn')}>
                                    <FontAwesome name="users" size={18} style={{ margin: 10 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Referrals</Text>
                                </TouchableOpacity>
                            }

                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('UserAddresses')}>
                                    <FontAwesome name="map-marker" size={22} style={{ margin: 14 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Address</Text>
                                </TouchableOpacity>
                            }

                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Notification')}>
                                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <FontAwesome name="bell" size={17} style={{ margin: 13 }} color='#27b6cc'></FontAwesome>
                                        {(notifications > 0) &&
                                            <View
                                                style={{
                                                    position: 'absolute',
                                                    backgroundColor: 'red',
                                                    width: 16,
                                                    height: 16,
                                                    borderRadius: 15 / 2,
                                                    right: 5,
                                                    top: +8,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                <Text
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: "#000000",
                                                        fontSize: 8,
                                                    }}>
                                                    {notifications}
                                                </Text>
                                            </View>
                                        }

                                    </View>


                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Notifications</Text>
                                </TouchableOpacity>
                            }

                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.DescColor }}>Shop By</Text>

                            <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Category')}>
                                <FontAwesome name="sitemap" size={20} style={{ margin: 12 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Categories</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Brands')}>
                                <FontAwesome name="tag" size={20} style={{ margin: 12 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Brands</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('ShopbyStoreType')}>
                                <Fontisto size={19} style={{ margin: 11 }} color='#27b6cc' name="shopping-store" />
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Store Types</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Partners')}>
                                <FontAwesome name="th-large" size={19} style={{ margin: 11 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Stores</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('allCities')}>
                                <FontAwesome name="building" size={19} style={{ margin: 12 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Cities</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('Farmers')}>
                                <FontAwesome name="th-large" size={19} style={{ margin: 12 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Farm Finds</Text>
                            </TouchableOpacity>

                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.DescColor }}>Biddings</Text>

                            <TouchableOpacity style={styles.item_styles} onPress={() => {
                                if (isAuthenticated)
                                    navigation.navigate('BidProducts');
                                else
                                    navigation.navigate('NewBidProducts');
                            }}>
                                <FontAwesome5 name="boxes" size={20} style={{ margin: 12 }} color='#27b6cc'></FontAwesome5>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Products</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('BidServices')}>
                                <FontAwesome name="cogs" size={20} style={{ margin: 12 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Services</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('BidRides')}>
                                <MaterialCommunityIcons size={19} style={{ margin: 11 }} color='#27b6cc' name="car-multiple" />
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Rides</Text>
                            </TouchableOpacity>

                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.DescColor }}>Support</Text>
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('MissingCashback')}>
                                    <FontAwesome name="ticket" size={20} style={{ margin: 10 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Cashback/Rewards</Text>
                                </TouchableOpacity>
                            }
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('Ticket')}>
                                    <FontAwesome name="ticket" size={20} style={{ margin: 14 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Tickets</Text>
                                </TouchableOpacity>
                            }

                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('ContactUs')}>
                                <FontAwesome name="phone-square" size={19} style={{ margin: 13 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Contact Us</Text>
                            </TouchableOpacity>

                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.DescColor }}>About</Text>


                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('PrivacyPolicy')}>
                                <FontAwesome name="file-text" size={19} style={{ margin: 14 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Privacy Policy</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('faq')}>
                                <FontAwesome name="comments-o" size={22} style={{ margin: 12 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>FAQ's</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('TermsAndConditions')}>
                                <FontAwesome name="shield" size={20} style={{ margin: 15 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Terms & Conditions</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('HowCashbackWorks')}>
                                <FontAwesome name="question-circle-o" size={22} style={{ margin: 14 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>How meepaisa works?</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('NewsAndEvents')}>
                                <FontAwesome name="newspaper-o" size={19} style={{ margin: 13 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>News & Events</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={styles.item_styles} onPress={() => props.navigation.navigate('ReviewsAndRatings')}>
                                <FontAwesome name="star" size={20} style={{ margin: 14 }} color='#27b6cc'></FontAwesome>
                                <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Reviews & Ratings</Text>
                            </TouchableOpacity>

                            {

                                (isAuthenticated) &&
                                <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.DescColor }}>Settings</Text>}


                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} disabled={logoutLoading} onPress={_handleSignOut}>
                                    <FontAwesome name="sign-out" size={20} style={{ margin: 14 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Log Out</Text>
                                </TouchableOpacity>
                            }
                            {
                                (isAuthenticated) &&
                                <TouchableOpacity style={styles.item_styles} disabled={logoutLoading} onPress={_handleDelete}>
                                    <FontAwesome name="user-times" size={20} style={{ margin: 14 }} color='#27b6cc'></FontAwesome>
                                    <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Delete Account</Text>
                                </TouchableOpacity>
                            }
                        </ScrollView>
                    </View>

                </SafeAreaView>
            </ScrollView>
            {customModels()}

        </Surface>
    )



}
const styles = StyleSheet.create({
    profile_name: {
        fontSize: 20,
        color: 'white'
    },
    profile_email: {
        fontSize: 14,
        color: 'white'
    },
    item_styles: {
        flexDirection: 'row',
        alignItems: 'center'
    },
});