import React, { useEffect, useMemo, useRef, useState } from "react";
import { View, ScrollView, RefreshControl, FlatList, Dimensions, Platform } from "react-native";
import { Button, IconButton, Menu, Searchbar, Surface, Text } from "react-native-paper";

import { useDispatch, useSelector } from "react-redux";

import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import ProductCard from "../../components/custom/product-card";
import { DrawerActions } from "@react-navigation/native";
import Pagination from "../../components/custom/pagination/pagination";
import { getProductsAction } from "../../state/actions/node-actions/home-actions";
import EsiDropdown from "../../components/custom/EsiDropDown";



export default function AllProducts(props) {
  const esiColor = useSelector(state => state.theme);
  const { navigation } = props;
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

  const [loading, setLoading] = React.useState(false);
  const [allproducts, setAllproducts] = React.useState([]);
  const [pageOld, setPageOld] = React.useState(false);

  const callBackGetData = async (status, data, pagination) => {
    setLoading(false);
    if (status) {
      setAllproducts(data);
      setPageOld(true);
      if (pagination) {
        setPagination(pagination);
      } else {
        setPagination({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
      }
    }
  }

  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });

  const data = props?.route?.params;

  const [testFill, setTestFill] = React.useState({});
  const [wishdata, setWishData] = React.useState(false);

  const [search, setSearch] = React.useState("");
  const [openBusinessType, setOpenBusinessType] = React.useState(false);
  const [businessType, setBusinessType] = React.useState("");

  useEffect(() => {
    if (data?.ptype) {
      setBusinessType(data?.ptype)
    }
    else {
      setBusinessType("")
    }
    setSearch("");
  }, [
    data
  ])

  let filters = useSelector((state) => state.filters);
  useEffect(() => {
    let brands = [];
    let isTopBrand = "";
    let categoryId = '';
    let subcategory = [];
    let partners = [];
    let partnersType = '';
    let discountType = '';
    let esiCashbackType = '';

    filters.brands.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      item.children.filter(child => child.checked === 'checked').map(children => {
        brands.push(children.value);
      })
      isTopBrand = item.value === "Top_Brands" ? "1" : "0";
    })
    if (data?.type === "brand") {
      brands.push(data.id);
    }

    filters.partners.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {

      if (isEmpty(partnersType)) {
        partnersType = item.value
      }

      item.children.filter(child => child.checked === 'checked').map(child => {
        partners.push(child.value);
      })
    })
    if (data?.type === "partner") {
      partners.push(data.id);
    }
    filters.categories.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      categoryId = item.value;
      item.children.filter((item => item.checked === 'checked')).map(item => {
        subcategory.push(item.value);
      })
    })

    if (data?.type === "sub-category") {
      subcategory.push(data.id);
      categoryId = data.cid;
    }

    const priceFilter = [];
    try {
      filters.price.filter(item => item.checked === 'checked')[0]?.value.split('-').map(item => priceFilter.push(item));
    } catch (error) {
    }
    const discountFilter = []
    try {
      filters.discount.filter(item => item.checked === 'checked')[0]?.value.split('-').map(item => discountFilter.push(item));
    } catch (error) {
    }

    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      condition: true
    }


    if (data?.address) {
      formData.Partner_Address_Id = data?.address;
    }

    if (data?.cityName) {
      formData.City_Name = [data?.cityName];
    }

    if (!isEmpty(brands)) {
      formData.Brand_Id = brands
    }
    if (!isEmpty(partners)) {
      formData.Partner_Details_Id = partners
    }

    if (!isEmpty(categoryId)) {
      formData.Category_Id = [categoryId]
    }
    if (!isEmpty(subcategory)) {
      formData.Sub_Category_Id = subcategory
    }

    if (!isEmpty(discountFilter.length > 0 ? discountFilter[0] : '')) {
      formData.Min_Discount = discountFilter[0]
    }
    if (!isEmpty(discountFilter.length > 1 ? discountFilter[1] : '')) {
      formData.Max_Discount = discountFilter[1]
    }
    if (!isEmpty(priceFilter.length > 0 ? priceFilter[0] : '')) {
      formData.Min_Price = priceFilter[0]
    }
    if (!isEmpty(priceFilter.length > 1 ? priceFilter[1] : '')) {
      formData.Max_Price = priceFilter[1]
    }
    if (filters.cashback[0].checked == 'checked') {
      formData.Extra_Cashback = '1';
    }


    if (filters.main[0].children.filter((item) => item.checked === 'checked').length < 5) {
      if (filters.main[0].children.filter((item) => item.value === 'Is_Top_Product')[0].checked === 'checked') {
        formData.Is_Top_Product = '1';
      }
      if (filters.main[0].children.filter((item) => item.value === 'Is_Popular')[0].checked === 'checked') {
        formData.Is_Popular = '1';
      }
      if (filters.main[0].children.filter((item) => item.value === 'Is_Verified')[0].checked === 'checked') {
        formData.Is_Verified = '1';
      }
      if (filters.main[0].children.filter((item) => item.value === 'Whats_Hot')[0].checked === 'checked') {
        formData.Whats_Hot = '1';
      }
      if (filters.main[0].children.filter((item) => item.value === 'Is_Upcoming')[0].checked === 'checked') {
        formData.Is_Upcoming = '1';
      }
    }
    if (partnersType) {
      formData.Partner_Type = partnersType
    }
    if (String(isTopBrand)) {
      formData.Is_Top_Brand = String(isTopBrand);
    }
    if (discountType) {
      formData.Discount_Type = discountType;
    }
    if (esiCashbackType) {
      formData.Esi_Cashback_Type = esiCashbackType;
    }
    if (!isEmpty(discountFilter.length > 0 ? discountFilter[0] : '')) {
      formData.Min_Esi_Cashback = discountFilter[0]
    }
    if (!isEmpty(discountFilter.length > 1 ? discountFilter[1] : '')) {
      formData.Max_Esi_Cashback = discountFilter[1]
    }
    formData.Search = search;
    formData.Business_Type = businessType;
    if (!(JSON.stringify(testFill) === JSON.stringify(formData))) {
      setTestFill(formData);
      setPageOld(false);
      setLoading(true);
      dispatch(getProductsAction(formData, pagination, callBackGetData));
    }

  }, [filters, search, businessType]);

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      if (!isEmpty(testFill)) {
        setLoading(true);
        goToTop();
        dispatch(getProductsAction(testFill, pagination, callBackGetData));
      }
    }
  }, [pagination])

  const checkChildChecked = (data: any) => {
    let count = 0;
    data?.map((item: any) => {
      if (item.checked === "checked") {
        count++
      }
    })
    return (count === data?.length && data?.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
  }

  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  const scroll = useRef(null);
  const goToTop = () => {
    scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
  }
  const handleClick = async (item) => {
    navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata })
  };


  const productShow = useMemo(() => {
    let numColumCount = (dimensions.width / 250).toFixed() == 1 ? 2 : (dimensions.width / 250).toFixed();

    return <FlatList
      // horizontal={true}
      key={dimensions.width}
      columnWrapperStyle={{ justifyContent: "space-around" }}
      numColumns={numColumCount}
      data={allproducts}
      renderItem={({ item, index, separators }) => (
        <ProductCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />
      )}
    />
  }, [allproducts, dimensions]
  )

  // api using redux end
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
      <Header {...props} isFilter={props.isFilter} />
      <View>
        <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
          {navigation.canGoBack() &&
            <IconButton
              icon="arrow-left"
              size={27}
              color={"#27B6CC"}
              style={{ marginLeft: 1 }}
              onPress={() => {
                navigation.goBack();
              }} />
          }
          <Searchbar style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, minWidth: "40%", maxWidth: "40%", borderWidth: 0.5, maxHeight: 30, marginTop: 10 }}
            inputStyle={{ fontSize: 12, marginLeft: -20 }}
            value={search}
            onChangeText={(value) => {
              setSearch(value);
            }}
            placeholderTextColor={esiColor.SPHTextColor}
            iconColor={esiColor.SIconColor}
            theme={{ colors: { text: esiColor.SBTextColor } }}
            multiline={false}
            placeholder="Search"
          />
          {Platform.OS == "web" ? <EsiDropdown label="All" data={[{ label: "All", value: "" }, { label: "FOOD", value: "FOOD" }, { label: "GROCERY", value: "GROCERY" }, { label: "RETAIL", value: "RETAIL" }]}
            onSelect={(item) => {
              setBusinessType(item.value);
            }}
            selected={businessType}
          /> :
            <Menu
              visible={openBusinessType}
              onDismiss={() => setOpenBusinessType(!openBusinessType)}
              anchor={<Button mode="text" style={{ marginTop: 10 }} labelStyle={{ fontSize: 12, textTransform: "capitalize", fontWeight: "bold" }} icon={"store-edit-outline"} color="#27b6cc" onPress={() => setOpenBusinessType(!openBusinessType)}>{businessType == "" ? "All" : businessType.charAt(0).toUpperCase() + businessType.substring(1).toLowerCase()}</Button>}
            >
              <Menu.Item onPress={() => {
                setOpenBusinessType(!openBusinessType);
                setBusinessType("");
              }} title="All" />

              <Menu.Item onPress={() => {
                setBusinessType("FOOD");
                setOpenBusinessType(!openBusinessType);
              }} title={"Food"} />
              <Menu.Item onPress={() => {
                setBusinessType("GROCERY");
                setOpenBusinessType(!openBusinessType);
              }} title={"Grocery"} />
              <Menu.Item onPress={() => {
                setBusinessType("RETAIL");
                setOpenBusinessType(!openBusinessType);
              }} title={"Retail"} />

            </Menu>
          }
          <IconButton
            color={'#27b6cc'}
            icon="filter"
            size={24}
            style={{ marginTop: -45, alignSelf: 'flex-end' }}
            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
          />

        </View>





      </View>
      <ScrollView ref={scroll}
        refreshControl={
          <RefreshControl
            refreshing={loading}
          />
        }
      >
        {
          <View style={{ alignItems: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'space-around' }}>
            {isEmpty(allproducts) ? (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                <Text style={{color: esiColor.NoDataFound}}>No Products found!</Text>
              </View>
            ) : (
              productShow
            )
            }
          </View>}
        <View style={{ marginBottom: 5 }}>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>
      </ScrollView>
    </Surface>

  );
}
