import React, { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Image, Platform, ScrollView, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useDispatch, useSelector } from "react-redux";
import { search_products_new } from "../../state/actions/globalSearch";
import Modal from "react-native-modal";
import { Colors, Surface } from "react-native-paper";
import ESIDealModel from "../../components/custom/deal-modal";
import ESICopyCouponModel from "../../components/custom/copycode-modal";
import { SEARCH_ADD, SEARCH_REMOVE } from "../../state/types";
import { getCouponsAction } from "../../state/actions/node-actions/home-actions";

export default function SearchProduct(props) {
    const { navigation } = props
    const textInput = useRef();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const esiColor = useSelector(state => state.theme);
    const [searchProduts, setSearchProduts] = useState([]);
    let searchHistoryProduts = useSelector((state) => state.gSearch.product);

    const [Dopen, setDOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showResent, setShowResent] = React.useState(false);

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const callBackGetDealData = (status, data) => {
        if (data) {
            navigation.navigate('Main', { screen: 'All', params: { page: 'deals', filter: 'all' } })
            openDModal(data[0])
        }
    }

    const [dealData, setDealData] = React.useState('');
    const openDModal = (data) => {
        setDealData(data)
        setDOpen(true);
    };

    const closeDModal = () => {
        setDOpen(!Dopen);
        setDealData("")
    };

    const closeModal = () => {
        setOpen(!open);
        setCouponData("")
    };

    const callBackGetCouponData = (status, data) => {
        if (data) {
            navigation.navigate('Main', { screen: 'All', params: { page: 'coupons', filter: 'all' } })
            openModal(data[0])
        }
    }

    const [couponData, setCouponData] = React.useState('');
    const openModal = (data) => {
        setCouponData(data)
        setOpen(true);
    };

    const [searchResult, setSearchResult] = useState([]);
    const onSearch = async (text) => {
        setSearchText(text);
    }

    const responseData = (data) => {
        setSearchProduts(data);
    }

    const addToSearchHistory = (item) => {
        let flag = false;
        for (let i = 0; i < searchHistoryProduts.length; i++) {
            if (searchHistoryProduts[i].id == item.id) {
                flag = true;
                break;
            }
        }
        if (!flag)
            dispatch({ type: SEARCH_ADD, product: item });
        setSearchResult([]);
    }

    const [seconds, setSeconds] = React.useState(Platform.OS === "web" ? 0 : 0);
    useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const gotoPage = (item) => {
        if (Platform.OS === "web") {
            setSearchProduts([]);
        }
        setSearchText("");

        switch (item.type) {
            case 'product':
                addToSearchHistory(item);
                props.navigation.navigate('ProductDetails', { Product_Id: item?.id, Partner_Details_Id: item?.Partner_Details_Id })
                break;
            case 'deal':
                addToSearchHistory(item);
                dispatch(getDealsAction({
                    Records_Filter: "FILTER",
                    Deal_Id: item?.id
                }, { PageNo: 1, PageSize: 20, SortBy: "DEAL_ID", SortOrder: "DESC" }, callBackGetDealData));
                break;
            case 'brand':
                addToSearchHistory(item);
                props.navigation.navigate('BrandDetails', { Brand_Id: item?.id })
                break;
            case 'coupon':
                addToSearchHistory(item);
                dispatch(getCouponsAction({
                    Records_Filter: "FILTER",
                    Coupon_Id: item?.id
                }, { PageNo: 1, PageSize: 20, SortBy: "COUPON_ID", SortOrder: "DESC" }, callBackGetCouponData));
                break;
            case 'partner':
                addToSearchHistory(item);
                props.navigation.navigate('PartnerDetails', { Partner_Details_Id: item?.id })
                break;
            default:
                break;
        }


    }

    const removeHistory = (id) => {
        dispatch({ type: SEARCH_REMOVE, id })
    }

    return (
        <Surface style={{ flex: 1 , backgroundColor: esiColor.BackgroundColor}}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: Platform.OS == "web" ? 0 : 40, backgroundColor: esiColor.BackgroundColor, paddingVertical: 10, maxHeight: 70, }}>
                {navigation.canGoBack() &&
                    <TouchableOpacity style={{ marginLeft: 5 }} onPress={() => { props.navigation.goBack(); setSearchText("") }}>
                        <FontAwesome5 name='angle-left' color={'#27b6cc'} size={30}></FontAwesome5>
                    </TouchableOpacity>
                }
                <View style={{
                    backgroundColor: esiColor.CBColor,
                    borderRadius: 15,
                    marginHorizontal: 15,
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingVertical: Platform.OS == 'android' ? 0 : 5,
                    flex: 1, borderColor: esiColor.SBorderColor, 
                }}>
                    <EvilIcons color='#27b6cc' name="search" size={25} style={{ margin: 2 }}></EvilIcons>
                    <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                        ref={textInput}
                        placeholderTextColor={esiColor.SPHTextColor}
                        value={searchText}
                        autoFocus={true}
                        onChangeText={(text) => {
                            setShowResent(false);
                            onSearch(text);
                            var formData = {
                                "name": searchText,
                            }
                            dispatch(search_products_new(formData, responseData));
                        }}
                        // onFocus={() => {
                        //     setShowResent(false);
                        //     if (!searchText) {
                        //         setSearchProduts([]);
                        //         var formData = {
                        //             "name": searchText || "",
                        //         }
                        //         dispatch(search_products_new(formData, responseData));
                        //     }
                        // }}
                        style={Platform.OS === "web" ? { fontSize: 14, flex: 0.9, paddingVertical: 5, outlineStyle: 'none', color: esiColor.Text } : { fontSize: 14, flex: 0.9, paddingVertical: 5,color: esiColor.Text }}
                      
                        multiline={false}
                        placeholder="Search for Products, Brands and More" />
                </View>
            </View>
            {!showResent &&
                (searchProduts?.length > 0 ?
                    <ScrollView style={{
                        position: 'absolute', backgroundColor: esiColor.CBColor, marginHorizontal: 36, width: Platform.OS === "ios" ? "87%" : Platform.OS === "web" ? "86%" : "85%", paddingHorizontal: "5%", marginTop: Platform.OS == 'web' ? 52 : 85, borderColor: 'gray', borderWidth: 1, borderBottomStartRadius: 10, borderBottomEndRadius: 10,
                        // shadowColor: '#000',
                        // shadowOffset: {
                        //     width: 0,
                        //     height: 1,
                        // },
                        shadowOpacity: 5,
                        shadowRadius: 5,
                        marginBottom: 10
                    }}>
                        {seconds <= 0 && searchProduts?.map((item, index) => {
                            return (
                                <TouchableOpacity onPress={() => { gotoPage(item) }} key={index} style={{ paddingHorizontal: 10, paddingVertical: 10, borderBottomWidth: 1, borderColor: esiColor.SBorderColor, flexDirection: 'row' }}>
                                    <View style={{ flexDirection: 'column', flex: 1 }}>
                                        <Text style={{color: esiColor.itemColor}} >{item.name} {item?.type == "product" && `(${item?.Partner_Name})`}</Text>
                                        <Text style={{ fontSize: 12, color: esiColor.DescColor }}>{item.type}</Text>
                                    </View>
                                    <View>
                                        <Image source={{ cache: "force-cache", uri: item.image_path }} style={{ width: 30, height: 30, resizeMode: 'contain' }} />
                                    </View>
                                </TouchableOpacity>
                            )
                        })}
                        {seconds <= 0 && searchProduts.length >= 5 && searchText != "" ?
                            <TouchableOpacity onPress={() => { navigation.navigate('SearchResults', { searchValue: searchText }) }}>
                                <Text style={{ marginVertical: 10, marginLeft: 10, color: esiColor.DescColor }} >
                                    View All
                                </Text>
                            </TouchableOpacity>
                            : null}



                    </ScrollView>
                    :
                    <TouchableOpacity>
                        {seconds > 0 ? <ActivityIndicator color='#27b6cc' /> :
                            <Text style={{ marginLeft: "40%", color: esiColor.NoDataFound }}>
                                No Data found.
                            </Text>}
                    </TouchableOpacity>)

            }


            <TouchableWithoutFeedback onPress={() => setShowResent(true)}>
                <View style={{ zIndex: -1, flex: 1 }}>
                    <View style={{ flexDirection: 'row', marginTop: 10 }}>
                        {searchHistoryProduts?.length > 0 &&
                            <>
                                <Text style={{ fontSize: 16, marginLeft: 10, flex: 1, color: esiColor.itemColor }}>Recent Searches</Text>
                                <TouchableOpacity onPress={() => removeHistory('all')} style={{ marginRight: 10 }}>
                                    <Text style={{ textDecorationLine: 'underline', color: esiColor.itemColor }}>Remove All</Text>
                                </TouchableOpacity>
                            </>
                        }
                    </View>
                    <View style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
                        {searchHistoryProduts?.map((item, index) => {
                            return (
                                <View key={`${index}`} style={{
                                    backgroundColor: 'white',
                                    marginHorizontal: 10,
                                    marginVertical: 5,
                                    padding: 5,
                                    paddingHorizontal: 10,
                                    borderRadius: 10,
                                    flexDirection: 'row',
                                }}>
                                    <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => gotoPage(item)}>
                                        <Image source={{ cache: "force-cache", uri: item.image_path }} style={{ width: 50, height: 50, resizeMode: 'contain' }} />
                                        <View style={{ marginLeft: 10, justifyContent: 'center' }}>
                                            <Text style={[{
                                                fontSize: 14,
                                                color: esiColor.itemColor
                                            }]}>{item?.name.slice(0, 12)} {item?.type == "product" && `(${item?.Partner_Name})`}</Text>
                                            <Text style={{ fontSize: 12, color: esiColor.itemColor }}>{item?.type}</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableWithoutFeedback onPress={() => removeHistory(item?.id)} >
                                        <Text style={{ paddingLeft: 10, color: esiColor.itemColor }}>X</Text>
                                    </TouchableWithoutFeedback>
                                </View>
                            )
                        })}
                    </View>
                </View>
            </TouchableWithoutFeedback>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={Dopen}>
                <View style={{ flex: 1, minWidth: dimensions.width <= 400 ? 350 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 430, borderRadius: 10, backgroundColor: Colors.white, }}>
                    <ESIDealModel navigation={navigation} openModal={openDModal} data={dealData} closeModal={closeDModal} />
                </View >
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={open}>
                <View style={{ flex: 1, maxWidth: dimensions.width <= 400 ? 340 : dimensions.width <= 400 ? 200 : 400, minWidth: dimensions.width <= 500 ? 400 : dimensions.width <= 500 ? 200 : 400, borderRadius: 10, backgroundColor: Colors.white }}>
                    <ESICopyCouponModel navigation={navigation} openModal={openModal} data={couponData} closeModal={closeModal} />
                </View >
            </Modal>

        </Surface>
    )
}


