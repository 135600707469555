import { useFocusEffect } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, FlatList, Image, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown"
import EsiTextAreaInput from "../../../components/custom/TextAreaInput/textareainput";
import { KeyboardAvoidingView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { addGiftCardAction, getAllVendorsAction, getGiftsImageAction } from "../../../state/actions/giftActions";
import Header from "../../header";
import { HelperText, Surface, TextInput } from "react-native-paper";
import useRazorpay from "react-razorpay";
import razorpayPay from "../../../hooks/razorpay";
import { CountryCode } from "../../../../utils/countrycode";
import isEmpty from "../../../state/validations/is-empty";
import { useToast } from "../../../self_modules/react-native-paper-toast/src";
import { getUserSettings } from "../../../state/actions/walletActions";
import ESITextInput from "../../../components/custom-fields/textInput";
export default function BuyGift(props) {
    const { navigation, textareavalueUpdate, ...rest } = props;
    const user = useSelector(state => state?.auth?.user);
    const getgifts = useSelector(state => state.BuyGift?.gifts?.all);
    const getgiftimages = useSelector(state => state.BuyGift?.giftImages?.all);
    const giftsCreate = useSelector(state => state.BuyGift?.giftsCreate?.loadingSend);
    const esiColor = useSelector(state => state.theme);
    const toast = useToast();
    const dispatch = useDispatch();
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState();
    const [phone, setPhone] = useState("");
    const [vender, setVender] = useState("");
    const [selectedItem, setSelectedItem] = useState(-1);
    const [selectedVendor, setSelectedVendor] = useState("");
    const [selectedVoucher, setSelectedVoucher] = useState(-1);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [custom_amount, setAmount] = useState('500');
    const [purpose, setPurpose] = useState(null);
    const [loadingSend, setLoadingSend] = useState(false);
    const [error_list, setErrorList] = useState({});
    const [vendorname, setvendorname] = useState({});
    const EMAIL_VALIDATE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const items = [
        {
            value: 'Birthday',
            label: 'Birthday'
        },
        {
            value: 'Anniversary',
            label: 'Anniversary'
        },
        {
            value: 'Festival',
            label: 'Festival'
        },
        {
            value: 'Occasion',
            label: 'Occasion'
        }
    ];

    const gifts = [
        {
            Vendor_Value: '',
            Vendor_Name: "Select Item",
            id: 0

        },
    ]
    const amount = [{ id: 0, value: '500' }, { id: 1, value: '1000' }, { id: 2, value: '1500' }
        , { id: 3, value: '2000' }, { id: 4, value: '3000' }]
    useFocusEffect(
        React.useCallback(() => {
            init();
        }, [])
    );

    const [minGiftAmount, setMinGiftAmount] = React.useState(500);

    const updateStatusLimit = (data) => {
        setMinGiftAmount(data);
    }
    useEffect(() => {

        init();
        dispatch(getUserSettings("Minimum_Gift_Card_Amount", updateStatusLimit));

        setLoading(true);
        var filter = {
            Records_Filter: "ALL",
        }
        dispatch(getAllVendorsAction(filter))
            .then(async res => {
                if (res?.Success_Response?.Is_Data_Exist == '0') {
                    setVendorList({ label: "No Data Found", value: "", Vendor_Id: "" })
                } else {
                    let getgifts = res?.Gift_Voucher_Vendor;
                    getgifts?.map((item, index) => {
                        setVendorList(getgifts => [...getgifts, { label: item?.Vendor_Name, value: item?.Vendor_Name, Vendor_Id: item?.Vendor_Id, id: index }])
                    })
                }
            })
            .catch(err => {
                setLoading(false);
                toast.show({ message: 'Network connection issue..', type: 'info', duration: 3000 });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])
    const init = () => {
        setErrorList({});
        setAmount('500')
        setSelectedTab(0);
        setSelectedVoucher(-1);
        setSelectedItem(-1);
        setSelectedVendor("");
        setSelectedAmount(0);
        setMessage("");
        setEmail("");
        setName("");
        setPhone("");
        setVender("")
        setPurpose(null);
        setvendorname("");
    }

    useEffect(() => {
        if (selectedVendor !== -1) {
            let type = selectedTab == 0 ? 'Card' : 'Voucher';
            let filter = {
                Records_Filter: "FILTER",
                Vendor_Id: selectedVendor,
                Occassion: purpose,
                Type: type
            }
            setSelectedItem(-1);
            setSelectedVoucher(-1);
            dispatch(getGiftsImageAction(filter))
                .then(async res => {
                    if (res?.Success_Response?.Is_Data_Exist == '1') {
                        // let image_gift = res?.Image_Gift;
                        getgiftimages?.map((item) => {
                            if (item?.Type == 'Card') {
                                setGiftCard(getgiftimages => [...getgiftimages, item]);
                            } else {
                                setGiftVoucher(getgiftimages => [...getgiftimages, item]);
                            }
                        })
                    }
                })
                .catch(err => {
                    toast.show({ message: 'Network connection issue.', type: 'info', duration: 3000 });
                })
        } else {
            setGiftCard([]);
            setGiftVoucher([]);
            setSelectedItem(-1);
            setSelectedVoucher(-1);
        }
    }, [selectedVendor, purpose, selectedTab])

    const SendGift = () => {
        let errorList = {};
        let error = false;
        if (custom_amount == '') {
            toast.show({ message: 'Please enter amount.', type: 'info', duration: 3000 });
            setErrorList({
                custom_amount: true
            })
            return;
        }
        if (custom_amount != '' && parseFloat(custom_amount) < minGiftAmount) {
            toast.show({ message: 'The amount must be equal or greater than ' + minGiftAmount + '.', type: 'info', duration: 3000 });
            return;
        }
        if (!selectedVendor) {
            errorList = { ...errorList, vender: "Please select vendor" };
            error = true;
        }
        if (!purpose) {
            errorList = { ...errorList, purpose: "Please select purpose" };
            error = true;
        }
        if ((selectedItem == -1 && selectedTab == 0) || (selectedVoucher == -1 && selectedTab != 0)) {
            errorList = { ...errorList, card: "Please select card or voucher." };
            error = true;
        }
        if (message == "") {
            errorList = { ...errorList, message: "Message is required." };
            error = true;
        }
        if (name == "") {
            errorList = { ...errorList, name: "Name is required." };
            error = true;
        }

        if (phone == "") {
            errorList = { ...errorList, phone: 'Mobile Number is required.' };
            error = true;
        }
        else if (phone.split(" ").length <= 1) {
            errorList = { ...errorList, phone: 'Mobile Number is required.' };
            error = true;
        }
        else if (phone.split(" ")[1].length <= 0) {
            errorList = { ...errorList, phone: 'Mobile Number is required.' };
            error = true;
        }
        else {
            let phoneSplit = phone.split(" ");
            if (phoneSplit[0] == "+91" && (phoneSplit[1].length < 10 || phoneSplit[1].length > 10)) {
                errorList = { ...errorList, phone: "Please provide a valid Mobile Number." };
                error = true;
            }
        }



        if (email == "") {
            errorList = { ...errorList, email: 'Email Address is required.' };
            error = true;
        }
        else if (!EMAIL_VALIDATE.test((email).toLowerCase())) {
            errorList = { ...errorList, email: 'Email must be a valid email.' };
            error = true;
        }
        // else if (email == user?.Email_Id) {
        //     errorList = { ...errorList, email: "Please enter another email." };
        //     error = true;
        // }
        if (error) {
            setErrorList(errorList);
            return;
        } else {
            setErrorList({});
        }
        setLoadingSend(true);
        let voucher_name = "";
        let type = "";
        let image_id = "";
        if (selectedTab == 0) {
            voucher_name = getgifts[selectedVendor];
            type = getgiftimages[selectedItem]?.Type;
            image_id = getgiftimages[selectedItem]?.Gift_Image_Id;
        } else {
            voucher_name = getgifts[selectedVendor];
            type = getgiftimages[selectedVoucher]?.Type;
            image_id = getgiftimages[selectedVoucher]?.Gift_Image_Id;
        }
        razorpayPay(RazorPay, { user: user, amount: custom_amount }, (status, response) => {
            if (status) {
                var data = {
                    "Gift_Message": message,
                    "Order_By_Email_Id": user?.Email_Id,
                    "Order_By_Name": user?.User_Name,
                    "Order_To_Email_Id": email,
                    "Order_To_Moblie": phone,
                    "Is_Approved": "1",
                    "Order_To_Name": name,
                    "Total_Value": custom_amount,
                    "Type": type,
                    "Voucher_Name": vendorname,
                    "Gift_Image_Id": image_id,
                    "Gift_Card_Vendor_Id": selectedVendor,
                    "Currency": "INR",
                    "Payment_Code": "200",
                    "Payment_Method": "Online",
                    "Payment_Status": "Success",
                    "Purpose": purpose,
                    "Transaction_Id": response.razorpay_payment_id,
                    "Transaction_Type": "RAZORPAY",
                }
                dispatch(addGiftCardAction(data))
                    .then(async res => {
                        toast.show({ message: 'Gift created successfully', type: 'info', duration: 3000, position: 'top' });
                        setAmount('500')
                        setSelectedTab(0);
                        setSelectedAmount(0);
                        setMessage("");
                        setEmail("");
                        setName("");
                        setPhone("");
                        setVender("");
                        navigation.navigate('GiftCard')
                    })
                    .catch(err => {
                        toast.show({ message: 'Network connection issue.', type: 'info', duration: 3000 });
                    })
                    .finally(() => {
                        setLoadingSend(false);
                    })
            } else {

            }
            setLoadingSend(false);
        });

    }
    const GiftCard = ({ item, index, data }) => {
        if (index % 3 != 0) {
            return null;
        }

        return (
            <View style={{ flexDirection: 'column' }}>
                <View style={{ marginHorizontal: 5, marginVertical: 5 }}>
                    <TouchableWithoutFeedback onPress={() => setSelectedItem(index)}>
                        <View style={[styles.gift_item, selectedItem == index && styles.selectedItem]}>
                            <Image source={{ cache: "force-cache", uri: item?.Image_URL }} style={{ width: 100, height: 100 }} />
                        </View>
                    </TouchableWithoutFeedback>
                </View>
                {data[index + 1] &&
                    <View style={{ marginHorizontal: 5, marginVertical: 5 }}>
                        <TouchableWithoutFeedback onPress={() => setSelectedItem(index + 1)}>
                            <View style={[styles.gift_item, selectedItem == index + 1 && styles.selectedItem]}>
                                <Image source={{ cache: "force-cache", uri: data[index + 2]?.Image_URL }} style={{ width: 100, height: 100 }} />
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                }
                {data[index + 2] &&
                    <View style={{ marginHorizontal: 5, marginVertical: 5 }}>
                        <TouchableWithoutFeedback onPress={() => setSelectedItem(index + 2)}>
                            <View style={[styles.gift_item, selectedItem == index + 2 && styles.selectedItem]}>
                                <Image source={{ cache: "force-cache", uri: data[index + 2]?.Image_URL }} style={{ width: 100, height: 100 }} />
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                }
            </View>
        )
    }
    const GiftVoucher = ({ item, index, data }) => {
        if (index % 3 != 0) {
            return null;
        }
        return (
            <View style={{ flexDirection: 'column' }}>
                <View style={{ marginHorizontal: 5, marginVertical: 5 }}>
                    <TouchableWithoutFeedback onPress={() => setSelectedVoucher(index)}>
                        <View style={[styles.gift_item, selectedVoucher == index && styles.selectedItem]}>
                            <Image source={{ cache: "force-cache", uri: item?.Image_URL }} style={{ width: 100, height: 100 }} />
                        </View>
                    </TouchableWithoutFeedback>
                </View>
                {data[index + 1] &&
                    <View style={{ marginHorizontal: 5, marginVertical: 5 }}>
                        <TouchableWithoutFeedback onPress={() => setSelectedVoucher(index + 1)}>
                            <View style={[styles.gift_item, selectedVoucher == index + 1 && styles.selectedItem]}>
                                <Image source={{ cache: "force-cache", uri: data[index + 2]?.Image_URL }} style={{ width: 100, height: 100 }} />
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                }
                {data[index + 2] &&
                    <View style={{ marginHorizontal: 5, marginVertical: 5 }}>
                        <TouchableWithoutFeedback onPress={() => setSelectedVoucher(index + 2)}>
                            <View style={[styles.gift_item, selectedVoucher == index + 2 && styles.selectedItem]}>
                                <Image source={{ cache: "force-cache", uri: data[index + 2]?.Image_URL }} style={{ width: 100, height: 100 }} />
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                }
            </View>
        )
    }

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const renderForm = () => {
        return (
            <ScrollView showsVerticalScrollIndicator={false} style={{ minHeight: dimensions.height - 100 }}>
                <TouchableWithoutFeedback >
                    <View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 20, paddingTop: 20 }}>
                            <TouchableOpacity style={{ marginLeft: -5 }} onPress={() => navigation.navigate('GiftCard')} >
                                <Ionicons name="arrow-back" size={30} color="#27b6cc" />
                            </TouchableOpacity>
                            <View>
                                <FlatList
                                    showsHorizontalScrollIndicator={false}
                                    horizontal={true}
                                    scroll
                                    overScrollMode="auto"
                                    data={amount}
                                    renderItem={({ item, index }) => <TouchableWithoutFeedback onPress={() => {
                                        setSelectedAmount(index);
                                        setAmount(amount[index]?.value)
                                    }} >
                                        <View style={[{
                                            padding: 3,
                                            paddingHorizontal: 8,
                                            borderRadius: 5,
                                            borderColor: "#27b6cc",
                                            borderWidth: 1,
                                            flexDirection: 'row',
                                            marginHorizontal: 10,
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            justifyContent: 'center'
                                        }, index == selectedAmount && styles.seletectAmountItem]}>
                                            <Text style={[{ fontSize: 16, color: 'black', fontWeight: 'bold' }, index == selectedAmount && styles.seletectAmount]}>{item.value}</Text>
                                        </View>
                                    </TouchableWithoutFeedback>}
                                    keyExtractor={item => item.id}
                                />
                            </View>
                        </View>
                        <View style={{ padding: 10, marginTop: -30 }}>
                            <View style={{ flexDirection: 'column', flex: 1, paddingVertical: 5 }}>
                                <ESITextInput
                                    value={custom_amount}
                                    keyboardType="numeric"
                                    placeholder="Enter amount"
                                    multiline={false}
                                    onFocus={() => {
                                        if (parseFloat(custom_amount) > 0) {
                                            setSelectedAmount(-1)
                                        }
                                    }}
                                    onChangeText={(text) => {
                                        const parsedQty = Number.parseInt(text)
                                        if (Number.isNaN(parsedQty)) {
                                            setAmount("") //setter for state
                                        } else if (parsedQty > 20000) {
                                            setAmount("20000")
                                        } else {
                                            setAmount(text)
                                        }
                                    }}
                                />
                            </View>
                        </View>
                        <View style={{ width: '95%', flexGrow: 1, margin: 10, borderWidth: 1, borderRadius: 2 }}>
                            <Text style={[{
                                position: 'absolute',
                                backgroundColor: 'white',
                                color: 'black',
                                left: 22,
                                top: -10,
                                zIndex: 999,
                                paddingHorizontal: 8,
                                fontSize: 14,
                            }, Boolean(error_list?.vender && vender === "") && { color: '#b00020' }]}>
                                Vendor Name
                            </Text>
                            <EsiSearchDropdown
                                style={[styles.dropdown, Boolean(error_list?.vender && vender === "") && { borderColor: '#b00020' }]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                data={getgifts?.length > 0 ? getgifts : gifts}
                                maxHeight={180}
                                labelField="Vendor_Name"
                                valueField="Vendor_Id"
                                dropdownPosition="bottom"
                                value={selectedVendor}
                                name="Vendor_Name"
                                onFocus={() => setTypeDropDown(true)}
                                onBlur={() => setTypeDropDown(false)}
                                placeholder="Select Item"
                                onUpdateValue={item => {
                                    if (getgifts?.length > 0) {
                                        setSelectedVendor(item?.Vendor_Id)
                                        setVender(item?.Vendor_Id)
                                        setvendorname(item?.Vendor_Name)
                                    }
                                }}
                            />

                        </View>
                        <HelperText>
                            {(error_list?.vender) && vender === "" &&
                                <Text style={{ color: 'red', paddingLeft: 10 }}>{error_list?.vender}</Text>
                            }
                        </HelperText>
                        <View style={{ width: '95%', flexGrow: 1, margin: 10, borderWidth: 1, borderRadius: 2 }}>
                            <Text style={[{
                                position: 'absolute',
                                backgroundColor: 'white',
                                color: 'black',
                                left: 22,
                                top: -10,
                                zIndex: 999,
                                paddingHorizontal: 8,
                                fontSize: 14,
                            }, Boolean(error_list?.purpose && !purpose) && { color: '#b00020' }]}>
                                Purpose
                            </Text>
                            <EsiSearchDropdown
                                style={[styles.dropdown, Boolean(error_list?.purpose && !purpose) && { borderColor: '#b00020' }]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                data={items}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                dropdownPosition="bottom"
                                placeholder="Select Item"
                                value={purpose}
                                name="purpose"
                                color={"#27b6cc"}
                                onFocus={() => setTypeDropDown(true)}
                                onBlur={() => setTypeDropDown(false)}
                                onUpdateValue={item => {
                                    setPurpose(item?.value)
                                }}
                            />

                        </View>
                        {(error_list?.purpose) && !purpose &&
                            <Text style={{ color: 'red', paddingLeft: 10 }}>{error_list?.purpose}</Text>
                        }
                        <View style={{ padding: 10, zIndex: -1 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <TouchableWithoutFeedback onPress={() => setSelectedTab(0)}>
                                    <Text style={[{
                                        paddingVertical: 5,
                                        paddingHorizontal: 10,
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                        fontSize: 20,
                                        flex: 1,
                                        //   backgroundColor:'white',
                                        color: esiColor.Text
                                    }, selectedTab == 0 && styles.selectedTab]}>Card</Text>
                                </TouchableWithoutFeedback>
                                <TouchableWithoutFeedback onPress={() => setSelectedTab(1)}>
                                    <Text style={[{
                                        paddingVertical: 5,
                                        paddingHorizontal: 10,
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                        fontSize: 20,
                                        flex: 1,
                                        color: esiColor.Text
                                    }, selectedTab == 1 && styles.selectedTab]}>Voucher</Text>
                                </TouchableWithoutFeedback>
                            </View>
                            <View style={{ borderWidth: 1, borderColor: esiColor.TextHighlight }}>
                                {selectedTab == 0 ?
                                    !isEmpty(selectedVendor) && getgiftimages?.length > 0 ?
                                        <FlatList
                                            showsHorizontalScrollIndicator={false}
                                            horizontal
                                            data={getgiftimages}
                                            renderItem={({ item, index }) => <GiftCard item={item} index={index} data={getgiftimages}></GiftCard>}
                                            keyExtractor={(item, index) => index + item?.Vendor_Id}
                                        />
                                        :
                                        <View style={{ height: 150, justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ color: esiColor.Text }} >No Gift Image Data found.</Text>
                                        </View>
                                    :
                                    !isEmpty(selectedVendor) && getgiftimages?.length > 0 ?
                                        <FlatList
                                            showsHorizontalScrollIndicator={false}
                                            horizontal
                                            data={getgiftimages}
                                            renderItem={({ item, index }) => <GiftVoucher item={item} index={index} data={getgiftimages}></GiftVoucher>}
                                            keyExtractor={(item, index) => item.Vendor_Id + index}
                                        />
                                        :
                                        <View style={{ height: 150, justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ color: esiColor.Text }} >No Gift Image Data found.</Text>
                                        </View>
                                }
                            </View>

                        </View>
                        <HelperText>
                            {(error_list?.card) && ((selectedItem == -1 && selectedTab == 0) || (selectedVoucher == -1) && (selectedTab != 0)) &&
                                <Text style={{ color: 'red' }}>{error_list?.card}</Text>
                            }
                        </HelperText>
                        <View style={{ borderWidth: 1, marginHorizontal: 10 }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.Text }}>Message:</Text>
                            <EsiTextAreaInput
                                error={Boolean(error_list.message && message === "")}
                                mode="outlined"
                                value={message}
                                textareavalueUpdate={value => {
                                    const emojiRegex = /[\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
                                    // Remove emojis from the input text
                                    const filteredText = value.replace(emojiRegex, '');
                                    setMessage(filteredText);
                                }}
                                placeholder={'I thought you would like this gift!'}
                                underlineColorAndroid={'transparent'}

                            />

                        </View>
                        <HelperText>
                            {(error_list?.message) && message === "" &&
                                <Text style={{ color: 'red' }}>{error_list?.message}</Text>
                            }
                        </HelperText>
                        <View style={{ padding: 10, paddingTop: 10, }}>
                            <View style={{ flexDirection: 'column', paddingVertical: -3 }}>
                                <ESITextInput
                                    error={Boolean(error_list.name && name === "")}
                                    left={<TextInput.Icon theme={{
                                        colors: {
                                            primary: esiColor.TextHighlight,
                                            text: esiColor.Text,
                                            placeholder: esiColor.TextPlaceholder
                                        }
                                    }} name="account" />}
                                    label='Name'
                                    value={name}
                                    mode='outlined'
                                    onChangeText={value => setName(value)}
                                />

                            </View>
                            <HelperText>
                                {(error_list?.name) && name === "" &&
                                    <Text style={{ color: 'red' }}>{error_list?.name}</Text>
                                }
                            </HelperText>
                            <View style={{ flexDirection: 'column', marginTop: 10, flex: 1, paddingVertical: -3 }}>
                                <ESITextInput
                                    error={Boolean(error_list.email && ((email == "") || (!EMAIL_VALIDATE.test((email).toLowerCase())) || (email == user?.Email_Id)))}
                                    left={<TextInput.Icon theme={{
                                        colors: {
                                            primary: esiColor.TextHighlight,
                                            text: esiColor.Text,
                                            placeholder: esiColor.TextPlaceholder
                                        }
                                    }} name="email" />}
                                    label='Email Address'
                                    value={email}
                                    mode='outlined'
                                    onChangeText={email => setEmail(email)}
                                    type='email'
                                />

                            </View>
                            <HelperText>
                                {(error_list?.email) && ((email == "") || (!EMAIL_VALIDATE.test((email).toLowerCase())) || (email == user?.Email_Id)) &&
                                    <Text style={{ color: 'red' }}>{error_list?.email}</Text>
                                }
                            </HelperText>
                        </View>
                        <View style={{ padding: 10, paddingTop: 0 }}>
                            <View style={{ flexDirection: 'column', borderRadius: 5, flex: 1, paddingVertical: 5, }}>
                                <ESITextInput
                                    style={{ width: "100%" }}
                                    label="Mobile Number"
                                    mode='outlined'
                                    onChangeText={(value) => {
                                        setPhone("+91 " + value)
                                    }}
                                    value={phone?.split(" ")[1]}
                                    left={<TextInput.Affix theme={{
                                        colors: {
                                            primary: esiColor.TextHighlight,
                                            text: esiColor.Text,
                                            placeholder: esiColor.TextPlaceholder
                                        }
                                    }} text="+91" />}
                                    error={Boolean(error_list?.phone)}
                                />
                                <HelperText type="error" visible={Boolean(error_list?.phone)}>
                                    {error_list?.phone}
                                </HelperText>
                            </View>

                        </View>

                        <View style={{ padding: 10 }}>
                            <View
                                style={[styles.send_style, { alignItems: 'center', justifyContent: 'center' }]}
                            >
                                <TouchableOpacity disabled={giftsCreate} style={{ height: 35, flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                                    if (!loadingSend)
                                        SendGift()
                                }}>
                                    {loadingSend ?
                                        <ActivityIndicator color='#27b6cc'
                                            size="large"
                                            style={{ paddingLeft: 5 }}
                                        />
                                        :
                                        <Text style={{ fontSize: 20, backgroundColor: '#27b6cc', flex: 1, textAlign: 'center', color: 'white' }}>
                                            Send
                                        </Text>
                                    }
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </ScrollView>
        )
    }

    return (
        <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ marginBottom: Platform.OS === 'web' ? null : 120, position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null }}>
            <Header {...props} />
            <ScrollView>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    </View>
                </View>

                {loading ? <ActivityIndicator color='#27b6cc' style={{ padding: 10 }} />
                    : Platform.OS == "ios" ?
                        <KeyboardAvoidingView behavior="padding">
                            {renderForm()}
                        </KeyboardAvoidingView> : renderForm()
                }
            </ScrollView>
        </Surface>
    );
}
const styles = StyleSheet.create({
    textareaContainer: {
        height: 170,
        padding: 5,
        backgroundColor: 'white',
        borderRadius: 10,
        borderColor: '#27b6cc',
        borderWidth: 1
    },
    textarea: {
        textAlignVertical: 'top',  // hack android
        height: 160,
        fontSize: 16,
        borderColor: '#27b6cc',
        backgroundColor: 'white'
    },
    send_style: {
        marginBottom: 20,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        flexDirection: 'row',
        alignItems: 'center',
        shadowColor: '#27b6cc',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
        elevation: 5,
        backgroundColor: '#27b6cc'
    },
    gift_item: {
        padding: 5,
        backgroundColor: 'white',
        borderRadius: 10,
        borderColor: '#27b6cc',
        marginHorizontal: 5,
        shadowColor: '#27b6cc',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 10,
        elevation: 10,
    },
    selectedItem: {
        borderWidth: 2
    },
    seletectAmountItem: {
        backgroundColor: '#27b6cc',
    },
    seletectAmount: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    selectedTab: {
        backgroundColor: '#27b6cc',
        color: 'white',
    },
    placeholderStyle: {
        fontSize: 16,
    },
    dropdown: {
        height: 58,
        // borderColor: 'gray',
        // borderWidth: 0.5,
        // borderRadius: 5,
        paddingHorizontal: 8,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});
