import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, Dimensions, TouchableOpacity, Text, RefreshControl } from 'react-native';
import Header from '../header';
import { createupi, getWalletHistory, getWallet, createWallet, getWalletByUser, getUserSettings } from '../../state/actions/walletActions';
import isEmpty from '../../state/validations/is-empty';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import ESITextInput from '../../components/custom-fields/textInput';




const Withdrawals = (props: any) => {
  const windowWidth = Dimensions.get('window').width;
  const { navigation } = props;
  const wallets = useSelector(state => state.wallet.walletHistory.wallet);
  const EmailID = useSelector(state => state.auth.user.Email_Id);
  const esiColor = useSelector(state => state.theme);

  //toast for popup after successful API calls.
  const toast = useToast();

  // isSubmitted contains the data from create API using state call
  const isSubmited = useSelector(state => state.wallet.walletCreate.isLoading);

  let formData = {};
  const dispatch = useDispatch();
  const [disbleWithdrow, setdisbleWithdrow] = React.useState(true);

  const [wallet, setWallet] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const updateStatesWallet = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data));
    setWallet(add?.Wallet[0]);
    setLoading(false);
  };


  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);


  const [withdrowLimit, setWithdrowLimit] = React.useState(250);

  const updateStatusLimit = (data) => {
    setWithdrowLimit(data);
  }

  const updateWithdrowStatus = (data) => {
    setdisbleWithdrow(!("true" == data));
  }

  //API dispatch
  useEffect(() => {
    if (!isEmpty(EmailID)) {
      let data = {
        User_Id: EmailID,
        Wallet_Id: "",
      };
      setLoading(true);
      dispatch(getWalletByUser(updateStatesWallet, data));
      dispatch(getUserSettings("User_Wallet_IS_ELIGIBLE_FOR_WITHDRAW", updateStatusLimit));
      dispatch(getUserSettings("USER_WALLET_WITHDROW_STATUS", updateWithdrowStatus, false));
      let formData = {
        "Records_Filters": "FILTER",
        "Credit_Reason": "",
        "Debit_Reason": "",
        "Transaction_Type": "",
        "User_Id": EmailID,
        "Wallet_History_Id": "",
        "Wallet_Id": "",
      };
      dispatch(getWalletHistory(formData))
    }
    else {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }

  }, [EmailID]);



  // initial values
  const values = {
    Reason: "",
    Upi_Id: "",
    User_Id: EmailID,
    Wallet_Id: "",
    Withdraw_Amount: ""
  }

  // Form Validations
  const Form_Validation = Yup.object().shape({
    Upi_Id: Yup.string().matches(/[a-zA-Z0-9_]{5,}@[a-zA-Z]{3,}/, " UPI ID is not valid.").required('UPI ID is required.'),
    Withdraw_Amount: Yup.number().min(withdrowLimit, "Required minmum amount of " + withdrowLimit + ".").max(wallet?.Remaining_Balance || 500, "Withdraw Amount should be <= " + (Number(wallet?.Remaining_Balance) || 500) + ".").required("Withdraw Amount is required."),
    Reason: Yup.string().required("Reason is required."),
  })


  const successResponse = () => {
    toast.show({ message: 'Withdraw request submitted successfully.', type: 'info', duration: 3000, position: 'top' });
    const formValue = {
      // "User_Id": EmailID,
      "Wallet_Id": wallet?.Wallet_Id
    };
    dispatch(getWalletHistory(formValue));
    let data = {
      User_Id: EmailID,
      Wallet_Id: "",
    };
    setLoading(true);
    dispatch(getWalletByUser(updateStatesWallet, data));
    navigation.navigate('WalletHistory');
  }

  const onSubmit = (values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
    setSubmitting(false);

    let request = JSON.parse(JSON.stringify(values));
    request.User_Id = EmailID;
    request.Wallet_Id = wallet?.Wallet_Id;
    dispatch(createWallet(request, navigation, toast, resetForm, successResponse));
  }
  const onRefresh = () => {
    let data = {
      User_Id: EmailID,
      Wallet_Id: "",
    };
    setLoading(true);
    dispatch(getWalletByUser(updateStatesWallet, data));
    dispatch(getUserSettings("User_Wallet_IS_ELIGIBLE_FOR_WITHDRAW", updateStatusLimit));
    let formData = {
      "Records_Filters": "FILTER",
      "Credit_Reason": "",
      "Debit_Reason": "",
      "Transaction_Type": "",
      "User_Id": EmailID,
      "Wallet_History_Id": "",
      "Wallet_Id": "",
    };
    dispatch(getWalletHistory(formData))
  };
  const styles = StyleSheet.create({
    cardCircle: {
      width: (Platform.OS === 'web') ? "75%" : "98%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2%",
      marginBottom: "2%",
      backgroundColor:esiColor.BackgroundColor
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    containerone: {
      backgroundColor: 'skyblue',
      borderRadius: 6,
      borderWidth: 0.02,
      borderColor: '#D6D6D7',
      justifyContent: 'center',
      alignItems: 'center',
      width: 200,
      height: 200
    },
    button: {
      alignItems: "center",
      padding: 5,
      backgroundColor: esiColor.globalButtonColor,
      borderRadius: 10,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: 'white',
      color: 'black',
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    placeholderStyle: {
      fontSize: 16,
    },
    selectedTextStyle: {
      fontSize: 16,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    datePickerStyle: {
      width: 358,
      height: 60,
      marginTop: 6,
    },

  });
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.CBColor }}>
      <Header {...props} />

      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', marginTop: 5 }}>
        <View style={{ marginRight: 10 }}>
          <TouchableOpacity onPress={() => navigation.navigate('WalletHistory')} style={styles.button}>
            <Text style={{ fontSize: 15, color: esiColor.itemButtenColor, fontWeight: "bold" }}>TRANSACTIONS</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ justifyContent: 'center', marginTop: 18 }}>
        {wallet &&
          <View key={wallet.Wallet_Id} style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <Text style={{ fontSize: 22, fontWeight: "bold", textAlign: 'center', color: esiColor.itemColor }}>Available Balance</Text>
            <Text style={{ fontSize: 24, marginVertical: 5, fontWeight: "bold", textAlign: 'center', color: esiColor.brandFontColor }}>₹{wallet.Remaining_Balance}</Text>
            <Text style={{ textAlign: 'center', }}> {seconds > 0 ?
              (<Text style={{ color: "red", justifyContent: 'center', textAlign: 'center', alignContent: 'center' }}>Your wallet amount should be Rs. {withdrowLimit}/- to withdraw.</Text>) : ("")}  </Text>
          </View>
        }
      </View>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={onRefresh}
          />
        }
      >
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
          {(props) => (
            <View>
              <Card style={styles.cardCircle}>
                <Card.Content>
                  {/* <Text style={{ fontSize: 20, fontWeight: "bold", justifyContent: 'center',color:'#27b6cc' }}>
                    Fill in to withdraw
                  </Text> */}
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    <View style={{ flexShrink: 1, width: 600 }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, maxWidth: windowWidth > 150 ? 500 : 500, minWidth: windowWidth > 200 ? 200 : '10%', marginRight: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                          {/* UPI field with validation */}
                          <ESITextInput 
                            label='UPI ID'
                            mode='outlined'
                            name="Upi_Id"
                            value={props.values.Upi_Id}
                            onChangeText={props.handleChange('Upi_Id')}
                            error={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}
                            disabled={disbleWithdrow || isSubmited}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}>
                            <ErrorMessage name="Upi_Id" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, maxWidth: windowWidth > 150 ? 500 : 500, minWidth: windowWidth > 200 ? 200 : '10%', marginLeft: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                          {/* Reason field with validation */}
                          <ESITextInput 
                            label='Reason'
                            mode='outlined'
                            name="Reason"
                            value={props.values.Reason}
                            onChangeText={props.handleChange('Reason')}
                            error={Boolean(props.touched.Reason && props.errors.Reason)}
                            disabled={disbleWithdrow || isSubmited}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Reason && props.errors.Reason)}>
                            <ErrorMessage name="Reason" />
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexGrow: 1, maxWidth: windowWidth > 150 ? 600 : 500, minWidth: windowWidth > 200 ? 200 : '10%', marginRight: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                        {/* withdraw amount field with validation */}
                        <ESITextInput
                          label='Withdraw Amount'
                          mode='outlined'
                          name="Withdraw_Amount"

                          value={props.values.Withdraw_Amount}
                          onChangeText={(text) => {
                            let updateText = text.replace(/\D/g, '');
                            props.setFieldValue('Withdraw_Amount', updateText);
                          }
                          }
                          keyboardType="Numeric"
                          disabled={disbleWithdrow || isSubmited}
                          error={Boolean(props.touched.Withdraw_Amount && props.errors.Withdraw_Amount)}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.Withdraw_Amount && props.errors.Withdraw_Amount)}>
                          <ErrorMessage name="Withdraw_Amount" />
                        </HelperText>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Button
                      disabled={disbleWithdrow || isSubmited}
                      style={{ marginTop: "3%", width: 130, backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => {
                        if (wallet.Remaining_Balance < withdrowLimit && wallet.Remaining_Balance) {
                          setSeconds(5);
                        } else {
                          props.handleSubmit();
                        }
                      }}>
                      <Text style={{ color: esiColor.itemButtenColor }}>WITHDRAW</Text>
                    </Button>
                  </View>
                  {disbleWithdrow &&
                    <View style={{ justifyContent: "center" }}>
                      <TouchableOpacity onPress={() => {
                        navigation.navigate("TermsAndConditions");
                      }} >
                        <HelperText type="error">
                          Due to new GST policy , partially we are hold withdrawal of wallet.Need more details click here.
                        </HelperText>
                      </TouchableOpacity>
                    </View>}
                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};


export default Withdrawals;
