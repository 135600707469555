import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createRefer } from '../../state/actions/referActions';
import { Card, TextInput, Text, Button, HelperText, Surface, IconButton } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, Dimensions, ActivityIndicator, KeyboardAvoidingView, Share } from 'react-native';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { CountryCode } from '../../../utils/countrycode';
import { RWebShare } from 'react-web-share';
import getEnvVars from '../../../environment';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import ESITextInput from '../../components/custom-fields/textInput';

const { BASEURL } = getEnvVars();

const UserRefer = (props) => {
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);

  const toast = useToast();
  const EmailID = useSelector((state) => state.auth.user?.Email_Id);
  const user = useSelector((state) => state.auth.user);
  const isSubmited = useSelector((state) => state.refer.referCreate.isLoading);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (isEmpty(EmailID)) {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate('Home');
        }
      }, 1000);
    }
  }, [EmailID]);

  const initialValues = {
    Amount: '',
    Email_Id: '',
    Message: '',
    Mobile: '',
    Name: '',
    Status: '',
    URL: `${BASEURL}/home?referral=${user.User_Details_Id}`,
    User_Email_Id: EmailID,
    Reference_Id: user.User_Details_Id,
  };

  const FormValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Friend's Name is required."),
    Message: Yup.string().required('Referral Message is required.'),

    Email_Id: Yup.string()
      .email('Please enter a valid Email.')
      .test(
        'oneOfRequired',
        "Friend's Email ID / Friend's Mobile is required.",
        function (item) {
          return this.parent.Email_Id || this.parent.Mobile;
        }
      ),
    Mobile: Yup.string().test(
      'oneOfRequired',
      "Friend's Email ID / Friend's Mobile is required.",
      function (item) {
        return this.parent.Email_Id || this.parent.Mobile;
      }
    ).min(14, "Please enter valid mobile number.").max(14, "Please enter valid mobile number."),
  });

  const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    values.User_Email_Id = EmailID;
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(createRefer(formValues, navigation, toast, EmailID, resetForm, setReload));
    }
  };


  const shareMessage = `Hi, I recommend meepaisa for online orders. It's easy, convenient and economical. Use the referral link to sign up & get exciting discounts and cashbacks on each order. ${BASEURL}/home?referral=${user?.User_Details_Id}`;

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: shareMessage,
      });
      if (result?.action === Share.sharedAction) {
        if (result?.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      // alert(error.message);
    }
  };

  return (
    <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1 }}>
      <Header navigation={navigation} />
      {!reload && (
        <Formik initialValues={{ ...initialValues }} onSubmit={onSubmit} validationSchema={FormValidationSchema}>
          {(props) => (
            <KeyboardAvoidingView behavior="padding">
              <ScrollView>
                <View style={{ flexDirection: "column" }} >
                  <View style={{ flexDirection: 'row', zIndex: 1, marginHorizontal: 2, marginBottom: -15, marginTop: 5 }}>
                    {navigation.canGoBack() && (
                      <IconButton
                        icon="arrow-left"
                        size={30}
                        color="#27B6CC"
                        style={{ marginTop: -8 }}
                        onPress={() => {
                          props.resetForm();
                          setReload(true);
                          navigation.goBack();
                        }}
                      />
                    )}
                    <Text style={{ fontSize: 20, fontWeight: 'bold', color: '#27B6CC' }}>INVITE</Text>
                  </View>
                  <View style={{ alignSelf: "center", width: "90%" }} >
                    <View style={{ paddingTop: 2, width: "100%" }}>
                      <ESITextInput
                        label="Friend's Name"
                        mode="outlined"
                        name="Name"
                        value={props.values.Name}
                        onChangeText={props.handleChange('Name')}
                        error={Boolean(props.touched.Name && props.errors.Name)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Name && props.errors.Name)}>
                        <ErrorMessage name="Name" />
                      </HelperText>
                    </View>
                    <View style={{ paddingTop: 15, width: "100%" }}>
                      <ESITextInput
                        label="Referral Message"
                        mode="outlined"
                        name="Message"
                        value={props.values.Message}
                        onChangeText={props.handleChange('Message')}
                        error={Boolean(props.touched.Message && props.errors.Message)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Message && props.errors.Message)}>
                        <ErrorMessage name="Message" />
                      </HelperText>
                    </View>
                    <View style={{ paddingTop: 15, width: "100%" }}>
                      <ESITextInput
                        label="Friend's Email ID"
                        mode="outlined"
                        name="Email_Id"
                        value={props.values.Email_Id}
                        onChangeText={props.handleChange('Email_Id')}
                        error={Boolean(props.touched.Email_Id && props.errors.Email_Id)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Email_Id && props.errors.Email_Id)}>
                        <ErrorMessage name="Email_Id" />
                      </HelperText>
                    </View>
                    <Text style={{ marginLeft: '20%', fontWeight: 'bold' }}>--------------- OR ---------------</Text>
                    <View style={{ paddingTop: 15, width: "100%" }}>
                      <ESITextInput
                        style={{ width: '100%' }}
                        label="Friend's Mobile"
                        mode="outlined"
                        onChangeText={(value) => {
                          if (value) {
                            props.setFieldValue('Mobile', `+91 ${value.replace(/[^0-9]/g, '')}`);
                          } else {
                            props.setFieldValue('Mobile', '');
                          }
                        }}
                        value={props.values.Mobile?.split(' ')[1]}
                        left={<TextInput.Affix text="+91" />}
                        error={Boolean(props.touched.Mobile && props.errors.Mobile)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Mobile && props.errors.Mobile)}>
                        {props.errors.Mobile}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-around', width: "100%" }}>
                    {isSubmited ? (
                      <ActivityIndicator color="#27b6cc" style={{ margin: 'auto', marginRight: 20 }} />
                    ) : (
                      <Button disabled={isSubmited} color="#27B6CC" style={{ margin: 'auto', marginRight: 20 }} mode="contained" onPress={props.handleSubmit}>
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>SAVE</Text>
                      </Button>
                    )}
                    {Platform.OS == 'web' ? (
                      <RWebShare
                        data={{
                          text: shareMessage,
                          url: `${BASEURL}/home?referral=${user?.User_Details_Id}`,
                          title: 'MeePaisa',
                        }}
                      >
                        <button style={{ backgroundColor: '#27B6CC', color: 'white', borderRadius: 5, border: 0, fontWeight: 'bold' }}>SHARE INVITE</button>
                      </RWebShare>
                    ) : (
                      <Button color="#27B6CC" style={{ margin: 'auto' }} mode="contained" onPress={onShare}>
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>Share Invite</Text>
                      </Button>
                    )}
                  </View>

                </View>
              </ScrollView>
            </KeyboardAvoidingView>
          )}
        </Formik>
      )}
    </Surface>
  );
};

export default UserRefer;
