import { shareAsync } from "expo-sharing";
import React, { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Image, Platform, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Dialog, Button, IconButton, Portal, Surface, Text, Card, Divider, DataTable, List, TextInput, HelperText, Menu, Colors, RadioButton, TouchableRipple, Avatar, Checkbox } from "react-native-paper";
import StepIndicator from 'react-native-step-indicator';
import { useDispatch, useSelector } from "react-redux";
import * as Print from "expo-print";
import ESIDeliveryRating from "./delireviewrating";
import { useToast } from "../../../self_modules/react-native-paper-toast/src";
import Header from "../../header";
import isEmpty from "../../../state/validations/is-empty";
import Modal from "react-native-modal";
import useRazorpay from "react-razorpay";
import razorpayPay from "../../../hooks/razorpay";
import { getUserSettings, getWalletByUser } from "../../../state/actions/walletActions";
import { getSingleServiceDetails, ServiceInvoiceAction, servicesUpdateAction } from "../../../state/actions/servicesAction";
import { Formik } from 'formik';
import * as Yup from 'yup';



export default function ServiceDetails(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const { serviceId } = props?.route?.params || "";
    const windowHeight = Dimensions.get('window').height;
    const EmailID = useSelector(state => state.auth.user?.Email_Id)
    const esiColor = useSelector(state => state.theme);
    const [invoiceDetails, setDetails] = useState();
    const [orderInfo, setOrderInfo] = useState({});
    const toast = useToast();


    const [oLoading, setOLoading] = React.useState(false);
    const [orderDetailsFilter, setOrderDetailsFilter] = React.useState("");
    useEffect(() => {
        if (isEmpty(serviceId)) {
            navigation.navigate('MyRides');
        }
        else {
            if (EmailID) {
                let formData1 = {
                    User_Service_Request_ID: serviceId
                }

                if (!oLoading && orderDetailsFilter != JSON.stringify(formData1)) {
                    setOrderInfo({});
                    setOLoading(true);
                    setOrderDetailsFilter(JSON.stringify(formData1));
                    callPageRequiredApis();
                }
            } else {
                navigation.navigate('Home');
            }
        }
    }, [props?.route?.params, oLoading]);
    const [maxWalletUsage, setMaxWalletUsage] = useState(50);


    const callPageRequiredApis = () => {
        let formData1 = {
            User_Service_Request_ID: serviceId
        }
        let formDataInvoice = {
            "User_Service_Request_Id": serviceId,
        }
        dispatch(ServiceInvoiceAction(formDataInvoice, updateStatesInvoice));

        dispatch(getSingleServiceDetails(formData1, orderDetailsResponse));
        let data = {
            User_Id: user.Email_Id,
            Wallet_Id: "",
        };
        dispatch(getWalletByUser(updateStatesWallet, data));
        dispatch(getUserSettings("ORDER_MAX_WALLET_USAGE_P", setMaxWalletUsage));

    };


    const orderDetailsResponse = (status, data) => {
        if (status) {
            setOrderInfo(data);
        } else {
            navigation.navigate("MyRides");
        }
        setOLoading(false);
    };

    const updateStatesInvoice = (status, data) => {
        if (status) {
            setDetails(data);

        }
    };


    const getCurrentDeliveryStatus = (ItemMasterStatus) => {
        let orderlabels = [];
        if (["NEW", "ACCEPTED", "INITIATED", "IN-PROCESS", "COMPLETED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Accepted", "In-Process", "Completed"];
        } else if (["REJECTED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Rejected"];
        } else if (["CANCELLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Cancelled"];
        } else {
            orderlabels = ["New", "Accepted", "In-Process", "Completed"];
        }
        return orderlabels
    }

    const getCurrentStatusPoission = (data, ItemMasterStatus) => {
        let poission = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].toUpperCase() == ItemMasterStatus) {
                poission = i;
                break;
            }
            else if (data[i] == "In-Process" && ItemMasterStatus == "IN-PROCESS") {
                poission = i;
                break;
            }
            else if (data[i] == "Accepted" && ItemMasterStatus == "INITIATED") {
                poission = i;
                break;
            }
        }
        return poission;
    }
    const customStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: 'green',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'green',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'green',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'green',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'green'
    }
    const cancelStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: '#fe7013',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'red',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'red',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'red',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'red'
    }

    const getInvoice = () => {

        try {
            return `
        <html>
        <head>
            <style>
            .grid-container {
            display: grid;
            grid-template-columns: auto auto;
            }.hr.solid {
            border-top: 5px solid #27b6cc;
            }
            table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            }
            td{
            border: 1px solid #27b6cc;
            padding: 8px;
            }
            th {
            border: 1px solid #27b6cc;
            background-color: #27b6cc;
            padding: 8px;
            }
            </style>
            
                    <meta charset="utf-8">
                    <title>Invoice</title>
                    <script src="script.js"></script>
                </head>
                <body onclick="window.print()" >
                <header>
                <center>
                        <h1 style="color:#27b6cc;">Invoice</h1>
                </center>
                <div class="grid-container">
                <img src="`+ require('../../../assets/images/img/logo/logo.png') + `" width="100" height="80" style="background-color: rgb(39, 182, 204);">
                <span style="text-align:right">
                <p>Service Date: `+ (invoiceDetails?.Service_Start_Date || "") + `</p>
                
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4>Service Address:</h4>
                <p>`+ invoiceDetails?.User_Service_Address?.Name + `</p>
                <p>`+ invoiceDetails?.User_Service_Address?.AddressDrToSt + `</p>
                <p>`+ invoiceDetails?.User_Service_Address?.City + `,` + invoiceDetails?.User_Service_Address?.State + `,` + invoiceDetails?.User_Service_Address?.Country + `</p>
                <p>${invoiceDetails?.User_Service_Address?.PhoneNumber}</p>
                <p>`+ invoiceDetails?.User_Service_Address?.email + `</p>
                </span>

                <span>
                <h4>Service info:</h4>
                <p> Service Type : `+ invoiceDetails?.Service[0]?.Service_Type + ` </p>
                <p> Agent Name : `+ invoiceDetails?.Partner_Service_Details?.Resource_Name + `</p>
                <p> Agent Email : `+ invoiceDetails?.Partner_Service_Details?.Resource_Email_Id + `</p>
                <p> Duration : `+ (Number(invoiceDetails?.Service[0]?.Service_Request_Total_Time_In_Days) > 0 ? (invoiceDetails?.Service[0]?.Service_Request_Total_Time_In_Days + (Number(invoiceDetails?.Service[0]?.Service_Request_Total_Time_In_Days) <= 1 ? " Day " : " Day's ")) : "") + " " +
                (Number(invoiceDetails?.Service[0]?.Service_Request_Total_Time_In_Hours) > 0 ? (invoiceDetails?.Service[0]?.Service_Request_Total_Time_In_Hours + (Number(invoiceDetails?.Service[0]?.Service_Request_Total_Time_In_Hours) <= 1 ? " Hour" : " Hour's")) : "") + `</p>
                </span>
               
               
                </div>
                <hr class="solid">
                <table>
                <tr>
                <th colspan="7">
                Payment details
                </th></tr>
                    <tr>
                    <td colspan="7">
                    <span>
                        Service Value 
                    </span>
                    <span style="float: right;">
                        `+ invoiceDetails?.Service_Totals?.Amount_Before_Discount + `
                    </span>
                    </td>
                    </tr>
                    <tr>
                    <td  colspan="7" >
                    <span>
                         Discount 
                    </span>
                    <span style="float: right;">
                        -`+ invoiceDetails?.Service_Totals?.Discount + ` 
                    </span>
                    </td>
                    </tr>
                    `


                + (invoiceDetails?.Service_Totals?.IGST ? `
                    <tr>
                    <td colspan="7"> <span> IGST </span> 
                    
                    <span style="float: center;">
                    `+ invoiceDetails?.Service_Totals?.IGSTPercentage + `%
                </span>
                    <span style="float: right;">
                    `+ invoiceDetails?.Service_Totals?.IGST + ` 
                </span>
                    </td>
                    </tr>`: ``)
                +

                `<tr>
                    <td  colspan="7"></td>
                   
                    </tr>

                    <tr>
                    <td  colspan="7">
                    <span style="font-weight: bold;">
                    Final Amount
                    </span>
                    <span style="float: right; font-weight: bold;">
                    `+ invoiceDetails?.Service_Totals?.Final_Amount + `
                    </span>
                    </td>
                    </tr>

                    <tr>
                        <td style="text-align: left; font-weight: bold;" colspan="7">
                        Amount in words:${invoiceDetails?.Service_Totals?.AmountInWords}
                        </td>
                    </tr>
            
                </table>
            </header>
            <aside>
                <div contenteditable>
                    <p>Note: This is computer generated Invoice and signature is not required.</p>
                </div>
            </aside>
        </body>
        </html>`;
        } catch (error) {
        }

    }

    const printToFile = async () => {
        const html = getInvoice();
        if (Platform.OS == 'web') {
            const pW = window.open('', '', 'height=500, width=500')
            pW.document.write(html);
            pW.document.close();
        }
        else {
            try {
                const { uri } = await Print.printToFileAsync({ html });
                await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
            } catch (error) {
            }
        }
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [rideTip, setRideTip] = React.useState(0);
    const [showRidePayment, setShowRidePayment] = React.useState(false);
    const [ridePaymentLoading, setRidePaymentLoading] = React.useState(false);
    const user = useSelector((state) => state.auth.user);
    const [wallet, setWallet] = React.useState(null);
    const updateStatesWallet = (data: any, loading: any) => {
        let add = JSON.parse(JSON.stringify(data));
        setWallet(add?.Wallet[0]);
    };

    const [useWallet, setUseWallet] = useState(false);
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }

    const getWalletDiscountPrice = (amount, wallet) => {
        let response = 0;
        if (((amount) * maxWalletUsage * 0.01) >= wallet) {
            response = wallet;
        } else {
            response = ((amount) * maxWalletUsage * 0.01);
        }
        return response
    }

    const paymentUpdateResponse = (status, response) => {
        setShowRidePayment(false);
        setRidePaymentLoading(false);
        if (status) {
            callPageRequiredApis();
            toast.show({ message: 'Service payment done successfully.', type: 'info', duration: 3000, position: 'top' });
        } else {
            toast.show({ message: 'Please try after some time.', type: 'info', duration: 3000, position: 'top' });
        }
    }

    const statusUpdateResponse = (status, response) => {
        setSubmit(!issubmitted);
        if (status) {
            callPageRequiredApis();
            toast.show({ message: 'Service successfully cancelled.', type: 'info', duration: 3000, position: 'top' });
        } else {
            toast.show({ message: 'Please try after some time.', type: 'info', duration: 3000, position: 'top' });
        }
    }

    const [rideTrackingShow, setRideTrackingShow] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [issubmitted, setSubmit] = React.useState(false);
    const hideDialog = () => {
        setVisible(false);
    }

    return (
        <Surface theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{ flex: 1 }}>
            <Header {...props} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={oLoading}
                        onRefresh={callPageRequiredApis}
                    />
                }
            >
                {oLoading ? <ActivityIndicator color={esiColor.SIconColor} /> :
                    <View style={{ color: esiColor.BackgroundColor, flex: 1 }}>
                        <View style={{ marginVertical: -10, backgroundColor: esiColor.BackgroundColor }}>
                            <IconButton icon="keyboard-backspace" color={esiColor.SIconColor} onPress={() => navigation.navigate('MyServices')}>
                            </IconButton>
                        </View>

                        <Portal>
                            <Dialog visible={visible} onDismiss={hideDialog} style={{ width: (dimensions.width >= 500) ? 400 : dimensions.width - 50, maxHeight: (dimensions.height >= 500) ? 500 : dimensions.height - 50, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor }}>

                                <Dialog.ScrollArea>
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 1, alignItems: "flex-end" }}>
                                            <IconButton style={{ marginTop: 1, marginRight: -10 }}
                                                icon="close"
                                                size={20}
                                                color={esiColor.SIconColor}
                                                onPress={() => setVisible(!visible)}
                                            />
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 1 }} >
                                            <Text style={{ color: esiColor.GBFColor, fontSize: 20, textAlign: "center", marginTop: -20, marginBottom: 20 }}>Service Cancellation Form</Text>
                                        </View>
                                    </View>
                                    <ScrollView >
                                        <Dialog.Content>
                                            {(() => {

                                                return (
                                                    <Formik
                                                        initialValues={
                                                            {
                                                                Cancel_Return_User_Comment: ''
                                                            }
                                                        }
                                                        validationSchema={Yup.object().shape({
                                                            Cancel_Return_User_Comment: Yup.string()
                                                                .required('Comment is required.')
                                                        })}
                                                        onSubmit={values => {
                                                            let updateData = {
                                                                User_Service_Request_Id: serviceId,
                                                                User_Service_Request_Status: "CANCELLED",
                                                                User_Cancel_Reason: values.Cancel_Return_User_Comment
                                                            }
                                                            dispatch(servicesUpdateAction(updateData, statusUpdateResponse));
                                                            setSubmit(!issubmitted);
                                                            setVisible(!visible);
                                                        }}
                                                    >
                                                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                                                            <View style={{
                                                                flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>

                                                                <View style={{ margin: "auto", width: "100%" }}>

                                                                    <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                                        label='Comment'
                                                                        mode='outlined'
                                                                        onChangeText={handleChange('Cancel_Return_User_Comment')}
                                                                        onBlur={handleBlur('Cancel_Return_User_Comment')}
                                                                        value={values.Cancel_Return_User_Comment}
                                                                        multiline={true}
                                                                        numberOfLines={4}
                                                                        theme={{ colors: { text: esiColor.Text, placeholder: esiColor.SPHTextColor } }}
                                                                        underlineColor={esiColor.itemColor}
                                                                        outlineColor={esiColor.itemColor}
                                                                        activeOutlineColor={esiColor.itemColor}
                                                                        style={{ maxHeight: 100, minWidth: "100%", marginHorizontal: "auto", backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                                                    />
                                                                    <HelperText style={{}} type="error" visible={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}>
                                                                        {touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment}
                                                                    </HelperText>
                                                                </View>
                                                                <View style={{ margin: "auto" }}>
                                                                    <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                                        <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>

                                                                    </Button>
                                                                </View>
                                                            </View>
                                                        )}
                                                    </Formik>
                                                )
                                            })()}

                                        </Dialog.Content>
                                    </ScrollView>
                                </Dialog.ScrollArea>
                            </Dialog>
                        </Portal>

                        <View style={{ marginHorizontal: 5, backgroundColor: esiColor.BackgroundColor, height: windowHeight }}>

                            <View style={{
                                shadowColor: esiColor.brandFontColor,
                                shadowOffset: { width: -2, height: 4 },
                                shadowOpacity: 0.2,
                                shadowRadius: 3,
                            }}>
                                {orderInfo &&
                                    <View style={{ flex: 5, flexDirection: 'column', marginLeft: 10 }}  >
                                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Service Address </Text>
                                        <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.User_Service_Address?.Name},</Text>
                                        <Text style={{ fontSize: 13, color: esiColor.itemColor }}>D-No : {orderInfo?.User_Service_Address?.Door_No},{orderInfo?.User_Service_Address?.Street}, {orderInfo?.User_Service_Address?.Landmark},</Text>
                                        <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{orderInfo?.User_Service_Address?.City},{orderInfo?.User_Service_Address?.State},{orderInfo?.User_Service_Address?.Country},</Text>
                                        <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{orderInfo?.User_Service_Address?.City}-{orderInfo?.User_Service_Address?.Pin},</Text>
                                        <Text style={{ fontSize: 13, color: esiColor.itemColor, marginBottom: 10 }}>Phone number : {orderInfo?.User_Service_Address?.PhoneNumber}.  </Text>
                                    </View>
                                }
                            </View>

                            <View >
                                {orderInfo.User_Service?.map((product) => {

                                    return (
                                        <View key={product.User_Service_Id}
                                            style={{
                                                shadowColor: esiColor.brandShadowColor,
                                                // shadowOffset: { width: -2, height: 4 },
                                                shadowOpacity: 5,
                                                shadowRadius: 7,
                                                marginHorizontal: 1, padding: 1, margin: 'auto', backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 15 : 0), flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                elevation: 10,
                                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                                maxHeight: 150,
                                                minHeight: 100,
                                                // borderColor: 'grey',
                                                // borderWidth: 0.5,
                                                borderRadius: 10
                                            }}
                                        >
                                            <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                                <Image source={{ cache: "force-cache", uri: product?.Service_Image + `?w=80&h=100` }} style={{ height: 100, marginTop: 15, width: 80, resizeMode: 'contain' }}></Image>

                                            </View>
                                            <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.DescColor }}>{product?.Service_Type}</Text>
                                                <Text style={{ marginVertical: 2, color: esiColor.itemColor }}>Service ID : {product?.User_Service_Id}</Text>

                                                <Text style={{ marginVertical: 2, color: esiColor.itemColor }}>Date Of Service  : {product?.User_Service_Requested_Date}</Text>
                                                <Text style={{ marginVertical: 2, color: esiColor.itemColor }}>Require Time : {Number(product?.Service_Reqiured_Days) > 0 && (product?.Service_Reqiured_Days + (Number(product?.Service_Reqiured_Days) <= 1 ? " Day " : " Day's "))}
                                                    {Number(product?.Service_Required_Hours) > 0 && (product?.Service_Required_Hours + (Number(product?.Service_Required_Hours) <= 1 ? " Hour" : " Hour's"))}
                                                </Text>

                                                {!(["CANCELLED", "COMPLETED"].includes(orderInfo?.User_Service_Details?.User_Service_Status)) &&
                                                    <View style={{ flexDirection: 'row', margin: 5, }}>
                                                        <TouchableOpacity
                                                            style={{
                                                                backgroundColor: 'gray', width: 80, borderRadius: 5, alignItems: "center", padding: 5, opacity: issubmitted ? 0.2 : ""
                                                            }}
                                                            onPress={() => { setVisible(!visible) }}
                                                        >
                                                            <Text style={{ color: esiColor.itemButtenColor }}> CANCEL </Text>
                                                        </TouchableOpacity>
                                                    </View>}
                                            </View>
                                        </View>
                                    )
                                })}

                                {getCurrentDeliveryStatus(orderInfo?.User_Service_Details?.User_Service_Status).length > 0 &&
                                    < View style={{ marginVertical: 10 }}>
                                        <StepIndicator
                                            customStyles={orderInfo?.User_Service_Details?.User_Service_Status === "CANCELLED" ? cancelStyles : customStyles}
                                            labels={getCurrentDeliveryStatus(orderInfo?.User_Service_Details?.User_Service_Status)}
                                            currentPosition={getCurrentStatusPoission(getCurrentDeliveryStatus(orderInfo?.User_Service_Details?.User_Service_Status), orderInfo?.User_Service_Details?.User_Service_Status)}
                                            stepCount={getCurrentDeliveryStatus(orderInfo?.User_Service_Details?.User_Service_Status).length}
                                        />
                                    </View>
                                }

                                {(["COMPLETED"].includes(orderInfo?.User_Service_Details?.User_Service_Status)) &&
                                    <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5 }} onPress={printToFile}>
                                        <View >
                                            <Text style={{ color: esiColor.itemButtenColor }} >Download Invoice</Text>
                                        </View>
                                    </TouchableOpacity>
                                }

                                {(orderInfo?.service_provider_detais?.Phone && !(["COMPLETED", "CANCELLED", "REJECTED"].includes(orderInfo?.User_Service_Details?.User_Service_Status))) &&
                                    <View
                                        style={{
                                            shadowColor: esiColor.brandShadowColor,
                                            shadowOffset: { width: 2, height: 1 },
                                            shadowOpacity: 0.5,
                                            shadowRadius: 3,
                                            marginHorizontal: 1, padding: 1, backgroundColor: esiColor.BackgroundColor, flexDirection: 'column',
                                            elevation: 10,
                                            borderColor: esiColor.SBorderColor,
                                            borderWidth: 0.5,
                                            borderRadius: 10,
                                            marginVertical: 10
                                        }}
                                    >
                                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginLeft: 3, marginTop: 1 }}>Service Agent</Text>
                                        {/* <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {}} > */}

                                        <View style={{ flex: 1, alignContent: 'center', alignItems: "center", marginLeft: 10 }} >
                                            <Image source={{ cache: "force-cache", uri: orderInfo?.service_provider_detais?.Photo + `?w=50&h=50` }} style={{ height: 50, width: 50, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.service_provider_detais?.Name}</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.service_provider_detais?.Phone}</Text>
                                        </View>
                                        {/* </TouchableOpacity> */}
                                    </View>
                                }

                            </View>
                            {(serviceId && (orderInfo?.User_Service_Details?.User_Service_Status == "COMPLETED")) &&
                                <View style={{ marginVertical: 5 }} >
                                    <ESIDeliveryRating
                                        orderid={serviceId}
                                        // lineitemid={masterId}
                                        ordStatus={orderInfo?.User_Service_Details?.User_Service_Status}
                                    />
                                </View>
                            }

                            <View style={{
                                marginVertical: 5, shadowColor: esiColor.brandShadowColor,
                                shadowOffset: { width: -2, height: 4 },
                                shadowOpacity: 0.2,
                                shadowRadius: 3
                            }} >

                                <View style={{ flexDirection: 'column', marginLeft: 10 }}  >
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Payment Summary</Text>

                                    <View style={{ flexDirection: "row", marginVertical: 3 }}>
                                        <Text style={{ fontSize: 10, alignSelf: "flex-start", color: esiColor.itemColor }}>"Service value may differ based on Service Provider"</Text>
                                    </View>

                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Service Value</Text>
                                        </View>
                                        <View style={{ flex: 2, }}>
                                            <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.Payment_Summary?.User_Service_Total_After_Discount}</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 2 }} >
                                            <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Tax Amount</Text>
                                        </View>
                                        <View style={{ flex: 2 }}>
                                            <Text style={{ fontSize: 10, alignSelf: "center", color: esiColor.itemColor }}>Total Tax : {orderInfo?.Payment_Summary?.Total_Tax_Percentage}%</Text>
                                        </View>
                                        <View style={{ flex: 2, }}>
                                            <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.Payment_Summary?.Total_Tax}</Text>
                                        </View>
                                    </View>


                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ fontSize: 13, alignSelf: "flex-start", fontWeight: "bold", color: esiColor.DescColor }}>Total Gross Price</Text>
                                        </View>
                                        <View style={{ flex: 2, }}>
                                            <Text style={{ fontSize: 13, alignSelf: "center", fontWeight: "bold", color: esiColor.itemColor }}>{orderInfo?.Payment_Summary?.Final_Gross_Price}</Text>
                                        </View>
                                    </View>
                                    {(orderInfo?.User_Service_Details?.Advance_payment_Status != "SUCCESS" && orderInfo?.User_Service_Details?.User_Service_Status != "CANCELLED") &&
                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ fontSize: 13, alignSelf: "flex-start", fontWeight: "bold", color: esiColor.DescColor }}>Pending Advance Payment</Text>
                                            </View>
                                            <View style={{ flex: 2, flexDirection: "column" }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", fontWeight: "bold", color: orderInfo?.User_Service_Details?.Advance_payment_Status == "SUCCESS" ? "green" : "red" }}>₹ {orderInfo?.User_Service_Details?.Wallet_Remaining_Advance_Amount} /-</Text>

                                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                                                    let amount = (Math.round(Number(orderInfo?.User_Service_Details?.Wallet_Remaining_Advance_Amount) + Number.EPSILON));

                                                    if (amount > 0) {
                                                        razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                                                            if (status) {
                                                                let formData = {
                                                                    User_Service_Request_Id: serviceId,
                                                                    Advance_Payment_Gateway_Amount: amount,
                                                                    Advance_Payment_Gateway_Reference_Number: response.razorpay_payment_id,
                                                                    Advance_Payment_Status: "SUCCESS",
                                                                }
                                                                let formValues = JSON.parse(JSON.stringify(formData));
                                                                setRidePaymentLoading(true);
                                                                dispatch(servicesUpdateAction(formValues, paymentUpdateResponse));
                                                            }
                                                        });
                                                    } else {
                                                        let formData = {
                                                            User_Service_Request_Id: serviceId,
                                                            Advance_Payment_Gateway_Amount: 0,
                                                            Advance_Payment_Gateway_Reference_Number: "",
                                                            Advance_Payment_Status: "SUCCESS",
                                                        }
                                                        let formValues = JSON.parse(JSON.stringify(formData));
                                                        setRidePaymentLoading(true);
                                                        dispatch(servicesUpdateAction(formValues, paymentUpdateResponse));
                                                    }
                                                }}>
                                                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>Pay Now</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    }
                                    {(orderInfo?.User_Service_Details?.User_Service_Status == "COMPLETED") &&
                                        <View>{orderInfo?.User_Service_Details?.Payment_Status != "SUCCESS" &&
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", fontWeight: "bold", color: esiColor.DescColor }}>Pending Amount</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", fontWeight: "bold", color: esiColor.itemColor }}>{orderInfo?.User_Service_Details?.After_Advance_Service_Amount}</Text>
                                                </View>
                                            </View>}
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", fontWeight: "bold", color: esiColor.DescColor }}>Payment Status</Text>
                                                </View>
                                                <View style={{ flex: 2, flexDirection: "column" }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", fontWeight: "bold", color: orderInfo?.User_Service_Details?.Payment_Status == "SUCCESS" ? "green" : "red" }}>{orderInfo?.User_Service_Details?.Payment_Status}</Text>
                                                    {(orderInfo?.User_Service_Details?.User_Service_Status == "COMPLETED" && orderInfo?.User_Service_Details?.Payment_Status != "SUCCESS") &&
                                                        <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                                                            setShowRidePayment(!showRidePayment);
                                                        }}>
                                                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>Pay Now</Text>
                                                        </TouchableOpacity>}
                                                </View>
                                            </View>
                                        </View>
                                    }

                                </View>

                            </View>
                        </View>

                    </View>}
            </ScrollView >
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }}
                isVisible={showRidePayment}
            // isVisible={true}
            >
                <View style={{ flex: 1, flexDirection: "column", maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 400, maxHeight: 430, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10 }} onPress={() => {
                        setShowRidePayment(!showRidePayment);
                    }}>
                        <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                    </TouchableRipple>
                    <View style={{ marginHorizontal: 20, marginTop: 20 }} >
                        <Text style={{ fontWeight: "700", marginBottom: 10, color: esiColor.DescColor }} > Service Value : ₹ {orderInfo?.User_Service_Details?.After_Advance_Service_Amount} /-</Text>
                    </View>
                    <View style={{ marginHorizontal: 20 }} >
                        <Text style={{ fontWeight: "bold", color: esiColor.DescColor }} > Service Tip : </Text>
                        <RadioButton.Group onValueChange={value => setRideTip(value)} value={rideTip}>
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left" }} mode="android" label="₹ 0/-  Not interested" value={0} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left" }} mode="android" label="₹ 20/- Average" value={20} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left" }} mode="android" label="₹ 50/- Good" value={50} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left" }} mode="android" label="₹ 100/- Best" value={100} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left" }} mode="android" label="₹ 500/- Excellent" value={500} />
                        </RadioButton.Group>
                    </View>
                    {(Number(wallet?.Remaining_Balance) > 0 && (Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip)) > 0 && <>
                        <View style={{ marginHorizontal: 20 }}>
                            <TouchableOpacity
                                onPress={() => {
                                    setUseWallet(!useWallet);
                                }}
                            >
                                <View style={{
                                    flexDirection: "row",
                                    marginTop: -10
                                }}>
                                    <Checkbox
                                        status={useWallet ? 'checked' : 'unchecked'}
                                        uncheckedColor={esiColor.SBorderColor}
                                    />
                                    <Text style={{
                                        marginTop: 10, color: esiColor.itemColor
                                    }}>{"Use Wallet Amount ₹ " + getWalletDiscountPrice((Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip), wallet?.Remaining_Balance)} /-</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        {useWallet &&
                            <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
                                <View style={{ flex: 2 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: 'bold', marginLeft: 5 }}>Wallet Amount  </Text>
                                </View>
                                <View style={{ flex: 2 }} >
                                    <Text style={{ color: esiColor.itemColor, fontWeight: 'bold' }}>
                                        : -₹ {getWalletDiscountPrice((Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip), wallet?.Remaining_Balance)} /-
                                    </Text>
                                </View>

                            </View>}
                    </>}
                    <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
                        <View style={{ flex: 2 }} >
                            <Text style={{ fontWeight: "bold", marginBottom: 10, color: esiColor.DescColor }} > Payable Amount </Text>
                        </View>
                        <View style={{ flex: 2 }} >
                            <Text style={{ fontWeight: "bold", marginBottom: 10, color: esiColor.itemColor }} >: ₹ {(Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip) - (useWallet ? getWalletDiscountPrice((Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip), wallet?.Remaining_Balance) : 0)} /-</Text>
                        </View>
                    </View>
                    <View style={{ marginHorizontal: 20, justifyContent: "center", alignItems: "center" }} >
                        {
                            ridePaymentLoading ? <ActivityIndicator color={esiColor.SIconColor} /> :


                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, maxWidth: 150, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                                    let amount = (Math.round(((Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip) - (useWallet ? getWalletDiscountPrice((Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip), wallet?.Remaining_Balance) : 0)) + Number.EPSILON));

                                    if (amount > 0) {
                                        razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                                            if (status) {
                                                let formData = {
                                                    User_Service_Request_Id: serviceId,
                                                    Service_Tip: rideTip,
                                                    payment_Gateway_Amount: amount,
                                                    Payment_Reference_Id: response.razorpay_payment_id,
                                                    Payment_Method: "RAZORPAY",
                                                    Payment_Status: "SUCCESS",
                                                    User_Paid_Total_Fare: Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip,
                                                    Wallet_Amount: (useWallet ? getWalletDiscountPrice((Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip), wallet?.Remaining_Balance) : 0)
                                                }
                                                let formValues = JSON.parse(JSON.stringify(formData));
                                                setRidePaymentLoading(true);
                                                dispatch(servicesUpdateAction(formValues, paymentUpdateResponse));
                                            }
                                        });
                                    }
                                    else {
                                        let formData = {
                                            User_Service_Request_Id: serviceId,
                                            Service_Tip: rideTip,
                                            Payment_Reference_Id: "",
                                            Payment_Method: "WALLET",
                                            Payment_Status: "SUCCESS",
                                            User_Paid_Total_Fare: Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip,
                                            Wallet_Amount: (useWallet ? getWalletDiscountPrice((Number(orderInfo?.User_Service_Details?.After_Advance_Service_Amount) + rideTip), wallet?.Remaining_Balance) : 0)
                                        }
                                        setRidePaymentLoading(true);
                                        let formValues = JSON.parse(JSON.stringify(formData));
                                        dispatch(servicesUpdateAction(formValues, paymentUpdateResponse));
                                    }
                                }}>
                                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>Pay Now</Text>
                                </TouchableOpacity>}
                    </View>
                </View>
            </Modal>
        </Surface >
    )
}
