import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../header";
import { ActivityIndicator, Avatar, Button, Checkbox, Colors, HelperText, Modal, Portal, Surface, Text, TextInput as TextInputP, TouchableRipple } from 'react-native-paper';
import * as Yup from 'yup';
import { View, StyleSheet, ScrollView, TouchableOpacity, TextInput, Platform, Dimensions } from "react-native";
import { getUserSettings, getWalletByUser } from "../../state/actions/walletActions";
import { getAllCartAddressAction } from "../../state/actions/addressActions";
import isEmpty from "../../state/validations/is-empty";
import { removeAll, removePartnerInCart, corpVoucherCheck, couponCodeCheckAction, getValidCorpVoucher, getValidGiftVoucher, giftVoucherCheck, placeNewOrderAction, productRemoveAddressError, getCashBackVoucher, productAddOrRemoveAddress } from "../../state/actions/cartActionsNew";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import { Formik } from "formik";
import { FontAwesome5 } from "@expo/vector-icons";
import razorpayPay from "../../hooks/razorpay";
import useRazorpay from "react-razorpay";
import { MD5 } from "../../hooks/md5/md5Encripion";
import { getAllOrdersAction } from "../../state/actions/ordersAction";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import * as ModelG from "react-native-modal";
import ESITextInput from "../../components/custom-fields/textInput";
export default function CartSummary(props: any) {
  const dispatch = useDispatch();


  // back page reload 
  const [count, setCount] = useState(0);
  const [maxWalletUsage, setMaxWalletUsage] = React.useState(50);
  const [fgPartnerOrderRequiredAmount, setFGPartnerOrderRequiredAmount] = React.useState(150);
  useEffect(() => {
    if (wallet) {
      setWallet(null);
      let data = {
        User_Id: user.Email_Id,
        Wallet_Id: "",
      };
      dispatch(getWalletByUser(updateStatesWallet, data));
    }
    if (giftVoucher.text) {
      setGiftVoucher({
        text: '', giftValidate: {}, loading: false, errorMessage: '',
        result: {}
      });
    }
    if (coupons.length > 0) {
      setCoupons([]);
    }
    if (corpVoucher.text) {
      setCorpVoucher({
        text: '', validate: {}, loading: false, errorMessage: '',
        result: {}
      });
    }
    setCashBackCoupon({});
    getAddress();
    dispatch(getUserSettings("CART_DELIVERY_CHARGE_BALANCER", setFGPartnerOrderRequiredAmount, false));
    dispatch(getUserSettings("ORDER_MAX_WALLET_USAGE_P", setMaxWalletUsage));
  }, [props.navigation, props.route]);




  const RazorPay = Platform.OS === "web" ? useRazorpay() : null;

  const { navigation, route, ...rest } = props;

  let cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.auth.user);
  const esiColor = useSelector(state => state.theme);


  // Wallet logic start
  const [useWallet, setUseWallet] = useState(false);
  // wallet logic end


  // Billing Address login start
  const [address, setAddress] = React.useState([]);
  const [billingAddress, setBillingAddress] = React.useState({});


  const addresslist = (address: any) => {
    let addresslist = []
    if (address) {
      if (address[0]) {
        for (let i = 0; i < address.length; i++) {
          addresslist.push({
            label: address[i].Address_Type + " " + "," + " " + address[i].Door_Number + " " + "," + " " + address[i].Landmark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip,
            value: JSON.stringify(address[i])
          })
          if (address[i].Is_Billing === "1") {
            setBillingAddress({ address: address[i] });
          }
        }
        if (!(billingAddress?.address) && address.length > 0) {
          setBillingAddress({ address: address[0] });
        }
      }
    }
    addresslist.push({
      label: "Add New Address",
      value: "Add New Address"
    })
    return addresslist
  }
  const updateStates = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data ? data : []));
    setAddress(addresslist(add));
  };



  const getAddress = () => {
    let data = {
      User_Email_Id: user.Email_Id,
      Records_Filter: "FILTER",
    };
    dispatch(getAllCartAddressAction(updateStates, data));
  }
  // Billing Address logic started.

  // Gift Voucher logic started.
  const [visible, setVisible] = React.useState(false);
  const [seconds, setSeconds] = React.useState(-1);


  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  const [giftVoucher, setGiftVoucher] = React.useState({
    text: '', giftValidate: {}, loading: false, errorMessage: '',
    result: {}
  });
  const giftVoucherActionResponse = (response, data) => {
    if (response) {
      setGiftVoucher({ ...giftVoucher, giftValidate: JSON.parse(JSON.stringify(data)), loading: false, errorMessage: '' });
      setVisible(true);
      setSeconds(30);
    } else {
      setGiftVoucher({ ...giftVoucher, giftValidate: {}, loading: false, errorMessage: data });
    }
  }

  const responseGiftVoucher = (response, data) => {
    setGiftVoucher({ ...giftVoucher, loading: false });
    if (response) {
      setVisible(false);
      let newGift = JSON.parse(JSON.stringify(data));
      newGift.validResponse = "*GIFT VOUCHER APPLIED SUCCESFULLY";
      setGiftVoucher({ ...giftVoucher, loading: false, result: newGift, errorMessage: "*GIFT VOUCHER APPLIED SUCCESFULLY" });
    } else {
      setGiftVoucher({ ...giftVoucher, loading: false, errorMessage: data });
    }
  }

  const giftVoucherCheckLocal = () => {
    setGiftVoucher({ ...giftVoucher, loading: true, errorMessage: "data" });
    dispatch(giftVoucherCheck(giftVoucher.text, giftVoucherActionResponse));
  };

  // Gift Voucher end.

  // Coupon logic start.
  const [coupons, setCoupons] = React.useState([]);

  React.useEffect(() => {
    let couponsL = [];
    if (route.params?.cart) {
      if (route.params?.cart === 'all') {
        // 
        cart.map((item) => {
          couponsL.push(
            {
              text: '', isLoading: false, result: {}, errorMessage: '', partner: { id: item.partnerId, Name: item.partnerName }
            }
          )
        })
      } else {
        cart.filter((item) => item.partnerId === route.params.cart).map((item) => {
          couponsL.push(
            {
              text: '', isLoading: false, result: {}, errorMessage: '', partner: { id: item.partnerId, Name: item.partnerName }
            }
          )
        })
      }
      if (couponsL.length > 0) {
        setCoupons(couponsL);
      }
      else {
        navigation.navigate('Cart');
      }

    }
    else {
      navigation.navigate('Cart');
    }
  }, [route.params])


  const couponCheck = (index) => {
    let dummyCoupon = JSON.parse(JSON.stringify(coupons));
    let coupon = dummyCoupon[index];
    coupon.errorMessage = '';
    coupon.isLoading = true;
    setCoupons(dummyCoupon);
    dispatch(couponCodeCheckAction(coupon.text, user.Email_Id, coupon.partner.id, couponResponse, index));
  };

  const couponResponse = (status, response, index) => {
    let dummyCoupon = JSON.parse(JSON.stringify(coupons));
    let coupon = dummyCoupon[index];
    coupon.isLoading = false;
    if (status) {
      coupon.result = response;
      coupon.errorMessage = "*COUPON APPLIED SUCCESFULLY"
    } else {
      coupon.errorMessage = response;
    }
    setCoupons(dummyCoupon);
  };

  // Coupon logic end.

  // CORP Voucher logic started.
  const [visibleC, setVisibleC] = React.useState(false);
  const [secondsC, setSecondsC] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => secondsC > 0 ? setSecondsC(secondsC - 1) : setSecondsC(-1), 1000)
    return () => clearTimeout(timer)
  }, [secondsC]);
  const [corpVoucher, setCorpVoucher] = React.useState({
    text: '', validate: {}, loading: false, errorMessage: '',
    result: {}
  });
  const corpVoucherActionResponse = (response, data) => {
    if (response) {
      setCorpVoucher({ ...corpVoucher, validate: JSON.parse(JSON.stringify(data)), loading: false, errorMessage: '' });
      setVisibleC(true);
      setSeconds(30);
    } else {
      setCorpVoucher({ ...corpVoucher, validate: {}, loading: false, errorMessage: data });
    }
  }

  const responseCorpVoucher = (response, data) => {
    setCorpVoucher({ ...corpVoucher, loading: false });
    if (response) {
      setVisibleC(false);
      let newGift = JSON.parse(JSON.stringify(data));
      newGift.validResponse = "*CORPORATE VOUCHER APPLIED SUCCESFULLY";
      setCorpVoucher({ ...corpVoucher, loading: false, result: newGift, errorMessage: "*CORPORATE VOUCHER APPLIED SUCCESFULLY" });
    } else {
      setCorpVoucher({ ...corpVoucher, loading: false, errorMessage: data });
    }
  }

  const corpVoucherCheckLocal = () => {
    setCorpVoucher({ ...corpVoucher, loading: true, errorMessage: "" });
    dispatch(corpVoucherCheck(corpVoucher.text, corpVoucherActionResponse));
  };

  // CORP Voucher end.

  const [wallet, setWallet] = React.useState(null);
  const updateStatesWallet = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data));
    setWallet(add?.Wallet[0]);
  };

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isLoading = useSelector(state => state.auth.isLoading);

  const addresses = useSelector(state => state.address.addresses.all);

  useEffect(() => {
    updateStates(addresses, false);
  }, [addresses]);

  const handileNavigation = () => {
    if (isAuthenticated) {
      getAddress();
      dispatch(productRemoveAddressError(cart));
      let data = {
        User_Id: user.Email_Id,
        Wallet_Id: "",
      };
      dispatch(getWalletByUser(updateStatesWallet, data));
    } else {
      if (!isLoading)
        navigation.navigate('Home');
    }
  };

  useEffect(() => {
    handileNavigation();
  }, [isAuthenticated]);

  // Discount Logics

  const getPartnerOrderOriginalPrice = (items) => {
    let amount = 0;
    items.products.map(product => (
      amount = amount + (Number(product.Product.Original_Price) * Number(product.itemCount))
    ));
    return amount.toFixed(2);
  };

  const getAllPartnerOriginalPrice = () => {
    let amount = 0;
    coupons.map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getPartnerOrderOriginalPrice(partner[0]));
      }
    })
    return amount.toFixed(2)
  };

  const getDiscountPrice = () => {
    return (Number(getAllPartnerOriginalPrice()) - Number(getAllPartnerOrderPrice())).toFixed(2);
  };



  const getPartnerOrderPrice = (items) => {
    let amount = 0;
    items.products.map(product => (
      amount = amount + (Number(product.partnerProduct.Partner_Selling_Price) * Number(product.itemCount))
    ));
    return amount.toFixed(2);
  };

  const getPartnerOrderPriceById = (partnerId) => {
    let amount = 0;
    let partner = cart.filter((item) => item.partnerId === partnerId);
    if (!isEmpty(partner)) {
      amount = amount + Number(getPartnerOrderPrice(partner[0]));
    }
    return amount.toFixed(2)
  };

  const getAllPartnerOrderPrice = () => {
    let amount = 0;
    coupons.map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getPartnerOrderPrice(partner[0]));
      }
    })
    return amount
  };

  const couponDiscountAmount = (items) => {
    let coupon = items.coupon;
    let amount = 0;
    let productsAvailable = false;
    let products = items.products.filter(pro => {
      let found = false;
      if (coupon.Category_Id && coupon.Brand_Id) {
        if (coupon.Category_Id === pro.Product.Category_Id && coupon.Brand_Id === pro.Product.Brand_Id) {
          found = true;
        }
      } else if (coupon.Brand_Id) {
        if (coupon.Brand_Id === pro.Product.Brand_Id) {
          found = true;
        }
      } else if (coupon.Category_Id === pro.Product.Category_Id) {
        found = true;
      }
      return found;
    });
    products.map(product => (
      amount = amount + (Number(product.partnerProduct.Partner_Selling_Price) * Number(product.itemCount))
    ));
    if (products.length > 0) {
      productsAvailable = true;
    }
    if (productsAvailable) {
      let response = {
        discountValid: coupon.Discount_Type ? true : false,
        discount: coupon.Discount_Type === "P" ? true : false,
        amount: 0,
      };
      let discountAmount = 0;
      if (response.discountValid) {
        if (response.discount) {
          discountAmount = (amount / 100) * Number(coupon.Discount_Amount);
        } else {
          if (Number(coupon.Discount_Amount) > amount) {
            discountAmount = amount;
          } else {
            discountAmount = coupon.Discount_Amount;
          }
        }
      }

      return discountAmount.toFixed(2);
    } else {
      return (0).toFixed(2);
    }
  }

  const allCouponDiscount = () => {
    let amount = 0;
    coupons.filter((coupon) => !isEmpty(coupon.result)).map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.result.Partner_Details_Id);
      if (!isEmpty(partner)) {
        amount = amount + Number(couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }));
      }
    })
    return amount.toFixed(2)
  }


  const deliveryValueCaluculation = (orderValue, arrayData, distance) => {
    let response = { charge: 0, message: "", distance: distance };
    function sortResults(input, prop, asc) {
      input.sort(function (a, b) {
        if (a[prop]) {
          if (asc) {
            return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
          } else {
            return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
          }
        } else {
          throw new Error(prop + ' is not a exist!');
        }
      });
      return input;
    }
    try {
      let dist = sortResults(JSON.parse(arrayData), "distance", true);
      for (let j = 0; j < dist.length; j++) {
        let isCurrectDistanceLogic = false;
        if (dist.length == 1) {
          isCurrectDistanceLogic = true;
        }
        else if (dist[j]?.distance >= distance && j + 1 < dist.length) {

          if (dist[j + 1]?.distance >= distance) {

          } else {
            isCurrectDistanceLogic = true;
          }

        }
        else if (dist[j]?.distance <= distance && j == dist.length - 1) {
          isCurrectDistanceLogic = true;
        }

        if (isCurrectDistanceLogic) {
          let data = sortResults(dist[j].charges, "value", true);
          for (let i = 0; i < data.length; i++) {
            if (orderValue < data[i].value || (i == data.length - 1 && orderValue >= data[i].value)) {
              let message = "";
              let charge = 0;
              if (i == 0 || i == data.length - 1) {
                charge = data[i].charge
              }
              else {
                charge = data[i - 1].charge
              }
              if (orderValue < data[i].value && i < data.length - 1) {
                if (data[data.length - 1].charge == 0) {
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                } else {
                  if (i + 1 == data.length - 1)
                    message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                  else
                    message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, and reduce ₹" + (charge - data[data.length - 1].charge) + " delivery charges please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + "more.";
                }
              } else if (orderValue < data[i].value) {
                if (data[data.length - 1].charge == 0) {
                  message = "To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                } else {
                  if (i + 1 == data.length - 1)
                    message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                }
              }
              response = { charge: charge, message: message, distance: distance };
              break;
            }
          }
          break;
        }
      }
    } catch (error) {
      try {
        let data = sortResults(JSON.parse(arrayData), "value", true);
        for (let i = 0; i < data.length; i++) {
          if (orderValue < data[i].value || (i == data.length - 1 && orderValue >= data[i].value)) {
            let message = "";
            let charge = 0;
            if (i == 0 || i == data.length - 1) {
              charge = data[i].charge
            }
            else {
              charge = data[i - 1].charge
            }
            if (orderValue < data[i].value && i < data.length - 1) {
              if (data[data.length - 1].charge == 0) {
                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
              } else {
                if (i + 1 == data.length - 1)
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                else
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, and reduce ₹" + (charge - data[data.length - 1].charge) + " delivery charges please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + "more.";
              }
            } else if (orderValue < data[i].value) {
              if (data[data.length - 1].charge == 0) {
                message = "To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
              } else {
                if (i + 1 == data.length - 1)
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
              }
            }
            response = { charge: charge, message: message, distance: distance };
            break;
          }
        }
      } catch (error) {
        // console.log(error);
      }
    }
    return response
  }

  const getPartnerAddress = (products) => {
    let address = "";
    let notHaveAddress = [];
    products.map((data, index) => {
      if (data.address) {
        if (address.label) {

        } else {
          let completeProduct = data;
          address = completeProduct.address ? {
            label: completeProduct.address.Address_Type + " " + "," + " " + completeProduct.address.Door_Number + " " + "," + " " + completeProduct.address.Landmark + " " + "," + " " + completeProduct.address.Street + " " + "," + " " + completeProduct.address.City + " " + "," + " " + completeProduct.address.State + " " + "," + " " + completeProduct.address.Zip,
            value: JSON.stringify(completeProduct.address),
            locations: completeProduct.GEO_Locations
          } : ""
        }
      } else {
        notHaveAddress.push(index);
      }
    })
    return address
  }

  const getDeliveyChargesByLatAndLong = (items) => {
    let loc = getPartnerAddress(items.products).locations;
    let distance = 3;
    if (loc?.User_Latitude && loc?.User_Longitude && loc?.Partner_Latitude && loc?.Partner_Longitude) {
      distance = getDistanceBetweenTwoLatAndLong(loc?.User_Latitude, loc?.Partner_Latitude, loc?.User_Longitude, loc?.Partner_Longitude);
    }
    return deliveryValueCaluculation(Number(getPartnerOrderPrice(items)), fgPartnerOrderRequiredAmount, distance).charge;
  }

  const getDeliveryPrice = (items) => {
    let extraCharge = 0;
    let amount = 0;
    items.products.map(product => {
      amount = amount + (Number(product.partnerProduct.Shipping_Amount ? product.partnerProduct.Shipping_Amount : 0) * Number(product.itemCount))
    });
    if (items?.Business_Type == "FOOD" || items?.Business_Type == "GROCERY") {
      extraCharge = getDeliveyChargesByLatAndLong(items);
    }
    return (amount + extraCharge).toFixed(2);
  };

  const getDistanceBetweenTwoLatAndLong = (lat1,
    lat2, lon1, lon2) => {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;
    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
      + Math.cos(lat1) * Math.cos(lat2)
      * Math.pow(Math.sin(dlon / 2), 2);
    let c = 2 * Math.asin(Math.sqrt(a));
    // Radius of earth
    // for miles
    // let r = 6371;
    // for kilometers
    let r = 3956;
    // calculate the result
    return Math.ceil(c * r);

  }

  const getAllDeliveryPrice = () => {
    let amount = 0;
    coupons.map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getDeliveryPrice(partner[0]));
      }
    })
    return amount.toFixed(2)
  };

  const getGiftPackPrice = (items) => {
    let amount = 0;
    items.products.map(product => {
      if (product.isGiftPack) {
        let amount2 = product?.partnerProduct?.Is_Gift_Wrap_Available === "1" ? Number(product.partnerProduct.Is_Gift_Wrap_Amount ? product.partnerProduct.Is_Gift_Wrap_Amount : 0) : 0;
        amount = amount + amount2;
      }
    });
    return amount.toFixed(2);
  };

  const getAllGiftPackPrice = () => {
    let amount = 0;
    coupons.map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getGiftPackPrice(partner[0]));
      }
    })
    return amount.toFixed(2)
  };

  const corpDiscountAmount = () => {
    let amount = 0;
    if (!isEmpty(corpVoucher.result)) {
      let coupon = Number(allCouponDiscount());
      let delivery = Number(getAllDeliveryPrice());
      let giftPackAmount = Number(getAllGiftPackPrice());
      let totalBeforeCrop = Number(getAllPartnerOrderPrice()) + delivery + giftPackAmount - coupon;
      amount = (totalBeforeCrop < Number(corpVoucher.result.Discount_Amount) ? totalBeforeCrop : Number(corpVoucher.result.Discount_Amount))
    }
    return amount.toFixed(2)
  }


  const getGiftDiscountPrice = () => {
    let amount = 0;
    if (!isEmpty(giftVoucher.result)) {
      let coupon = Number(allCouponDiscount());
      let delivery = Number(getAllDeliveryPrice());
      let giftPackAmount = Number(getAllGiftPackPrice());
      let getCorporateCoupon = Number(corpDiscountAmount());
      let totalBeforeGift = Number(getAllPartnerOrderPrice()) + delivery + giftPackAmount - coupon - getCorporateCoupon;
      amount = (totalBeforeGift < Number(giftVoucher.result.Total_Value) ? totalBeforeGift : Number(giftVoucher.result.Total_Value))
    }
    return amount.toFixed(2);
  };

  const getWalletDiscountPrice = () => {
    let amount = 0;
    let giftUseAmont = Number(getGiftDiscountPrice());
    let coupon = Number(allCouponDiscount());
    let delivery = Number(getAllDeliveryPrice());
    let giftPackAmount = Number(getAllGiftPackPrice());
    let getCorporateCoupon = Number(corpDiscountAmount());
    let totalBeforeWallet = Number(getAllPartnerOrderPrice()) + delivery + giftPackAmount - coupon - getCorporateCoupon - giftUseAmont;
    if (Number(wallet?.Remaining_Balance))
      amount = (totalBeforeWallet * (maxWalletUsage / 100)) < Number(wallet?.Remaining_Balance) ? (totalBeforeWallet * (maxWalletUsage / 100)) : Number(wallet?.Remaining_Balance);
    return amount.toFixed(2)
  };

  const getWalletUsedAmount = () => {
    return useWallet ? getWalletDiscountPrice() : "0.00"
  };

  const orderFinalAmount = () => {
    let amount = 0;
    let giftUseAmont = Number(getGiftDiscountPrice());
    let coupon = Number(allCouponDiscount());
    let delivery = Number(getAllDeliveryPrice());
    let giftPackAmount = Number(getAllGiftPackPrice());
    let getCorporateCoupon = Number(corpDiscountAmount());
    let getWallet = Number(getWalletUsedAmount());
    amount = Number(getAllPartnerOrderPrice()) + delivery + giftPackAmount - coupon - getCorporateCoupon - giftUseAmont - getWallet;
    return amount.toFixed(2)
  }

  const [orderPlaced, setOrderPlaced] = useState(false);

  // Order place logic

  const payNow = () => {
    let amount = (Math.round(Number(orderFinalAmount()) + Number.EPSILON));
    if (amount > 0) {
      razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
        if (status) {
          placeNewOrder("RAZORPAY", response.razorpay_payment_id, orderFinalAmount());
        } else {

        }
      });
    } else {
      placeNewOrder("WALLET", "", orderFinalAmount());
    }
  }
  // coupon code is start
  const placeNewOrder = (type, transactionId, amount) => {



    let Partner_Orders = [];

    coupons.map((coupon) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {


        let OrderTotals = {
          "Order_Totals": {
            "Gift_Amount": getGiftDiscountPrice(),
            "Order_Actual_Total": getAllPartnerOriginalPrice(),
            "Order_Amount_Collected_Using_Payment_Gateway": type,
            "Order_Discount_Amount": getDiscountPrice(),
            "Order_Total_After_Discount": orderFinalAmount(),
            "Wallet_Amount": useWallet ? Number(getWalletUsedAmount()) : 0
          },
          "Shipment_Total": {
            "Shipping_Total": getAllDeliveryPrice()
          }
        };

        let orderProducts = [
        ];

        partner[0].products.map((masterProduct) => {
          let newOrderProduct = {
            "Is_Gift": masterProduct.isGiftPack ? 1 : 0,
            "Gift_Message": masterProduct.isGiftPackMessage,
            "Line_Item_Product_ID": masterProduct.Product.Product_Id,
            "Line_Item_Partner_Product_ID": masterProduct.partnerProduct.Partner_Product_Id,
            "Line_Item_Qty": masterProduct.itemCount,
            "Selling_Price": masterProduct.partnerProduct.Partner_Selling_Price,
            "Size": masterProduct.size,
            "Shipping_Address": {
              "User_Address_Id": masterProduct.address.User_Address_Id
            },
          }
          orderProducts.push(newOrderProduct);
        });


        Partner_Orders.push(
          ["RETAIL", "FARMER", "MFH"].includes(partner[0].Business_Type) ?
            {
              "Coupon_Code": coupon.result?.Code,
              "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }),
              "Coupon_ID": coupon.result?.Coupon_Id,
              "Partner_Id": partner[0].partnerId,
              "Product_Line_Items": orderProducts,
              "Partner_Payments": {
                "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }),
                "Coupon_ID": coupon.result?.Coupon_Id,
                "Selling_Total_Before_Coupon_Discount": getPartnerOrderPriceById(partner[0].partnerId),
                "Final_Selling_Total": Number(getPartnerOrderPriceById(partner[0].partnerId)) - Number(couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }))
              }
            } : {
              'Esi_Shipping_Fee': getDeliveyChargesByLatAndLong(partner[0]),
              "Coupon_Code": coupon.result?.Code,
              "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }),
              "Coupon_ID": coupon.result?.Coupon_Id,
              "Partner_Id": partner[0].partnerId,
              "Shipping_Address": orderProducts[0].Shipping_Address,
              "Product_Line_Items": orderProducts,
              "Partner_Payments": {
                "Coupon_Discount_Amount": couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }),
                "Coupon_ID": coupon.result?.Coupon_Id,
                "Selling_Total_Before_Coupon_Discount": getPartnerOrderPriceById(partner[0].partnerId),
                "Final_Selling_Total": Number(getPartnerOrderPriceById(partner[0].partnerId)) - Number(couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }))
              }
            }
        )
      }
    })

    let OrdersNew = {
      "Multi_Partner_Flag": Partner_Orders.length > 1 ? 1 : 0,
      "User_Detals": {
        "User_Email_ID": user.Email_Id
      },
      "Billing_Address": {
        "User_Address_Id": billingAddress?.address?.User_Address_Id
      },
      "Corporate": {
        "Corporate_Discount_Amount": corpDiscountAmount(),
        "Corporate_Discont_Coupon": corpVoucher.validate?.Coupon_Id_Corp ? corpVoucher.validate?.Coupon_Id_Corp : "",
      },
      "Gift_Voucher": {
        "Gift_ID": giftVoucher.result?.Gift_Voucher_Id ? giftVoucher.result?.Gift_Voucher_Id : "",
        "Gift_ID_Amount": getGiftDiscountPrice()
      },
      "Wallet": {
        "Wallet_Amount": Number(getWalletUsedAmount()),
        "Wallet_Id": wallet?.Wallet_Id ? wallet?.Wallet_Id : ""
      },
      "Order_Payments": {
        "Currency": "INR",
        "Payment_Mode": type,
        "Response_Code": "200",
        "Transaction_Actual_Amount": amount,
        "Transaction_Id": transactionId,
        "Transcation_Status": "Success"
      },
      "Order_Totals": {
        "Order_Totals": {
          "Gift_Amount": getGiftDiscountPrice(),
          "Order_Actual_Total": getAllPartnerOriginalPrice(),
          "Order_Amount_Collected_Using_Payment_Gateway": type,
          "Order_Discount_Amount": getDiscountPrice(),
          "Order_Total_After_Discount": orderFinalAmount(),
          "Wallet_Amount": useWallet ? Number(getWalletUsedAmount()) : 0,
          "Order_Selling_Price_Total": getAllPartnerOrderPrice()
        },
        "Shipment_Total": {
          "Shipping_Total": getAllDeliveryPrice()
        }
      },
      "Partner_Orders": Partner_Orders,
      "Meepaisa_Coupon": {
        "Meepaisa_Coupon_ID": cashBackCoupon?.Code ? ((Number(getAllPartnerOrderPrice()) >= Number(cashBackCoupon?.Min_Order_Value || "0")) ? cashBackCoupon?.Code : "") : ""
      }
    };

    setOrderPlaced(true);
    dispatch(placeNewOrderAction({ Orders: OrdersNew }, placeNewOrderResponse, {}));
  };

  // Order place logic end.


  const placeNewOrderResponse = (status, response, item) => {
    setOrderPlaced(false);
    if (response[0]?.Response_Status === "Success") {
      let partners = [];
      coupons.map((coupon) => {
        partners.push(coupon.partner.id);

      })

      if (!isEmpty(partners)) {
        if (partners.length > 1) {
          dispatch(removeAll(user.Email_Id));
        } else {
          dispatch(removePartnerInCart(partners[0], user.Email_Id));
        }
      }

      dropDownAlertFun('info', 'Success', 'Order created successfully.');
      let orderformData = {
        Order_Id: "",
        Order_Status: "",
        Records_Filter: "FILTER",
        User_Email_Id: user.Email_Id,
      }
      dispatch(getAllOrdersAction(orderformData));
      navigation.navigate('MyOrders');
    }
    else {
      dropDownAlertFun('info', 'Failed', 'Sorry we unable to place your order, please try again.');
    }
  };

  // toast logic
  // Tost message code.
  const toast = useToast();

  // Tost message code.
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 5000, position: 'top' });
  }


  // Meepaisa CashBack Coupon

  const [openSearchModel, setOpenSearchModel] = useState(false);
  const [cashBackCoupon, setCashBackCoupon] = useState({});
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  return (
    <Surface style={{ flex: 1, flexDirection: 'column' }}>
      <Header {...props} />
      <View style={{ flexDirection: "row" }} >
        <View style={{ flex: 1 }} >
          {navigation.canGoBack() &&
            <TouchableOpacity style={{ marginLeft: 5 }} onPress={() => {
              // navigation.navigate('Cart');
              navigation.goBack();
            }}>
              <FontAwesome5 name='arrow-left' color='#27b6cc' style={{ marginVertical: 10 }} size={20}></FontAwesome5>
            </TouchableOpacity>}
        </View>
        <View style={{ flex: 8, flexDirection: "row", justifyContent: 'center' }}>
          <Text style={{ fontWeight: 'bold', marginVertical: 2, fontSize: 30, color: '#27b6cc' }}>Cart Summary</Text>
        </View>
      </View>
      <ScrollView pointerEvents={orderPlaced ? "none" : 'auto'}  >
        <ActivityIndicator color='#27b6cc' style={{ position: "absolute", zIndex: 2, left: "45%", top: '50%' }} animating={orderPlaced} />
        <View >
          <View style={{ margin: 5, padding: 2, marginBottom: Platform.OS == "ios" ? 200 : 10 }}>

            <View style={{ marginLeft: 10, marginRight: 10, marginTop: 5, marginBottom: 5 }}>
              <View >
                <Text style={{ fontWeight: 'bold', fontSize: 18, color: '#27b6cc', marginBottom: 10, marginLeft: 5 }}>Billing Address</Text>
              </View>
              <EsiSearchDropdown
                style={[styles.dropdown, { borderColor: billingAddress?.addressError ? '#b00020' : 'gray' }]}
                data={address}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                labelField="label"
                valueField="value"
                maxHeight={180}
                search
                placeholder='Billing Address'
                defaultValue={billingAddress?.address ? {
                  label: billingAddress.address.Address_Type + " " + "," + " " + billingAddress.address.Door_Number + " " + "," + " " + billingAddress.address.Landmark + " " + "," + " " + billingAddress.address.Street + " " + "," + " " + billingAddress.address.City + " " + "," + " " + billingAddress.address.State + " " + "," + " " + billingAddress.address.Zip,
                  value: JSON.stringify(billingAddress.address)
                } : ""}
                className="address"
                onUpdateValue={(item: any) => {
                  if (item.value === "Add New Address") {
                    setBillingAddress({ ...billingAddress, addressError: '' });
                    navigation.navigate('AddAddress', { page: 'cart-summary' });
                  } else {
                    setBillingAddress({ address: JSON.parse(item.value) });
                  }
                }}
                value={billingAddress?.address ? JSON.stringify(billingAddress?.address) : ''}
              />
              <HelperText type="error" visible={billingAddress?.addressError ? true : false}>
                {billingAddress?.addressError ? billingAddress?.addressError : ""}
              </HelperText>
            </View>
            {coupons.map((data, index) => (
              <View style={{ margin: 10 }} key={"cou" + index} >
                <View >
                  <Text style={{ fontWeight: 'bold', fontSize: 14, color: '#27b6cc', marginBottom: 10, marginLeft: 5 }}>Add <Text style={{ fontWeight: 'bold', fontSize: 18, color: '#27b6cc' }}>{data.partner.Name}</Text> Coupon Code</Text>
                </View>
                <View style={{ flexDirection: 'row', paddingRight: 5 }}>
                  <View style={{ flex: 4, justifyContent: 'center', alignContent: 'center', borderTopStartRadius: 25, borderColor: '#D3D3D3', borderWidth: 2, borderBottomStartRadius: 25, width: '100%' }}>
                    <TextInput selectionColor='rgba(0, 0, 0, 0.5)' value={data.text} placeholder='Coupon Code' editable={!data.isLoading}
                      onChangeText={(value) => {
                        data.text = value;
                        data.errorMessage = '';
                        data.isLoading = false;
                        data.result = {};
                        let dummyCoupons = JSON.parse(JSON.stringify(coupons));
                        dummyCoupons[index] = data;
                        setCoupons(dummyCoupons);
                      }}
                      style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontWeight: 'bold', width: '100%', color: esiColor.Text }} />
                  </View>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                    <TouchableOpacity disabled={data.isLoading ? true : false} onPress={() => { couponCheck(index) }} style={{ justifyContent: 'center', alignItems: 'center', borderTopEndRadius: 25, borderBottomEndRadius: 25, flexDirection: 'row', backgroundColor: '#D3D3D3', height: 50 }}>
                      <Text style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: '-12%', color: '#000000', fontWeight: 'bold', flexDirection: 'row', width: 100, fontSize: 16 }}>apply</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                {data.isLoading ?
                  <ActivityIndicator color='#27b6cc' animating={data.isLoading} /> :
                  <HelperText style={{ marginLeft: 5, color: !isEmpty(data.result) ? 'green' : 'red' }} type="info" visible={!isEmpty(data.errorMessage)}>
                    {data.errorMessage}
                  </HelperText>
                }
              </View>
            ))}

            <View style={{ margin: 10 }} >
              <View >
                <Text style={{ fontWeight: 'bold', fontSize: 14, color: '#27b6cc', marginBottom: 10, marginLeft: 5 }}>Add <Text style={{ fontWeight: 'bold', fontSize: 18, color: '#27b6cc' }}>Corporate</Text> Voucher</Text>
              </View>

              <View style={{ flexDirection: 'row', paddingRight: 5 }}>
                <View style={{ flex: 4, justifyContent: 'center', alignContent: 'center', borderTopStartRadius: 25, borderColor: '#D3D3D3', borderWidth: 2, borderBottomStartRadius: 25, width: '100%' }} >
                  <TextInput selectionColor='rgba(0, 0, 0, 0.5)' editable={!corpVoucher.loading} value={corpVoucher?.text} placeholder={'Corporate Voucher'}
                    style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontWeight: 'bold', width: '100%', color: esiColor.Text }}
                    onChangeText={(value) => {
                      setCorpVoucher({ text: value, errorMessage: '', validate: {}, loading: false, result: {} });
                    }}
                  />
                </View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                  <TouchableOpacity disabled={corpVoucher.loading ? true : false} onPress={() => {
                    corpVoucherCheckLocal()
                  }} style={{ justifyContent: 'center', alignItems: 'center', borderTopEndRadius: 25, borderBottomEndRadius: 25, flexDirection: 'row', backgroundColor: '#D3D3D3', height: 50 }}>
                    <Text style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: '-12%', color: '#000000', fontWeight: 'bold', flexDirection: 'row', width: 100, fontSize: 16 }}>apply</Text>
                  </TouchableOpacity>
                </View>
              </View>
              {(corpVoucher.loading && !visible) ?
                <ActivityIndicator color='#27b6cc' animating={corpVoucher.loading} />
                :
                <HelperText style={{ marginLeft: 5, color: !isEmpty(corpVoucher.result) ? 'green' : 'red' }} type="info" visible={Boolean(corpVoucher.errorMessage)}>
                  {corpVoucher.errorMessage}
                </HelperText>
              }
            </View>

            <View style={{ margin: 10 }} >
              <View >
                <Text style={{ fontWeight: 'bold', fontSize: 14, color: '#27b6cc', marginBottom: 10, marginLeft: 5 }}>Add Gift Card</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingRight: 5 }}>
                <View style={{ flex: 4, justifyContent: 'center', alignContent: 'center', borderTopStartRadius: 25, borderColor: '#D3D3D3', borderWidth: 2, borderBottomStartRadius: 25, width: '100%' }}>
                  <TextInput selectionColor='rgba(0, 0, 0, 0.5)' editable={!giftVoucher.loading} value={giftVoucher?.text} placeholder={'Gift Card'}
                    style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontWeight: 'bold', width: '100%', color: esiColor.Text }}
                    onChangeText={(value) => {
                      setGiftVoucher({ text: value, errorMessage: '', giftValidate: {}, loading: false, result: {} });
                    }}
                  />
                </View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                  <TouchableOpacity disabled={giftVoucher.loading ? true : false} onPress={() => {
                    setGiftVoucher({ ...giftVoucher, errorMessage: '', result: {}, giftValidate: {}, loading: true })
                    dispatch(giftVoucherCheck(giftVoucher?.text, giftVoucherActionResponse));
                  }} style={{ justifyContent: 'center', alignItems: 'center', borderTopEndRadius: 25, borderBottomEndRadius: 25, flexDirection: 'row', backgroundColor: '#D3D3D3', height: 50 }}>
                    <Text style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: '-12%', color: '#000000', fontWeight: 'bold', flexDirection: 'row', width: 100, fontSize: 16 }}>apply</Text>
                  </TouchableOpacity>
                </View>
              </View>
              {(giftVoucher.loading && !visible) ?
                <ActivityIndicator color='#27b6cc' animating={giftVoucher.loading} />
                :
                <HelperText style={{ marginLeft: 5, color: !isEmpty(giftVoucher.result) ? 'green' : 'red' }} type="info" visible={Boolean(giftVoucher.errorMessage)}>
                  {giftVoucher.errorMessage}
                </HelperText>
              }
            </View>

            <View style={{ margin: 10 }} >
              <View>
                <View style={{ margin: 5, flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={{ fontWeight: 'bold' }}>Price</Text>
                  <Text style={{ fontWeight: 'bold' }}>₹ {getAllPartnerOriginalPrice()}</Text>
                </View>
                {/* {Number(getPartnerOrderDiscountPrice(item)) > 0 && */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                  <Text style={{ fontWeight: 'bold', color: '#4f8fbf' }}>Discount</Text>
                  <Text style={{ fontWeight: 'bold', color: '#4f8fbf' }}>
                    -₹ {getDiscountPrice()}
                  </Text>
                </View>
                {coupons.filter((coupon) => !isEmpty(coupon.result)).length > 0 &&
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                    <Text style={{ fontWeight: 'bold', color: '#4f8fbf' }}>Coupon Discount</Text>
                    <Text style={{ fontWeight: 'bold', color: '#4f8fbf' }}>
                      -₹ {allCouponDiscount()}
                    </Text>
                  </View>}
                {Number(getAllDeliveryPrice()) > 0 &&
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                    <Text style={{ fontWeight: 'bold' }}>Delivery Charges</Text>
                    <Text style={{ fontWeight: 'bold' }}>₹ {getAllDeliveryPrice()}</Text>
                  </View>
                }

                {Number(getAllGiftPackPrice()) > 0 &&
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                    <Text style={{ fontWeight: 'bold' }}>Gift Pack Charges</Text>
                    <Text style={{ fontWeight: 'bold' }}>₹ {getAllGiftPackPrice()}</Text>
                  </View>
                }
                {Number(corpDiscountAmount()) > 0 &&
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                    <Text style={{ color: '#4f8fbf', fontWeight: 'bold' }}>Corporate Voucher Amount</Text>
                    <Text style={{ color: '#4f8fbf', fontWeight: 'bold' }}>
                      -₹ {corpDiscountAmount()}
                    </Text>
                  </View>}
                {!isEmpty(giftVoucher.result) &&
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                    <Text style={{ color: '#4f8fbf', fontWeight: 'bold' }}>Gift Card Amount</Text>
                    <Text style={{ color: '#4f8fbf', fontWeight: 'bold' }}>
                      -₹ {getGiftDiscountPrice()}
                    </Text>
                  </View>
                }
                {Number(wallet?.Remaining_Balance) > 0 && Number(getWalletDiscountPrice()) > 0 && <>
                  <View style={{ margin: 5, marginBottom: -5, marginLeft: -3 }}>
                    <TouchableOpacity
                      onPress={() => {
                        setUseWallet(!useWallet);
                      }}
                    >
                      <View style={{
                        flexDirection: "row",
                        marginTop: -10
                      }}>
                        <Checkbox.Android
                          status={useWallet ? 'checked' : 'unchecked'}
                        />
                        <Text style={{
                          marginTop: 10, color: esiColor.itemColor
                        }}>{"Use Wallet Amount ₹ " + getWalletDiscountPrice()}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                  {useWallet &&
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                      <Text style={{ color: '#4f8fbf', fontWeight: 'bold' }}>Wallet Amount</Text>
                      <Text style={{ color: '#4f8fbf', fontWeight: 'bold' }}>
                        -₹ {getWalletDiscountPrice()}
                      </Text>
                    </View>}
                </>}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                  <Text style={{ fontWeight: 'bold' }}>Total Price</Text>
                  <Text style={{ fontWeight: 'bold' }}>₹ {orderFinalAmount()}</Text>
                </View>

                <View style={{}} >
                  <View >
                    <Text style={{ fontWeight: 'bold', fontSize: 14, color: '#27b6cc', marginBottom: 10, marginLeft: 5 }}>Cashback Coupon</Text>
                  </View>

                  <View style={{ flexDirection: 'row', paddingRight: 5 }}>
                    <View style={{ flex: 4, justifyContent: 'center', alignContent: 'center', borderTopStartRadius: 25, borderColor: '#D3D3D3', borderWidth: 2, borderBottomStartRadius: 25, width: '100%' }}
                      onTouchStart={() => {
                        setOpenSearchModel(!openSearchModel);
                      }}
                    >
                      <TextInput selectionColor='rgba(0, 0, 0, 0.5)' editable={false} value={cashBackCoupon?.Code} placeholder={'Cashback Voucher'}
                        style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontWeight: 'bold', width: '100%', color: esiColor.Text }}
                        // onChangeText={(value) => {
                        //   setCorpVoucher({ text: value, errorMessage: '', validate: {}, loading: false, result: {} });
                        // }}
                        onPressIn={() => {
                          setOpenSearchModel(!openSearchModel);
                        }}
                        onKeyPress={() => {
                          setOpenSearchModel(!openSearchModel);
                        }}
                        onTouchStart={() => {
                          setOpenSearchModel(!openSearchModel);
                        }}
                      />
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                      <TouchableOpacity disabled={corpVoucher.loading ? true : false} onPress={() => {
                        setOpenSearchModel(!openSearchModel);
                      }} style={{ justifyContent: 'center', alignItems: 'center', borderTopEndRadius: 25, borderBottomEndRadius: 25, flexDirection: 'row', backgroundColor: '#D3D3D3', height: 50 }}>
                        <Text style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: '-12%', color: '#000000', fontWeight: 'bold', flexDirection: 'row', width: 100, fontSize: 16 }}>apply</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <HelperText style={{ marginLeft: 5, color: (Number(getAllPartnerOrderPrice()) >= Number(cashBackCoupon?.Min_Order_Value || "0")) ? 'green' : 'red' }} type="info" visible={Boolean(cashBackCoupon?.Code)}>
                    {(Number(getAllPartnerOrderPrice()) >= Number(cashBackCoupon?.Min_Order_Value || "0")) ? "Congrats! you will get " + cashBackCoupon?.Cashback_Label + " " + (cashBackCoupon?.Cashback_Type == "A" ? "₹" : "") + cashBackCoupon?.Cashback + " " + (cashBackCoupon?.Cashback_Type == "A" ? "" : "%") + " cashback."
                      :
                      "Add ₹" + (Number(cashBackCoupon?.Min_Order_Value || "0") - Number(getAllPartnerOrderPrice())) + " more to get cashback."}
                  </HelperText>
                </View>

                <View style={{ flexDirection: "row", justifyContent: 'center' }}>
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#27b6cc",
                      padding: 10,
                      margin: 'auto',
                      borderRadius: 8
                    }}
                    onPress={() => {
                      if (isEmpty(billingAddress?.address)) {
                        setBillingAddress({ ...billingAddress, addressError: 'Billing Address is required.' })
                      } else {
                        payNow();
                      }
                    }}
                  >
                    <Text style={{ color: 'white' }} >Pay and Place New Order</Text>
                  </TouchableOpacity>
                </View>
              </View>

            </View>
          </View>
        </View>
        <Portal>
          <Modal visible={visible} onDismiss={() => setVisible(false)}
            //  dismissable={false}
            contentContainerStyle={{
              backgroundColor: esiColor.BackgroundColor,
              paddingLeft: 10,
              paddingRight: 10,
              marginLeft: 20,
              marginRight: 20,
              minHeight: 100,
              borderRadius: 8
            }}>
            <View  >
              <View style={{ marginTop: 5, marginBottom: 5 }}  >
                <Text style={{ fontSize: 24, fontWeight: 'bold', marginLeft: "auto", marginRight: "auto" }} >Gift Card OTP</Text>
              </View>
              <View style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignSelf: 'flex-start',
              }}>
                {!isEmpty(giftVoucher?.giftValidate) &&
                  <Text style={{ paddingLeft: 10, paddingRight: 10, textAlign: 'justify' }} >OTP has been sent to email({giftVoucher.giftValidate ? (giftVoucher.giftValidate?.Email.split("@"))[0].substring(0, 4) + "***@" + (giftVoucher.giftValidate?.Email.split("@"))[1] : ""}) and mobile number( ****{giftVoucher.giftValidate ? giftVoucher.giftValidate?.Mobile.substring(giftVoucher.giftValidate?.Mobile.length - 4) : ""})
                  </Text>
                }
              </View>
              <Formik
                initialValues={{ otp: "" }}

                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .required('Voucher OTP is required.')
                })}

                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setSubmitting(false);
                  if (MD5(values.otp) === giftVoucher.giftValidate?.OTP) {
                    setGiftVoucher({ ...giftVoucher, loading: true, errorMessage: '', result: {} });
                    dispatch(getValidGiftVoucher(giftVoucher.text, responseGiftVoucher));
                  } else {
                    setErrors({ otp: "Invalid Voucher OTP." });
                  }

                }}
              >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm }) => (
                  <>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <TextInputP selectionColor='rgba(0, 0, 0, 0.5)' style={{ width: "100%", margin: "auto" }}
                        label="Voucher OTP"
                        mode='outlined'
                        onChangeText={handleChange('otp')}
                        onBlur={handleBlur('otp')}
                        value={values.otp}
                        error={Boolean(touched.otp && errors.otp)}
                      />
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.otp && errors.otp)}>
                        {touched.otp && errors.otp}
                      </HelperText>
                    </View>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(giftVoucher.errorMessage)}>
                        {giftVoucher.errorMessage}
                      </HelperText>
                    </View>

                    <View style={{ width: "90%", flexDirection: 'row', justifyContent: 'space-around', margin: "auto", marginBottom: 15 }}>
                      <Button style={{ marginRight: 10, backgroundColor: '#27b6cc' }} mode="contained" disabled={giftVoucher.loading} onPress={handleSubmit}>
                        Validate OTP
                      </Button>
                      <Button style={{ backgroundColor: '#27b6cc' }} mode="contained" disabled={giftVoucher.loading || seconds >= 0} onPress={() => {
                        if (!(seconds >= 0)) {
                          giftVoucherCheckLocal();
                          resetForm();
                        }

                      }}>
                        Resend OTP {seconds > 0 ? seconds : ""}
                      </Button>
                    </View>
                  </>
                )}
              </Formik>

            </View>
          </Modal>

          <Modal visible={visibleC} onDismiss={() => setVisibleC(false)}
            //  dismissable={false}
            contentContainerStyle={{
              backgroundColor: esiColor.BackgroundColor,
              paddingLeft: 10,
              paddingRight: 10,
              marginLeft: 20,
              marginRight: 20,
              minHeight: 100,
              borderRadius: 8
            }}>
            <View  >
              <View style={{ marginTop: 5, marginBottom: 5 }}  >
                <Text style={{ fontSize: 24, fontWeight: 'bold', marginLeft: "auto", marginRight: "auto" }} >Corprate Voucher OTP</Text>
              </View>
              <View style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignSelf: 'flex-start',
              }}>
                {!isEmpty(corpVoucher?.validate) &&
                  <Text style={{ paddingLeft: 10, paddingRight: 10, textAlign: 'justify' }} >OTP has been sent to email({corpVoucher.validate ? (corpVoucher.validate?.Email_Id.split("@"))[0].substring(0, 4) + "***@" + (corpVoucher.validate?.Email_Id.split("@"))[1] : ""})
                  </Text>
                }
              </View>
              <Formik
                initialValues={{ otp: "" }}

                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .required('Voucher OTP is required.')
                })}

                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setSubmitting(false);
                  if (MD5(values.otp) === corpVoucher.validate?.OTP) {
                    setCorpVoucher({ ...corpVoucher, loading: true, errorMessage: '', result: {} });

                    dispatch(getValidCorpVoucher(corpVoucher.validate.Coupon_Id_Corp, responseCorpVoucher));
                  } else {
                    setErrors({ otp: "Invalid Voucher OTP." });
                  }

                }}
              >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm }) => (
                  <>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <TextInputP selectionColor='rgba(0, 0, 0, 0.5)' style={{ width: "100%", margin: "auto" }}
                        label="Voucher OTP"
                        mode='outlined'
                        onChangeText={handleChange('otp')}
                        onBlur={handleBlur('otp')}
                        value={values.otp}
                        error={Boolean(touched.otp && errors.otp)}
                      />
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.otp && errors.otp)}>
                        {touched.otp && errors.otp}
                      </HelperText>
                    </View>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(corpVoucher.errorMessage)}>
                        {corpVoucher.errorMessage}
                      </HelperText>
                    </View>

                    <View style={{ width: "90%", flexDirection: 'row', justifyContent: 'space-around', margin: "auto", marginBottom: 15 }}>
                      <Button style={{ marginRight: 10, backgroundColor: '#27b6cc' }} mode="contained" disabled={corpVoucher.loading} onPress={handleSubmit}>
                        Validate OTP
                      </Button>
                      <Button style={{ backgroundColor: '#27b6cc' }} mode="contained" disabled={corpVoucher.loading || seconds >= 0} onPress={() => {
                        if (!(seconds >= 0)) {
                          corpVoucherCheckLocal();
                          resetForm();
                        }

                      }}>
                        Resend OTP {seconds > 0 ? seconds : ""}
                      </Button>
                    </View>
                  </>
                )}
              </Formik>
            </View>
          </Modal>
        </Portal >
      </ScrollView >
      <ModelG.default animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={openSearchModel}>
        <View style={{
          maxWidth: dimensions.width - 50, minWidth: 300, minHeight: 400, maxHeight: dimensions.height - 150, borderRadius: 10, backgroundColor: esiColor.BackgroundColor,
          shadowColor: '#0E9EAD',
          shadowOffset: { width: -2, height: 4 },
          shadowOpacity: 0.2,
          shadowRadius: 3
        }}>
          <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, backgroundColor: "" }} onPress={() => {
            setOpenSearchModel(!openSearchModel);
          }}>
            <Avatar.Icon color={'#27b6cc'} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
          </TouchableRipple>
          <CashBackCouponSearch Email_Id={user.Email_Id} setCashbackCoupon={(data) => {
            setOpenSearchModel(!openSearchModel);
            setCashBackCoupon(data);
          }} />
        </View>
      </ModelG.default>
    </Surface>

  );
}


function CashBackCouponSearch(props: any) {
  const [search, setSearch] = useState("");
  let [searchOld, setSearchOld] = useState("");
  const [loading, setLoading] = useState(false);
  const [cashBackCoupons, setCashBackCoupons] = useState([]);
  const esiColor = useSelector(state => state.theme);

  const responseOfCity = (status, response) => {
    setLoading(false);
    if (status) {
      setCashBackCoupons(response)
    } else {
      setCashBackCoupons([])
    }
  }


  const dispatch = useDispatch();
  useEffect(() => {
    if (search) {
      searchaserviceCall();
    } else {
      setCashBackCoupons([]);
    }

  }, [search, loading])

  const searchaserviceCall = () => {
    if (search) {
      if ((search != searchOld) && !loading) {
        setLoading(true);
        setSearchOld(search);
        dispatch(getCashBackVoucher({
          Code: search,
          User_Email_Id: props.Email_Id
        }, responseOfCity));
      }
    } else {
      setCashBackCoupons([]);
    }
  }

  return (
    <View style={{ flexDirection: 'column', marginTop: 30 }} >
      <View style={{ flexDirection: "row", justifyContent: "center" }} >
        <ESITextInput
          style={{ width: "90%", margin: "auto" }}
          label="Search Coupon"
          mode='outlined'
          value={search}
          onChangeText={(text) => {
            setSearch(text)
          }}
          left={<TextInputP.Icon color={"#27b6cc"} name={"card-search-outline"} />}
          right={<TextInputP.Icon onPress={() => {
            setSearch("");
          }} color={"#27b6cc"} name={"close"} />}
        />
      </View>
      <View style={{ paddingHorizontal: 20 }} >
        {loading ?
          <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 5 }} >
            <ActivityIndicator color={"#27b6cc"} />
          </View> :
          <View >
            {search ? cashBackCoupons.length <= 0 ?
              <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 5 }} >
                <Text> No Cashback coupons are availabile with given search. </Text>
              </View> :
              cashBackCoupons.slice(0, 7).map((coupon, index) => (<View key={"city" + index}
                style={{
                  shadowColor: '#0E9EAD',
                  shadowOffset: { width: -2, height: 4 },
                  shadowOpacity: 0.2,
                  shadowRadius: 3,
                  marginHorizontal: 2, paddingHorizontal: 10, padding: 1, margin: 'auto', backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 15 : 5), flexDirection: 'column',
                  elevation: 10,
                  borderColor: 'grey',
                  borderWidth: 0.5,
                  borderRadius: 10,
                }}
              >
                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {
                  props.setCashbackCoupon(coupon);
                }} >
                  <View style={{ flex: 2, flexDirection: 'column' }}  >
                    <View style={{ flexDirection: "row" }} >
                      <Text style={{ fontSize: 14, textAlign: "left", flex: 2, fontWeight: 'bold', alignSelf: "center", color: "#27b6cc" }}>{coupon?.Code}</Text>
                      <Text style={{ fontSize: 12, textAlign: "right", flex: 2, alignSelf: "center", color: "#27b6cc" }}>Get {coupon?.Cashback_Label} {coupon?.Cashback_Type == "A" ? "₹" : ""}{coupon?.Cashback} {coupon?.Cashback_Type == "A" ? "" : "%"}</Text>
                    </View>
                    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{coupon?.Name}</Text>
                    <Text style={{ fontSize: 12 }}>{coupon?.Description}</Text>
                  </View>
                </TouchableOpacity>
              </View>))

              :
              <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 5 }} >
                <Text> Please search to get cashback coupons. </Text>
              </View>
            }
          </View>
        }

      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  dropdown: {
    height: 58,
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  centerElement: { justifyContent: 'center', alignItems: 'center' },

});