import React, { useEffect, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { Avatar, HelperText, Colors, Text, TextInput, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-native-modal";
import { createDelReviewRatingsAction, createOrderDelReviewRatingsAction, getAllDelReviewRatingsAction, getOrderByDelReviewRatingsAction } from "../../state/actions/deliveryRatingsAction";
import isEmpty from "../../state/validations/is-empty";
import SignIN from "../../screens/auth/signin";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import EsiRating from "../../components/custom/Rating/rating";

export default function ESIDeliveryRating(props: any) {
    const { orderid, lineitemid, ordStatus, toggle, isRetail = true, ...rest } = props;
    const esiColor = useSelector(state => state.theme);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const toast = useToast();


    //For add review
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);
    const [rating, setRating] = useState(0);
    const user = useSelector(state => state.auth?.user);
    const [comment, setComment] = useState();

    const toggleModal = () => {
        if (isAuthenticated) {
            setReviewModalVisible(!isReviewModalVisible);
        }
        else {
            setModalVisible(true);
        }
    };

    const addReviewModal = (formData) => {
        if (isRetail) {
            let data = {
                Comments: comment,
                Del_User_Email_Id: alldelireviewsandratings?.Del_User_Email_Id,
                Name: user?.User_Name,
                Order_Id: orderid,
                Order_Line_Item_Id: lineitemid,
                Rating: rating,
                Status: "Active",
                User_Email_Id: user?.Email_Id,
            }
            let formValues = JSON.parse(JSON.stringify(formData));
            dispatch(createDelReviewRatingsAction(formValues, toast, successCall));

        }
        else {
            let data = {
                Comments: comment,
                Del_User_Email_Id: alldelireviewsandratings?.Del_User_Email_Id,
                Name: user?.User_Name,
                Order_Id: orderid,
                Rating: rating,
                Status: "Active",
                User_Email_Id: user?.Email_Id,
            }
            let formValues = JSON.parse(JSON.stringify(formData));
            dispatch(createOrderDelReviewRatingsAction(formValues, toast, successCall));

        }
        setReviewModalVisible(false);
    }

    // let alldelireviewsandratings = useSelector((state) => state.deliReviewRating.delreviewratings.all);
    const [alldelireviewsandratings, setAlldelireviewsandratings] = useState({});
    const containerStyle = { backgroundColor: 'white', padding: 20 };
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const getReviews = (data: any) => {
        setIsLoading(false);
        setAlldelireviewsandratings(data);
    }

    useEffect(() => {
        if (!isLoading && orderid)
            successCall();
    }, [orderid, lineitemid]);

    const successCall = () => {
        if (isRetail && lineitemid) {
            setIsLoading(true);
            let formData = {
                "Records_Filter": "FILTER",
                "Order_ID": orderid,
                "Order_Line_Item_ID": lineitemid
            }
            dispatch(getAllDelReviewRatingsAction(formData, getReviews));
        }
        else {
            setIsLoading(true);
            let formData = {
                "Records_Filter": "FILTER",
                "Order_ID": orderid,
            }
            dispatch(getOrderByDelReviewRatingsAction(formData, getReviews));
        }
    }

    //For add review
    const [issModalVisible, settModalVisible] = React.useState(false);

    //displaying Signin pop up
    const signInModalResponse = () => {
        settModalVisible(!issModalVisible);
    };

    // Tost message code.
    let dropDownAlertRef = React.useRef();
    const dropDownAlertFun = (type, title, sub_title) => {
        const inMilliSeconds = Math.floor(Math.random() * 6000 + 1);
        const inSeconds = (inMilliSeconds / 1000).toFixed(2);
        dropDownAlertRef.alertWithType(
            type,
            title,
            sub_title,
            {},
            inMilliSeconds
        );
    }

    //For view Review & Ratings
    const [view, setViewModalVisible] = React.useState(false);
    const [isViewReviewModalVisible, setViewReviewModalVisible] = React.useState(false);
    const [data, setData] = React.useState();

    const viewToggleModal = () => {
        if (isAuthenticated) {
            setViewReviewModalVisible(!isViewReviewModalVisible);
        }
        else {
            setViewModalVisible(true);
        }
    };
    return (
        <View>
            <View>
                {isEmpty(alldelireviewsandratings?.data) && ordStatus === "DELIVERED" ?
                    <View style={{flexDirection:"row",justifyContent:"center"}} >
                        <TouchableOpacity
                            style={{ backgroundColor: "white", alignItems: "center" }}
                            onPress={() => toggleModal()}
                        >
                            <Text style={{ color: '#27b6cc',alignContent:"center",alignSelf:"center" }}>Add Delivery Review</Text>
                        </TouchableOpacity>
                    </View>
                    :

                    alldelireviewsandratings && ordStatus === "DELIVERED" ?
                        alldelireviewsandratings?.data?.map((delorder, index) => (
                            <TouchableOpacity style={{ marginHorizontal: 5 }} key={"del" + index} onPress={() => { viewToggleModal(), setData(delorder) }}>
                                <Text style={{ fontWeight: "bold", color: "#27b6cc" }}>Delivery Review</Text>
                                <EsiRating
                                    readonly={true}
                                    rating={delorder?.Rating ? parseInt(delorder?.Rating) : 0}
                                    type='heart'
                                    ratingBackgroundColor='#c8c7c8'
                                    startingValue={delorder?.Rating}
                                    ratingCount={5}
                                    imageSize={20}
                                    style={{ marginTop: -20 }}
                                    minValue={1}
                                    onFinishRating={(rating) => ratingCompleted(rating)}
                                    name="rating"
                                />
                            </TouchableOpacity>
                        ))
                        :
                        null
                    // })
                }
            </View>

            {/* For Add Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}
                contentContainerStyle={containerStyle}
            >
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 5, shadowRadius: 7 }}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => setReviewModalVisible(false)}>
                            <Avatar.Icon color={'black'} style={{ backgroundColor: Colors.white }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <DeliveryAddReview dorderid={orderid} dorderlineid={lineitemid} responseFunction={addReviewModal}
                    />
                </View>
            </Modal>
            {/* For view Review & Ratings */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isViewReviewModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 200, borderRadius: 10,backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 5, shadowRadius: 7}}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setViewReviewModalVisible(false) }} >
                            <Avatar.Icon color={'black'} style={{ backgroundColor: Colors.white }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <Text style={{ fontWeight: "bold", padding: 10 }}>Comment   <Text style={{ color: "#4BBFF9" }}>{data?.Comments}</Text></Text>
                        <Text style={{ fontWeight: "bold", padding: 10 }}>Rating   </Text>
                        <EsiRating
                            readonly={true}
                            rating={data?.Rating ? parseInt(data?.Rating) : 0}
                            type='heart'
                            ratingBackgroundColor='#c8c7c8'
                            startingValue={data?.Rating}
                            ratingCount={5}
                            imageSize={20}
                            style={{ marginTop: -30 }}
                            minValue={1}
                            onFinishRating={(rating) => ratingCompleted(rating)}
                            name="rating"

                        />
                    </View>
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={issModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: Colors.white }}>
                    <SignIN dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
        </View>

    )
}

const DeliveryAddReview = ({ responseFunction, dorderid, dorderlineid, alldelireviewsandratings }) => {
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState(false);
    const [comment, setComment] = useState("");
    const user = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);

    // Comment validations
    const handleOnChange = (value) => {
        setComment(value);
    }

    const addReviewModal = () => {
        let error = false;
        if (rating <= 0) {
            setRatingError(true);
            error = true;
        } else {
            setRatingError(false);
        }
        if (!error) {
            let formData = {
                Comments: comment,
                Del_User_Email_Id: alldelireviewsandratings?.Del_User_Email_Id,
                Name: user?.User_Name,
                Order_Id: dorderid,
                Order_Line_Item_Id: dorderlineid,
                Rating: rating,
                Status: "Active",
                User_Email_Id: user?.Email_Id,
            }
            responseFunction(formData);
        }
    }

    return (
        <View style={{ margin: '4%' }}>
            <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                theme={{ colors: { primary: '#27B6CC' } }}
                label='Add Review'
                mode='outlined'
                onChangeText={(Text) => { handleOnChange(Text); }}
                multiline={true}
                numberOfLines={4}
                value={comment}
            />
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center'
            }}>
                <EsiRating
                    type='heart'
                    ratingBackgroundColor='#1a1a1a'
                    tintColor= {esiColor.TintColor}
                    // ratingTextColor="#1a1a1a"
                    // ratingBackgroundColor="#1a1a1a"
                    startingValue={rating || 0}
                    ratingCount={5}
                    imageSize={30}
                    style={{ paddingVertical: 10 }}
                    onFinishRating={(value) => { setRating(value); setRatingError(false) }}
                />
                <HelperText>
                    {ratingError &&
                        <Text style={{ color: "red" }}>
                            Please select the Rating.
                        </Text>
                    }
                </HelperText>
                <View style={{ fontSize: 20 }}>
                    <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5 }} onPress={() => addReviewModal()}>
                        <Text style={{ color: esiColor.itemButtenColor, margin: 10 }}>SUBMIT</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}