import React from "react";
import { Card, Text } from 'react-native-paper';
import { View, Image, TouchableOpacity, Dimensions, ImageBackground } from "react-native";
import { useSelector } from "react-redux";
import default_image from '../../assets/images/coin.png';

export default function ESIDealsCard(props: any) {
    //data is a Deals object as we use this object to display the info front-end
    //toggleModal param is used to call the Deal details popup screen
    const esiColor = useSelector(state => state.theme);
    const { data, toggleModal, ...rest } = props
    const sampleData =
    {
        Records_Filter: "ALL",
        Brand_Id: [],
        Partner_Details_Id: [],
        Category_Id: [],
        Deal_Id: [],
        Discount_Type: "",
        Discount: "",
        Esi_Cashback_Type: "",
        Is_Top_Deal: "",
        Whats_Hot: "",
        Verified_Deal: "",
        Exclusive: "",
        Extra_Cashback: "",
        Live_Status: "",
        Status: "Active",
        Type: "",
    };
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimensions = dimensions / 2;

    return (
        <Card theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{
            maxWidth: 230, width: (halfdimensions - 20), margin: 5,
            shadowColor: esiColor.brandShadowColor, borderRadius: 10, borderWidth: 0.5, shadowRadius: 10, elevation: 10, opacity: 10,
            backgroundColor: data?.Expired_Status === "Live" ? esiColor.CBColor : esiColor.CBColor,

        }}>
            <View style={{ flexDirection: 'row', justifyContent: "space-between", marginBottom: 2 }}>
                {/* <Image source={{ cache: "force-cache", uri: data?.Logo_Path }} style={{ flex: 2, marginVertical: 5, alignItems: 'flex-start', maxHeight: 40, width: '60%', opacity: data?.Expired_Status === "Live" ? 2.5 : 0.3 }} /> */}

                {parseInt(data?.Discount) != 0 &&
                    <View style={{ alignItems: 'flex-end', flex: 2, marginTop: 5, borderRadius: 10 }}>
                        <Text style={{ padding: 2, fontSize: 14, color: data?.Expired_Status === "Live" ? esiColor.SIconColor : "gray", borderTopLeftRadius: 3, borderBottomLeftRadius: 3, marginLeft: 10 }}>{data?.Discount_Type == 'A' && data?.Discount + "/-"}{(data?.Discount_Type == 'P' || data?.Discount_Type == 'percentage' || data?.Discount_Type == 'Percentage') && data?.Discount + "%"} Off</Text>
                    </View>
                }
            </View>
            <View style={{
                flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'
            }}>
                <ImageBackground style={{ height: 100, width: "100%", backgroundColor: esiColor.ImgBck }}>
                    <Image source={{ cache: "force-cache", uri: data || data.Image.length > 1 ? data.Image : default_image + `?w=200&h=100` }} style={{ height: 100, width: "100%" }} resizeMode='contain'></Image>
                </ImageBackground>

            </View>
            <View style={{ alignSelf: 'center', flexDirection: 'column' }}>
                <Text style={{ textAlign: 'center', color: data?.Expired_Status === "Live" ? esiColor.itemColor : 'gray', opacity: 0.8, fontSize: 14, marginVertical: 2 }}>{data.Title?.substring(0, 20)}...</Text>
                <View style={{ flexDirection: "row", justifyContent: "flex-start", marginVertical: 2 }}>
                    <Image source={(data?.Esi_Cashback_Type != 'R') ? require("../../assets/images/cashback.gif") : require("../../assets/images/cashback.gif")} style={{ height: 30, width: "40%", resizeMode: 'contain' }} />
                    <Text style={{ color: data?.Expired_Status === "Live" ? esiColor.amountColor : 'gray', fontWeight: "bold", marginTop: 5 }} >  {data?.Cashback_Label?.toLowerCase()} {data?.Esi_Cashback_Amount}{(data?.Esi_Cashback_Type == 'P' || data?.Esi_Cashback_Type == 'percentage' || data?.Esi_Cashback_Type == 'Percentage') ? '%'
                        : (data?.Esi_Cashback_Type != 'R') ? '/-' : "*"}
                    </Text>
                </View>

                {data?.Expired_Status == "Live" ?
                    <Text style={{ fontSize: 10, textAlign: 'center', color: esiColor.DescColor }}>Deal will expire on {data?.End_Date}</Text> : data?.Expired_Status == "Ideal" ?
                        <Text style={{ fontSize: 10, textAlign: 'center', color: esiColor.DescColor }}>Deal valid from {data?.Start_Date}</Text> : <Text style={{ fontSize: 10, textAlign: 'center', color: esiColor.DescColor }}>Deal has expired on {data?.End_Date}</Text>}

            </View>
            {/* </View> */}
            <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginVertical: 4 }}>
                <TouchableOpacity disabled={data?.Expired_Status === "Live" ? false : true} onPress={() => { data?.Expired_Status === "Live" && toggleModal(data) }}
                    style={{
                        backgroundColor: data?.Expired_Status === "Live" ? esiColor.globalButtonColor : 'lightgrey', borderRadius: 20, paddingVertical: 5,
                        paddingHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center',
                    }}>
                    <Text style={{ color: data?.Expired_Status === "Live" ? esiColor.itemButtenColor : "#000", fontWeight: 'bold' }}>Grab Deal</Text>
                </TouchableOpacity>
            </View>
        </Card>
    );
}

