import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getMfhZones } from "../../state/actions/node-actions/mfh-actions";


export default function MfhZones(props) {
    const { navigation, dataReload, dataErrorReload } = props;
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();

    const [AllPartners, setAllPartners] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setAllPartners(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            partnersCall();
        } else {
            await delay(dataErrorReload);
            partnersCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);


    useEffect(() => {
        partnersCall()
    }, []);

    const partnersCall = () => {
        setIsLoading(true);
        dispatch(getMfhZones({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : ""
        },
            { PageNo: 1, PageSize: 10, SortBy: "MEEPAISA_ZONE_ID", SortOrder: "DESC" }
            , callBackGetData));
    }

    const handleClick = async (data) => {
        navigation('MFH', { Zone_Id: data?.Zone_Id })
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const styles = StyleSheet.create({
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10,
            height: 70

        },

        item: {
            borderRadius: 30,
            borderWidth: 0.5,
            borderColor: esiColor.BorderColor,
            padding: 4,
            maxHeight: 50,
            maxWidth: 50,
            height: 50,
            width: 50,
            flexDirection: "column",
            marginTop: 5
        }
    });

    return (AllPartners.length > 0 &&
        <View style={styles.content}>
            <FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={AllPartners.slice(0, 10)}
                renderItem={({ item, index, separators }) => (
                    <View key={index} style={{ maxWidth: 55, minWidth: 55 }}>
                        <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                            <Image source={{ cache: "force-cache", uri: item?.Zone_Image }} style={{ height: 25, resizeMode: 'contain', maxWidth: 40, maxHeight: 40, flex: 1, borderRadius: 100 }}></Image>
                        </TouchableOpacity>
                    </View>
                )}
            />
        </View>


    )
}


