import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DataTable, IconButton } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, ActivityIndicator } from 'react-native';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import Header from '../header';
import EsiDatePicker from '../../components/custom/date/date';
import { AddTicketsAction, getPartnersIdAction, getVisitsIdActions, uploadAttachment } from '../../state/actions/missingcashbackActions';
import FileUpload from '../../components/custom/file-upload/upload';
import isEmpty from '../../state/validations/is-empty';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import { uriToBlob } from '../../../utils/uriBlob';
import ESITextInput from '../../components/custom-fields/textInput';


function AddMissingCashback(props: any) {
  const { navigation, valueUpdate, ...rest } = props
  const dispatch = useDispatch();
  const toast = useToast();
  //here iterating Partners name and Partners ID values 

  const EmailID = useSelector(state => state.auth.user.Email_Id);
  const esiColor = useSelector(state => state.theme);


  React.useEffect(() => {
    if (isEmpty(EmailID)) {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }
  }, [EmailID]);


  const [partners, setPartner] = React.useState([]);
  const [onepartner, setOnePartner] = React.useState([]);

  let partneropt = []
  if (partners) {
    if (partners[0]) {
      for (let i = 0; i < partners.length; i++) {
        partneropt.push({
          label: partners[i].Parnter_Name,
          value: partners[i].Store_Id
        })
      }
    }
  }

  //here iterating user visiter ID values 
  let onepartneropt = []
  if (onepartner) {
    if (onepartner[0]) {
      for (let i = 0; i < onepartner.length; i++) {
        onepartneropt.push({
          label: onepartner[i].User_Visit_Id,
          value: onepartner[i].User_Visit_Id,
          Offer_Name: onepartner[i].Offer_Name,
          Offer_Id: onepartner[i].Offer_Id,
        })
      }
    }
  }
  const [selectDate, setSelectDate] = React.useState('');
  const [selectPartner, setSelectPartner] = React.useState('');
  const isSubmited = useSelector(state => state.allCashbacks.CashbackCreate.isLoading);
  // call back function for user visitor data
  const callBackUserVisitsGet = (data: any) => {
    if (data) {
      setPartner(data)
    }
  };
  // call back function for partner data
  const callBackPartnersGet = (data: any) => {
    if (data) {
      setOnePartner(data)
    }
    setUploadFile(false);
  };
  //filter for partner dropdown
  const getDate = (date) => {
    let formData = {
      "Created_Date": date,
      "Records_Filter": "FILTER",
      "User_Email_Id": EmailID
    };
    setOnePartner([])
    setSelectPartner('')
    dispatch(getPartnersIdAction(formData, callBackUserVisitsGet));

  }
  // filter for table
  const getPartner = (data) => {
    let formData = {
      "Created_Date": selectDate,
      "Store_Id": data.value,
      "Records_Filter": "FILTER",
      "User_Email_Id": EmailID
    };
    dispatch(getVisitsIdActions(formData, callBackPartnersGet));
  }

  // img upload
  const [file, setFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [uploadFile, setUploadFile] = useState(false);

  const callBackUploadFile = (data: any) => {
    if (data) {
      setFileUrl(data.File_URL);
    }
    setUploadFile(false);
  };

  const handleFile = async (result: any) => {

    if (result) {
      let call = result.uri
      let fileData = {
        fileName: result.name.split(".")[0],
        ext: result.name.split(".")[1],
        missingCashbackId: "B-R-0000003",
      };
      const imageData = await fetchFileFromUri(call);
      setFile(result.name);
      dispatch(uploadAttachment(imageData, fileData, callBackUploadFile));
    };
  }
  const fetchFileFromUri = async (uri: any) => {
    const blob = await uriToBlob(uri);
    return blob;
  };
  const handleRemoveFile = e => {
    setFileUrl('');
    setFile('');
  };


  //Dropdowns
  const [showDropDownpartners, setShowDropDownpartners] = React.useState('');
  const [showDropDown, setShowDropDown] = React.useState('');
  /*====================================
          Handler
  ====================================*/

  const styles = StyleSheet.create({
    cardCircle: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "2%",
      marginVertical: 10
    },
    tableheader: {
      width: 120,
      justifyContent: 'center'
    },
    container: {
      backgroundColor: 'white',
      paddingTop: 15
    },
    // images: {
    //   width: '100%',
    //   height: 100,
    //   borderRadius: 5,
    //   marginBottom: 15
    // },
    files: {
      backgroundColor: 'grey',
    },
    containerone: {
      backgroundColor: 'white',
      paddingTop: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.Text,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    placeholderStyle: {
      fontSize: 16,
    },
    selectedTextStyle: {
      fontSize: 16,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    datePickerStyle: {
      width: 358,
      height: 60,
      marginTop: 6,
    },

  });

  return (
    <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1 }}>
      <Header {...props} />
      {navigation.canGoBack() &&
        <IconButton
          icon="arrow-left"
          size={27}
          color={esiColor.SIconColor}
          style={{ marginBottom: -5, zIndex: 1, marginTop: 2 }}
          onPress={() => navigation.goBack()} />}
      <ScrollView>
        <Formik initialValues={{
          Coupon_Code: "",
          Created_By: EmailID,
          Invoice_Attachment: fileUrl,
          Order_Or_Booking_Id: "",
          Status: "Pending",
          Total_Amount: "",
          User_Email_Id: EmailID,
          User_Visit_Id: ""
        }}
          //field validations
          validationSchema={Yup.object().shape({
            Coupon_Code: Yup.string().required('Coupon Code is required.'),
            Total_Amount: Yup.number().required('Total Amount is required.'),
            Order_Or_Booking_Id: Yup.string().required('Booking ID is required.'),
            User_Visit_Id: Yup.string().required('User Visit ID is required.'),
          })}
          onSubmit={(values: any, { setSubmitting, setErrors, resetForm }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};

            values.Invoice_Attachment = fileUrl;
            if (!values.Invoice_Attachment) {
              submitTest = false;
              errors.Invoice_Attachment = "Invoice Attachment is required.";
            }
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              dispatch(AddTicketsAction(formValues, navigation, toast, resetForm));
            }
          }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <Card style={[styles.cardCircle, { backgroundColor: esiColor.BackgroundColor }]}>
              <Card.Content>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginBottom: 5 }}>
                    <EsiDatePicker
                      label={'DATE OF TRANSACTION'}
                      style={{ width: "100%" }}
                      value={selectDate}
                      valueUpdate={date => {
                        setSelectDate(date)
                        getDate(date)
                      }} />
                    {selectDate && isEmpty(partners) ? <Text style={{ fontSize: 12, padding: 10, color: 'red' }}>
                      Sorry, we do not see any transactions from your account on this date. Please select a valid date of transaction.
                    </Text> : null}
                  </View>
                  <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                    <View style={[styles.containerone, { backgroundColor: esiColor.BackgroundColor }]}>
                      <Text style={[styles.dropdownlabel, Boolean(touched.Parnter_Name && errors.Parnter_Name) && { color: '#b00020' }]}>
                        Partner Name
                      </Text>
                      <EsiSearchDropdown
                        style={[styles.dropdown, Boolean(touched.Parnter_Name && errors.Parnter_Name) && { borderColor: '#b00020' }]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={partneropt}
                        maxHeight={180}
                        search
                        labelField="label"
                        valueField="value"
                        searchPlaceholder="Search..."
                        value={selectPartner}
                        onUpdateValue={data => {
                          setFieldValue('Store_Id', data.value)
                          setShowDropDownpartners(false);
                          setSelectPartner(data.value)
                          getPartner(data)
                        }}
                      />
                      <HelperText type="error" visible={Boolean(touched.Parnter_Name && errors.Parnter_Name)}>
                        {touched.Parnter_Name && errors.Parnter_Name}
                      </HelperText>
                    </View>
                  </View>
                </View>
                <View >
                  <ScrollView horizontal>
                    <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor }}>
                      <DataTable.Header style={{ borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 0.5 }}>
                        <DataTable.Title style={styles.tableheader}><Text style={{ color: esiColor.esiMainColor, fontSize: 14, fontWeight: 'bold' }}>User Visit ID</Text></DataTable.Title>
                        <DataTable.Title style={styles.tableheader}><Text style={{ color: esiColor.esiMainColor, fontSize: 14, fontWeight: 'bold' }}>Offer Type</Text></DataTable.Title>
                        <DataTable.Title style={styles.tableheader}><Text style={{ color: esiColor.esiMainColor, fontSize: 14, fontWeight: 'bold' }}>Partner Name</Text></DataTable.Title>
                        {/* <DataTable.Title style={styles.tableheader}><Text style={{ color: 'rgb(36, 113, 163)', fontSize: 14, fontWeight: 'bold' }}>Amount</Text></DataTable.Title> */}
                        <DataTable.Title style={styles.tableheader}><Text style={{ color: esiColor.esiMainColor, fontSize: 14, fontWeight: 'bold' }}>Status</Text></DataTable.Title>
                      </DataTable.Header>
                      {onepartner.map((partner, i) => (
                        <DataTable.Row key={i}>
                          <DataTable.Cell style={styles.tableheader}><Text style={{ color: esiColor.Text }} >{partner.User_Visit_Id}</Text></DataTable.Cell>
                          <DataTable.Cell style={styles.tableheader}><Text style={{ color: esiColor.Text }}>{partner.Offer_Type}</Text></DataTable.Cell>
                          <DataTable.Cell style={styles.tableheader}><Text style={{ color: esiColor.Text }}>{partner.Parnter_Name}</Text></DataTable.Cell>
                          {/* <DataTable.Cell style={styles.tableheader}><Text style={{ color: esiColor.Text}}>{partner.Approved_Cashback_Amount}</Text></DataTable.Cell> */}
                          <DataTable.Cell style={styles.tableheader}><Text style={{ color: esiColor.Text }}>{partner.Status}</Text></DataTable.Cell>
                        </DataTable.Row>
                      ))
                      }
                      <View style={{ alignItems: 'center', padding: 10 }}>{isEmpty(onepartner) &&
                        <Text style={{ fontSize: 16, color: esiColor.NoDataFound }}>
                          No records found.
                        </Text>
                      }</View>
                    </DataTable>
                  </ScrollView>
                </View>
                <View>
                  <Text style={{ fontSize: 14, margin: 10, fontWeight: "bold", color: esiColor.Text }}>
                    ENTER MISSING CASHBACK DETAILS
                  </Text>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <ESITextInput selectionColor='rgba(0, 0, 0, 0.5)'
                        label='Order/Booking ID'
                        mode='outlined'
                        name="Order_Or_Booking_Id"
                        value={values.Order_Or_Booking_Id}
                        onChangeText={handleChange('Order_Or_Booking_Id')}
                        error={Boolean(touched.Order_Or_Booking_Id && errors.Order_Or_Booking_Id)}
                      />
                      <HelperText type="error" visible={Boolean(touched.Order_Or_Booking_Id && errors.Order_Or_Booking_Id)}>
                        <ErrorMessage name="Order_Or_Booking_Id" />
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <ESITextInput selectionColor='rgba(0, 0, 0, 0.5)'
                        label='Total Amount'
                        mode='outlined'
                        name="Total_Amount"
                        value={values.Total_Amount}
                        onChangeText={handleChange('Total_Amount')}
                        keyboardType="numeric"
                        error={Boolean(touched.Total_Amount && errors.Total_Amount)}
                      />
                      <HelperText type="error" visible={Boolean(touched.Total_Amount && errors.Total_Amount)}>
                        <ErrorMessage name="Total_Amount" />
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <View style={[styles.container, { backgroundColor: esiColor.BackgroundColor }]}>
                        <Text style={[styles.dropdownlabel, Boolean(touched.User_Visit_Id && errors.User_Visit_Id) && { color: '#b00020' }]}>
                          User Visit ID
                        </Text>
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(touched.User_Visit_Id && errors.User_Visit_Id) && { borderColor: '#b00020' }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          data={onepartneropt}
                          maxHeight={180}
                          labelField="label"
                          valueField="value"
                          searchPlaceholder="Search..."
                          value={values.User_Visit_Id}
                          onUpdateValue={item => {
                            setFieldValue('User_Visit_Id', item?.value)
                            setFieldValue('Coupon_Code', item.Offer_Id)
                          }}
                        />
                        <HelperText type="error" visible={Boolean(touched.User_Visit_Id && errors.User_Visit_Id)}>
                          {touched.User_Visit_Id && errors.User_Visit_Id}
                        </HelperText>
                      </View>
                    </View>
                    {/* Dropdown for Categorys  */}
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <View style={[styles.container, { backgroundColor: esiColor.BackgroundColor }]}>
                        <Text style={[styles.dropdownlabel, Boolean(touched.Offer_Name && errors.Offer_Name) && { color: '#b00020' }]}>
                          Offer Name
                        </Text>
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(touched.Offer_Name && errors.Offer_Name) && { borderColor: '#b00020' }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          data={onepartneropt}
                          maxHeight={180}
                          disable
                          labelField="Offer_Name"
                          valueField="Offer_Id"
                          searchPlaceholder="Search..."
                          value={values.Coupon_Code}
                          onUpdateValue={item => {
                            setFieldValue('Coupon_Code', item.Offer_Id)
                            setShowDropDown(false);
                          }}
                        />
                        <HelperText type="error" visible={Boolean(touched.Offer_Name && errors.Offer_Name)}>
                          {touched.Offer_Name && errors.Offer_Name}
                        </HelperText>
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <View style={{ flexShrink: 1, minWidth: 100 }} >
                      <FileUpload style={{ borderRadius: 6, borderWidth: 1, backgroundColor: esiColor.globalButtonColor }} filestyle={styles.files} handleRemoveFile={handleRemoveFile} file={file} uploadFile={handleFile} />
                      <HelperText type="error" visible={!fileUrl && touched.Invoice_Attachment && errors.Invoice_Attachment}>
                        {!fileUrl && touched.Invoice_Attachment && errors.Invoice_Attachment}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", }}>
                    {(isSubmited || uploadFile) ? <ActivityIndicator color={esiColor.SIconColor} /> :
                      <Button disabled={isSubmited || uploadFile} style={{ marginTop: "3%", }} color={esiColor.globalButtonColor} mode="contained" onPress={handleSubmit}>
                        Save
                      </Button>}
                  </View>
                </View>
              </Card.Content>
            </Card>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};



export default AddMissingCashback
