import * as React from 'react';
import { Avatar, Button, Colors, HelperText, ProgressBar, Surface, Text, TextInput, TouchableRipple } from
    'react-native-paper';
import { Linking, View, Dimensions, ScrollView, ActivityIndicator } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loginCheckNew, signInMobileExist } from '../../state/actions/authActions';
import { Platform } from 'react-native';
import Modal from "react-native-modal";
import * as Device from 'expo-device';
import * as Network from 'expo-network';
import * as Location from "expo-location";
import Constants from 'expo-constants';
import api from '../../state/actions/api';
import { getLocationByIpAddress } from '../../state/actions/node-actions/home-actions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ESITextInput from '../../components/custom-fields/textInput';
import SignUPNew from './signupNew';
import { isNumber } from '../../../utils';
import isEmpty from '../../state/validations/is-empty';

function Copyright() {
    const esiColor = useSelector(state => state.theme);
    return (

        <Text onPress={() => {
            Linking.openURL("https://www.easystepin.com/").catch((err) =>
                console.error('An error occurred', err),
            );
        }
        } style={{ color: esiColor.itemColor }} > {'Copyright © '}
            EasyStepIn
            {' 2016 - '}
            {new Date().getFullYear()}
            {'.'}
        </Text>

    );
}

export default function SignIN(props) {
    const esiColor = useSelector(state => state.theme);
    const { navigation, toggleModal, dropDownAlertFunHead } = props;
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const dispatch = useDispatch();
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [deviceLocation, setLocation] = React.useState({});
    const [ipBasedLocation, setIpBasedLocation] = React.useState(true);
    const [locationLoad, setLocationLoad] = React.useState(false);
    const [locationStock, setLocationStock] = React.useState(false);
    let deviceModelName = Device.modelName;
    const [deviceIPAddress, setDeviceIPAddress] = React.useState("");
    const [notificationToken, setNotificationToken] = React.useState("");

    React.useEffect(() => {
        const { referral } = props?.route?.params || false;
        if (referral) {
            setSignUpModalVisible(true);
        }

        (async () => {
            if (Platform.OS != "web") {
                const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');
                setNotificationToken(ExpoPushToken || "");
            }

            setLocationLoad(true);
            let iPAddress = await Network.getIpAddressAsync();
            setDeviceIPAddress(iPAddress);
            if (Platform.OS === 'android' && !Constants.isDevice) {
                alert(
                    'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
                );
                return;
            }
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                setLocationStock(true);
                setLocationLoad(false);
                // Alert.alert('Location Service not enabled', 'Please enable your location services to continue', [{ text: 'OK' }], { cancelable: false });
                return;
            } else {
                setLocationStock(false);

            }

            const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
            setLocation({ latitude, longitude });
            api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
                let locationInfo = response.data.display_name;
                setLocation({ latitude, longitude, locationInfo });
                setLocationLoad(false);
            }).catch(async (reason) => {
                setLocationLoad(false);
            });
        })();

        (async () => {
            let iPAddress = await Network.getIpAddressAsync();
            const callbackResponse = (data) => {
                if (data && data.length > 1)
                    setIpBasedLocation({ locationInfo: data?.city + ',' + data?.region, latitude: data?.ll[0], longitude: data?.ll[1] });
                else
                    setIpBasedLocation(false);
            }
            dispatch(getLocationByIpAddress({ iPAddress: iPAddress }, callbackResponse));
        })();



    }, []);
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [secondsP, setSecondsP] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => secondsP > 0 ? setSecondsP(secondsP - 1) : setSecondsP(-1), 1000)
        return () => clearTimeout(timer)
    }, [secondsP]);

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);


    const [errorMessage, setErrorMessage] = React.useState('');
    const [accountActivate, setAccountActivate] = React.useState(false);

    const signinCallResponse = (status: any, errorMessage: any, field: any) => {
        setIsSubmited(false);
        if (status) {
            dropDownAlertFunHead('info', 'Success', 'You have successfully logged in.');
            if (Platform.OS == "web") {
                localStorage.setItem('signIn', new Date().toISOString());
            }
            toggleModal(true);
        }
        else {
            setErrorMessage(errorMessage);
            setSeconds(3);
            if (field == "activate") {
                setAccountActivate(true);
            }
        }
    }


    const [isForgotModalVisible, setForgotModalVisible] = React.useState(false);
    const toggleForgotModal = () => {
        setForgotModalVisible(!isForgotModalVisible);
        formikRef.current?.resetForm();
        formikRefM.current?.resetForm();
        setOtpMobileNumber("");
    };

    const [isSignUpModalVisible, setSignUpModalVisible] = React.useState(false);
    const toggleSignUpModal = (close: any) => {
        if (close === true) {
            toggleModal();
        }
        setSignUpModalVisible(!isSignUpModalVisible);
    };


    const [signUpSuccess, setSignUpSuccess] = React.useState(false);
    const signUpSuccessResponse = () => {
        setSignUpSuccess(true);
    }

    const [otpMobileNumber, setOtpMobileNumber] = React.useState("");
    const [otpMobileNumberError, setOtpMobileNumberError] = React.useState([]);
    const formikRef = React.useRef();
    const formikRefM = React.useRef();
    const onMobileFieldOnBlur = () => {
        formikRef.current?.handleSubmit();
        if (!(formikRef.current?.errors.Mobile) && otpMobileNumber != formikRef.current?.values.Mobile) {
            setOtpMobileNumber(formikRef.current?.values.Mobile);
            dispatch(signInMobileExist(formikRef.current?.values.Mobile, mobileCheckResponse));
        }
        else if (otpMobileNumber == formikRef.current?.values.Mobile) {
        }
        else {
            setOtpMobileNumber("");
        }
    }

    const mobileCheckResponse = (status: any, errorMessage: any, response: any, input: any) => {
        if (status) {
            setSecondsP(30);
        } else {
            let newOtpMobileNumberError = JSON.parse(JSON.stringify(otpMobileNumberError));
            newOtpMobileNumberError.push(input);
            setOtpMobileNumberError(newOtpMobileNumberError);
        }
    }
    React.useEffect(() => {
        let error = false;
        for (let i = 0; i <= otpMobileNumberError.length; i++) {
            if (otpMobileNumberError[i] == formikRef.current?.values.Mobile) {
                error = true;
                break;
            }
        }
        if (error)
            formikRef.current?.setFieldError("Mobile", 'Invalid Mobile Number.');
    }, [otpMobileNumberError])

    return (
        <View style={{
            alignItems: 'flex-end',
            justifyContent: 'center',
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor
        }} >


            <TouchableRipple style={{ width: 36 }} onPress={() => {
                toggleModal()
            }}>
                <Avatar.Icon color={esiColor.SBTextColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
            </TouchableRipple>
            <View
                style={Platform.OS === 'web' ? {
                    alignItems: 'center',
                    elevation: 0,
                    // overflowY: "scroll",
                    minHeight: 450,
                    maxHeight: 480,
                    backgroundColor: esiColor.BackgroundColor,

                } : {
                    alignItems: 'center',
                    elevation: 0,

                }} >
                <ScrollView keyboardShouldPersistTaps='always' style={{
                    minHeight: 420,
                    maxHeight: 450,
                }} >
                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginVertical: 10,
                    }}>
                        <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }} />
                        <Text style={{ color: esiColor.itemButtenColor }}>Sign In</Text>
                    </View>
                    {isForgotModalVisible ?
                        <Formik
                            innerRef={formikRef}

                            initialValues={
                                {
                                    Mobile: '',
                                    Mobile_OTP: ''
                                }
                            }
                            validationSchema={Yup.object().shape({
                                Mobile: Yup.string().notOneOf(otpMobileNumberError, 'In valid Mobile Number.').min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
                                Mobile_OTP: Yup.string().when('Mobile', (Mobile, schema) => {
                                    if (Mobile?.length == 10) {
                                        return schema.min(6, 'Please provide valid OTP.')
                                            .required('Mobile OTP is required.');
                                    } else {
                                        return schema
                                    }
                                })
                            })}
                            onSubmit={values => {
                                let sessignExist = null;
                                if (Platform.OS == "web") {
                                    sessignExist = localStorage.getItem('signIn');
                                }
                                if (sessignExist === null) {
                                    setIsSubmited(true);
                                    let formdata = {
                                        "Device_Identity": deviceModelName,
                                        "Email_ID": !isNumber(values.Mobile) ? values.Mobile : "",
                                        "Mobile": isNumber(values.Mobile) ? "+91 " + values.Mobile : "",
                                        "IP_Address": deviceIPAddress,
                                        "Latitude": deviceLocation?.latitude || ipBasedLocation?.latitude || "",
                                        "Location": deviceLocation?.locationInfo || ipBasedLocation?.locationInfo || "",
                                        "Longitude": deviceLocation?.longitude || ipBasedLocation?.longitude || "",
                                        "Mobile_OTP": values.Mobile_OTP,
                                        "Expo_Push_Notification": "",
                                    }
                                    if (Platform.OS != "web") {
                                        formdata.Expo_Push_Notification = notificationToken;
                                    }
                                    setAccountActivate(false);
                                    dispatch(loginCheckNew(formdata, signinCallResponse))
                                } else {
                                    if (Platform.OS == "web") {
                                        window.location.reload()
                                    }
                                }

                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                <View style={{
                                    flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <View style={{ width: "90%", margin: "auto" }}>
                                        <ESITextInput
                                            style={{ width: "100%" }}
                                            label="Mobile Number*"
                                            mode='outlined'
                                            onChangeText={handleChange('Mobile')}
                                            onBlur={(props) => {
                                                handleBlur(props);
                                                onMobileFieldOnBlur();
                                            }}
                                            value={values.Mobile}
                                            left={<TextInput.Affix textStyle={{ color: esiColor.Text }} text="+91" />}
                                            error={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}
                                        />
                                        <HelperText type="error" visible={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}>
                                            {(touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile}
                                        </HelperText>
                                        {(!isEmpty(otpMobileNumber) && secondsP > 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                            <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right" }}
                                                visible={!isEmpty(otpMobileNumber)}>
                                                {secondsP} Secs To Resend OTP
                                            </HelperText>}
                                        {(!isEmpty(otpMobileNumber) && secondsP <= 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                            <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right" }}
                                                onPress={() => {
                                                    dispatch(signInMobileExist(otpMobileNumber, mobileCheckResponse));
                                                }}
                                                visible={!isEmpty(otpMobileNumber)}>
                                                Resend OTP
                                            </HelperText>}
                                    </View>
                                    {(!isEmpty(otpMobileNumber) && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                        <View style={{ width: "90%", margin: "auto" }}>
                                            <ESITextInput
                                                style={{ width: "100%" }}
                                                label="Mobile OTP"
                                                mode='outlined'
                                                onChangeText={handleChange('Mobile_OTP')}
                                                onBlur={handleBlur}
                                                value={values.Mobile_OTP}
                                                error={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}>
                                                {touched.Mobile_OTP && errors.Mobile_OTP}
                                            </HelperText>
                                        </View>
                                    }
                                    <View style={{
                                        width: "90%", margin: "auto", marginTop: -5, alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                                            height: 5,
                                            width: 300,
                                        }} progress={.30 * seconds} color={Colors.red900} /></>}
                                    </View>
                                    {isSubmited ?
                                        <View style={{
                                            width: "90%", margin: "auto", marginTop: 5, alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <ActivityIndicator color='#27b6cc' style={{ margin: "auto" }} />
                                        </View> :
                                        !(!isEmpty(otpMobileNumber) && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) ?
                                            <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} disabled={isSubmited} mode="contained" onPress={() => {
                                                if (!(formikRef.current?.errors.Mobile) && otpMobileNumber != formikRef.current?.values.Mobile) {
                                                    setOtpMobileNumber(formikRef.current?.values.Mobile);
                                                    dispatch(signInMobileExist(formikRef.current?.values.Mobile, mobileCheckResponse));
                                                }
                                                else if (otpMobileNumber == formikRef.current?.values.Mobile) {
                                                }
                                                else {
                                                    setOtpMobileNumber("");
                                                }
                                            }}>
                                                Send OTP
                                            </Button> :
                                            <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} disabled={isSubmited} mode="contained" onPress={handleSubmit}>
                                                <Text style={{ color: esiColor.itemButtenColor }}>Sign In</Text>
                                            </Button>}



                                    <View style={{ width: "90%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>

                                        <TouchableRipple
                                            onPress={toggleForgotModal}
                                        >
                                            <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.itemColor }}>Sign In using Password?</Text>
                                        </TouchableRipple>

                                        <TouchableRipple
                                            onPress={toggleSignUpModal}
                                        >
                                            <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.itemColor }}>Don't have an account? Sign Up</Text>
                                        </TouchableRipple>
                                    </View>
                                </View>
                            )}
                        </Formik>
                        : <Formik
                            innerRef={formikRefM}
                            initialValues={
                                {
                                    Email_Id: '',
                                    password: '',
                                    Mobile: '',
                                }
                            }
                            validationSchema={Yup.object().shape({
                                Email_Id: Yup.string().when('Mobile', (Mobile, schema) => {
                                    if (isNumber(Mobile) && (Mobile?.length > 10 || Mobile?.length < 10)) {
                                        return schema.email('Please provide valid Mobile or Email.')
                                            .max(255);
                                    }
                                    else if (!(Mobile?.length) || (Mobile?.length == 0)) {
                                        return schema.required("Mobile or Email is required.")
                                    }
                                    else if (!isNumber(Mobile)) {
                                        return schema.email('Please provide valid Mobile or Email.')
                                            .max(255)
                                    }
                                }).required("Mobile or Email is required.")
                                ,
                                Mobile: Yup.string(),
                                password: Yup.string()
                                    .max(255)
                                    .required('Password is required.')
                            })}
                            onSubmit={values => {
                                let sessignExist = null;
                                if (Platform.OS == "web") {
                                    sessignExist = localStorage.getItem('signIn');
                                }
                                if (sessignExist === null) {
                                    setIsSubmited(true);
                                    let formdata = {
                                        "Device_Identity": deviceModelName,
                                        "Email_ID": !isNumber(values.Email_Id) ? values.Email_Id : "",
                                        "Mobile": isNumber(values.Email_Id) ? "+91 " + values.Email_Id : "",
                                        "IP_Address": deviceIPAddress,
                                        "Latitude": deviceLocation?.latitude || ipBasedLocation?.latitude || "",
                                        "Location": deviceLocation?.locationInfo || ipBasedLocation?.locationInfo || "",
                                        "Longitude": deviceLocation?.longitude || ipBasedLocation?.longitude || "",
                                        "Password": values.password,
                                        "Expo_Push_Notification": ""
                                    }
                                    if (Platform.OS != "web") {
                                        formdata.Expo_Push_Notification = notificationToken;
                                    }
                                    setAccountActivate(false);
                                    dispatch(loginCheckNew(formdata, signinCallResponse))
                                } else {
                                    if (Platform.OS == "web") {
                                        window.location.reload()
                                    }
                                }
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                <View style={{
                                    flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <View style={{ width: "90%", margin: "auto" }}>
                                        <ESITextInput style={{ width: "100%", margin: "auto" }}
                                            label="Mobile or Email"
                                            mode='outlined'
                                            onChangeText={(props) => {
                                                setFieldValue('Email_Id', props);
                                                setFieldValue('Mobile', props);
                                            }}
                                            onBlur={(props) => {
                                                handleBlur(props);
                                            }}
                                            value={values.Email_Id}
                                            error={Boolean(touched.Email_Id && errors.Email_Id)}
                                        />
                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Email_Id && errors.Email_Id)}>
                                            {touched.Email_Id && errors.Email_Id}
                                        </HelperText>
                                    </View>
                                    <View style={{ width: "90%", margin: "auto" }}>
                                        <ESITextInput
                                            style={{ width: "100%" }}
                                            label="Password"
                                            mode='outlined'
                                            onChangeText={handleChange('password')}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            error={Boolean(touched.password && errors.password)}
                                            secureTextEntry={!show}
                                            right={<TextInput.Icon onPress={() => setShow(!show)} name={show ? "eye" : "eye-off"} />}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.password && errors.password)}>
                                            {touched.password && errors.password}
                                        </HelperText>
                                    </View>
                                    <View style={{
                                        width: "90%", margin: "auto", marginTop: -5, alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                                            height: 5,
                                            width: 300,
                                        }} progress={.30 * seconds} color={Colors.red900} /></>}
                                    </View>
                                    {isSubmited ?
                                        <View style={{
                                            width: "90%", margin: "auto", marginTop: 5, alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <ActivityIndicator color='#27b6cc' style={{ margin: "auto" }} />
                                        </View> :
                                        <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} disabled={isSubmited} mode="contained" onPress={handleSubmit}>
                                            <Text style={{ color: esiColor.itemButtenColor }}>Sign In</Text>
                                        </Button>}

                                    {accountActivate &&
                                        <View style={{ width: "90%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'center' }}>
                                            { }
                                            <TouchableRipple
                                                onPress={toggleForgotModal} disabled={isSubmited}
                                            >
                                                <Text style={{ fontSize: 14, textDecorationLine: 'underline' }}>Activate and Sign In by using OTP.</Text>
                                            </TouchableRipple>


                                        </View>
                                    }

                                    <View style={{ width: "90%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>

                                        <TouchableRipple
                                            onPress={toggleForgotModal}
                                        >
                                            <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.itemColor }}>Sign In using OTP?</Text>
                                        </TouchableRipple>

                                        <TouchableRipple
                                            onPress={toggleSignUpModal}
                                        >
                                            <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.itemColor }}>Don't have an account? Sign Up</Text>
                                        </TouchableRipple>
                                    </View>
                                </View>
                            )}
                        </Formik>}
                </ScrollView>
                <Copyright />

            </View>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isSignUpModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <SignUPNew navigation={navigation} {...props} dropDownAlertFun={signUpSuccessResponse} toggleModal={toggleSignUpModal} />

                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={signUpSuccess}>
                <View style={{ flex: 1, flexDirection: "column", justifyContent: "center", paddingHorizontal: 15, maxWidth: (dimensions.width - 100) > 300 ? (dimensions.width - 100) : 300, minWidth: 300, minHeight: 100, maxHeight: 250, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, marginTop: -20, alignSelf: "center", borderColor: '#ffffff' }} />
                    <Text style={{ alignSelf: "center", paddingHorizontal: 10, color: esiColor.DescColor }} >Congratulations, your account has been successfully created. Please Sign In. </Text>
                    <Button style={{ marginTop: 15, maxWidth: 150, backgroundColor: esiColor.globalButtonColor, alignSelf: "center" }} mode="contained" onPress={() => { setSignUpSuccess(false) }}>
                        Okay
                    </Button>
                </View>
            </Modal>
        </View >
    );
}