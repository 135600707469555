import React, { useState, useEffect } from "react";
import { Avatar, Button, Colors, IconButton, Text, TouchableRipple } from "react-native-paper";
import { Image, TouchableOpacity, View, Dimensions, ActivityIndicator, Linking, Platform, StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { apiActions } from "../../state/actions";
import isEmpty from "../../state/validations/is-empty";
import SignIN from "../../screens/auth/signin";
import Modal from "react-native-modal";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { adduservisitAction, getProductSizeActionWishList, productsizereset } from "../../state/actions/productsActions";
import * as Network from 'expo-network';
import { addCart, addItemMultipleCart } from "../../state/actions/cartActionsNew";
import { getSingleCartItemCheck } from "../../state/actions/node-actions/home-actions";
import { FlatList } from "react-native";
import QuentityField from "../../components/custom/QuentityField";


export default function AddToCart(props: any) {
    // handle click event is used to go to next page with IDs
    // data is a Product object as we use this object to display the info front-end
    const { data, handleClick, navigation, ProductId, PartnerId } = props;
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(false);
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const [checkWish, setCheckWish] = useState({});
    const [loading, setLoading] = useState(false);
    const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);
    const esiColor = useSelector(state => state.theme);
    const [openredirect, setRedirectionOpen] = React.useState(false);
    const onOpenModal = (data) => {
        let Url = data.Web_Redirection_Url;
        setTimeout(function () {
            Linking.openURL(Url);
            setRedirectionOpen(false);
        }, 2000);
    };
    const [imageURL, setImageURL] = useState("");
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [cartLoading, setCartLoading] = useState(false);

    const [productData, setProductData] = useState();

    const handleClickCart = async (data) => {
        if (EmailID) {
            if (data.Partner_Type === "Online") {
                setRedirectionOpen(true)
                onOpenModal(data);
                setImageURL(data.Logo_Path)
            }
            else {
                dispatch(getProductSizeActionWishList(data.Product_Id, data.Partner_Details_Id, data, sizeAddtoCart));
                setModalIsOpen(true);
                setCartLoading(true);
                setProductData(data);
            }
            let iPAddress = await Network.getIpAddressAsync();
            let formData = {
                User_Email_Id: EmailID,
                Ip_Address: iPAddress,
                Offer_Type: "Product",
                Offer_Id: data.Product_Id,
                Store_Type: data.Partner_Type,
                Store_Id: data.Partner_Details_Id,
                Status: "Active"
            }
            dispatch(adduservisitAction(formData));
        } else {
            setModalVisible(!isModalVisible);
        }
    };

    const [cartSize, setCartSize] = useState([]);
    const [cartErrorMessage, setCartErrorMessage] = useState("");

    const sizeAddtoCart = (status, response, partnerData, message) => {
        setCartLoading(false);
        if (status && !isEmpty(response)) {
            if (response.length > 1) {
                setCartSize(response);
            } else {
                let payload = {
                    "Partner_Details_Id": partnerData.Partner_Details_Id,
                    "Partner_Product_Id": partnerData.Partner_Product_Id,
                    "Quantity": "1",
                    "Size": response[0]?.Product_Size,
                    "User_Email_Id": EmailID
                }

                dispatch(addCart(payload, EmailID, checkCartUpdateResponse));
                dispatch(productsizereset());
            }
        } else {
            setCartErrorMessage(message);
        }
    }
    const [size, setSize] = useState({ size: "", quantity: "" });

    const onCloseModal = () => {
        setSize({ size: "", quantity: "" })
        setModalIsOpen(false);
        setSelected(false);
    };

    useEffect(() => {
        getWishList();
        checkCart();
    }, [EmailID, ProductId])

    const [cart, setCart] = useState({ User_Cart_Id: 0, Quantity: 0 });

    const checkCartResponse = (status, data) => {
        if (status) {
            setCart(data);
        }
    }

    const checkCartUpdateResponse = (data) => {
        if (data?.Response_Status == "Failure") {
            setCartErrorMessage(data?.UI_Display_Message);
        } else {
            checkCart();
        }
    }

    const checkCart = () => {
        if (EmailID && data?.Partner_Product_Id) {
            let filter = {
                "Partner_Product_ID": data?.Partner_Product_Id,
                "User_Email_Id": EmailID,
            }
            dispatch(getSingleCartItemCheck(filter, {}, checkCartResponse))
        }
    }
    const getWishList = () => {
        setCheckWish({});
        if (EmailID && ProductId && PartnerId && !loading) {
            setLoading(true);
            let filter = {
                "Records_Filter": "FILTER",
                "User_Email_Id": EmailID,
                "Product_Id": ProductId,
                "Partner_Details_Id": PartnerId,
                "Route_Filter_Key": '2'
            }
            apiActions.get_wish_list(filter)
                .then(async res => {
                    setLoading(false);
                    let wish_data = res.Wish[0]?.Wish_List;
                    if (!isEmpty(wish_data)) {
                        if (wish_data.length > 0) {
                            setCheckWish(wish_data[0])
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }
    const onLikeClicked = (is_like) => {
        setLoading(true);
        if (!is_like) {
            if (isAuthenticated) {
                var data = {
                    "User_Email_Id": EmailID,
                    "Product_Id": ProductId,
                    "Partner_Details_Id": PartnerId
                }
                apiActions.create_wish_list(data)

                    .then(async res => {
                        setLoading(false);
                        setCheckWish({});
                        getWishList();
                    })
                    .catch(err => {
                        setLoading(false);
                    })
            } else {
                setModalVisible(!isModalVisible);
                setLoading(false);
            }

        } else {

            var data = {
                Wish_Id: checkWish?.Wish_Id
            }

            apiActions.delete_wish_list(data)
                .then(async res => {

                    setLoading(false);
                    getWishList();
                })
                .catch(err => {
                    setLoading(false);
                })

        }
    }
    const toast = useToast();
    const [isModalVisible, setModalVisible] = React.useState(false);

    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
        if (status) {
            onLikeClicked(false);
        }
    };

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const halfdimensions = dimensions.width / 2;
    const [isNotProductAvailable, setIsNotProductAvailable] = React.useState(false);

    const cartData = () => {
        setModalIsOpen(!modalIsOpen);
        setSelected(false);
        let payload = {
            "Partner_Details_Id": productData.Partner_Details_Id,
            "Partner_Product_Id": productData.Partner_Product_Id,
            "Quantity": "1",
            "Size": size.size,
            "User_Email_Id": EmailID
        }

        dispatch(addCart(payload, EmailID));

        dispatch(productsizereset());
        setSize({ size: "", quantity: "" });
        setProductData();
    }

    const handleSelect = (id) => {
        if (id === selected) {
            setSize("")
        }
        setSelected(id !== selected ? id : "")
    };

    return (
        <View >
            <View style={{ flexDirection: "row", justifyContent: 'space-around', alignItems: 'center', marginVertical: 2 }}>
                {cart.User_Cart_Id ?
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity disabled={cart.Quantity <= 1 ? true : false} onPress={(e) => dispatch(addItemMultipleCart({
                            User_Cart_Id: cart.User_Cart_Id,
                            partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                        }, Number(cart.Quantity) - 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: cart.Quantity <= 1 ? esiColor.itemColor : "#27b6cc" }}>
                            <MaterialIcons name="remove" size={22} color={cart.Quantity <= 1 ? esiColor.itemColor : "#27b6cc"} />
                        </TouchableOpacity>
                        {
                            <QuentityField count={cart.Quantity} handleOnChangeCount={(count) => {
                                dispatch(addItemMultipleCart({
                                    User_Cart_Id: cart.User_Cart_Id,
                                    partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                                }, count, checkCartUpdateResponse))
                            }} />
                        }
                        <TouchableOpacity onPress={(e) => dispatch(addItemMultipleCart({
                            User_Cart_Id: cart.User_Cart_Id,
                            partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                        }, Number(cart.Quantity) + 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: '#27b6cc' }}>
                            <MaterialIcons name="add" size={22} color="#27b6cc" />
                        </TouchableOpacity>
                    </View> :
                    <TouchableOpacity onPress={() => {
                        if (data.Is_Open != "1" || data.Is_Product_Available != "1") {
                            setIsNotProductAvailable(!isNotProductAvailable);
                        } else {
                            handleClickCart(data);
                        }
                    }} >
                        <View style={{
                            flexDirection: "row", backgroundColor: esiColor.globalButtonColor, borderRadius: 8, paddingVertical: 5, paddingHorizontal: 20, marginBottom: 4,
                            shadowOffset: { width: 5, height: -3 },
                            shadowColor: 'black',
                            shadowOpacity: 0.1,
                            shadowRadius: 3,
                            elevation: 20,
                        }
                        }>
                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: "bold" }}>Add to Cart </Text>
                        </View>
                    </TouchableOpacity>
                }
            </View>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isNotProductAvailable}>
                <View style={{ flex: 1, maxWidth: 300, minWidth: 200, minHeight: 250, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>

                    <View style={{ marginHorizontal: 10, justifyContent: "center", flexDirection: "column", height: "100%" }} >
                        <Text style={{ textAlign: "center", color: esiColor.itemColor, fontSize: 24 }} >
                            Oops..
                        </Text>
                        <Text style={{ marginVertical: 10, color: esiColor.itemColor }} >
                            Currently the partner is not accepting orders, Please try again.
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-around" }} >
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    color={esiColor.globalButtonColor}
                                    mode="outlined"
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                    }}
                                >

                                    Cancel
                                </Button>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                handleClick(data);
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    color={esiColor.globalButtonColor}
                                    mode="contained"
                                    labelStyle={{ color: "#fff" }}
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                        handleClick(data);
                                    }}
                                >
                                    Okay
                                </Button>
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>

            {/* Partner Redirection Modal */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openredirect}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 150, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <View style={{ alignItems: 'flex-end', marginTop: '-6%' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
                            <Avatar.Icon color={'black'} style={{ backgroundColor: 'white', marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ color: esiColor.itemColor, textAlign: 'center', marginBottom: 10 }}>Prices may vary for this product. Redirecting to partner site. Please wait....</Text>
                    </View>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />
                            <Button color="#27b6cc" icon="arrow-right-bold" style={{ size: 15, marginTop: 10 }} />
                            {imageURL ? <Image source={{ cache: "force-cache", uri: imageURL }} style={{ height: 60, width: 60, resizeMode: 'contain' }} /> : <></>}
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={modalIsOpen}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 100, minHeight: 100, maxHeight: 200, height: 200, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>

                    <View style={{
                        alignItems: 'flex-end', marginTop: -30
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { onCloseModal(); setCartErrorMessage(""); }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    {
                        !isEmpty(cartSize) ?
                            <View>
                                <Text style={{ textAlign: "center", marginTop: -10, marginBottom: 10, fontSize: 16, fontWeight: "bold", color: esiColor.DescColor }}>
                                    Select Size
                                </Text>

                                <View style={{ textAlign: "center", justifyContent: "center", flexDirection: 'row', width: (dimensions.width * 0.9) > 500 ? 400 : (dimensions.width * 0.7) }}>
                                    <FlatList
                                        // horizontal={true}
                                        showsHorizontalScrollIndicator={true}
                                        numColumns={cartSize.length}
                                        data={cartSize}
                                        contentContainerStyle={{ justifyContent: "space-around" }}
                                        renderItem={({ item, index, separators }) => (
                                            <View key={index}>
                                                <TouchableOpacity
                                                    style={selected === item.Product_Size ? styles.productDetailsSize : styles.productDetailsSizeSelect}
                                                    key={index}
                                                    onPress={() => { setSize({ size: item.Product_Size, quantity: item.Product_Available_Stock }); handleSelect(item.Product_Size) }}
                                                >
                                                    <View ><Text style={{ textAlign: "center", justifyContent: "center", marginTop: 16,color:"black" }} >{item.Product_Size}</Text>
                                                    </View>

                                                </TouchableOpacity>
                                                {
                                                    item.Product_Available_Stock ?
                                                        <Text >
                                                            {parseInt(item.Product_Available_Stock) < 11 ?
                                                                <Text style={styles.ribbon}>{item.Product_Available_Stock} Left</Text>
                                                                : null}
                                                        </Text> : null
                                                }
                                            </View>
                                        )}
                                    />
                                </View>

                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 10 }} >
                                    <Button
                                        color={size.size && size.quantity ? esiColor.actionColor : "gray"}
                                        type="submit"
                                        mode="contained"
                                        disabled={size.size && size.quantity ? false : true}
                                        style={{ color: size.size && size.quantity ? "#000000" : "#fff", width: "50%" }}
                                        onPress={cartData}
                                    >
                                        Proceed
                                    </Button>
                                </View>
                            </View>
                            :
                            cartLoading ? <ActivityIndicator color='#27b6cc' /> :
                                <View style={{ flexDirection: 'row', justifyContent: 'center', textAlign: "center" }}>
                                    {cartErrorMessage ? <Text style={{ color: "red" }} >{cartErrorMessage}</Text> :
                                        <View style={{ flexDirection: "column", alignItems: "center" }} >
                                            <Text style={{ fontSize: 16, fontWeight: "bold" }} >Item added to the cart.</Text>
                                            <IconButton icon={"cart-check"} color={"#27b6cc"} size={60} />
                                            <TouchableOpacity onPress={() => {
                                                onCloseModal(); setCartErrorMessage("");
                                            }}>
                                                <Button
                                                    mode="contained"
                                                    color="#27b6cc"
                                                    labelStyle={{ color: "#fff" }}
                                                    type="submit"
                                                    // style={{ : "#fff" }}
                                                    onPress={() => {
                                                        onCloseModal(); setCartErrorMessage("");
                                                    }}
                                                >
                                                    Done
                                                </Button>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                    }
                </View>
            </Modal >
        </View>

    );
}

const styles = StyleSheet.create({

    productDetailsSize: {
        width: 50,
        height: 50,
        backgroundColor: 'rgb(42, 175, 82)',
        // cursor: pointer,
        borderRadius: 50,
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // marginTop: 20,
        // marginBottom: 20,
        borderColor: '#c4c4da',
        borderWidth: 1,
        // position: 'relative',
        // alignItems: 'center',
        // justifyContent: 'center',
        color: 'white',
        flexDirection: "column",
        marginHorizontal: 4

    },

    productDetailsSizeSelect: {
        width: 50,
        // marginTop: 20,
        // marginBottom: 20,
        // height: auto;
        height: 50,
        backgroundColor: 'white',
        // cursor: pointer;
        borderRadius: 50,
        // marginLeft: 'auto',
        // marginRight: 'auto',
        borderColor: '#c4c4da',
        borderWidth: 1,
        // position: 'relative',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: "column",
        marginHorizontal: 4

    },

    ribbon: {
        // padding: 15,
        // height: 20,
        backgroundColor: 'rgb(207, 152, 49)',
        color: '#dddeda',
        fontSize: 15,
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // width: '100%',
        // marginTop: '-0.1vw',
        // marginLeft: 4,
        // maxWidth: 100
        textAlign: "center"
    }


});