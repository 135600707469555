import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import EsiCheckboxTree from '../../components/custom/checkbox-tree';
import { getAllfilterpartnerAction } from '../../state/actions/partnerActions';
import isEmpty from '../../state/validations/is-empty';
import { changePartnerData } from "../../state/actions/filterServiceActions";


export default function SortByPartners(props) {
    // const partnerId = props?.route?.params?.id;
    const partnerId = props.state?.routes[0]?.params?.id;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const callback = (data: any) => {
        let onlinePart = [];
        let physicalPart = [];
        if (data) {
            data.map((item: any, index: Number) => {
                if (item.Type === "Physical") {
                    physicalPart.push({
                        label: item.Name,
                        value: item.Partner_Details_Id,
                        // value: item.Name,
                        checked: item.Partner_Details_Id === partnerId ? "checked" : "unchecked",
                        // checked: "unchecked",
                        key: "2-" + String(index),
                    });
                } else {
                    onlinePart.push({
                        label: item.Name,
                        value: item.Partner_Details_Id,
                        // value: item.Name,
                        // checked: "unchecked",
                        checked: item.Partner_Details_Id === partnerId ? "checked" : "unchecked",
                        key: "1-" + String(index),
                    });
                }
            })
        }

        dispatch(changePartnerData(
            [
                {
                    label: "Online",
                    value: "Online",
                    checked: "unchecked",
                    key: '1',
                    children: onlinePart.slice(0, 10)
                },
                {
                    label: "Physical",
                    value: "Physical",
                    checked: "unchecked",
                    key: '2',
                    children: physicalPart.slice(0, 10)
                }
            ]
        ));
    };
    let sortByOptionsInit = useSelector((state) => state.filters.partners);

    useEffect(() => {
        if (!isEmpty(sortByOptionsInit)) {
            let partner = JSON.parse(JSON.stringify(sortByOptionsInit));
            let exist = false;
            if (props.state?.routes[0]?.params?.type === "partner") {
                partner.map((item) => {
                    item.children.map((sItem) => {
                        if (sItem.value === props.state?.routes[0]?.params?.id) {
                            if (sItem.checked === "checked") {
                                exist = false;
                            } else {
                                sItem.checked = "checked";
                                exist = true;
                            }
                        } else {
                            sItem.checked = "unchecked";
                            exist = true;
                        }
                    })
                })

            } else {
                partner.map((item) => {
                    item.children.map((sItem) => {
                        if (sItem.checked === "checked") {
                            sItem.checked = "unchecked";
                            exist = true;
                        }
                    })
                })
            }
            if (exist)
                dispatch(changePartnerData(partner));
        }
    }, [props.state?.routes[0]?.params?.id]);

    useEffect(() => {
        if (isEmpty(sortByOptionsInit)) {
            dispatch(getAllfilterpartnerAction(callback));
        }
    }, [sortByOptionsInit]);
    const sortByOptionsInitData = [
        {
            label: "Online",
            value: "Online",
            checked: "unchecked",
            key: '1',
        },
        {
            label: "Physical",
            value: "Online",
            checked: "unchecked",
            key: '2',
        }
    ];

    const { navigation } = props;


    const checkChildChange = (data: any, status: string) => {
        if (props.state?.routes[0]?.params?.type === "partner") {
            navigation.setParams({ ...props.state?.routes[0]?.params, id: "", type: "" });
        }
        let key = data.key;
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
            if (status === 'unchecked' || status === 'indeterminate') {
                item.checked = "checked";
            } else {
                item.checked = "unchecked";
            }
        })

        response.filter((item: any) => { return !(item.key === key) }).map(((children: any) => {
            children.children.map((item: any) => {
                item.checked = "unchecked";
            })
        }))

        dispatch(changePartnerData(response));
    }

    const changeChecked = (data: any, status: string) => {
        let key = data.key;
        let keys = key.split('-');

        if (props.state?.routes[0]?.params?.type === "partner") {
            navigation.setParams({ ...props.state?.routes[0]?.params, id: "", type: "" });
        }
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        if (keys.length === 1) {
            response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        } else if (keys.length === 2) {
            response.filter((item: any) => { return item.key === keys[0] })[0].children.filter((item: any) => {
                return item.key === key
            })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
            response.filter((item: any) => { return !(item.key === keys[0]) }).map(children => {
                children.children.map(item => {
                    item.checked = 'unchecked';
                })
            })
        }
        dispatch(changePartnerData(response));
    }

    const updateOptions = ((data: any) => {
        dispatch(changePartnerData(data));
    })

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: esiColor.BackgroundColor
        }
    });
    return (
        <View style={styles.container}>
            <View style={{ marginLeft: 10, flexDirection: 'row' }}>
                <View>
                    <Image style={{ width: 25, height: 25, marginRight: 10 }}
                        source={require('../../assets/images/favicon.png')}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 14, color: esiColor.itemColor, marginTop: 5 }}>Partners</Text>
                </View>
                <TouchableOpacity style={{ position: 'absolute', right: 10, marginTop: 5 }} onPress={() => { navigation.navigate('Partners') }} >
                    <Text style={{ textDecorationLine: "underline", color: esiColor.itemColor }}  >See All</Text>
                </TouchableOpacity>
            </View>
            <EsiCheckboxTree
                sortByOptionsInit={sortByOptionsInit}
                updateOptions={updateOptions}
                checkChildChange={checkChildChange}
                changeChecked={changeChecked}
                {...props} />
        </View>
    );
}

