import React, { useContext, useEffect, useState } from 'react';
import { BackHandler, Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View, TextInput, Alert, Linking } from 'react-native';
import { Appbar, Avatar, Badge, Button, Colors, Menu, Surface, TouchableRipple } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { loginOut, loginOutSuccess } from '../state/actions/authActions';
import Modal from "react-native-modal";
import SignIN from './auth/signin';
import { Entypo, EvilIcons, Feather, FontAwesome } from '@expo/vector-icons';
import isEmpty from '../state/validations/is-empty';
import { scrollTo, useAnimatedRef, useSharedValue } from 'react-native-reanimated';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { pushNotificationAction } from '../state/actions/pushnotificationAction';
import { useToast } from '../self_modules/react-native-paper-toast/src';
import { checkVersion } from "react-native-check-version";
import { getCart, setDefaultAddress, setDefaultAddressOnTest } from "../state/actions/cartActionsNew";
import { getAllCartAddressAction } from '../state/actions/addressActions';
import Dropdown from '../components/custom/Dropdown';
import { useRoute } from '@react-navigation/native';
import { addStatistics } from '../state/actions/dashboardActions';
import * as Network from 'expo-network';


const window = Dimensions.get("window");

const Header = (props) => {
  const { navigation } = props;
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  const esiColor = useSelector(state => state.theme);
  const signInModalResponse = (status: any) => {
    setModalVisible(!isModalVisible);
    const { refferal } = props?.route?.params || false;
    if (refferal) {
      navigation.navigate("Home");
    }
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };


  const checkUpdateNeeded = async () => {
    try {
      let updateNeeded = await checkVersion();
      if (updateNeeded.needsUpdate) {
        Alert.alert("Update Required", 'You will have to update your app to the latest version to continue using.',
          [
            {
              text: 'Update',
              onPress: () => {
                BackHandler.exitApp();
                Linking.openURL(updateNeeded.url);
              }
            },
            {
              text: "Cancel",
              onPress: () => { },
              style: "cancel"
            }
          ]
        );
      }

    } catch (error) {
      Alert.alert("Error", "Version control location");
    }

    // Alert.alert("getPackageName", VersionCheck.getPackageName());
    // Alert.alert("getCurrentBuildNumber", VersionCheck.getCurrentBuildNumber());
    // Alert.alert("getCurrentVersion", VersionCheck.getCurrentVersion());

    // VersionCheck.getLatestVersion()
    //   .then(latestVersion => {
    //     Alert.alert("latestVersion", latestVersion);
    //   });
  }

  React.useEffect(() => {
    if (Platform.OS != "web") {
      checkUpdateNeeded();
    }

    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window);
      }
    );
  });

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const userName = useSelector(state => state.auth.user?.User_Name ? state.auth.user?.User_Name : "");
  const userPic = useSelector(state => state.auth.user?.Profile_Pic_Path ? { uri: state.auth.user?.Profile_Pic_Path } : require("../assets/images/icons/userlogo.png"));
  const cart = useSelector((state) => state.cart.cart);
  const shippingAddress = useSelector((state) => state.cart.defaultAddress);
  const _handleSignOut = () => {
    // dispatch(loginOut(navigation));
  };

  const [testUserSession, setTestUserSession] = React.useState("");


  React.useEffect(() => {
    if (!isEmpty(user)) {
      setModalVisible(false);
      if (user?.User_Session_Id) {
        if (testUserSession != user?.User_Session_Id) {
          setTestUserSession(user?.User_Session_Id);
          dispatch(getCart(
            {
              "Records_Filter": "FILTER",
              "User_Email_Id": user.Email_Id
            }
          ));
          dispatch(setDefaultAddressOnTest())
        }

      } else {
        dispatch(loginOutSuccess());
      }
    } else {
      setSeconds(2);
    }
  }, [user])

  const route = useRoute();

  React.useEffect(() => {
    if (!isEmpty(user)) {
      (async () => {
        let iPAddress = await Network.getIpAddressAsync();
        let formdata = {
          "Component": route.name,
          "Email_Id": user?.Email_Id,
          "Event_Context": JSON.stringify(route.params),
          "Event_Name": "Navigation",
          "Ip_Address": iPAddress,
          "Mobile_Number": user?.Mobile,
          "User_Full_Name": user?.User_Name,
          "Origin": Platform.OS
        };
        dispatch(addStatistics(formdata));
      })();
    }
  }, [route])

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);
      if (seconds === 1) {
        if (isEmpty(user)) {
          const { referral } = props?.route?.params || false;
          if (referral) {
            setModalVisible(true);
          }
        }
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const _handleProfile = () => {
    navigation.navigate('ProfileDetails')
  }

  const _esiLogo = () => {
    navigation.navigate('Home')
  }
  const toast = useToast();

  // Tost message code.
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }


  const goToTop = () => {
    try {
      scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }


  // push notifications
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  React.useEffect(() => {
    if (
      lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.url &&
      lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      navigation.navigate(lastNotificationResponse.notification.request.content.data.url);
    }
  }, [lastNotificationResponse]);

  useEffect(() => {
    const requestPermissions = async () => {
      const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');

      let message = {
        to: ExpoPushToken,
        sound: 'default',
        title: 'Original Title',
        body: 'And here is the body!',
        data: { url: 'Notification' },
      };
      dispatch(pushNotificationAction(message))
    }
    if (Platform.OS != "web") {
      // requestPermissions()
    }
  }, [seconds])

  const [addressT, setAddress] = React.useState([]);
  const [addressV, setAddressV] = React.useState(false);

  const updateStates = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data ? data : []));
    setAddress(addresslist(add));
  };

  const addresslist = (address: any) => {
    let addresslist = []
    if (address) {
      for (let i = 0; i < address.length; i++) {
        addresslist.push({
          label: address[i].City + "," + address[i].Zip + "," + address[i].Address_Type + "," + address[i].Name,
          value: JSON.stringify(
            {
              "User_Address_Id": address[i].User_Address_Id,
              "Name": address[i].Name,
              "Shipping_Email_Id": address[i].Shipping_Email_Id,
              "User_Email_Id": address[i].User_Email_Id,
              "Mobile": address[i].Mobile,
              "Address_Type": address[i].Address_Type,
              "Door_Number": address[i].Door_Number,
              "Street": address[i].Street,
              "City": address[i].City,
              "State": address[i].State,
              "Country": address[i].Country,
              "Zip": address[i].Zip,
              "Landmark": address[i].Landmark,
              "Location": address[i].Location,
              "Alternate_Mobile": address[i].Alternate_Mobile,
              "City_Id": address[i]?.City_Id
            }
          )
        })
      }
    }
    if (isAuthenticated == true) {
      addresslist.push({
        label: "Add New Address",
        value: ""
      })
    }
    return addresslist
  }

  const getAddress = () => {
    let data = {
      User_Email_Id: user.Email_Id,
      Records_Filter: "FILTER",
    };
    dispatch(getAllCartAddressAction(updateStates, data));
  }


  const [openDropDown, setOpenDropDown] = React.useState(false);
  const styles = StyleSheet.create({

    cart_icon: {
      borderRadius: 50, right: 20, position: 'relative'
    },
    search: {
      backgroundColor: esiColor.BackgroundColor,
      borderRadius: 5,
      height: 40,
      margin: 8,
      marginTop: 0,
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      borderColor: esiColor.amountStrikeColor,
      borderWidth: 0.2
    },
    cart_count: {
      position: 'absolute',
      zIndex: 10,
      right: 45,
      marginTop: -30,
      backgroundColor: 'blue',
      borderRadius: 50,
      paddingHorizontal: 5,
      justifyContent: 'center',
    },
    cart_text: {
      color: '#27b6cc',
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'bold'
    },

    placeholderStyle: {
      fontSize: 16,
      color: "#27b6cc"
    },
    selectedTextStyle: {
      fontSize: 16,
      color: "#27b6cc",
      backgroundColor: "white",
      width: "100%",
      alignSelf: "center"
    },
    iconStyle: {
      width: 20,
      height: 20,
      color: "#27b6cc"
    },
    inputSearchStyle: {
      height: 30,
      fontSize: 16,
      width: "100%"
    },
    dropdown: {
      height: 30,
      minWidth: 200,
      borderColor: '#27b6cc',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 10,
      backgroundColor: "white",
    },
  });

  return (
    <Surface >
      <View style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 0.5, shadowRadius: 2, borderWidth: 0.5 }}>

        {/* <View style={{ padding: 10, backgroundColor: "#4169E1" }}> */}
        <View style={{ flexDirection: 'row', alignItems: 'center', height: 40, marginLeft: -10, marginTop: Platform.OS === 'web' ? 0 : 40 }}>
          <View style={{ flexDirection: "row", alignItems: 'center', flex: 3 }}>
            <Appbar.Action style={{ marginTop: 10 }} size={30} color={esiColor.SIconColor} icon="menu" onPress={() => {
              if (props.isFilter) {
                navigation.getParent().openDrawer();
              } else { navigation.openDrawer(); goToTop() }
            }} />
            <Appbar.Action style={{ marginLeft: -30, marginTop: 10 }} size={72} color={esiColor.GBFColor} icon={require('../assets/images/img/logo/logo.png')}
              onPress={() => {
                if (props.isFilter) {
                  navigation.getParent().openDrawer();
                } else { navigation.openDrawer(); goToTop() }
              }}
            />
          </View>
          <View style={{ flexDirection: "row", alignItems: 'center', flex: 3 }}>

          </View>
          <View style={{ flexDirection: "row", flex: 3, justifyContent: "flex-end" }}>
            <TouchableOpacity style={styles.cart_icon} // onPress={() => gotoCart()}
              onPress={() => { isAuthenticated ? navigation.navigate('Cart') : toggleModal() }}>
              {isAuthenticated ? cart.length > 0 &&
                <Badge style={{
                  position: 'absolute',
                  color: esiColor.SIconColor,
                  fontWeight: 'bold',
                  bottom: 12,
                  left: 18,
                  backgroundColor: "white"
                }}>
                  {cart.length}
                </Badge> : null}
              <Entypo name="shopping-cart" size={23} color={esiColor.SIconColor} />
            </TouchableOpacity>
            {
              !isAuthenticated ?
                <TouchableOpacity onPress={toggleModal}>
                  <Image source={userPic} style={{ height: 25, width: 25, marginRight: 10, borderRadius: 50, backgroundColor: esiColor.esiMainColor }} />
                </TouchableOpacity>
                :
                <TouchableRipple onPress={_handleProfile}>
                  <Avatar.Image size={25} source={userPic} style={{ marginRight: 10, backgroundColor: esiColor.esiMainColor }} />
                </TouchableRipple>
            }
          </View>
        </View>

        {!isAuthenticated ?
          <View style={[styles.search, Platform.OS != "android" ? {
            shadowOffset: { width: 0, height: 0 },
            shadowColor: esiColor.brandShadowColor,
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 20

          } : {
            elevation: 20,
            overflow: "hidden"
          }]}>
            {dispatch(setDefaultAddress(""))}
            <EvilIcons name="search"
              size={25}
              color={esiColor.SIconColor}
              style={{ margin: 5, fontWeight: "bold" }} />

            <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
              onPressIn={() => navigation.navigate('searchproduct')}
              onKeyPress={() => navigation.navigate('searchproduct')}
              onTouchStart={() => navigation.navigate('searchproduct')}
              style={{ flex: 1, color: esiColor.Text, backgroundColor: esiColor.BackgroundColor }}
              placeholderTextColor={esiColor.amountStrikeColor}
              multiline={false}
              editable={Platform.OS === "android" ? true : false}
              placeholder="Search for Products, Brands and More" />
          </View>
          :

          <View style={{ flexDirection: "row", marginVertical: 10, justifyContent: "space-between" }} >
            <View style={{ flexDirection: "row", flex: 11, overflow: Platform.OS != "web" ? "scroll" : "hidden" }}  >
              <TouchableOpacity style={{ flexDirection: "row" }} onPress={() => {
                getAddress();
                setOpenDropDown(!openDropDown);
              }}>
                <EvilIcons
                  name="location"
                  size={25}
                  color="#27b6cc"
                  style={{
                    flex: 1,
                  }}
                />
                <Text style={{
                  fontWeight: 'bold',
                  color: "#27b6cc",
                  flex: 4
                }}> {"Deliver to "}</Text>
                <Dropdown label="select address" onClick={() => {
                  getAddress();
                }} data={addressT} onSelect={(item) => {
                  if (item.value) {
                    dispatch(setDefaultAddress(item));
                  } else {
                    navigation.navigate("AddAddress");
                  }
                }}
                  maxWidth={dimensions.width * 0.50}
                  selected={shippingAddress}
                />
              </TouchableOpacity>
            </View>
            <FontAwesome
              name="search"
              size={22}
              color="#27b6cc"
              style={{ flex: 1, textAlign: "right", marginRight: 10 }}
              onPress={() => navigation.navigate('searchproduct')}
            />
          </View>
        }
        {/* </View> */}
        <Modal animationIn={"slideInDown"}
          deviceWidth={dimensions.width}
          deviceHeight={dimensions.height}
          style={{ alignItems: 'center' }} isVisible={isModalVisible}>
          <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
            <SignIN navigation={navigation} {...props} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
          </View>
        </Modal>
      </View>
    </Surface>
  );
};

export default Header;

