import React, { useEffect } from "react";
import { ActivityIndicator, Card, Surface, Text, IconButton, Colors } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import { Image, StyleSheet, View, ScrollView, Platform, Dimensions, RefreshControl } from "react-native";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import Modal from "react-native-modal";
import ESIStoreTypeCard from "../../components/custom/store-type-modal";
import { getShopByStoresAction } from "../../state/actions/node-actions/home-actions";

export default function ShopbyStoreType(props) {
  // api using redux
  const { navigation } = props;
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);

  const halfdimentions = dimensions / 2;
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  useEffect(() => {
    onRefresh();
  }, []);

  const [loading, setIsLoading] = React.useState(false);
  const [storeTypes, setStoreTypes] = React.useState([]);



  const callBackGetData = async (status, data) => {
    setIsLoading(false);
    if (status) {
      setStoreTypes(data);
    }
  }

  const onRefresh = () => {
    setIsLoading(true);
    dispatch(getShopByStoresAction({
      "Records_Filter": "FILTER",
      "Status": "Active",
      "Type": props.type ? props.type : ""
    },
      { PageNo: 1, PageSize: 100, SortBy: "STORE_TYPE_NAME", SortOrder: "DESC" }
      , callBackGetData));
  };

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();
  const closeModal = () => {
    setOpen(!open)
  }
  return (
    <Surface style={{ marginBottom: Platform.OS === 'web' ? null : 120, position: Platform.OS === 'web' ? 'absolute' : null,
     top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null,
      bottom: Platform.OS === 'web' ? 0 : null, backgroundColor: esiColor.CBColor  }}>
      <Header {...props} />
      <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center' }}>
        <Text style={{ fontSize: 22, fontWeight: "bold", alignSelf: 'center', color: esiColor.brandFontColor, margin: 2 }}>Shop by Store Type</Text>
      </View>
      {navigation.canGoBack() &&
        <IconButton
          icon="arrow-left"
          size={25}
          color={"#27B6CC"}
          style={{ marginTop: (Platform.OS === 'web' ? -38 : -40), marginLeft: -6 }}
          onPress={() => navigation.goBack()}
        />
      }
      {storeTypes.length == 0 &&
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <Text style={{color: esiColor.NoDataFound}}>No Store Type available to display</Text>
        </View>
      }
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={onRefresh}
          />
        }
      >
        <View style={styles.Card}>
          {!isEmpty(storeTypes) && (
            storeTypes.map((item, index) => {
              return (
                <View key={index} style={{ padding: 4, marginBottom: 30, maxWidth: (halfdimentions), width: (halfdimentions) }}>
                  <Card style={{
                    height: 140, maxHeight: 140, margin: 1,
                    shadowColor: "#CACFD2", borderRadius: 2, shadowRadius: 10, elevation: 10, opacity: 10
                  }} onPress={(e) => { setOpen(true), setData(item) }}>
                    <View >
                      <Image source={{ cache: "force-cache", uri: item?.Image_Path + `?h=140` }}
                        style={{
                          height: 140, maxHeight: 140,
                          shadowColor: "#CACFD2", borderRadius: 2, shadowRadius: 10, opacity: 10
                        }}
                      />
                    </View>
                  </Card>
                  <Text style={{ margin: 2, flex: 1, color: esiColor.itemColor, fontSize: 14, fontWeight: 'bold', textAlign: 'center', paddingVertical: 4 }}>{item?.Store_Type_Name}</Text>
                </View>
              )
            })
          )}
        </View>
        <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 20 }} />
      </ScrollView>
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={open}>
        {/* <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 100, maxHeight: 150,justifyContent:"center", borderRadius: 10, backgroundColor: Colors.white }}> */}
        <ESIStoreTypeCard navigation={navigation} setOpen={setOpen} data={data} closeModal={closeModal} />
        {/* </View> */}
      </Modal>
    </Surface>
  );
}
const styles = StyleSheet.create({
  Card: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
  },
});
