import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Searchbar, Surface, Text } from 'react-native-paper';
import { View, TouchableOpacity, Image, RefreshControl, ScrollView, FlatList, Platform } from 'react-native';
import Header from "../header";
import { Dimensions } from 'react-native';
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import isEmpty from "../../state/validations/is-empty";
import { EvilIcons } from "@expo/vector-icons";
import Pagination from "../../components/custom/pagination/pagination";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import MfhZones from "./MFHZones";
import { getMFHProducts, getMFHSThemes } from "../../state/actions/node-actions/mfh-actions";
import ProductCard from "../../components/custom/product-mfh-card";
import { BrandFilters } from "../products/DropDownFilters/BrandFilters";
import { CategoryFilters } from "../products/DropDownFilters/CategoryFilters";
import { PopularFilters } from "../products/DropDownFilters/PopularFilters";
import { ExtraCashBackFilter } from "../products/DropDownFilters/ExtraCashBackFilter";
import { DiscountFilters } from "../products/DropDownFilters/DiscountFilters";
import { PriceFilter } from "../products/DropDownFilters/PriceFilter";
import { Drawer } from 'react-native-drawer-layout';

export default function MFHProducts(props: any) {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();
    const { navigation, ...rest } = props;
    // dimensions are used for the width and height of card 
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [wishdata, setWishData] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const data = props?.route?.params;
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = React.useState(
        {
            "Search": "",
            "Zone_Id": "",
            "Theme_Id": "",
            extra: false,
            price: "",
            discount: "",
            popular: []
        }
    );

    useEffect(() => {
        if (data) {
            setFilters(
                {
                    "Search": "",
                    "Zone_Id": data?.Zone_Id || "",
                    "Theme_Id": "",
                    extra: false,
                    price: "",
                    discount: "",
                    popular: []
                }
            );
        } else {
            setFilters(
                {
                    "Search": "",
                    "Zone_Id": "",
                    "Theme_Id": "",
                    extra: false,
                    price: "",
                    discount: "",
                    popular: []
                }
            );
        }
    }, [data])



    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
    const [searchBarOpen, setSearchBarOpen] = React.useState(false);
    const callBackGetData = async (status, data, pagination) => {

        setIsLoading(false);
        if (status) {
            setProducts(data);
        }
        if (pagination) {
            setPageOld(true);
            setPagination(pagination);
        } else {
            setPageOld(true);
            setPagination({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            if (!isEmpty(filters)) {
                productCall()
            }
        }
    }, [pagination])
    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {

        if (filters) {
            let formData = {
                Search: filters.Search,
                Zone_Id: filters.Zone_Id,
                Theme_Id: filters.Theme_Id
            }
            if (!isEmpty(filters?.brandId)) {
                formData.Brand_Id = [filters?.brandId]
            }

            if (!isEmpty(filters?.categoryId)) {
                formData.Category_Id = [filters?.categoryId]
            }
            if (!isEmpty(filters?.subCategoryId)) {
                formData.Sub_Category_Id = [filters?.subCategoryId]
            }

            if (filters.popular.indexOf("Is_Top_Product") >= 0) {
                formData.Is_Top_Product = '1';
            }
            if (filters.popular.indexOf("Is_Popular") >= 0) {
                formData.Is_Popular = '1';
            }
            if (filters.popular.indexOf("Is_Verified") >= 0) {
                formData.Is_Verified = '1';
            }
            if (filters.popular.indexOf("Whats_Hot") >= 0) {
                formData.Whats_Hot = '1';
            }
            if (filters.popular.indexOf("Is_Upcoming") >= 0) {
                formData.Is_Upcoming = '1';
            }

            const discountFilter = filters.discount.split("-");
            if (discountFilter.length == 2) {
                formData.Min_Discount = discountFilter[0];
                formData.Max_Discount = discountFilter[1];
            }

            const priceFilter = filters.price.split("-");
            if (priceFilter.length >= 1) {
                if (priceFilter[0])
                    formData.Min_Price = priceFilter[0]
            }
            if (priceFilter.length == 2) {
                formData.Max_Price = priceFilter[1]
            }

            if (filters.extra) {
                formData.Extra_Cashback = '1';
            }

            if ((JSON.stringify(testFilters) != JSON.stringify(formData)) && !isEmpty(formData)) {
                setTestFilters(formData);
                goToTop();
                setIsLoading(true);
                dispatch(getMFHProducts(formData,
                    pagination,
                    callBackGetData
                ));
            }
        }
    }, [filters]);

    useEffect(() => {
        dispatch(getMFHSThemes({},
            { PageNo: 1, PageSize: 100, SortBy: "MEEPAISA_THEME_ID", SortOrder: "DESC" }
            , callbackThemes));
    }, []);

    const [themes, setThemes] = React.useState([]);

    const callbackThemes = (status, data) => {
        if (status) {
            setThemes(data);
        }
    }

    const [loading, setIsLoading] = React.useState(false);
    const productCall = () => {
        goToTop();
        setIsLoading(true);
        dispatch(getMFHProducts(testFilters,
            pagination,
            callBackGetData
        ));
    };


    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };



    const scroll = useRef(null);
    const goToTop = () => {
        if (scroll)
            scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
    }

    const handleClick = async (item) => {
        navigation.navigate('MFHDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata })
    };

    const drawerContent = useCallback(() => {
        return (

            <FlatList
                style={{ backgroundColor: esiColor.BackgroundColor, flex: 1 }}
                data={[{ id: 1 }]}
                renderItem={({ item }) => <View style={{ marginVertical: 10 }} >
                    <View style={{ marginTop: 5 }} >
                        <BrandFilters filters={filters} setFilters={setFilters} />
                    </View>
                    <View style={{ marginTop: 5 }} >
                        <CategoryFilters filters={filters} setFilters={setFilters} />
                    </View>
                    <View style={{ marginTop: 5 }} >
                        <PopularFilters filters={filters} setFilters={setFilters} />
                    </View>
                    <View style={{ marginTop: 5 }} >
                        <ExtraCashBackFilter filters={filters} setFilters={setFilters} />
                    </View>
                    <View style={{ marginTop: 5 }} >
                        <DiscountFilters filters={filters} setFilters={setFilters} />
                    </View>
                    <View style={{ marginTop: 5 }} >
                        <PriceFilter filters={filters} setFilters={setFilters} />
                    </View>
                </View>}
            />
        )
    }, [open, filters])

    const mfhSearchBar = useMemo(() => {
        return (
            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                <View style={{ flex: 8, flexDirection: "row" }} >

                    {searchBarOpen || filters?.Search?.length > 0 ?
                        <Searchbar iconColor={esiColor.LCHFontColor} style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor, borderWidth: 0.5, maxHeight: 30, marginTop: 5, width: dimensions.width * 0.5 }}
                            inputStyle={{ fontSize: 12, marginLeft: -20 }}
                            value={filters?.Search}
                            autoFocus
                            onChangeText={(value) => {
                                setPageOld(true);
                                setPagination({ PageNo: 1, PageSize: 50, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
                                setFilters({
                                    ...filters,
                                    Search: value
                                });
                            }}
                            onBlur={() => {
                                setSearchBarOpen(false);
                            }}
                            onPressOut={() => {
                                setSearchBarOpen(false);
                            }}
                            placeholderTextColor={esiColor.SPHTextColor}
                            theme={{ colors: { text: esiColor.Text } }}

                            multiline={false}
                            placeholder="Search"
                            icon={() => <EvilIcons
                                name="search"
                                size={20}
                                color={esiColor.LCHFontColor}
                            // style={{marginHorizontal:-15}}
                            />}
                        />
                        :
                        <EvilIcons
                            name="search"
                            size={30}
                            color={esiColor.LCHFontColor}
                            style={{ marginLeft: 5, marginTop: 5 }}
                            onPress={() => { setSearchBarOpen(true) }}
                        />
                    }

                </View>
                <View style={{ flex: 2 }}>

                    <IconButton
                        color={'#27b6cc'}
                        icon="filter"
                        size={24}
                        style={{ alignSelf: 'flex-end', marginTop: -2 }}
                        onPress={() => setOpen((prevOpen) => !prevOpen)}
                    />
                </View>
            </View>
        )
    }, [open, searchBarOpen, filters, esiColor])

    return (
        <Surface style={{ flex: 1 }}
            theme={{
                colors: {
                    surface: esiColor.BackgroundColor,
                }
            }}
        >
            <Header {...props} />
            <Drawer
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                drawerStyle={{}}
                renderDrawerContent={drawerContent}
                drawerPosition={"left"}
            // drawerType={"slide"}
            >
                <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 1, paddingTop: 5, paddingLeft: 5 }} >
                        <MfhZones navigation={navigation.navigate} filters={filters} setFilters={setFilters} />
                    </View>

                </View>


                <View style={{ flexDirection: 'row', height: Platform.OS == "ios" ? dimensions.height * 0.69 : dimensions.height * 0.72, width: "100%" }}>

                    <View style={{ flex: 2, flexDirection: "column", justifyContent: "flex-start", marginHorizontal: 4, maxWidth: 50 }} >

                        <FlatList
                            // horizontal={true}
                            // columnWrapperStyle={{ justifyContent: "space-around" }}
                            showsVerticalScrollIndicator={false}
                            data={themes}
                            renderItem={({ item, index, separators }) => {
                                let store = item;
                                return (<TouchableOpacity key={"store" + index}
                                    onPress={() => {
                                        if (filters?.Theme_Id != store?.Meepaisa_Theme_Id) {
                                            setFilters({ ...filters, Theme_Id: store?.Meepaisa_Theme_Id });
                                        } else {
                                            setFilters({ ...filters, Theme_Id: "" });
                                        }
                                    }}
                                >
                                    <Surface style={{
                                        borderRadius: 10, marginHorizontal: 'auto', marginVertical: 5, backgroundColor: esiColor.CBColor,
                                        shadowColor: esiColor.brandShadowColor, borderColor: filters?.Theme_Id != store?.Meepaisa_Theme_Id ? "#CACFD2" : "#27b6cc",
                                        borderWidth: 0.5,
                                        shadowOffset: { width: 0, height: 2 },
                                        shadowOpacity: 0.2,
                                        shadowRadius: 10,
                                        elevation: 10,
                                        padding: 2
                                    }}
                                    >
                                        <Image source={{ cache: "force-cache", uri: store?.Meepaisa_Theme_Image }} style={{ height: 40, width: 40, alignSelf: "center", opacity: 1, borderRadius: 8 }} resizeMode='stretch' ></Image>
                                        <Text style={{ fontSize: 10, alignSelf: "center", color: esiColor.itemColor }}>{store?.Meepaisa_Theme_Name}</Text>
                                    </Surface>
                                </TouchableOpacity>)
                            }}
                        />

                    </View>

                    <FlatList
                        refreshControl={
                            <RefreshControl
                                refreshing={loading}
                                onRefresh={productCall}
                            />
                        }
                        style={{ flex: 1, marginTop: 5, marginBottom: 10 }}
                        data={products}
                        numColumns={1}
                        contentContainerStyle={{ marginVertical: 2 }}
                        renderItem={({ item, index, separators }) => (
                            <ProductCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />

                        )}
                        ListFooterComponent={() =>
                            <View style={{ marginTop: 10, marginBottom: 15 }}>
                                <Pagination pagination={pagination} setPagination={setPagination} />
                            </View>
                        }
                        ListEmptyComponent={() => <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                            <Text style={{ color: esiColor.NoDataFound }}>No products found!</Text>
                        </View>
                        }
                        ListHeaderComponent={mfhSearchBar}
                    />

                </View>

                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                    <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                        <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                    </View>
                </Modal>
            </Drawer>
        </Surface>

    );
}
