import * as React from 'react';
import { Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Button, Card, Icon } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import Swiper from 'react-native-web-swiper';
import { useDispatch, useSelector } from 'react-redux';
import { getFunctionHallsAction } from '../../state/actions/eventsActions';
import isEmpty from '../../state/validations/is-empty';
import Header from '../header';
import { List } from 'react-native-paper';
import EsiState from '../../components/custom/country-state-city-boomi/state';
import EsiCity from '../../components/custom/country-state-city-boomi/city';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import Modal from "react-native-modal";
import SignIN from '../auth/signin';


export default function AllHalls(props) {

    const dispatch = useDispatch();
    const toast = useToast();
    let halls = useSelector((state) => state.events.allfunctionhalls.all);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [stateDropDown, setStateDropDown] = React.useState(false);
    const [cityDropDown, setCityDropDown] = React.useState(false);
    const [state, setState] = React.useState("");
    const [stateiso, setStateISO] = React.useState("");
    const [city, setCity] = React.useState("");
    const [cityname, setCityName] = React.useState("");
    const [expand, setExpand] = React.useState(false);

    React.useEffect(() => {
        if (isEmpty(halls)) {
            let formData = {
                Records_Filter: "ALL"
            }
            dispatch(getFunctionHallsAction(formData));
        }
    }, [halls]);


    const [isModalVisible, setModalVisible] = React.useState(false);
    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };
    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
    };
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimentions = dimensions / 1.06;
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    const filterData = () => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', width: "100%" }}><View style={{ flexGrow: 1, width: 300, marginRight: '0.5%', paddingTop: 5, flexShrink: 1 }}>
                <EsiState
                    labelField="NAME"
                    valueField="STATE_CODE"
                    value={stateiso}
                    placeholder="Select State"
                    name="State"
                    search
                    searchPlaceholder={'Search State'}
                    CountryCode={"IN"}
                    onUpdateValue={item => {
                        setState(item.NAME)
                        setCity("")
                        setStateISO(item.STATE_CODE)
                        setStateDropDown(false);
                    }}
                />
            </View>
                <View style={{ flexGrow: 1, width: 300, paddingTop: 5, marginLeft: '0.5%', flexShrink: 1 }}>
                    <EsiCity
                        labelField="NAME"
                        valueField="CITY_ID"
                        CountryCode={"IN"}
                        StateCode={stateiso}
                        placeholder="Select City"
                        value={city}
                        name="City"
                        search
                        searchPlaceholder={'Search City'}
                        onUpdateValue={item => {
                            setCity(item.CITY_ID)
                            setCityName(item.NAME)
                            let formData = {
                                City_ID: item.CITY_ID,
                                Records_Filter: "FILTER"
                            }
                            dispatch(getFunctionHallsAction(formData))
                            setCityDropDown(false);
                        }}
                    />
                </View></View>)
    }
    return (
        <View style={styles.container}>
            <Header {...props} />
            <View>

                <List.Accordion
                    title={<View style={{ flexDirection: "row" }}><Text>Filter    -  {state.length > 0 && cityname.length > 0 ? (state + ", " + cityname) : "All"}</Text></View>}
                    titleStyle={{ color: "#27b6cc" }}
                >
                    <List.Item description={filterData} />

                </List.Accordion>
            </View>
            <View style={{ borderBottomColor: '#27B6CC', borderBottomWidth: 1 }} />
            <View style={{ flexDirection: "row", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                <TouchableOpacity onPress={() => {
                    setCity("")
                    setStateISO("")
                    setCityName("")
                    setState("")
                    let formData = {
                        Records_Filter: "All"
                    }
                    dispatch(getFunctionHallsAction(formData))
                }}>
                    <Text style={{ color: "#27b6cc", textAlign: "right", fontWeight: "bold" }}>Clearfilter</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { !isAuthenticated ? toggleModal() : props.navigation.navigate("AllEvents") }}>
                    <Text style={{ color: "#27b6cc", textAlign: "right", fontWeight: "bold" }}>-->Click here for Booking history</Text>
                </TouchableOpacity>
            </View>
            <View style={{ borderBottomColor: '#27B6CC', borderBottomWidth: 1 }} />
            <ScrollView>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto', }}>
                    {!isEmpty(halls) ? (
                        halls.Is_Data_Exist === "0" ? (
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                                <Text> No Halls found related to city. </Text>
                            </View>
                        ) : (
                            halls.map((item: any, index: any) => (
                                <React.Fragment key={index}>
                                    <View style={{ maxWidth: Platform.OS === "web" ? 340 : (halfdimentions), width: Platform.OS === 'web' ? dimensions <= 1500 ? 770 : "90%" : "85%" && (halfdimentions) }}>
                                        <Card >
                                            <Card.Title style={{ color: "#27b6cc" }}>{item.Name.toUpperCase()}</Card.Title>
                                            <Card.Divider />
                                            <View style={{width:"100%", height: 200 }}>
                                                <Swiper
                                                    showsButtons={false} horizontal={true}
                                                    timeout={5}
                                                    springConfig={{ speed: 11 }}
                                                    minDistanceForAction={0.15}
                                                    loop={true}
                                                    controlsEnabled={false}
                                                >
                                                    {item?.Function_Hall_Document?.map((data: any, index: any) => (
                                                        <Image
                                                            key={index}
                                                            style={styles.tinyLogo}
                                                            // resizeMode="cover"
                                                            source={{uri:data.Function_Hall_Document_Id}}
                                                        />
                                                    ))}
                                                </Swiper>
                                            </View>
                                            <TouchableOpacity style={{ paddingTop: 10, paddingBottom: 5 }}>
                                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <Text>
                                                        Advance Amount :
                                                    </Text>
                                                    <Text style={{ color: "#27b6cc", fontWeight: "bold" }}>
                                                        {item?.Advance_Amount_per_Day}/-
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                            <Text style={{ marginBottom: 10, height: 40 }}>
                                                {item?.Short_Description?.substring(0, 60)}...
                                            </Text>
                                            <Button
                                                disabled={item?.IS_Available_For_Booking === "1" ? false : true}
                                                onPress={() => { !isAuthenticated ? toggleModal() : props.navigation.navigate("EventDetails", { data: item.Function_Hall_Id }) }}
                                                icon={<Icon name={'home'} color={"white"} />}
                                                buttonStyle={{ backgroundColor: "#27b6cc", borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                                                title='VIEW INFO' />
                                        </Card>
                                    </View>
                                </React.Fragment>
                            ))
                        )
                    ) : (<></>
                    )}
                </View>
            </ScrollView>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: 'white' }}>

                    <SignIN navigation={props.navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    tinyLogo: {
        width: 300,
        height: 200,
    },
});
