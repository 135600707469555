import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Searchbar, Surface, Text, ActivityIndicator, Colors, Button, TouchableRipple, Avatar, Checkbox, Appbar, Menu, IconButton } from 'react-native-paper';
import { View, Linking, TouchableOpacity, Platform, Image, RefreshControl, ScrollView, FlatList } from 'react-native';
import Header from "../header";
import EsiPartnerCard from '../../components/custom/partner-card';
import { Dimensions } from 'react-native';
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import isEmpty from "../../state/validations/is-empty";
import { EvilIcons } from "@expo/vector-icons";
import { getPartnersAction, getShopByStoresAction } from "../../state/actions/node-actions/home-actions";
import Pagination from "../../components/custom/pagination/pagination";
import PartnerLocation from "../../components/custom/location/partner-location";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import EsiDropdown from "../../components/custom/EsiDropDown";

export default function AllPartners(props: any) {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();
    const { navigation, ...rest } = props;
    // dimensions are used for the width and height of card 
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [imageURL, setImageURL] = useState("");


    const data = props?.route?.params;
    const [partners, setpartners] = useState([]);
    const [filters, setFilters] = React.useState(
        {}
    );

    useEffect(() => {
        if (data) {
            setFilters(
                {
                    "Records_Filter": "FILTER",
                    "Partner_Status": "Active",
                    "Search_Partner_Name": "",
                    "Top_Stores": (data?.top == "true" || data?.top == true) ? 1 : "",
                    "Zipcode": data?.type == "Physical" ? pFilters.Zipcode : "",
                    "Type": data?.type ? data?.type == "All" ? "" : data?.type : (filters.Type || "Physical"),
                    "ZipcodeLocation": data?.type == "Physical" ? pFilters.ZipcodeLocation : "",
                    "City_ID": (data?.city || data?.type == "Physical") ? pFilters.City_ID || data?.city : "",
                    "City_Info": (data?.city ? "All in " + data?.cityName : data?.type == "Physical" ? pFilters.City_Info : ""),
                    "Store_Type": data?.Store_Type || filters.Store_Type || "",
                    "Store_Name": data?.Store_Type || filters.Store_Name || ""
                }
            );
        } else {
            setFilters({
                "Records_Filter": "FILTER",
                "Partner_Status": "Active",
                "Search_Partner_Name": "",
                "Top_Stores": "",
                "Zipcode": "",
                "Type": "Physical",
                "ZipcodeLocation": "",
                "City_ID": "",
                "City_Info": "",
                "Store_Type": "",
                "Store_Name": ""
            });
        }
    }, [data])

    const [pFilters, setPFilters] = useState({
        "Zipcode": "",
        "ZipcodeLocation": "",
        "City_ID": "",
        "City_Info": ""
    });


    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
    const [searchBarOpen, setSearchBarOpen] = React.useState(false);
    const callBackGetData = async (status, data, pagination) => {

        setIsLoading(false);
        if (status) {
            setpartners(data);
        }
        if (pagination) {
            setPageOld(true);
            setPagination(pagination);
        } else {
            setPageOld(true);
            setPagination({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            if (!isEmpty(filters)) {
                goToTop();
                setIsLoading(true);
                dispatch(getPartnersAction(filters,
                    pagination,
                    callBackGetData
                ));
            }
        }
    }, [pagination])
    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if ((JSON.stringify(testFilters) != JSON.stringify(filters)) && !isEmpty(filters)) {
            setTestFilters(filters);
            partnersCall();
        }
    }, [filters]);

    useEffect(() => {
        dispatch(getShopByStoresAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : ""
        },
            { PageNo: 1, PageSize: 100, SortBy: "STORE_TYPE_NAME", SortOrder: "DESC" }
            , callbackStoreTypes));
    }, []);

    const [storeTypes, setStoreTypes] = React.useState([]);

    const callbackStoreTypes = (status, data) => {
        if (status) {
            setStoreTypes(data);
        }
    }

    const [loading, setIsLoading] = React.useState(false);
    const partnersCall = () => {
        goToTop();
        setIsLoading(true);
        dispatch(getPartnersAction(filters,
            pagination,
            callBackGetData
        ));
    };





    // Authentication
    const onOpenModal = (item) => {
        let Url = item.Web_Redirection_Url;
        setTimeout(function () {
            Linking.openURL(Url);
            setRedirectionOpen(false);
        }, 2000);
    };
    const [openredirect, setRedirectionOpen] = React.useState(false);
    const toggleModal = (item) => {
        if (isAuthenticated) {
            if (item.Type === "Online") {
                setRedirectionOpen(true)
                onOpenModal(item);
                setImageURL(item.Logo_Path)
            }
        }
        else {
            setModalVisible(true);
        }
    }



    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };



    const scroll = useRef(null);
    const goToTop = () => {
        if (scroll)
            scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
    }

    const [openDropDown, setOpenDropDown] = useState(false);
    const [isLocationVisible, setIsLocationVisible] = useState(false);

    return (
        <Surface style={{ flex: 1 }}
            theme={{
                colors: {
                    surface: esiColor.BackgroundColor,
                }
            }}
        >
            <Header {...props} />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 3, paddingTop: 5, paddingLeft: 5 }} >
                    {Platform.OS == "web" ?
                        <EsiDropdown label="All" data={[{ label: "Physical", value: "Physical" }, { label: "Online", value: "Online" }, { label: "All", value: "All" }]}
                            onSelect={(item) => {
                                if (item.label == "Physical") {
                                    if (data?.type) {
                                        navigation.setParams({
                                            type: "Physical",
                                        });
                                    } else {
                                        setFilters({ ...filters, ...pFilters, Type: "Physical" });
                                    }
                                } else if (item.label == "Online") {
                                    if (filters?.Type == "Physical") {
                                        setPFilters({
                                            "Zipcode": filters?.Zipcode,
                                            "ZipcodeLocation": filters?.ZipcodeLocation,
                                            "City_ID": filters?.City_ID,
                                            "City_Info": filters?.City_Info
                                        });
                                    }
                                    if (data?.type) {
                                        navigation.setParams({
                                            type: "Online",
                                        });
                                    } else {
                                        setFilters({
                                            ...filters, Type: "Online", "Zipcode": "",
                                            "ZipcodeLocation": "",
                                            "City_ID": "",
                                            "City_Info": ""
                                        });
                                    }
                                } else {
                                    if (filters?.Type == "Physical") {
                                        setPFilters({
                                            "Zipcode": filters?.Zipcode,
                                            "ZipcodeLocation": filters?.ZipcodeLocation,
                                            "City_ID": filters?.City_ID,
                                            "City_Info": filters?.City_Info
                                        });
                                    }

                                    if (data?.type) {
                                        navigation.setParams({
                                            type: "All",
                                        });
                                    } else {
                                        setFilters({
                                            ...filters, Type: "", "Zipcode": "",
                                            "ZipcodeLocation": "",
                                            "City_ID": "",
                                            "City_Info": ""
                                        });
                                    }
                                }
                            }}
                            selected={filters?.Type}
                        /> :
                        <Menu
                            visible={openDropDown}
                            onDismiss={() => setOpenDropDown(!openDropDown)}
                            anchor={<Button mode="text" labelStyle={{ fontSize: 12, textTransform: "capitalize", fontWeight: "bold" }} icon={"account-group-outline"} color={esiColor.LCHFontColor} onPress={() => setOpenDropDown(!openDropDown)}>{filters?.Type == "" ? "All" : filters?.Type}</Button>}
                        >

                            <Menu.Item onPress={() => {
                                if (data?.type) {
                                    navigation.setParams({
                                        type: "Physical",
                                    });
                                } else {
                                    setFilters({ ...filters, ...pFilters, Type: "Physical" });
                                }
                                setOpenDropDown(!openDropDown);
                            }} title="Physical" />
                            <Menu.Item onPress={() => {
                                if (filters?.Type == "Physical") {
                                    setPFilters({
                                        "Zipcode": filters?.Zipcode,
                                        "ZipcodeLocation": filters?.ZipcodeLocation,
                                        "City_ID": filters?.City_ID,
                                        "City_Info": filters?.City_Info
                                    });
                                }
                                if (data?.type) {
                                    navigation.setParams({
                                        type: "Online",
                                    });
                                } else {
                                    setFilters({
                                        ...filters, Type: "Online", "Zipcode": "",
                                        "ZipcodeLocation": "",
                                        "City_ID": "",
                                        "City_Info": ""
                                    });
                                }
                                setOpenDropDown(!openDropDown);
                            }} title="Online" />
                            <Menu.Item onPress={() => {
                                if (filters?.Type == "Physical") {
                                    setPFilters({
                                        "Zipcode": filters?.Zipcode,
                                        "ZipcodeLocation": filters?.ZipcodeLocation,
                                        "City_ID": filters?.City_ID,
                                        "City_Info": filters?.City_Info
                                    });
                                }

                                if (data?.type) {
                                    navigation.setParams({
                                        type: "All",
                                    });
                                } else {
                                    setFilters({
                                        ...filters, Type: "", "Zipcode": "",
                                        "ZipcodeLocation": "",
                                        "City_ID": "",
                                        "City_Info": ""
                                    });
                                }
                                setOpenDropDown(!openDropDown);
                            }} title="All" />
                        </Menu>}
                </View>
                <View style={{ flex: 4, paddingHorizontal: 5 }} >

                </View>
                <View style={{ flex: 3 }} >
                    <TouchableOpacity style={{ marginRight: -5, flexDirection: "row", justifyContent: "flex-end" }}
                        onPress={() => {

                            if (data?.top || data?.top == false || data?.top == true) {
                                navigation.setParams({
                                    top: (data?.top == false || data?.top == "false") ? true : false,
                                });
                            } else {
                                setPageOld(true);
                                setPagination({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
                                setFilters({
                                    ...filters,
                                    Top_Stores: filters?.Top_Stores == "" ? 1 : ""
                                });
                            }
                        }}
                    >
                        <Checkbox.Item mode="android" color={esiColor.LCHFontColor} label="Top Stores" labelStyle={{
                            fontWeight: 'bold',
                            color: esiColor.LCHFontColor,
                            fontSize: 12
                        }}
                            style={{
                                height: 30
                            }}
                            uncheckedColor={esiColor.CBCUcolor}
                            status={filters?.Top_Stores == 1 ? "checked" : "unchecked"}
                        />
                    </TouchableOpacity>
                </View>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 2 }}>
                <View style={{ flex: 5, flexDirection: "row" }} >
                    {filters?.Type == "Physical" && <>
                        <TouchableOpacity style={{ flexDirection: "row" }} onPress={() => { setIsLocationVisible(true) }}>
                            <EvilIcons
                                name="location"
                                size={20}
                                color={'#27b6cc'}
                                style={{ marginLeft: 5 }}

                            />
                            <Text style={{
                                marginTop: Platform.OS == "web" ? 3 : 0,
                                fontWeight: 'bold',
                                color: esiColor.LCHFontColor
                            }}> {(filters?.Zipcode || filters?.City_ID) ? filters?.ZipcodeLocation || filters?.City_Info : "Select Location"}</Text>

                        </TouchableOpacity>
                        {(filters?.Zipcode || filters?.City_ID) ?
                            <IconButton size={16} style={{ marginTop: 1 }} color="#27b6cc" icon={"close"}
                                onPress={() => {
                                    setFilters({ ...filters, Zipcode: "", ZipcodeLocation: "", City_ID: "", City_Info: "" });
                                }}
                            /> : <></>
                        }
                    </>
                    }


                </View>
                <View style={{ flex: 5 }}>

                    {searchBarOpen || filters?.Search_Partner_Name?.length > 0 ?
                        <Searchbar iconColor={esiColor.LCHFontColor} style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor, borderWidth: 0.5, maxHeight: 30 }}
                            inputStyle={{ fontSize: 12, marginLeft: -20 }}
                            value={filters?.Search_Partner_Name}
                            autoFocus
                            onChangeText={(value) => {
                                setPageOld(true);
                                setPagination({ PageNo: 1, PageSize: 50, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
                                setFilters({
                                    ...filters,
                                    Search_Partner_Name: value
                                });
                            }}
                            onBlur={() => {
                                setSearchBarOpen(false);
                            }}
                            onPressOut={() => {
                                setSearchBarOpen(false);
                            }}
                            placeholderTextColor={esiColor.SPHTextColor}
                            theme={{ colors: { text: esiColor.Text } }}

                            multiline={false}
                            placeholder="Search"
                            icon={() => <EvilIcons
                                name="search"
                                size={20}
                                color={esiColor.LCHFontColor}
                            // style={{marginHorizontal:-15}}
                            />}
                        />
                        :
                        <EvilIcons
                            name="search"
                            size={30}
                            color={esiColor.LCHFontColor}
                            style={{ alignSelf: "flex-end", marginRight: 15 }}
                            onPress={() => { setSearchBarOpen(true) }}
                        />
                    }
                </View>
            </View>

            <View style={{ flexDirection: 'row', height: "75%", width: "100%" }}>
                <View style={{ flex: 2, flexDirection: "column", justifyContent: "flex-start", marginHorizontal: 4, maxWidth: 50 }} >

                    <FlatList
                        // horizontal={true}
                        // columnWrapperStyle={{ justifyContent: "space-around" }}
                        showsVerticalScrollIndicator={false}
                        data={storeTypes}
                        renderItem={({ item, index, separators }) => {
                            let store = item;
                            return (<TouchableOpacity key={"store" + index}
                                onPress={() => {
                                    if (filters?.Store_Name != store?.Store_Type_Name) {
                                        setFilters({ ...filters, Store_Type: store?.Store_Type_Id || store?.Store_Type_Name, Store_Name: store?.Store_Type_Name });
                                    } else {
                                        setFilters({ ...filters, Store_Type: "", Store_Name: "" });
                                    }
                                }}
                            >
                                <Surface style={{
                                    borderRadius: 10, marginHorizontal: 'auto', marginVertical: 5, backgroundColor: esiColor.CBColor,
                                    shadowColor: esiColor.brandShadowColor, borderColor: filters?.Store_Name != store?.Store_Type_Name ? "#CACFD2" : "#27b6cc",
                                    borderWidth: 0.5,
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.2,
                                    shadowRadius: 10,
                                    elevation: 10,
                                    padding: 2
                                }}
                                >
                                    <Image source={{ cache: "force-cache", uri: store?.Image_Path + `?w=40&h=40` }} style={{ height: 40, width: 40, alignSelf: "center", opacity: 1, borderRadius: 8 }} resizeMode='stretch' ></Image>
                                    <Text style={{ fontSize: 10, alignSelf: "center", color: esiColor.itemColor }}>{store?.Store_Type_Name.split(" ")[store?.Store_Type_Name.split(" ").length - 1]}</Text>
                                </Surface>
                            </TouchableOpacity>)
                        }}
                    />

                </View>
                <FlatList
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={partnersCall}
                        />
                    }
                    style={{ flex: 1, marginTop: 5, marginBottom: 10 }}
                    data={partners}
                    numColumns={1}
                    contentContainerStyle={{ marginVertical: 2 }}
                    renderItem={({ item, index, separators }) => (
                        <EsiPartnerCard item={item} isLocation={(filters?.Zipcode || filters?.City_ID) ? true : false} toggleModal={toggleModal} signInModalResponse={signInModalResponse} isAuthenticated={isAuthenticated} isModalVisible={isModalVisible} setModalVisible={setModalVisible} {...props} />
                    )}
                    ListFooterComponent={() =>
                        <View >
                            <Pagination pagination={pagination} setPagination={setPagination} />
                        </View>
                    }
                    ListEmptyComponent={() => <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                        <Text style={{ color: esiColor.NoDataFound }}>No partners found!</Text>
                    </View>}
                />
            </View>




            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isLocationVisible}>
                <View style={{ flex: 1, maxWidth: dimensions.width - 10, minWidth: dimensions.width - 20, minHeight: 500, maxHeight: dimensions.height - 100, borderRadius: 10, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <PartnerLocation isLocationVisible={isLocationVisible} setIsLocationVisible={setIsLocationVisible} filters={filters} setFilters={setFilters} />
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>

            {/* Partner Redirection Modal */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openredirect}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 150, borderRadius: 10, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <View style={{ alignItems: 'flex-end', marginTop: '-6%' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ color: "#27b6cc", textAlign: 'center', marginBottom: 10 }}>Redirecting to partner site. Please wait....</Text>
                    </View>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />
                            <Button color="#27b6cc" icon="arrow-right-bold" style={{ size: 15, marginTop: 10 }} />
                            {imageURL ?
                                <Image source={{ cache: "force-cache", uri: imageURL }} style={{ height: 60, width: 60, resizeMode: 'contain' }} /> : <></>}
                        </View>
                    </View>
                </View>
            </Modal>
        </Surface>

    );
}
