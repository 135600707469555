import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Text, Provider, Surface, TouchableRipple } from "react-native-paper";
import { View, Button, TouchableOpacity, Image, Dimensions, RefreshControl, ScrollView } from "react-native";
import Header from "../header";
import { getsingleFarmersAction } from "../../state/actions/farmerActions";
import FarmerProducts from "./FarmerProducts";
import { Ionicons } from "@expo/vector-icons";

 
export default function FarmerDetails(props) {
    const { navigation } = props;
    const partnerId = props?.route?.params?.Partner_Details_Id;

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const dispatch = useDispatch();
    const [partnerdata, setPartnerData] = useState(); 

    const callBackGetPartnerData = (status, Partner) => {
        if (status) {
            setPartnerData(Partner[0]);
        }

    };

    useEffect(() => {
        onRefresh();
    }, [partnerId]);

    const onRefresh = () => {
        // setVisible(10);

        dispatch(getsingleFarmersAction({
            "search_by_filter": "FILTER",
            "Partner_Detail_Id": partnerId

        }, callBackGetPartnerData));

    };

    const esiColor = useSelector(state => state.theme);

    return (
        <Surface theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{ flex: 1 }}>

            <Header {...props} />

            <View style={{ flexDirection: "column", width: "100%" }} >

                <View style={{ flexDirection: "row", marginHorizontal: '2%' }} >
                    <TouchableOpacity style={{ flex: 3, marginLeft: 8, alignSelf: "flex-start" }} onPress={() => navigation.navigate('Farmers')}>
                        <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
                    </TouchableOpacity>
                </View>
            </View>

            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={onRefresh}
                    />
                }

                style={{ marginHorizontal: '2%' }}>

                <View style={{ zIndex: 1, flex: 3 }}>
                    <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }} >
                            <Image source={{ cache: "force-cache", uri: partnerdata?.Logo_Path }} style={{ maxHeight: 150, minHeight: 100, width: dimensions.width * 0.9, borderRadius: 8 }} resizeMode={'stretch'}></Image>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 2 }} >
                            <Text style={{ fontSize: 20, color: esiColor.brandFontColor }}>{partnerdata?.Name}</Text>
                        </View>
                    </View>

                </View>

                {partnerdata?.Is_Active == 1 &&
                    <View style={{ marginVertical: 5 }}>
                        <FarmerProducts navigation={props.navigation} partnerId={partnerdata?.Partner_Details_Id} />
                    </View>}
            </ScrollView>
        </Surface>
    )

}