import * as React from 'react';
import { Avatar, Button, Checkbox, Colors, HelperText, ProgressBar, RadioButton, Surface, Text, TextInput, TouchableRipple } from 'react-native-paper';
import { material } from 'react-native-typography';
import { Linking, View, Dimensions, ScrollView, Platform, ActivityIndicator, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { signUpEmailExist, signUpMobileExist, signUpNew } from '../../state/actions/authActions';
import ESITextInput from '../../components/custom-fields/textInput';
import isEmpty from '../../state/validations/is-empty';

// import { TouchableOpacity } from 'react-native-gesture-handler';
// import {logo} from '../../assets/images/img/logo/meePaisa_logo.png'

function Copyright() {
    return (

        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Text onPress={() => {
                Linking.openURL("https://www.easystepin.com/").catch((err) =>
                    console.error('An error occurred', err),
                );
            }} style={[material.caption, {}]}>{'Copyright © '}
                EasyStepIn
                {' 2016 - '}
                {new Date().getFullYear()}
                {'.'}
            </Text>
        </View>

    );
}


const SignUPNew = (props) => {

    const { navigation, toggleModal, dropDownAlertFun } = props;
    const [isSubmited, setIsSubmited] = React.useState(false);
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();
    const phoneInput = React.useRef(null);
    const { referral } = props?.route?.params || false;
    const [signupInit, setSignupInit] = React.useState({
        Email_ID: '',
        Mobile: '',
        Name: '',
        Mobile_OTP: '',
        Terms: false,
        Marketing: false,
        Reference_Id: referral ? referral : '',
        Password: '',
        cpassword: ''
    });


    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const signIn = () => {
        toggleModal();
    }

    const [errorMessage, setErrorMessage] = React.useState('');
    const signUpCallResponse = (status: any, errorMessage: any, response: any) => {
        setErrorMessage(errorMessage);
        setIsSubmited(false);
        if (!status) {
            setSeconds(3);
        } else {
            dropDownAlertFun();
            toggleModal();
        }
    }


    const [secondsOTP, setSecondsOTP] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => secondsOTP > 0 ? setSecondsOTP(secondsOTP - 1) : setSecondsOTP(-1), 1000)
        return () => clearTimeout(timer)
    }, [secondsOTP]);

    const [show, setShow] = React.useState(false);
    const formikRef = React.useRef();
    const [otpMobileNumber, setOtpMobileNumber] = React.useState("");
    const [otpMobileNumberError, setOtpMobileNumberError] = React.useState([]);

    const onMobileFieldOnBlur = () => {
        if (!(formikRef.current?.errors.Mobile) && otpMobileNumber != formikRef.current?.values.Mobile) {
            setOtpMobileNumber(formikRef.current?.values.Mobile);
            dispatch(signUpMobileExist(formikRef.current?.values.Mobile, mobileCheckResponse));
        }
        else if (otpMobileNumber == formikRef.current?.values.Mobile) {
        }
        else {
            setOtpMobileNumber("");
        }
    }

    React.useEffect(() => {
        let error = false;
        for (let i = 0; i <= otpMobileNumberError.length; i++) {
            if (otpMobileNumberError[i] == formikRef.current?.values.Mobile) {
                error = true;
                break;
            }
        }
        if (error)
            formikRef.current?.setFieldError("Mobile", 'Mobile Number is already exist.');
    }, [otpMobileNumberError])

    const mobileCheckResponse = (status: any, errorMessage: any, response: any, input: any) => {
        if (status) {
            setSecondsOTP(30);
        } else {
            let newOtpMobileNumberError = JSON.parse(JSON.stringify(otpMobileNumberError));
            newOtpMobileNumberError.push(input);
            setOtpMobileNumberError(newOtpMobileNumberError);
        }
    }

    const [emailExist, setEmailExist] = React.useState("");
    const [emailExistError, setEmailExistError] = React.useState([]);

    const onEmailFieldOnBlur = () => {
        if (!(formikRef.current?.errors.Email_ID) && emailExist != formikRef.current?.values.Email_ID) {
            setEmailExist(formikRef.current?.values.Email_ID);
            dispatch(signUpEmailExist(formikRef.current?.values.Email_ID, emailCheckResponse));
        }
        else if (emailExist == formikRef.current?.values.Email_ID) {
        }
        else {
            setEmailExist("");
        }
    }

    const emailCheckResponse = (status: any, errorMessage: any, response: any, input: any) => {
        if (!status) {
            let emailExistErrorN = JSON.parse(JSON.stringify(emailExistError));
            emailExistErrorN.push(input);
            setEmailExistError(emailExistErrorN);
        }
    }

    React.useEffect(() => {
        let error = false;
        for (let i = 0; i <= emailExistError.length; i++) {
            if (emailExistError[i] == formikRef.current?.values.Email_ID) {
                error = true;
                break;
            }
        }
        if (error)
            formikRef.current?.setFieldError("Email_ID", 'Email Address is already exist.');
    }, [emailExistError])

    return (
        <View style={Platform.OS === 'web' ? {
            alignItems: 'center',
            elevation: 0,
            // overflowY: "scroll",
            minHeight: 450,
            maxHeight: 480,
            top: 10,
            backgroundColor: esiColor.BackgroundColor
        } : {
            alignItems: 'center',
            elevation: 0,
            top: 20,
        }} >
            <TouchableRipple style={{ width: 36, position: 'absolute', right: 0, zIndex: 1 }} onPress={() => {
                toggleModal();
            }}>
                <Avatar.Icon color={esiColor.SBTextColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
            </TouchableRipple>
            <ScrollView keyboardShouldPersistTaps='always' style={{
                minHeight: 450,
                maxHeight: 480,
            }} showsVerticalScrollIndicator={false} >

                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 20
                }}>
                    <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10 }} />
                    <Text style={{ color: esiColor.itemButtenColor }}>Sign Up</Text>
                </View>
                <View>
                    <Formik
                        innerRef={formikRef}
                        initialValues={signupInit}

                        validationSchema={Yup.object().shape({
                            Email_ID: Yup.string()
                                .email('Please provide valid Email Address.').notOneOf(emailExistError, 'Email Address is already exist.')
                                .max(255),
                            Mobile: Yup.string().notOneOf(otpMobileNumberError, 'Mobile Number is already exist.').min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
                            Name: Yup
                                .string()
                                .required("First Name is required."),
                            Password: Yup.string()
                                .max(255)
                                .matches(/[a-z]+/, "At least one lowercase letter is required.")
                                .matches(/[A-Z]+/, "At least one uppercase letter is required.")
                                .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
                                .matches(/.*\d+/, "At least one number is required.")
                                .min(8, "At least minimum 8 characters are required.")
                                .required('Password is required.'),
                            cpassword: Yup.string()
                                .max(255).oneOf([Yup.ref('Password'), null], 'Passwords must match.')
                                .required('Confirm Password is required.'),
                            Mobile_OTP: Yup.string().when('Mobile', (Mobile, schema) => {
                                if (Mobile?.length == 10) {
                                    return schema.min(6, 'Please provide valid OTP.')
                                        .required('Mobile OTP is required.');
                                } else {
                                    return schema
                                }
                            })
                            ,
                            Terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions."),
                            Marketing: Yup.boolean().oneOf([true], "You must accept the marketing terms.")
                        })}

                        onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
                            setSubmitting(false);
                            setSignupInit(values);
                            setErrorMessage("");
                            dispatch(signUpNew({
                                ...values,
                                Mobile: "+91 " + values.Mobile,
                                Terms: values.Terms ? 1 : 0,
                                Marketing: values.Marketing ? 1 : 0
                            }, signUpCallResponse));
                            setIsSubmited(true);

                        }}
                    >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                            <View style={{
                                flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                justifyContent: 'center', marginBottom: Platform.OS == "ios" ? 150 : 10
                            }}>
                                <View style={{ width: "80%", margin: "auto" }}>
                                    <ESITextInput style={{ width: "100%", margin: "auto" }}
                                        label='Name*'
                                        mode='outlined'
                                        name="Name"
                                        value={values.Name}
                                        onChangeText={handleChange('Name')}
                                        error={Boolean(touched.Name && errors.Name)}
                                    // autoFocus
                                    />
                                    <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Name && errors.Name)}>
                                        {touched.Name && errors.Name}
                                    </HelperText>
                                </View>

                                <View style={{ width: "80%", margin: "auto" }}>
                                    <ESITextInput style={{ width: "100%", margin: "auto" }}
                                        label="Email Address"
                                        mode='outlined'
                                        onChangeText={handleChange('Email_ID')}
                                        onBlur={(props) => {
                                            handleBlur(props);
                                            onEmailFieldOnBlur();
                                        }}
                                        value={values.Email_ID}
                                        error={Boolean((touched.Email_ID || emailExistError.length > 0) && errors.Email_ID)}
                                    // autoFocus
                                    />
                                    <HelperText style={{ width: "90%" }} type="error" visible={Boolean((touched.Email_ID || emailExistError.length > 0) && errors.Email_ID)}>
                                        {(touched.Email_ID || emailExistError.length > 0) && errors.Email_ID}
                                    </HelperText>
                                </View>

                                <View style={{
                                    width: "80%", margin: "auto"
                                }}>
                                    <ESITextInput
                                        style={{ width: "100%" }}
                                        label="Mobile Number*"
                                        mode='outlined'
                                        onChangeText={handleChange('Mobile')}
                                        onBlur={(props) => {
                                            handleBlur(props);
                                            onMobileFieldOnBlur();
                                        }}
                                        value={values.Mobile}
                                        left={<TextInput.Affix textStyle={{color:esiColor.Text}} text="+91" />}
                                        error={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}
                                    />
                                    <HelperText type="error" visible={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}>
                                        {(touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile}
                                    </HelperText>
                                    {(!isEmpty(otpMobileNumber) && secondsOTP > 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                        <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right" }}
                                            visible={!isEmpty(otpMobileNumber)}>
                                            {secondsOTP} Secs To Resend OTP
                                        </HelperText>}
                                    {(!isEmpty(otpMobileNumber) && secondsOTP <= 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                        <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right" }}
                                            onPress={() => {
                                                dispatch(signUpMobileExist(otpMobileNumber, mobileCheckResponse));
                                            }}
                                            visible={!isEmpty(otpMobileNumber)}>
                                            Resend OTP
                                        </HelperText>}
                                </View>
                                {(!isEmpty(otpMobileNumber) && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                    <View style={{ width: "80%", margin: "auto" }}>
                                        <ESITextInput
                                            style={{ width: "100%" }}
                                            label="Mobile OTP"
                                            mode='outlined'
                                            onChangeText={handleChange('Mobile_OTP')}
                                            onBlur={handleBlur('Mobile_OTP')}
                                            value={values.Mobile_OTP}
                                            error={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}>
                                            {touched.Mobile_OTP && errors.Mobile_OTP}
                                        </HelperText>
                                    </View>
                                }
                                <View style={{ width: "80%", margin: "auto" }}>
                                    <ESITextInput
                                        style={{ width: "100%" }}
                                        label="Password*"
                                        mode='outlined'
                                        onChangeText={handleChange('Password')}
                                        onBlur={handleBlur('Password')}
                                        value={values.Password}
                                        secureTextEntry={!show}
                                        right={<TextInput.Icon textStyle={{color:esiColor.Text}} onPress={() => setShow(!show)} name={show ? "eye" : "eye-off"} />}
                                        error={Boolean(touched.Password && errors.Password)}
                                    />
                                    <HelperText type="error" visible={Boolean(touched.Password && errors.Password)}>
                                        {touched.Password && errors.Password}
                                    </HelperText>
                                </View>
                                <View style={{ width: "80%", margin: "auto" }}>
                                    <ESITextInput
                                        style={{ width: "100%" }}
                                        label="Confirm Password*"
                                        mode='outlined'
                                        onChangeText={handleChange('cpassword')}
                                        onBlur={handleBlur('cpassword')}
                                        value={values.cpassword}
                                        secureTextEntry={true}
                                        error={Boolean(touched.cpassword && errors.cpassword)}
                                    />
                                    <HelperText type="error" visible={Boolean(touched.cpassword && errors.cpassword)}>
                                        {touched.cpassword && errors.cpassword}
                                    </HelperText>
                                </View>

                                <View style={{ width: "80%", margin: "auto" }}>
                                    <ESITextInput style={{ width: "100%", margin: "auto" }}
                                        label="Referral Code"
                                        mode='outlined'
                                        onChangeText={handleChange('Reference_Id')}
                                        onBlur={handleBlur('Reference_Id')}
                                        value={values.Reference_Id}
                                        error={Boolean(touched.Reference_Id && errors.Reference_Id)}
                                    // autoFocus
                                    />
                                    <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Reference_Id && errors.Reference_Id)}>
                                        {touched.Reference_Id && errors.Reference_Id}
                                    </HelperText>
                                </View>

                                <View style={{ width: "80%", margin: "auto" }}>
                                    <View style={{
                                        flexDirection: "row",
                                    }}>
                                        <RadioButton.Android
                                            value={true}
                                            name="Terms"
                                            status={values.Terms ? 'checked' : 'unchecked'}
                                            onPress={() => setFieldValue('Terms', !values.Terms)}
                                            uncheckedColor={esiColor.SBTextColor}
                                        />
                                        <Text style={{ marginTop: 10, color: Boolean(touched.Terms && errors.Terms) ? "#b00020" : esiColor.SBTextColor }}>I agree to the{" "}
                                        </Text>

                                        <TouchableOpacity onPress={() => {
                                            navigation.navigate('TermsAndConditions');
                                            toggleModal(true);
                                        }
                                        }><Text style={{ marginTop: 10, textDecorationLine: 'underline', color: "#27b6cc" }} >terms and conditions.</Text></TouchableOpacity>
                                    </View>
                                </View>

                                <View style={{ width: "80%", margin: "auto" }}>
                                    <View style={{
                                        flexDirection: "row",
                                    }}>
                                        <RadioButton.Android
                                            value={true}
                                            name="Marketing"
                                            status={values.Marketing ? 'checked' : 'unchecked'}
                                            onPress={() => setFieldValue('Marketing', !values.Marketing)}
                                            uncheckedColor={esiColor.SBTextColor}
                                        />
                                        <Text style={{ marginTop: 10, color: Boolean(touched.Marketing && errors.Marketing) ? "#b00020" : esiColor.SBTextColor }}>I agree to the{" "}
                                        </Text>

                                        <TouchableOpacity onPress={() => {
                                            navigation.navigate('MarketingTerms');
                                            toggleModal(true);
                                        }
                                        }><Text style={{ marginTop: 10, textDecorationLine: 'underline', color: "#27b6cc" }} >marketing terms.</Text></TouchableOpacity>
                                    </View>
                                </View>

                                <View style={{
                                    width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                                        height: 5,
                                        width: 250,
                                    }} progress={.30 * seconds} color={Colors.red900} /></>}
                                </View>
                                <View style={{
                                    width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    {isSubmited ? <ActivityIndicator color='#27b6cc' /> :
                                        <TouchableWithoutFeedback onPress={() => { }}>
                                            <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} disabled={isSubmited} mode="contained" onPress={handleSubmit}>
                                                Sign Up
                                            </Button>
                                        </TouchableWithoutFeedback>
                                    }
                                </View>
                                <View style={{ width: "80%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'center', fontSize: 10 }}>

                                    <TouchableRipple
                                        onPress={signIn}
                                    >
                                        <Text style={{ fontSize: 11, textDecorationLine: 'underline' }}>Already have an account? Sign in</Text>
                                    </TouchableRipple>
                                </View>
                            </View>
                        )}
                    </Formik>
                </View>
            </ScrollView>
            <Copyright />

        </View>
    );
};

export default SignUPNew;
