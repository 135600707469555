import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersAction } from "../../state/actions/node-actions/home-actions";

export default function MeeStores(props) {
    const { navigation, dataReload, dataErrorReload, setCityDataExist = (() => { }), address = {}, storeType = null } = props;

    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const [AllPartners, setAllPartners] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);


    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setAllPartners(data);
            if (address?.City_Id && storeType == null) {
                setCityDataExist(data.length > 0 ? true : false);
            }
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            // partnersCall();
            setReloadPage(!reloadPage);
        } else {
            if (address?.City_Id && storeType == null) {
                setCityDataExist(false);
            }
            await delay(dataErrorReload);
            // partnersCall();
            setReloadPage(!reloadPage);
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);
    const [cityId, setCityId] = React.useState("");


    useEffect(() => {
        if (address?.City_Id) {
            if (cityId != address?.City_Id) {
                setCityId(address?.City_Id);
                partnersCall()
            }
        } else {
            partnersCall()
        }
    }, [address?.City_Id]);

    useEffect(() => {
        if (reloadPage) {
            partnersCall();
            setReloadPage(false);
        }
    }, [reloadPage]);

    const partnersCall = () => {
        setIsLoading(true);
        dispatch(getPartnersAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : "",
            "City_ID": address?.City_Id || "",
            "Store_Type": storeType
        },
            { PageNo: 1, PageSize: 10, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" }
            , callBackGetData));
    }

    const handleClick = async (data) => {
        if (!(data?.Product_Count == '0')) {
            navigation('PartnerProducts', { Partner_Details_Id: data.Partner_Details_Id, back: "Home" });
        } else
            navigation('PartnerDetails', { Partner_Details_Id: data.Partner_Details_Id })
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const styles = StyleSheet.create({
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10,
        },
        item: {
            borderRadius: 5,
            borderWidth: 0.2,
            borderColor: esiColor.brandShadowColor,
            marginRight: 10,
            padding: 5,
            flex: 1,
            maxHeight: 50,
            minHeight: 50
        }

    });

    return (AllPartners.length > 0 &&
        <View>
            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', marginLeft: 2 }}> {props.title}</Text>

            <View style={styles.content}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 5 }}>
                    <TouchableOpacity onPress={() => {
                        if (address) {
                            if (address?.City_Id) {
                                navigation('Partners', { city: address.City_Id, cityName: address.City });

                            } else {
                                navigation('Partners', { type: props.type });
                            }
                        } else
                            navigation('Partners', { type: props.type });
                    }} style={{ position: 'absolute', right: 10 }}>
                        <Text style={{ fontSize: 14, color: esiColor.LCHFontColor, fontWeight: 'bold' }}>View All</Text>
                    </TouchableOpacity>
                </View>
                <FlatList
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={AllPartners.slice(0, 10)}
                    renderItem={({ item, index, separators }) => (
                        <View key={index} style={{ maxWidth: 120 }} >
                            <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                                <Image source={{ cache: "force-cache", uri: item?.Logo_Path + `?w=90&h=50` }} style={{ height: 50, flex: 1, alignSelf: "center", resizeMode: 'contain', width: 90 }}></Image>
                            </TouchableOpacity>
                            <View style={{ marginTop: 5 }}>
                                <Text style={{ fontSize: 14, color: esiColor.itemColor, textAlign: 'center', marginRight: 10 }}>{item?.Name}</Text>
                            </View>
                        </View>
                    )}
                />
            </View>
        </View>

    )
}


