import * as React from 'react';
import { Dimensions, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import * as Yup from 'yup';
import { Button } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { addEventsAction, getHallIdAction } from '../../state/actions/eventsActions';
import isEmpty from '../../state/validations/is-empty';
import Header from '../header';
import { ActivityIndicator, Checkbox, HelperText, List, Provider, RadioButton, Surface } from 'react-native-paper';
import razorpayPay from "../../hooks/razorpay";
import useRazorpay from "react-razorpay";
import ESITextInput from '../../components/custom-fields/textInput';
import { Formik } from 'formik';
import EsiDateTimePicker from '../../components/custom/datetime/datetime';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import { Ionicons } from '@expo/vector-icons';

const { height, width } = Dimensions.get('window');
export default function AddEventDetails(props) {
    const dispatch = useDispatch();
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }
    const toast = useToast();
    const data = props?.route?.params.data
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [isSelected, setSelection] = React.useState(false);
    const [hall, setHallData] = React.useState([]);
    const [ID, setID] = React.useState("");
    const [isFoodSelected, setFoodSelection] = React.useState(false);
    const [isRoomSelected, setRoomSelection] = React.useState(false);
    const [isAlcoholSelected, setAlcoholSelection] = React.useState(false);
    const [isLegalPermissionSelected, setLegalPermissionSelected] = React.useState(false);
    const [expanded, setExpanded] = React.useState(true);
    const handlePress = () => setExpanded(!expanded);
    const user = useSelector((state) => state.auth.user);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimentions = dimensions / 1.06;
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    React.useEffect(() => {
        if (isEmpty(user.Email_Id)) {
            setTimeout(() => {
                if (isEmpty(user.Email_Id)) {
                    props.navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [user.Email_Id])

    const callBack = (data) => {
        setHallData(data)
    }

    React.useEffect(() => {
        if (isEmpty(hall) || (ID !== data)) {
            let formData = {
                Function_Hall_ID: data,
                Records_Filter: "FILTER"
            }
            setID(hall?.Function_Hall_Id)
            dispatch(getHallIdAction(formData, callBack))
        }
    }, [hall])

    const [isImageViewVisible, setIsImageViewVisible] = React.useState(false);
    const [index, setIndex] = React.useState(0);

    const payNow = (formData) => {
        let amount = (Math.round(Number(hall?.Advance_Amount_per_Day) + Number.EPSILON));

        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    formData.Advance_Payment_Status = "Success"
                    formData.Advance_Payment_Gateway_Amount = amount
                    formData.Advance_Payment_Gateway_Reference_Number = response.razorpay_payment_id
                    dispatch(addEventsAction(formData, props.navigation.navigate, user.Email_Id, toast))
                } else {

                }
            });
        } else {

        }
    }


    const values = {
        AC_ELECTRICITY_PRICE_PER_HOUR: hall?.AC_Electricity_Price_Per_Hour,
        ANY_EXTRA_CHARGES: hall?.Any_Extra_Charges,
        BOOKING_STATUS: "PENDING",
        DISCOUNT_PERCENTAGE: hall?.Discount_Percentage,
        ELECTRICITY_PRICE_PER_HOUR: hall?.Electricity_Price_Per_Hour,
        EVENT_BOOKING_REQUESTED_DATE: "",
        EVENT_END_DATE: "",
        Advance_Amount: hall?.Advance_Amount_per_Day,
        //   EVENT_PRICE: hall?.,
        EVENT_START_DATE: "",
        EVENT_STATUS: "NEW",
        EVENT_TOTAL_MEMBERS: "",
        FOOD_NONVEG_PRICE_PER_PLATE: hall?.Hall_Food_Non_Veg_Price_Per_Plate,
        FOOD_VEG_PRICE_PER_PLATE: hall?.Hall_Food_Veg_Price_Per_Plate,
        FUNCTION_HALL_ID: hall?.Function_Hall_Id,
        HALL_DECORATION_PRICE: hall?.Hall_Decoration_Price,
        IS_EVENT_HAS_LEGAL_PERMISSION: isLegalPermissionSelected ? "1" : "0",
        IS_EVENT_REQUIRED_ALCHOL: isAlcoholSelected ? "1" : "0",
        Is_Food_Required: isFoodSelected ? "1" : "0",
        Is_Hall_Decoration_Required: isSelected ? "1" : "0",
        Is_Rooms_Required: isRoomSelected ? "1" : "0",
        No_Of_AC_Rooms: "",
        No_Of_NonAC_Rooms: "",
        No_Of_NonVeg_Plates: "",
        No_Of_Veg_Plates: "",
        PRICE_PER_DAY: hall?.Price_Per_Day,
        PRICE_PER_HOUR: hall?.Price_Per_Hour,
        ROOM_COST_PER_NIGHT: hall?.Room_Cost_Per_Night,
        STATUS: hall?.Status,
        USER_EMAIL_ID: user.Email_Id,
    }
    return (
        <Surface style={{ flex: 1 }}>
            <Header {...props} />
            <Provider>
                <View style={{ flexDirection: "column", flexWrap: "wrap", alignItems: 'center', alignContent: 'center' }}>
                    <Text style={{ fontSize: 16, marginTop: 5, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>Please provide below deatils for booking</Text>
                </View>
                <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => { props.navigation.navigate("EventDetails", { data: data }) }}>
                    <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
                </TouchableOpacity>
                {!isEmpty(hall) ?
                    <ScrollView>
                        <Formik
                            initialValues={values}

                            validationSchema={Yup.object().shape({
                                EVENT_TOTAL_MEMBERS: Yup.string().matches(/^[0-9]+$/, "Please enter valid number.").required("Event members is requried.").test('test_length_greater_than_40', '', function (value) {
                                    if (Number(value) > Number(hall?.Hall_Seating_Capacity)) {
                                        return this.createError({ message: `Functional hall capacity is  ${hall?.Hall_Seating_Capacity} Please provide valid.` })
                                    }
                                    return true
                                }),
                                No_Of_NonVeg_Plates: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_Veg_Plates', { // Change approvalState to string
                                        is: (value: any) => !value && isFoodSelected,
                                        then: Yup.string().required("Number of non veg plates are requried."),
                                    })),
                                No_Of_Veg_Plates: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_NonVeg_Plates', { // Change approvalState to string
                                        is: (value: any) => !value && isFoodSelected,
                                        then: Yup.string().required("Number of veg plates are requried."),
                                    })),
                                No_Of_AC_Rooms: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_NonAC_Rooms', { // Change approvalState to string
                                        is: (value: any) => !value && isRoomSelected,
                                        then: Yup.string().required("Number of veg plates are requried."),
                                    })),
                                No_Of_NonAC_Rooms: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_AC_Rooms', { // Change approvalState to string
                                        is: (value: any) => !value && isRoomSelected,
                                        then: Yup.string().required("Number of non veg plates are requried."),
                                    })),
                                EVENT_START_DATE: Yup.date().min(new Date(), 'Please choose future date').required("Event Start Date is required."),
                                EVENT_END_DATE: Yup.date().min(Yup.ref("EVENT_START_DATE"), "End date has to be more than start date.").required("Event End Date is required."),
                            })}

                            onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
                                let formData = JSON.parse(JSON.stringify(values));
                                payNow(formData)
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                                <View style={{
                                    flex: 1,
                                    marginHorizontal: 2, padding: 5, margin: 'auto', backgroundColor: 'white', flexDirection: 'column', borderColor: "#CACFD2", borderWidth: 1,
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowColor: '#6D70E7',
                                    marginTop: "1%",
                                    marginBottom: "1%",
                                    shadowOpacity: 0.2,
                                    shadowRadius: 10,
                                    elevation: 10,
                                    marginLeft: "auto", marginRight: "auto",
                                    width: dimensions >= 10000 ? "90%" : dimensions,
                                }}>
                                    <View style={{ width: "98%", margin: "auto" }}>
                                        <ESITextInput style={{ width: "100%", margin: "auto" }}
                                            label="Number of Attendees"
                                            mode='outlined'
                                            name="EVENT_TOTAL_MEMBERS"
                                            value={values.EVENT_TOTAL_MEMBERS}
                                            onChangeText={handleChange('EVENT_TOTAL_MEMBERS')}
                                            error={Boolean(touched.EVENT_TOTAL_MEMBERS && errors.EVENT_TOTAL_MEMBERS)}
                                        />
                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.EVENT_TOTAL_MEMBERS && errors.EVENT_TOTAL_MEMBERS)}>
                                            {touched.EVENT_TOTAL_MEMBERS && errors.EVENT_TOTAL_MEMBERS}
                                        </HelperText>
                                    </View>

                                    <View style={{ width: "98%", margin: "auto" }}>
                                        <EsiDateTimePicker style={{ width: "100%", margin: "auto" }}
                                            label="Event Start Date and Time"
                                            mode='outlined'
                                            name="EVENT_START_DATE"
                                            value={values.EVENT_START_DATE}
                                            valueUpdate={item => {
                                                setFieldValue('EVENT_START_DATE', item)
                                            }}
                                            error={Boolean(touched.EVENT_START_DATE && errors.EVENT_START_DATE)}
                                        />
                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.EVENT_START_DATE && errors.EVENT_START_DATE)}>
                                            {touched.EVENT_START_DATE && errors.EVENT_START_DATE}
                                        </HelperText>
                                    </View>

                                    <View style={{ width: "98%", margin: "auto" }}>
                                        <EsiDateTimePicker style={{ width: "100%", margin: "auto" }}
                                            label="Event End Date and Time"
                                            mode='outlined'
                                            name="EVENT_END_DATE"
                                            value={values.EVENT_END_DATE}
                                            valueUpdate={item => {
                                                setFieldValue('EVENT_END_DATE', item)
                                            }}
                                            error={Boolean(touched.EVENT_END_DATE && errors.EVENT_END_DATE)}
                                        />
                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.EVENT_END_DATE && errors.EVENT_END_DATE)}>
                                            {touched.EVENT_END_DATE && errors.EVENT_END_DATE}
                                        </HelperText>
                                    </View>
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="Is_Hall_Decoration_Required"
                                                status={isSelected ? 'checked' : 'unchecked'}
                                                onPress={() => { setFieldValue("Is_Hall_Decoration_Required", !isSelected ? "1" : "0"); setSelection(!isSelected) }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Decoration Required?</Text>

                                        </View>
                                    </View>

                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="Is_Food_Required"
                                                status={isFoodSelected ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Food_Required", !isFoodSelected ? "1" : "0"); setFoodSelection(!isFoodSelected); !isFoodSelected && (setFieldValue("No_Of_Veg_Plates", ""),
                                                        setFieldValue("No_Of_NonVeg_Plates", ""))
                                                }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Food Required?</Text>

                                        </View>
                                    </View>
                                    {isFoodSelected &&
                                        <View style={{
                                            flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput style={{ width: "100%", margin: "auto" }}
                                                    label="Number Of Veg Plates"
                                                    mode='outlined'
                                                    name="No_Of_Veg_Plates"
                                                    value={values.No_Of_Veg_Plates}
                                                    onChangeText={handleChange('No_Of_Veg_Plates')}
                                                    error={Boolean(values.Is_Food_Required && touched.No_Of_Veg_Plates && errors.No_Of_Veg_Plates)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(values.Is_Food_Required && touched.No_Of_Veg_Plates && errors.No_Of_Veg_Plates)}>
                                                    {values.Is_Food_Required && touched.No_Of_Veg_Plates && errors.No_Of_Veg_Plates}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput 
                                                    style={{ width: "100%" }}
                                                    label="Number Of Non Veg Plates"
                                                    mode='outlined'
                                                    name="No_Of_NonVeg_Plates"
                                                    value={values.No_Of_NonVeg_Plates}
                                                    onChangeText={handleChange('No_Of_NonVeg_Plates')}
                                                    error={Boolean(values.Is_Food_Required && touched.No_Of_NonVeg_Plates && errors.No_Of_NonVeg_Plates)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(values.Is_Food_Required && touched.No_Of_NonVeg_Plates && errors.No_Of_NonVeg_Plates)}>
                                                    {values.Is_Food_Required && touched.No_Of_NonVeg_Plates && errors.No_Of_NonVeg_Plates}
                                                </HelperText>
                                            </View>
                                        </View>
                                    }
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="Is_Rooms_Required"
                                                status={isRoomSelected ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Rooms_Required", !isRoomSelected ? "1" : "0"); setRoomSelection(!isRoomSelected); !isRoomSelected && (setFieldValue("No_Of_AC_Rooms", ""),
                                                        setFieldValue("No_Of_NonAC_Rooms", ""))
                                                }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Rooms Required?</Text>

                                        </View>

                                    </View>
                                    {isRoomSelected &&
                                        <View style={{
                                            flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput style={{ width: "100%", margin: "auto" }}
                                                    label="Number Of AC rooms"
                                                    mode='outlined'
                                                    name="No_Of_AC_Rooms"
                                                    value={values.No_Of_AC_Rooms}
                                                    onChangeText={handleChange('No_Of_AC_Rooms')}
                                                    error={Boolean(values.Is_Rooms_Required && touched.No_Of_AC_Rooms && errors.No_Of_AC_Rooms)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(values.Is_Rooms_Required && touched.No_Of_AC_Rooms && errors.No_Of_AC_Rooms)}>
                                                    {values.Is_Rooms_Required && touched.No_Of_AC_Rooms && errors.No_Of_AC_Rooms}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput 
                                                    style={{ width: "100%" }}
                                                    label="Number Of Non AC rooms"
                                                    mode='outlined'
                                                    name="No_Of_NonAC_Rooms"
                                                    value={values.No_Of_NonAC_Rooms}
                                                    onChangeText={handleChange('No_Of_NonAC_Rooms')}
                                                    error={Boolean(values.Is_Rooms_Required && touched.No_Of_NonAC_Rooms && errors.No_Of_NonAC_Rooms)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(values.Is_Rooms_Required && touched.No_Of_NonAC_Rooms && errors.No_Of_NonAC_Rooms)}>
                                                    {values.Is_Rooms_Required && touched.No_Of_NonAC_Rooms && errors.No_Of_NonAC_Rooms}
                                                </HelperText>
                                            </View>
                                        </View>
                                    }
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="IS_EVENT_REQUIRED_ALCHOL"
                                                status={isAlcoholSelected ? 'checked' : 'unchecked'}
                                                onPress={() => { setFieldValue("IS_EVENT_REQUIRED_ALCHOL", !isAlcoholSelected ? "1" : "0"), setAlcoholSelection(!isAlcoholSelected) }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Alcohol Required?</Text>

                                        </View>
                                    </View>
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="IS_EVENT_HAS_LEGAL_PERMISSION"
                                                status={isLegalPermissionSelected ? 'checked' : 'unchecked'}
                                                onPress={() => { setFieldValue("IS_EVENT_HAS_LEGAL_PERMISSION", !isLegalPermissionSelected ? "1" : "0"); setLegalPermissionSelected(!isLegalPermissionSelected) }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Event has Legal Permission?</Text>

                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingBottom: 5 }}>
                                        <Text style={{ fontWeight: "bold" }}>
                                            ADVANCE BOOKING AMOUNT :
                                        </Text>
                                        <Text style={{ color: "#27b6cc", fontWeight: "bold" }}>
                                            {hall?.Advance_Amount_per_Day}/-
                                        </Text>
                                    </View>
                                    <Button
                                        onPress={handleSubmit}
                                        buttonStyle={{ backgroundColor: "#27b6cc", borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                                        title='CONFIRM BOOKING' />
                                </View>
                            )}
                        </Formik>
                    </ScrollView>
                    : <ActivityIndicator animating={true} color={"#27b6cc"} />}
            </Provider>
        </Surface >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    tinyLogo: {
        width: 500,
        height: 200,
    },
});
