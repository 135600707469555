import React, { useEffect } from "react";
import { ActivityIndicator, Button, Surface, Text } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import { View, ScrollView, RefreshControl } from "react-native";
import RenderHTML from "react-native-render-html";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import { List } from 'react-native-paper';
import { getAllFaqAction } from "../../state/actions/faqActions";
import Accordian from "../../components/accordian";

const AllFaq = (props) => {
    const { navigation } = props;
    const dispatch = useDispatch();
    let faq = useSelector((state) => state.allFAQs.faqs.all);
    const loading = useSelector(state => state.allFAQs.faqs.isLoading);
    const esiColor = useSelector(state => state.theme);

    useEffect(() => {
        if (isEmpty(faq)) {
            refreshControlCall();
        }
    }, []);

    const refreshControlCall = () => {
        dispatch(getAllFaqAction());
    }
    return (
        <Surface style={{ flex: 1 }}>
            <Header {...props} />
            <Text style={{ fontSize: 20, marginTop: 5, marginBottom: 10, fontWeight: "bold", textAlign: 'center', color: "#27b6cc", justifyContent: 'center' }}>FAQ's</Text>
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={refreshControlCall}
                    />
                }
            >
                {!isEmpty(faq) ? faq.map((data: any, index: any) => (
                    <View key={index} style={{ marginLeft: 10, marginRight: 10, flexWrap: "wrap" }} >
                        <Accordian data={data}  >
                            <View
                                style={{
                                    borderBottomColor: '#9ce5f0',
                                    borderBottomWidth: 1,
                                    marginBottom: 5
                                }}
                            />
                            <View style={{ flexWrap: "wrap", padding: 5, backgroundColor: esiColor.RTBC, borderRadius: 8 }} >
                                <RenderHTML
                                    source={{ html: data?.Answer }} />
                            </View>
                        </Accordian>
                    </View>)
                ) :
                    <ActivityIndicator color='#27b6cc' animating={loading ? true : false} style={{ marginTop: 20 }} />
                }
            </ScrollView>
        </Surface >
    );
}


export default AllFaq;