import { Entypo } from "@expo/vector-icons";
import React, { useState, useEffect, useMemo } from "react";
import { ActivityIndicator, Dimensions, FlatList, Image, Linking, RefreshControl, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Tooltip } from "react-native-elements";
import { Avatar, Button, Card, Colors, IconButton, Surface, TextInput, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ImageCarousel from "../../components/custom/image-carousel";
import PartnerProductCard from "../../components/custom/partner-product-card";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { apiActions } from "../../state/actions";
import { addProductReviewAction, adduservisitAction, editProductReviewAction, getPartnerproductsbyIdAction, getProductByIdAction, getProductColorsAction, getProductsImageAction, getProductSizeActionWishList, getRelatedProductAction, getRelatedProductByIdAction, getUserReviewsbyIdAction, productsizereset } from "../../state/actions/productsActions";
import isEmpty from "../../state/validations/is-empty";
import NoDataFound from "../../state/validations/NoDataFound";
import Header from "../header";
import Modal from "react-native-modal";
import SignIN from "../auth/signin";
import * as Network from 'expo-network';
import RenderHTML from "react-native-render-html";
import Readmore from "./readmore";
import Unorderedlist from 'react-native-unordered-list';
import { addCart } from "../../state/actions/cartActionsNew";
import ProductCard from "../../components/custom/product-card";
import EsiRating from "../../components/custom/Rating/rating";
import ESITextInput from "../../components/custom-fields/textInput";




export default function ProductDetailsNew(props) {
    const esiColor = useSelector(state => state.theme);
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
    const dimensions = Dimensions.get('window');
    const { height, width } = dimensions;
    const { navigation } = props;
    const dispatch = useDispatch();
    const Product_Id = props?.route?.params?.Product_Id;
    const Partner_Details_Id = props?.route?.params?.Partner_Details_Id;
    const masterId = props?.route?.params?.masterId;

    let productImage = useSelector((state) => state.allProducts.productImages.all);
    let productReviews = useSelector((state) => state.allProducts.productReviews.all);
    let partnerproduct = useSelector((state) => state.allProducts.partnerproducts.all);
    let productRelated = useSelector((state) => state.allProducts.productsRelated.all);
    let relatedproduct = useSelector((state) => state.allProducts.related.all);

    let colors = useSelector((state) => state.allProducts.colors.all);
    let sizes = useSelector((state) => state.allProducts.sizes.all);
    const [cartSize, setCartSize] = useState([]);
    const [cartLoading, setCartLoading] = useState(false);
    const [cartErrorMessage, setCartErrorMessage] = useState("");


    //For add review
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);

    //For edit review
    const [setEditModalVisible] = React.useState(false);
    const [isEditReviewModalVisible, setEditReviewModalVisible] = React.useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [PartnerDetails, setPartnerDetails] = useState();

    const toast = useToast();
    const [product, setProductData] = useState();

    useEffect(() => {
        onRefresh();
    }, [props.navigation, props.route, EmailID]);

    const callBackGetData = (data) => {
        setProductLoading(false);
        if (data) {
            setProductData(data.Product[0]);
        }
    };

    const getRelatedProducts = (relatedproduct) => {
        let Id = [];
        let multiID;
        if (relatedproduct) {
            let array = relatedproduct[0]?.Related_Products
            for (let i = 0; i < array?.length; i++) {
                Id.push(array[i].Related_Product_Of_Product_Id)
            }
        } else {
            Id = [];
        }
        if (Id) {
            multiID = Id.toString().replace(/\[.*\]/g, '')
        }
        if (multiID)
            dispatch(getRelatedProductAction(multiID))
    }

    const onRefresh = (isRefresh = false) => {
        if (Product_Id && (Product_Id != product?.Product_Id || isRefresh) && !productLoading) {
            setProductLoading(true);
            setProductData({});
            dispatch(getProductByIdAction(Product_Id, callBackGetData));
            dispatch(getProductsImageAction(Product_Id));
            dispatch(getUserReviewsbyIdAction(Product_Id));
            dispatch(getPartnerproductsbyIdAction(Product_Id));
            dispatch(getRelatedProductByIdAction(Product_Id));
            dispatch(productsizereset());
            setSize({ size: "", quantity: "" });
            setPartnerDetails();
        }

        if (relatedproduct) {
            getRelatedProducts(relatedproduct);
        }
        if (masterId) {
            dispatch(getProductColorsAction(masterId))
        }
        setWishList([]);
        setWishPartners([]);
        if (EmailID) {
            getWishList();
        }
    };

    const [wishPartners, setWishPartners] = useState([]);
    const [wish_list, setWishList] = useState([]);
    const getWishList = () => {
        let filter = {
            "Records_Filter": "FILTER",
            "User_Email_Id": EmailID,
            "Product_Id": Product_Id,
            "Route_Filter_Key": '3'
        }
        apiActions.get_wish_list(filter)
            .then(async res => {
                let wish_data = res.Wish[0]?.Wish_List;
                setWishList(wish_data);
                let wishPartnersT = [];
                if (!isEmpty(wish_data)) {
                    for (let i = 0; i < wish_data.length; i++) {
                        wishPartnersT.push(wish_data[i].Partner_Details_Id)
                    }
                    setWishPartners(wishPartnersT);
                }
            })
            .catch(err => {
            })
    }

    const backClick = () => {
        props.navigation.goBack();
    };

    const makeImageJson = (productImage) => {
        let image = [];
        if (productImage) {
            if (productImage[0]) {
                if (productImage[0].Product_Image) {
                    let data = productImage[0].Product_Image
                    for (var i = 0; i < data.length; i++) {
                        image.push({ imageuri: data[i].Product_Image })
                    }
                }
            }
        }
        return image
    }

    const handleColorclick = (Product_Id) => {
        props.navigation.navigate('ProductDetails', { masterId: masterId, Product_Id: Product_Id, Partner_Details_Id: Partner_Details_Id });
    };

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [openredirect, setRedirectionOpen] = React.useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
    const [commenterror, setCommentError] = useState("");
    const [visible, setVisible] = useState(2);
    const [reviewId, setData] = useState();


    const [error, setError] = useState("")
    const [imageURL, setImageURL] = useState("");

    const toggleModal = () => {
        if (isAuthenticated) {
            setReviewModalVisible(!isReviewModalVisible);
        }
        else {
            setModalVisible(true);
        }
    };

    const editToggleModal = () => {
        if (isAuthenticated) {
            setEditReviewModalVisible(!isEditReviewModalVisible);
        }
        else {
            setEditModalVisible(true);
        }

    };

    const responseFunction = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setReviewModalVisible(!isReviewModalVisible);
        setComment("");
        setRating("");
    }

    const responseFunctionUpdate = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setEditReviewModalVisible(!isEditReviewModalVisible);
        setComment("");
        setRating("");
    }

    const addReviewModal = () => {
        if (!isEmpty(comment) && rating > 0) {
            setCommentError("");
            let formData = {
                Product_Id: Product_Id,
                Rating: rating,
                Comments: comment,
                Email_Id: EmailID,
                Status: "Active"
            }
            let formValues = JSON.parse(JSON.stringify(formData));
            dispatch(addProductReviewAction(formValues, Product_Id, responseFunction));
        } else {
            if (isEmpty(comment)) {
                setCommentError("Review is required.")
            }
        }
    }

    const editReviewModal = () => {

        if (!isEmpty(comment) && rating > 0) {
            setCommentError("");
            let editData = {
                Product_Id: Product_Id,
                Product_Review_Id: reviewId,
                Rating: rating,
                Comments: comment,
                Email_Id: EmailID,
                Status: "Active"
            }
            let formValues = JSON.parse(JSON.stringify(editData));
            dispatch(editProductReviewAction(formValues, Product_Id, responseFunctionUpdate));

        } else {
            if (isEmpty(comment)) {
                setCommentError("Review is required.");
            }
        }
    }

    let existcomment;
    const handleOnChange = (value) => {
        if (existcomment > 0) {
            setError("You have already added review.")
        }
        if (value === "") {
            setError("")
        }
    }

    const editOnChange = (value) => {
        if (value === "") {
            setCommentError("Review is required.")
        } else {
            setCommentError("")
        }
    }

    const onCloseModal = () => {
        setSize({ size: "", quantity: "" })
        setModalIsOpen(false);
        setSelected(false)
        setPartnerDetails()
        setComment("")
        setRating("")
    };

    const onOpenModal = (data) => {
        let Url = data.Web_Redirection_Url;
        setTimeout(function () {
            Linking.openURL(Url);
            setRedirectionOpen(false);
        }, 2000);
    };

    const onLikeClicked = (is_like, partnerDetailId) => {
        if (!is_like) {
            if (EmailID) {
                var data = {
                    "User_Email_Id": EmailID,
                    "Product_Id": Product_Id,
                    "Partner_Details_Id": partnerDetailId
                }
                apiActions.create_wish_list(data)
                    .then(async res => {
                        getWishList();
                    })
                    .catch(err => {
                    })
            }
        } else {
            let wishId = "";
            wish_list.map((wish) => {
                if (wish.Partner_Details_Id == partnerDetailId) {
                    wishId = wish.Wish_Id
                }
            })
            if (wishId) {
                var data = {
                    Wish_Id: wishId
                }
                apiActions.delete_wish_list(data)
                    .then(async res => {
                        setWishPartners([]);
                        getWishList()
                    })
                    .catch(err => {
                    })
            }
        }
    }

    const [size, setSize] = useState({ size: "", quantity: "" });
    const [selected, setSelected] = useState(false);
    const handleSelect = (id) => {
        if (id === selected) {
            setSize("")
        }
        setSelected(id !== selected ? id : "")
    };
    const [cartAddedSuccess, setCartAddedSuccess] = useState(false);


    const sizeAddtoCart = (status, response, partnerData, message) => {
        setCartLoading(false);
        if (status && !isEmpty(response)) {
            if (response.length > 1) {
                setCartSize(response);
            } else {
                let item = { partner: partnerData, product: product, size: response[0]?.Product_Size, quantity: response[0].Product_Available_Stock };
                let payload = {
                    "Partner_Details_Id": partnerData.Partner_Details_Id,
                    "Partner_Product_Id": partnerData.Partner_Product_Id,
                    "Quantity": "1",
                    "Size": response[0]?.Product_Size,
                    "User_Email_Id": EmailID
                }

                dispatch(addCart(payload, EmailID, (data) => {
                    if (data?.Response_Status == "Failure") {
                        setCartErrorMessage(data?.UI_Display_Message);
                    } else {
                        setCartAddedSuccess(!cartAddedSuccess);
                    }
                }));
                dispatch(productsizereset());
                setPartnerDetails();
            }
        } else {
            setCartErrorMessage(message);
        }
    }

    const login = async (data) => {
        if (EmailID) {
            if (data.Partner_Type === "Online") {
                setRedirectionOpen(true)
                onOpenModal(data);
                setImageURL(data.Logo_Path)
            }
            else {
                setPartnerDetails(data);
                dispatch(getProductSizeActionWishList(Product_Id, data.Partner_Details_Id, data, sizeAddtoCart));
                setModalIsOpen(true);
                setCartLoading(true);
            }
            let iPAddress = await Network.getIpAddressAsync();
            let formData = {
                User_Email_Id: EmailID,
                Ip_Address: iPAddress,
                Offer_Type: "Product",
                Offer_Id: Product_Id,
                Store_Type: data.Partner_Type,
                Store_Id: data.Partner_Details_Id,
                Status: "Active"
            }
            dispatch(adduservisitAction(formData));
        } else {
            setModalVisible(!isModalVisible);
        }
    };
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const signInModalResponse = (status) => {
        setModalVisible(!isModalVisible);
    };

    const handleClick = async (item) => {
        props.navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id })
    };

    const cartData = () => {
        let item = { partner: PartnerDetails, product: product, size: size.size, quantity: size.quantity };
        setModalIsOpen(!modalIsOpen);
        setSelected(false);
        let payload = {
            "Partner_Details_Id": PartnerDetails.Partner_Details_Id,
            "Partner_Product_Id": PartnerDetails.Partner_Product_Id,
            "Quantity": "1",
            "Size": size.size,
            "User_Email_Id": EmailID
        }

        dispatch(addCart(payload, EmailID));

        dispatch(productsizereset());
        setSize({ size: "", quantity: "" });
        setPartnerDetails();
    }

    const styles = StyleSheet.create({
        pagingText: { color: '#888', margin: 3 },
        pagingActiveText: { color: '#fff', margin: 3 },

        root: {
            backgroundColor: "#ffffff",
            marginTop: 20

        },

        containerm: {
            paddingLeft: 19,
            paddingRight: 16,
            paddingVertical: 12,
            flexDirection: 'row',
            alignItems: 'flex-start',
            // borderColor: "#CACFD2",
            // borderWidth: 0.5,
            marginLeft: 1,
            width: (width > height) ? height : width,
            maxWidth: 400
        },

        content: {
            marginLeft: 16,
            flex: 1,
        },
        tooltip: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'red',
            maxHeight: 100,
            maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9),
            borderRadius: 13
        },
        contentHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            // marginBottom: 6,
        },

        separatorm: {
            height: 1,
            backgroundColor: "#CCCCCC",
        },
        image: {
            width: 45,
            height: 45,
            borderRadius: 20,
            marginTop: 10
        },

        name: {
            fontSize: 16,
            fontWeight: "bold",
            width: '50%',
            color: esiColor.itemColor
        },
        card: {
            // marginTop: 20,
            // width : 500,
            minHeight: 400,
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'flex-start' // if you want to fill rows left to right
        },
        similarcard: {
            // width : 500,
            // height:450,
            // flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            // alignSelf: 'flex-start' // if you want to fill rows left to right
        },
        pcard: {
            // flex: 1,
            // flexDirection: 'row',
            // flexWrap: 'wrap',
            // borderColor: 'black',

            // flexShrink: 1
        },

        productDetailsSize: {
            width: 50,
            height: 50,
            backgroundColor: esiColor.globalButtonColor,
            // cursor: pointer,
            borderRadius: 50,
            // marginLeft: 'auto',
            // marginRight: 'auto',
            // marginTop: 20,
            // marginBottom: 20,
            borderColor: esiColor.SBorderColor,
            borderWidth: 1,
            // position: 'relative',
            // alignItems: 'center',
            // justifyContent: 'center',
            color: esiColor.itemButtenColor,
            flexDirection: "column",
            marginHorizontal: 4

        },

        productDetailsSizeSelect: {
            width: 50,
            // marginTop: 20,
            // marginBottom: 20,
            // height: auto;
            height: 50,
            backgroundColor: esiColor.BackgroundColor,
            // cursor: pointer;
            borderRadius: 50,
            // marginLeft: 'auto',
            // marginRight: 'auto',
            borderColor: esiColor.SBorderColor,
            borderWidth: 1,
            // position: 'relative',
            // alignItems: 'center',
            // justifyContent: 'center',
            flexDirection: "column",
            marginHorizontal: 4

        },

        ribbon: {
            // padding: 15,
            // height: 20,
            backgroundColor: 'rgb(207, 152, 49)',
            color: '#dddeda',
            fontSize: 15,
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // width: '100%',
            // marginTop: '-0.1vw',
            // marginLeft: 4,
            // maxWidth: 100
            textAlign: "center"
        },

        productDetailsImgSelect: {
            backgroundColor: 'white',
            borderColor: '#c4c4da',
            borderWidth: 1,
            position: 'relative',
            textAlign: 'center',
            width: 50,
            height: 50,
            marginLeft: 15,
            maxHeight: 50,
            maxWidth: 50,
            borderRadius: 10,
            shadowRadius: 2,
            marginBottom: 5
        },

        productDetailsImgSelect_color: {
            backgroundColor: 'white',
            borderStyle: "solid",
            borderColor: 'blue',
            borderWidth: 1,
            position: 'relative',
            textAlign: 'center',
            width: 50,
            height: 50,
            marginLeft: 15,
            maxHeight: 50,
            maxWidth: 50,
            borderRadius: 10,
            shadowRadius: 2,
            marginBottom: 5
        }

    });


    const pPCard = useMemo(() => {
        return <FlatList
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            data={partnerproduct}
            renderItem={({ item, index, separators }) => (
                <PartnerProductCard data={item} onLikeClicked={onLikeClicked} wishPartners={wishPartners} sizes={sizes} PartnerDetails={PartnerDetails} login={login}
                    cartAddedSuccess={cartAddedSuccess}
                />
            )}
        />
    }, [partnerproduct, wish_list, getWishList, cartAddedSuccess])

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View>
                {navigation.canGoBack() &&
                    <IconButton
                        icon="arrow-left"
                        size={27}
                        color={"#27B6CC"}
                        style={{ marginTop: -2, marginLeft: 1 }}
                        onPress={() => backClick()}
                    />}
            </View>
            {!productLoading && product ?
                <ScrollView style={{ maxHeight: dimensions.height - 20 }}
                    refreshControl={
                        <RefreshControl
                            refreshing={productLoading}
                            onRefresh={() => onRefresh(true)}
                        />
                    }
                >
                    {productLoading ? <ActivityIndicator /> :

                        <View style={{
                            flex: 1
                        }}>
                            <View style={{ flexDirection: 'column' }}>
                                <View>
                                    <ImageCarousel image={makeImageJson(productImage)} dimensions={dimensions} />
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <Text style={{ color: esiColor.DescColor, textAlign: 'center', fontWeight: 'bold', marginTop: 10 }}>Brand: {product?.Brand_Name}</Text>
                                    <EsiRating
                                        readonly={true}
                                        type='heart'
                                        startingValue={product?.Esi_Rating}
                                        ratingCount={5}
                                        imageSize={20}
                                        tintColor={esiColor.TintColor}
                                        style={{ paddingVertical: 10 }}
                                    />
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>

                                    {(colors.Is_data_Exist !== "0" && colors?.length > 1) &&
                                        <FlatList
                                            horizontal={true}
                                            showsHorizontalScrollIndicator={false}
                                            data={colors}
                                            renderItem={({ item, index, separators }) => (
                                                <TouchableOpacity onPress={() => handleColorclick(item.Product_Id)} >
                                                    <Card
                                                        style={item.Product_Id === Product_Id ? styles.productDetailsImgSelect_color : styles.productDetailsImgSelect}>

                                                        <Image source={{ cache: "force-cache", uri: item?.Color_Variant.Color_Variant[0].Product_Image }} style={{ height: 48, width: 48, borderRadius: 8, shadowRadius: 2 }} />
                                                    </Card>
                                                </TouchableOpacity>
                                            )}
                                        />

                                    }
                                </View>
                                <View style={{ flexDirection: "column" }}>
                                    <View>
                                        <View style={{ alignItems: 'center', paddingRight: 5 }}>
                                            <Text style={{ textAlign: 'center', opacity: 0.8, color: esiColor.brandFontColor, fontWeight: 'bold' }}>{product && product?.Product_Name}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>

                                        <View>
                                            <Text style={{ textAlign: 'center', fontWeight: 'bold', opacity: 0.8, color: esiColor.itemColor, fontSize: 16 }}>₹{parseFloat(product?.Partner_Selling_Price)}</Text>
                                        </View>
                                        <View style={{ marginLeft: 5, marginTop: 1 }} >
                                            {/*Toottip*/}
                                            <Tooltip backgroundColor={esiColor.globalButtonColor} width={350} height={80}
                                                popover={<Text style={{ color: esiColor.itemButtenColor }}>The prices shown in the product details page may change in the Supplier website based on the Seller price.</Text>}>
                                                <Entypo name="info-with-circle" size={16} color={esiColor.esiMainColor} />
                                            </Tooltip>
                                        </View>
                                        <View style={{ paddingLeft: 10 }}>
                                            <Text style={{ textAlign: 'center', textDecorationLine: 'line-through', opacity: 0.8, height: 25, fontSize: 16, fontWeight: 'bold', color: esiColor.itemColor }}>₹{product?.Original_Price}</Text>
                                        </View>
                                    </View>
                                    {/* Partner Cards */}
                                    <View>
                                        <Text style={{ color: esiColor.itemColor, fontWeight: 'bold', marginLeft: 10, marginBottom: 5 }}> Grab this exciting offer from MeePaisa preferred partners</Text>
                                    </View>
                                    {pPCard}

                                    {product?.Product_Description &&
                                        <Readmore label={"Description"} value={product?.Product_Description} />
                                    }
                                    {product?.Product_Description &&
                                        <Readmore label={"Features"} value={product?.Features} />
                                    }
                                    {product?.Product_Description &&
                                        <Readmore label={"Specifications"} value={product?.Specifications} />
                                    }
                                    {
                                        !(partnerproduct?.filter((partner) => partner?.Partner_Product_Warranty_Period ? true : false).length <= 0 && !(product?.Product_Warranty_Period)) && <View style={{ marginLeft: 10, borderBottomWidth: 1, borderTopWidth: 1, borderColor: esiColor.SBorderColor }} >
                                            <Text style={{ fontWeight: "bold", fontSize: 16, color: esiColor.DescColor, }} >Warranty </Text>
                                            {product?.Product_Warranty_Period &&
                                                <Unorderedlist style={{ fontSize: 22, marginTop: -5, marginLeft: 20 }} ><Text style={{ color: esiColor.itemColor }}>{product?.Product_Warranty_Period}</Text></Unorderedlist>
                                            }

                                            {partnerproduct?.length > 0 &&
                                                partnerproduct.filter((partner) => partner?.Partner_Product_Warranty_Period ? true : false).map((partner, index) => {
                                                    return (
                                                        <Unorderedlist style={{ fontSize: 22, marginTop: -5, marginLeft: 20 }} key={"warranty" + index} ><Text style={{ color: esiColor.itemColor }}>{partner?.Partner_Name} : {partner?.Partner_Product_Warranty_Period}</Text></Unorderedlist>
                                                    )
                                                })
                                            }
                                        </View>
                                    }

                                    {/* Product Reviews */}
                                    <View style={{ flexDirection: 'row', width: (width > height) ? height : width }}>
                                        <View style={{ flexDirection: 'column', flex: 2, alignSelf: 'flex-start', marginLeft: 15, marginTop: 15 }}>
                                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.DescColor }}>Product Reviews </Text>
                                        </View>
                                        {Boolean(productReviews?.Product_Review?.filter((review) => {
                                            return review.Email_Id === EmailID
                                        }).length) <= 0 ? <View style={{ flexDirection: 'column', flex: 1, alignSelf: 'flex-end', margin: 10 }}>
                                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, width: 110, height: 30, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }} onPress={() => toggleModal()}>
                                                <Text style={{ color: esiColor.itemButtenColor }}>ADD REVIEW</Text>
                                            </TouchableOpacity>
                                        </View> : <View style={{ flexDirection: 'column', flex: 1, alignSelf: 'flex-end', margin: 10 }}>
                                            <TouchableOpacity disabled style={{ backgroundColor: 'gray', width: 110, height: 30, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }} onPress={() => toggleModal()}>
                                                <Text style={{ color: esiColor.itemButtenColor }}>ADD REVIEW</Text>
                                            </TouchableOpacity>
                                        </View>
                                        }
                                    </View>

                                    <View style={styles.containerm}>
                                        <Image style={styles.image} source={require("../../assets/images/img/logo/meePaisa_logo.png")} />
                                        <View style={styles.content}>
                                            <View style={styles.contentHeader}>
                                                <Text style={styles.name}>meepaisa Review</Text>
                                                <View style={{ marginTop: -8 }}>
                                                    <EsiRating
                                                        rating={product.Esi_Rating ? (parseInt(product.Esi_Rating)) : (0)}
                                                        numberOfStars={5}
                                                        ratingCount={5}
                                                        imageSize={20}
                                                        style={{ paddingVertical: 10 }}
                                                        startingValue={product?.Esi_Rating}
                                                        readonly={true}
                                                        type='heart'
                                                        // ratingBackgroundColor='#c8c7c8'
                                                        tintColor={esiColor.TintColor}
                                                        onFinishRating={(rating) => ratingCompleted(rating)} />
                                                </View>
                                            </View>
                                            <View style={{ backgroundColor: esiColor.RTBC, flexWrap: "wrap", marginTop: -10, borderRadius: 8 }}>
                                                {product?.Esi_Reviews ? <View style={{ flexWrap: "wrap", padding: 5, backgroundColor: esiColor.RTBC, borderRadius: 8 }} >
                                                    <RenderHTML source={{ html: product?.Esi_Reviews }} /></View> : <></>}
                                            </View>
                                        </View>
                                    </View >

                                    <View>
                                        {
                                            productReviews?.Is_Data_Exist === "0" || isEmpty(productReviews.Product_Review) ? (
                                                <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                                                    <Text style={{ color: esiColor.NoDataFound }}>No User Reviews found!</Text>
                                                </View>
                                            ) : <>
                                                {productReviews.Product_Review.slice(0, visible).map((data, index) => (
                                                    <View key={index}>
                                                        <View />
                                                        <View style={styles.containerm}>
                                                            <TouchableOpacity>
                                                                {data?.Profile_Pic_Path ?
                                                                    <Image style={styles.image} source={{ cache: "force-cache", uri: data.Profile_Pic_Path }} /> :
                                                                    <Image style={styles.image} source={require("../../assets/images/img/logo/meePaisa_logo.png")} />}

                                                            </TouchableOpacity>
                                                            <View style={styles.content}>
                                                                <View style={styles.contentHeader}>
                                                                    <Text style={styles.name}>{data.Full_Name}
                                                                    </Text>
                                                                    <View style={{ marginTop: -10 }}>
                                                                        {EmailID && EmailID === data?.Email_Id ? (
                                                                            <IconButton icon="pencil" size={20} onPress={() => { editToggleModal(); setRating(parseInt(data.Rating)); setComment(data.Comments); setData(data.Product_Review_Id); }} />
                                                                        ) : (null)}
                                                                    </View>
                                                                    <View style={{ marginTop: -8 }}>
                                                                        <EsiRating
                                                                            readonly={true}
                                                                            type='heart'
                                                                            // ratingBackgroundColor='#c8c7c8'
                                                                            tintColor={esiColor.TintColor}
                                                                            startingValue={data?.Rating}
                                                                            ratingCount={5}
                                                                            imageSize={20}
                                                                            onFinishRating={(rating) => ratingCompleted(rating)}
                                                                            style={{ paddingVertical: 10 }}
                                                                        />

                                                                    </View>
                                                                </View>
                                                                <View style={{ flexWrap: "wrap" }}>
                                                                    <Text style={{ color: esiColor.Text }} >{data?.Comments}</Text>
                                                                </View>
                                                            </View>
                                                        </View>

                                                    </View>
                                                ))}
                                                <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                                                    {productReviews?.Product_Review.length > visible &&
                                                        <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: '#27b6cc' }} onPress={() => setVisible(visible + 3)}>Show More ▼</Text>
                                                    }
                                                </View>
                                            </>
                                        }
                                    </View>
                                    {/* Similar Products */}

                                    <View style={{}}>

                                        <View style={{ paddingHorizontal: 15 }} >

                                            {!isEmpty(productRelated) && (
                                                productRelated.Is_Data_Exist === "0" ? (
                                                    <NoDataFound text={productRelated.UI_Display_Message} />
                                                ) : typeof productRelated === "object" ? (<View>
                                                    <Text style={{ color: 'black', fontSize: 17, fontWeight: 'bold' }}>Similar/Related Products</Text>
                                                    <ScrollView
                                                        contentContainerStyle={{ alignContent: "stretch" }}
                                                        horizontal
                                                        showsHorizontalScrollIndicator={false}
                                                    >

                                                        {productRelated.map((data, index) => (
                                                            <View key={index}>
                                                                <ProductCard data={data} navigate={navigation.navigate} handleClick={handleClick} ProductId={data?.Product_Id} PartnerId={data?.Partner_Details_Id} />
                                                            </View>
                                                        ))}
                                                    </ScrollView>
                                                </View>
                                                ) : (
                                                    typeof relatedproduct
                                                )
                                            )}

                                        </View>
                                    </View>

                                </View>
                            </View>
                        </View>}
                </ScrollView>
                :
                <ActivityIndicator color='#27b6cc' />}

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>

            {/* For Add Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 325, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <View style={{
                        alignItems: 'flex-end', marginTop: '-6%'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setReviewModalVisible(false), setComment(''), setRating(0) }}>
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>

                            <ESITextInput
                                label='Add Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); handleOnChange(Text); setCommentError("") }}
                                multiline={true}
                                numberOfLines={4}
                                style={{ maxHeight: 100, minWidth: 250, maxWidth: 250, marginHorizontal: "auto" }}
                                value={comment}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                        <Text style={{ color: 'red', textAlign: "center" }}>{error}</Text>

                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <EsiRating
                                type='heart'
                                tintColor={esiColor.TintColor}
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value); }}
                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red" }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }
                            <View>
                                <Button color={esiColor.globalButtonColor} style={{ alignItems: 'center', marginTop: 10 }} mode="contained" onPress={() => addReviewModal()}>
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </Button>
                            </View>
                        </View>

                    </View>

                </View>
            </Modal>

            {/* For Edit Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isEditReviewModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 325, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>

                    <View style={{
                        alignItems: 'flex-end', marginTop: '-6%'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setEditReviewModalVisible(false), setComment(''), setRating(0) }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>

                            <ESITextInput
                                label='Edit Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); editOnChange(Text); setCommentError("") }}
                                multiline={true}
                                numberOfLines={4}
                                style={{ maxHeight: 100, minWidth: 250, maxWidth: 250, marginHorizontal: "auto" }}
                                value={comment}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <EsiRating
                                type='heart'
                                tintColor={esiColor.TintColor}
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value); }}

                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red", fontSize: 16 }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }

                            <View >
                                <Button color={esiColor.globalButtonColor} style={{ alignItems: 'center', marginTop: 10 }} mode="contained" onPress={() => editReviewModal()}>
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </Button>
                            </View>
                        </View>

                    </View>

                </View>
            </Modal>

            {/* Partner Redirection Modal */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openredirect}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 150, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <View style={{ alignItems: 'flex-end', marginTop: '-6%' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ color: "#27b6cc", textAlign: 'center', marginBottom: 10 }}>Redirecting to partner site. Please wait....</Text>
                    </View>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />
                            <Button color="#27b6cc" icon="arrow-right-bold" style={{ size: 15, marginTop: 10 }} />
                            {imageURL ? <Image source={{ cache: "force-cache", uri: imageURL }} style={{ height: 60, width: 60, resizeMode: 'contain' }} /> : <></>}
                        </View>
                    </View>
                </View>


            </Modal>

            {/* Size Selection Modal */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={modalIsOpen}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 100, minHeight: 100, maxHeight: 200, height: 200, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>

                    <View style={{
                        alignItems: 'flex-end', marginTop: -30
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { onCloseModal(); setCartErrorMessage(""); }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    {
                        !isEmpty(cartSize) ?
                            <View>
                                <Text style={{ textAlign: "center", marginTop: -10, marginBottom: 10, fontSize: 16, fontWeight: "bold", color: esiColor.DescColor }}>
                                    Select Size
                                </Text>

                                <View style={{ textAlign: "center", justifyContent: "center", flexDirection: 'row', }}>
                                    <FlatList
                                        horizontal={true}
                                        showsHorizontalScrollIndicator={false}
                                        data={cartSize}
                                        contentContainerStyle={{ justifyContent: "space-around" }}
                                        renderItem={({ item, index, separators }) => (
                                            <View key={index}>
                                                <TouchableOpacity
                                                    style={selected === item.Product_Size ? styles.productDetailsSize : styles.productDetailsSizeSelect}
                                                    key={index}
                                                    onPress={() => { setSize({ size: item.Product_Size, quantity: item.Product_Available_Stock }); handleSelect(item.Product_Size) }}
                                                >
                                                    <View ><Text icon="format-size" style={{ textAlign: "center", justifyContent: "center", marginTop: 16, color: esiColor.DescColor }} >{item.Product_Size}</Text>
                                                    </View>

                                                </TouchableOpacity>
                                                {
                                                    item.Product_Available_Stock ?
                                                        <Text >
                                                            {parseInt(item.Product_Available_Stock) < 11 ?
                                                                <Text style={styles.ribbon}>{item.Product_Available_Stock} Left</Text>
                                                                : null}
                                                        </Text> : null
                                                }
                                            </View>
                                        )}
                                    />
                                </View>

                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 10 }} >
                                    <Button
                                        color={size.size && size.quantity ? esiColor.globalButtonColor : esiColor.brandShadowColor}
                                        type="submit"
                                        mode="contained"
                                        disabled={size.size && size.quantity ? false : true}
                                        style={{ color: size.size && size.quantity ? esiColor.itemButtenColor : esiColor.itemButtenColor, width: "50%" }}
                                        onPress={cartData}
                                    >
                                        Proceed
                                    </Button>
                                </View>
                            </View>
                            :
                            cartLoading ? <ActivityIndicator color='#27b6cc' /> :
                                <View style={{ flexDirection: 'row', justifyContent: 'center', textAlign: "center" }}>
                                    {cartErrorMessage ? <Text style={{ color: "red" }} >{cartErrorMessage}</Text> :
                                        <View style={{ flexDirection: "column", alignItems: "center" }} >
                                            <Text style={{ fontSize: 16, fontWeight: "bold", color: esiColor.DescColor }} >Item added to the cart.</Text>
                                            <IconButton icon={"cart-check"} color={"#27b6cc"} size={60} />
                                            <TouchableOpacity onPress={() => {
                                                onCloseModal(); setCartErrorMessage("");
                                            }}>
                                                <Button
                                                    mode="contained"
                                                    color={esiColor.globalButtonColor}
                                                    labelStyle={{ color: esiColor.itemButtenColor }}
                                                    type="submit"
                                                    // style={{ : "#fff" }}
                                                    onPress={() => {
                                                        onCloseModal(); setCartErrorMessage("");
                                                    }}
                                                >
                                                    Done
                                                </Button>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                    }
                </View>
            </Modal >
        </Surface>
    )
}

