import 'react-native-gesture-handler';
import React, { useEffect, useState } from "react";
import { getWalletHistory } from '../../state/actions/walletActions';
import { View, StyleSheet, ScrollView, Dimensions, TouchableOpacity, RefreshControl } from 'react-native';
import { Provider, DataTable, Button, Text, Surface, ActivityIndicator, IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { useToast } from '../../self_modules/react-native-paper-toast/src';


function WalletHistory(props) {
  const { navigation } = props;
  const wallets = useSelector(state => state.wallet.walletHistory.wallet);
  const toast = useToast();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const esiColor = useSelector(state => state.theme);
  const loading = useSelector(state => state.wallet.walletHistory.isLoading);
  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

  // Pagination
  let currentItems;
  let length;
  const pages = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxpageNumberLimit, setmaxpageNumberLimit] = useState(5);
  const [minpageNumberLimit, setminpageNumberLimit] = useState(0);
  const handleClick = (event) => {
    setCurrentPage(Number(event))
  }


  const handleprev = () => {
    setCurrentPage(currentPage - 1)
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxpageNumberLimit(maxpageNumberLimit - pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit - pageNumberLimit);
    }
  }
  const handlenext = () => {
    setCurrentPage(currentPage + 1)
    if (currentPage + 1 > maxpageNumberLimit) {
      setmaxpageNumberLimit(maxpageNumberLimit + pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit + pageNumberLimit);
    }
  }
  if (wallets) {
    length = wallets.length
    for (let i = 1; i <= Math.ceil(wallets.length / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = wallets.length > 17 ? currentPage * itemsPerPage : itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    if (wallets) {
      currentItems = wallets[0] ? wallets.slice(indexOfFirstItem, indexOfLastItem) : 0
    }
  }
  let pageIncrementbtn = null;
  if (pages.length > maxpageNumberLimit) {
    pageIncrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: esiColor.SBorderColor, borderRadius: 5, height: 40, justifyContent: 'center',
          alignItems: 'center', shadowColor: esiColor.brandShadowColor
        }} onPress={handlenext}>
          <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
        </TouchableOpacity>
      </View>
  }
  let pageDecrementbtn =
    null;
  if (minpageNumberLimit >= 1) {
    pageDecrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: esiColor.SBorderColor, borderRadius: 5, height: 40, justifyContent: 'center',
          alignItems: 'center', shadowColor: esiColor.brandShadowColor
        }} onPress={handleprev}>
          <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
        </TouchableOpacity>
      </View>

  }
  const renderPageNumber = pages.map((number) => {
    if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
      return (
        <View key={number} style={{ alignItems: 'center', margin: "0.5%" }}>
          <TouchableOpacity style={{
            backgroundColor: currentPage === number ? esiColor.globalButtonColor : null,
            width: 30, borderWidth: 1, borderColor: esiColor.SBorderColor, borderRadius: 5, height: 40, justifyContent: 'center',
            alignItems: 'center', shadowColor: esiColor.brandShadowColor
          }} onPress={() => { handleClick(number) }}>
            <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>{number}</Text>
          </TouchableOpacity>
        </View>
      )
    }
    else {
      return null;
    }

  })
  const EmailID = useSelector(state => state.auth.user.Email_Id);
  //API dispatch
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isEmpty(EmailID)) {
      let formData = {
        "User_Id": EmailID,
      };
      dispatch(getWalletHistory(formData))
    } else {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }
  }, [EmailID]);

  const onRefresh = () => {
    let formData = {
      "User_Id": EmailID,
    };
    dispatch(getWalletHistory(formData))
  };

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.CBColor }}>
      <Header {...props} />
      <Provider>
        <View style={{ flexDirection: 'row', margin: 2 }}>
          {navigation.canGoBack() &&
            <IconButton
              icon="arrow-left"
              size={30}
              color={"#27B6CC"}
              style={{ marginTop: -4 }}
              onPress={() => {
                navigation.goBack();
              }} />}
          <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor, marginTop: 5 }}>
            TRANSACTIONS
          </Text>
        </View>
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={onRefresh}
            />
          }
        >
          <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginTop: -10 }}>
            <View style={{ flex: 1, margin: 15 }}>
              <ScrollView horizontal={true}>

                <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                  <DataTable.Header style={{ borderBottomColor: '#bdc3c7' }}>
                    <DataTable.Title style={[style.title]}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Withdraw Reason</Text></DataTable.Title>
                    <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Amount</Text></DataTable.Title>
                    <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Transaction Type</Text></DataTable.Title>
                    <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Status</Text></DataTable.Title>
                  </DataTable.Header>
                  {!isEmpty(wallets) ? (
                    currentItems == 0 ? <Text style={{ fontSize: 16, textAlign: 'center', color: esiColor.NoDataFound }}>
                      No records found.
                    </Text> : (
                      currentItems.map((wallet: any) => (
                        <DataTable.Row style={[style.databeBox, { borderBottomColor: '#bdc3c7' }]} key={wallet.Wallet_History_Id}>
                          <DataTable.Cell textStyle={{ color: esiColor.itemColor }} style={[style.tablerow, { justifyContent: "flex-start", textAlign: "center" }]}>{wallet.Transaction_Type === "DB" ? wallet.Debit_Reason : wallet.Credit_Reason}</DataTable.Cell>
                          <DataTable.Cell textStyle={{ color: esiColor.itemColor }} style={style.tablerow}>{wallet.Transaction_Type === "DB" ? wallet.Debited_Amount : wallet.Credited_Amount}{'/-'}</DataTable.Cell>
                          <DataTable.Cell textStyle={{ color: esiColor.itemColor }} style={style.tablerow}>{wallet.Transaction_Type}</DataTable.Cell>
                          <DataTable.Cell textStyle={{ color: esiColor.itemColor }} style={style.tablerow}><Text style={{ color: wallet.Status === "Pending" ? "#27b6cc" : "green", }}>{wallet.Status}</Text></DataTable.Cell>
                        </DataTable.Row>
                      ))
                    )) : <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 1 }} />}
                </DataTable>
              </ScrollView>
            </View>
          </View>
          <View>
            {length > 16 ? (
              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity disabled={currentPage === pages[0] ? true : false} onPress={handleprev}
                  style={{
                    width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                    // backgroundColor: currentPage === pages[0] === true ? 'grey' : 'white',
                    flexDirection: 'column', pointerEvents: currentPage === pages[0] ? "none" : null,
                    borderRadius: 5, borderColor: esiColor.SBorderColor, borderWidth: 1, shadowColor: esiColor.brandShadowColor, opacity: currentPage === pages[0] ? "0.6" : null
                  }}>
                  <Text style={{ color: esiColor.itemColor }}>Prev</Text>
                </TouchableOpacity>
                {pageDecrementbtn}
                {renderPageNumber}
                {pageIncrementbtn}
                <TouchableOpacity disabled={currentPage === pages[pages.length - 1] ? true : false} onPress={handlenext}
                  style={{
                    width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                    // backgroundColor: currentPage === pages[pages.length - 1] === true ? 'grey' : 'white',
                    flexDirection: 'column', borderRadius: 5, borderColor: esiColor.SBorderColor, borderWidth: 1, shadowColor: esiColor.brandShadowColor,
                    pointerEvents: currentPage === pages[pages.length - 1] ? "none" : null,
                    opacity: currentPage === pages[pages.length - 1] ? "0.6" : null
                  }}>
                  <Text style={{ color: esiColor.itemColor }}>Next</Text>
                </TouchableOpacity>
              </View>
            ) : (null)}
          </View>
        </ScrollView>
      </Provider>
    </Surface>
  );
}
const style = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontWeight: "bold",
    fontSize: 12,
    justifyContent: "center",
    textAlign: "center",
    width: 80
  },
  titletext: {
    fontWeight: "bold",
    fontSize: 14
  },
  tablerow: {
    justifyContent: "center",
    textAlign: "center",
    width: 140
  },
  mainbox: {
    textAlign: 'center',
    margin: 15,
    flex: 1,
  },
  databeBox: {
    margin: 10,
    textAlign: 'center',
  },
  databeHeader: {
    margin: 10,
    textAlign: 'center',
  },
});

export default WalletHistory