import 'react-native-gesture-handler';
import React, { useEffect, useState } from "react";
import { getRefferals } from "../../state/actions/referActions";
import { View, StyleSheet, ScrollView, Dimensions, Platform, TouchableOpacity, RefreshControl } from 'react-native';
import { Provider, Card, DataTable, Button, Text, Surface, Modal, ActivityIndicator, Divider } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { useToast } from '../../self_modules/react-native-paper-toast/src';

function Earn(props) {
  const { navigation } = props;
  const refers = useSelector(state => state.refer.refers.all);
  const loading = useSelector(state => state.refer.refers.isLoading);
  const toast = useToast();
  const EmailID = useSelector(state => state.auth.user.Email_Id)
  const esiColor = useSelector(state => state.theme);
  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);


  //API dispatch
  const dispatch = useDispatch()
  useEffect(() => {
    setCurrentPage(1);
    if (EmailID) {
      dispatch(getRefferals(EmailID));
    } else {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }
  }, [props.navigation, props.route, EmailID]);


  // Pagination
  let currentItems;
  let length;
  const pages = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxpageNumberLimit, setmaxpageNumberLimit] = useState(5);
  const [minpageNumberLimit, setminpageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setCurrentPage(Number(event))
  }

  const handleprev = () => {
    setCurrentPage(currentPage - 1)
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxpageNumberLimit(maxpageNumberLimit - pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit - pageNumberLimit);
    }
  }

  const handlenext = () => {
    setCurrentPage(currentPage + 1)
    if (currentPage + 1 > maxpageNumberLimit) {
      setmaxpageNumberLimit(maxpageNumberLimit + pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit + pageNumberLimit);
    }
  }
  if (refers) {
    length = refers.length
    for (let i = 1; i <= Math.ceil(refers.length / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = refers.length > 17 ? currentPage * itemsPerPage : itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    currentItems = refers?.length ? refers.slice(indexOfFirstItem, indexOfLastItem) : 0
  }


  const retrunPagenationRefers = (refers, currentPage, itemsPerPage) => {
    if (refers) {
      return refers.slice((currentPage * itemsPerPage) - itemsPerPage, currentPage * itemsPerPage)
    }
    return []
  }



  let pageIncrementbtn = null;
  if (pages.length > maxpageNumberLimit) {
    pageIncrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: 'black', borderRadius: 5, height: 40, justifyContent: 'center',
          alignItems: 'center'
        }} onPress={handlenext}>
          <Text style={{ fontWeight: 'bold' }}>....</Text>
        </TouchableOpacity>
      </View>
  }
  let pageDecrementbtn =
    null;
  if (minpageNumberLimit >= 1) {
    pageDecrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: 'black', borderRadius: 5, height: 40, justifyContent: 'center',
          alignItems: 'center'
        }} onPress={handleprev}>
          <Text style={{ fontWeight: 'bold' }}>....</Text>
        </TouchableOpacity>
      </View>

  }
  const renderPageNumber = pages.map((number) => {
    if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
      return (
        <View key={number} style={{ alignItems: 'center', margin: "0.5%" }}>
          <TouchableOpacity style={{
            backgroundColor: currentPage === number ? "#27b6cc" : null,
            width: 30, borderWidth: 1, borderColor: 'black', borderRadius: 5, height: 40, justifyContent: 'center',
            alignItems: 'center'
          }} onPress={() => { handleClick(number) }}>
            <Text style={{ fontWeight: 'bold' }}>{number}</Text>
          </TouchableOpacity>
        </View>
      )
    }
    else {
      return null;
    }

  })


  const onRefresh = () => {
    setCurrentPage(1);
    if (EmailID) {
      dispatch(getRefferals(EmailID));
    } else {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }
  };
  const style = StyleSheet.create({
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      minWidth: 100,
      color: esiColor.DescColor
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      minWidth: 100,
      color: esiColor.itemColor
    },
    databeBox: {
      textAlign: 'center',
    },
  });
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <ScrollView

        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={onRefresh}
          />
        }
      >
        <View style={{ flexDirection: "row", justifyContent: 'space-between', marginHorizontal: 10, marginVertical: 6 }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", color: esiColor.brandFontColor }}>Refer & Earn</Text>
          <View>
            <Button color={esiColor.globalButtonColor} mode="contained" onPress={() => {
              navigation.navigate('refer', { go_back_key: navigation.getState().key })
            }}>
              <Text style={{ color: esiColor.itemButtenColor }}>INVITE</Text>
            </Button>
          </View>
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, }}>
          <View style={{ textAlign: 'center', flex: 1, margin: 10, }}>
            {/* <Card> */}
            <ScrollView horizontal={true}>
              <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7', minWidth: 400 }}>
                <DataTable.Header>
                  <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Name</Text></DataTable.Title>
                  <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Amount</Text></DataTable.Title>
                  <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Sign-Up Status</Text></DataTable.Title>
                  <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Payment Status</Text></DataTable.Title>
                </DataTable.Header>
                <Divider style={{ borderWidth: 0.5, borderColor: esiColor.SBorderColor }} />
                {!isEmpty(refers) ? (
                  refers.Is_Data_Exist === "0" ? <Text style={{ fontSize: 16, textAlign: 'center', color: esiColor.NoDataFound }}>
                    No records found.
                  </Text> : (
                    retrunPagenationRefers(refers, currentPage, itemsPerPage).map((refer: any, index: any) => (
                      <DataTable.Row style={style.databeBox} key={refer.Reference_Id + index}>
                        <DataTable.Cell style={style.tablerow} textStyle={{ color: esiColor.itemColor }}>{refer.Name}</DataTable.Cell>
                        <DataTable.Cell style={style.tablerow} textStyle={{ color: esiColor.itemColor }}>{refer.Amount}{(refer.Amount === 'A' || refer.Amount === 'Amount' || refer.Amount) ? '/-' : '0'}</DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: refer.Sign_up_Status != "Registered" ? "red" : "green" }}>{refer.Sign_up_Status}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: refer.Payment_Status != "Settled" ? "red" : "green" }}>{refer.Payment_Status}</Text></DataTable.Cell>
                      </DataTable.Row>
                    ))
                  )) : <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 1 }} />}
              </DataTable>
            </ScrollView>
          </View>
        </View>
        <View>
          {length > itemsPerPage ? (
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity disabled={currentPage === pages[0] ? true : false} onPress={handleprev}
                style={{
                  width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                  backgroundColor: currentPage === pages[0] === true ? 'grey' : 'white',
                  flexDirection: 'column', pointerEvents: currentPage === pages[0] ? "none" : null,
                  borderRadius: 5, borderColor: 'black', borderWidth: 1, opacity: currentPage === pages[0] ? "0.6" : null
                }}>
                <Text style={{ color: esiColor.itemColor }}>Prev</Text>
              </TouchableOpacity>
              {pageDecrementbtn}
              {renderPageNumber}
              {pageIncrementbtn}
              <TouchableOpacity disabled={currentPage === pages[pages.length - 1] ? true : false} onPress={handlenext}
                style={{
                  width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                  backgroundColor: currentPage === pages[pages.length - 1] === true ? 'grey' : 'white',
                  flexDirection: 'column', borderRadius: 5, borderColor: 'black', borderWidth: 1,
                  pointerEvents: currentPage === pages[pages.length - 1] ? "none" : null,
                  opacity: currentPage === pages[pages.length - 1] ? "0.6" : null
                }}>
                <Text style={{ color: esiColor.itemColor }}>Next</Text>
              </TouchableOpacity>
            </View>
          ) : (null)}
        </View>
      </ScrollView>
    </Surface>
  );
}


export default Earn