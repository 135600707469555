import React, { Fragment, useState, useEffect, useCallback } from "react";
import CartProductCard from "./CartProductCard";
import { useDispatch, useSelector } from "react-redux";
import { removeItemInCart, addItemMultipleCart, partnerAddOrRemoveAddress, paceOrderValidation, productAddOrRemoveAddress, productAddOrRemoveAddressPartnerPage } from "../../state/actions/cartActionsNew";

import { ScrollView, StyleSheet, Text, TouchableOpacity, View, Dimensions } from "react-native";
import CartAccordionListItem from "../../components/custom/accordion/cart-accordion";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import { Avatar, Button, Colors, HelperText, TouchableRipple } from "react-native-paper";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import Modal from "react-native-modal";

export default function PartnerCart({ cart, item, address, setOpenAddressPopup, pindex, expanded, handleChange, wallet, navigation, partnerDeliveryCharges, fgPartnerOrderRequiredAmount }) {
  const dispatch = useDispatch();
  const email = useSelector(state => state.auth.user.Email_Id);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

  // useEffect(() => {
  //   const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(), 1000)
  //   return () => clearTimeout(timer)
  // }, [seconds]);
  // inventory Error
  const [productError, setProductError] = useState([]);
  const esiColor = useSelector(state => state.theme);
  const [isConfirmScreen, setIsConfirmScreen] = useState(false);
  const [isConfirmScreenProduct, setIsConfirmScreenProduct] = useState({});

  const handleOnClickRemoveCard = (e, data) => {
    setIsConfirmScreenProduct(data);
    setIsConfirmScreen(true);
  };

  const deleteConfirmCheck = (status) => {
    dispatch(removeItemInCart({ data: isConfirmScreenProduct, Move_Wish_List: status }));
    setIsConfirmScreen(false);
  };


  const handleOnChangeCount = (e: any, data: any, type: any) => {
    let count;
    if (type) {
      count =
        e !== "" ? parseInt(e) : "";
    } else {
      count = e !== "" ? parseInt(e) : 1;
      count = count > 0 ? count : 1;
    }
    dispatch(addItemMultipleCart(data, Number(count)));
  };

  const handleOnClickCountIncrement = (count: any, data: any) => {
    dispatch(addItemMultipleCart(data, Number(count) + 1));
  };

  const handleOnClickCountDecrement = (e: any, count: any, data: any) => {

    dispatch(addItemMultipleCart(data, Number(count) - 1));
  };

  const getPartnerOrderPrice = (items: any) => {
    let amount = 0;
    items.products.map(product => (
      amount = amount + (Number(product.partnerProduct.Partner_Selling_Price) * Number(product.itemCount))
    ));
    return amount.toFixed(2);
  };

  const getPartnerOrderTotalPrice = (items: any) => {
    let amount = 0;
    // items.products.map(product => (
    //   amount = amount + (Number(product.product.Original_Price) * Number(product.itemCount))
    // ));
    return amount.toFixed(2);
  };



  const getPartnerOrderDeliveryPrice = (items) => {
    let amount = 0;
    items.products.map(product => {
      amount = amount + (Number(product.partnerProduct.Shipping_Amount ? product.partnerProduct.Shipping_Amount : 0) * Number(product.itemCount))
    });
    return amount.toFixed(2);
  };

  const getPartnerOrderCouponDiscountPrice = (items) => {
    let coupon = items.coupon;
    let amount = 0;
    let productsAvailable = false;
    let products = items.products.filter(pro => {
      let found = false;
      if (coupon?.Category_Id && coupon?.Brand_Id) {
        if (coupon.Category_Id === pro.product.Category_Id && coupon.Brand_Id === pro.product.Brand_Id) {
          found = true;
        }
      } else if (coupon?.Brand_Id) {
        if (coupon?.Brand_Id === pro?.product?.Brand_Id) {
          found = true;
        }
      } else if (coupon?.Category_Id === pro?.product?.Category_Id) {
        found = true;
      }
      return found;
    });
    products.map(product => (
      amount = amount + (Number(product?.product?.Original_Price) * Number(product?.itemCount))
    ));
    if (products.length > 0) {
      productsAvailable = true;
    }
    if (productsAvailable) {
      let response = {
        discountValid: coupon?.Discount_Type ? true : false,
        discount: coupon?.Discount_Type === "P" ? true : false,
        esiDiscountValid: coupon?.ESI_Cashback_Type ? true : false,
        esiDiscount: coupon?.ESI_Cashback_Type === "P" ? true : false,
        amount: 0,
        esiAmount: 0,
        valid: true,
        validResponse: "*COUPON APPLIED SUCCESFULLY"
      };
      let discountAmount = 0;
      if (response.discountValid) {
        if (response.discount) {
          discountAmount = (amount / 100) * Number(coupon?.Discount_Amount);
        } else {
          if (Number(coupon?.Discount_Amount) > amount) {
            discountAmount = amount;
          } else {
            discountAmount = coupon?.Discount_Amount;
          }
        }
      }
      let esiDiscountAmount = 0;
      if (response.esiDiscountValid) {
        if (response.esiDiscount) {
          esiDiscountAmount = (amount / 100) * Number(coupon?.ESI_Cashback_Amount);
        } else {
          if (Number(coupon?.ESI_Cashback_Amount) > amount) {
            esiDiscountAmount = amount;
          } else {
            esiDiscountAmount = coupon?.ESI_Cashback_Amount;
          }
        }
      }
      response.amount = discountAmount.toFixed(2);
      response.esiAmount = esiDiscountAmount.toFixed(2);
      return response;
    } else {
      return {
        discountValid: coupon.Discount_Type ? true : false,
        discount: coupon.Discount_Type === "P" ? true : false,
        esiDiscountValid: coupon.ESI_Cashback_Type ? true : false,
        esiDiscount: coupon.ESI_Cashback_Type === "P" ? true : false,
        amount: (0).toFixed(2),
        esiAmount: (0).toFixed(2),
        valid: true,
        validResponse: "*COUPON APPLIED SUCCESFULLY"
      };
    }
    // return { discount: true, esiDiscount: true, amount: (300).toFixed(2), esiAmount: (300).toFixed(2), valid: true, validResponse: "*COUPON APPLIED SUCCESFULLY" }
  };

  const getPartnerGiftPackPrice = (items) => {
    let amount = 0;
    items.products.map(product => {
      if (product.isGiftPack) {
        let amount2 = product?.partnerProduct?.Is_Gift_Wrap_Available === "1" ? Number(product.partnerProduct.Is_Gift_Wrap_Amount ? product.partnerProduct.Is_Gift_Wrap_Amount : 0) : 0;
        amount = amount + amount2;
      }
    });
    return amount.toFixed(2);
  };

  const giftAvailableAmount = (items) => {
    return (items?.gift?.Total_Value ? items?.gift?.Total_Value : 0).toFixed(2);
  };

  const getPartnerGiftDiscountPrice = (items) => {
    let coupon = getPartnerOrderCouponDiscountPrice(items);
    let delivery = Number(getPartnerOrderDeliveryPrice(items));
    let giftPackAmount = Number(getPartnerGiftPackPrice(items));
    let totalBeforeGift = Number(getPartnerOrderPrice(items)) + delivery + giftPackAmount - (coupon.valid ? coupon.percentage ? 0 : coupon.amount : 0);
    return (totalBeforeGift < Number(giftAvailableAmount(items)) ? totalBeforeGift : Number(giftAvailableAmount(items))).toFixed(2);
  };

  const getPartnerWalletDiscountPrice = (items) => {
    let giftUseAmont = Number(getPartnerGiftDiscountPrice(items));
    let coupon = getPartnerOrderCouponDiscountPrice(items);
    let delivery = Number(getPartnerOrderDeliveryPrice(items));
    let giftPackAmount = Number(getPartnerGiftPackPrice(items));
    let totalBeforeWallet = Number(getPartnerOrderPrice(items)) + delivery + giftPackAmount - (coupon.valid ? coupon.percentage ? 0 : coupon.amount : 0) - giftUseAmont;
    return (totalBeforeWallet < Number(wallet?.Remaining_Balance) ? totalBeforeWallet : Number(wallet?.Remaining_Balance)).toFixed(2);
  };

  const savePartnerOrderDeliveryPrice = (items, address) => {
    let loc = address.locations;
    let distance = 3;
    if (loc?.User_Latitude && loc?.User_Longitude && loc?.Partner_Latitude && loc?.Partner_Longitude) {
      distance = getDistanceBetweenTwoLatAndLong(loc?.User_Latitude, loc?.Partner_Latitude, loc?.User_Longitude, loc?.Partner_Longitude);
    }
    let extraCharge = { charge: 0, message: "", distance: distance };
    if (items?.Business_Type == "FOOD" || items?.Business_Type == "GROCERY") {
      extraCharge = deliveryValueCaluculation(Number(getPartnerOrderPrice(items)), fgPartnerOrderRequiredAmount, distance);
    }
    return extraCharge
  }

  const deliveryValueCaluculation = (orderValue, arrayData, distance) => {
    let response = { charge: 0, message: "", distance: distance };
    function sortResults(input, prop, asc) {
      input.sort(function (a, b) {
        if (a[prop]) {
          if (asc) {
            return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
          } else {
            return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
          }
        } else {
          throw new Error(prop + ' is not a exist!');
        }
      });
      return input;
    }
    try {
      let dist = sortResults(JSON.parse(arrayData), "distance", true);
      for (let j = 0; j < dist.length; j++) {
        let isCurrectDistanceLogic = false;
        if (dist.length == 1) {
          isCurrectDistanceLogic = true;
        }
        else if (dist[j]?.distance >= distance && j + 1 < dist.length) {

          if (dist[j + 1]?.distance >= distance) {

          } else {
            isCurrectDistanceLogic = true;

          }

        }
        else if (dist[j]?.distance <= distance && j == dist.length - 1) {
          isCurrectDistanceLogic = true;
        }

        if (isCurrectDistanceLogic) {
          let data = sortResults(dist[j].charges, "value", true);
          for (let i = 0; i < data.length; i++) {
            if (orderValue < data[i].value || (i == data.length - 1 && orderValue >= data[i].value)) {
              let message = "";
              let charge = 0;
              if (i == 0 || i == data.length - 1) {
                charge = data[i].charge
              }
              else {
                charge = data[i - 1].charge
              }
              if (orderValue < data[i].value && i < data.length - 1) {
                if (data[data.length - 1].charge == 0) {
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                } else {
                  if (i + 1 == data.length - 1)
                    message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                  else
                    message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, and reduce ₹" + (charge - data[data.length - 1].charge) + " delivery charges please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + "more.";
                }
              } else if (orderValue < data[i].value) {
                if (data[data.length - 1].charge == 0) {
                  message = "To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
                } else {
                  if (i + 1 == data.length - 1)
                    message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                }
              }
              response = { charge: charge, message: message, distance: distance };
              break;
            }
          }
          break;
        }
      }
    } catch (error) {
      try {
        let data = sortResults(JSON.parse(arrayData), "value", true);
        for (let i = 0; i < data.length; i++) {
          if (orderValue < data[i].value || (i == data.length - 1 && orderValue >= data[i].value)) {
            let message = "";
            let charge = 0;
            if (i == 0 || i == data.length - 1) {
              charge = data[i].charge
            }
            else {
              charge = data[i - 1].charge
            }
            if (orderValue < data[i].value && i < data.length - 1) {
              if (data[data.length - 1].charge == 0) {
                message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
              } else {
                if (i + 1 == data.length - 1)
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
                else
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + " more, and reduce ₹" + (charge - data[data.length - 1].charge) + " delivery charges please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + "more.";
              }
            } else if (orderValue < data[i].value) {
              if (data[data.length - 1].charge == 0) {
                message = "To get free delivery please add ₹" + (data[data.length - 1].value - orderValue).toFixed(2) + " more.";
              } else {
                if (i + 1 == data.length - 1)
                  message = "To reduce ₹" + (charge - data[i + 1].charge) + " delivery charges please add ₹" + (data[i].value - orderValue).toFixed(2) + "more.";
              }
            }
            response = { charge: charge, message: message, distance: distance };
            break;
          }
        }
      } catch (error) {
      }
    }
    return response
  }


  const getPartnerOrderFinalPrice = (items) => {
    let coupon = getPartnerOrderCouponDiscountPrice(items);
    let delivery = Number(getPartnerOrderDeliveryPrice(items));
    let giftPackAmount = Number(getPartnerGiftPackPrice(items));
    let extraCharge = 0;
    if (items?.Business_Type == "FOOD" || items?.Business_Type == "GROCERY") {
      let loc = getPartnerAddress(item.products).locations;
      let distance = 3;
      if (loc?.User_Latitude && loc?.User_Longitude && loc?.Partner_Latitude && loc?.Partner_Longitude) {
        distance = getDistanceBetweenTwoLatAndLong(loc?.User_Latitude, loc?.Partner_Latitude, loc?.User_Longitude, loc?.Partner_Longitude);
      }
      let delChargeInfo = deliveryValueCaluculation(Number(getPartnerOrderPrice(items)), fgPartnerOrderRequiredAmount, distance);
      extraCharge = delChargeInfo.charge;
    }
    return (Number(getPartnerOrderPrice(items)) + delivery + extraCharge + giftPackAmount - Number(getPartnerGiftDiscountPrice(items)) - (coupon.valid ? coupon.percentage ? 0 : coupon.amount : 0)).toFixed(2);;
  };
  const addressCheckResponse = (noError) => {
    if (noError) {
      setProductError([]);
      navigation.navigate('CartSummary', { cart: item.partnerId });
    } else {
    }
  };


  const handleSubmit = (partnerId) => {
    dispatch(paceOrderValidation(cart, item, addressCheckResponse, productError));
  };

  let addresslist = []
  if (address) {
    if (address[0]) {
      for (let i = 0; i < address.length; i++) {
        addresslist.push({
          label: address[i].Address_Type + " " + "," + " " + address[i].Door_Number + " " + "," + " " + address[i].Landmark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip,
          value: address[i].User_Address_Id
        })
      }
    }
  }

  const accordionExpend = (expend, panelName) => {
    if (expend) {
      if (expend === true) {
        return true;
      } else {
        return panelName === expanded;
      }
    }
    return false;
  }



  const getPartnerAddressError = (products) => {
    let error = false;
    products.map((data) => {
      if (data.addressError) {
        error = true;
      }
    })
    return error
  }

  const getPartnerAddress = (products) => {
    let address = "";
    let notHaveAddress = [];
    products.map((data, index) => {
      if (data.address) {
        if (address.label) {

        } else {
          let completeProduct = data;
          address = completeProduct.address ? {
            label: completeProduct.address.Address_Type + " " + "," + " " + completeProduct.address.Door_Number + " " + "," + " " + completeProduct.address.Landmark + " " + "," + " " + completeProduct.address.Street + " " + "," + " " + completeProduct.address.City + " " + "," + " " + completeProduct.address.State + " " + "," + " " + completeProduct.address.Zip,
            value: JSON.stringify(completeProduct.address),
            locations: completeProduct.GEO_Locations
          } : ""
        }
      } else {
        notHaveAddress.push(index);
      }
    })
    return address
  }

  const getPartnerAddressCallback = useCallback(() => {
    let products = item.products;
    let address = "";
    let notHaveAddress = [];
    products.map((data, index) => {
      if (data.address) {
        if (address.label) {

        } else {
          let completeProduct = data;
          address = completeProduct.address ? {
            label: completeProduct.address.Address_Type + " " + "," + " " + completeProduct.address.Door_Number + " " + "," + " " + completeProduct.address.Landmark + " " + "," + " " + completeProduct.address.Street + " " + "," + " " + completeProduct.address.City + " " + "," + " " + completeProduct.address.State + " " + "," + " " + completeProduct.address.Zip,
            value: JSON.stringify(completeProduct.address),
            locations: completeProduct.GEO_Locations
          } : ""
        }
      } else {
        notHaveAddress.push(index);
      }
    })
    if (notHaveAddress.length > 0 && address?.value) {
      for (let i = 0; i < notHaveAddress.length; i++) {
        let addressN = JSON.parse(address?.value);
        if ((products[notHaveAddress[i]]?.User_Cart_Id && addressN?.User_Address_Id) ? true : false) {
          dispatch(productAddOrRemoveAddressPartnerPage(products[notHaveAddress[i]], addressN));
        }
      }
      notHaveAddress = [];
    }
    return address
  }, [item]);

  const [tempAddress, setTempAddress] = useState(false);
  const toast = useToast();

  const updatePartnerAddress = (products, address) => {
    setTempAddress(!tempAddress);
    dispatch(partnerAddOrRemoveAddress(products[0], address, toast));
  }

  const getDistanceBetweenTwoLatAndLong = (lat1,
    lat2, lon1, lon2) => {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;
    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
      + Math.cos(lat1) * Math.cos(lat2)
      * Math.pow(Math.sin(dlon / 2), 2);
    let c = 2 * Math.asin(Math.sqrt(a));
    // Radius of earth
    // for miles
    // let r = 6371;
    // for kilometers
    let r = 3956;
    // calculate the result
    return Math.ceil(c * r);

  }

  const removePartnerAddress = (products) => {
    products.map((data) => {
      let testAddress = JSON.stringify(data.address);
      dispatch(productAddOrRemoveAddress(data, ''));
      dispatch(productAddOrRemoveAddress(data, testAddress ? JSON.parse(testAddress) : ""));
    })
  }

  const styles = StyleSheet.create({

    cart_icon: {
      borderRadius: 50, right: 20, position: 'relative'
    },
    cart_count: {
      position: 'absolute',
      right: 60,
      // zIndex: 10,
      marginTop: -10,
      backgroundColor: 'blue',
      borderRadius: 50,
      paddingHorizontal: 5,
      justifyContent: 'center',
    },
    label: {
      marginBottom: 30,
    },
    containerone: {
      backgroundColor: 'white',
      paddingTop: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.DescColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.itemColor
    },
    selectedTextStyle: {
      fontSize: 16,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    centerElement: { justifyContent: 'center', alignItems: 'center' },
  });
  return (
    <ScrollView style={{ backgroundColor: esiColor.BackgroundColor }} >
      {item &&
        <View style={{ backgroundColor: esiColor.BackgroundColor, borderRadius: 7, paddingHorizontal: 20, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7 }}>
          <CartAccordionListItem style={{ marginTop: "10px" }} key={item?.partnerId} item={item}
            getPartnerOrderFinalPrice={getPartnerOrderFinalPrice}
            expanded={accordionExpend(expanded, "panel" + pindex)} onChange={handleChange("panel" + pindex)}>
            {savePartnerOrderDeliveryPrice(item, getPartnerAddressCallback()).charge > 0 &&
              <View style={{ width: "100%", margin: "auto" }}>
                <HelperText type="info">
                  {"Delivery distance " + savePartnerOrderDeliveryPrice(item, getPartnerAddress(item.products)).distance + "KM"}
                </HelperText>
                <HelperText type="info">
                  {"Delivery charges ₹" + savePartnerOrderDeliveryPrice(item, getPartnerAddress(item.products)).charge + "/-"}
                </HelperText>
                <HelperText type="info">
                  {savePartnerOrderDeliveryPrice(item, getPartnerAddress(item.products)).message}
                </HelperText>
              </View>
            }

            {!(["RETAIL", "FARMER", "MFH"].includes(item?.Business_Type)) ?

              <View style={{ width: "100%", margin: "auto", backgroundColor: esiColor.BackgroundColor }}>

                {tempAddress &&
                  <EsiSearchDropdown
                    style={[styles.dropdown, { borderColor: getPartnerAddressError(item.products) ? '#b00020' : '#27B6CC' }
                    ]}
                    data={address}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    labelField="label"
                    valueField="value"
                    maxHeight={180}
                    search
                    placeholder='Shipping Address'
                    defaultValue={getPartnerAddress(item.products)}
                    className="address"
                    onUpdateValue={(itemAddress: any) => {
                      if (itemAddress.value === "Add New Address") {
                        updatePartnerAddress(item.products, {});
                        navigation.navigate('AddAddress', { page: 'cart' });
                      } else {
                        updatePartnerAddress(item.products, JSON.parse(itemAddress.value));
                      }
                    }}
                    value={getPartnerAddress(item.products)?.value || ""}
                  />}
                {!tempAddress &&

                  <EsiSearchDropdown
                    style={[styles.dropdown, { borderColor: getPartnerAddressError(item.products) ? '#b00020' : '#27B6CC' }
                    ]}
                    data={address}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    labelField="label"
                    valueField="value"
                    maxHeight={180}
                    search
                    placeholder='Shipping Address'
                    defaultValue={getPartnerAddress(item.products)}
                    className="address"
                    onUpdateValue={(itemAddress: any) => {
                      if (itemAddress.value === "Add New Address") {
                        updatePartnerAddress(item.products, {});
                        navigation.navigate('AddAddress', { page: 'cart' });
                      } else {
                        updatePartnerAddress(item.products, JSON.parse(itemAddress.value));
                      }
                    }}
                    value={getPartnerAddress(item.products)?.value || ""}
                  />
                }
                <HelperText type="error" visible={getPartnerAddressError(item.products) ? true : false}>
                  {getPartnerAddressError(item.products) ? item?.products[0]?.addressError : ""}
                </HelperText>
              </View> : <></>
            }

            {item.products.map((data, index) => (
              <CartProductCard
                key={'prodcart' + index}
                address={address}
                setOpenAddressPopup={setOpenAddressPopup}
                index={index}
                data={data.Product}
                partner={data.partnerProduct}
                count={data.itemCount}
                handleOnChangeCount={handleOnChangeCount}
                handleOnClickCountIncrement={handleOnClickCountIncrement}
                handleOnClickCountDecrement={handleOnClickCountDecrement}
                handleOnClickRemoveCard={handleOnClickRemoveCard}
                completeProduct={data}
                cart={cart}
                productError={productError}
              />
            ))}

            <View style={{ flexDirection: "row", justifyContent: 'center', backgroundColor: esiColor.BackgroundColor, marginTop: 5 }}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: esiColor.globalButtonColor,
                  padding: 10,
                  margin: 'auto',
                  borderRadius: 8
                }}
                onPress={() => handleSubmit(item?.partnerId)}
              >
                <Text style={{ color: esiColor.itemButtenColor }} >Place Order</Text>
              </TouchableOpacity>
            </View>

          </CartAccordionListItem>
        </View>
      }
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }}
        isVisible={isConfirmScreen}
      >
        <View style={{ flex: 1, maxWidth: dimensions.width - 50, minWidth: 300, minHeight: 150, maxHeight: 170, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
          <View style={{ flexDirection: "column" }} >
            <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, backgroundColor: "" }} onPress={() => {
              setIsConfirmScreen(!isConfirmScreen);
            }}>
              <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
            </TouchableRipple>
            <View style={{
              flexDirection: "column",
              justifyContent: "space-around",
              height: 150
            }}>
              <View style={{
                flexDirection: "column",
                justifyContent: "space-around",
                paddingHorizontal: 10,
                marginTop: 20
              }}>
                <Text style={{ fontWeight: "bold", fontSize: 16, color: esiColor.Text }} >Remove item from cart :</Text>
                <Text style={{ color: esiColor.Text }} >Are you sure you want to move this item from cart?</Text>
              </View>
              <View style={{
                flexDirection: "row",
                justifyContent: "space-around",
                paddingHorizontal: 5,
                marginTop: 5

              }}>
                <Button style={{ backgroundColor: '#91ecfa' }} mode="contained" onPress={() => { deleteConfirmCheck(false); }} icon="delete" >
                  Remove
                </Button>
                <Button style={{ backgroundColor: '#27b6cc' }} mode="contained" onPress={() => { deleteConfirmCheck(true); }} icon="heart" >
                  wish list
                </Button>
              </View>
            </View>

          </View>
        </View>
      </Modal>
    </ScrollView>
  );
}

