import React from 'react';
import EsiSearchDropdown from '../searchable-dropdown';
import { StyleSheet } from 'react-native';
import axios from 'axios';
import config from '../../../state/actions/config';
const headers = config.headersCommon;

const EsiCity = (props: any) => {
    
    // props variable consist all react text field properties along with valueUpdate function.
    const { CountryCode, StateCode, ...rest } = props
    const [city, setCity] = React.useState([]);
    React.useEffect(() => {
        if (CountryCode && StateCode) {
            getCitys(CountryCode, StateCode)
        }
    }, [CountryCode, StateCode])
    React.useLayoutEffect(() => {       
        if ((CountryCode && StateCode)) {
            let citysdata = {
                "Filter_Type": "CITY",
                "Country": CountryCode,
                "State": StateCode

            }
            getCitys(citysdata)
        }   
    }, [CountryCode, StateCode])

    const getCitys = (citysdata: any) => {
        axios.post(`${config.url}/Country_State_City/Get`, citysdata,
            {
                headers: headers
            }
        )
            .then(response => {
                setCity(response?.data?.City[0]?.City_List);
            })
            .catch(error => {
            })
    }
    let cities = []
    if (city) {
        if (city[0]) {
            for (let i = 0; i < city.length; i++) {
                cities.push({
                    id: [i],
                    label: city[i].NAME,
                    value: city[i].CITY_ID
                })
            }
        }
    }
    return (
        <EsiSearchDropdown
            {...props}
            // data param here is used to assign the data from the function values that will be provided.
            data={city}
            style={styles.dropdown}
            // Providing styles for placeholder
            placeholderStyle={styles.placeholderStyle}
            // Providing styles for selectedText
            selectedTextStyle={styles.selectedTextStyle}
            // Providing styles for inputSearch
            inputSearchStyle={styles.inputSearchStyle}
            // Providing styles for icon
            iconStyle={styles.iconStyle}
            // valueField is used to get the value
            valueField={props.valueField}
            // labelField is used to get the label
            labelField={props.labelField}
            // search is used to get the value from the dropdown
            search={props.search}
            searchPlaceholder={props.searchPlaceholder}
            maxHeight={180}
            dropdownPosition="auto"
        />

    );
};

export default EsiCity;


const styles = StyleSheet.create({
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
})
