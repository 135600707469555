import React, { useState } from "react";
import { Surface } from "react-native-paper";
import Header from "../header";
import HomeFoldOne from "./HomeFoldOne";
import MeeStores from "./MeeStores";
import MeeProducts from "./MeeProducts";
import MeeDeals from "./MeeDeals";
import MeeCoupons from "./MeeCoupons";
import MeeBrands from "./MeeBrands";
import RecentlyVisites from "./RecentlyVisites";
import MeeStoresTypes from "./MeeStoresTypes";
import { useSelector } from "react-redux";
import MeeProductsType from "./MeeProductsType";
import { FlatList } from "react-native";
import MeeSCProducts from "./sub-category-products/MeeSCProducts";
import MfhZones from "./MFHZones";
import MeeFarmers from "./MeeFarmers";

export default function Homee(props) {
    const { navigation } = props;
    const shippingAddress = JSON.parse(useSelector((state) => state.cart.defaultAddress)?.value || '{}');
    const [cityDataExist, setCityDataExist] = useState(true);
    const esiColor = useSelector(state => state.theme);

    const dataReload = (30 * 60) * 1000;
    const dataErrorReload = 60 * 1000;
    const DATA = [
        {
            id: 2
        },
        {
            id: 19
        },
        {
            id: 3
        },
        {
            id: 4
        },
        {
            id: 5
        },
        {
            id: 6
        },
        {
            id: 7
        },
        {
            id: 8
        },
        {
            id: 9
        },
        {
            id: 10
        },
        {
            id: 13
        },
        {
            id: 14
        },
        {
            id: 15
        },
        {
            id: 16
        },
        {
            id: 17
        },
        {
            id: 18
        },
   
        {
            id: 20
        }
    ];
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
            <Header {...props} />
            <FlatList
                data={DATA}
                renderItem={({ item }) => {
                    let res;
                    switch (item.id) {
                        case 1:
                            res = <HomeFoldOne navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />;
                            break;
                        case 2:
                            res = <MeeStoresTypes navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                            break;
                        case 3:
                            res = shippingAddress?.City_Id &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Physical"} storeType={"Mee Food"} address={shippingAddress} title={"Food Stores - " + shippingAddress.City} />
                            break;
                        case 4:
                            res = shippingAddress?.City_Id &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Physical"} storeType={"Mee Grocery"} address={shippingAddress} title={"Grocery Stores - " + shippingAddress.City} />

                            break;
                        case 5:
                            res = shippingAddress?.City_Id &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} setCityDataExist={setCityDataExist} type={"Physical"} title={"Mee Stores - " + shippingAddress.City} address={shippingAddress} />

                            break;
                        case 6:
                            res = ((!cityDataExist && shippingAddress?.City_Id) || !shippingAddress?.City_Id) &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Physical"} title={"Mee Physical Stores"} />

                            break;
                        case 7:
                            res = <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Online"} title={"Mee Online Stores"} />;
                            break;
                        case 8:
                            res = shippingAddress?.City_Id &&
                                <MeeProductsType navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} Business_Type={"FOOD"} title={"Mee Food"} City_Id={shippingAddress?.City_Id} />
                                ;
                            break;
                        case 9:
                            res = shippingAddress?.City_Id &&
                                <MeeProductsType navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} Business_Type={"GROCERY"} title={"Mee Grocery"} City_Id={shippingAddress?.City_Id} />

                            break;
                        case 10:
                            res = shippingAddress?.City_Id &&
                                <MeeProductsType navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} Business_Type={"RETAIL"} title={"Mee Retail"} City_Id={shippingAddress?.City_Id} />
                                ;
                            break;
                        case 13:
                            res = <MeeBrands navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />;
                            break;
                        case 14:
                            res = <MeeProducts navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />;
                            break;
                        case 15:
                            res = <MeeDeals navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                            break;
                        case 16:
                            res = <MeeCoupons navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />;
                            break;
                        case 17:
                            res = <RecentlyVisites navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />;
                            break;
                        case 18:
                            res = <MeeSCProducts navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} City_Id={shippingAddress?.City_Id} />;
                            break;
                        case 19:
                            res = <MfhZones navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                            break;
                        case 20:
                            res = <MeeFarmers navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                            break;
                        default:

                    }

                    return res
                }}
                keyExtractor={item => item.id}
            />
        </Surface>

    )

}