import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { StyleSheet, View, Dimensions, ActivityIndicator } from 'react-native';
import { TextInput, Text, HelperText, Button, Card, ProgressBar, Colors, TouchableRipple } from 'react-native-paper';
import { changePasswordAction } from "../../state/actions/profileActions";
import * as Yup from 'yup';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import ESITextInput from '../../components/custom-fields/textInput';

const ChangePassword = ({ props, profiletemp, callgetuser, loading, setLoading, setScreenType, ...rest }) => {
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    //toast for popup after successful API calls.
    const toast = useToast();
    // contains the data from API using state calls
    const user = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);

    const [errorMessage, setErrorMessage] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [hidePass, setHidePass] = useState(true);
    const [changehidePass, setChangeHidePass] = useState(true);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const changePasswordResponse = (status, errorMessage) => {
        if (!status) {
            setErrorMessage(errorMessage);
            setSeconds(3);
        } else {
            setScreenType("profile");
        }
        setLoading(false);
    }
    const copyPasteCutChange = (e) => {
        e.preventDefault();
    };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);
    /*====================================
           Handler
   ====================================*/
    const values = {

        Email_Id: logedInEmail,
        Old_Password: '',
        New_Password: '',
        cpassword: ''

    }

    //Form Validation
    const Form_Validation = Yup.object().shape({
        Old_Password: Yup.string().required('Current Password is required.'),
        New_Password: Yup.string()
            .max(255)
            .matches(/[a-z]+/, "At least one lowercase letter is required.")
            .matches(/[A-Z]+/, "At least one uppercase letter is required.")
            .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
            .matches(/.*\d+/, "At least one number is required.")
            .min(8, "At least minimum 8 characters required.")
            .notOneOf([Yup.ref('Old_Password'), null], "Don't use Old Password.")
            .required('New Password is required.'),

        cpassword: Yup.string()
            .max(255).oneOf([Yup.ref('New_Password'), null], 'Password should match.')
            .required('Confirm Password is required.')
    })

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(false);
        setErrorMessage("");
        if (values) {
            let formData1 = JSON.parse(JSON.stringify(values));
            dispatch(changePasswordAction(formData1, changePasswordResponse, resetForm, toast));
            setLoading(true);
        }
    }


    return (
        <Formik initialValues={{ ...values }} enableReinitialize={true} onSubmit={onSubmit} validationSchema={Form_Validation}>
            {(props) => (
                <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                    <Card style={{
                        width: dimensions <= 500 ? "98%" : "30%", marginLeft: "auto", marginRight: "auto",
                        marginTop: "2%", marginBottom: "2%"
                    }}>
                        <Card.Content>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                    Change Password
                                </Text>
                                <TouchableRipple
                                    onPress={() => setScreenType("profile")}
                                >
                                    <Text style={{ fontSize: 14, textDecorationLine: 'underline', color: "#27b6cc", marginTop: 5 }}>Back to Profile</Text>
                                </TouchableRipple>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                    {/*  Current Password Field  with Validation */}
                                    <ESITextInput
                                        error={Boolean(props.touched.Old_Password && props.errors.Old_Password)}
                                        label='Current Password'
                                        mode='outlined'
                                        name="Old_Password"
                                        value={props.values.Old_Password}
                                        onChangeText={props.handleChange('Old_Password')}
                                        secureTextEntry={hidePass ? true : false}
                                        right={<TextInput.Icon
                                            theme={{
                                                colors: {
                                                    primary: esiColor.TextHighlight,
                                                    text: esiColor.Text,
                                                    placeholder: esiColor.TextPlaceholder
                                                }
                                            }} name={hidePass ? 'eye-off' : 'eye'}
                                            size={20}
                                            onPress={() => setHidePass(!hidePass)}
                                        />}
                                        onCut={copyPasteCutChange}
                                        onCopy={copyPasteCutChange}
                                        onPaste={copyPasteCutChange}
                                    />
                                    <HelperText type="error" visible={Boolean(props.touched.Old_Password && props.errors.Old_Password)}>
                                        <ErrorMessage name="Old_Password" />
                                    </HelperText>
                                </View>

                                {/*   New Password Field with validation */}
                                <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                    <ESITextInput
                                        error={Boolean(props.touched.New_Password && props.errors.New_Password)}
                                        label='New Password'
                                        mode='outlined'
                                        name="New_Password"
                                        value={props.values.New_Password}
                                        onChangeText={props.handleChange('New_Password')}
                                        secureTextEntry={true}
                                    />
                                    <HelperText type="error" visible={Boolean(props.touched.New_Password && props.errors.New_Password)}>
                                        <ErrorMessage name="New_Password" />
                                    </HelperText>
                                </View>
                            </View>

                            {/*   Confirm Password Field with validation */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <View style={{ width: 453, flexShrink: 1, paddingTop: 15 }}>
                                    <ESITextInput
                                        error={Boolean(props.touched.cpassword && props.errors.cpassword)}
                                        label='Confirm Password'
                                        mode='outlined'
                                        name="cpassword"
                                        value={props.values.cpassword}
                                        onChangeText={props.handleChange('cpassword')}
                                        secureTextEntry={changehidePass ? true : false}
                                        right={<TextInput.Icon theme={{
                                            colors: {
                                                primary: esiColor.TextHighlight,
                                                text: esiColor.Text,
                                                placeholder: esiColor.TextPlaceholder
                                            }
                                        }} name={changehidePass ? 'eye-off' : 'eye'}
                                            size={20}
                                            onPress={() => setChangeHidePass(!changehidePass)}
                                        />}
                                        onCut={copyPasteCutChange}
                                        onCopy={copyPasteCutChange}
                                        onPaste={copyPasteCutChange}
                                    />
                                    <HelperText type="error" visible={Boolean(props.touched.cpassword && props.errors.cpassword)}>
                                        <ErrorMessage name="cpassword" />
                                    </HelperText>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', alignSelf: 'center' }} >
                                <Text style={{ color: "red", textAlign: 'center' }} > {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                                    height: 5,
                                    width: 300,
                                }} progress={.30 * seconds} color={Colors.red900} /></>} </Text>
                            </View>
                            <View style={{ width: 200, marginLeft: "auto", marginRight: "auto" }}>
                                {loading ? <ActivityIndicator color='#27b6cc' /> :
                                    <Button style={{ width: "60%", marginTop: "3%", marginLeft: "20.5%", backgroundColor: "#27B6CC" }} mode="contained" onPress={props.handleSubmit}>
                                        Confirm
                                    </Button>
                                }
                            </View>
                        </Card.Content>
                    </Card>
                </View>
            )}
        </Formik>
    );
}

const styles = StyleSheet.create({
    heading: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: "3%",
    },
})

ChangePassword.propTypes = {
    profiletemp: PropTypes.object,
    callgetuser: PropTypes.func
};




export default ChangePassword;