import React from "react";
import { Text, Colors, TouchableRipple, Avatar, Surface } from 'react-native-paper';
import { View, Dimensions, StyleSheet, Image, TouchableOpacity, useWindowDimensions, Linking, ScrollView, Platform } from "react-native";
import RenderHTML from "react-native-render-html";
import { adduservisitAction } from "../../state/actions/dealActions";
import { useDispatch, useSelector } from "react-redux";

export default function ESIDealModel(props: any) {
    // data is a deal object as we use this object to display the info front-end
    // isAuthenticated prop is used here to call authentication
    const { data, isAuthenticated, closeModal, ...rest } = props
    const esiColor = useSelector(state => state.theme);

    const sampleData =
    {
        Records_Filter: "FILTER",
        Brand_Id: [],
        Partner_Details_Id: [],
        Category_Id: [],
        Deal_Id: [],
        Discount_Type: "",
        Discount: "",
        Esi_Cashback_Type: "",
        Is_Top_Deal: "",
        Whats_Hot: "",
        Verified_Deal: "",
        Exclusive: "",
        Extra_Cashback: "",
        Live_Status: "",
        Status: "Active",
        Type: "",
    };
    const dispatch = useDispatch();
    const handleClick = async () => {
        const formData = {
            "User_Email_Id": isAuthenticated?.Email_Id,
            "Ip_Address": "192.168. 0.1",
            "Offer_Type": "Deal",
            "Offer_Id": data?.Deal_Id,
            "Store_Type": data?.Partner_Type,
            "Store_Id": data?.Partner_Details_Id,
            "Esi_Cashback": data?.Esi_Cashback,
            "Status": data?.Status,
            "Redirection_Url": data?.Mobile_Redirect_Url
        }
        dispatch(adduservisitAction(formData))
        Linking.openURL(data?.Mobile_Redirect_Url);
    }
    const window = Dimensions.get("window");
    const { width } = useWindowDimensions();
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
    });
    return (
        <ScrollView>
            <View style={{ alignItems: 'flex-end' }}>
                <TouchableRipple style={{ width: 30 }} onPress={closeModal}>
                    <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                </TouchableRipple>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: "space-around", alignSelf: 'center' }}>
                <View style={{ flex: 1, alignSelf: 'center', width: 100, height: 100 }} >
                    <View style={{ flexDirection: 'row', justifyContent: "space-between", alignSelf: 'center' }}>

                        <Image source={{ cache: "force-cache", uri: data?.Image + `?w=100&h=50` }} style={{ width: 100, height: 50, margin: 30, resizeMode: 'contain', }} />
                    </View>
                </View>
                {/* {Platform.OS === "web" &&
                    <View style={{ flex: 1, alignSelf: 'center', width: 100, height: 100 }} >

                    </View>} */}

                <View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignSelf: "center" }} >
                    <View style={{ flexDirection: 'row', justifyContent: "space-between", alignSelf: 'center' }}>

                        <Image source={{ cache: "force-cache", uri: data?.Logo_Path + `?w=100&h=50` }} style={{ width: 100, height: 50, resizeMode: 'contain', borderWidth: 1, borderColor: 'black', margin: 5 }} />
                    </View>
                    <Text style={{ fontSize: 12, textAlign: 'center', fontWeight: 'bold', flexDirection: 'row', justifyContent: "center", color: esiColor.DescColor }}>
                        Status:
                        <Text style={{ color: data?.Expired_Status === "Live" ? "green" : esiColor.itemColor, flexDirection: 'row', fontSize: 12, fontWeight: 'bold' }}>
                            {data?.Expired_Status}
                        </Text>
                    </Text>
                </View>
            </View>
            <View style={{ flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 14, flex: 1, color: '#27b6cc' }}>{data?.Title} </Text>
                <View style={{ margin: 'auto', flexWrap: "wrap", paddingHorizontal: "5%", marginBottom: 5, backgroundColor: esiColor.RTBC, borderRadius: 8 }}>
                    {data?.Deal_Description ? <View style={{ backgroundColor: esiColor.RTBC, borderRadius: 8 }} >
                        <RenderHTML
                            source={{ html: data?.Deal_Description }} /></View> : <></>}
                </View>
            </View>
            <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', flexGrow: 1, flexShrink: 1, flexWrap: 'wrap' }}>
                    <Text style={{ padding: '1%', fontSize: 12, fontWeight: 'bold', color: 'grey', borderColor: 'grey', borderWidth: 0.5, borderRadius: 6, shadowColor: '#1b81d2', shadowRadius: 5 }}>{data?.Esi_Cashback_Type == 'R' ? "Rewards" : "Cashback"}:{" "}
                        {data?.Cashback_Label?.toLowerCase()} {data?.Esi_Cashback_Amount}{(data?.Esi_Cashback_Type == 'P' || data?.Esi_Cashback_Type == 'percentage' || data?.Esi_Cashback_Type == 'Percentage') ? '%'
                            : (data?.Esi_Cashback_Type == 'A' || data?.Esi_Cashback_Type == 'amount' || data?.Esi_Cashback_Type == 'Amount') ? '/-' : <Image source={{ cache: "force-cache", uri: "https://yourstaffingsolutions.in/wp-content/uploads/2021/04/rewards.png" }} style={{ height: 10, width: 10, resizeMode: 'contain', borderRadius: 20 }} />}</Text>
                    <Text style={{ fontSize: 12, padding: '1%', color: esiColor.itemColor, fontWeight: 'bold', borderColor: 'grey', borderWidth: 0.5, borderRadius: 6, marginLeft: '4%', shadowColor: '#1b81d2', shadowRadius: 5 }}>Valid till {data?.End_Date}</Text>
                </View>
            </View>
            <View style={styles.Copycodecontainer1}>
                <TouchableOpacity onPress={() => handleClick()} style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Grab Offer</Text>
                </TouchableOpacity>
            </View>
        </ScrollView>
    )
}
const styles = StyleSheet.create({

    Copycodecontainer1: {
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        marginTop: '5%',
        margin: "2%"
    },
});


