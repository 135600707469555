import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View, ScrollView, RefreshControl, FlatList, Dimensions, Platform, ActivityIndicator, Image, TouchableOpacity } from "react-native";
import { Avatar, Badge, Button, DefaultTheme, HelperText, IconButton, Menu, Searchbar, Surface, Text, TouchableRipple } from "react-native-paper";

import { useDispatch, useSelector } from "react-redux";

import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import Pagination from "../../../components/custom/pagination/pagination";
import { Drawer } from 'react-native-drawer-layout';
import { BrandsFilters } from "./BrandsFilters";
import { FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Modal from "react-native-modal";
import SignIN from "../../auth/signin";
import { useToast } from "../../../self_modules/react-native-paper-toast/src";
import { Formik } from "formik";
import * as Yup from 'yup';
import ESITextInput from "../../../components/custom-fields/textInput";
import EsiTextAreaInput from "../../../components/custom/TextAreaInput/textareainput";
import { createBids, createCartBids } from "../../../state/actions/boomi-actions/bid-ride-actions";
import * as Location from "expo-location";
import api from "../../../state/actions/api";
import EsiDatePicker from "../../../components/custom/date/date";
import { availabileBiddingVehicals, getBiddingRidesCart } from "../../../state/actions/node-actions/bid-rides-actions";
import { ComfortTypesFilters } from "./ComfortTypesFilters";
import { useFocusEffect, useRoute } from "@react-navigation/native";


export default function NewBidRide(props) {
    const [open, setOpen] = React.useState(false);
    const { navigation } = props;
    const dispatch = useDispatch();
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);
    const [pageOld, setPageOld] = React.useState(false);
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const esiColor = useSelector(state => state.theme);
    const callBackGetData = async (status, data, pagination) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            setPageOld(true);
            if (pagination) {
                setPagination(pagination);
            } else {
                setPagination({ PageNo: 1, PageSize: 50, SortBy: "BIDDING_VEHICLE_ID", SortOrder: "DESC" });
            }
        }
    }

    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "BIDDING_VEHICLE_ID", SortOrder: "DESC" });

    const [testFill, setTestFill] = React.useState({});

    const [search, setSearch] = React.useState("");
    const [pincode, setPincode] = React.useState("");
    const data = props?.route?.params;

    useEffect(() => {
        setSearch("");
    }, [data])

    useEffect(() => {
        (async () => {
            if (Platform.OS === 'android' && !Constants.isDevice) {
                alert(
                    'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
                );
                return;
            }
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                // Alert.alert('Location Service not enabled', 'Please enable your location services to continue', [{ text: 'OK' }], { cancelable: false });
                return;
            }

            const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
            api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
                let info = response?.data?.address?.postcode;
                if (info)
                    setPincode(info)

            }).catch(async (reason) => {
            });
        })();
    }, [])

    const [filters, setFilters] = useState([]);
    const [cfilters, setCFilters] = useState([]);
    const [cartCount, setCartCount] = useState(0);
    const [testPagination, setTestPagination] = React.useState(pagination);


    const callProducts = () => {
        let formData = {
            Status: "Active"
        }

        if (filters.length > 0)
            formData.Brand = filters.length > 0 ? filters[0]?.id : "";

        if (cfilters.length > 0)
            formData.ComfortType = cfilters.length > 0 ? cfilters[0]?.id : "";

        formData.Search = search;
        formData.EmailId = logedInEmail || "";
        if (!pageOld) {
            if (!(JSON.stringify(testFill) === JSON.stringify(formData)) || !(JSON.stringify(testPagination) === JSON.stringify(pagination))) {
                setTestFill(formData);
                setLoading(true);
                dispatch(availabileBiddingVehicals(formData, pagination, callBackGetData));
            }
        } else {
            setPageOld(false);
        }
        if (logedInEmail)
            dispatch(getBiddingRidesCart({
                "getCount": true,
                "User_Email_ID": logedInEmail
            }, (status, data) => setCartCount(data?.Counts || 0)));
    }

    useFocusEffect(
        React.useCallback(() => {
            if (!isEmpty(logedInEmail)) {
                callProducts()
            }
            return () => {

            };
        }, [])
    );

    useEffect(() => {
        callProducts();
    }, [search, logedInEmail, filters, cfilters, pagination]);
    const scroll = useRef(null);
    const goToTop = () => {
        scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
    }


    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
        if (status) {

        }
    };


    const [addCartPop, setAddCartPop] = React.useState(false);
    const [addCartItem, setAddCartItem] = React.useState({});
    const addProductToBid = (data: any) => {
        if (logedInEmail) {
            setAddCartPop((prop) => !prop);
            setAddCartItem(data);
        } else {
            setModalVisible(!isModalVisible);
        }
    }

    const vehicleShow = useMemo(() => {
        let numColumCount = Number((dimensions.width / 250).toFixed() == "1" ? 2 : (dimensions.width / 250).toFixed());

        return <FlatList
            // horizontal={true}
            key={dimensions.width}
            columnWrapperStyle={{ justifyContent: "space-around" }}
            numColumns={numColumCount}
            data={allproducts}
            renderItem={({ item, index, separators }) => (
                <View key={"bid" + index} style={[{
                    margin: 5, backgroundColor: esiColor.BackgroundColor,
                    borderRadius: 8, width: (dimensions.width / Number(numColumCount)) - 10, maxWidth: 230, paddingVertical: 2

                }, Platform.OS != "android" ? {
                    shadowOffset: { width: 0, height: 0 },
                    shadowColor: esiColor.brandShadowColor,
                    shadowOpacity: 5,
                    shadowRadius: 7,
                    elevation: 20
                } : {
                    elevation: 20,
                    overflow: "hidden"
                }]}>
                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <Image style={{ resizeMode: 'contain', width: (dimensions.width / Number(numColumCount)) - 20, maxWidth: 210, height: ((dimensions.width / Number(numColumCount)) - 20) * .7, maxHeight: 200, borderRadius: 8, backgroundColor: "#efefef" }}
                            source={{ cache: "force-cache", uri: item?.Image + `?w=210&h=200` }}></Image>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 5 }}>
                        <Text style={{ flexWrap: "wrap", textAlign: "center", color: esiColor.itemColor, fontWeight: 500 }} >{item?.Vehicle_Type + " - " + item?.Vehicle_Comfort_Type + " - "}
                            <MaterialCommunityIcons name="seat-passenger" size={18} color={esiColor.itemColor} /> {item?.Seating_Capacity}
                        </Text>

                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "center", marginBottom: 5 }}>
                        <Button icon="cart-plus" uppercase={false} contentStyle={{ borderColor: 'gray', borderWidth: 0.5, borderRadius: 8 }} mode="outlined" color={esiColor.globalButtonColor} onPress={() => {
                            addProductToBid(item);
                        }}>
                            Add to Bid
                        </Button>
                    </View>
                </View>
            )}
        />
    }, [allproducts, dimensions])

    const drawerContent = useCallback(() => {
        return (
            <ScrollView
                showsHorizontalScrollIndicator={false}
                style={{ marginVertical: 10 }} >
                <View style={{ marginTop: 5 }} >
                    <BrandsFilters filters={filters} setFilters={setFilters} />
                </View>
                <View style={{ marginVertical: 5 }} >
                    <ComfortTypesFilters filters={cfilters} setFilters={setCFilters} />
                </View>
            </ScrollView >
        )
    }, [open, filters])

    const [loadingForm, setloadingForm] = React.useState(false);
    const [formResults, setFormResults] = React.useState(false);
    const [formikSubmitType, setFormikSubmitType] = React.useState(0);
    let nDate = new Date();
    nDate.setDate(nDate.getDate() + 2);
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);

    const [errorMessage, setErrorMessage] = useState("");

    const formCallbackResponse = (status: boolean, erorMessage: string) => {
        setloadingForm(false);
        if (status) {
            setFormResults(true);
            callProducts();
        } else {
            setErrorMessage(erorMessage);
        }
    }

    const canGoBackCallback = useMemo(() => {
        return navigation.canGoBack();
    }, [navigation])


    const datediff = (first, second) => {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    return (
        <Surface
            theme={{
                colors: {
                    surface: esiColor.BackgroundColor,
                }
            }}
            style={{ flex: 1 }}>
            <Header {...props} />

            <Drawer
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                drawerStyle={{ backgroundColor: esiColor.BackgroundColor }}
                renderDrawerContent={drawerContent}
                drawerPosition={"left"}
            >
                <View>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        {canGoBackCallback &&
                            <IconButton
                                icon="arrow-left"
                                size={27}
                                color={"#27B6CC"}
                                style={{ marginLeft: 1 }}
                                onPress={() => {
                                    navigation.goBack();
                                }} />
                        }
                        <Searchbar style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, minWidth: "40%", maxWidth: "40%", borderWidth: 0.5, maxHeight: 30, marginTop: 10 }}
                            inputStyle={{ fontSize: 12, marginLeft: -20 }}
                            value={search}
                            onChangeText={(value) => {
                                setSearch(value);
                            }}
                            iconColor={esiColor.SIconColor}
                            placeholderTextColor={esiColor.SPHTextColor}
                            theme={{ colors: { text: esiColor.SBTextColor } }}
                            multiline={false}
                            placeholder="Search"
                        />

                        <IconButton
                            color={'#27b6cc'}
                            icon="filter"
                            size={24}
                            style={{ marginTop: -45, alignSelf: 'flex-end' }}
                            onPress={() => setOpen((prevOpen) => !prevOpen)}
                        />

                    </View>

                </View>
                <ScrollView ref={scroll}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                        />
                    }
                >
                    <View style={{ alignItems: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'space-around' }}>
                        {isEmpty(allproducts) ? (loading ? <ActivityIndicator /> :
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                                <Text style={{ color: esiColor.NoDataFound }}>No Vehicles found!</Text>
                            </View>
                        ) : (
                            vehicleShow
                        )
                        }
                    </View>



                    <View style={{ marginBottom: 5 }}>
                        <Pagination pagination={pagination} setPagination={setPagination} />
                    </View>

                </ScrollView>
                {(cartCount > 0 && logedInEmail) &&
                    <TouchableOpacity onPress={() => {
                        navigation.navigate("CartBidRides");
                    }} style={{ position: 'absolute', right: 0, bottom: 20, backgroundColor: esiColor.esiMainColor, borderRadius: 8, flexDirection: "row", justifyContent: "center", padding: 5 }} >
                        <Ionicons name="cart" size={22} style={{}} color="white" />
                        <Text style={{ color: "white", marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold" }} > Bids </Text>
                        <Badge style={{ position: "absolute", right: 0, top: -10, color: esiColor.esiMainColor, backgroundColor: "#efefef" }} >{cartCount}</Badge>
                    </TouchableOpacity>}
            </Drawer>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={addCartPop}>
                <ScrollView style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, overflow: "scroll" }}>

                    <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                        setAddCartPop((prop) => !prop);
                        setFormResults(false);
                    }}>
                        <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                    </TouchableRipple>
                    <Formik
                        initialValues={
                            {
                                Bidding_Vehicle_Id: addCartItem.Bidding_Vehicle_Id,
                                Bidding_Ride_Brand_Vehicle_Id: addCartItem?.Bidding_Ride_Brand_Vehicle_Id,
                                Quantity: '',
                                User_Email_Id: logedInEmail,
                                Description: '',
                                Pincode: pincode || '',
                                Bidding_Expiry_Date: "",
                                No_Of_Days: 1,
                                Distance_In_KMS: 10,
                                Ride_Start_Date: ""
                            }
                        }
                        validationSchema={Yup.object().shape({
                            Quantity: Yup.number().min(1, "Quantity should be more than Zere.").required("Quantity is required.").typeError('Input must be number.'),
                            No_Of_Days: Yup.number().min(1, "Quantity should be more than Zere.").required("Quantity is required.").typeError('Input must be number.'),
                            Distance_In_KMS: Yup.number().min(1, "Quantity should be more than Zere.").required("Quantity is required.").typeError('Input must be number.'),
                            Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                            Bidding_Expiry_Date: Yup.string().required('Date of service is required.').test("date-of-bidding", "Service Date should be more than " + dos + ".", (value: any) => {
                                if (value) {
                                    let date1 = new Date(value).getTime();
                                    let date2 = new Date(dos).getTime();
                                    if (datediff(date2, date1) > -1) {
                                        return true;
                                    }
                                }
                                return false;

                            }),
                            Ride_Start_Date: Yup.string().required('Ride Start Date is required.')

                        })}
                        onSubmit={(values, actions) => {

                            let date1 = new Date(values.Bidding_Expiry_Date).getTime();
                            let date2 = new Date(values.Ride_Start_Date).getTime();
                            if (datediff(date2, date1) > -1) {
                                actions.setFieldError("Ride_Start_Date", "Ride Date should be after Bidding Expiry Date");
                            } else {
                                setloadingForm(true);
                                if (formikSubmitType == 0)
                                    dispatch(createBids(values, formCallbackResponse));
                                else
                                    dispatch(createCartBids(values, formCallbackResponse));
                            }
                        }}
                    >
                        {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                            <View style={{
                                flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                            }}>
                                {loadingForm ?
                                    <ActivityIndicator color={esiColor.esiMainColor} />
                                    :
                                    formResults ?
                                        <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                            <Image source={require('../../../assets/images/GIF/cart-success.gif')}
                                                style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                                            <View style={{ width: "90%", margin: "auto" }}>
                                                <Text style={{ textAlign: "center", fontWeight: "bold", marginBottom: 40, color: esiColor.DescColor }} >
                                                    {formikSubmitType == 0 ? "Successfully placed your bid." : "Successfully added your item to cart."}
                                                </Text>
                                            </View>
                                        </View>
                                        :
                                        <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center", marginBottom: 100 }}>

                                            <View style={{ width: "90%", margin: "auto" }}>
                                                <ESITextInput
                                                    style={{ width: "100%" }}
                                                    label="Quantity*"
                                                    mode='outlined'
                                                    onChangeText={handleChange("Quantity")}
                                                    onBlur={
                                                        handleBlur
                                                    }
                                                    value={values.Quantity}
                                                    error={Boolean(touched.Quantity && errors.Quantity)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Quantity && errors.Quantity)}>
                                                    {touched.Quantity && errors.Quantity}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Description:</Text>
                                                <EsiTextAreaInput
                                                    error={Boolean(touched.Description && errors.Description)}
                                                    containerStyle={{ height: 170 }}
                                                    style={{ height: 170 }}
                                                    mode='outlined'
                                                    value={values.Description}
                                                    textareavalueUpdate={value => {
                                                        setFieldValue("Description", value)
                                                    }}
                                                    placeholder={'Please provide additional information'}
                                                    underlineColorAndroid={'transparent'}
                                                    theme={{
                                                        colors: {
                                                            primary: "#27b6cc"
                                                        },
                                                    }}
                                                />
                                            </View>
                                            <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                <ESITextInput
                                                    style={{ width: "100%" }}
                                                    label="Pincode*"
                                                    mode='outlined'
                                                    onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                    onBlur={
                                                        handleBlur
                                                    }
                                                    value={values.Pincode}
                                                    error={Boolean(touched.Pincode && errors.Pincode)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                    {touched.Pincode && errors.Pincode}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                <EsiDatePicker
                                                    style={{ margin: "auto" }}
                                                    name={'Bidding_Expiry_Date'}
                                                    label='Bidding Expiry Date'
                                                    value={values.Bidding_Expiry_Date}
                                                    valueUpdate={item => {
                                                        setFieldValue("Bidding_Expiry_Date", item);
                                                    }}
                                                    error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                                                    {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                </HelperText>
                                            </View>

                                            <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                <EsiDatePicker
                                                    style={{ margin: "auto" }}
                                                    name={'Ride_Start_Date'}
                                                    label='Ride Start Date'
                                                    value={values.Ride_Start_Date}
                                                    valueUpdate={item => {
                                                        setFieldValue("Ride_Start_Date", item);
                                                    }}
                                                    error={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}>
                                                    {touched.Ride_Start_Date && errors.Ride_Start_Date}
                                                </HelperText>
                                            </View>

                                            <View style={{ width: "90%", margin: "auto" }}>
                                                <ESITextInput
                                                    style={{ width: "100%" }}
                                                    label="Ride Days *"
                                                    mode='outlined'
                                                    onChangeText={handleChange("No_Of_Days")}
                                                    onBlur={
                                                        handleBlur
                                                    }
                                                    value={values.No_Of_Days}
                                                    error={Boolean(touched.No_Of_Days && errors.No_Of_Days)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.No_Of_Days && errors.No_Of_Days)}>
                                                    {touched.No_Of_Days && errors.No_Of_Days}
                                                </HelperText>
                                            </View>

                                            <View style={{ width: "90%", margin: "auto" }}>
                                                <ESITextInput
                                                    style={{ width: "100%" }}
                                                    label="Ride Distance*"
                                                    mode='outlined'
                                                    onChangeText={handleChange("Distance_In_KMS")}
                                                    onBlur={
                                                        handleBlur
                                                    }
                                                    value={values.Distance_In_KMS}
                                                    error={Boolean(touched.Distance_In_KMS && errors.Distance_In_KMS)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Distance_In_KMS && errors.Distance_In_KMS)}>
                                                    {touched.Distance_In_KMS && errors.Distance_In_KMS}
                                                </HelperText>
                                            </View>
                                            {errorMessage?.length > 0 &&
                                                <View style={{ width: "90%", margin: "auto" }}>
                                                    <HelperText type="error" >
                                                        {errorMessage}
                                                    </HelperText>
                                                </View>
                                            }
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {
                                                    setFormikSubmitType(0);
                                                    handleSubmit()
                                                }}>
                                                    Ask Quotation
                                                </Button>
                                                <Button style={{ marginTop: 15 }} mode="outlined" onPress={() => {
                                                    setFormikSubmitType(1);
                                                    handleSubmit();
                                                    setErrorMessage("");
                                                }}>
                                                    <FontAwesome5 name={"cart-plus"} size={20} color={esiColor.esiMainColor} />
                                                </Button>
                                            </View>
                                        </View>
                                }
                            </View>)}
                    </Formik>

                </ScrollView>
            </Modal>

        </Surface>
    )
}