import React from "react";
import { Image, StyleSheet, TouchableOpacity, View, Dimensions, Linking, ImageBackground, Text, TouchableHighlight } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Surface } from "react-native-paper";
import Header from "../header";
const { height, width } = Dimensions.get('window');
export default function FunctionHalls(props) {
    const { navigation } = props;
    const dataReload = (5 * 60) * 1000;
    const dataErrorReload = 30 * 1000;
    return (
        <Surface style={{ flex: 1, backgroundColor: '#fff' }}>
            <Header {...props} />
            <ScrollView>
                <View style={{
                    width: width,
                    justifyContent: "center",
                    alignItems: 'center',
                    marginBottom: 10
                }}>

                    <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: "center", marginTop: 1 }}>

                        <Text style={{ fontSize: 40, fontWeight: 'bold', color: '#27b6cc' }}>Function Halls</Text>

                        <View style={{marginTop:-30,marginBottom:20}}>
                            <ImageBackground source={require('../../assets/images/GIF/function-halls.gif')} resizeMode="contain" style={{ width: width }} >
                                <View style={{flexDirection:"column",height:400,justifyContent:"center"}} >
                            <Text style={{ fontSize: 24, fontWeight: '900', color: '#46cce0', textAlign: 'center',marginTop:200 }}>Coming soon...</Text>
                            </View>
                                </ImageBackground>
                        </View>
                        
                    </View>
                </View>
            </ScrollView>
        </Surface>

    )

}