import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, Surface, Colors, ActivityIndicator, Button, IconButton, } from 'react-native-paper';
import { View, Dimensions, useWindowDimensions, ScrollView, TouchableOpacity, Platform, FlatList, RefreshControl } from "react-native";
import Header from "../header";
import SignIN from "../auth/signin";
import ESIDealsCard from '../../components/custom/deal-card';
import Modal from "react-native-modal";
import isEmpty from "../../state/validations/is-empty";
import ESIDealModel from "../../components/custom/deal-modal";
import { DrawerActions } from "@react-navigation/native";
import { FontAwesome5 } from "@expo/vector-icons";
import Pagination from "../../components/custom/pagination/pagination";
import { getDealsAction } from "../../state/actions/node-actions/home-actions";
import { useToast } from "../../self_modules/react-native-paper-toast/src";

export default function AllDeals(props: any) {
  const dispatch = useDispatch();
  const { navigation, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const [dealData, setDealData] = React.useState('');
  const [isModalVisible, setModalVisible] = React.useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const esiColor = useSelector(state => state.theme);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

  // data is used for the filter condition for particular deals let deals;

  const data = props?.route?.params;


  //displaying Signin pop up
  const signInModalResponse = () => {
    setModalVisible(!isModalVisible);
  };
  const openModal = (data) => {
    setDealData(data)
    setOpen(true);
  };
  const toggleModal = async (data) => {
    if (isAuthenticated) {
      openModal(data)
    }
    else {
      setModalVisible(!isModalVisible);
    }
  }
  const closeModal = () => {
    setOpen(!open);
    setDealData("")
  };

  // Condition for deals expired before  3 days only will display at UI
  const dealLiveData = (deals: any) => {
    let data = [];
    var currentdate = new Date(),
      date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
    if (deals) {
      if (deals[0]) {
        data = deals.filter((deal: any) => {
          let currentDate = new Date(date);
          let dealExpDate = new Date(deal.End_Date.split("/").reverse().join("-"));
          return Math.floor((currentDate - dealExpDate) / (1000 * 60 * 60 * 24)) < 3
        })
      }
    } else {
      data = []
    }
    return data;
  }

  const [loading, setLoading] = React.useState(false);
  const [deal, setDeal] = React.useState([]);
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "DEAL_ID", SortOrder: "DESC" });
  const callBackGetData = async (status, data, pagination) => {
    setLoading(false);
    if (status) {
      setDeal(data);
      setPageOld(true);
      if (pagination) {
        setPagination(pagination);
      } else {
        setPagination({ PageNo: 1, PageSize: 30, SortBy: "DEAL_ID", SortOrder: "DESC" });
      }
    }
  }

  const goToTop = () => {
    scroll.scrollTo({ x: 0, y: 0, animated: true });
  }
  const [testFill, setestFill] = React.useState({});
  let filters = useSelector((state) => state.filters);
  // Api Calls
  useEffect(() => {
    const data = props?.route?.params;
    let brands = [];
    let isTopBrand = "";
    let categoryId = '';
    let subcategory = [];
    let partners = [];
    let partnersType = '';
    let discountType = '';
    let esiCashbackType = '';
    filters.brands.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      item.children.filter(child => child.checked === 'checked').map(children => {
        brands.push(children.value);
      })
      isTopBrand = item.value === "Top_Brands" ? "1" : "0";
    })
    if (data?.type === "brand") {
      brands.push(data.id);
    }
    filters.partners.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      if (isEmpty(partnersType)) {
        partnersType = item.value
      }
      item.children.filter(child => child.checked === 'checked').map(child => {
        partners.push(child.value);
      })
    })
    if (data?.type === "partner") {
      partners.push(data.id);
    }
    filters.categories.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      categoryId = item.value;
      item.children.filter((item => item.checked === 'checked')).map(item => {
        subcategory.push(item.value);
      })
    })
    const discountFilter = []
    try {
      filters.discount.filter(item => item.checked === 'checked')[0]?.value.split('-').map(item => discountFilter.push(item));
    } catch (error) {
    }
    let formData = {
      Records_Filter: "FILTER"
    }
    if (data?.cityName) {
      formData.City_Name = [data?.cityName];
    }
    if (!isEmpty(brands)) {
      formData.Brand_Id = brands
    }
    if (!isEmpty(partners)) {
      formData.Partner_Details_Id = partners
    }
    if (!isEmpty(categoryId)) {
      formData.Category_Id = categoryId
    }
    if (!isEmpty(subcategory)) {
      formData.Sub_Category_Id = subcategory
    }
    if (!isEmpty(discountFilter.length > 0 ? discountFilter[0] : '')) {
      formData.Min_Discount = discountFilter[0]
    }
    if (!isEmpty(discountFilter.length > 1 ? discountFilter[1] : '')) {
      formData.Max_Discount = discountFilter[1]
    }

    if (filters.cashback[0].checked == 'checked') {
      formData.Extra_Cashback = '1';
    }
    if (filters.main[2].children.filter((item) => item.checked === 'checked').length < 4) {
      if (filters.main[2].children.filter((item) => item.value === 'Is_Top_Deal')[0].checked === 'checked') {
        formData.Is_Top_Deal = '1';
      }
      if (filters.main[2].children.filter((item) => item.value === 'Exclusive')[0].checked === 'checked') {
        formData.Exclusive = '1';
      }
      if (filters.main[2].children.filter((item) => item.value === "Verified_Deal")[0].checked === 'checked') {
        formData.Verified = '1';
      }
      if (filters.main[2].children.filter((item) => item.value === 'Whats_Hot')[0].checked === 'checked') {
        formData.Whats_Hot = '1';
      }
    }
    if (partnersType) {
      formData.Partner_Type = partnersType
    }
    if (String(isTopBrand)) {
      formData.Is_Top_Brand = String(isTopBrand);
    }
    if (discountType) {
      formData.Discount_Type = discountType;
    }
    if (esiCashbackType) {
      formData.Esi_Cashback_Type = esiCashbackType;
    }
    if (!isEmpty(discountFilter.length > 0 ? discountFilter[0] : '')) {
      formData.Min_Esi_Cashback = discountFilter[0]
    }
    if (!isEmpty(discountFilter.length > 1 ? discountFilter[1] : '')) {
      formData.Max_Esi_Cashback = discountFilter[1]
    }
    formData.Status = "Active";
    formData.condition = true;
    if (!(JSON.stringify(testFill) === JSON.stringify(formData))) {
      setestFill(formData);
      dispatch(getDealsAction(formData, pagination, callBackGetData));
    }
  }, [filters, props?.route?.params]);

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      if (!isEmpty(testFill)) {
        setLoading(true);
        goToTop();
        dispatch(getDealsAction(testFill, pagination, callBackGetData));
      }
    }
  }, [pagination])

  const checkChildChecked = (data: any) => {
    let count = 0;
    data?.map((item: any) => {
      if (item.checked === "checked") {
        count++
      }
    })
    return (count === data?.length && data?.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
  }
  // Tost message code.
  const toast = useToast();

  // Tost message code.
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }

  return (
    <Surface style={{ flex: 1, position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : 0, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} isFilter={props.isFilter} />
      <View>

        <View style={{ flexDirection: 'row' }}>
        {props.navigation.canGoBack() &&
          <IconButton
            icon="arrow-left"
            size={27}
            color={"#27B6CC"}
            style={{ marginTop: -2, marginLeft: 1 }}
            onPress={() => {
              props.navigation.goBack()
            }} />
          }
        </View>
        <IconButton
          color={'#27b6cc'}
          icon="filter"
          size={24} style={{ marginTop: -45, alignSelf: 'flex-end' }}
          onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        />
      </View>
      <ScrollView ref={(c) => { scroll = c }}
        refreshControl={
          <RefreshControl
            refreshing={loading}
          />
        }
      >

        <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', marginTop: -5 }}>
          {
            deal.length <= 0 ? (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                <Text style={{color: esiColor.NoDataFound}}>No Deals found!</Text>
              </View>
            ) : (
              <FlatList
                // horizontal={true}
                key={dimensions.width}
                columnWrapperStyle={{ justifyContent: "space-around" }}
                numColumns={(dimensions.width / 250).toFixed() == 1 ? 2 : (dimensions.width / 250).toFixed()}
                data={deal}
                renderItem={({ item, index, separators }) => (
                  <ESIDealsCard data={item} navigation={navigation} toggleModal={toggleModal} />)}
              />
            )
          }
        </View>
        < View style={{ marginBottom: 5 }}>
          {/* <Text> */}
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>
      </ScrollView>
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={isModalVisible}>
        <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
          <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
        </View>
      </Modal>
      {/* <ScrollView> */}
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={open}>
        <View style={{ flex: 1, minWidth: dimensions.width <= 400 ? 350 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 430, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
          <ESIDealModel navigation={navigation} openModal={openModal} data={dealData} closeModal={closeModal} />
        </View >
      </Modal>
      {/* </ScrollView> */}
      {/* ActivityIndicator is user for reloading the page */}
    </Surface>
  );
}