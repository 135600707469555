import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderHTML from "react-native-render-html";
import Modal from "react-native-modal";
import { Avatar, Card, Text, TextInput, Provider, Surface, TouchableRipple, IconButton, Colors, HelperText } from "react-native-paper";
import { View, Button, TouchableOpacity, Image, useWindowDimensions, ScrollView, StyleSheet, Platform, Dimensions, RefreshControl } from "react-native";
import isEmpty from "../../state/validations/is-empty";
import { addPartnerReviewAction, editPartnerReviewAction, getPartnersByIdAction, getUserReviewsbyIdAction } from "../../state/actions/partnerActions";
import Header from "../header";
import SignIN from "../auth/signin";
import axios from 'axios';
import config from "../../state/actions/config";
import { Ionicons } from "@expo/vector-icons";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { getPartnersAction } from "../../state/actions/node-actions/home-actions";
import EStyleSheet from "react-native-extended-stylesheet";
import MeeProducts from "./MeeProducts";
import MeeCoupons from "./MeeCoupons";
import MeeDeals from "./MeeDeals";
import EsiRating from "../../components/custom/Rating/rating";
import ESITextInput from "../../components/custom-fields/textInput";
// api
export default function PartnerDetails(props) {
    const { navigation } = props;
    const toast = useToast();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [visible, setVisible] = useState(10);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [rating, setRating] = useState();
    //comment param is used here to take the value from the Comment text box
    const [comment, setComment] = useState("");
    const [disabled, setDisabled] = useState("")
    const partnerId = props?.route?.params?.Partner_Details_Id;
    let Partner = useSelector((state) => state.allPartners.partner.all);
    const esiColor = useSelector(state => state.theme);
    let partnerReviews = useSelector((state) => state.allPartners.partnerReviews.all);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.auth?.user?.Email_Id);
    //reviewId is used to get the Particular Review ID dynamically
    const [reviewId, setData] = useState()
    //setCommentError param is used here to display validation error message
    const [commenterror, setCommentError] = useState("")
    const [error, setError] = useState("")
    //setRatingError param is used here to display validation error message
    const [ratingerror, setRatingError] = useState("")

    //For add review
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);

    //For edit review
    const [setEditModalVisible] = React.useState(false);
    const [isEditReviewModalVisible, setEditReviewModalVisible] = React.useState(false);
    const PartnerReviews = () => {
        let existcomment;
        let UserData = [];
        if (partnerReviews[0]) {
            existcomment = partnerReviews.filter(review => review.Partner_Email_Id === user).length
            UserData = partnerReviews.filter(review => review.Partner_Email_Id === user ? review : "")
        }
        return [existcomment, UserData]
    }
    const [commentvalidation, uservalidation] = PartnerReviews();
    const countryOption = [
        {
            value: 'IN',
            label: 'India'
        },
        {
            value: 'US',
            label: 'United States'
        },
        {
            value: 'GB',
            label: 'United Kingdom'
        },
        {
            value: 'SG',
            label: 'Singapore'
        },
        {
            value: 'PH',
            label: 'Phillipines'
        },
        {
            value: 'MY',
            label: 'Malaysia'
        },
        {
            value: 'AU',
            label: 'Australia'
        },
        {
            value: 'ID',
            label: 'Indonesia'
        },
        {
            value: 'VN',
            label: 'Vietnam'
        },
        {
            value: 'NP',
            label: 'Nepal'
        },
        {
            value: 'SA',
            label: 'Saudi Arabia'
        }
    ];
    //displaying Signin pop up
    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    //For Add review
    const toggleModal = () => {
        if (isAuthenticated) {
            setReviewModalVisible(!isReviewModalVisible);
        }
        else {
            setModalVisible(true);
        }

    };
    //For edit review
    const editToggleModal = () => {
        if (isAuthenticated) {
            setEditReviewModalVisible(!isEditReviewModalVisible);
        }
        else {
            setEditModalVisible(true);
        }

    };
    const addReviewModal = (formData) => {
        formData.Partner_Email_Id = user;
        formData.Reviewer_Email_Id = user;
        let formValues = JSON.parse(JSON.stringify(formData));
        dispatch(addPartnerReviewAction(formValues, partnerId, toast));
        setReviewModalVisible(false);
    }
    const editReviewModal = () => {
        if (commenterror === "") {
            let editData = {
                Partner_Details_Id: partnerId,
                Partner_Review_Id: reviewId,
                Rating: rating,
                Comments: comment,
                Partner_Email_Id: user,
                Reviewer_Email_Id: user,
                Modified_By: user,
                Status: "Active"
            }

            let formValues = JSON.parse(JSON.stringify(editData));
            dispatch(editPartnerReviewAction(formValues, partnerId));
            toast.show({ message: 'Review updated successfully.', type: 'info', duration: 3000, position: 'top' });
            setEditReviewModalVisible(!isEditReviewModalVisible);
            setComment("")
            setRating("")
        }
    }
    // Comment validations
    const handleOnChange = (value) => {
        if (commentvalidation > 0) {
            setError("You have already added review.")
        }
        if (value === "") {
            setError("")
        }
    }
    // Edit Onchanges
    const editOnChange = (value) => {
        if (value === "") {
            setCommentError("Review is required.")
        } else {
            setCommentError("")
        }
    }
    const [partnerdata, setPartnerData] = useState();
    const callBackGetPartnerData = (status, Partner) => {
        if (status) {
            setPartnerData(Partner[0]);
        }
    };
    useEffect(() => {
        onRefresh();
    }, [partnerId]);


    const onRefresh = () => {
        setVisible(10);
        dispatch(getPartnersAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Partner_Details_Id": partnerId
        },
            { PageNo: 1, PageSize: 10, SortBy: "PARTNER_DETAILS_ID", SortOrder: "ASC" }
            , callBackGetPartnerData));
        dispatch(getUserReviewsbyIdAction(partnerId));
    };
    // Tost message code.
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    return (
        <Surface theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{ flex: 1 }}>
            <Header {...props} />
            <View style={{ flexDirection: "column", width: "100%" }} >
                <View style={{ flexDirection: "row", marginHorizontal: '2%' }} >
                    <TouchableOpacity style={{ flex: 3, marginLeft: 8, alignSelf: "flex-start" }} onPress={() => navigation.navigate('Partners')}>
                        <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
                    </TouchableOpacity>
                    <View style={{ zIndex: 1, flex: 3 }}>
                        {partnerdata?.Is_Open != "1" &&
                            <Text style={{ fontSize: 14, paddingHorizontal: 10, paddingVertical: 5, backgroundColor: '#DC143C', borderRadius: 5, color: 'white', marginTop: 10, marginVertical: 5, fontWeight: "bold", alignSelf: "center" }}> Store Closed</Text>
                        }
                    </View>
                    <View style={{ flex: 3 }}>
                        <EsiRating
                            readonly={true}
                            rating={partnerdata?.Esi_Rating ? parseInt(partnerdata?.Esi_Rating) : 0}
                            type='heart'
                            // ratingBackgroundColor='#c8c7c8'
                            tintColor={esiColor.TintColor}
                            startingValue={partnerdata?.Esi_Rating ? parseInt(partnerdata?.Esi_Rating) : 0}
                            ratingCount={5}
                            imageSize={20}
                            style={{ paddingVertical: 5, alignSelf: "flex-end", marginTop: 8, marginRight: 5 }}
                            minValue={1}
                            onFinishRating={(rating) => ratingCompleted(rating)}
                            name="rating"
                        />
                    </View>
                </View>
            </View>
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={onRefresh}
                    />
                }
                style={{ marginHorizontal: '2%' }}>

                {/* <Provider> */}
                <Card theme={{
                    colors: {
                        surface: esiColor.BackgroundColor,
                    }
                }} style={{}} >
                    <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }} >
                            <Image source={{ cache: "force-cache", uri: partnerdata?.Logo_Path + `?w=${dimensions.width * 0.9}&h=150` }} style={{ maxHeight: 150, minHeight: 100, width: dimensions.width * 0.9, borderRadius: 8 }} resizeMode={'stretch'}></Image>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 2 }} >
                            <Text style={{ fontSize: 20, color: esiColor.brandFontColor }}>{partnerdata?.Name}</Text>
                        </View>
                    </View>

                    <View style={{ flex: 1, marginLeft: '3%', marginRight: '3%', marginVertical: 5 }}>
                        {partnerdata?.Short_Description ? <View style={{ flexWrap: "wrap", width: width * 0.85, padding: 5, backgroundColor: esiColor.RTBC, borderRadius: 8 }} >
                            <RenderHTML
                                source={{ html: partnerdata?.Short_Description }} /></View>
                            : <></>
                        }
                    </View>
                    <View>
                        <View style={{ marginLeft: '3%', flex: 1, marginRight: '3%' }}>
                            {partnerdata?.Description ? <View style={{ flexWrap: "wrap", width: width * 0.85, padding: 5, backgroundColor: esiColor.RTBC, borderRadius: 8 }} >
                                <RenderHTML
                                    source={{ html: partnerdata?.Description }} /></View>
                                : <></>}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginVertical: 5 }}>
                        <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                            <Text style={{ color: esiColor.DescColor }}>GST: </Text>{!isEmpty(partnerdata?.Gst) ? <Text style={{ color: esiColor.itemColor }}>
                                {partnerdata?.Gst.substring(0, 2) + "*********" + partnerdata?.Gst.substring(partnerdata?.Gst.length - 2, partnerdata?.Gst.length)}
                            </Text>
                                : <Text style={{ color: esiColor.itemColor }}>**************</Text>}
                        </View>
                        <View style={{ flexDirection: 'row', marginLeft: 15 }}>
                            <Text style={{ color: esiColor.DescColor }}>PAN: </Text>{!isEmpty(partnerdata?.Pan) ?
                                <Text style={{ color: esiColor.itemColor }}>
                                    {partnerdata?.Pan.substring(0, 2) + "*********" + partnerdata?.Pan.substring(partnerdata?.Pan.length - 2, partnerdata?.Pan.length)}
                                </Text>
                                : <Text style={{ color: esiColor.itemColor }}>**************</Text>}
                        </View>
                    </View>

                </Card>


                <Card theme={{
                    colors: {
                        surface: esiColor.BackgroundColor,
                    }
                }} style={{
                    marginVertical: 5, shadowColor: esiColor.brandShadowColor, borderColor: esiColor.CBColor, borderWidth: 0.5,
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.2,
                    shadowRadius: 10,
                }} >
                    <View style={{ flexDirection: 'row', justifyContent: "space-around", zIndex: 100, marginVertical: 10 }}>
                        <View style={{ marginRight: 10 }}>
                            <TouchableOpacity disabled={partnerdata?.Deal_Count == '0'} onPress={(e) => {
                                // navigate function is used to redirect to particular deal Details.
                                props.navigation.navigate('Main', { screen: 'All', params: { id: partnerdata.Partner_Details_Id, type: "partner", page: 'deals', filter: 'all' } });

                            }}>
                                <Text style={{ color: EStyleSheet.value(partnerdata?.Deal_Count == '0' ? 'red' : '#27b6cc'), }}>{partnerdata?.Deal_Count} {partnerdata?.Deal_Count == '1' || partnerdata?.Deal_Count == '0' ? 'Deal' : 'Deals'}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ marginRight: 10 }}>
                            <TouchableOpacity disabled={partnerdata?.Coupon_Count == '0'} onPress={(e) => {
                                // navigate function is used to redirect to particular coupon Details.
                                props.navigation.navigate('Main', { screen: 'All', params: { id: partnerdata.Partner_Details_Id, type: "partner", page: 'coupons', filter: 'all' } });
                            }}>
                                <Text style={{ color: EStyleSheet.value(partnerdata?.Coupon_Count == '0' ? 'red' : '#27b6cc') }}>{partnerdata?.Coupon_Count} {partnerdata?.Coupon_Count == '1' || partnerdata?.Coupon_Count == '0' ? 'Coupon' : 'Coupons'}</Text>
                            </TouchableOpacity>
                        </View>
                        <View>
                            <TouchableOpacity disabled={partnerdata?.Product_Count == '0'} onPress={(e) => {

                                props.navigation.navigate('PartnerProducts', { Partner_Details_Id: partnerId, back: "PartnerDetails" });

                                // props.navigation.navigate('Products', { id: item.Partner_Details_Id, type: "Partner" });
                            }}>
                                <Text style={{ color: EStyleSheet.value(partnerdata?.Product_Count == '0' ? 'red' : '#27b6cc') }}>{partnerdata?.Product_Count} {partnerdata?.Product_Count == '1' || partnerdata?.Product_Count == '0' ? 'Product' : 'Products'}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                </Card>
                {partnerdata?.Product_Count != '0' &&
                    <View style={{ marginVertical: 5 }}>
                        <MeeProducts navigation={props.navigation} partnerId={partnerdata?.Partner_Details_Id} />
                    </View>}
                {partnerdata?.Coupon_Count != '0' &&
                    <View style={{ marginVertical: 2 }}>
                        <MeeCoupons navigation={props.navigation} partnerId={partnerdata?.Partner_Details_Id} />
                    </View>}
                {partnerdata?.Deal_Count != '0' &&
                    <View style={{ marginVertical: 5 }}>
                        <MeeDeals navigation={props.navigation} partnerId={partnerdata?.Partner_Details_Id} />
                    </View>}

                <View style={{ fontSize: 20, width: 100, alignSelf: 'center', marginBottom: 10 }}>
                    <TouchableOpacity style={[styles.centerElement, { backgroundColor: commentvalidation > 0 ? 'grey' : esiColor.globalButtonColor, width: 100, height: 30, borderRadius: 5 }]}
                        onPress={() => toggleModal()} disabled={commentvalidation > 0 ? true : false}>
                        <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Add Review</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.container}>
                    <Image style={styles.image} source={require("../../assets/images/img/logo/meePaisa_logo.png")} />
                    <View style={styles.content}>
                        <View style={styles.contentHeader}>
                            <Text style={styles.name}>meepaisa Review</Text>
                            <View style={{ marginTop: -8 }}>
                                <EsiRating
                                    rating={partnerdata?.Esi_Rating ? (parseInt(partnerdata.Esi_Rating)) : (0)}
                                    numberOfStars={5}
                                    ratingCount={5}
                                    imageSize={20}
                                    style={{ paddingVertical: 10 }}
                                    readonly={true}
                                    startingValue={partnerdata?.Esi_Rating}
                                    type='heart'
                                    // ratingBackgroundColor='#c8c7c8'
                                    tintColor={esiColor.TintColor}
                                    onFinishRating={(rating) => ratingCompleted(rating)} />
                            </View>
                        </View>
                        <View style={{ flexWrap: "wrap", flex: 1, backgroundColor: esiColor.RTBC, borderRadius: 8 }}>
                            {partnerdata?.Esi_Review ?
                                <RenderHTML source={{ html: partnerdata?.Esi_Review }} /> : <></>}
                        </View>
                    </View>
                </View >
                <View>
                    {!isEmpty(partnerReviews) && (
                        partnerReviews.Is_Data_Exist === "0" || isEmpty(partnerReviews) ? (
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <Text style={{ color: esiColor.NoDataFound }}>No Partner Reviews found!</Text>
                            </View>
                        ) : typeof partnerReviews === "object" && (
                            <>
                                {partnerReviews.slice(0, visible).map((data, index) => (
                                    <View key={index}>
                                        <View />
                                        <View style={styles.containerm}>
                                            <TouchableOpacity>
                                                {data?.Profile_Pic_Path ?
                                                    <Image style={styles.image} source={{ cache: "force-cache", uri: data.Profile_Pic_Path + `?w=45&h=45` }} /> :
                                                    <Image style={styles.image} source={require("../../assets/images/img/logo/meePaisa_logo.png")} />}

                                            </TouchableOpacity>
                                            <View style={styles.content}>
                                                <View style={styles.contentHeader}>
                                                    <Text style={styles.name}>{data.Full_Name}
                                                    </Text>
                                                    <View style={{}}>
                                                        {user && user === data?.Reviewer_Email_Id ? (
                                                            <IconButton icon="pencil" color={esiColor.SIconColor} style={{ marginTop: -4 }} size={20} onPress={() => { editToggleModal(); setRating(parseInt(data.Rating)); setComment(data.Comments); setData(data.Partner_Review_Id); }} />
                                                        ) : (null)}
                                                    </View>
                                                    <View style={{ marginTop: -8 }}>
                                                        <EsiRating
                                                            readonly={true}
                                                            type='heart'
                                                            startingValue={data?.Rating}
                                                            tintColor={esiColor.TintColor}
                                                            ratingCount={5}
                                                            imageSize={20}
                                                            onFinishRating={(rating) => ratingCompleted(rating)}
                                                            style={{ paddingVertical: 10 }}
                                                        />

                                                    </View>
                                                </View>
                                                <View style={{ flexWrap: "wrap", flex: 1 }}>
                                                    {data.Comments ? <Text style={{ color: esiColor.Text }}>{data.Comments}</Text> : <></>
                                                    }
                                                </View>
                                            </View>
                                        </View>

                                    </View>
                                ))}
                                <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                                    {partnerReviews?.length > visible &&
                                        <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: '#27b6cc' }} onPress={() => setVisible(visible + 10)}>Show More ▼</Text>
                                    }
                                </View>
                            </>
                        ))}
                </View>
                {/* </Provider> */}
            </ScrollView>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            {/* For Add Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => setReviewModalVisible(false)}>
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <PartnerAddRiview responseFunction={addReviewModal} partnerId={partnerId} />
                </View>
            </Modal>
            {/* For Edit Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isEditReviewModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setEditReviewModalVisible(false), setComment(''), setRating(0) }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <ESITextInput
                                label='Edit Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); editOnChange(Text) }}
                                multiline={true}
                                numberOfLines={4}
                                value={comment}
                                style={{ maxHeight: 100, minWidth: 250, maxWidth: 250, marginHorizontal: "auto" }}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <EsiRating
                                type='heart'
                                tintColor={esiColor.TintColor}
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value) }}
                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red", fontSize: 16 }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }
                            <View style={{ width: 100 }}>
                                <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5 }]}
                                    onPress={() => editReviewModal()} >
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </Surface>
    );
}

const PartnerAddRiview = ({ partnerId, responseFunction }) => {
    const esiColor = useSelector(state => state.theme);

    const [error, setError] = useState("");
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState(false);
    const [comment, setComment] = useState("");
    const [commenterror, setCommentError] = useState("");
    // Comment validations
    const handleOnChange = (value) => {
        setComment(value);
        if (value) {
            if (value.length < 10) {
                setCommentError("Review should be greater than 10 characters.");
            } else {
                setCommentError("");
            }
        } else {
            setCommentError("Review is required.");
        }
    }

    const addReviewModal = () => {
        let error = false;
        if (comment === "") {
            setCommentError("Review is required.");
            error = true;
        } else {
            if (comment.length < 10) {
                setCommentError("Review should be greterthen ten.");
                error = true;
            } else {
                setCommentError("");
            }
        }
        if (rating <= 0) {
            setRatingError(true);
            error = true;
        } else {
            setRatingError(false);
        }


        if (!error) {
            let formData = {
                Partner_Details_Id: partnerId,
                Rating: rating,
                Comments: comment,
                Status: "Active"
            }
            responseFunction(formData);
        }
    }

    return (
        <View style={{ margin: '4%' }}>
            <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <ESITextInput
                    label='Add Review'
                    mode='outlined'
                    onChangeText={(Text) => { handleOnChange(Text); }}
                    multiline={true}
                    numberOfLines={4}
                    value={comment}
                    style={{ maxHeight: 100, minWidth: 250, maxWidth: 250, marginHorizontal: "auto" }}
                />
            </View>
            <HelperText>
                {commenterror &&
                    <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                }
            </HelperText>
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center'
            }}>
                <EsiRating
                    type='heart'
                    tintColor={esiColor.TintColor}
                    startingValue={rating || 0}
                    ratingCount={5}
                    imageSize={30}
                    style={{ paddingVertical: 10 }}
                    onFinishRating={(value) => { setRating(value); setRatingError(false) }}
                />
                <HelperText>
                    {ratingError &&
                        <Text style={{ color: "red" }}>
                            Please select the Rating.
                        </Text>
                    }
                </HelperText>
                <View style={{ width: 100 }}>
                    <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5 }]} onPress={() => addReviewModal()}>
                        <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}



const styles = StyleSheet.create({
    // root: {
    //     backgroundColor: "#ffffff",
    //     // marginTop: 80
    //     flex: 1
    // },
    container: {
        paddingLeft: 15,
        paddingVertical: 10,
        marginRight: 15,
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    content: {
        marginLeft: 16,
        flex: 1,
    },
    contentHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    // separator: {
    //     height: 1,
    //     backgroundColor: "#CCCCCC"
    // },
    image: {
        width: 45,
        height: 45,
        borderRadius: 20,
    },
    // time: {
    //     fontSize: 11,
    //     color: "#808080",
    // },
    name: {
        fontSize: 16,
        fontWeight: "bold",
        color: "#27b6cc"
    },
    // container1: {
    //     flex: 2,
    //     padding: 10,
    // },
    // containerStyle:
    // {
    //     backgroundColor: 'white',
    //     padding: 20,
    //     borderColor: 'black',
    //     borderWidth: 1,
    // },
    containerm: {
        paddingLeft: 19,
        paddingRight: 16,
        paddingVertical: 12,
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginLeft: 1
    },
    centerElement: {
        justifyContent: 'center',
        alignItems: 'center'
    },
}); 
