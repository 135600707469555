import { Dimensions, Image, Platform, RefreshControl, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Avatar, Button, Checkbox, Colors, HelperText, IconButton, Surface, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../header";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getBiddingPartnerProductDetails } from "../../../../state/actions/node-actions/bid-products-actions";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { placeCommentToBids, updateProductPayment, updateUserBiddingProduct } from "../../../../state/actions/boomi-actions/bid-products-actions";
import razorpayPay from "../../../../hooks/razorpay";
import useRazorpay from "react-razorpay";
import { getUserSettings, getWalletByUser } from "../../../../state/actions/walletActions";
import Review from "./Review";
import Modal from "react-native-modal";
import { EsiAutocompleteDropdown } from "../../../../components/custom/DropDowns/AutoCompleteDropDown1";
import { getAllCartAddressAction } from "../../../../state/actions/addressActions";
import { useFocusEffect } from "@react-navigation/native";


export default function BidPartnerProductDetails(props) {
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
    const esiColor = useSelector(state => state.theme);
    const dimensions = Dimensions.get('window');
    const { height, width } = dimensions;
    const { navigation } = props;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);


    const [openAddress, setOpenAddress] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [cancelMessage, setCancelMessage] = useState("");

    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isConfirmError, setIsConfirmError] = useState("");


    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }

    const payNow = () => {
        let amount = (Math.round(finalAdvanceOnlineAmount() + Number.EPSILON));
        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    updatePaymentDetails("RAZORPAY", response.razorpay_payment_id, amount);
                } else {

                }
            });
        } else {
            updatePaymentDetails("WALLET", "", amount);
        }
    }

    const updatePaymentDetails = (type, transactionId, amount) => {
        let formData = {
            "Advance_Online_Amount": type == "RAZORPAY" ? amount : 0,
            "Advance_Online_Transaction_Id": transactionId,
            "Advance_Wallet_Amount": (useWallet ? walletPaybleAmount() : 0),
            "Finalize_Partner_Bidding_Quote_Id": Bidding_Id,
            "Modified_By": EmailID,
            "User_Address_Id": deliveryAddress,

            "Total_Advance_Amount": getAdvanceAmount,
            "User_Products_Bidding_Id": product?.User_Products_Bidding_Id
        }
        setLoading(true);
        dispatch(updateProductPayment(formData, paymentCallBack));
    }


    const payNowFinal = () => {
        let amount = (Math.round(finalOnlineAmount() + Number.EPSILON));

        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    updateFinalPaymentDetails("RAZORPAY", response.razorpay_payment_id, amount);
                } else {

                }
            });
        } else {
            updateFinalPaymentDetails("WALLET", "", amount);
        }
    }

    const updateFinalPaymentDetails = (type, transactionId, amount) => {
        let formData = {
            "Modified_By": EmailID,
            "User_Products_Bidding_Id": product?.User_Products_Bidding_Id,
            "Wallet_Amount": (useWallet ? walletFinalPaybleAmount() : 0),
            "Online_Amount": type == "RAZORPAY" ? amount : 0,
            "Online_Transaction_Id": transactionId,
            "Final_Payment_Status_From_User": 1
        }
        setLoading(true);
        dispatch(updateProductPayment(formData, paymentCallBack));
    }

    const paymentCallBack = (status, data) => {
        setLoading(false);
        getProductDetails();
    }

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [addressD, setAddressD] = useState();
    const [partnerProduct, setPartnerProduct] = useState({});
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(false);
    const [commentLoading, setCommentLoading] = useState(false);

    const submitComment = () => {
        setCommentError(false);
        if (comment) {
            if (comment.length < 20) {
                setCommentError(true);
            } else {
                setCommentLoading(true);
                let formData = {
                    "Commented_By": "USER",
                    "Comments": comment,
                    "Created_By": EmailID,
                    "Partner_Bidding_Product_Quote_Id": Bidding_Id
                }
                dispatch(placeCommentToBids(formData, placeCallbackResponse));
            }
        } else {
            setCommentError(true);
        }
    }

    const placeCallbackResponse = (status: boolean) => {
        setCommentLoading(false);
        if (status) {
            setComment("");
            getProductDetails();
        }
    }

    const [wallet, setWallet] = useState(null);
    const [maxWalletUsage, setMaxWalletUsage] = useState(50);
    const [useWallet, setUseWallet] = useState(false);


    const updateStatesWallet = (data: any, loading: any) => {
        let add = JSON.parse(JSON.stringify(data));
        setWallet(add?.Wallet[0]);
    };

    const walletPaybleAmount = () => {
        let walletPayable = getAdvanceAmount * (maxWalletUsage * 0.01);
        let payable = walletPayable;
        if (walletPayable > Number(wallet?.Remaining_Balance || 0)) {
            payable = Number(wallet?.Remaining_Balance || 0);
        }
        if (product?.Finalize_Partner_Bidding_Quote_Id) {
            payable = 0;
        }
        return payable
    }

    const walletFinalPaybleAmount = () => {
        let walletPayable = (product?.Remaining_Amount_After_Advance_Amount || 0) * (maxWalletUsage * 0.01);
        let payable = walletPayable;
        if (walletPayable > Number(wallet?.Remaining_Balance || 0)) {
            payable = Number(wallet?.Remaining_Balance || 0);
        }
        if (product?.Payment_Mode != 'COD') {
            payable = 0;
        }
        return payable
    }

    const finalAdvanceOnlineAmount = () => {
        return getAdvanceAmount - (useWallet ? walletPaybleAmount() : 0);
    }

    const finalOnlineAmount = () => {
        return (product?.Remaining_Amount_After_Advance_Amount || 0) - (useWallet ? walletFinalPaybleAmount() : 0);
    }

    const getProductDetails = () => {
        if (Bidding_Id) {
            setLoading(true);
            dispatch(getBiddingPartnerProductDetails({ Partner_Bidding_Quote_Id: Bidding_Id }, getCallback));
        }
        if (user?.Email_Id) {
            let data = {
                User_Id: user.Email_Id,
                Wallet_Id: "",
            };
            dispatch(getWalletByUser(updateStatesWallet, data));
            dispatch(getUserSettings("ORDER_MAX_WALLET_USAGE_P", setMaxWalletUsage));

        }
    }

    const getAdvanceAmount = useMemo(() => {
        let advance = 0;
        return advance + (partnerProduct?.Final_Advance_Token_Amount || 0)
    }, [product, partnerProduct])

    const getCallback = (status, data) => {
        setLoading(false);
        if (status) {
            setProduct(data.User_Bid_Product);
            setPartnerProduct(data.Partnerbids);
            setComments(data.Comments);
            setAddressD(data?.Address);
        }
    }
    const Bidding_Id = props?.route?.params?.id;
    useEffect(() => {
        getProductDetails();
    }, [props?.route?.params, isConfirmLoading])


    const getAddress = () => {
        let data = {
            User_Email_Id: user.Email_Id,
            Records_Filter: "FILTER",
        };
        dispatch(getAllCartAddressAction(updateStates, data));
    }
    const [address, setAddress] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState("");

    const updateStates = (data: any, loading: any) => {
        let add = (data ? data : []).map((item => ({ id: item.User_Address_Id, title: item?.Address_Type + " " + "," + " " + item?.Door_Number + " " + "," + " " + item?.Landmark + " " + "," + " " + item?.Street + " " + "," + " " + item?.City + " " + "," + " " + item?.State + " " + "," + " " + item?.Zip, })));
        add.push({ id: "Add New Address", title: "Add New Address" })
        setAddress(add);
    };

    useFocusEffect(
        useCallback(() => {
            setCancelMessage("");
        }, [])
    );

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ flexDirection: 'row', position: "relative", }} >

                <IconButton
                    icon="arrow-left"
                    size={27}
                    color={"#27B6CC"}
                    style={{ marginTop: -2, marginLeft: 1 }}
                    onPress={() => {
                        if (navigation.canGoBack())
                            props.navigation.goBack()
                        else
                            props.navigation.navigate("BidProducts")
                    }}
                />
                {product?.Finalize_Partner_Bidding_Quote_Id &&
                    <TouchableOpacity
                        style={{ position: "absolute", right: 5, top: 5, backgroundColor: esiColor.globalButtonColor, borderRadius: 5, height: 25, marginHorizontal: 10, width: '40%' }}
                        onPress={() => {
                            navigation.navigate('BidProductDetails', { id: product?.User_Products_Bidding_Id });
                        }}
                    >
                        <Text style={{ fontWeight: '500', color: esiColor.itemButtenColor, marginTop: 2, marginLeft: 10 }} >Show remaining quotes</Text>
                    </TouchableOpacity>}
            </View>
            <ScrollView style={{ maxHeight: dimensions.height - 20, }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => getProductDetails}
                    />
                }
            >
                <View>
                    {product?.Product_Image &&
                        <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
                            <Image
                                source={{ cache: "force-cache", uri: product?.Product_Image }}
                                style={{
                                    height: 200, maxHeight: 400,

                                    width: (width > height) ? height : width - 20, resizeMode: 'contain', borderRadius: 10, shadowRadius: 2, backgroundColor: "#efefef"
                                }}>
                            </Image>
                        </View>}
                    <View style={{ marginVertical: 5 }} >
                        <Text style={{ textAlign: 'center', color: esiColor.brandFontColor, fontWeight: 'bold' }}>{product?.Product_Name}</Text>
                    </View>
                    <View style={{ paddingHorizontal: 10 }} >
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Quantity </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {product?.Quantity} ({product?.Measurement_Type})</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Status </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {product?.Bidding_Status}</Text>
                                {(product?.Bidding_Status != "COMPLETED" && product?.Bidding_Status != "CANCELLED") &&
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmScreen(true);
                                    }} style={{ borderColor: esiColor.esiMainColor, backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Cancel </Text>
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Pincode </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {product?.User_Pincode}</Text>
                            </View>
                        </View>
                        {partnerProduct?.Delivery_Fee > 0 && <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Delivery fees </Text>

                            </View>
                            <View style={{ flex: 6, flexDirection: "column" }} >
                                <Text style={{ color: esiColor.itemColor }}>: ₹ {partnerProduct?.Delivery_Fee} </Text>
                            </View>
                        </View>}
                        {product?.Advance_Payment_Status_From_User == 1 &&
                            <View style={{ flexDirection: "column" }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: 'bold' }}> Advance Amount </Text>
                                {product?.Advance_Wallet_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Wallet Payment  </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Advance_Wallet_Amount} </Text>
                                        </View>
                                    </View>}
                                {product?.Advance_Online_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Online Payment </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Advance_Online_Amount} </Text>
                                        </View>
                                    </View>}
                                {product?.Advance_Online_Transaction_Id &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Transaction ID </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: {product?.Advance_Online_Transaction_Id} </Text>
                                        </View>
                                    </View>
                                }

                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Total Advance </Text>

                                    </View>
                                    <View style={{ flex: 6, flexDirection: "column" }} >
                                        <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Total_Advance_Amount} </Text>
                                    </View>
                                </View>
                            </View>
                        }

                        {product?.Final_Payment_Status_From_User == 1 &&
                            <View style={{ flexDirection: "column" }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: 'bold' }}> Payment Summarry </Text>
                                {product?.Wallet_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Wallet Payment  </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Wallet_Amount} </Text>
                                        </View>
                                    </View>}
                                {product?.Online_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Online Payment </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Online_Amount} </Text>
                                        </View>
                                    </View>}
                                {product?.Online_Transaction_Id &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Transaction ID </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: {product?.Online_Transaction_Id} </Text>
                                        </View>
                                    </View>
                                }

                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Total Advance </Text>

                                    </View>
                                    <View style={{ flex: 6, flexDirection: "column" }} >
                                        <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Total_Advance_Amount} </Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Total Payed </Text>

                                    </View>
                                    <View style={{ flex: 6, flexDirection: "column" }} >
                                        <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Total_Advance_Amount + product?.Remaining_Amount_After_Advance_Amount} </Text>
                                    </View>
                                </View>
                            </View>
                        }
                        {product?.Advance_Payment_Status_From_User != 1 ?
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Advance Amount </Text>

                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Total_Advance_Amount || getAdvanceAmount} </Text>

                                    {(walletPaybleAmount() > 0) &&
                                        <View>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    setUseWallet(!useWallet);
                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: "row",
                                                    // marginTop: -10
                                                }}>
                                                    <Checkbox.Android
                                                        status={useWallet ? 'checked' : 'unchecked'}
                                                    />
                                                    <Text style={{
                                                        marginTop: 8, color: esiColor.itemColor
                                                    }}>{"Use Wallet Amount ₹ " + walletPaybleAmount()}</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    {useWallet &&
                                        <View>
                                            <Text style={{
                                            }}>{"Remaining amount after wallet amount ₹ " + finalAdvanceOnlineAmount()}</Text>
                                        </View>
                                    }
                                    {(!product?.Finalize_Partner_Bidding_Quote_Id && product?.Bidding_Status != "CANCELLED") &&

                                        <View>
                                            <View>
                                                <TouchableOpacity disabled={commentLoading} onPress={() => {
                                                    setDeliveryAddress("");
                                                    setOpenAddress(state => !state);
                                                    getAddress();
                                                }} style={{ borderColor: esiColor.esiMainColor, borderWidth: 1, borderRadius: 8, width: 50 }} >
                                                    <Text style={{ color: esiColor.esiMainColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > PAY </Text>
                                                </TouchableOpacity>
                                            </View>
                                            <Text style={{ fontWeight: "500", color: esiColor.DescColor }}>Pay Advance amount to lock this offer.</Text>
                                        </View>
                                    }
                                </View>
                            </View>
                            : product?.Final_Payment_Status_From_User != 1 &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Remaining Amount </Text>
                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>: ₹ {product?.Remaining_Amount_After_Advance_Amount || 0} </Text>
                                    {(walletFinalPaybleAmount() > 0) &&
                                        <View>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    setUseWallet(!useWallet);
                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: "row",
                                                    // marginTop: -10
                                                }}>
                                                    <Checkbox.Android
                                                        status={useWallet ? 'checked' : 'unchecked'}
                                                    />
                                                    <Text style={{
                                                        marginTop: 8, color: esiColor.itemColor
                                                    }}>{"Use Wallet Amount ₹ " + walletFinalPaybleAmount()}</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    {useWallet &&
                                        <View>
                                            <Text style={{
                                                color: esiColor.itemColor
                                            }}>{"Remaining amount after wallet amount ₹ " + finalOnlineAmount()}</Text>
                                        </View>
                                    }
                                    {(product?.Payment_Mode != "COD" && product?.Final_Payment_Status_From_User == 0 && product?.Bidding_Status != "CANCELLED") &&

                                        <View>
                                            <View>
                                                <TouchableOpacity disabled={commentLoading} onPress={payNowFinal} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 50 }} >
                                                    <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > PAY </Text>
                                                </TouchableOpacity>
                                            </View>
                                            <Text style={{ fontWeight: "500", color: esiColor.DescColor }}>Pay remaining amount to get meepaisa support.</Text>
                                        </View>
                                    }
                                </View>
                            </View>}

                        {product?.Payment_Mode &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Payment Mode </Text>
                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>:{product?.Payment_Mode} </Text>
                                </View>
                            </View>}
                        <View style={{ flexDirection: "column" }} >
                            <Text style={{ fontWeight: "bold", color: esiColor.DescColor, textAlign: "center" }}>Description</Text>
                            <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }}>{product?.Description} </Text>
                        </View>
                        {product?.Bidding_Status == "COMPLETED" &&
                            <Review partnerProduct={partnerProduct} product={product} dimensions={dimensions} getProductDetails={getProductDetails} dispatch={dispatch} />
                        }
                    </View>
                    {addressD &&
                        <View style={{
                            paddingHorizontal: 10
                        }}>

                            <View style={{
                                shadowColor: esiColor.brandShadowColor,
                                // shadowOffset: { width: -2, height: 4 },
                                // shadowOpacity: 5,
                                // shadowRadius: 7,
                                borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10),
                                alignItems: 'flex-start',
                                elevation: 10,
                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                maxHeight: height > 250 ? 250 : 90,
                                minHeight: 100,
                                // borderColor: 'grey',
                                borderWidth: 0.5,
                                flexDirection: 'column'

                            }}  >
                                <View style={{
                                    marginHorizontal: 10
                                }}>
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.Text, marginBottom: 5 }}>Delivery Address </Text>
                                    <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{addressD?.Name},</Text>
                                    <Text style={{ fontSize: 13, color: esiColor.itemColor }}>D-No : {addressD?.Door_No},{addressD?.Street}, {addressD?.Land_Mark},</Text>
                                    <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{addressD?.City},{addressD?.State},{addressD?.Country},</Text>
                                    <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{addressD?.City}-{addressD?.Zip},</Text>
                                    <Text style={{ fontSize: 13, marginBottom: 10, color: esiColor.itemColor }}>Phone number : {addressD?.Mobile}.  </Text>
                                </View>
                            </View>

                        </View>
                    }
                    <View style={{ paddingHorizontal: 10 }} >
                        <View style={{
                            shadowColor: esiColor.brandShadowColor,
                            // // shadowOffset: { width: -2, height: 4 },
                            // shadowOpacity: 5,
                            // shadowRadius: 7,
                            borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                            alignItems: 'flex-start',
                            elevation: 10,
                            maxWidth: (Platform.OS === 'web' ? 650 : 550),
                            minHeight: 100,
                            // borderColor: 'grey',
                            borderWidth: 0.5,
                        }}>
                            <View style={{ flex: 1, alignContent: 'center', marginLeft: 10, marginVertical: 5 }} >
                                <Image source={{ cache: "force-cache", uri: partnerProduct?.Logo_Path }} style={{ borderRadius: 8, height: 100, width: 80, resizeMode: 'contain', backgroundColor: "#efefef" }}></Image>
                            </View>
                            <View style={{ flex: 2, flexDirection: 'column' }}  >
                                <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{partnerProduct?.Name}</Text>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={{ flex: 4 }}  >
                                        <Text style={{ color: esiColor.DescColor }}>Amount </Text>

                                    </View>
                                    <View style={{ flex: 6 }}  >
                                        <Text style={{ color: esiColor.itemColor }} >: {partnerProduct?.Quotation_Amount}</Text>

                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={{ flex: 4 }}  >
                                        <Text style={{ color: esiColor.DescColor }}>Delivery Days </Text>

                                    </View>
                                    <View style={{ flex: 6 }}  >
                                        <Text style={{ color: esiColor.itemColor }}>: {partnerProduct?.No_Of_Delivery_Days}</Text>

                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={{ flex: 4 }}  >
                                        <Text style={{ color: esiColor.DescColor }} >Location </Text>

                                    </View>
                                    <View style={{ flex: 6 }}  >
                                        <Text style={{ color: esiColor.itemColor }} >: {partnerProduct?.Partner_Location}</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={{ flex: 4 }}  >
                                        <Text style={{ color: esiColor.DescColor }} >Delivery Date </Text>

                                    </View>
                                    <View style={{ flex: 6 }}  >
                                        <Text style={{ color: esiColor.itemColor }}>: {partnerProduct?.Estimated_Delivery_Date}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View>
                            <Text style={{ fontWeight: "bold", color: esiColor.Text, margin: 4 }} >Partner Info : </Text>
                            <Text style={{ flexWrap: "wrap", margin: 8, color: esiColor.itemColor }} >{partnerProduct?.Partner_Comments}</Text>
                        </View>
                    </View>
                    <View style={{ paddingHorizontal: 10 }} >
                        <Text style={{ fontWeight: "bold", color: esiColor.DescColor, textAlign: "center", paddingVertical: 2 }}>Comments</Text>
                        <View style={{}} >
                            <EsiTextAreaInput
                                // error={Boolean(touched.Description && errors.Description)}
                                style={{ height: 60 }}
                                mode='outlined'
                                value={comment}
                                textareavalueUpdate={value => {
                                    setComment(value)
                                }}
                                placeholder={'Please provide comment'}
                                underlineColorAndroid={'transparent'}
                                theme={{
                                    colors: {
                                        primary: "#27b6cc"
                                    },
                                    roundness: 20
                                }}
                                maxLength={500}
                            />
                            <View style={{ paddingHorizontal: 10, marginTop: 5, marginBottom: 10, flexDirection: "row" }} >
                                <HelperText style={{ textAlign: "left" }} type="error" visible={commentError} >
                                    {(comment && comment?.length < 20) &&
                                        "Minimum 20 characters are required."}
                                    {!(comment) &&
                                        "Comment is required."}
                                </HelperText>
                                <TouchableOpacity disabled={commentLoading} onPress={submitComment} style={{ position: "absolute", right: 0, backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 100 }} >
                                    <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Post </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        {comments?.map((item, index) => (
                            <View style={{ flexDirection: "row" }} key={"comment" + index} >
                                <View style={{ flex: 2 }} >
                                    {(item.Commented_By == "PARTNER" && comments[index - 1]?.Commented_By != "PARTNER") &&
                                        <Image
                                            source={{ cache: "force-cache", uri: item?.Partner_Logo }}
                                            style={{
                                                height: 40, width: 40, marginVertical: 5, alignSelf: "center",
                                                resizeMode: 'contain', borderRadius: 50, shadowRadius: 2, backgroundColor: "#efefef"
                                            }}>
                                        </Image>}
                                    {(item.Commented_By == "USER" && comments[index - 1]?.Commented_By != "USER") &&

                                        <Image
                                            source={{ cache: "force-cache", uri: item?.Commenter_Pic }}
                                            style={{
                                                height: 40, width: 40, marginVertical: 5, alignSelf: "center",
                                                resizeMode: 'contain', borderRadius: 50, shadowRadius: 2, backgroundColor: "#efefef"
                                            }}>
                                        </Image>}
                                </View>
                                <View style={{
                                    flex: 10,
                                    marginHorizontal: 2, borderRadius: 10, padding: 5,
                                    alignItems: 'flex-start',
                                    marginVertical: 5, paddingVertical: 10, borderColor: esiColor.brandBorderColor, borderWidth: 0.2
                                }} >
                                    <Text style={{ position: "absolute", right: 5, top: 0, color: esiColor.Text }}>{new Date(item.Created_Date).toDateString() + " " + new Date(item.Created_Date).toLocaleTimeString()}</Text>
                                    <Text style={{ marginHorizontal: 5, marginTop: 10, color: esiColor.Text }}>{item.Comments}</Text>
                                </View>

                            </View>
                        ))}
                    </View>

                    <Modal animationIn={"slideInDown"}
                        deviceWidth={dimensions.width}
                        deviceHeight={dimensions.height}
                        style={{ alignItems: 'center' }} isVisible={openAddress}>
                        <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 200, maxHeight: 230, borderRadius: 10, backgroundColor: "white" }}>
                            <Surface style={{
                                justifyContent: 'center',
                                borderRadius: 10,
                                top: Platform.OS === 'web' ? 10 : 20,
                                elevation: 0
                            }} >
                                <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                                    setOpenAddress((prop) => !prop);
                                }}>
                                    <Avatar.Icon color={"block"} style={{ backgroundColor: "white" }} size={36} icon="close" />
                                </TouchableRipple>
                                <View style={{
                                    flexDirection: "row", justifyContent: "center", zIndex: 2
                                }}>
                                    <View style={{ width: (dimensions.width * 0.9) > 500 ? 280 : (dimensions.width * 0.8), minHeight: 70, margin: "auto" }}>
                                        <EsiAutocompleteDropdown

                                            key={"select-address"}
                                            loading={false}
                                            data={address}
                                            label={"Delivery Address"}
                                            onSelect={(item) => {
                                                if (item.id === "Add New Address") {
                                                    navigation.navigate('AddAddress', { page: 'cart' });
                                                    setOpenAddress((prop) => !prop);

                                                } else {
                                                    setDeliveryAddress(item.id)
                                                }

                                            }}
                                            onClear={() => {
                                            }}
                                            getSuggestions={(q) => {
                                                // apiCall(q);
                                            }}
                                            // selected={}

                                            maxWidth={(dimensions.width * 0.75) > 260 ? 260 : dimensions.width * 0.75}
                                        />
                                    </View>

                                </View>
                                <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", zIndex: 1 }}>
                                    <Button disabled={!deliveryAddress} style={{ marginTop: 15, backgroundColor: '#27b6cc' }} uppercase={false} mode="contained" onPress={() => {
                                        payNow();
                                        setOpenAddress((prop) => !prop);

                                    }}>
                                        Pay Advance
                                    </Button>
                                </View>
                            </Surface>
                        </View>
                    </Modal>
                    <Modal animationIn={"slideInDown"}
                        deviceWidth={dimensions.width}
                        deviceHeight={dimensions.height}
                        style={{ alignItems: 'center' }}
                        isVisible={isConfirmScreen}
                    >
                        <View style={{ flex: 1, maxWidth: dimensions.width - 50, minWidth: 300, minHeight: 150, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                            <View style={{ flexDirection: "column" }} >
                                <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, backgroundColor: "" }} onPress={() => {
                                    setIsConfirmScreen(!isConfirmScreen);
                                }}>
                                    <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                                </TouchableRipple>
                                <View style={{
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    height: 250
                                }}>
                                    <View style={{
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        paddingHorizontal: 10,
                                        marginTop: 20
                                    }}>
                                        <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.Text }} >Cancel Reason:</Text>
                                        <EsiTextAreaInput
                                            error={Boolean(errorMessage)}
                                            style={{ height: 60 }}
                                            mode='outlined'
                                            value={cancelMessage}
                                            textareavalueUpdate={value => {
                                                if (value) {
                                                    if (value.length < 20) {
                                                        setErrorMessage("Cancel reason should be more than 20 characters.")
                                                    } else {
                                                        setErrorMessage("");
                                                    }
                                                } else {
                                                    setErrorMessage("Please provide cancel reason.")
                                                }
                                                setCancelMessage(value);
                                            }}
                                            placeholder={'Please provide reason'}
                                            underlineColorAndroid={'transparent'}
                                            theme={{
                                                colors: {
                                                    primary: "#27b6cc"
                                                },
                                                roundness: 8
                                            }}
                                            maxLength={500}
                                        />
                                        <HelperText type="error" visible={Boolean(errorMessage)}>
                                            {errorMessage}
                                        </HelperText>
                                        <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.Text }} >Are you sure you want to cancel?</Text>
                                    </View>


                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        paddingHorizontal: 5,
                                        marginVertical: 5

                                    }}>
                                        <TouchableOpacity onPress={() => {
                                            setIsConfirmError("");
                                            if (cancelMessage) {
                                                if (cancelMessage.length < 20) {
                                                    setErrorMessage("Cancel reason should be more than 20 characters.")
                                                } else {
                                                    setErrorMessage("");

                                                    setIsConfirmLoading(true);
                                                    dispatch(updateUserBiddingProduct({
                                                        User_Products_Bidding_Id: product?.User_Products_Bidding_Id,
                                                        Bidding_Status: "CANCELLED",
                                                        Cancelled_Reason: cancelMessage
                                                    }, (status) => {
                                                        setIsConfirmLoading(false);
                                                        setCancelMessage("");
                                                        if (status) {
                                                            setIsConfirmScreen(false);
                                                        } else {
                                                            setIsConfirmError("Please try again.");
                                                        }
                                                    }))

                                                }
                                            } else {
                                                setErrorMessage("Please provide cancel reason.")
                                            }

                                        }} style={{ borderColor: esiColor.esiMainColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                            <Text style={{ color: esiColor.esiMainColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Yes </Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            setIsConfirmScreen((prop) => !prop);
                                        }} style={{ borderColor: esiColor.light, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                            <Text style={{ color: esiColor.esiMainColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Close </Text>
                                        </TouchableOpacity>
                                    </View>

                                    <HelperText type="error" style={{ textAlign: "left" }} >
                                        Note : Advance amount will not refundable. After cancel our backend team will reach you and help you to get reslove your issue.
                                    </HelperText>
                                </View>

                            </View>
                        </View>
                    </Modal>

                </View>
            </ScrollView >
        </Surface >
    )
}