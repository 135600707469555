import 'react-native-gesture-handler';
import * as React from 'react';
import { deleteAddressAction, GetAllAddressAction, } from "../../state/actions/addressActions";
import { View, StyleSheet, ScrollView, Dimensions, TouchableOpacity, Image, Platform, ActivityIndicator, RefreshControl, FlatList } from 'react-native';
import { Provider, Card, Text, Surface, IconButton, Button } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import addressgif from "../../assets/images/GIF/addressgif.gif";
import { useToast } from '../../self_modules/react-native-paper-toast/src';


function Useraddresses(props: any) {
    const { navigation, ...rest } = props
    const { height, width } = Dimensions.get('window');
    const toast = useToast();
    const addresses = useSelector(state => state.address.addresses.all);
    const isLoading = useSelector(state => state.address.addresses.isLoading);
    const EmailID = useSelector(state => state.auth.user.Email_Id);
    const esiColor = useSelector(state => state.theme);
    const windowHeight = Dimensions.get('window').height;

    //API dispatch
    const dispatch = useDispatch();
    const onRefresh = () => {
        let formData = {
            Records_Filter: "FILTER",
            User_Email_Id: EmailID,
        };
        dispatch(GetAllAddressAction(formData))
    };
    React.useEffect(() => {
        if (!isEmpty(EmailID)) {
            if (!isLoading) {
                onRefresh()
            }
        } else {
            setSeconds(2);
        }
    }, [EmailID]);

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (isEmpty(EmailID) || seconds === 1) {
                navigation.navigate("Home");
            }
            seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);

        }, 1000)
        return () => clearTimeout(timer)
    }, [seconds]);
    const [bopen, setBopen] = React.useState(false);
    const bhandleClose = (address: any) => {
        setBopen(false);
        if (address) {
            dispatch(deleteAddressAction(address, EmailID, toast));

        }
    };
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    const style = StyleSheet.create({

        button: {
            alignItems: "center",
            paddingTop: 11,
            backgroundColor: esiColor.globalButtonColor,
            borderRadius: 5,
            width: 145

        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 80
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            marginHorizontal: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
        },
    });
    return (
        <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1 }}>
            <Header {...props} />

            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={isLoading}
                        onRefresh={onRefresh}
                    />
                }
            >
                <View style={{ flexDirection: 'row-reverse', marginTop: "2%", marginBottom: "2%", marginLeft: 10, }}>
                    <View style={{ marginRight: 10 }}>
                        {isEmpty(addresses) ? null : <TouchableOpacity onPress={() => navigation.navigate('AddAddress')} style={style.button}>
                            <Text style={{ fontSize: 15, color: esiColor.itemButtenColor, fontWeight: "bold", paddingBottom: 10 }}>+ ADD ADDRESS</Text>
                        </TouchableOpacity>
                        }
                    </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <View style={style.mainbox}>
                        <View>
                            <ScrollView>
                                {isLoading ? <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                                    <Text style={{ fontSize: 13, marginTop: 10, fontWeight: 'bold', color: esiColor.NoDataFound }}>No Address Found!</Text>
                                    {/* <Image source={require('../../assets/images/not-found-animate.gif')}
                                            style={{ width: 300, height: 300 }} /> */}
                                    <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={addressgif} />
                                </View> : <View
                                    style={{
                                        marginTop: 0
                                    }} >
                                    <FlatList
                                        // horizontal={true}
                                        key={dimensions.width}
                                        data={addresses}
                                        renderItem={({ item, index, separators }) => {
                                            let address = item
                                            return (
                                                <View style={{
                                                    borderRadius: 7,
                                                    borderWidth: 1,
                                                    shadowColor: esiColor.brandShadowColor,
                                                    shadowOffset: { width: 2, height: 2 },
                                                    shadowOpacity: 5,
                                                    shadowRadius: 4,
                                                    elevation: 10,
                                                    marginVertical: 5,
                                                    padding: 2, backgroundColor: esiColor.CBColor,
                                                    borderColor: esiColor.SBorderColor
                                                }} key={"address" + index}>
                                                    <View style={{ flexDirection: "column", marginLeft: 25 }}>
                                                        <Text style={{ color: esiColor.brandFontColor, fontSize: 18, marginTop: 5 }}>{address?.Name}</Text>
                                                        <Text style={{ fontWeight: "bold", fontSize: 18, color: esiColor.itemColor }}>{address?.Mobile}</Text>
                                                        <Text style={{ fontSize: 18, color: esiColor.itemColor }}>{address?.Door_Number},{address?.Street},</Text>
                                                        <Text style={{ fontSize: 18, color: esiColor.itemColor }}>{address?.City},{address?.State},{address?.Country},</Text>
                                                        <Text style={{ fontSize: 18, color: esiColor.itemColor }}>{address?.Zip}. </Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignSelf: "center", justifyContent: 'space-between', marginTop: 15 }}>
                                                        <View style={{ flex: 1, justifyContent: "center", flexDirection: "row" }}>
                                                            <View style={{ height: 30, width: 45, backgroundColor: '#f0f0f0', borderRadius: 8 }} >
                                                                <Text style={{ textAlign: 'center', color: 'grey', fontSize: 10, marginVertical: 8 }} >{address?.Address_Type} </Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ flex: 1 }} >
                                                            <View style={{ height: 30, width: 45, backgroundColor: address?.Is_Default === "1" ? "green" : "transparent", borderRadius: 8 }} >
                                                                <Text style={{ color: address?.Is_Default === "1" ? "white" : 'transparent', fontSize: 10, textAlign: 'center', marginVertical: 8 }}> Default</Text>

                                                            </View>
                                                        </View>
                                                        <View style={{ flex: 2, flexDirection: "row", justifyContent: "space-around" }}  >
                                                            <IconButton icon="square-edit-outline" color={esiColor.globalButtonColor} size={30} style={{ height: 30, marginTop: -2.7, marginHorizontal: 'auto', }} onPress={() => {
                                                                navigation.navigate('EditAddress', { data: address.User_Address_Id });
                                                            }}>
                                                            </IconButton>
                                                            <IconButton size={30} color={esiColor.globalButtonColor} style={{ height: 30, marginTop: -2.7, marginHorizontal: 'auto' }} icon="delete" onPress={() => { bhandleClose(address) }}  >
                                                            </IconButton>
                                                        </View>
                                                    </View>
                                                </View>
                                            )
                                        }}
                                    />
                                    {isEmpty(addresses) &&
                                        <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                                            <Text style={{ fontSize: 13, marginTop: 10, fontWeight: 'bold', color: esiColor.NoDataFound }}>No Address Found!</Text>
                                            {/* <Image source={require('../../assets/images/not-found-animate.gif')}
                                                    style={{ width: 300, height: 170, marginBottom: 50 }} /> */}
                                            <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={addressgif} />
                                            <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                                                <TouchableOpacity onPress={() => navigation.navigate('AddAddress')} style={style.button}>
                                                    <Text style={{ fontSize: 15, color: esiColor.itemButtenColor, fontWeight: "bold" }}>+ ADD ADDRESS</Text>
                                                </TouchableOpacity>
                                                <View style={{ margin: 20 }} />
                                                <TouchableOpacity onPress={() => {
                                                    let formData = {
                                                        Records_Filter: "FILTER",
                                                        User_Email_Id: EmailID,
                                                    };
                                                    dispatch(GetAllAddressAction(formData))
                                                }} style={style.button}>
                                                    <Text style={{ fontSize: 15, color: esiColor.itemButtenColor, fontWeight: "bold", textAlign: 'center' }}>RELOAD</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    }</View>}
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </ScrollView>

        </Surface>
    );
}


export default Useraddresses