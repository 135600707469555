import React, { useCallback } from "react";
import { Linking } from "react-native";
import FontAwesome from 'react-native-vector-icons/FontAwesome';

import { useSelector } from "react-redux";
const EsiURLButton = ({ url, children }) => {
  const esiColor = useSelector(state => state.theme);
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
        Linking.openURL(url);
    }
  }, [url]);

  // return <IconButton icon='pdf-box'size={30} title={children} onPress={handlePress} />;
   return <FontAwesome name="file-pdf-o" size={20} color={esiColor.DescColor} title={children} onPress={handlePress} />;
};
export default EsiURLButton;