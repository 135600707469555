import React from "react";
import { TextInput } from "react-native-paper";
import { View } from "react-native";
import { useSelector } from "react-redux";
export default function EsiTextAreaInput(props) {
    const { textareavalueUpdate, ...rest } = props;
    const esiColor = useSelector(state => state.theme);

    return (
        <View style={{ zIndex: -1}}>
        <TextInput
            error={props.error}
            {...rest}
            label={props.label}
            value={props.value}
            numberOfLines={5}
            multiline={true}
            onChangeText={text => props.textareavalueUpdate(text)}
            returnKeyType="done"
            blurOnSubmit={true}
            selectionColor={esiColor.TextSelection}
            theme={{
                colors: {
                    primary: esiColor.TextHighlight,
                    text:esiColor.Text,
                    placeholder: esiColor.TextPlaceholder
                }
            }}
            outlineColor={esiColor.TextOuterLine}
            underlineColor={esiColor.TextUnderline}
            style={[props?.style, { backgroundColor: esiColor.TextBC }]}
        />
        </View>
    )
}
