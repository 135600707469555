import * as React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import EsiCheckboxTree from '../components/custom/checkbox-tree';

import { Text, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import Header from './header';

export default function NotFoundScreen(props) {
  return (
    <>
    <Header {...props}  />
 <View style={styles.container}>
   <Text style={{color:'#27b6cc',fontWeight:'bold',fontSize:20}}>Oops! Page not found!</Text>
   <Image  style={{height:'30%',width:'70%'}} source={require('../assets/images/img/4044.png')} />
   {/* <TouchableOpacity onPress={() => navigation.replace('Root')} >
     <Text style={{color:'#27b6cc',}}>Go to home screen!</Text>
   </TouchableOpacity> */}
   <EsiCheckboxTree/>
 </View>
 </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
