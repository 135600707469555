import * as React from 'react';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import { LogBox } from 'react-native';
import { Provider } from "react-redux";
import store from './src/state/store';
import { DarkTheme, DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import AuthLayer from './AuthLayer';

import GenerateExpoPushToken from "./src/components/GenerateExpoPushToken";

import {
  Platform
} from 'react-native';
import * as Sentry from 'sentry-expo';
import { ToastProvider } from './src/self_modules/react-native-paper-toast/src';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import useColorScheme from './src/hooks/useColorScheme';

Sentry.init({
  dsn: 'https://259d559834e10bd242c069726d43db61@sentry.meepaisa.com/7',
  enableInExpoDevelopment: true,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  tracesSampleRate: 1.0,
});
let firebaseConfig: FirebaseOptions;
// Initialize Firebase
if (Platform.OS == 'web'){
 firebaseConfig = {
  apiKey: "AIzaSyDN5iBPnmDX_dOifNcerIrO17mKXRcTUA4",
  authDomain: "meepaisa-fff60.firebaseapp.com",
  projectId: "meepaisa-fff60",
  storageBucket: "meepaisa-fff60.appspot.com",
  messagingSenderId: "267435852832",
  appId: "1:267435852832:web:364e17c0c18a440de46845",
  measurementId: "G-Y69JDCJGEL"
};
}
else if (Platform.OS == 'ios'){

  firebaseConfig = {
    apiKey: "AIzaSyAGQ83Mmrd-MY13zbuOsEq538eBuWXA8PE",
    authDomain: "meepaisa-fff60.firebaseapp.com",
    projectId: "meepaisa-fff60",
    storageBucket: "meepaisa-fff60.appspot.com",
    messagingSenderId: "267435852832",
    appId: "1:267435852832:ios:d9688e3ebf70af67e46845",
  };

}
else if (Platform.OS == 'android'){

  firebaseConfig = {
    apiKey: "AIzaSyAS25twVGA8Xvo7FTFYBawx8RBQBfSsBoc",
    authDomain: "meepaisa-fff60.firebaseapp.com",
    projectId: "meepaisa-fff60",
    storageBucket: "meepaisa-fff60.appspot.com",
    messagingSenderId: "267435852832",
    appId: "1:267435852832:android:351a277c4d2ae20de46845",
  };

}

const App = () => {
  const colorScheme = useColorScheme();
  initializeApp(firebaseConfig)

  LogBox.ignoreAllLogs(true);

  return (
    <SafeAreaProvider>
      <PaperProvider theme={colorScheme=="dark"?DarkTheme:DefaultTheme}>
        <Provider store={store}>

          <ToastProvider>
            <AuthLayer colorScheme={colorScheme}  />
            {Platform.OS != "web" && <GenerateExpoPushToken />}
            <StatusBar />
          </ToastProvider>
        </Provider>
      </PaperProvider>
    </SafeAreaProvider>
  );
}

App.propTypes = {
  name: PropTypes.string
};



export default App;
