import React, { useEffect } from "react";
import { Image, Platform, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View, Dimensions, ActivityIndicator, RefreshControl } from "react-native";
import { Card, IconButton, Surface } from "react-native-paper";
import Header from "../header";
import { useDispatch, useSelector } from "react-redux";
import { getFilterCategoriesAction } from "../../state/actions/categoriesActions";
import isEmpty from "../../state/validations/is-empty";


const SubCategory = (props) => {
    const { navigation } = props
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const esiColor = useSelector(state => state.theme);

    const [sub_category, setSub_category] = React.useState([]);
    const [categoryId, setCategoryId] = React.useState("");
    const halfdimentions = dimensions / 2;

    const [loading, setLoading] = React.useState(false);

    const categeyCallback = (data) => {
        setLoading(false);
        if (!(data?.Is_Data_Exist === "0")) {
            setSub_category(data.Category[0]?.Sub_Categories || []);
        }
    }
    const dispatch = useDispatch();

    useEffect(() => {
        if (categoryId != props?.route?.params?.category_id) {
            setSub_category([]);
            setCategoryId(props?.route?.params?.category_id);
            let sub = categoriesOne?.filter((item => props?.route?.params?.category_id === item.Category_Id))[0]?.SubCategories || [];
            if (!isEmpty(sub)) {
                setSub_category(sub)
            } else {
                onRefresh();
            }
        }

    }, [sub_category, props?.route?.params?.category_id]);

    const onRefresh = () => {
        setLoading(true);
        let formData = {
            "Category_Id": props?.route?.params?.category_id,
            "Records_Filter": "FILTER"
        }
        dispatch(getFilterCategoriesAction(formData, categeyCallback));
    };

    let categoriesOne = useSelector((state) => state.allCategories.allCategories || []);
    return (
        <Surface style={{ flex: 1, position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center' }}>
                <Text style={{ fontSize: 22, fontWeight: "bold", alignSelf: 'center', color: esiColor.brandFontColor, margin: 2 }}>Sub Categories</Text>
            </View>
            {navigation.canGoBack() &&
                <IconButton
                    icon="arrow-left"
                    size={25}
                    color= "#27B6CC"
                    style={{ marginTop: (Platform.OS === 'web' ? -38 : -40), marginLeft: -6 }}
                    onPress={() => navigation.goBack()}
                />}
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={onRefresh}
                    />
                }
            >
                {loading ?
                    <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 20 }} />
                    :
                    <View style={styles.Card}>
                        {sub_category.length <= 0 ?
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <Text style={{color: esiColor.NoDataFound}}>No sub categories available to display</Text>
                            </View>
                            : sub_category?.map((item, index) => {
                                return (
                                    <View key={index} style={{ padding: 4, maxWidth: (halfdimentions), width: (halfdimentions) }}>
                                        <Card style={{
                                            height: 140, maxHeight: 140, margin: 8,
                                            shadowColor: esiColor.brandShadowColor, borderRadius: 2, elevation: 10, opacity: 10
                                        }} >
                                            <TouchableOpacity onPress={() => props.navigation.navigate('Main', { screen: 'All', params: { id: item.Sub_Category_Id, cid: categoryId, type: 'sub-category', page: 'products', filter: 'all' } })}>
                                                <View>
                                                    <Image source={{ cache: "force-cache", uri: item.Sub_Category_Image_Mobile + `?h=140` }}
                                                        style={{
                                                            height: 140, maxHeight: 140,
                                                            shadowColor: "#CACFD2", borderRadius: 2, opacity: 10

                                                        }}
                                                    />
                                                </View>
                                            </TouchableOpacity>
                                        </Card>
                                        <Text style={{ margin: 2, flex: 1, color: esiColor.itemColor, fontSize: 14, textAlign: 'center', paddingVertical: 4, fontWeight: 'bold' }}>{item.Sub_Category_Name}</Text>
                                    </View>
                                )
                            })}
                    </View>}
            </ScrollView>
        </Surface >
    );
}
export default SubCategory;
const styles = StyleSheet.create({
    Card: {
        // flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'flex-start',
        position: 'relative',
        marginBottom: 70
    },
});
