import React, { useEffect } from "react";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import { TabBar, TabView } from "react-native-tab-view";
import RetailOrders from "./retailOrders";
import FoodOrders from "./foodOrders";
import { getFoodGroceriesOrdersAction } from "../../state/actions/ordersAction";
export default function MyOrders(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isEmpty(logedInEmail)) {
            setSeconds(2);
        } else {
            setSeconds(10);
        }
    }, [logedInEmail]);

    const [seconds, setSeconds] = React.useState(-1);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);
            if (seconds === 1) {
                if (isEmpty(logedInEmail)) {
                    navigation.navigate("Home");
                } else {
                    dispatch(getFoodGroceriesOrdersAction({
                        "Type": "FOOD",
                        "User_Email_Id": logedInEmail
                    }, ((data) => { setFood(data); setSeconds(10) })));
                    dispatch(getFoodGroceriesOrdersAction({
                        "Type": "GROCERY",
                        "User_Email_Id": logedInEmail
                    }, ((data) => { setGroceries(data) })));

                }
            }
        }, 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: 'first', title: 'Retail' },
        { key: 'second', title: 'Food' },
        { key: 'third', title: 'Grocery' },
    ]);

    const [food, setFood] = React.useState([]);
    const [groceries, setGroceries] = React.useState([]);

    const [lastCall, setLastCall] = React.useState(0);
    const [skip, setSkip] = React.useState(true);

    const refreshPage = () => {
        switch (index) {
            case 1: {
                dispatch(getFoodGroceriesOrdersAction({
                    "Type": "FOOD",
                    "User_Email_Id": logedInEmail
                }, ((data) => { setFood(data) })));
            }
            case 2: {
                dispatch(getFoodGroceriesOrdersAction({
                    "Type": "GROCERY",
                    "User_Email_Id": logedInEmail
                }, ((data) => { setGroceries(data) })));
            }
        }
    }




    React.useEffect(() => {
        if (lastCall != index && skip && index != 0) {
            setSkip(false);
            switch (index) {
                case 1: {
                    dispatch(getFoodGroceriesOrdersAction({
                        "Type": "FOOD",
                        "User_Email_Id": logedInEmail
                    }, ((data) => { setFood(data) })));
                }
                case 2: {
                    dispatch(getFoodGroceriesOrdersAction({
                        "Type": "GROCERY",
                        "User_Email_Id": logedInEmail
                    }, ((data) => { setGroceries(data) })));
                }
            }
            setLastCall(index);
            setSkip(true);

        }
    }, [index]);


    const renderScene = ({ route, jumpTo }) => {

        switch (route.key) {
            case 'first':
                return (<RetailOrders {...props} />)

            case 'second':
                return (<FoodOrders {...props} orderData={food} refreshPage={refreshPage} />)

            case 'third':
                return (<FoodOrders {...props} orderData={groceries} refreshPage={refreshPage} />)
        }
    };

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <TabView
                renderTabBar={props => <TabBar {...props}
                    style={{ backgroundColor: esiColor.BackgroundColor, height: 30 }}
                    activeColor={esiColor.brandFontColor}
                    inactiveColor={esiColor.brandFontColor}
                    labelStyle={{ fontWeight: 'bold', marginVertical: -20 }}
                    indicatorStyle={{ backgroundColor: esiColor.brandFontColor }} />}
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
            />
        </Surface>
    )
}
