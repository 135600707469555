import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { TabBar, TabView } from 'react-native-tab-view';
import ReceiveGifts from "./receive-gifts/ReceiveGifts";
import SendGifts from "./send-gifts";
import Modal from "react-native-modal";
import Header from "../header";
import { Surface } from "react-native-paper";
import { useSelector } from "react-redux";
import isEmpty from "../../state/validations/is-empty";

export default function GiftCard(props) {
    const { navigation } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: 'first', title: 'Sent' },
        { key: 'second', title: 'Received' },
    ]);
    const EmailID = useSelector(state => state.auth.user?.Email_Id)
    const esiColor = useSelector(state => state.theme);
    useEffect(() => {
        if (isEmpty(EmailID)) {
            setTimeout(() => {
                if (isEmpty(EmailID)) {
                    navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [EmailID])


    const renderScene = ({ route, jumpTo }) => {
        switch (route.key) {
            case 'first':
                return <SendGifts {...props} setModalVisible={setModalVisible} />;
            case 'second':
                return <ReceiveGifts {...props} setModalVisible={setModalVisible} />;
        }
    };
    const styles = StyleSheet.create({
        sub_cart: {
            padding: 20,
            flexDirection: 'column',
            // height: 80,
            // backgroundColor: '#27b6cc',
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
        },
        button: {
            borderRadius: 10,
            paddingHorizontal: 10,
            paddingVertical: 5,
            alignSelf: "center",
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 5,
            backgroundColor: esiColor.globalButtonColor,
            marginVertical:2,
            width: 125

        },
        content: {
            borderRadius: 10,
            backgroundColor: 'white',
            marginHorizontal: 15,
            marginVertical: 10,
            height: 120,
            padding: 10,
            justifyContent: 'center',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10,
        },
        image: {
            width: 80,
            height: 80
        }
    });
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <TabView
                renderTabBar={props => <TabBar {...props}
                    style={{ backgroundColor: esiColor.BackgroundColor }}
                    activeColor={esiColor.brandFontColor}
                    inactiveColor={esiColor.brandFontColor}
                    labelStyle={{ fontWeight: 'bold' }}
                    indicatorStyle={{ backgroundColor: esiColor.brandFontColor }} />}
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
            />
            {index == 0 &&
                <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('BuyGift')}>
                    <Text style={{ padding: 10, color: esiColor.itemButtenColor, fontWeight: 'bold', textAlign: "center" }}>Send a Gift</Text>
                </TouchableOpacity>
            }
            <Modal setModalVisible={setModalVisible} modalVisible={modalVisible}
                onBackButtonPress={() => setModalVisible(false)}
                isVisible={modalVisible}
                onBackdropPress={() => setModalVisible(false)}
                swipeDirection="left"
                backdropColor='grey'
                backdropOpacity={0.6}
                animationIn="zoomInDown"
                animationOut="zoomOutUp"
                animationInTiming={600}
                animationOutTiming={600}
                backdropTransitionInTiming={600}
                backdropTransitionOutTiming={600}
            >
                <View style={{ minHeight: '20%', minWidth: '80%', backgroundColor: 'white', borderRadius: 10, flexDirection: 'column' }}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 18, color: esiColor.NoDataFound }}>Are you sure to cancel this order?</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginHorizontal: 20 }}>
                        <TouchableOpacity onPress={() => cancelOrder()} style={{
                            backgroundColor: esiColor.globalButtonColor, flex: 1, alignItems: 'center',
                            borderBottomLeftRadius: 10, borderTopLeftRadius: 10, padding: 5, shadowColor: '#000',
                            shadowOffset: { width: 0, height: 2 },
                            shadowOpacity: 0.2,
                            shadowRadius: 10,
                            elevation: 10,
                        }}>
                            <Text style={{ color: esiColor.itemButtenColor, fontSize: 16 }}>Yes</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setModalVisible(false)}
                            style={{
                                backgroundColor: esiColor.globalButtonColor, flex: 1, alignItems: 'center',
                                borderBottomRightRadius: 10, borderTopRightRadius: 10, padding: 5, shadowColor: '#000',
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.2,
                                shadowRadius: 10,
                                elevation: 10,
                            }}>
                            <Text style={{ fontSize: 16, color: esiColor.itemButtenColor }}>Cancel</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </Surface>
    );
}

