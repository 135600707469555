import { useEffect, useState } from "react";
import { ActivityIndicator, Image, Platform, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown";
import { getBiddingCategorysProducts } from "../../../state/actions/node-actions/bid-products-actions";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";

export const CategoryFilters = (props) => {
    const { filters, setFilters } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const esiColor = useSelector(state => state.theme);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setCategory(data.map(item => ({ "id": item.Bidding_Products_Category_Id, "title": item.Bidding_Products_Category_Name, image: item.Bidding_Products_Category_Image })));
        } else {
            setCategory([]);
        }
    }


    const apiCall = (q) => {
        setLoading(true);
        dispatch(getBiddingCategorysProducts({
            "Records_Filter": "FILTER",
            "Search": q,
            "NotEquelCategorys": filters.map((item) => item.id) || []

        },
            { PageNo: 1, PageSize: 10, SortBy: "BIDDING_PRODUCTS_CATEGORY_NAME", SortOrder: "ASC" },
            callBackGetData
        ));
    }

    useEffect(() => {
        apiCall("");
    }, [filters]);


    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3
                }}>Category</Text>
            </View>
            <EsiAutocompleteDropdown key={"sele" + filters.length}
                loading={loading}
                data={category}
                label={"Search Category"}
                onSelect={(item) => {
                    if (item?.id) {
                        if (filters.filter((filter) => item?.id == filter?.id).length == 0)
                            setFilters((props) => props.concat([item]));

                    }
                }}
                onClear={() => {
                    setFilters((props => {
                        return { ...props, categoryId: "" }
                    }))
                }}
                getSuggestions={(q) => {
                    apiCall(q);
                }}
            />
            {filters.length > 1 &&
                <View style={{ flexDirection: "row", justifyContent: "flex-end" }} >
                    <TouchableOpacity onPress={() => { setFilters([]) }} >
                        <Text style={{ color: esiColor.actionColor, margin: 5 }} >Clear All</Text>
                    </TouchableOpacity>
                </View>}
            <View>
                {filters.map((item) => {
                    return (
                        <View style={{
                            flexDirection: "row", borderRadius: 25,
                            paddingRight: Platform.OS == "android" ? 0 : 10,
                            // backgroundColor: es,
                            borderWidth: 1,
                            borderColor: '#383b42',
                            shadowColor: '#00000099',
                            shadowOffset: {
                                width: 0,
                                height: 5,
                            },
                            shadowOpacity: 0.3,
                            shadowRadius: 8.46,
                            marginVertical: 2,
                            elevation: 13
                        }} key={"cat" + item.id} >
                            <View style={{
                                flex: 3, flexDirection: "row"
                            }} >
                                <Text style={{ marginVertical: 5, marginLeft: 5,color:esiColor.Text }} > {item.title} </Text>

                            </View>
                            <View style={{
                                flex: 1, flexDirection: "row", justifyContent: "flex-end"
                            }} >
                                <TouchableOpacity onPress={() => { setFilters((props) => props.filter((prop) => prop.id != item.id)) }} style={{ marginTop: 2 }} >
                                    <Ionicons name="md-trash" size={22} color="#ee4d2d" />
                                </TouchableOpacity>
                            </View>
                        </View>
                    )
                })}
            </View>

        </View>
    )
}