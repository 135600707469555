import * as React from 'react';
import { Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Button } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import Swiper from 'react-native-web-swiper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../state/validations/is-empty';
import Header from '../header';
import { ActivityIndicator, List, Provider, Surface } from 'react-native-paper';
import RenderHTML from "react-native-render-html";
import useRazorpay from "react-razorpay";
import { getHallIdAction } from '../../state/actions/eventsActions';
import { Ionicons } from '@expo/vector-icons';
import ImageView from '../../self_modules/react-native-image-view/src/ImageView';

const { height, width } = Dimensions.get('window');
export default function EventDetails(props) {
    const dispatch = useDispatch();
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }
    const data = props?.route?.params.data

    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [expanded, setExpanded] = React.useState(true);
    const handlePress = () => setExpanded(!expanded);
    const [hall, setHallData] = React.useState([]);
    const [ID, setID] = React.useState("");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimentions = dimensions / 1.06;
    const user = useSelector((state) => state.auth.user);;

    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    React.useEffect(() => {
        if (isEmpty(user.Email_Id)) {
            setTimeout(() => {
                if (isEmpty(user.Email_Id)) {
                    props.navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [user.Email_Id])

    const callBack = (data) => {
        setHallData(data)
    }

    React.useEffect(() => {
        if (isEmpty(data) || (ID !== data)) {
            let formData = {
                Function_Hall_ID: data,
                Records_Filter: "FILTER"
            }
            setID(hall?.Function_Hall_Id)
            dispatch(getHallIdAction(formData, callBack))
        }
    }, [data, ID])

    const [isImageViewVisible, setIsImageViewVisible] = React.useState(false);
    const [index, setIndex] = React.useState(0);

    const zoomImage = (image) => {
        const images = [

        ];

        image.map((item => {
            images.push(
                {
                    source: {
                        uri: item.Document_URL
                        ,
                    },
                    width: 806,
                    height: height,
                }
            )
        }))
        return images
    }

    return (
        <Surface style={{ flex: 1 }}>
            <Header {...props} />
            {(!isEmpty(hall) && (hall?.Function_Hall_Id === data)) ?
                <Provider>
                    <View style={{ flexDirection: "column", flexWrap: "wrap", alignItems: 'center', alignContent: 'center' }}>
                        <Text style={{ fontSize: 24, marginTop: 5, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>{hall.Name.toUpperCase()}</Text>
                    </View>
                    <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => { props.navigation.navigate("AllHalls") }}>
                        <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
                    </TouchableOpacity>
                    <ScrollView>
                        <View style={{
                            flex: 1,
                            marginHorizontal: 2, padding: 5, margin: 'auto', backgroundColor: 'white', flexDirection: 'column', borderColor: "#CACFD2", borderWidth: 1,
                            shadowOffset: { width: 0, height: 2 },
                            shadowColor: '#6D70E7',
                            marginTop: "1%",
                            marginBottom: "1%",
                            shadowOpacity: 0.2,
                            shadowRadius: 10,
                            elevation: 10,
                            marginLeft: "auto", marginRight: "auto",
                            width: dimensions >= 10000 ? "90%" : dimensions,
                        }}>
                            <ImageView
                                images={zoomImage(hall?.Function_Hall_Document)}
                                imageIndex={index}
                                isVisible={isImageViewVisible}
                                onClose={() => setIsImageViewVisible(false)}
                            />
                            <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: 1 }}>
                                <Text>{hall.Short_Description}</Text>
                            </View>
                            <View style={{ justifyContent: "center", width: dimensions, height: 400 }}>
                                <Swiper
                                    showsButtons={false} horizontal={true}
                                    timeout={5}
                                    springConfig={{ speed: 11 }}
                                    minDistanceForAction={0.15}
                                    loop={true}
                                    controlsEnabled={false}
                                >

                                    {hall?.Function_Hall_Document?.map((data: any, index: any) => (
                                        <TouchableOpacity style={styles.img} key={index} onPress={() => {
                                            setIndex(index);
                                            setIsImageViewVisible(true);
                                        }}>
                                            <Image
                                                style={styles.tinyLogo}
                                                resizeMode="cover"
                                                source={{ cache:"force-cache" , uri: data.Document_URL }}
                                            />
                                        </TouchableOpacity>
                                    ))}

                                </Swiper>
                            </View>
                            <View style={{ flexDirection: "column" }}>

                                <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: 1 }}>
                                    <RenderHTML
                                        source={{ html: hall?.Full_Description }} />
                                </View>
                                <View style={{ borderBottomColor: '#27B6CC', borderBottomWidth: 3 }} />
                                <View style={{ flexDirection: "row", paddingBottom: 5 }}>
                                    <Text style={{ fontWeight: "bold" }}>
                                        ADAVNCE BOOKING AMOUNT :
                                    </Text>
                                    <Text style={{ color: "#27b6cc", fontWeight: "bold" }}>
                                        {hall?.Advance_Amount_per_Day}/-
                                    </Text>
                                </View>
                                <View style={{ borderBottomColor: '#27B6CC', borderBottomWidth: 3 }} />
                                <List.Section title={hall.Name + " Details"}>

                                    <List.Accordion
                                        title="Contact Details"
                                        titleStyle={{ color: "#27b6cc" }}
                                        descriptionStyle={{ paddingTop: 10 }}
                                        left={props => <List.Icon {...props} icon="cellphone-basic" color="#27b6cc" />}
                                    >
                                        <List.Item title={hall.Name + "," + hall.Location + "," + hall.City + "," + "India"} />
                                        <List.Item title={<TouchableOpacity style={{ paddingTop: 10, paddingBottom: 5 }} onPress={() => { props.navigation.navigate("Ticket") }}>
                                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                {/* <Image source={require("../../assets/icons/call.png")} style={{ width: 20, height: 20, }} /> */}
                                                <Text style={{ textDecorationLine: "underline", color: "blue" }}>
                                                    Click here for Enquiry
                                                </Text>
                                            </View>
                                        </TouchableOpacity>} />

                                    </List.Accordion>
                                    <List.Accordion
                                        title="Price Details"
                                        titleStyle={{ color: "#27b6cc" }}
                                        descriptionStyle={{ lineHeight: 5 }}
                                        left={props => <List.Icon {...props} icon="account-cash" color="#27b6cc" />}
                                    >
                                        <List.Item title={"Price Per Day : " + hall.Price_Per_Day} />
                                        <List.Item title={"Price Per Hour : " + hall.Price_Per_Hour} />
                                        <List.Item title={"AC Electricity Per Hour : " + hall.AC_Electricity_Price_Per_Hour} />
                                        <List.Item title={"Room Cost Per Night : " + hall.Room_Cost_Per_Night} />
                                        <List.Item title={"Electricity Price Per Hour : " + hall.Electricity_Price_Per_Hour} />
                                        <List.Item title={"Hall Decoration Price : " + hall.Hall_Decoration_Price} />
                                    </List.Accordion>
                                    <List.Accordion
                                        title="Food Details"
                                        titleStyle={{ color: "#27b6cc" }}
                                        left={props => <List.Icon {...props} icon="food-fork-drink" color="#27b6cc" />}
                                    >
                                        <List.Item title={"Non Veg Plate : " + hall.Hall_Food_Non_Veg_Price_Per_Plate} />
                                        <List.Item title={"Veg Plate : " + hall.Hall_Food_Veg_Price_Per_Plate} />
                                        <List.Item title={"Alchohol : " + (hall.IS_Alchol_Party_Allowed === "0" ? "Not Allowed" : "Allowed")} />
                                        <List.Item title={"Room Cost Per Night : " + hall.Room_Cost_Per_Night} />
                                        <List.Item title={"Electricity Price Per Hour : " + hall.Electricity_Price_Per_Hour} />
                                    </List.Accordion>
                                </List.Section>
                                <Button
                                    onPress={() => { props.navigation.navigate("AddEventDetails", { data: hall.Function_Hall_Id }) }}
                                    // onPress={payNow}
                                    buttonStyle={{ backgroundColor: "#27b6cc", borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                                    title='ClICK HERE FOR BOOKING' />
                            </View>
                        </View>
                    </ScrollView>
                </Provider>
                : <ActivityIndicator animating={true} color={"#27b6cc"} />}
        </Surface >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    tinyLogo: {
        width: 500,
        height: 400,
    }
});
