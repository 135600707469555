import { useFocusEffect } from "@react-navigation/native";
import React, { useState } from "react";
import { Dimensions, RefreshControl, ScrollView } from "react-native";
import { FlatList, Image, StyleSheet, Text, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from "react-redux";
import giftgif from "../../../assets/images/GIF/cartgif.gif"
import { getAllReceivedGiftsAction } from "../../../state/actions/giftActions";
export default function ReceiveGift(props) {
    const user = useSelector(state => state.auth?.user?.Email_Id);
    const esiColor = useSelector(state => state.theme);
    const [gift_list, setGiftList] = useState([]);
    const [loading, setLoading] = useState(true);
    let receivedGifts = useSelector((state) => state.BuyGift.receivedGifts.all);
    const dispatch = useDispatch();
    const onRefresh = () => {
        setGiftList([]);
        setLoading(true);
        let filter = {
            "Email_Id": user,
            "Is_approved": "1"
        }
        dispatch(getAllReceivedGiftsAction(filter))
            .then(async res => {
                setLoading(false)
            })
            .catch(err => {
                setLoading(false);
            })
    }
    useFocusEffect(
        React.useCallback(() => {
            onRefresh();
        }, [])
    );
    const { height, width } = Dimensions.get('window');
    const styles = StyleSheet.create({
        sub_cart: {
            padding: 20,
            flexDirection: 'column',
            height: 80,
            backgroundColor: '#27b6cc',
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
        },
        button: {
            borderRadius: 20,
            backgroundColor: 'white',
            alignItems: 'center',
        },
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            marginHorizontal: 15,
            marginVertical: 10,
            minHeight: 120,
            padding: 10,
            justifyContent: 'center',
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 2, height: 2 },
            shadowOpacity: 5,
            shadowRadius: 5,
            elevation: 10,
        },
        image: {
            width: 80,
            height: 80,
            marginLeft: 10
        }
    });
    const RenderItem = ({ item }) => {
        return (
            <View style={styles.content}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 1 }}>
                        <Image source={{ cache: "force-cache", uri: item?.Gift_Image + `?w=80&h=80` }} style={styles.image}></Image>
                    </View>
                    <View style={{ flex: 2, justifyContent: 'flex-start', alignSelf: 'flex-start' }}>
                        <Text style={{ color: esiColor.DescColor }}>{item?.Received_By_Email}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Message: {item?.Gift_Message}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Type: {item?.Type}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Voucher: {item?.Voucher_Name}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Amount/Gift Card: ₹ {item?.Total_Value}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Date: {item?.Created_Date}</Text>
                    </View>
                </View>
            </View>
        )
    }
    return (
        <View style={{ flex: 1 }}>
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={onRefresh}
                    />
                }
            >
                {loading ? <ActivityIndicator color='#27b6cc' />
                    :
                    receivedGifts.length > 0 ?
                        <FlatList
                            data={receivedGifts}
                            renderItem={RenderItem}
                            keyExtractor={(item, index) => item?.Gift_Voucher_Id + index}
                        />
                        :
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={giftgif} />
                            {/* <Ionicons name="gift-outline" size={100} color="gray"></Ionicons> */}
                            <Text style={{ color: esiColor.NoDataFound }}>No Gift Cards Received.</Text>
                        </View>
                }
            </ScrollView>
        </View>
    )
}

