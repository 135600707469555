import React, { Fragment, useEffect } from "react";
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ESIDealsCard from "../../components/custom/deal-card";
import ESIDealModel from "../../components/custom/deal-modal";
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import { resetMainData } from "../../state/actions/filterServiceActions";
import { getDealsAction } from "../../state/actions/node-actions/home-actions";


export default function MeeDeals(props) {
    const dispatch = useDispatch();
    const width = useWindowDimensions();
    const esiColor = useSelector(state => state.theme);
    const { navigation, partnerId, ...rest } = props;
    const [open, setOpen] = React.useState(false);
    const [dealData, setDealData] = React.useState('');
    const [isModalVisible, setModalVisible] = React.useState(false);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [loading, setLoading] = React.useState(false);
    const [deal, setDeal] = React.useState([]);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setDeal(data);
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        if (!loading && partnerId) {
            dealsCall();
        }
    }, [partnerId]);

    const dealsCall = () => {
        setLoading(true);
        let formData = { "Records_Filter": "FILTER", "Partner_Details_Id": [partnerId], "Status": "Active", "condition": true }
        dispatch(getDealsAction(formData, { PageNo: 1, PageSize: 20, SortBy: "DEAL_ID", SortOrder: "DESC" }, callBackGetData));
    }


    // Condition for deals expired before  3 days only will display at UI
    const dealLiveData = (deals: any) => {
        let data = [];
        var currentdate = new Date(),
            date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
        if (deals) {
            if (deals[0]) {
                data = deals.filter((deal: any) => {
                    let currentDate = new Date(date);
                    let dealExpDate = new Date(deal.End_Date.split("/").reverse().join("-"));
                    return Math.floor((currentDate - dealExpDate) / (1000 * 60 * 60 * 24)) < 3
                })
            }
        } else {
            data = []
        }
        return data;
    }

    //displaying Signin pop up
    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    const openModal = (data) => {
        setDealData(data)
        setOpen(true);
    };
    const toggleModal = async (data) => {
        if (isAuthenticated) {
            openModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeModal = () => {
        setOpen(!open);
        setDealData("")
    };
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window }) => {
                setDimensions(window);
            }
        );
    });

    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            // shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 5,
            shadowRadius: 7,
            elevation: 10
        },

    });

    return (
        <Surface theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{ elevation: 0 }}>
            <View style={styles.content}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, color: "#27b6cc", fontWeight: 'bold' }}> Deals</Text>
                    <TouchableOpacity onPress={(e) => {
                        dispatch(resetMainData());
                        props.navigation.navigate('Main', { screen: 'All', params: { id: partnerId, type: "partner", page: 'deals', filter: 'all' } });
                    }} style={{ position: 'absolute', right: 10 }}>
                        <Text style={{ fontSize: 13, color: esiColor.DescColor, fontWeight: 'bold' }}>View All</Text>
                    </TouchableOpacity>
                </View>

                <ScrollView
                    contentContainerStyle={{ paddingVertical: 10 }}
                    horizontal
                    showsHorizontalScrollIndicator={false}>
                    <View style={{ flexDirection: 'row' }}>
                        {
                            deal.length === 0 ? (
                                <View style={{ width: dimensions.width - 50, flex: 1, justifyContent: "center" }}>
                                    <Text style={{ alignSelf: "center", color: esiColor.NoDataFound }}>No Deals found!</Text>
                                </View>
                            ) : (
                                dealLiveData(deal).slice(0, 10).map((data, index) => (
                                    <Fragment key={index}>
                                        <ESIDealsCard data={data} toggleModal={toggleModal} />
                                    </Fragment>
                                ))
                            )
                        }
                    </View>
                </ScrollView>
            </View>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: 'white' }}>
                    <SignIN navigation={navigation} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={open}>
                <View style={{ flex: 1, maxWidth: 600, minWidth: dimensions.width <= 400 ? 200 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 400, borderRadius: 10, backgroundColor: 'white' }}>
                    <ESIDealModel navigation={navigation} openModal={openModal} data={dealData} closeModal={closeModal} />
                </View >
            </Modal>

        </Surface>
    )

}
