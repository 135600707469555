
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCitiesAction } from "../../state/actions/shopbycityActions";
import { Searchbar, Text, ActivityIndicator, Surface } from 'react-native-paper';
import { View, ScrollView, TouchableOpacity, RefreshControl } from 'react-native';
import { Dimensions } from 'react-native';
import Header from "../header";
import EsiCityCard from "../../components/custom/AllCityCard";
import isEmpty from "../../state/validations/is-empty";

const countryOption = [
  {
    value: 'IN',
    label: 'India'
  },
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'GB',
    label: 'United Kingdom'
  },
  {
    value: 'SG',
    label: 'Singapore'
  },
  {
    value: 'PH',
    label: 'Phillipines'
  },
  {
    value: 'MY',
    label: 'Malaysia'
  },
  {
    value: 'AU',
    label: 'Australia'
  },
  {
    value: 'ID',
    label: 'Indonesia'
  },
  {
    value: 'VN',
    label: 'Vietnam'
  },
  {
    value: 'NP',
    label: 'Nepal'
  },
  {
    value: 'SA',
    label: 'Saudi Arabia'
  }
];

export default function AllCities(props: any) {
  const { item, navigation, ...rest } = props;
  const data = props?.route?.params;
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const dispatch = useDispatch();
  // API call
  let cities = useSelector((state) => state.allCities.allCities.all);
  const esiColor = useSelector(state => state.theme);

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    dispatch(getAllCitiesAction(""));
  };

  let city;
  if (cities) {
    city = cities
  }

  const [search, setSearch] = useState("");
  const cityDetails = useMemo(() => {
    if (!search) return city;
    return city.filter((city) => {
      if (city.Name) {
        return city.Name ? (city.Name.toLowerCase().includes(search.toLowerCase())) : ""
      }
    })
  }, [search, city]);
  // Pagination

  let currentItems;
  let length;
  const pages = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(14);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxpageNumberLimit, setmaxpageNumberLimit] = useState(5);
  const [minpageNumberLimit, setminpageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setCurrentPage(Number(event))
  }
  const handleprev = () => {
    setCurrentPage(currentPage - 1)
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxpageNumberLimit(maxpageNumberLimit - pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit - pageNumberLimit);
    }
  }
  const handlenext = () => {
    setCurrentPage(currentPage + 1)
    if (currentPage + 1 > maxpageNumberLimit) {
      setmaxpageNumberLimit(maxpageNumberLimit + pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit + pageNumberLimit);
    }
  }
  if (cityDetails) {
    length = cityDetails?.length
    for (let i = 1; i <= Math?.ceil(cityDetails?.length / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = cityDetails?.length > 17 ? currentPage * itemsPerPage : itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    currentItems = cityDetails ? cityDetails?.slice(indexOfFirstItem, indexOfLastItem) : 0
  }
  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  const scroll = useRef();
  const goToTop = () => {
    if (scroll)
    scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
  }
  let pageIncrementbtn = null;
  if (pages.length > maxpageNumberLimit) {
    pageIncrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: esiColor.SBorderColor, borderRadius: 5, height: 40, justifyContent: 'center',shadowRadius: 4, shadowColor: esiColor.brandShadowColor,
          alignItems: 'center'
        }} onPress={() => { handlenext(); goToTop() }}>
          <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
        </TouchableOpacity>
      </View>
  }

  let pageDecrementbtn = null;
  if (minpageNumberLimit >= 1) {
    pageDecrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: esiColor.SBorderColor, borderRadius: 5, height: 40, justifyContent: 'center', shadowColor: esiColor.brandShadowColor, shadowRadius: 4,
          alignItems: 'center'
        }} onPress={() => { handleprev(); goToTop() }}>
          <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
        </TouchableOpacity>
      </View>
  }
  const renderPageNumber = pages.map((number) => {
    if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
      return (
        <View key={number} style={{ alignItems: 'center', margin: 1 }}>
          <TouchableOpacity style={{
            backgroundColor: currentPage === number ? "#27B6CC" : null,
            width: 30, borderWidth: 1, borderColor: esiColor.SBorderColor, borderRadius: 5, height: 40, justifyContent: 'center',
            alignItems: 'center',shadowColor: esiColor.brandShadowColor, shadowRadius: 5,
          }} onPress={() => { handleClick(number); goToTop() }}>
            <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>{number}</Text>
          </TouchableOpacity>
        </View>
      )
    }
    else {
      return null;
    }

  })
  return (
    <Surface  theme={{
      colors: {
          surface: esiColor.BackgroundColor,
      }
  }} style={{ flex: 1}}>
      <Header {...props} />
      <Searchbar style={{ margin: 10, borderColor: esiColor.SBorderColor, borderWidth: 0.5, backgroundColor: esiColor.BackgroundColor }}
        value={search}
        iconColor={esiColor.LCHFontColor}
        onChangeText={(value) => { setSearch(value); }}
        placeholderTextColor={esiColor.SPHTextColor}
        multiline={false}
        theme={{ colors: { text: esiColor.SBTextColor } }}
        placeholder="Search for Cities"
      />
      <ScrollView ref={scroll}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={onRefresh}
          />
        }

      >
        {!(cityDetails.length || cityDetails?.Is_Data_Exist === "0" || search !== "") &&
          <ActivityIndicator color='#27b6cc' />}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto', }}>
          {!isEmpty(cities) ? (
            cities.Is_Data_Exist === "0" || isEmpty(cityDetails) ? (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                <Text style={{color: esiColor.NoDataFound}}>No Cities found!</Text>
              </View>
            ) : (
              currentItems.filter((data: any) => data.Status === "Active").map((item: any, index: any) => (
                <Fragment key={index}>
                  <TouchableOpacity onPress={() => {
                    navigation.navigate('Partners', { city: item.City_Id, cityName: item.Name });
                  }}>
                    <EsiCityCard item={item} navigation={navigation} />
                  </TouchableOpacity>
                </Fragment>
              ))
            )
          ) : (<></>
          )}
        </View>
        <View>
          {length > 16 ? (
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity disabled={currentPage === pages[0] ? true : false} onPress={() => { handleprev(); goToTop() }}
                style={{
                  width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                  flexDirection: 'column', pointerEvents: currentPage === pages[0] ? "none" : "",
                  borderRadius: 5, borderColor: esiColor.SBorderColor, borderWidth: 1, opacity: currentPage === pages[0] ? "0.6" : "",
                  shadowColor: esiColor.brandShadowColor, shadowRadius: 3
                }}>
                <Text style={{ color: currentPage === pages[0] === true ? 'grey' : esiColor.itemColor }}>Prev</Text>
              </TouchableOpacity>
              {pageDecrementbtn}
              {renderPageNumber}
              {pageIncrementbtn}
              <TouchableOpacity disabled={currentPage === pages[pages.length - 1] ? true : false} onPress={() => { handlenext(); goToTop() }}
                style={{
                  width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                  flexDirection: 'column', borderRadius: 5, borderColor: esiColor.SBorderColor, borderWidth: 1,
                  pointerEvents: currentPage === pages[pages.length - 1] ? "none" : "",
                  opacity: currentPage === pages[pages.length - 1] ? "0.6" : "",
                  shadowColor: esiColor.brandShadowColor, shadowRadius: 3
                }}>
                <Text style={{ color: currentPage === pages[pages.length - 1] === true ? 'grey' : esiColor.itemColor }}>Next</Text>
              </TouchableOpacity>
            </View>
          ) : (null)}
        </View>
      </ScrollView>
    </Surface>
  );
}

