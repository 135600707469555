import React, { useEffect } from "react";
import { Dimensions, FlatList, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../components/custom/product-card";
import { resetMainData } from "../../state/actions/filterServiceActions";
import { getProductsAction } from "../../state/actions/node-actions/home-actions";
import isEmpty from "../../state/validations/is-empty";


export default function MeeProductsType(props) {
    const { navigation, dataReload, dataErrorReload, Business_Type, title, City_Id } = props;
    const dispatch = useDispatch();
    const [wishdata, setWishData] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const esiColor = useSelector(state => state.theme);
    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);
    const [loadRequired, setLoadRequired] = React.useState(false);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            setLoadRequired(true);
        } else {
            await delay(dataErrorReload);
            setLoadRequired(true);

        }
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        productsCall();

    }, [City_Id]);

    useEffect(() => {
        if (loadRequired)
            productsCall();

    }, [loadRequired]);
    const productsCall = () => {
        if (!loading) {
            setLoading(true);
            dispatch(getProductsAction({
                Records_Filter: "FILTER",
                Business_Type: Business_Type,
                City_Id: City_Id ? City_Id : ""
            }, { PageNo: 1, PageSize: 20, SortBy: "PRODUCT_NAME", SortOrder: "DESC" }, callBackGetData));
        }
    }

    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 5,
            padding: 5,
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10,
        },
    
    });

    const handleClick = async (item) => {
        navigation('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata })
    };

    return (allproducts.length > 0 &&
        <View style={styles.content}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom:10 }}>
                <Text style={{  marginLeft: 5, color: esiColor.LCFontColor, fontWeight: 'bold' }}>{title}</Text>
                <TouchableOpacity onPress={(e) => {
                    dispatch(resetMainData());
                    navigation('Main', { screen: 'All', params: { page: 'products',ptype:Business_Type } })
                }} style={{ position: 'absolute', right: 10 }}>
                    <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold' }}>View All</Text>
                </TouchableOpacity>
            </View>
            <FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={allproducts.slice(0, 10)}
                renderItem={({ item, index, separators }) => (
                    <ProductCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />
                )}
            />
        </View>
    )
}

