import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Surface, Colors, TextInput, TouchableRipple, Avatar, HelperText, Searchbar, Checkbox, IconButton } from 'react-native-paper';
import { View, Dimensions, Text, TouchableOpacity, Platform, TouchableHighlight, ActivityIndicator, ScrollView, StyleSheet, Image, FlatList } from 'react-native';
import Header from "../header";
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import ESITextInput from "../../components/custom-fields/textInput";
import { TouchableOpacity as TouchableOpacityIos } from "react-native-gesture-handler";
import isEmpty from "../../state/validations/is-empty";
import { addRide, getMyRiders, getRiders } from "../../state/actions/ridesAction";
import EsiDatePicker from "../../components/custom/date/date";
import { Formik } from "formik";
import * as Yup from 'yup';
import { addService, getCitys, getCityServices, getMyServices } from "../../state/actions/servicesAction";
import { isLoaded } from "expo-font";
import useRazorpay from "react-razorpay";
import { getUserSettings, getWalletByUser } from "../../state/actions/walletActions";
import razorpayPay from "../../hooks/razorpay";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import { getAllCartAddressAction } from "../../state/actions/addressActions";


export default function Services(props: any) {
    const { navigation, ...rest } = props;
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const EmailID = useSelector(state => state.auth.user.Email_Id);
    const esiColor = useSelector(state => state.theme);

    useEffect(() => {
        if (!isAuthenticated) {
            setTimeout(() => {
                if (isEmpty(EmailID)) {
                    navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [isAuthenticated]);

    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    const dispatch = useDispatch();

    // dimensions are used for the width and height of card 
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));





    const [openSearchModel, sertOpenSearchModel] = useState(false);



    // Schedule Call
    let nDate = new Date();
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());


    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);

    const [useWallet, setUseWallet] = useState(false);
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }

    const [wallet, setWallet] = React.useState(null);
    const updateStatesWallet = (data: any, loading: any) => {
        let add = JSON.parse(JSON.stringify(data));
        setWallet(add?.Wallet[0]);
    };

    const getWalletDiscountPrice = (amount, wallet) => {
        let response = 0;
        if (((amount) * maxWalletUsage * 0.01) >= wallet) {
            response = wallet;
        } else {
            response = ((amount) * maxWalletUsage * 0.01);
        }
        return response
    }

    const [address, setAddress] = React.useState([]);

    const addresslist = (address: any) => {
        let addresslist = []
        if (address) {
            if (address[0]) {
                for (let i = 0; i < address.length; i++) {
                    addresslist.push({
                        label: (address[i].Address_Type + " " + "," + " " + address[i].Door_Number + " " + "," + " " + address[i].Landmark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip).slice(0, 30),
                        value: JSON.stringify(address[i])
                    })
                }
            }
        }
        addresslist.push({
            label: "Add New Address",
            value: "Add New Address"
        })
        return addresslist
    }
    const updateStates = (data: any, loading: any) => {
        let add = JSON.parse(JSON.stringify(data ? data : []));
        setAddress(addresslist(add));
    };

    const getAddress = () => {
        let data = {
            User_Email_Id: user.Email_Id,
            Records_Filter: "FILTER",
        };
        dispatch(getAllCartAddressAction(updateStates, data));
    }

    const user = useSelector((state) => state.auth.user);
    const [maxWalletUsage, setMaxWalletUsage] = useState(50);

    useEffect(() => {
        if (user) {
            let data = {
                User_Id: user.Email_Id,
                Wallet_Id: "",
            };
            dispatch(getWalletByUser(updateStatesWallet, data));
            dispatch(getUserSettings("ORDER_MAX_WALLET_USAGE_P", setMaxWalletUsage));
            getAddress();
        }
    }, [user]);

    const [loading, setLoading] = useState(false);
    let resetData;
    const createServiceResponse = (status, response) => {
        setLoading(false);
        if (status) {
            if (resetData) {
                resetData();
            }
            toast.show({ message: "Successfully Service created.", type: 'info', duration: 3000, position: 'top' });
            dispatch(getMyServices({
                "Records_Filter": "FILTER",
                "Email_Id": EmailID
            }))
            navigation.navigate("MyServices");
        } else {
            toast.show({ message: response, type: 'info', duration: 3000, position: 'top' });
        }
    }

    const cityTextBox = (handleChange, handleBlur, handleSubmit, values, errors, touched) => {
        return (<ESITextInput
            style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }}
            label="City"
            mode='outlined'
            value={values?.City?.City_Name || ""}
            // disabled
            editable={false}
            // multiline={true}
            left={<TextInput.Icon color={values?.City ? "#27b6cc" : "#61e6fa"} name={"city"} />}
            error={Boolean(touched.City && errors.City)}
        />)
    }

    const serviceTextBox = (handleChange, handleBlur, handleSubmit, values, errors, touched) => {
        return (<ESITextInput
            style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }}
            label="Service"
            mode='outlined'
            value={values?.Service?.Service_Type || ""}
            editable={false}
            // multiline={true}
            numberOfLines={1}
            left={<TextInput.Icon color={values?.Service ? "#27b6cc" : "#61e6fa"} name={"card-bulleted-outline"} />}
            error={Boolean(touched.Service && errors.Service)}
        />)
    }



    return (
        <Surface theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{ flex: 1 }}>
            <Header {...props} />



            <Formik
                initialValues={
                    {
                        Service: "",
                        dateOfService: dos,
                        numberOfDays: "",
                        numberOfHours: "",
                        Service_Required_Address_ID: ""
                    }
                }
                validationSchema={Yup.object().shape({
                    Service: Yup.object()
                        .required('Service is required.'),
                    Service_Required_Address_ID: Yup.object()
                        .required('Service Address is required.'),
                    dateOfService: Yup.string().test("date-of-service", "Service Date cannot be past date.", (value: any) => {
                        if (value) {
                            let date1 = new Date(value).getTime();
                            let date2 = new Date(dos).getTime();
                            if (date1 >= date2) {
                                return true;
                            }
                        }
                        return false;

                    }).required('Date of service is required.'),
                    numberOfDays: Yup.number().when('numberOfHours', (Hour, schema) => {
                        if (Hour > 0) {
                            return schema;
                        } else {
                            return schema.min(1, "Number Of Days / Hours is required.").required('Number Of Days / Hours is required.');
                        }
                    })
                })}
                onSubmit={(values, { setErrors, resetForm }) => {
                    resetData = resetForm;
                    const errors = {};
                    let noErrors = true;

                    setErrors(errors);
                    if (noErrors) {
                        let paybleAmount = (Number(values.Service?.Advance_Amount) || 0);
                        if (useWallet) {
                            paybleAmount = (Number(values.Service?.Advance_Amount) || 0) - Number(getWalletDiscountPrice((Number(values.Service?.Advance_Amount) || 0), wallet?.Remaining_Balance));
                        }
                        if (paybleAmount > 0) {
                            razorpayPay(RazorPay, { user: user, amount: paybleAmount }, (status, response) => {
                                let formData = {};
                                if (status) {
                                    formData = {
                                        "City_ID": values.Service?.City_Id,
                                        "Advance_Amount": values.Service?.Advance_Amount,
                                        "Advance_Gateway_Payment_Amount": paybleAmount,
                                        "Advance_Gateway_Reference_Id": response.razorpay_payment_id,
                                        "Advance_Payment_Status": "SUCCESS",
                                        "Advance_Wallet_Payment_Amount": useWallet ? Number(getWalletDiscountPrice((Number(values.Service?.Advance_Amount) || 0), wallet?.Remaining_Balance)) : 0,
                                        "Price_Per_Day": values.Service?.Per_Daye_Rate,
                                        "Price_Per_Hour": values.Service?.Per_Hour_Rate,
                                        "Service_Price": ((Number(values.numberOfDays) ? Number(values.numberOfDays) * Number(values.Service?.Per_Daye_Rate) : 0) + (Number(values.numberOfHours) ? Number(values.numberOfHours) * Number(values.Service?.Per_Hour_Rate) : 0)),
                                        "Service_Request_Total_Time_In_Days": values.numberOfDays,
                                        "Service_Request_Total_Time_In_Hours": values.numberOfHours,
                                        "Service_Required_Date": values.dateOfService,
                                        "Service_Type": values.Service?.Service_Type,
                                        "User_Email_ID": EmailID,
                                        "Service_Required_Address_ID": values.Service_Required_Address_ID.User_Address_Id
                                    }

                                } else {
                                    formData = {
                                        "City_ID": values.Service?.City_Id,
                                        "Advance_Amount": values.Service?.Advance_Amount,
                                        "Advance_Gateway_Payment_Amount": paybleAmount,
                                        "Advance_Gateway_Reference_Id": "",
                                        "Advance_Payment_Status": "FAILED",
                                        "Advance_Wallet_Payment_Amount": useWallet ? Number(getWalletDiscountPrice((Number(values.Service?.Advance_Amount) || 0), wallet?.Remaining_Balance)) : 0,
                                        "Price_Per_Day": values.Service?.Per_Daye_Rate,
                                        "Price_Per_Hour": values.Service?.Per_Hour_Rate,
                                        "Service_Price": ((Number(values.numberOfDays) ? Number(values.numberOfDays) * Number(values.Service?.Per_Daye_Rate) : 0) + (Number(values.numberOfHours) ? Number(values.numberOfHours) * Number(values.Service?.Per_Hour_Rate) : 0)),
                                        "Service_Request_Total_Time_In_Days": values.numberOfDays,
                                        "Service_Request_Total_Time_In_Hours": values.numberOfHours,
                                        "Service_Required_Date": values.dateOfService,
                                        "Service_Type": values.Service?.Service_Type,
                                        "User_Email_ID": EmailID,
                                        "Service_Required_Address_ID": values.Service_Required_Address_ID.User_Address_Id
                                    }

                                }
                                let formValues = JSON.parse(JSON.stringify(formData));
                                setLoading(true);
                                dispatch(addService(formValues, createServiceResponse));
                            });
                        }
                        else {
                            let formData = {
                                "City_ID": values.Service?.City_Id,
                                "Advance_Amount": values.Service?.Advance_Amount,
                                "Advance_Gateway_Payment_Amount": "",
                                "Advance_Gateway_Reference_Id": "",
                                "Advance_Payment_Status": "SUCCESS",
                                "Advance_Wallet_Payment_Amount": useWallet ? Number(getWalletDiscountPrice((Number(values.Service?.Advance_Amount) || 0), wallet?.Remaining_Balance)) : 0,
                                "Price_Per_Day": values.Service?.Per_Daye_Rate,
                                "Price_Per_Hour": values.Service?.Per_Hour_Rate,
                                "Service_Price": ((Number(values.numberOfDays) ? Number(values.numberOfDays) * Number(values.Service?.Per_Daye_Rate) : 0) + (Number(values.numberOfHours) ? Number(values.numberOfHours) * Number(values.Service?.Per_Hour_Rate) : 0)),
                                "Service_Request_Total_Time_In_Days": values.numberOfDays,
                                "Service_Request_Total_Time_In_Hours": values.numberOfHours,
                                "Service_Required_Date": values.dateOfService,
                                "Service_Type": values.Service?.Service_Type,
                                "User_Email_ID": EmailID,
                                "Service_Required_Address_ID": values.Service_Required_Address_ID.User_Address_Id
                            }
                            setLoading(true);
                            let formValues = JSON.parse(JSON.stringify(formData));
                            dispatch(addService(formValues, createServiceResponse));
                        }
                    }

                }}
            >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                    <ScrollView>
                        <View style={{ flexDirection: 'row', marginTop: 10, paddingHorizontal: -7 }}>
                            {navigation.canGoBack() &&
                                <IconButton
                                    icon="arrow-left"
                                    // size={30}
                                    color={"#27B6CC"}
                                    style={{ marginVertical: -11, flex: 1 }}
                                    onPress={() => {
                                        navigation.goBack();
                                    }} />}
                            <View style={{ flex: 11 }}>
                                <Text style={{ fontSize: 20, fontWeight: "400", marginTop: -7, alignSelf: "flex-start", color: esiColor.brandFontColor }}>
                                    New Service
                                </Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5 }}>
                            <View>
                                <EsiSearchDropdown
                                    style={[Boolean(touched.Service_Required_Address_ID && errors.Service_Required_Address_ID) && { borderColor: '#b00020' }]}
                                    data={address}
                                    labelField="label"
                                    valueField="value"
                                    maxHeight={180}
                                    search
                                    placeholder='Service Address'
                                    defaultValue={values?.Service_Required_Address_ID ? {
                                        label: (values?.Service_Required_Address_ID.Address_Type + " " + "," + " " + values?.Service_Required_Address_ID.Street + " " + "," + " " + values?.Service_Required_Address_ID.City + " " + "," + " " + values?.Service_Required_Address_ID.State + " " + "," + " " + values?.Service_Required_Address_ID.Zip).slice(0, 30),
                                        value: JSON.stringify(values?.Service_Required_Address_ID)
                                    } : ""}
                                    className="address"
                                    onUpdateValue={(item: any) => {
                                        if (item.value === "Add New Address") {
                                            setFieldValue("Service_Required_Address_ID", "");
                                            navigation.navigate('AddAddress', { page: 'services' });
                                        } else {
                                            setFieldValue("Service_Required_Address_ID", JSON.parse(item.value));
                                        }
                                    }}
                                    value={values?.Service_Required_Address_ID ? JSON.stringify(values?.Service_Required_Address_ID) : ''}
                                    error={Boolean(touched.Service_Required_Address_ID && errors.Service_Required_Address_ID)}
                                />
                                <HelperText style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }} type="error" visible={Boolean(touched.Service_Required_Address_ID && errors.Service_Required_Address_ID)}>
                                    {touched.Service_Required_Address_ID && errors.Service_Required_Address_ID}
                                </HelperText>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5, paddingHorizontal: 15 }}>
                            <View>
                                {Platform.OS == "android" ?
                                    <TouchableOpacity style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width), paddingHorizontal: 15 }} onPress={() => {
                                        if (values.Service_Required_Address_ID?.Zip)
                                            sertOpenSearchModel(true);
                                    }} >
                                        {serviceTextBox(handleChange, handleBlur, handleSubmit, values, errors, touched)}
                                    </TouchableOpacity> : <TouchableOpacityIos style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }} onPress={() => {
                                        if (values.Service_Required_Address_ID?.Zip)
                                            sertOpenSearchModel(true);
                                    }} >
                                        {serviceTextBox(handleChange, handleBlur, handleSubmit, values, errors, touched)}
                                    </TouchableOpacityIos>}
                                <HelperText style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }} type="error" visible={Boolean(touched.Service && errors.Service)}>
                                    {touched.Service && errors.Service}
                                </HelperText>
                            </View>
                        </View>



                        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5 }}>
                            <View>
                                <EsiDatePicker
                                    style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width), margin: "auto" }}
                                    name={'Date Of Service'}
                                    label='Date Of Service'
                                    value={values.dateOfService}
                                    valueUpdate={item => {
                                        setFieldValue("dateOfService", item);
                                    }}
                                    error={Boolean(touched.dateOfService && errors.dateOfService)}
                                />
                                <HelperText style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }} type="error" visible={Boolean(touched.dateOfService && errors.dateOfService)}>
                                    {touched.dateOfService && errors.dateOfService}
                                </HelperText>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5 }}>
                            <View>
                                <ESITextInput
                                    style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }}
                                    label="Number Of Days"
                                    mode='outlined'
                                    value={values.numberOfDays}
                                    keyboardType="numeric"
                                    onChangeText={(text) => {
                                        setFieldValue("numberOfDays", text.replace(/[^0-9]/g, ''));

                                    }}
                                    editable={values.Service ? true : false}
                                    error={Boolean(touched.numberOfDays && errors.numberOfDays)}
                                />
                                <HelperText style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }} type="error" visible={Boolean(touched.numberOfDays && errors.numberOfDays)}>
                                    {touched.numberOfDays && errors.numberOfDays}
                                </HelperText>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5 }}>
                            <View>
                                <ESITextInput
                                    style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }}
                                    label="Number Of Hours"
                                    mode='outlined'
                                    value={values.numberOfHours}
                                    keyboardType="numeric"
                                    onChangeText={(text) => {
                                        setFieldValue("numberOfHours", text.replace(/[^0-9]/g, ''));
                                    }}
                                    error={Boolean(touched.numberOfDays && errors.numberOfDays)}
                                    editable={values.Service ? true : false}
                                />
                                <HelperText style={{ minWidth: (dimensions.width > 300 ? 300 : dimensions.width) }} type="error" visible={Boolean(touched.numberOfDays && errors.numberOfDays)}>
                                    {touched.numberOfDays && errors.numberOfDays}
                                </HelperText>
                            </View>
                        </View>

                        {(Number(values.numberOfDays) > 0 || Number(values.numberOfHours) > 0) && <>
                            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5, marginHorizontal: 30 }}>
                                <View style={{ flex: 5 }} >
                                    <Text style={{ fontWeight: "bold", color: esiColor.DescColor }} > Total Service Amount </Text>
                                </View>
                                <View style={{ flex: 2 }} >
                                    <Text style={{ fontWeight: "bold", color: "#27b6cc" }} >: ₹{(Number(values.numberOfDays) ? Number(values.numberOfDays) * Number(values.Service?.Per_Daye_Rate) : 0) + (Number(values.numberOfHours) ? Number(values.numberOfHours) * Number(values.Service?.Per_Hour_Rate) : 0)}/- </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5, marginHorizontal: 30 }}>
                                <View style={{ flex: 5 }} >
                                    <Text style={{ fontWeight: "bold", color: esiColor.DescColor }} > Advance Amount </Text>
                                </View>
                                <View style={{ flex: 2 }} >
                                    <Text style={{ fontWeight: "bold", color: "#27b6cc" }} >: ₹{Number(values.Service?.Advance_Amount) || 0}/- </Text>
                                </View>
                            </View>
                            {(Number(wallet?.Remaining_Balance) > 0 && (Number(values.Service?.Advance_Amount) || 0) > 0) && <>
                                <View style={{ marginTop: 5, marginHorizontal: 30 }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setUseWallet(!useWallet);
                                        }}
                                    >
                                        <View style={{
                                            flexDirection: "row",
                                            marginTop: -10
                                        }}>
                                            <Checkbox
                                                status={useWallet ? 'checked' : 'unchecked'}
                                            />
                                            <Text style={{
                                                marginTop: 10, color: esiColor.itemColor
                                            }}>{"Use Wallet Amount ₹ " + getWalletDiscountPrice((Number(values.Service?.Advance_Amount) || 0), wallet?.Remaining_Balance)} /-</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                {useWallet &&
                                    <View style={{ flexDirection: 'row', marginTop: 5, marginHorizontal: 30 }}>
                                        <View style={{ flex: 5 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: 'bold', marginLeft: 5 }}>Wallet Amount  </Text>
                                        </View>
                                        <View style={{ flex: 2 }} >
                                            <Text style={{ color: '#27b6cc', fontWeight: 'bold' }}>
                                                : -₹ {getWalletDiscountPrice((Number(values.Service?.Advance_Amount) || 0), wallet?.Remaining_Balance)} /-
                                            </Text>
                                        </View>

                                    </View>}
                                {useWallet &&
                                    <View style={{ flexDirection: 'row', marginTop: 5, marginHorizontal: 30 }}>
                                        <View style={{ flex: 5 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: 'bold', marginLeft: 5 }}>Remaining Advance Amount </Text>
                                        </View>
                                        <View style={{ flex: 2 }} >
                                            <Text style={{ color: '#27b6cc', fontWeight: 'bold' }}>
                                                : ₹ {(Number(values.Service?.Advance_Amount) || 0) - Number(getWalletDiscountPrice((Number(values.Service?.Advance_Amount) || 0), wallet?.Remaining_Balance))} /-
                                            </Text>
                                        </View>

                                    </View>}
                            </>}
                        </>}
                        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 10 }}>
                            {loading ? <ActivityIndicator color={"#279bcc"} /> :

                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, maxWidth: 200, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                                    handleSubmit();
                                }}>
                                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>Book Service</Text>
                                </TouchableOpacity>}
                        </View>

                        <Modal animationIn={"slideInDown"}
                            deviceWidth={dimensions.width}
                            deviceHeight={dimensions.height}
                            style={{ alignItems: 'center' }} isVisible={openSearchModel}>
                            <View style={{ flex: 1, maxWidth: dimensions.width - 50, minWidth: 300, minHeight: 400, maxHeight: dimensions.height - 150, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.2 }}>
                                <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10 }} onPress={() => {
                                    sertOpenSearchModel(!openSearchModel);
                                }}>
                                    <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                                </TouchableRipple>
                                <ServiceSearch zipcode={values.Service_Required_Address_ID.Zip} maxHeight={dimensions.height - 150} setService={(data) => {
                                    setFieldValue("Service", data);
                                    sertOpenSearchModel(!openSearchModel);
                                }} />
                            </View>
                        </Modal>
                    </ScrollView>
                )}
            </Formik>



            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
        </Surface>

    );
}



function ServiceSearch(props: any) {
    const [search, setSearch] = useState("");
    let [searchOld, setSearchOld] = useState("");
    const [loading, setLoading] = useState(false);
    const [citys, setCitys] = useState([]);
    const responseOfCity = (status, response) => {
        setLoading(false);
        if (status) {
            setCitys(response)
        } else {
            setCitys([])
        }
    }

    const dispatch = useDispatch();
    useEffect(() => {
        if (search) {
            searchaserviceCall();
        } else {
            setCitys([]);
        }

    }, [search, loading])

    const searchaserviceCall = () => {
        if (search) {
            if ((search != searchOld) && !loading) {
                setLoading(true);
                setSearchOld(search);
                dispatch(getCityServices({
                    Zip_Code: props.zipcode,
                    Service_Name: search
                }, responseOfCity));
            }
        } else {
            setCitys([]);
        }
    }
    const esiColor = useSelector(state => state.theme);

    return (
        <View style={{ flexDirection: 'column', marginTop: 30 }} >
            <View style={{ flexDirection: "row", justifyContent: "center" }} >
                <ESITextInput
                    style={{ width: "90%", margin: "auto" }}
                    label="Search"
                    mode='outlined'
                    value={search}
                    onChangeText={(text) => {
                        setSearch(text)
                    }}
                    left={<TextInput.Icon color={"#27b6cc"} name={"card-search-outline"} />}
                    right={<TextInput.Icon onPress={() => {
                        setSearch("");
                    }} color={"#27b6cc"} name={"close"} />}
                />
            </View>
            <View >
                {loading ?
                    <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 5 }} >
                        <ActivityIndicator color={"#27b6cc"} />
                    </View> :
                    <ScrollView style={{ maxHeight: props.maxHeight * 0.8 }} >
                        {search ? citys.length <= 0 ?
                            <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 5 }} >
                                <Text style={{ color: esiColor.NoDataFound }}> No Services are availabile with given search. </Text>
                            </View> :
                            <FlatList
                                contentContainerStyle={{ alignItems: "center" }}
                                data={citys.slice(0, 10)}
                                renderItem={({ item, index, separators }) => (
                                    <TouchableOpacity key={index} style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        shadowOffset: { width: -2, height: 4 },
                                        shadowOpacity: 0.2,
                                        shadowRadius: 3,
                                        backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 15 : 5),
                                        elevation: 10,
                                        borderColor: esiColor.SBorderColor,
                                        borderWidth: 0.5,
                                        borderRadius: 10,
                                        width: "90%",
                                        minWidth: 250,
                                        flexDirection: 'row'
                                    }} onPress={() => {
                                        props.setService(item);
                                    }} >
                                        <View style={{ flex: 1, flexDirection: 'column' }}  >
                                            {item?.Image_URL &&
                                                <Image source={{ cache: "force-cache", uri: item?.Image_URL }} style={{ borderRadius: 8, marginLeft: 5, height: 50, width: 50, resizeMode: 'contain' }}></Image>
                                            }
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.DescColor }}>{item?.Service_Type}</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.DescColor }}>Day Price   :{item?.Per_Daye_Rate}</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.DescColor }}>Hour Price  :{item?.Per_Hour_Rate}</Text>
                                        </View>
                                    </TouchableOpacity>
                                )}
                            />
                            :
                            <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 5 }} >
                                <Text style={{ color: esiColor.DescColor }}> Please search to get Services. </Text>
                            </View>
                        }
                    </ScrollView>
                }

            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    dropdown: {
        height: 58,
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    centerElement: { justifyContent: 'center', alignItems: 'center' },

});