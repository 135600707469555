import React from 'react';
import { Text, View, Image, Platform, Dimensions } from 'react-native';
import { Surface } from 'react-native-paper';
import { useSelector } from 'react-redux';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const EsiCityCard = (props: any) => {

  // item is brand object , using this object item to display info.
  const { item, navigate, navigation, ...rest } = props
  const esiColor = useSelector(state => state.theme);
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const halfdimensions = dimensions;
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  return (
    <Surface theme={{
      colors: {
        surface: esiColor.BackgroundColor,
      }
    }} style={{
      marginHorizontal: 2, borderRadius: 10, padding: 2, margin: 'auto', backgroundColor: esiColor.CBColor,
      marginBottom: 15, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
      // borderColor: "#CACFD2", borderWidth: 0.5,
      // shadowOffset: { width: 0, height: 2 },
      // shadowOpacity: 0.2,
      shadowRadius: 7,
      alignItems: 'flex-start',
      height: 100,
      maxWidth: (halfdimensions - 10), width: (halfdimensions - 20),
    }}>
      <View style={{ flex: 2, justifyContent: 'center', margin: "auto" }} >
        <Image source={item?.Image_Path ? { uri: item?.Image_Path + `?w=100&h=90` } : require('../../assets/images/custom-components/flag.png')}
          style={{ height: 90, width: '100%', resizeMode: 'contain' }}></Image>
      </View>
      <View style={{ flex: 5, flexDirection: 'column', marginTop: (Platform.OS === 'web' ? 20 : null), }}  >
        <Text style={{ fontSize: 14, fontWeight: 'bold', marginLeft: (Platform.OS === 'web' ? null : 50), color: esiColor.DescColor }}>{item.Country_Name}</Text>
        <Text style={{ fontSize: 14, fontWeight: 'bold', margin: 'auto', marginLeft: (Platform.OS === 'web' ? null : 50), color: esiColor.itemColor }}>{item.Name}</Text>
        <Text style={{ fontSize: 14, margin: 'auto', marginLeft: (Platform.OS === 'web' ? null : 50), color: esiColor.itemColor }}>
          {item?.State_Name}</Text>
      </View>
    </Surface>
  );
};

export default EsiCityCard;

