import React, { useEffect, useState } from "react";
import { ActivityIndicator, Card, Surface, Text, IconButton } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import { Image, StyleSheet, View, ScrollView, Platform, Dimensions, RefreshControl } from "react-native";
import { getFilterCategoriesAction } from "../../state/actions/categoriesActions";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";

export default function AllCategory(props) {
  // api using redux
  const { navigation } = props;
  const dispatch = useDispatch();
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);

  const halfdimentions = dimensions / 2;
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [mainCategory, setMainCategory] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState("");

  const [loading, setLoading] = useState(false);

  const categeyCallback = (data) => {
    setLoading(false);
    if (!(data?.Is_Data_Exist === "0")) {
      setMainCategory(data.Category || []);
    } else {
      setMainCategory([]);
    }
  }
  let type = props?.route?.params?.type
  useEffect(() => {

    onRefresh();

  }, [props?.route?.params?.Store_Type_Name]);

  const onRefresh = () => {
    let formData = {};
    if (type == "store") {
      formData = {
        "Store_Type_Name": props?.route?.params?.Store_Type_Name,
        "Records_Filter": "FILTER"
      }
    } else {
      formData = {
        "Records_Filter": "ALL"
      }
    }
    setLoading(true);
    dispatch(getFilterCategoriesAction(formData, categeyCallback));
  };
  return (
    <Surface style={{ flex: 1, position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null, backgroundColor: esiColor.BackgroundColor}}>
      <Header {...props} />
      <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center' }}>
        <Text style={{ fontSize: 22, fontWeight: "bold", alignSelf: 'center', color: esiColor.brandFontColor, margin: 2 }}>All Categories</Text>
      </View>
      {navigation.canGoBack() &&
        <IconButton
          icon="arrow-left"
          size={25}
          color={"#27B6CC"}
          style={{ marginTop: (Platform.OS === 'web' ? -38 : -40), marginLeft: -6 }}
          onPress={() => navigation.goBack()}
        />}

      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={onRefresh}
          />
        }
      >
        {loading ?
          <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 20 }} />
          :
          <View style={styles.Card}>
            {mainCategory.length === 0 ?
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Text style={{color: esiColor.NoDataFound}}>No categories available to display</Text>
              </View> :
              mainCategory.map((item, index) => {
                return (
                  <View key={index} style={{ padding: 4, marginBottom: 30, maxWidth: (halfdimentions), width: (halfdimentions) }}>
                    <Card style={{
                      height: 140, maxHeight: 140, margin: 1,
                      shadowColor: esiColor.brandShadowColor, borderRadius: 2, shadowRadius: 10, elevation: 10, opacity: 10
                    }} onPress={(e) => navigation.navigate('SubCategory', { category_id: item?.Category_Id })} >
                      <View >
                        <Image source={{ cache: "force-cache", uri: item?.Category_Image_Mobile + `?h=140` }}
                          style={{
                            height: 140, maxHeight: 140,
                            shadowColor: "#CACFD2", borderRadius: 2, shadowRadius: 10, elevation: 10, opacity: 10
                          }}
                        />
                      </View>
                    </Card>
                    <Text style={{ margin: 2, flex: 1, color: esiColor.itemColor, fontSize: 14, fontWeight: 'bold', textAlign: 'center', paddingVertical: 4 }}>{item?.Category_Name}</Text>
                  </View>
                )
              })
            }
          </View>
        }
      </ScrollView>

    </Surface>
  );
}
const styles = StyleSheet.create({
  Card: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
  },
});
