import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  uploadProfileAction, editProfileAction
} from "../../state/actions/profileActions";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Text, } from 'react-native-paper';
import { StyleSheet, View, Dimensions, } from 'react-native';
import ProfileUpload from '../../components/custom/image-upload/profileupload';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import { uriToBlob } from '../../../utils/uriBlob';

const Profile = ({ profiletemp, callgetuser, loading, setLoading, ...rest }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const toast = useToast();

  // Image upload

  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);



  const callBackUploadImage = (data) => {
    if (data) {
      setImgUrl(data);
    }
    setUploadPic(false);
    if (data?.File_URL) {
      setUploadPic(true);
      dispatch(
        editProfileAction(callBackUploadImageUrl, { User_Details_Id: profiletemp.User_Details_Id, Profile_Pic_Path: data?.File_URL })
      );
    }
  };

  const callBackUploadImageUrl = (data) => {
    setUploadPic(false);
    callgetuser();
  };

  const handleImage = async (result) => {
    try {
      const img = await fetchImageFromUri(result.uri);
      setImage({
        preview: result.uri,
        raw: img
      });
      let localUri = result.uri;
      let uriParts = result.uri.split('.');
      let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
      let filformate = fileExtension[0].split(':')
      let imgExtension = filformate
      try {
        filformate[1].split('/')[1]
      } catch {

      }
      // Infer the type of the image
      let fileData = {
        fileName: localUri.split('/').pop(),
        ext: imgExtension,
      };
      dispatch(
        uploadProfileAction(img, fileData, callBackUploadImage)
      );
    } catch (error) {
    }
  };

  const fetchImageFromUri = async (uri) => {
    const blob = await uriToBlob(uri);
    return blob;
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  //Toast message display

  const formData = (data, loading) => {
    setLoading(false);
    toast.show({ message: 'Image uploaded Successfully.', type: 'info', duration: 3000, position: 'top' });
    // setCurrentUrl(data.File_URL);
    callgetuser();
  };



  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

  useEffect(() => {
    if (profiletemp) {
      setImage({
        preview: profiletemp?.Profile_Pic_Path,
        raw: profiletemp?.Profile_Pic_Path
      });
      setImgUrl(profiletemp?.Profile_Pic_Path)
    }
  }, [imgUrl, profiletemp]);
  return (

    <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
      {profiletemp &&
        <Card style={{
          width: dimensions <= 500 ? "98%" : 500, marginLeft: "auto", marginRight: "auto",
          marginTop: "2%", marginBottom: "2%"
        }}>
          <Card.Content>

            <View style={styles.img} >
              <View style={{ flexShrink: 1, paddingTop: 20, width: 500 }} >
                <ProfileUpload handleRemoveImage={handleRemoveImage} image={image.preview} uploadPic={handleImage} />
              </View>

              <View style={{ flex: 1, alignItems: 'center', flexDirection: "column", flexWrap: 'wrap' }}>
                <Text> {profiletemp?.Full_Name}</Text>

                <Text> {profiletemp?.Email_Id}</Text>
                <Text>{profiletemp?.Mobile}</Text>
              </View>

            </View>
          </Card.Content>
        </Card>
      }
    </View>

  );
};

const styles = StyleSheet.create({

  img: {
    justifyContent: 'center',
    alignItems: 'center',


  },
})

Profile.propTypes = {

  profiletemp: PropTypes.object,
  callgetuser: PropTypes.func
};

export default Profile;

