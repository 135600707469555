import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import Modal from "react-native-modal";
import { Avatar, Button, HelperText, Surface, TouchableRipple } from "react-native-paper";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { updateUserBiddingRide } from "../../../../state/actions/boomi-actions/bid-ride-actions";
import EsiRating from "../../../../components/custom/Rating/rating";
import { useSelector } from "react-redux";



export default function Review(props) {
    const { dimensions, delPartner, rideDetails, getRideDetails, dispatch, vehicals, ...rest } = props;
    const [addReviewPop, setAddReviewPop] = useState(false);
    const [loadingForm, setloadingForm] = useState(false);
    const esiColor = useSelector(state => state.theme);

    const reviewCallBack = (status, data) => {
        setloadingForm(false);
        if (status) {
            setAddReviewPop((prop) => !prop);
            getRideDetails();
        }
    }


    return (
        <View style={{ flexDirection: "column", backgroundColor: esiColor.BackgroundColor }} >
            <Text style={{ fontWeight: "bold", color: esiColor.DescColor, textAlign: "center" }}>Review</Text>
            <View style={{ flexDirection: 'column', margin: 10 }}>

                {rideDetails?.User_Ratings ?
                    <View style={{ flexDirection: 'column' }}>
                        <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, marginVertical: 5, fontWeight: "500", color: esiColor.DescColor }}>Vehicles Rating:</Text>
                            <EsiRating
                                type='heart'
                                // ratingBackgroundColor='#c8c7c8'
                                tintColor={esiColor.TintColor}
                                startingValue={rideDetails?.Vehicle_Ratings || 0}
                                ratingCount={5}
                                imageSize={20}
                                style={{ paddingVertical: 5 }}
                                readonly={true}
                            />
                        </View>
                        <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, marginVertical: 5, fontWeight: "500", color: esiColor.DescColor }}>Vehicles Review:</Text>
                            <Text style={{ fontSize: 12, marginLeft: 5, color: esiColor.itemColor }}>{rideDetails?.Vehicle_Reviews}</Text>

                        </View>
                        <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, marginVertical: 5, fontWeight: "500", color: esiColor.DescColor }}>Ride Partner Rating:</Text>
                            <EsiRating
                                type='heart'
                                // ratingBackgroundColor='#c8c7c8'
                                tintColor={esiColor.TintColor}
                                startingValue={rideDetails?.User_Ratings || 0}
                                ratingCount={5}
                                imageSize={20}
                                style={{ paddingVertical: 5 }}
                                readonly={true}
                            />
                        </View>
                        <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, marginVertical: 5, fontWeight: "500", color: esiColor.DescColor }}>Partner Review:</Text>
                            <Text style={{ fontSize: 12, marginLeft: 5, color: esiColor.itemColor }}>{rideDetails?.User_Reviews}</Text>
                        </View>
                    </View>
                    :
                    <View style={{ flex: 1, alignSelf: 'center', }}>
                        <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, width: 110, height: 30, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }} onPress={() => { setAddReviewPop(prop => !prop) }}>
                            <Text style={{ color: esiColor.itemButtenColor }}>ADD REVIEW</Text>
                        </TouchableOpacity>
                    </View>}
            </View>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={addReviewPop}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <Surface style={{
                        justifyContent: 'center',
                        borderRadius: 10,
                        top: Platform.OS === 'web' ? 10 : 20,
                        elevation: 0
                    }} >
                        <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                            setAddReviewPop((prop) => !prop);
                        }}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                        <Formik
                            initialValues={
                                {
                                    Vehicle_Ratings: 0,
                                    Vehicle_Reviews: '',
                                    User_Ratings: 0,
                                    User_Reviews: '',

                                }
                            }
                            validationSchema={Yup.object().shape({
                                Vehicle_Ratings: Yup.number().min(1, "Please provide Rating.").required("Please provide Rating."),
                                User_Ratings: Yup.number().min(1, "Please provide Rating.").required("Please provide Rating."),
                                Vehicle_Reviews: Yup.string().min(10, "Please provide minimum 10 characters.").required("Review is required."),
                                User_Reviews: Yup.string().min(10, "Please provide minimum 10 characters.").required("Review is required."),
                            })}
                            onSubmit={values => {
                                setloadingForm(true);
                                let formData = {
                                    "User_Ride_Bidding_Id": rideDetails?.User_Ride_Bidding_Id,
                                    "User_Ratings": values.User_Ratings,
                                    "User_Reviews": values.User_Reviews,
                                    "Vehicle_Ratings": values.Vehicle_Ratings,
                                    "Vehicle_Reviews": values.Vehicle_Reviews
                                }
                                dispatch(updateUserBiddingRide(formData, reviewCallBack));
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                <View style={{
                                    flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                }}>
                                    {
                                        <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>

                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.Text }}>Vehicles Rating:</Text>
                                                <EsiRating
                                                    type='heart'
                                                    // ratingBackgroundColor='#c8c7c8'
                                                    tintColor={esiColor.TintColor}
                                                    startingValue={values.Vehicle_Ratings}
                                                    ratingCount={5}
                                                    imageSize={30}
                                                    style={{ paddingVertical: 10 }}
                                                    onFinishRating={(value) => {
                                                        setFieldValue("Vehicle_Ratings", value)
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Vehicle_Ratings && errors.Vehicle_Ratings)}>
                                                    {touched.Vehicle_Ratings && errors.Vehicle_Ratings}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.Text }}>Vehicles Review:  </Text>
                                                <EsiTextAreaInput
                                                    error={Boolean(touched.Vehicle_Reviews && errors.Vehicle_Reviews)}
                                                    containerStyle={{ height: 85 }}
                                                    style={{ height: 85 }}
                                                    mode='outlined'
                                                    value={values.Vehicle_Reviews}
                                                    textareavalueUpdate={value => {
                                                        setFieldValue("Vehicle_Reviews", value)
                                                    }}
                                                    placeholder={'Please provide vehicle review.'}
                                                    underlineColorAndroid={'transparent'}
                                                
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Vehicle_Reviews && errors.Vehicle_Reviews)}>
                                                    {touched.Vehicle_Reviews && errors.Vehicle_Reviews}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.Text }}>Ride Partner Rating:  </Text>
                                                <EsiRating
                                                    type='heart'
                                                    // ratingBackgroundColor='#c8c7c8'
                                                    tintColor={esiColor.TintColor}
                                                    startingValue={values.User_Ratings}
                                                    ratingCount={5}
                                                    imageSize={30}
                                                    style={{ paddingVertical: 10 }}
                                                    onFinishRating={(value) => {
                                                        setFieldValue("User_Ratings", value)
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.User_Ratings && errors.User_Ratings)}>
                                                    {touched.User_Ratings && errors.User_Ratings}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.Text }}>Partner Review:  </Text>
                                                <EsiTextAreaInput
                                                    error={Boolean(touched.User_Reviews && errors.User_Reviews)}
                                                    containerStyle={{ height: 85 }}
                                                    style={{ height: 80 }}
                                                    mode='outlined'
                                                    value={values.User_Reviews}
                                                    textareavalueUpdate={value => {
                                                        setFieldValue("User_Reviews", value)
                                                    }}
                                                    placeholder={'Please provide ride partner review.'}
                                                    underlineColorAndroid={'transparent'}
                                               
                                                />
                                                <HelperText type="error" visible={Boolean(touched.User_Reviews && errors.User_Reviews)}>
                                                    {touched.User_Reviews && errors.User_Reviews}
                                                </HelperText>
                                            </View>

                                            <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                <Button disabled={loadingForm} style={{ marginTop: 5, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {
                                                    handleSubmit()
                                                }}>
                                                    <Text style={{ color: esiColor.itemButtenColor }}>SUBMIT</Text>
                                                </Button>

                                            </View>
                                        </View>}
                                </View>)}
                        </Formik>
                    </Surface>

                </View>
            </Modal>
        </View>
    )
}