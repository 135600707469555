import React, { useEffect } from "react";
import { Dimensions, FlatList,  StyleSheet, TouchableOpacity, View } from "react-native";
import { Searchbar, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/custom/pagination/pagination";
import ProductCard from "../../components/custom/product-card";
import { getProductsAction } from "../../state/actions/node-actions/home-actions";

export default function PProducts(props) {
    const { navigation, partnerId } = props;
    const dispatch = useDispatch();
    const [wishdata, setWishData] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const esiColor = useSelector(state => state.theme);
    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);

    const [pageOld, setPageOld] = React.useState(false);

    const callBackGetData = async (status, data, pagination) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            setPageOld(true);
            if (pagination) {
                setPagination(pagination);
            } else {
                setPagination({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
            }
        }
    }

    const [search, setSearch] = React.useState("");
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });

    useEffect(() => {
        if (pageOld) {
            setPageOld(!pageOld)
        } else {
            productsCall();
        }
    }, [partnerId, pagination, search]);

    const productsCall = () => {
        if (!loading && partnerId) {
            setLoading(true);
            dispatch(getProductsAction({ "Records_Filter": "FILTER", "Status": "Active", "condition": true, "Partner_Details_Id": [partnerId], "Search": search }, pagination, callBackGetData));
        }
    }

    const handleClick = async (item) => {
        navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata, back: "PartnerProducts" })
    };

    return (
        <View >
            <View style={{ flexDirection: 'row', justifyContent: "center", width: "100%" , backgroundColor: esiColor.BackgroundColor}}>
                <Searchbar style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor,flex: 1, minWidth: "95%", maxWidth: "95%", borderWidth: 0.5, maxHeight: 30, marginTop: 10, alignSelf: "center" }}
                    inputStyle={{ fontSize: 12, marginLeft: -20 }}
                    value={search}
                    onChangeText={(value) => {
                        setSearch(value);
                    }}
                    iconColor={esiColor.SIconColor}
                    placeholderTextColor={esiColor.SPHTextColor}
                    theme={{ colors: { text: esiColor.SBTextColor } }}
                    multiline={false}
                    placeholder="Search products"
                />
            </View>

            <View style={{ flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'center',alignItems:"center", width: "100%", backgroundColor: esiColor.BackgroundColor }}>
                {allproducts.length <= 0 ? (
                    <View style={{ width: dimensions.width - 50, flex: 1, justifyContent: "center" }}>
                        <Text style={{ alignSelf: "center" , color: esiColor.NoDataFound}} >No Products found!</Text>
                    </View>
                ) : (
                    <FlatList
                        // horizontal={true}
                        columnWrapperStyle={{ justifyContent: "space-around" }}
                        numColumns={(dimensions.width/250).toFixed()==1?2:(dimensions.width/250).toFixed()}
                        data={allproducts}
                        renderItem={({ item, index, separators }) => (
                            <ProductCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />
                        )}
                    />
                )}

            </View>
            <View style={{ marginBottom: 5 }}>
                <Pagination pagination={pagination} setPagination={setPagination} />
            </View>
        </View>
    )
}
