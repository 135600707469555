import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Searchbar, Surface, Text, ActivityIndicator, Colors, Button, TouchableRipple, Avatar, Checkbox, Appbar, Menu, IconButton } from 'react-native-paper';
import { View, Linking, TouchableOpacity, Platform, Image, RefreshControl, ScrollView, FlatList } from 'react-native';
import Header from "../header";
import { getFarmersAction, getFarmersActions } from "../../state/actions/farmerActions";
import isEmpty from "../../state/validations/is-empty";
import { Dimensions } from 'react-native';
import { EvilIcons } from "@expo/vector-icons";
import Pagination from "../../components/custom/pagination/pagination";
 
export default function Farmers(props: any) {
    const { navigation, ...rest } = props
    //API dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFarmersAction());
    }, []);

    const [loading, setIsLoading] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const [searchBarOpen, setSearchBarOpen] = React.useState(false);
    const [pageOld, setPageOld] = React.useState(false);
    const [farms, setfarms] = useState([]);

    const [filters, setFilters] = React.useState({
        "search": "",
        "search_by_filter": "",
    });
 
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
    const callBackGetData = async (status, farmers, pagination) => {
        // goToTop();

        setIsLoading(false);
        if (status) {
            setfarms(farmers);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            farmersCall();
        }
    }, [pagination])
    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
            setTestFilters(filters);
            farmersCall();
        }
    }, [filters]);
    // search and set search is used for the search functionality
    const farmersCall = () => {
        setIsLoading(true);
        dispatch(getFarmersActions(filters,
            pagination,
            callBackGetData
        ));
    };

    const scroll = useRef(null);
    const esiColor = useSelector(state => state.theme);

    return (
        <Surface style={{ flex: 1, position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null, backgroundColor: esiColor.BackgroundColor }}
            theme={{
                colors: {
                    surface: esiColor.BackgroundColor,
                }
            }}
        >
            <Header {...props} />
            <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center' }}>
                <Text style={{ fontSize: 22, fontWeight: "bold", alignSelf: 'center', color: esiColor.brandFontColor, margin: 2 }}>All Farmers</Text>
            </View>

            {navigation.canGoBack() &&
                <IconButton
                    icon="arrow-left"
                    size={25}
                    color={"#27B6CC"}
                    style={{ marginTop: (Platform.OS === 'web' ? -38 : -40), marginLeft: -6 }}
                    // onPress={() => navigation.goBack()}
                    onPress={() => navigation.navigate('Home')}

                />}

            <View style={{ marginBottom: 15 }}>

                {searchBarOpen || filters?.search?.length > 0 ?
                    <Searchbar iconColor={esiColor.LCHFontColor} style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor,backgroundColor: esiColor.BackgroundColor, borderWidth: 0.5, maxHeight: 30 }}
                        inputStyle={{ fontSize: 12, marginLeft: -20, color: esiColor.Text }}
                        value={filters?.search}
                        autoFocus
                        onChangeText={(value) => {
                            setPageOld(true);
                            setPagination({ PageNo: 1, PageSize: 30, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" });
                            setFilters({
                                ...filters,
                                search: value,
                            });
                        }}
                        onBlur={() => {
                            setSearchBarOpen(false);
                        }}
                        onPressOut={() => {
                            setSearchBarOpen(false);
                        }}
                        placeholderTextColor={esiColor.SPHTextColor}
                        theme={{ colors: { text: esiColor.TextColor } }}
                        multiline={false}
                        placeholder="Search"
                        icon={() => <EvilIcons
                            name="search"
                            size={20}
                            color={esiColor.LCHFontColor}
                        // style={{marginHorizontal:-15}}
                        />}
                    />
                    :
                    <EvilIcons
                        name="search"
                        size={30}
                        color={esiColor.LCHFontColor}
                        style={{ alignSelf: "flex-end", marginRight: 15 }}
                        onPress={() => { setSearchBarOpen(true) }}
                    />
                }
            </View>
            <ScrollView ref={scroll} style={{ flexGrow: 1, flex: 2 }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={farmersCall}
                    />
                }
            >
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto' }}>
                    {isEmpty(farms) ? (
                        !loading &&
                        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                            <Text style={{ color: esiColor.NoDataFound }}>No farmers found!</Text>
                        </View>
                    ) : (<FlatList
                        // horizontal={true}
                        // columnWrapperStyle={{ justifyContent: "space-around" }}
                        data={farms}
                        renderItem={({ item, index, separators }) => ( 

                            <Surface
                                theme={{
                                    colors: {
                                        surface: esiColor.BackgroundColor,
                                    }
                                }}
                                style={{
                                    marginHorizontal: 2, borderRadius: 10, margin: 'auto',
                                    marginBottom: 15, flexDirection: 'column',
                                    shadowColor: "#A1E1C7",
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 2,
                                    shadowRadius: 10, borderWidth: 0.5,
                                    alignItems: 'flex-start', borderColor: "#A1E1C7",
                                }}>

                                <TouchableOpacity onPress={() => {
                                    navigation.navigate('FarmerDetails', { Partner_Details_Id: item.Partner_Details_Id, back: rest.route.key });

                                }}>
                                    <View style={{ justifyContent: 'center', flexDirection: "row", marginHorizontal: dimensions * 0.04 }} >
                                        <Image source={{ cache: "force-cache", uri: item?.Logo_Path + `?w=${dimensions * 0.7}&h=150` }} style={{ height: 150, marginTop: 10, width: dimensions * 0.7, alignSelf: "center", opacity: 1, borderRadius: 8 }} resizeMode='stretch' ></Image>
                                    </View>
                                    <View style={{ flexDirection: 'column', marginTop: 10, marginBottom: 10 }}  >
                                        <Text style={{ fontSize: 20, fontWeight: 'bold', color: esiColor.itemColor }}>{item.Name}</Text>

                                    </View>
                                </TouchableOpacity>
                            </Surface>
                        )}
                    />
                    )
                    }
                </View>
                <View style={{ marginBottom: Platform.OS == "web" ? -10 : 5 }}>
                    <Pagination pagination={pagination} setPagination={setPagination} />
                </View>
            </ScrollView>

        </Surface>
    );
}