import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl } from "react-native";
import { Badge, Searchbar, Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import ordergif from "../../../assets/images/GIF/biddingImage.png";
import { getBiddingProductsCart, getMyBidProductsAction } from "../../../state/actions/node-actions/bid-products-actions";
import { useFocusEffect, useRoute } from "@react-navigation/native";
import { FontAwesome5 } from "@expo/vector-icons";

export default function MyBidProducts(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const esiColor = useSelector(state => state.theme);
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions;


    const [ordersFilter, setOrdersFilter] = useState({ Search: "" });

    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "USER_PRODUCTS_BIDDING_ID", SortOrder: "DESC" });
    const [loading, setIsLoading] = React.useState(false);
    const [myBids, setMyBids] = React.useState([]);



    const dispatch = useDispatch();

    const handleSearch = itemValue => {
        setOrdersFilter({ ...ordersFilter, Search: itemValue })
    };

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setMyBids(data);
        }
    }

    const getMyBids = () => {
        let formData = { ...ordersFilter };
        if (logedInEmail) {
            formData.User_Email_ID = logedInEmail;
        }
        dispatch(getMyBidProductsAction(pagination,
            formData,
            callBackGetData));
        setIsLoading(true);
    }

    const route = useRoute();
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            getMyBids();
            dispatch(getBiddingProductsCart({
                "getCount": true,
                "User_Email_ID": logedInEmail
            }, (status, data) => setCartCount(data?.Counts || 0)));
        } else {
            navigation.navigate("Home");
        }
    }, [logedInEmail, ordersFilter]);

    useFocusEffect(
        React.useCallback(() => {
            if (!isEmpty(logedInEmail)) {
                getMyBids();
                dispatch(getBiddingProductsCart({
                    "getCount": true,
                    "User_Email_ID": logedInEmail
                }, (status, data) => setCartCount(data?.Counts || 0)));
            } else {
                navigation.navigate("Home");
            }
            return () => {

            };
        }, [])
    );
    const styles = StyleSheet.create({
        separator: {
            height: 1,
            backgroundColor: "#CCCCCC",
        },
        dropdown: {
            height: 30,
            maxWidth: 150,
            borderColor: '#27b6cc',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        placeholderStyle: {
            fontSize: 16,
        },
        selectedTextStyle: {
            fontSize: 16,
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            maxWidth: 200,
        },
        text: {
            fontSize: 12,
            // fontFamily: 'system-ui',
            color: esiColor.itemColor
        }
    });
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10 }}>
                <View style={{
                    flexDirection: "row", paddingTop: 5
                }}>

                    <View style={{ flex: 4 }}>
                        <Searchbar
                            style={{ borderRadius: 5, borderWidth: 0.5, maxWidth: 200, borderColor: esiColor.SBorderColor, height: 30, backgroundColor: esiColor.BackgroundColor }}
                            placeholder="Search"
                            iconColor={esiColor.SIconColor}
                            placeholderTextColor={esiColor.SPHTextColor}
                            theme={{ colors: { text: esiColor.SBTextColor} }}
                            onChangeText={(value) => handleSearch(value)}
                            value={ordersFilter.Search}
                            inputStyle={{ fontSize: 12,marginLeft:-5}}
                        />
                    </View>
                    <View style={{ flex: 3, alignItems: "center", paddingTop: 8 }}>
                        {cartCount > 0 &&
                            <TouchableOpacity onPress={(e) => {
                                navigation.navigate("CartBidProducts");
                            }}>
                                <FontAwesome5 name={"shopping-cart"} size={20} color={esiColor.esiMainColor} />
                                <Badge style={{
                                    position: 'absolute',
                                    color: "#27b6cc",
                                    fontWeight: 'bold',
                                    bottom: 12,
                                    left: 18,
                                    backgroundColor: "white"
                                }}>
                                    {cartCount}
                                </Badge>
                            </TouchableOpacity>}
                    </View>
                    <View style={{ flex: 3, alignItems: "flex-end" }}>
                        {/* {Platform.OS !== "web" ? */}
                        <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, maxWidth: 150, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                            navigation.navigate("NewBidProducts");
                        }}>
                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>New Bid</Text>
                        </TouchableOpacity>

                    </View>
                </View>
                <ScrollView style={{ height: height - ((height / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={() => {
                                getMyBids();
                            }}
                        />
                    }
                >
                    {myBids.length <= 0 ?
                        <View style={{ alignItems: "center", marginTop: 10 }}>

                            <View>
                                <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image>
                                <Text style={{ textAlign: "center", color: esiColor.NoDataFound }}>Oops! No Bid's found...</Text>
                            </View>

                        </View>
                        :
                        <View>
                            {(Array.isArray(myBids) ? myBids : []).map((data, index) => (
                                <TouchableOpacity key={index} onPress={() => {
                                    if (data?.Finalize_Partner_Bidding_Quote_Id) {
                                        navigation.navigate('BidPartnerProductDetails', { id: data?.Finalize_Partner_Bidding_Quote_Id });
                                    } else
                                        navigation.navigate('BidProductDetails', { id: data?.User_Products_Bidding_Id });
                                }}>
                                    <View key={index} style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        // shadowOffset: { width: -2, height: 4 },
                                        shadowOpacity: 7,
                                        shadowRadius: 5,
                                        marginHorizontal: 2, borderRadius: 10, padding: 2, 
                                        backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        elevation: 10,
                                        maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                        minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                                        maxHeight: height > 250 ? 250 : 90,
                                        minHeight: 100,
                                        // borderColor: 'grey',
                                        // borderWidth: 0.5,
                                    }}>
                                        <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                            <Image source={{ cache: "force-cache", uri: data?.Image }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.brandFontColor }}>{data?.Product_Name}</Text>
                                            <Text style={styles.text}>Bidding ID : {data.User_Products_Bidding_Id}</Text>
                                            <Text style={styles.text}>Status : {data.Bidding_Status}</Text>
                                            <Text style={styles.text}>Quantity: {data?.Quantity}</Text>
                                            {data?.Finalize_Partner_Bidding_Quote_Id &&
                                                <Text style={styles.text}>Finalize Quote Id: {data?.Finalize_Partner_Bidding_Quote_Id}</Text>}
                                        </View>
                                    </View>
                                </TouchableOpacity>

                            ))}
                            <View style={{ marginBottom: 25 }} ></View>
                        </View>
                    }
                </ScrollView>
            </View>
        </Surface>
    )
}

