import { Platform } from 'react-native';
import {
  UPDATE_CART
} from '../actions/cartActions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GET_CART_ITEMS, CART_DEFAULT_ADDRESS } from '../actions/cartActionsNew';

const storeData = async (value) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem('defaultAddress', jsonValue)
  } catch (e) {
    // saving error
  }
}

const removeValue = async () => {
  try {
    await AsyncStorage.removeItem('cart');
  } catch (e) {
  }

}

const initialState = {
  cart: [],
  error: '',
  isLoading: false,
  defaultAddress: ""
}

const addCartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CART_ITEMS:
      return { ...state, cart: action.data }
    case CART_DEFAULT_ADDRESS:
      storeData(action.data);
      return { ...state, defaultAddress: action.data }
    default:
      return state;
  }
}
export default addCartReducer;