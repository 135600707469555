import { Dimensions, Image, Platform, RefreshControl, ScrollView, Text, TouchableOpacity, View, FlatList } from "react-native";
import { Avatar, Button, Checkbox, Colors, HelperText, IconButton, Surface, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../header";
import { useEffect, useMemo, useState } from "react";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { placeCommentToBids, updateProductPayment, updateUserBiddingRide } from "../../../../state/actions/boomi-actions/bid-ride-actions";
import razorpayPay from "../../../../hooks/razorpay";
import useRazorpay from "react-razorpay";
import { getUserSettings, getWalletByUser } from "../../../../state/actions/walletActions";
import Review from "./Review";
import { getBiddingPartnerRideDetails } from "../../../../state/actions/node-actions/bid-rides-actions";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getAllCartAddressAction } from "../../../../state/actions/addressActions";
import Modal from "react-native-modal";
import { EsiAutocompleteDropdown } from "../../../../components/custom/DropDowns/AutoCompleteDropDown1";
import isEmpty from "../../../../state/validations/is-empty";


export default function BidPartnerRideDetails(props) {
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const user = useSelector((state) => state.auth.user);
    const esiColor = useSelector(state => state.theme);
    const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
    const dimensions = Dimensions.get('window');
    const { height, width } = dimensions;
    const { navigation } = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [rideDetails, setRideDetails] = useState({});
    const [rideDetailsN, setRideDetailsN] = useState({});
    const [delPartner, setDelPartnerDetails] = useState({});
    const [comments, setComments] = useState([]);
    const [vehicals, setVehicals] = useState([]);
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(false);
    const [commentLoading, setCommentLoading] = useState(false);
    const [addressD, setAddressD] = useState();


    const submitComment = () => {
        setCommentError(false);
        if (comment) {
            if (comment.length < 20) {
                setCommentError(true);
            } else {
                setCommentLoading(true);
                let formData = {
                    "Commented_By": "USER",
                    "Comments": comment,
                    "Created_By": EmailID,
                    "Delivery_Bidding_Ride_Quote_Id": rideId
                }
                dispatch(placeCommentToBids(formData, placeCallbackResponse));
            }
        } else {
            setCommentError(true);
        }
    }

    const placeCallbackResponse = (status: boolean) => {
        setCommentLoading(false);
        if (status) {
            setComment("");
            getRideDetails();
        }
    }
    const updateStatesWallet = (data: any, loading: any) => {
        let add = JSON.parse(JSON.stringify(data));
        setWallet(add?.Wallet[0]);
    };

    const getRideDetails = () => {
        if (rideId) {
            setLoading(true);
            dispatch(getBiddingPartnerRideDetails({ Delivery_Bidding_Quote_ID: rideId }, getCallback))
            let data = {
                User_Id: user.Email_Id,
                Wallet_Id: "",
            };
            dispatch(getWalletByUser(updateStatesWallet, data));
            dispatch(getUserSettings("ORDER_MAX_WALLET_USAGE_P", setMaxWalletUsage));
        }
    }

    const getCallback = (status, data) => {
        setLoading(false);
        if (status) {
            setRideDetails(data);
            setRideDetailsN(data?.User_Bid_Ride || []);
            setDelPartnerDetails(data?.Deliverybids || []);
            setComments(data?.Comments || []);
            setVehicals(data?.Vehicals || []);
            setAddressD(data?.Address);

        }
    }
    const rideId = props?.route?.params?.id;
    useEffect(() => {
        getRideDetails();
    }, [props?.route?.params])


    const [errorMessage, setErrorMessage] = useState("");
    const [cancelMessage, setCancelMessage] = useState("");

    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isConfirmError, setIsConfirmError] = useState("");


    const [wallet, setWallet] = useState(null);
    const [maxWalletUsage, setMaxWalletUsage] = useState(50);
    const [useWallet, setUseWallet] = useState(false);

    const [openAddress, setOpenAddress] = useState(false);
    const [address, setAddress] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState("");

    const getAddress = () => {
        let data = {
            User_Email_Id: user.Email_Id,
            Records_Filter: "FILTER",
        };
        dispatch(getAllCartAddressAction(updateStates, data));
    }


    const updateStates = (data: any, loading: any) => {
        let add = (data ? data : []).map((item => ({ id: item.User_Address_Id, title: item?.Address_Type + " " + "," + " " + item?.Door_Number + " " + "," + " " + item?.Landmark + " " + "," + " " + item?.Street + " " + "," + " " + item?.City + " " + "," + " " + item?.State + " " + "," + " " + item?.Zip, })));
        add.push({ id: "Add New Address", title: "Add New Address" })
        setAddress(add);
    };

    const getAdvanceAmount = useMemo(() => {
        return (delPartner?.Esi_Commision_Amount + delPartner?.Advance_Token_Amount || 0)
    }, [delPartner, vehicals])

    const walletPaybleAmount = () => {
        let walletPayable = getAdvanceAmount * (maxWalletUsage * 0.01);
        let payable = walletPayable;
        if (walletPayable > Number(wallet?.Remaining_Balance)) {
            payable = Number(wallet?.Remaining_Balance);
        }
        if (rideDetailsN?.Finalize_Delivery_Bidding_Quote_Id) {
            payable = 0;
        }
        return payable
    }
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }

    const payNow = () => {
        let amount = (Math.round(finalAdvanceOnlineAmount() + Number.EPSILON));
        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    updatePaymentDetails("RAZORPAY", response.razorpay_payment_id, amount);
                } else {

                }
            });
        } else {
            updatePaymentDetails("WALLET", "", amount);
        }
    }

    const updatePaymentDetails = (type, transactionId, amount) => {
        let formData = {
            "Advance_Online_Amount": type == "RAZORPAY" ? amount : 0,
            "Advance_Online_Transaction_Id": transactionId,
            "Advance_Wallet_Amount": (useWallet ? walletPaybleAmount() : 0),
            "Advance_Payment_Status": "1",
            "Finalize_Delivery_Bidding_Quote_Id": rideId,
            "Modified_By": EmailID,
            "User_Address_Id": deliveryAddress,
            "User_Email_Id": EmailID,
            "Total_Advance_Amount": getAdvanceAmount,
            "User_Ride_Bidding_Id": rideDetailsN?.User_Ride_Bidding_Id
        }
        setLoading(true);
        dispatch(updateProductPayment(formData, paymentCallBack));
    }

    const finalAdvanceOnlineAmount = () => {
        return getAdvanceAmount - (useWallet ? walletPaybleAmount() : 0);
    }


    const walletFinalPaybleAmount = () => {
        let walletPayable = (rideDetailsN?.Final_Amount_Including_Charges || 0) * (maxWalletUsage * 0.01);
        let payable = walletPayable;
        if (walletPayable > Number(wallet?.Remaining_Balance || 0)) {
            payable = Number(wallet?.Remaining_Balance || 0);
        }
        if (rideDetailsN?.Payment_Mode == 'COD') {
            payable = 0;
        }
        return payable
    }
    const finalOnlineAmount = () => {
        return (rideDetailsN?.Final_Amount_Including_Charges || 0) - (useWallet ? walletFinalPaybleAmount() : 0);
    }

    const payNowFinal = () => {
        let amount = (Math.round(finalOnlineAmount() + Number.EPSILON));
        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    updateFinalPaymentDetails("RAZORPAY", response.razorpay_payment_id, amount);
                } else {

                }
            });
        } else {
            updateFinalPaymentDetails("WALLET", "", amount);
        }
    }

    const updateFinalPaymentDetails = (type, transactionId, amount) => {
        let formData = {
            "Modified_By": EmailID,
            "User_Ride_Bidding_Id": rideDetailsN?.User_Ride_Bidding_Id,
            "Wallet_Amount": (useWallet ? walletFinalPaybleAmount() : 0),
            "Online_Transaction_Amount": type == "RAZORPAY" ? amount : 0,
            "Online_Transaction_Id": transactionId,
            "Final_Payment_Status": "1",
            "User_Email_Id": EmailID
        }
        setLoading(true);
        dispatch(updateProductPayment(formData, paymentCallBack));
    }

    const paymentCallBack = (status, data) => {
        setLoading(false);
        getRideDetails();
    }

    const RenderItemVehicals = ({ item }) => {

        return (
            <View style={{
                shadowColor: esiColor.brandShadowColor,
                // shadowOffset: { width: -2, height: 4 },
                shadowOpacity: 5,
                shadowRadius: 7,
                marginHorizontal: 2, borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                alignItems: 'flex-start',
                // elevation: 10,
                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                maxHeight: height > 250 ? 250 : 90,
                minHeight: 100,
                // borderColor: 'grey',
                borderWidth: 0.5,
                marginBottom: 10
            }}>
                <View style={{ flex: 2 }} >
                    {item?.Images?.length > 0 &&
                        <Image source={{ cache: "force-cache", uri: item?.Images[0] }} style={{ borderRadius: 8, height: 100, width: 80, resizeMode: 'contain', backgroundColor: "#efefef" }}></Image>
                    }
                </View>
                <View style={{ flex: 4 }}>
                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.itemColor }}>{item?.Vehicle_Type},{item?.Vehicle_Comfort_Type + "  ,"}
                        <MaterialCommunityIcons name="seat-passenger" size={18} color="#27b6cc" /> {item?.Seating_Capacity}</Text>

                    <View style={{ flexDirection: 'row' }} >
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.DescColor }} >Quantity </Text>
                        </View>
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.itemColor }} >: {item.Quantity}</Text>
                        </View>
                    </View>
                    {!isEmpty(item?.Brand_Name) &&
                        <View style={{ flexDirection: 'row' }} >
                            <View style={{ flex: 4 }}  >
                                <Text style={{ color: esiColor.DescColor }} >Brand</Text>
                            </View>
                            <View style={{ flex: 6 }}  >
                                <Text style={{ color: esiColor.itemColor }} >: {item.Brand_Name}</Text>
                            </View>
                        </View>}
                </View>
            </View >
        )
    }

    const RenderItemDeliveryPersons = ({ item }) => {
        let data = item;
        return (<TouchableOpacity onPress={() => {
            if (rideDetailsN?.Finalize_Delivery_Bidding_Quote_Id) {
                if (rideDetailsN?.Finalize_Delivery_Bidding_Quote_Id == data?.Delivery_Bidding_Quote_Id) {
                    navigation.navigate('BidPartnerRideDetails', { id: data?.Delivery_Bidding_Quote_Id });
                }
            } else {
                navigation.navigate('BidPartnerRideDetails', { id: data?.Delivery_Bidding_Quote_Id });
            }
        }}>
            <View style={{
                shadowColor: rideDetailsN?.Finalize_Delivery_Bidding_Quote_Id == data?.Delivery_Bidding_Quote_Id ? esiColor.brandShadowColor : esiColor.brandShadowColor,
                // shadowOffset: { width: -2, height: 4 },
                shadowOpacity: 5,
                shadowRadius: 7,
                marginHorizontal: 2, borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                alignItems: 'flex-start',
                // elevation: 10,
                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                maxHeight: height > 250 ? 250 : 90,
                minHeight: 100,
                // borderColor: 'grey',
                borderWidth: 0.5
            }}>
                <View style={{ flex: 1, alignContent: 'center', marginLeft: 10, marginVertical: 5 }} >
                    <Image source={{ cache: "force-cache", uri: data?.Profile_Pic_Path }} style={{ borderRadius: 8, height: 100, width: 80, resizeMode: 'contain', backgroundColor: "#efefef" }}></Image>
                </View>
                <View style={{ flex: 2, flexDirection: 'column' }}  >
                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{data?.Full_Name}</Text>
                    <View style={{ flexDirection: 'row' }} >
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.DescColor }} >Amount </Text>
                        </View>
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.itemColor }}>: {data.Quotation_Amount}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }} >
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.DescColor }} >Extra Fare Per Day </Text>
                        </View>
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.itemColor }} >: {data.Fare_Per_Day}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }} >
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.DescColor }} >Extra Fare Per KM </Text>
                        </View>
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.itemColor }} >: {data.Price_Per_Km}</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontWeight: "bold", color: esiColor.DescColor }} >Info </Text>
                    </View>
                    <View>
                        <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }} > {"  " + data?.Delivery_Comments ? data?.Delivery_Comments : ""}</Text>

                    </View>
                </View>
            </View>
        </TouchableOpacity>)
    }

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View>
                {navigation.canGoBack() &&
                    <IconButton
                        icon="arrow-left"
                        size={27}
                        color={"#27B6CC"}
                        style={{ marginTop: -2, marginLeft: 1 }}
                        onPress={() => props.navigation.goBack()}
                    />}
            </View>
            <ScrollView style={{ maxHeight: dimensions.height - 20 }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => getRideDetails}
                    />
                }
            >
                <View>
                    <View style={{ marginVertical: 5, }} >
                        <Text style={{ textAlign: 'center', color: esiColor.brandFontColor, fontWeight: 'bold' }}>{rideDetailsN?.Pickup_Zip_Code}</Text>
                    </View>
                    <View style={{ paddingHorizontal: 10 }} >
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Ride Date </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {rideDetailsN?.Ride_Start_Date}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Bid Expire Date </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {rideDetailsN?.Bidding_Expiry_Date}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row" }} >
                            <View style={{ flex: 4 }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Status </Text>

                            </View>
                            <View style={{ flex: 6 }} >
                                <Text style={{ color: esiColor.itemColor }}>: {rideDetailsN?.Bidding_Status}</Text>
                                {(rideDetailsN?.Bidding_Status != "COMPLETED" && rideDetailsN?.Bidding_Status != "CANCELLED") &&
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmScreen(true);
                                    }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Cancel </Text>
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                        {rideDetailsN?.Finalize_Delivery_Bidding_Quote_Id &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Finalize ID </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>: {rideDetailsN?.Finalize_Delivery_Bidding_Quote_Id}</Text>
                                </View>
                            </View>
                        }

                        {rideDetailsN?.Advance_Payment_Status == 1 &&
                            <View style={{ flexDirection: "column" }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: 'bold' }}> Advance Amount </Text>
                                {rideDetailsN?.Advance_Wallet_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Wallet Payment  </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Advance_Wallet_Amount} </Text>
                                        </View>
                                    </View>}
                                {rideDetailsN?.Advance_Online_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Online Payment </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Advance_Online_Amount} </Text>
                                        </View>
                                    </View>}
                                {rideDetailsN?.Advance_Online_Transaction_Id &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Transaction ID </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: {rideDetailsN?.Advance_Online_Transaction_Id} </Text>
                                        </View>
                                    </View>
                                }

                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Total Advance </Text>

                                    </View>
                                    <View style={{ flex: 6, flexDirection: "column" }} >
                                        <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Total_Advance_Amount} </Text>
                                    </View>
                                </View>
                            </View>
                        }

                        {rideDetailsN?.Final_Payment_Status == 1 &&
                            <View style={{ flexDirection: "column" }} >
                                <Text style={{ color: esiColor.DescColor, fontWeight: 'bold' }}> Payment Summarry </Text>
                                {rideDetailsN?.Wallet_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Wallet Payment  </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Wallet_Amount} </Text>
                                        </View>
                                    </View>}
                                {rideDetailsN?.Online_Transaction_Amount > 0 &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Online Payment </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Online_Transaction_Amount} </Text>
                                        </View>
                                    </View>}
                                {rideDetailsN?.Payment_Transaction_Id &&
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Transaction ID </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>: {rideDetailsN?.Payment_Transaction_Id} </Text>
                                        </View>
                                    </View>
                                }

                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Total Advance </Text>

                                    </View>
                                    <View style={{ flex: 6, flexDirection: "column" }} >
                                        <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Total_Advance_Amount} </Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Total Paid </Text>

                                    </View>
                                    <View style={{ flex: 6, flexDirection: "column" }} >
                                        <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Total_Advance_Amount + rideDetailsN?.Final_Amount_Including_Charges} </Text>
                                    </View>
                                </View>
                            </View>
                        }

                        {rideDetailsN?.Advance_Payment_Status != 1 ?
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Advance Amount </Text>

                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>: ₹ {getAdvanceAmount || rideDetailsN?.Total_Advance_Amount } </Text>
                                    {(walletPaybleAmount() > 0) &&
                                        <View>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    setUseWallet(!useWallet);
                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: "row",
                                                    // marginTop: -10
                                                }}>
                                                    <Checkbox.Android
                                                        status={useWallet ? 'checked' : 'unchecked'}
                                                        uncheckedColor={esiColor.SBorderColor}
                                                    />
                                                    <Text style={{
                                                        marginTop: 8,
                                                        color: esiColor.itemColor
                                                    }}>{"Use Wallet Amount ₹ " + walletPaybleAmount()}</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    {useWallet &&
                                        <View>
                                            <Text style={{
                                                color: esiColor.itemColor
                                            }}>{"Remaining amount after wallet amount ₹ " + finalAdvanceOnlineAmount()}</Text>
                                        </View>
                                    }
                                    {!rideDetailsN?.Finalize_Delivery_Bidding_Quote_Id &&

                                        <View>
                                            <View>
                                                <TouchableOpacity disabled={commentLoading} onPress={() => {
                                                    setDeliveryAddress("");
                                                    setOpenAddress(state => !state);
                                                    getAddress();
                                                }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 50 }} >
                                                    <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > PAY </Text>
                                                </TouchableOpacity>
                                            </View>
                                            <Text style={{ fontWeight: "500", color: esiColor.DescColor }}>Pay advance amount to lock this offer.</Text>
                                        </View>
                                    }
                                </View>
                            </View>
                            : rideDetailsN?.Final_Payment_Status != 1 &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Remaining Amount </Text>
                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>: ₹ {rideDetailsN?.Final_Amount_Including_Charges || 0} </Text>
                                    {(walletFinalPaybleAmount() > 0) &&
                                        <View>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    setUseWallet(!useWallet);
                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: "row",
                                                    // marginTop: -10
                                                }}>
                                                    <Checkbox.Android
                                                        status={useWallet ? 'checked' : 'unchecked'}
                                                        uncheckedColor={esiColor.SBorderColor}
                                                    />
                                                    <Text style={{
                                                        color: esiColor.itemColor,
                                                        marginTop: 8
                                                    }}>{"Use Wallet Amount ₹ " + walletFinalPaybleAmount()}</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    {useWallet &&
                                        <View>
                                            <Text style={{
                                                color: esiColor.itemColor
                                            }}>{"Remaining amount after wallet amount ₹ " + finalOnlineAmount()}</Text>
                                        </View>
                                    }
                                    {(rideDetailsN?.Payment_Mode != "COD" && rideDetailsN?.Final_Payment_Status == 0) &&

                                        <View>
                                            <View>
                                                <TouchableOpacity disabled={commentLoading} onPress={payNowFinal} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 50 }} >
                                                    <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > PAY </Text>
                                                </TouchableOpacity>
                                            </View>
                                            <Text style={{ fontWeight: "500", color: esiColor.DescColor }}>Pay remaining amount to get meepaisa support.</Text>
                                        </View>
                                    }
                                </View>
                            </View>}

                        {rideDetailsN?.Payment_Mode &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Payment Mode </Text>
                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>:{rideDetailsN?.Payment_Mode} </Text>
                                </View>
                            </View>}

                        <View style={{ flexDirection: "column" }} >
                            <Text style={{ fontWeight: "bold", color: esiColor.DescColor, textAlign: "center" }}>Description</Text>
                            <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }}>{rideDetailsN?.Description}</Text>
                        </View>
                        <View style={{ flexDirection: "column" }} >
                            <Text style={{ fontWeight: "bold", color: esiColor.DescColor, textAlign: "center" }}>Vehicals</Text>
                            <View>
                                <FlatList
                                    data={vehicals || []}
                                    renderItem={RenderItemVehicals}
                                    keyExtractor={(item, index) => index}
                                />
                            </View>
                        </View>
                    </View>

                    {addressD &&
                        <View style={{
                            paddingHorizontal: 10
                        }}>

                            <View style={{
                                shadowColor: esiColor.brandShadowColor,
                                // shadowOffset: { width: -2, height: 4 },
                                shadowOpacity: 5,
                                shadowRadius: 7,
                                borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10),
                                alignItems: 'flex-start',
                                // elevation: 10,
                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                maxHeight: height > 250 ? 250 : 90,
                                minHeight: 100,
                                // borderColor: 'grey',
                                borderWidth: 0.5,
                                flexDirection: 'column'

                            }}  >
                                <View style={{
                                    marginHorizontal: 10
                                }}>
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor, marginBottom: 5 }}>Delivery Address </Text>
                                    <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{addressD?.Name},</Text>
                                    <Text style={{ fontSize: 13, color: esiColor.itemColor }}>D-No : {addressD?.Door_No},{addressD?.Street}, {addressD?.Land_Mark},</Text>
                                    <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{addressD?.City},{addressD?.State},{addressD?.Country},</Text>
                                    <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{addressD?.City}-{addressD?.Zip},</Text>
                                    <Text style={{ fontSize: 13, marginBottom: 10, color: esiColor.itemColor }}>Phone number : {addressD?.Mobile}.  </Text>
                                </View>
                            </View>

                        </View>
                    }

                    <View style={{ paddingHorizontal: 10 }} >
                        <View style={{ flexDirection: "column" }} >
                            <Text style={{ fontWeight: "bold", textAlign: "center", color: esiColor.DescColor }}>Quotations</Text>
                        </View>

                        <View>
                            <FlatList
                                data={delPartner ? [delPartner] : []}
                                renderItem={RenderItemDeliveryPersons}
                                keyExtractor={(item, index) => index}
                            />
                        </View>

                        {rideDetailsN?.Bidding_Status == "COMPLETED" &&
                            <Review delPartner={delPartner} rideDetails={rideDetailsN} dimensions={dimensions} getRideDetails={getRideDetails} vehicals={vehicals} dispatch={dispatch} />
                        }

                        <View style={{ paddingHorizontal: 10 }} >
                            <Text style={{ fontWeight: "bold", color: esiColor.DescColor, textAlign: "center", paddingVertical: 2 }}>Comments</Text>
                            <View style={{}} >
                                <EsiTextAreaInput
                                    // error={Boolean(touched.Description && errors.Description)}
                                    style={{ height: 60 }}
                                    mode='outlined'
                                    value={comment}
                                    textareavalueUpdate={value => {
                                        setComment(value)
                                    }}
                                    placeholder={'Please provide comment'}
                                    underlineColorAndroid={'transparent'}
                                    theme={{
                                        colors: {
                                            primary: "#27b6cc"
                                        },
                                        roundness: 20
                                    }}
                                    maxLength={500}
                                />
                                <View style={{ paddingHorizontal: 10, marginTop: 5, marginBottom: 10, flexDirection: "row" }} >
                                    <HelperText style={{ textAlign: "left" }} type="error" visible={commentError} >
                                        {(comment && comment?.length < 20) &&
                                            "Minimum 20 characters are required."}
                                        {!(comment) &&
                                            "Comment is required."}
                                    </HelperText>
                                    <TouchableOpacity disabled={commentLoading} onPress={submitComment} style={{ position: "absolute", right: 0, backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 100 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Post </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            {comments?.map((item, index) => (
                                <View style={{ flexDirection: "row" }} key={"comment" + index} >
                                    <View style={{ flex: 2 }} >
                                        {(item.Commented_By == "DELIVERY" && comments[index - 1]?.Commented_By != "DELIVERY") &&
                                            <Image
                                                source={{ cache: "force-cache", uri: item?.Rider_Logo }}
                                                style={{
                                                    height: 40, width: 40, marginVertical: 5, alignSelf: "center",
                                                    resizeMode: 'contain', borderRadius: 50, shadowRadius: 2, backgroundColor: "#efefef"
                                                }}>
                                            </Image>}
                                        {(item.Commented_By == "USER" && comments[index - 1]?.Commented_By != "USER") &&

                                            <Image
                                                source={{ cache: "force-cache", uri: item?.Commenter_Pic }}
                                                style={{
                                                    height: 40, width: 40, marginVertical: 5, alignSelf: "center",
                                                    resizeMode: 'contain', borderRadius: 50, shadowRadius: 2, backgroundColor: "#efefef"
                                                }}>
                                            </Image>}
                                    </View>
                                    <View style={{
                                        flex: 10,
                                        marginHorizontal: 2, borderRadius: 10, padding: 5, borderColor: esiColor.brandBorderColor, borderWidth: 0.2,
                                        alignItems: 'flex-start',
                                        marginVertical: 5, paddingVertical: 10
                                    }} >
                                        <Text style={{ position: "absolute", right: 5, top: 0, color: esiColor.Text }}>{new Date(item.Created_Date).toDateString() + " " + new Date(item.Created_Date).toLocaleTimeString()}</Text>
                                        <Text style={{ marginHorizontal: 5, marginTop: 10, color: esiColor.Text }}>{item.Comments}</Text>
                                    </View>

                                </View>
                            ))}
                        </View>
                    </View>
                </View>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={openAddress}>
                    <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 200, maxHeight: 230, borderRadius: 10 }}>
                        <Surface style={{
                            justifyContent: 'center',
                            borderRadius: 10,
                            top: Platform.OS === 'web' ? 10 : 20,
                            elevation: 0
                        }} >
                            <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                                setOpenAddress((prop) => !prop);
                            }}>
                                <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            <View style={{
                                flexDirection: "row", justifyContent: "center", zIndex: 2
                            }}>
                                <View style={{ width: (dimensions.width * 0.9) > 500 ? 280 : (dimensions.width * 0.8), minHeight: 70, margin: "auto" }}>
                                    <EsiAutocompleteDropdown

                                        key={"select-address"}
                                        loading={false}
                                        data={address}
                                        label={"Delivery Address"}
                                        onSelect={(item) => {
                                            if (item.id === "Add New Address") {
                                                navigation.navigate('AddAddress', { page: 'cart' });
                                                setOpenAddress((prop) => !prop);

                                            } else {
                                                setDeliveryAddress(item.id)
                                            }

                                        }}
                                        onClear={() => {
                                        }}
                                        getSuggestions={(q) => {
                                            // apiCall(q);
                                        }}
                                        // selected={}

                                        maxWidth={260}
                                    />
                                </View>

                            </View>
                            <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", zIndex: 1 }}>
                                <Button disabled={!deliveryAddress} style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {
                                    payNow();
                                    setOpenAddress((prop) => !prop);

                                }}>
                                    <Text style={{color:esiColor.itemButtenColor}} >
                                        Pay Advance</Text>
                                </Button>
                            </View>
                        </Surface>
                    </View>
                </Modal>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }}
                    isVisible={isConfirmScreen}
                >
                    <View style={{ flex: 1, maxWidth: dimensions.width - 50, minWidth: 300, minHeight: 150, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                        <View style={{ flexDirection: "column" }} >
                            <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, }} onPress={() => {
                                setIsConfirmScreen(!isConfirmScreen);
                            }}>
                                <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "space-around",
                                height: 250
                            }}>
                                <View style={{
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    paddingHorizontal: 10,
                                    marginTop: 20
                                }}>
                                    <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.Text }} >Cancel Reason:</Text>
                                    <EsiTextAreaInput
                                        error={Boolean(errorMessage)}
                                        style={{ height: 60 }}
                                        mode='outlined'
                                        value={cancelMessage}
                                        textareavalueUpdate={value => {
                                            if (value) {
                                                if (value.length < 20) {
                                                    setErrorMessage("Cancel reason should be more than 20 characters.")
                                                } else {
                                                    setErrorMessage("");
                                                }
                                            } else {
                                                setErrorMessage("Please provide cancel reason.")
                                            }
                                            setCancelMessage(value);
                                        }}
                                        placeholder={'Please provide reason'}
                                        underlineColorAndroid={'transparent'}
                                        theme={{
                                            colors: {
                                                primary: "#27b6cc"
                                            },
                                            roundness: 8
                                        }}
                                        maxLength={500}
                                    />
                                    <HelperText type="error" visible={Boolean(errorMessage)}>
                                        {errorMessage}
                                    </HelperText>
                                    <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.Text }} >Are you sure you want to cancel?</Text>
                                </View>


                                <View style={{
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    paddingHorizontal: 5,
                                    marginVertical: 5

                                }}>
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmError("");
                                        if (cancelMessage) {
                                            if (cancelMessage.length < 20) {
                                                setErrorMessage("Cancel reason should be more than 20 characters.")
                                            } else {
                                                setErrorMessage("");

                                                setIsConfirmLoading(true);
                                                dispatch(updateUserBiddingRide({
                                                    User_Ride_Bidding_Id: rideDetailsN?.User_Ride_Bidding_Id,
                                                    Bidding_Status: "CANCELLED",
                                                    Cancelled_Reason: cancelMessage
                                                }, (status) => {
                                                    setIsConfirmLoading(false);
                                                    setCancelMessage("");
                                                    if (status) {
                                                        setIsConfirmScreen(false);
                                                    } else {
                                                        setIsConfirmError("Please try again.");
                                                    }
                                                }))

                                            }
                                        } else {
                                            setErrorMessage("Please provide cancel reason.")
                                        }

                                    }} style={{ borderColor: esiColor.SBorderColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.esiMainColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Yes </Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmScreen(false);
                                    }} style={{ borderColor: esiColor.SBorderColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.esiMainColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Close </Text>
                                    </TouchableOpacity>
                                </View>

                                <HelperText type="error" style={{ textAlign: "left" }} >
                                    Note : Advance amount is not refundable. After cancelling the ride, our backend team will help you to resolve your issue.
                                </HelperText>
                            </View>

                        </View>
                    </View>
                </Modal>
            </ScrollView >
        </Surface >
    )
}