import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, FlatList, StyleSheet, Image, Platform, TouchableOpacity, SafeAreaView, Text, View, Linking, ScrollView, RefreshControl } from "react-native";
import Modal from 'react-native-modal';
import { Avatar, Button, Surface, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes, apiActions } from "../../state/actions";
import { getProductSizeActionWishList, getvisitedProductByIdAction } from "../../state/actions/productsActions";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import { addCart } from "../../state/actions/cartActionsNew";
import wishlistgif from "../../assets/images/GIF/wishlistgif.gif"
import { useToast } from "../../self_modules/react-native-paper-toast/src";


const WishList = (props) => {
    const { navigation } = props;
    const { height, width } = Dimensions.get('window');
    const dimensions = Dimensions.get('window');

    const EmailID = useSelector(state => state.auth.user?.Email_Id)
    const esiColor = useSelector(state => state.theme);
    const [wish_list, setWishList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sub_total, setSubTotal] = useState(0);
    const [delete_id, setDeleteId] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isEmpty(EmailID)) {
            getWishList();
        }
        else {
            setTimeout(() => {
                if (isEmpty(EmailID)) {
                    navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [props.navigation, props.route, EmailID]);

    const getWishList = async () => {
        if (EmailID) {
            setLoading(true);
            setSubTotal(0);
            setWishList([]);
            setDeleteId("");
            let filter = {
                "User_Email_Id": EmailID
            }

            apiActions.get_my_wish_list(filter)
                .then(async res => {
                    let wish_data = res.Product_Details;
                    if (!isEmpty(wish_data)) {
                        setWishList(wish_data);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }

    const removeItem = (wish_id) => {

        let data = {
            Wish_Id: wish_id
        }
        setLoading(true);
        apiActions.delete_wish_list(data).then((res) => {
            if (res.Response_Status === "Success") {
                getWishList();
                dispatch(getvisitedProductByIdAction(EmailID));
            } else {
                dropDownAlertFun('info', 'Failed', 'Unable to delete, Please try again.');
            }

        }).finally(() => {
            setLoading(false);
        })

    }
    const checkOut = async () => {
        if (wish_list.length == 0) {
            // Toast.show('No items', Toast.LONG);
        } else {
            let diff = 0;
            var wishList = wish_list?.map((item) => {
                if (item?.checked == true) {
                    dispatch({ type: actionTypes.CART_ADD, product: item, Partner_Details_Id: item.Partner_Details_Id })
                    let data = {
                        Wish_Id: item.Wish_Id
                    }
                    apiActions.delete_wish_list(data)
                    apiActions.get_wish_list()
                        .then(async res => {
                        })
                        .catch(err => {
                        })
                        .finally(() => {
                            setModalVisible(false)
                        })
                    diff += parseFloat(item?.Partner_Selling_Price)
                    return null;
                }
                return item;
            })
            setSubTotal(sub_total - diff)
            wishList = wishList.filter(item => item != null);
            setWishList(wishList);
        }
    }
    const [modalVisible, setModalVisible] = useState(false);

    // Tost message code.
    const toast = useToast();

    // Tost message code.
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const [loadCartSize, setLoadCartSize] = useState(false);
    const [cartProduct, setCartProduct] = useState({});
    const [selectedCart, setSelectedCart] = useState({});
    const [cartModelOpen, setCartModelOpen] = useState(false);
    let cart = useSelector((state) => state.cart.cart);

    const apiResponse = (status, response, wishlistId, message) => {
        setLoadCartSize(false);
        if (status) {
            if (isEmpty(response)) {
                dropDownAlertFun('info', 'Failed', 'Out of stock.');
            } else {
                setCartProduct({ sizes: response, walletId: wishlistId });
                setCartModelOpen(true);
            }
        } else {

        }
    }

    const selectItem = (ischeck, wish_id) => {

        var wishList = wish_list?.map((item, index) => {
            if (item.Wish_Id == wish_id) {
                if (ischeck == true) {
                    setSubTotal(sub_total + parseFloat(item?.Partner_Selling_Price))
                } else {
                    setSubTotal(sub_total - parseFloat(item?.Partner_Selling_Price))
                }
                item.checked = ischeck;
            }
            return item;
        })
        setWishList(wishList);
    }
    const RenderItem = ({ item }) => {

        return (
            <View>
                <View style={{
                    borderRadius: 8, padding: 2, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor,
                    // borderColor: "#CACFD2",
                    //  borderWidth: 0.5,
                    // shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 7,
                    shadowRadius: 7,
                    margin: 5,
                    elevation: 10, height: 150,

                }}>
                    <View style={{ flexDirection: 'row', paddingVertical: 5 }}>
                        <View style={{ flex: 1, justifyContent: 'flex-start', alignSelf: 'center', marginLeft: 10 }}>
                            <TouchableOpacity disabled={item?.Partner_Product?.Is_Open == "0"} onPress={() => {
                                if (item?.Partner_Product?.Partner_Type === "Physical") {
                                    setLoadCartSize(true);
                                    dispatch(getProductSizeActionWishList(item.Products.Product_Id, item.Partner_Product.Partner_Details_Id, item.wishlistId, apiResponse));
                                } else {
                                    Linking.openURL(item?.Partner_Product.Web_Redirection_Url).catch((err) =>
                                        console.error('An error occurred', err),
                                    );
                                }
                            }}>
                                <FontAwesome color={item?.Partner_Product?.Is_Open == "0" ? esiColor.TextPlaceholder : "#27b6cc"} name={item?.Partner_Product?.Partner_Type === "Physical" ? "cart-plus" : "external-link"} size={20} />
                            </TouchableOpacity>

                        </View>
                        <View style={{ flex: 3, flexDirection: 'column', alignItems: 'center', marginLeft: -10 }}>
                            <View style={{ flex: 1, marginVertical: 5 }}>
                                <Image source={{ cache: "force-cache", uri: item?.Partner_Product.Logo_Path + `?w=50&h=20` }} style={{ height: 20, width: 50, borderRadius: 10 }}></Image>
                            </View>
                            <View style={{ flex: 3 }}>
                                <Image source={{ cache: "force-cache", uri: item?.Products.Product_Image + `?w=50&h=50` }} style={{ height: 50, width: 50 }}></Image>
                            </View>
                        </View>
                        <View style={{ flex: 8, flexDirection: 'column', alignItems: 'center', }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold', marginVertical: 5, color: esiColor.itemColor }}>Brand : {item?.Products.Brand_Name}</Text>
                            <Text style={{ color: esiColor.brandFontColor }}>{item?.Products.Product_Name?.length > 50 ? item?.Products.Product_Name.slice(0, 50) + '...' : item?.Products.Product_Name}</Text>
                            <View style={{ flexDirection: 'row', marginVertical: 5 }}>
                                <Text style={{ color: esiColor.itemColor, textDecorationLine: 'line-through', fontSize: 12 }}> ₹{item?.Partner_Product?.Original_Price}</Text>
                                <Text style={{ color: esiColor.itemColor, fontWeight: 'bold' }}>    ₹{parseFloat(item?.Partner_Product?.Partner_Selling_Price).toFixed(2)}</Text>
                            </View>
                            <Text style={{ color: esiColor.itemColor, marginBottom: 5 }}>{item?.Products.Product_Short_Description?.length > 50 ? item?.Products.Product_Short_Description?.slice(0, 50) + '...' : item?.Products.Product_Short_Description}</Text>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column', alignSelf: 'center' }}>
                            <TouchableOpacity onPress={() => {
                                setDeleteId(item?.wishlistId)
                                setModalVisible(true)
                            }}>
                                <FontAwesome name="trash-o" size={20} color="red"></FontAwesome>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <Modal
                        onBackButtonPress={() => setModalVisible(false)}
                        isVisible={modalVisible}
                        onBackdropPress={() => setModalVisible(false)}
                        swipeDirection="left"
                        backdropColor={esiColor.BackgroundColor}
                        backdropOpacity={0.6}
                        animationIn="zoomInDown"
                        animationOut="zoomOutUp"
                        animationInTiming={600}
                        animationOutTiming={600}
                        backdropTransitionInTiming={600}
                        backdropTransitionOutTiming={600}
                    >
                        <View style={{ minHeight: '20%', minWidth: '80%', backgroundColor: esiColor.BackgroundColor, borderRadius: 10, flexDirection: 'column', backfaceVisibility: "hidden" }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={{ fontSize: 18, color: esiColor.Text }}>Are you sure to delete this product?</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginHorizontal: 20 }}>
                                <TouchableOpacity onPress={() => {
                                    removeItem(delete_id);
                                    setModalVisible(false);
                                }} style={{
                                    backgroundColor: "#27b6cc", flex: 1, alignItems: 'center',
                                    borderBottomLeftRadius: 10, borderTopLeftRadius: 10, padding: 5, shadowColor: '#000',
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.2,
                                    shadowRadius: 10,
                                    elevation: 10,
                                }}>
                                    <Text style={{ color: 'white', fontSize: 16 }}>Yes</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => setModalVisible(false)}
                                    style={{
                                        backgroundColor: "white", flex: 1, alignItems: 'center',
                                        borderBottomRightRadius: 10, borderTopRightRadius: 10, padding: 5, shadowColor: '#000',
                                        shadowOffset: { width: 0, height: 2 },
                                        shadowOpacity: 0.2,
                                        shadowRadius: 10,
                                        elevation: 10,
                                    }}>
                                    <Text style={{ fontSize: 16 }}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </Modal>
                </View>
            </View>
        )
    }



    const onRefresh = () => {
        getWishList();
    };
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}
        >
            <Header {...props} />
            <View style={{ alignItems: 'center', marginBottom: 10, marginTop: 5, }}>
                <Text style={{ fontSize: 22, color: esiColor.brandFontColor, fontWeight: 'bold' }}>Wish List</Text>
            </View>
            {/* <View style={{ flexDirection: 'row', justifyContent: "flex-end", zIndex: 1, position: "absolute", top: Platform.OS === "web" ? 100 : 140, right: 0 }}>
                {!(loading || loadCartSize) &&
                    <TouchableOpacityG onPress={() => { getWishList(); }}>
                        <Ionicons size={30} name="reload-circle-outline" />
                    </TouchableOpacityG>
                }
            </View> */}

            {(loading || loadCartSize) ? <ActivityIndicator color='#27b6cc' />
                : <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={onRefresh}
                        />
                    }
                >{
                        wish_list.length > 0 ?
                            <View style={{ flex: 1 }}>
                                <FlatList
                                    data={wish_list}
                                    renderItem={RenderItem}
                                    keyExtractor={(item, index) => index}
                                />
                            </View> :
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                {/* <Feather name="heart" size={150} color="gray"></Feather>
                             */}
                                <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={wishlistgif} />
                                <Text style={{ fontSize: 20, marginVertical: 20, color: esiColor.NoDataFound }}> No favourites are added yet.</Text>
                                <TouchableOpacity onPress={() => props.navigation.navigate('Home')} style={{ backgroundColor: "white", padding: 10, marginTop: 20 }}>
                            <Text style={{ color: '#27b6cc' }}>Click Here to add your favourites.</Text>
                        </TouchableOpacity>
                                {loading ? <ActivityIndicator color='#27b6cc' size="small" />
                                    : wish_list.length > 0 ?
                                        <View style={{ flex: 1 }}>
                                            <FlatList
                                                data={wish_list}
                                                renderItem={RenderItem}
                                                keyExtractor={(item, index) => index}
                                            />
                                        </View> :
                                        <TouchableOpacity onPress={() => getWishList()} ><Text style={{ color: esiColor.NoDataFound }}>Please reload the page.</Text></TouchableOpacity>}

                                {/* <Text style={{ textAlign: 'center', paddingHorizontal: 20 }}>Favourite the products you love and buy them whenever you like!</Text> */}
                               
                            </View>}
                </ScrollView>
            }
            {/* Size Selection Modal */}
            <Modal animationIn={"slideInDown"}
                style={{ alignItems: 'center' }} isVisible={cartModelOpen}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 200, maxHeight: 250, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>

                    <View style={{
                        alignItems: 'flex-end', marginTop: '-6%'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setCartModelOpen(false); }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ textAlign: "center", color: esiColor.Text }}>
                            Select Size
                        </Text>

                        <View style={{ textAlign: "center", justifyContent: "space-evenly", flexDirection: 'row' }}>
                            {cartProduct.sizes &&
                                cartProduct.sizes.map((size, index) => (
                                    <View key={index}>
                                        <TouchableOpacity
                                            key={index}
                                            onPress={() => { setSelectedCart({ size: size.Product_Size, quantity: size.Product_Available_Stock }); }}
                                        >
                                            <View style={selectedCart.size === size.Product_Size ?
                                                {
                                                    width: 50,
                                                    height: 50,
                                                    backgroundColor: 'rgb(42, 175, 82)',
                                                    // cursor: pointer,
                                                    borderRadius: 50,
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    borderColor: '#c4c4da',
                                                    borderWidth: 1,
                                                    position: 'relative',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: 'white'

                                                }
                                                : {
                                                    width: 50,
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    // height: auto;
                                                    height: 50,
                                                    backgroundColor: 'white',
                                                    // cursor: pointer;
                                                    borderRadius: 50,
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    borderColor: '#c4c4da',
                                                    borderWidth: 1,
                                                    position: 'relative',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',

                                                }} >
                                                <Text icon="format-size">{size?.Product_Size ? size?.Product_Size : ""}</Text>
                                            </View>

                                        </TouchableOpacity>
                                        {
                                            size.Product_Available_Stock ?
                                                <Text >
                                                    {parseInt(size.Product_Available_Stock) < 11 ?
                                                        <Text style={styles.ribbon}>{size.Product_Available_Stock} Left</Text>
                                                        : null}
                                                </Text> : null
                                        }
                                    </View>
                                )

                                )}
                        </View>

                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <TouchableOpacity accessible={selectedCart?.size && selectedCart?.quantity ? true : false} >
                                <Button
                                    onPress={() => {
                                        let data = {};
                                        try {
                                            data = wish_list.filter((item) => item.wishlistId === cartProduct.walletId)[0];

                                        } catch (error) {

                                        }
                                        if (!isEmpty(data)) {

                                            let item = { partner: data.Partner_Product, product: { ...(data.Products), ...(data.Partner_Product) }, size: selectedCart.size, quantity: selectedCart.quantity }
                                            setCartModelOpen(false);

                                            let payload = {
                                                "Partner_Details_Id": data.Partner_Product.Partner_Details_Id,
                                                "Partner_Product_Id": data.Partner_Product.Partner_Product_Id,
                                                "Quantity": "1",
                                                "Size": selectedCart.size,
                                                "User_Email_Id": EmailID
                                            }

                                            dispatch(addCart(payload, EmailID));

                                            removeItem(cartProduct.walletId);
                                        }
                                    }}
                                    mode="contained"
                                    color={esiColor.esiMainColor}
                                    type="submit"
                                    disabled={selectedCart?.size && selectedCart?.quantity ? false : true}
                                    style={{ color: selectedCart?.size && selectedCart?.quantity ? "" : "blue", width: 200 }}
                                >
                                    Proceed
                                </Button>
                            </TouchableOpacity>
                            {/* <button  onClick={cartData} className="sizebutton" >Proceed</button> */}
                        </View>
                    </View>
                </View>
            </Modal >
        </Surface >
    );
}

export default WishList;


const styles = StyleSheet.create({
    sub_cart: {
        flexDirection: 'column',
        height: 90,
        backgroundColor: '#27b6cc',
    },
    button: {
        borderRadius: 20,
        backgroundColor: 'white',
        marginTop: 20,
        alignItems: 'center',
    },
});

