import React from 'react';
import { StyleSheet, Text, View, Platform, Dimensions } from 'react-native';
import { Avatar, Card, IconButton, } from 'react-native-paper';
import { useSelector } from 'react-redux';
import EsiRating from './Rating/rating';


const EsiTestimonial = (props) => {
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);


    //    item is Testimonial object as we use this object to display the info front-end
    const { data, ...rest } = props

    //    const sampleData:{
    //     Description: "Good app iam giving rating five",
    //     Profile_Pic_Path: "http://api.easystepin.com:8092/uploads/ESICB/User/20210811/User_20210811132753_test.jpg",
    //     Rating: "5",
    //     Testmonial_Id: "T-0000024",
    //     Title: "Hello app",
    //     User_Name: "rajesh rajie",
    //     }

    const regex = /(<([^>]+)>)/ig;
    const result = (data?.Description || "").replace(regex, '');
    return (

        <Card style={{
            flex: 1,
            marginHorizontal: 2, borderRadius: 25, padding: 5, margin: 'auto', backgroundColor: esiColor.BackgroundColor, flexDirection: 'column', shadowColor: '#000', borderColor: "#CACFD2", borderWidth: 0.5,
            shadowOffset: { width: 0, height: 2 },
            marginTop: "2%",
            shadowOpacity: 0.2,
            shadowRadius: 10,
            paddingTop: 10,
            elevation: 10,
            marginLeft: "auto", marginRight: "auto",
            width: dimensions >= 700 ? "90%" : "95%",
            // minHeight: 200,
            // maxHeight: 300,
            position: 'relative',
        }}>
            <Card.Content>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 7, marginTop: -5 }} >
                        <Text style={{ fontWeight: "bold", marginBottom: 2,color:esiColor.Text }}> {data.Title} </Text>
                        <Text style={{color:esiColor.Text}} > {data?.Description} </Text>
                    </View>
                    <View style={{ flex: 3, flexDirection: 'column', marginTop: -10 }}>
                        <View style={{ flexDirection: 'row', justifyContent: "flex-end" }}>
                            {props?.user && props?.user === data?.User_Email_Id ? (
                                <IconButton style={{ position: "absolute", zIndex: 1, top: -20, right: -20, backgroundColor: "#78e4f5" }} icon="pencil" size={20} onPress={props.editFunction} />

                            ) : (null)}
                            < Avatar.Image source={{ cache: "force-cache", uri: data?.Profile_Pic_Path }} />
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: "flex-end" }}>
                            <Text>  {data.User_Name} </Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: "flex-end" }}>
                            <EsiRating
                              readonly={true}
                              type='heart'
                              startingValue={data.Rating}
                              ratingCount={5}
                              imageSize={20}
                              tintColor={esiColor.TintColor}
                              style={{ paddingVertical: 10 }}
                            />
                        </View>
                    </View>
                </View>
            </Card.Content>
        </Card>

    );
}

const styles = StyleSheet.create({

    img: {
        alignItems: 'flex-end',
        marginTop: (Platform.OS === 'web') ? -50 : -50,
    },
    welcome: {
        alignItems: 'flex-end',
        paddingTop: 5,
    },
    rating: {
        alignItems: 'flex-end',
        paddingTop: 3,
    },
    heading: {
        justifyContent: 'center',
        fontWeight: 'bold',
        marginBottom: (Platform.OS === 'web') ? 20 : 10,
    },
    description: {
        width: (Platform.OS === 'web') ? 800 : 250,
        justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: 1
    }
})

export default EsiTestimonial;