
import { meeapiBoomi, meeapiCUD } from "./meeapi";

export const createBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.post("/User_Ride_Bidding/Create",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false, data?.UI_Display_Message);
        }
    }).catch(error => {
        responseFunction(false, String(error));
    });
};

export const createCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/Bidding_Ride_Cart/Create",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false, data?.UI_Display_Message);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const updateCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.put("/Bidding_Ride_Cart/Update",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false, data?.UI_Display_Message);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const deleteCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/Bidding_Ride_Cart/Delete",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const placeCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.post("/Bidding_User_Ride/Create",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false, data?.UI_Display_Message);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const placeCommentToBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/Bidding_User_Rides_Comments/Rides_Comments_Create",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const updateProductPayment = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.put("/Bidding_User_Ride/Update",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const updateUserBiddingRide = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.put("/User_Ride_Bidding/Update",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};