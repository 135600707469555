import React, { Fragment, useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Surface, Text, } from 'react-native-paper';
import { View, TouchableOpacity, Platform, Image, StyleSheet, ScrollView, FlatList } from 'react-native';
import { getFarmersAction } from "../../state/actions/farmerActions";
import isEmpty from "../../state/validations/is-empty";

export default function MeeFarmers(props) {
    const { navigation, dataReload, dataErrorReload, ...rest } = props
    const farmers = useSelector(state => state.allFarmers.farmers.all);
    //API dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFarmersAction());
    }, []);

    const handleClick = async (data) => {
        navigation('FarmerDetails', { Partner_Details_Id: data.Partner_Details_Id })
    }

    const esiColor = useSelector(state => state.theme);

    const styles = StyleSheet.create({
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10
        },

        item: {
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#27b6cc",
            marginHorizontal: 5,
            padding: 4,
            maxHeight: 35
        }
    });

    return (farmers.length > 0 &&
        <View>
            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', marginLeft: 2 }}>Farm Finds</Text>

            <View style={styles.content}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 5 }}>
                    <TouchableOpacity onPress={() => navigation('Farmers')} style={{ position: 'absolute', right: 10 }}>
                        <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold' }}>View All</Text>
                    </TouchableOpacity>
                </View>
                {isEmpty(farmers) ? (
                    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                        <Text style={{ color: esiColor.NoDataFound }}>No farmers found!</Text>
                    </View>
                ) : (<FlatList
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={farmers.slice(0, 10)}
                    renderItem={({ item, index, separators }) => (
                        <View key={index} style={{ maxWidth: 90, minWidth: 70, paddingHorizontal: 5 }}>
                            <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                                <Image source={{ cache: "force-cache", uri: item?.Logo_Path + `?w=50&h=25` }} style={{ height: 25, resizeMode: 'contain', maxWidth: 50 }}></Image>
                            </TouchableOpacity>
                            <View style={{ marginTop: 5 }}>
                                <Text style={{ fontSize: 12, color: esiColor.itemColor, textAlign: 'center' }}>{item?.Name}</Text>
                            </View>
                        </View>
                    )}
                />
                )
                }
            </View>
        </View>
    )
}
