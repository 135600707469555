import React from "react";
import { Image, StyleSheet, TouchableOpacity, View, Dimensions, Linking, ImageBackground, Text, TouchableHighlight } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Surface } from "react-native-paper";
import Header from "../header";
const { height, width } = Dimensions.get('window');
export default function HomeIndex(props) {
    const { navigation } = props;
    const dataReload = (5 * 60) * 1000;
    const dataErrorReload = 30 * 1000;
    return (
        <Surface style={{ flex: 1, backgroundColor: '#fff' }}>
            <Header {...props} />
            <ScrollView>
                <View style={{
                    width: width,
                    justifyContent: "center",
                    alignItems: 'center',
                    marginBottom: 10
                }}>

                    <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: "center", marginTop: 1 }}>

                        <Text style={{ fontSize: 40, fontWeight: 'bold', color: '#27b6cc' }}>meepaisa</Text>

                        <View style={{ width: 200, marginVertical: 10 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'normal', color: '#46cce0', textAlign: 'center' }}>India's 1st unified paisa vasool app.</Text>
                        </View>


                        <View style={{marginTop:-30,marginBottom:20}}>
                            <ImageBackground source={require('../../assets/images/meepaisa_allImage.png')} resizeMode="contain" style={{ width: width, height: 350 }} />
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: 325, marginTop: 1, marginBottom: 0 }}>
                            <View>
                                <TouchableHighlight onPress={() => Linking.openURL("https://apps.apple.com/in/app/meepaisa/id1629144663").catch((err) =>
                                    console.error('An error occurred', err),
                                )}>
                                    <Image source={require('../../assets/images/app_store_download.png')} style={{ height: 50, width: 150, resizeMode: 'contain' }} />
                                </TouchableHighlight>
                            </View>
                            <View>
                                <TouchableHighlight onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.user.meepaisa").catch((err) =>
                                    console.error('An error occurred', err),
                                )}>
                                    <Image source={require('../../assets/images/play_store_download.png')} style={{ height: 50, width: 150, resizeMode: 'contain' }} />
                                </TouchableHighlight>
                            </View>

                        </View>
                    </View>
                </View>
            </ScrollView>
        </Surface>

    )

}