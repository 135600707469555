import { useEffect, useState } from "react";
import { Image, Platform, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown";
import { getBiddingVehicalBrands } from "../../../state/actions/node-actions/bid-rides-actions";

export const BrandsFilters = (props) => {
    const { filters, setFilters } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const esiColor = useSelector(state => state.theme);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setBrands(data.map(item => ({ "id": item.Bidding_Ride_Vehicle_Brand_Id, "title": item.Brand_Name, image: item.Brand_Logo })));
        } else {
            setBrands([]);
        }
    }


    const apiCall = (q) => {
        setLoading(true);
        dispatch(getBiddingVehicalBrands({
            "Records_Filter": "FILTER",
            "Search": q,
            "NotEquelBrands": filters.map((item) => item.id) || []

        },
            { PageNo: 1, PageSize: 10, SortBy: "BIDDING_RIDE_VEHICLE_BRAND_ID", SortOrder: "ASC" },
            callBackGetData
        ));
    }

    useEffect(() => {
        apiCall("");
    }, [filters]);


    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3
                }}>Brands</Text>
            </View>
            <EsiAutocompleteDropdown key={"brands-filter"}
                loading={loading}
                data={brands}
                label={"Search Brands"}
                onSelect={(item) => {
                    if (item?.id) {
                        setFilters([item]);
                    } else {
                        setFilters([]);
                    }
                }}
                onClear={() => {
                    setFilters([])
                }}
                getSuggestions={(q) => {
                    apiCall(q);
                }}
            />
            {/* {filters.length > 1 &&
                <View style={{ flexDirection: "row", justifyContent: "flex-end" }} >
                    <TouchableOpacity onPress={() => { setFilters([]) }} >
                        <Text style={{ color: esiColor.actionColor, margin: 5 }} >Clear All</Text>
                    </TouchableOpacity>
                </View>} */}
            {/* <View>
                {filters.map((item) => {
                    return (
                        <View style={{
                            flexDirection: "row", borderRadius: 25,
                            paddingRight: Platform.OS == "android" ? 0 : 10,
                            backgroundColor: '#fff',
                            borderWidth: 1,
                            borderColor: '#383b42',
                            shadowColor: '#00000099',
                            shadowOffset: {
                                width: 0,
                                height: 5,
                            },
                            shadowOpacity: 0.3,
                            shadowRadius: 8.46,
                            marginVertical: 2,
                            elevation: 13
                        }} key={"cat" + item.id} >
                            <View style={{
                                flex: 3, flexDirection: "row"
                            }} >
                                <Text style={{ marginVertical: 5, marginLeft: 5 }} > {item.title} </Text>

                            </View>
                            <View style={{
                                flex: 1, flexDirection: "row", justifyContent: "flex-end"
                            }} >
                                <TouchableOpacity onPress={() => { setFilters((props) => props.filter((prop) => prop.id != item.id)) }} style={{ marginTop: 2 }} >
                                    <Ionicons name="md-trash" size={22} color="#ee4d2d" />
                                </TouchableOpacity>
                            </View>
                        </View>
                    )
                })}
            </View> */}

        </View>
    )
}