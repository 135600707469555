import 'react-native-gesture-handler';
import * as React from 'react';
import Header from '../header';
import { Text, View, StyleSheet, Picker, ScrollView, Platform, Dimensions, TouchableOpacity, Image, RefreshControl } from 'react-native';
import { Provider, Searchbar, Surface, ActivityIndicator } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getalltickets, ticketFilter } from "../../state/actions/ticketActions";
import isEmpty from '../../state/validations/is-empty'
import EsiURLButton from '../../components/custom/linkingfunction/linkingFunction';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import ticketgif from "../../assets/images/GIF/ticketgif.gif"


const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Ticket_Id',
    label: 'Ticket ID'
  },
  {
    value: 'Ticket_Status',
    label: 'Ticket Status'
  },
  {
    value: 'Type',
    label: 'Type'
  },
];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function Ticket(props: any) {
  const { height, width } = Dimensions.get('window');
  let ticket = useSelector((state) => state.ticket.Tickets.all);
  let ticketMenu = useSelector(state => state.ticket.ticketFilter.select);
  let ticketSearch = useSelector(state => state.ticket.ticketFilter.search);
  const isLoading = useSelector(state => state.ticket.Tickets.isLoading);
  const EmailID = useSelector(state => state.auth?.user?.Email_Id);
  const esiColor = useSelector(state => state.theme);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [cashbacks, setCashbacks] = React.useState([]);
  const { navigation } = props;
  const dispatch = useDispatch()
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

  const onRefresh = () => {
    const formData = {
      Records_Filter: "FILTER",
      Created_By: EmailID
    };
    dispatch(getalltickets(formData));
  };

  React.useEffect(() => {
    if (!isEmpty(EmailID)) {
      onRefresh();
      dispatch(ticketFilter('search', ''));
      dispatch(ticketFilter('select', 'All'));
    } else {
      setSeconds(2);
    }
  }, [EmailID]);

  const [seconds, setSeconds] = React.useState(-1);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);
      if (seconds === 1) {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const [oldSearch, setOldSearch] = React.useState(0);

  const checkticketForTable = () => {
    //search
    if (ticketSearch) {
      try {
        if (ticketMenu === "All") {
          return ticket.filter(x => {
            let colummns = ["Ticket_Id", "Ticket_Status", "Created_Date", "Type"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? ticketSearch.toLowerCase() : ticketSearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(ticketSearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == ticketSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return ticket.filter(x => {
            let mainString = typeof x[ticketMenu] == "string" ? x[ticketMenu].toLowerCase() : x[ticketMenu];
            let subString = typeof x[ticketMenu] == "string" ? ticketSearch.toLowerCase() : ticketSearch;
            if (typeof x[ticketMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == ticketSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (oldSearch != ticketSearch.length) {
          setOldSearch(ticketSearch.length);
        }
      }
    }
    else {
      return ticket
    }
  }

  React.useEffect(() => {
    if (!isEmpty(ticket)) {
      setCashbacks(ticket);
    } else {
      setCashbacks([]);
    }
  }, [ticket]);

  const handleChange = itemValue => {
    dispatch(ticketFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(ticketFilter('search', value));
  };
  const data =
    checkticketForTable()
  const styles = StyleSheet.create({
    button: {
      alignItems: "center",
      padding: 5,
      backgroundColor: esiColor.globalButtonColor,
      borderRadius: 10,
    },

    mainbox: {
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
      alignSelf: 'center',
    }, placeholderStyle: {
      fontSize: 16,
    },
    selectedTextStyle: {
      fontSize: 15,
    }, containerstyle: {
      width: 240,
    },

    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    }, iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },


  });
  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={props.navigation} />
      <Provider>

        <View style={{ alignItems: 'flex-start', margin: 10 }}><Text style={{ fontSize: 16, color: esiColor.brandFontColor, fontWeight: "bold" }}>Support Tickets</Text>
          <View style={{ alignSelf: 'flex-end', flexWrap: "wrap", flexShrink: 1, marginTop: -25 }}>

            <TouchableOpacity onPress={() => navigation.navigate('AddTicket')} style={styles.button}>
              <Text style={{ fontSize: 14, color: esiColor.itemButtenColor, fontWeight: "bold" }}>+ CREATE SUPPORT TICKET</Text>
            </TouchableOpacity>
          </View>
        </View>

        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={onRefresh}
            />
          }
        >

          <View style={{ flexDirection: "row", marginRight: 17, marginLeft: 20, marginTop: 20 }}>
            <View style={{ flex: dimensions <= 700 ? 1.5 : 0.5 }}>
              {Platform.OS !== 'web' ?
                <EsiSearchDropdown
                  style={[styles.dropdown]}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={filterOptions}
                  containerStyle={styles.containerstyle}
                  // search
                  maxHeight={180}
                  labelField="label"
                  valueField="value"
                  placeholder={!showDropDown ? 'All' : ''}
                  // searchPlaceholder="Search..."
                  value={ticketMenu}
                  onFocus={() => setShowDropDown(true)}
                  onBlur={() => setShowDropDown(false)}
                  onUpdateValue={item => {
                    setShowDropDown(false);
                    handleChange(item.value)
                  }}
                /> :
                <Picker
                  selectedValue={ticketMenu}
                  style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                  onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                >
                  <Picker.Item label="All" value="" />
                  <Picker.Item label="Ticket ID" value="Ticket_Id" />
                  <Picker.Item label="Ticket Status" value="Ticket_Status" />
                  <Picker.Item label="Type" value="Type" />
                </Picker>}
            </View>
            <View style={{ flex: dimensions <= 700 ? 0.3 : 0.6 }} />
            <View style={{ flex: 1.8 }}>
              <Searchbar
                style={{ borderRadius: 5, borderWidth: 0.5, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}
                placeholder="Search"
                placeholderTextColor={esiColor.SPHTextColor}
                iconColor={esiColor.SIconColor}
                theme={{ colors: { text: esiColor.SBTextColor } }}
                variant="outlined"
                onChangeText={(value) => handleSearch(value)}
                value={ticketSearch}
              />
            </View>
          </View>
          <ScrollView>
            <View style={styles.mainbox}>
              <View style={{ marginTop: -10 }} >
                {data.map((tickets, index) => (<View key={index}>
                  {isEmpty(tickets) ? <Text style={{ fontSize: 16, display: 'flex', justifyContent: 'center', color: esiColor.NoDataFound, alignItems: 'center', marginTop: 10 }}>
                    No records found.
                  </Text> :

                    <TouchableOpacity style={{ marginBottom: 10 }} onPress={() => { navigation.navigate('Comments', { ticketid: tickets?.Ticket_Id }) }}>

                      <View style={{
                        paddingTop: 15, marginHorizontal: 2, borderRadius: 10, padding: 2, margin: 'auto', marginTop: 10,
                        backgroundColor: esiColor.BackgroundColor,
                        borderColor: esiColor.SBorderColor,
                        borderWidth: 0.5,
                        shadowOpacity: 2,
                        shadowRadius: 2,
                        elevation: 10,
                        minHeight: 120,
                        shadowColor: esiColor.brandShadowColor,
                        flex: 1,
                        width: 360, justifyContent: 'space-between'
                      }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, margin: '1%', padding: 10 }}>
                          <View style={{ flex: 2 }} >
                            <Text style={{ fontSize: 15, color: esiColor.Text }}>Ticket ID : <Text style={{ color: esiColor.SIconColor }}>{tickets?.Ticket_Id}</Text></Text>
                            <Text style={{ fontSize: 15, marginBottom: 4, marginTop: 10, color: esiColor.Text }}>Type:  <Text style={{ fontSize: 14, color: esiColor.SIconColor }}>{tickets?.Type}</Text></Text>

                          </View>
                          <View style={{ flex: 2 }}>
                            <Text style={{ fontSize: 15, color: esiColor.Text }}>Ticket Status:  <Text style={{ color: tickets?.Ticket_Status === "New" ? "red" : tickets?.Ticket_Status === "In Progress" ? 'green' : tickets?.Ticket_Status === "Hold" ? 'blue' : tickets?.Ticket_Status === "Closed" ? esiColor.GBFColor : esiColor.Text }}>{tickets?.Ticket_Status}</Text>
                            </Text>
                            <View style={{ flexDirection: 'row', marginTop: 10 }}>
                              <Text style={{ fontSize: 15, color: esiColor.Text }}> Attachment:  </Text>
                              <EsiURLButton url={tickets?.Ticket_Attachment} />
                            </View>

                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  }
                </View>
                ))
                }
                {(isLoading || isEmpty(data)) &&
                  <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                    {/* <Image source={require('../../assets/images/not-found-animate.gif')}
                      style={{ width: 300, height: 300 }} /> */}
                    <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={ticketgif} />
                    {isLoading ?
                      <ActivityIndicator color={esiColor.SIconColor} style={{ marginTop: 20, }} />

                      : <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.NoDataFound, marginTop: 10 }}>No records found!</Text>}
                  </View>
                }</View>

            </View>
          </ScrollView>
        </ScrollView>
      </Provider>
    </Surface>
  );
}


export default Ticket