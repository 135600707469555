
import { meeapiBoomi, meeapiCUD } from "./meeapi";

export const placeCommentToBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/Bidding_User_Service_Comments/Create",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const updateServicePayment = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.put("/Bidding_User_Service/Update",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const updateUserBiddingService = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.put("/Bidding_User_Service/Service_Update",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};