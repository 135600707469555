import React, { useMemo, useRef, useState } from "react";
import { Card, Surface, Text } from "react-native-paper";
import { Image, Platform, StyleSheet, TouchableOpacity, View, ScrollView, Dimensions, ImageBackground } from "react-native";
import ImageView from "../../self_modules/react-native-image-view/src/ImageView";
import { useSelector } from "react-redux";
const { height, width } = Dimensions.get('window');

export default function ImageCarousel(props: any) {
    // image is a Product images object as we use this object to display the info front-end
    const { image, dimensions } = props
    const esiColor = useSelector(state => state.theme);

    //For image scroll
    const [isImageViewVisible, setIsImageViewVisible] = useState(false);
    const [active, setactive] = useState(0);
    const el = useRef(null);
    const change = ({ nativeEvent }: { nativeEvent: any }) => {
        const slide = Math.ceil(nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width)
        if (slide !== active) {
            setactive(slide);
        }

    }

    const zoomImage = (image) => {
        const images = [

        ];

        image.map((item => {
            images.push(
                {
                    source: {
                        uri: item.imageuri,
                    },
                    width: 806,
                    height: height,
                }
            )
        }))
        return images
    }

    const mImageView = useMemo(() => {
        try {
            return <ImageView
                images={zoomImage(image)}
                imageIndex={0}
                isVisible={isImageViewVisible}
                onClose={() => {
                    setIsImageViewVisible(false);
                }}
            />
        } catch (error) {
        }
    }, [isImageViewVisible])

    return (
        isImageViewVisible ? mImageView
            :

            <View style={{
                margin: "auto", position: 'relative', height: 400, maxHeight: 400,

                width: (width > height) ? height : width, alignItems: 'center'
            }}>
                <ScrollView
                    ref={el}
                    horizontal
                    onScroll={change}
                    showsHorizontalScrollIndicator={false}
                    automaticallyAdjustContentInsets={false}
                    style={{
                        margin: "auto", height: (height < width) ? height * 0.8 : width * 0.8,
                        width: (width > height) ? height * 0.8 : width * 0.8
                    }}>
                    {image.map((image: any, index: any) => (
                        <TouchableOpacity key={index} onPress={() => {
                            setIsImageViewVisible(true);
                        }}>
                            <ImageBackground style={{
                                height: 400, maxHeight: 400,
                                backgroundColor: esiColor.ImgBck,
                                width: (width > height) ? height * 0.8 : width * 0.8, borderRadius: 10,
                            }}>
                                <Image
                                    source={{ cache: "force-cache", uri: image.imageuri }}
                                    style={{
                                        height: 400, maxHeight: 400,
                                        width: (width > height) ? height * 0.8 : width * 0.8, resizeMode: 'contain', borderRadius: 10, shadowRadius: 2, backgroundColor: "#efefef"
                                    }}>
                                </Image>
                            </ImageBackground>
                        </TouchableOpacity>
                    ))
                    }
                </ScrollView>
                <View style={{ flexDirection: 'row', position: 'absolute', bottom: 0, alignSelf: 'center' }}>
                    {image.map((image: any, index: any) => (
                        <Text key={index} style={index == active ? styles.pagingActiveText : styles.pagingText}>⬤</Text>
                    )
                    )}
                </View>
            </View >
    )

}


const styles = StyleSheet.create({
    pagingText: { color: '#888', margin: 3 },
    pagingActiveText: { color: '#27b6cc', margin: 3 },
})