import * as React from 'react';
import { Dimensions, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import * as Yup from 'yup';
import { Button } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { getEventIdAction, updateEventsAction } from '../../state/actions/eventsActions';
import isEmpty from '../../state/validations/is-empty';
import Header from '../header';
import { ActivityIndicator, Checkbox, HelperText, Surface } from 'react-native-paper';
import ESITextInput from '../../components/custom-fields/textInput';
import { Formik } from 'formik';
import EsiDateTimePicker from '../../components/custom/datetime/datetime';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import { Ionicons } from '@expo/vector-icons';

const { height, width } = Dimensions.get('window');
export default function EditEventDetails(props) {
    const dispatch = useDispatch();
    const toast = useToast();
    const data = props?.route?.params.data
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [hall, setEventData] = React.useState([]);
    const [ID, setID] = React.useState("");
    const [isSelected, setSelection] = React.useState(hall?.Is_Hall_Decoration_Required == "1" ? true : false);
    const [isFoodSelected, setFoodSelection] = React.useState(hall?.Is_Food_Required == "1" ? true : false);
    const [isRoomSelected, setRoomSelection] = React.useState(hall?.Is_Rooms_Required == "1" ? true : false);
    const [isAlcoholSelected, setAlcoholSelection] = React.useState(hall?.IS_Event_Required_Alchol == "1" ? true : false);
    const [isLegalPermissionSelected, setLegalPermissionSelected] = React.useState(hall?.IS_Event_Has_Legal_Permission == "1" ? true : false);
    const [expanded, setExpanded] = React.useState(true);
    const handlePress = () => setExpanded(!expanded);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const user = useSelector((state) => state.auth.user);

    const halfdimentions = dimensions / 1.06;
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    React.useEffect(() => {
        if (isEmpty(user.Email_Id)) {
            setTimeout(() => {
                if (isEmpty(user.Email_Id)) {
                    props.navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [user.Email_Id])

    const callBack = (data: any) => {
        if (data) {
            setEventData(data)
            setSelection(data?.Is_Hall_Decoration_Required == "1" ? true : false)
            setFoodSelection(data?.Is_Food_Required == "1" ? true : false)
            setRoomSelection(data?.Is_Rooms_Required == "1" ? true : false)
            setAlcoholSelection(data?.IS_Event_Required_Alchol == "1" ? true : false)
            setLegalPermissionSelected(data?.IS_Event_Has_Legal_Permission == "1" ? true : false)
        }
    }

    React.useEffect(() => {
        if (isEmpty(hall) || (ID !== data)) {
            let formData = {
                User_Event_ID: data,
                Records_Filter: "FILTER"
            }
            setID(hall?.User_Event_ID)
            dispatch(getEventIdAction(formData, callBack))
        }
    }, [hall, setSelection])

    const values = {

        AC_Electricty_Price_Per_Hour: hall?.AC_Electricity_Price_Per_Hour,
        Any_Extra_Charges: hall?.Any_Extra_Charges,
        Booking_Status: hall?.Booking_Status,
        Cashback_Amount: hall?.Cashback_Amount,
        Cashback_Settled_To_User: hall?.Cashback_Settled_To_User,
        Discount_Amount: hall?.Discount_Amount,
        Discount_Percentage: hall?.Discount_Percentage,
        Electricity_Price_Per_Hour: hall?.Electricity_Price_Per_Hour,
        Event_Booking_Requested_Date: hall?.Event_Booking_Requested_Date,
        Event_End_Date: hall?.Event_End_Date,
        Event_Start_Date: hall?.Event_Start_Date,
        Event_Status: hall?.Event_Status,
        Event_Total_Members: hall?.Event_Total_Members,
        Food_NonVeg_Price_Per_Plate: hall?.Food_NonVeg_Price_Per_Plate,
        Food_Veg_Price_Per_Plate: hall?.Food_Veg_Price_Per_Plate,
        Function_Hall_Id: hall?.Function_Hall_Id,
        Hall_Decoration_Price: hall?.Hall_Decoration_Price,
        IS_Event_Has_Legal_Permission: hall?.IS_Event_Has_Legal_Permission,
        IS_Event_Required_Alchol: hall?.IS_Event_Required_Alchol,
        Is_Food_Required: hall?.Is_Food_Required,
        Is_Hall_Decoration_Required: hall?.Is_Hall_Decoration_Required,
        Is_Rooms_Required: hall?.Is_Rooms_Required,
        Meepaisa_Commission: hall?.Meepaisa_Commission,
        Modified_By: user.Email_Id,
        No_Of_AC_Rooms: hall?.No_Of_AC_Rooms,
        No_Of_NonAC_Rooms: hall?.No_Of_NonAC_Rooms,
        No_Of_NonVeg_Plates: hall?.No_Of_NonVeg_Plates,
        No_Of_Veg_Plates: hall?.No_Of_Veg_Plates,
        Partner_Total_Share: hall?.Partner_Total_Share,
        Price_Per_Day: hall?.Price_Per_Day,
        Price_Per_Hour: hall?.Price_Per_Hour,
        Room_Cost_Per_Night: hall?.Room_Cost_Per_Night,
        Status: hall?.Status,
        User_Email_ID: user.Email_Id,
        User_Event_ID: hall?.User_Event_ID,
        Vent_Date: hall?.Event_Date
    }

    return (
        <Surface style={{ flex: 1 }}>
            <Header {...props} />
            {!isEmpty(hall) ?
                <>
                    <View style={{ flexDirection: "column", flexWrap: "wrap", alignItems: 'center', alignContent: 'center' }}>
                        <Text style={{ fontSize: 16, marginTop: 5, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>Please check below deatils for booking</Text>
                    </View>
                    <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => { props.navigation.navigate("AllEvents") }}>
                        <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
                    </TouchableOpacity>
                    <ScrollView>
                        <Formik
                            initialValues={values}

                            validationSchema={Yup.object().shape({
                                Event_Total_Members: Yup.string().matches(/^[0-9]+$/, "Please enter valid number.").required("Event members is requried.").test('test_length_greater_than_40', '', function (value) {
                                    if (Number(value) > Number(hall?.Hall_Seating_Capacity)) {
                                        return this.createError({ message: `Functional hall capacity is  ${hall?.Hall_Seating_Capacity} Please provide valid.` })
                                    }
                                    return true
                                }),
                                No_Of_NonVeg_Plates: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_Veg_Plates', { // Change approvalState to string
                                        is: (value: any) => !value && isFoodSelected,
                                        then: Yup.string().required("Number of non veg plates are requried."),
                                    })),
                                No_Of_Veg_Plates: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_NonVeg_Plates', { // Change approvalState to string
                                        is: (value: any) => !value && isFoodSelected,
                                        then: Yup.string().required("Number of veg plates are requried."),
                                    })),
                                No_Of_AC_Rooms: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_NonAC_Rooms', { // Change approvalState to string
                                        is: (value: any) => !value && isRoomSelected,
                                        then: Yup.string().required("Number of veg plates are requried."),
                                    })),
                                No_Of_NonAC_Rooms: Yup.lazy(() => Yup.string().matches(/^[0-9]+$/, "Please enter valid number.")
                                    .when('No_Of_AC_Rooms', { // Change approvalState to string
                                        is: (value: any) => !value && isRoomSelected,
                                        then: Yup.string().required("Number of non veg plates are requried."),
                                    })),
                                Event_Start_Date: Yup.date().min(new Date(), 'Please choose future date').required("Event Start Date is required."),
                                Event_End_Date: Yup.date().min(Yup.ref("Event_Start_Date"), "End date has to be more than start date.").required("Event End Date is required."),
                            })}
                            onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
                                let formData = JSON.parse(JSON.stringify(values));
                                dispatch(updateEventsAction(formData, props.navigation.navigate, user.Email_Id, toast))
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                                <View style={{
                                    flex: 1,
                                    marginHorizontal: 2, padding: 5, margin: 'auto', backgroundColor: 'white', flexDirection: 'column', borderColor: "#CACFD2", borderWidth: 1,
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowColor: '#6D70E7',
                                    marginTop: "1%",
                                    marginBottom: "1%",
                                    shadowOpacity: 0.2,
                                    shadowRadius: 10,
                                    elevation: 10,
                                    marginLeft: "auto", marginRight: "auto",
                                    width: dimensions >= 10000 ? "90%" : dimensions,
                                }}>
                                    <View style={{ width: "98%", margin: "auto" }}>
                                        <ESITextInput style={{ width: "100%", margin: "auto" }}
                                            label="Number of Attendees"
                                            mode='outlined'
                                            name="Event_Total_Members"
                                            value={values.Event_Total_Members}
                                            onChangeText={handleChange('Event_Total_Members')}
                                            error={Boolean(touched.Event_Total_Members && errors.Event_Total_Members)}
                                        />
                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Event_Total_Members && errors.Event_Total_Members)}>
                                            {touched.Event_Total_Members && errors.Event_Total_Members}
                                        </HelperText>
                                    </View>

                                    <View style={{ width: "98%", margin: "auto" }}>
                                        <EsiDateTimePicker style={{ width: "100%", margin: "auto" }}
                                            label="Event Start Date and Time"
                                            mode='outlined'
                                            name="Event_Start_Date"
                                            value={values.Event_Start_Date}
                                            valueUpdate={item => {
                                                setFieldValue('Event_Start_Date', item)
                                            }}
                                            error={Boolean(touched.Event_Start_Date && errors.Event_Start_Date)}
                                        />
                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Event_Start_Date && errors.Event_Start_Date)}>
                                            {touched.Event_Start_Date && errors.Event_Start_Date}
                                        </HelperText>
                                    </View>

                                    <View style={{ width: "98%", margin: "auto" }}>
                                        <EsiDateTimePicker style={{ width: "100%", margin: "auto" }}
                                            label="Event Start Date and Time"
                                            mode='outlined'
                                            name="Event_End_Date"
                                            value={values.Event_End_Date}
                                            valueUpdate={item => {
                                                setFieldValue('Event_End_Date', item)
                                            }}
                                            error={Boolean(touched.Event_End_Date && errors.Event_End_Date)}
                                        />
                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Event_End_Date && errors.Event_End_Date)}>
                                            {touched.Event_End_Date && errors.Event_End_Date}
                                        </HelperText>
                                    </View>
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="Is_Hall_Decoration_Required"
                                                status={isSelected ? 'checked' : 'unchecked'}
                                                onPress={() => { setFieldValue("Is_Hall_Decoration_Required", !isSelected ? "1" : "0"); setSelection(!isSelected) }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Decoration Required?</Text>

                                        </View>
                                    </View>

                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="Is_Food_Required"
                                                status={isFoodSelected ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Food_Required", !isFoodSelected ? "1" : "0"); setFoodSelection(!isFoodSelected); !isFoodSelected && (setFieldValue("No_Of_Veg_Plates", ""),
                                                        setFieldValue("No_Of_NonVeg_Plates", ""))
                                                }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Food Required?</Text>

                                        </View>
                                    </View>
                                    {isFoodSelected &&
                                        <View style={{
                                            flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput style={{ width: "100%", margin: "auto" }}
                                                    label="Number Of Veg Plates"
                                                    mode='outlined'
                                                    name="No_Of_Veg_Plates"
                                                    value={values.No_Of_Veg_Plates}
                                                    onChangeText={handleChange('No_Of_Veg_Plates')}
                                                    error={Boolean(isFoodSelected && touched.No_Of_Veg_Plates && errors.No_Of_Veg_Plates)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(isFoodSelected && touched.No_Of_Veg_Plates && errors.No_Of_Veg_Plates)}>
                                                    {isFoodSelected && touched.No_Of_Veg_Plates && errors.No_Of_Veg_Plates}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput 
                                                    style={{ width: "100%" }}
                                                    label="Number Of Non Veg Plates"
                                                    mode='outlined'
                                                    name="No_Of_NonVeg_Plates"
                                                    value={values.No_Of_NonVeg_Plates}
                                                    onChangeText={handleChange('No_Of_NonVeg_Plates')}
                                                    error={Boolean(isFoodSelected && touched.No_Of_NonVeg_Plates && errors.No_Of_NonVeg_Plates)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(values.Is_Food_Required && touched.No_Of_NonVeg_Plates && errors.No_Of_NonVeg_Plates)}>
                                                    {isFoodSelected && touched.No_Of_NonVeg_Plates && errors.No_Of_NonVeg_Plates}
                                                </HelperText>
                                            </View>
                                        </View>
                                    }
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="Is_Rooms_Required"
                                                status={isRoomSelected ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Rooms_Required", !isRoomSelected ? "1" : "0"); setRoomSelection(!isRoomSelected); !isRoomSelected && (setFieldValue("No_Of_AC_Rooms", ""),
                                                        setFieldValue("No_Of_NonAC_Rooms", ""))
                                                }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Rooms Required?</Text>

                                        </View>
                                    </View>
                                    {isRoomSelected &&
                                        <View style={{
                                            flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput style={{ width: "100%", margin: "auto" }}
                                                    label="Number Of AC rooms"
                                                    mode='outlined'
                                                    name="No_Of_AC_Rooms"
                                                    value={values.No_Of_AC_Rooms}
                                                    onChangeText={handleChange('No_Of_AC_Rooms')}
                                                    error={Boolean(isRoomSelected && touched.No_Of_AC_Rooms && errors.No_Of_AC_Rooms)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(isRoomSelected && touched.No_Of_AC_Rooms && errors.No_Of_AC_Rooms)}>
                                                    {isRoomSelected && touched.No_Of_AC_Rooms && errors.No_Of_AC_Rooms}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "98%", margin: "auto" }}>
                                                <ESITextInput 
                                                    style={{ width: "100%" }}
                                                    label="Number Of Non AC rooms"
                                                    mode='outlined'
                                                    name="No_Of_NonAC_Rooms"
                                                    value={values.No_Of_NonAC_Rooms}
                                                    onChangeText={handleChange('No_Of_NonAC_Rooms')}
                                                    error={Boolean(values.Is_Rooms_Required && touched.No_Of_NonAC_Rooms && errors.No_Of_NonAC_Rooms)}
                                                />
                                                <HelperText style={{ width: "90%" }} type="error" visible={Boolean(values.Is_Rooms_Required && touched.No_Of_NonAC_Rooms && errors.No_Of_NonAC_Rooms)}>
                                                    {values.Is_Rooms_Required && touched.No_Of_NonAC_Rooms && errors.No_Of_NonAC_Rooms}
                                                </HelperText>
                                            </View>
                                        </View>
                                    }
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="IS_Event_Required_Alchol"
                                                status={isAlcoholSelected ? 'checked' : 'unchecked'}
                                                onPress={() => { setFieldValue("IS_Event_Required_Alchol", !isAlcoholSelected ? "1" : "0"), setAlcoholSelection(!isAlcoholSelected) }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Alcohol Required?</Text>

                                        </View>
                                    </View>
                                    <View style={{ marginLeft: 10, flex: 1 }}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Checkbox
                                                color="#27B6CC"
                                                name="IS_Event_Has_Legal_Permission"
                                                status={isLegalPermissionSelected ? 'checked' : 'unchecked'}
                                                onPress={() => { setFieldValue("IS_Event_Has_Legal_Permission", !isLegalPermissionSelected ? "1" : "0"); setLegalPermissionSelected(!isLegalPermissionSelected) }}
                                            />
                                            <Text style={{ marginTop: 8 }}>Is Event has Legal Permission?</Text>

                                        </View>
                                    </View>
                                    <Button
                                        onPress={handleSubmit}
                                        buttonStyle={{ backgroundColor: "#27b6cc", borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                                        title='UPDATE BOOKING' />
                                </View>
                            )}
                        </Formik>
                    </ScrollView>
                </>
                : <ActivityIndicator animating={true} style={{ alignItems: "center" }} color={"#27b6cc"} />}
        </Surface >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    tinyLogo: {
        width: 500,
        height: 200,
    },
});
