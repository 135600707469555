import React, { useEffect } from "react";
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../components/custom/product-card";
import { getProductsAction } from "../../state/actions/node-actions/home-actions";

export default function MeeProducts(props) {
    const { navigation, partnerId } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const [wishdata, setWishData] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
        }
    }

    useEffect(() => {
        productsCall();
    }, [partnerId]);

    const productsCall = () => {
        if (!loading && partnerId) {
            setLoading(true);
            dispatch(getProductsAction({ "Records_Filter": "FILTER", "Status": "Active", "condition": true, "Partner_Details_Id": [partnerId], "Search": "" }, { PageNo: 1, PageSize: 20, SortBy: "PRODUCT_NAME", SortOrder: "DESC" }, callBackGetData));
        }
    }

    const handleClick = async (item) => {
        navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata })
    };
    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            // shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 5,
            shadowRadius: 10,
            // elevation: 10
        }

    });
    return (
        <View style={styles.content}>
            <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                <Text style={{ fontSize: 16, color: "#27b6cc", fontWeight: 'bold' }}> Products</Text>
                <TouchableOpacity onPress={(e) => {
                    // dispatch(resetMainData());
                    props.navigation.navigate('PartnerProducts', { Partner_Details_Id: partnerId, back: "PartnerDetails" });
                    // props.navigation.navigate('Main', { screen: 'All', params: { id: partnerId, type: "partner", page: 'products', filter: 'all' } });
                }} >
                    <Text style={{ fontSize: 13, color: esiColor.DescColor, fontWeight: 'bold' }}>View All</Text>
                </TouchableOpacity>
            </View>
            <ScrollView
                contentContainerStyle={{ paddingVertical: 10 }}
                horizontal
                showsHorizontalScrollIndicator={false}>

                <View style={{ flexDirection: 'row', }}>
                    {allproducts.length <= 0 ? (
                        <View style={{ width: dimensions.width - 50, flex: 1, justifyContent: "center" }}>
                            <Text style={{ alignSelf: "center", color: esiColor.NoDataFound }} >No Products found!</Text>
                        </View>
                    ) : (
                        allproducts.slice(0, 10).map((data, index) => (
                            <View key={index}>
                                <ProductCard data={data} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={data?.Product_Id} PartnerId={data?.Partner_Details_Id} />
                            </View>
                        ))
                    )}

                </View>
            </ScrollView>
        </View>
    )
}

