import React from "react";
import { View, Image, TouchableOpacity, Dimensions, ImageBackground } from "react-native";
import { Card, Text } from "react-native-paper";
import default_image from '../../assets/images/coin.png';
import { useSelector } from "react-redux";


export default function ESICouponsCard(props: any) {
  // data is a Coupon object as we use this object to display the info front-end
  // toggleModal param is used to call the Coupon details popup screen
  const { data, toggleModal, ...rest } = props
  const esiColor = useSelector(state => state.theme);
  const sampleData =
  {
    Records_Filter: "FILTER",
    Brand_Id: [],
    Partner_Details_Id: [],
    Category_Id: [],
    Coupon_Id: [],
    Discount_Type: "",
    Min_Discount: "",
    Max_Discount: "",
    Esi_Cashback_Type: "",
    Min_Esi_Cashback: "",
    Max_Esi_Cashback: "",
    Is_Top_Coupon: "",
    Is_Upcoming: "",
    Is_Coupon_Expired: "",
    Whats_Hot: "",
    Verified: "",
    Exclusive: "",
    Extra_Cashback: "",
    Expired_Status: "",
    Status: "Active",
    Partner_Type: "",
    Is_Top_Brand: "",
  };

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const halfdimentions = dimensions / 2;

  return (
    <Card
      theme={{
        colors: {
          surface: esiColor.BackgroundColor,
        }
      }}
      style={{
        margin: 5,
        maxWidth: 230, width: (halfdimentions - 20), shadowColor: esiColor.brandShadowColor,
        borderRadius: 10, borderWidth: 0.5, shadowRadius: 10, elevation: 10, opacity: 10,
        backgroundColor: data?.Expired_Status === "Live" ? esiColor.CBColor : esiColor.CBColor,
      }}>
      <View style={{ flexDirection: 'row', marginVertical: 5, width: "100%" }}>
        {/* <Image source={{ cache: "force-cache", uri: data?.Logo_Path }} style={{ alignItems: 'flex-start', flex: 2, maxHeight: 40, width: '60%', opacity: data?.Expired_Status === "Live" ? 2.5 : 0.3 }} /> */}
        {parseInt(data?.Discount) != 0 &&
          <View style={{ alignItems: 'flex-end', flex: 2, marginBottom: 2, borderRadius: 10 }}>
            <Text style={{ fontSize: 14, color: data?.Expired_Status === "Live" ? esiColor.SIconColor : esiColor.Text, borderTopLeftRadius: 3, borderBottomLeftRadius: 3 }}>{data?.Discount_Type == 'A' && data?.Discount + "/-"}{(data?.Discount_Type == 'P' || data?.Discount_Type == 'percentage' || data?.Discount_Type == 'Percentage') && data?.Discount + "%"} Off</Text>
          </View>
        }
      </View>
      <View style={{ flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <ImageBackground style={{ height: 100, width: "100%", backgroundColor: esiColor.ImgBck }}>
        <Image source={{ cache: "force-cache", uri: data || data.Image_Path.length > 1 ? data.Image_Path : default_image + `?w=200&h=100` }} style={{ height: 100, width: "100%", alignSelf: 'center' }} resizeMode='contain'></Image>
        </ImageBackground>
      </View>
      <View style={{ alignSelf: 'center', flexDirection: 'column', margin: 2 }}>
        <Text style={{ textAlign: 'center', color: data?.Expired_Status === "Live" ? esiColor.DescColor : '#ffffff', opacity: 0.8, fontSize: 14, height: 35 }}>{data?.Name?.substring(0, 40)}...</Text>

        <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <Image source={(data?.Esi_Cashback_Type != 'R') ? require("../../assets/images/cashback.gif") : require("../../assets/images/cashback.gif")} style={{ height: 30, width: "40%", resizeMode: 'contain' }} />
          <Text style={{ color: esiColor.amountColor, fontWeight: "bold", marginTop: 5 }} > {data?.Cashback_Label?.toLowerCase()} {data?.ESI_Cashback_Amount}{(data?.ESI_Cashback_Type == 'P' || data?.ESI_Cashback_Type == 'percentage' || data?.ESI_Cashback_Type == 'Percentage') ? '%'
            : (data?.Esi_Cashback_Type != 'R') ? '/-' : "*"}</Text>
        </View>
        {data?.Expired_Status == "Live" ?
          <Text style={{ fontSize: 10, textAlign: 'center', color: esiColor.DescColor, }}>Coupon will expire on {data?.Valid_Through_Date}</Text> : data?.Expired_Status == "Ideal" ?
            <Text style={{ fontSize: 10, textAlign: 'center', color: esiColor.DescColor, }}>Coupon valid from {data?.Valid_From_Date}</Text> : <Text style={{ fontSize: 10, textAlign: 'center', color: esiColor.DescColor }}>Coupon has expired on {data?.Valid_Through_Date}</Text>}
      </View>
      <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 4 }}>
        <TouchableOpacity style={{ backgroundColor: data?.Expired_Status === "Live" ? esiColor.globalButtonColor : 'lightgrey', borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }} disabled={data?.Expired_Status === "Live" ? false : true} onPress={(e) => { data?.Expired_Status === "Live" ? toggleModal(data) : null }}>
          <Text style={{ color: data?.Expired_Status === "Live" ? esiColor.itemButtenColor : "#000", fontWeight: 'bold', marginBottom: 2 }}>Grab Coupon</Text>
        </TouchableOpacity>
      </View>
    </Card>
  );
}
