import React, { useEffect, useState } from "react";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import TrackingMap from "../../../components/custom/map/TrackingMap/TrackingMap";
import { getRideTrackingDetails } from "../../../state/actions/ridesAction";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";

export default function DeliveryTracking(props: any) {
    const dispatch = useDispatch();
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const [loading, setLoading] = useState(false);
    const returnOrderTrackingDetails = (status, data) => {
        setLoading(false);
        let haveData = true;
        if (status) {
            let delTrack = data?.Ride_Status.Ride_Live_Location[0];
            let userTrack = { Latitude: data?.Pickup_Details[0]?.Latitude, Longitude: data?.Pickup_Details[0]?.Longitude };
            if (delTrack?.Latitude && delTrack?.Longitude) {
                setDeliveryTracking({ latitude: Number(delTrack?.Latitude), longitude: Number(delTrack?.Longitude) });
            } else {
                haveData = false;
            }
            if (isEmpty(userTracking)) {
                if (userTrack?.Latitude && userTrack?.Longitude) {
                    setUserTracking({ latitude: Number(userTrack.Latitude), longitude: Number(userTrack.Longitude) });
                } else if (delTrack?.Latitude && delTrack?.Longitude) {
                    setUserTracking({ latitude: Number(delTrack?.Latitude), longitude: Number(delTrack?.Longitude) });
                }
            }
        }
        setSeconds(5);
    }


    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : !loading ? getOrderTrackingDetailsLocal(userTrackingID) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);


    const [deliveryTracking, setDeliveryTracking] = useState({});
    const [userTracking, setUserTracking] = useState({});
    const [userTrackingID, setUserTrackingID] = useState("");

    const getOrderTrackingDetailsLocal = (id) => {
        setLoading(true);
        dispatch(getRideTrackingDetails({
            "User_Ride_Id": id
        }, returnOrderTrackingDetails));
        setSeconds(-1);
    }


    useEffect(() => {
        if (props?.rideId && !isEmpty(props?.rideId) && !isEmpty(EmailID) && !loading) {
            getOrderTrackingDetailsLocal(props?.rideId);
            setUserTrackingID(props?.rideId);
        }
    }, [props?.rideId])
    return (
        (!isEmpty(userTracking) && !isEmpty(deliveryTracking)) &&
        <TrackingMap origin={deliveryTracking} destination={userTracking} navigation={props.navigation} isBackRequired={false} driverFlag={"car"}  />

    )
}
