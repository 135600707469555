import React, { Fragment, useEffect } from "react";
import { Dimensions, FlatList, ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ESIDealsCard from "../../components/custom/deal-card";
import ESIDealModel from "../../components/custom/deal-modal";
import isEmpty from "../../state/validations/is-empty";
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import { resetMainData } from "../../state/actions/filterServiceActions";
import { getDealsAction } from "../../state/actions/node-actions/home-actions";

export default function MeeDeals(props) {
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const width = useWindowDimensions();
    const { navigation, dataReload, dataErrorReload, ...rest } = props;
    const [open, setOpen] = React.useState(false);
    const [dealData, setDealData] = React.useState('');
    const [isModalVisible, setModalVisible] = React.useState(false);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [loading, setLoading] = React.useState(false);
    const [deal, setDeal] = React.useState([]);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setDeal(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            dealsCall();
        } else {
            await delay(dataErrorReload);
            dealsCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        if (!(deal?.length > 0 || loading)) {
            dealsCall();
        }
    }, []);

    const dealsCall = () => {
        setLoading(true);
        let formData = {
            Records_Filter: "FILTER"
        }
        dispatch(getDealsAction(formData, { PageNo: 1, PageSize: 20, SortBy: "DEAL_ID", SortOrder: "DESC" }, callBackGetData));
    }


    // Condition for deals expired before  3 days only will display at UI
    const dealLiveData = (deals: any) => {
        let data = [];
        var currentdate = new Date(),
            date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
        if (deals) {
            if (deals[0]) {
                data = deals.filter((deal: any) => {
                    let currentDate = new Date(date);
                    let dealExpDate = new Date(deal.End_Date?.split("/").reverse().join("-"));
                    return Math.floor((currentDate - dealExpDate) / (1000 * 60 * 60 * 24)) < 3
                })
            }
        } else {
            data = []
        }
        return data;
    }

    //displaying Signin pop up
    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    const openModal = (data) => {
        setDealData(data)
        setOpen(true);
    };
    const toggleModal = async (data) => {
        if (isAuthenticated) {
            openModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeModal = () => {
        setOpen(!open);
        setDealData("")
    };
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window }) => {
                setDimensions(window);
            }
        );
    });


    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            elevation: 10
        },

    });

    return (dealLiveData(deal).length > 0 &&
        <View>
            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', marginLeft: 2 }}> Mee Deals</Text>

            <Surface style={{ elevation: 0, backgroundColor: esiColor.CBColor }}>

                <View style={styles.content}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 5 }}>
                        <TouchableOpacity onPress={(e) => {
                            dispatch(resetMainData());
                            navigation('Main', { screen: 'All', params: { page: 'deals' } })
                        }} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold' }}>View All</Text>
                        </TouchableOpacity>
                    </View>
                    <FlatList
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        data={dealLiveData(deal).slice(0, 10)}
                        renderItem={({ item, index, separators }) => (
                            <ESIDealsCard data={item} toggleModal={toggleModal} />
                        )}
                    />
                </View>

                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                    <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                        <SignIN navigation={navigation} toggleModal={signInModalResponse} />
                    </View>
                </Modal>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={open}>
                    <View style={{
                        flex: 1, maxWidth: 600, minWidth: dimensions.width <= 400 ? 200 : dimensions.width <= 400 ? 200 : 400,
                        maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 400,
                        borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 5, shadowOpacity: 5
                    }}>
                        <ESIDealModel navigation={navigation} openModal={openModal} data={dealData} closeModal={closeModal} />
                    </View >
                </Modal>

            </Surface>
        </View>
    )

}
