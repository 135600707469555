import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View, ScrollView, RefreshControl, FlatList, Dimensions, Platform, ActivityIndicator, Image, TouchableOpacity, StyleSheet } from "react-native";
import { Avatar, Badge, Button, HelperText, IconButton, Menu, Searchbar, Surface, Text, TextInput, TouchableRipple } from "react-native-paper";

import { useDispatch, useSelector } from "react-redux";

import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import Pagination from "../../../components/custom/pagination/pagination";
import { Drawer } from 'react-native-drawer-layout';
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import Modal from "react-native-modal";
import SignIN from "../../auth/signin";
import { useToast } from "../../../self_modules/react-native-paper-toast/src";
import { Formik } from "formik";
import * as Yup from 'yup';

import EsiTextAreaInput from "../../../components/custom/TextAreaInput/textareainput";
import * as Location from "expo-location";
import api from "../../../state/actions/api";
import { availabileBiddingServices, createServiceBids, createServiceCartBids } from "../../../state/actions/boomi-actions/bid-services-action";

import EsiDatePicker from "../../../components/custom/date/date";
import ESITextInput from "../../../components/custom-fields/textInput";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown1";

export default function NewBiddingServices(props) {
  const [open, setOpen] = React.useState(false);
  const esiColor = useSelector(state => state.theme);
  const { navigation } = props;
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  const [loading, setLoading] = React.useState(false);
  const [allServices, setAllservices] = React.useState([]);
  const [pageOld, setPageOld] = React.useState(false);
  const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
  const userAddress = useSelector((state) => { state.auth.user.address });
  const callBackGetData = async (status, data, count, pagination) => {
    setLoading(false);
    if (status) {
      setAllservices(data);
      setCartCount(count?.Cart_Count || 0);
      setPageOld(true);
      if (pagination) {
        setPagination(pagination);
      } else {
        setPagination({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
      }
    }
  }

  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "BIDDING_PRODUCT_ID", SortOrder: "DESC" });
  const [search, setSearch] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const data = props?.route?.params;

  useEffect(() => {
    setSearch("");
    return () => {
      setloadingForm(false);
      setFormResults(false);
    };
  }, [data])

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'android' && !Constants.isDevice) {
        alert(
          'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
        );
        return;
      }
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        // Alert.alert('Location Service not enabled', 'Please enable your location services to continue', [{ text: 'OK' }], { cancelable: false });
        return;
      }

      const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
      api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
        let info = response?.data?.address?.postcode;
        if (info)
          setPincode(info)

      }).catch(async (reason) => {
      });
    })();
  }, [])

  const [filters, setFilters] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  const callServices = () => {
    let formData = {
      Bidding_Service_Id: "",
      Partner_Bidding_Service_Available_Id: "",
      Partner_Details_Id: "",
      Records_Filter: "All",
      User_Mail_Id: logedInEmail
    }

    if (filters.length > 0)
      formData.Categorys = filters.map((item) => item.id);
    formData.Search = search;
    formData.EmailId = logedInEmail || "";
    if (!pageOld) {
      setLoading(true);
      dispatch(availabileBiddingServices(formData, pagination, callBackGetData));
    } else {
      setPageOld(false);
    }

  }

  useEffect(() => {
    callServices();
  }, [search, logedInEmail, filters, pagination]);
  const scroll = useRef(null);
  const goToTop = () => {
    scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
  }


  const [isModalVisible, setModalVisible] = React.useState(false);
  const toast = useToast();

  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }

  const signInModalResponse = (status: any) => {
    setModalVisible(!isModalVisible);
    if (status) {

    }
  };


  const [addCartPop, setAddCartPop] = React.useState(false);
  const [addCartItem, setAddCartItem] = React.useState({});
  const addServiceTOBid = (data: any) => {
    if (logedInEmail) {
      setAddCartPop((prop) => !prop);
      setAddCartItem(data);
    } else {
      setModalVisible(!isModalVisible);
    }
  }

  const styles = StyleSheet.create({
    container: {
      // flex: 1,
      marginTop: 20,
      backgroundColor: esiColor.CBColor
      // maxWidth:250
    },
    list: {
      paddingHorizontal: 5,
      backgroundColor: esiColor.BackgroundColor,
    },
    listContainer: {
      alignItems: 'center',
    },
    separator: {
      marginTop: 2,
    },
    /******** card **************/
    card: {
      shadowColor: esiColor.brandShadowColor,
      shadowOpacity: 5,
      shadowRadius: 7,
      marginVertical: 5,
      backgroundColor: esiColor.CBColor,
      marginHorizontal: 5,
      borderRadius: 10
    },
    cardHeader: {
      paddingVertical: 17,
      paddingHorizontal: 16,
      borderTopLeftRadius: 1,
      borderTopRightRadius: 1,
      flexDirection: 'row',
      justifyContent: 'space-between'
    },

    cardFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 12.5,
      paddingBottom: 25,
      paddingHorizontal: 16,
      borderBottomLeftRadius: 1,
      borderBottomRightRadius: 1,
    },

    /******** card components **************/
    title: {
      fontSize: 16,
      flexWrap: "wrap",
      color: esiColor.itemColor
    },
    description: {
      fontSize: 14,
      flex: 1,
    },

    buyNow: {
      color: esiColor.itemButtenColor,
    },
    icon: {
      width: 25,
      height: 25,
      color: esiColor.globalButtonColor
    },
    /******** social bar ******************/
    socialBarContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
    },
    socialBarSection: {
      justifyContent: 'center',
      flexDirection: 'row',
      flex: 1,
    },

    socialBarButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: esiColor.globalButtonColor,
      borderColor: esiColor.TextOuterLine,
      borderWidth: 0.2,
      borderRadius: 8,
      padding: 2
    },
    model_title: {
      fontSize: 18,
      flex: 1,
      alignContent: 'center',
      marginLeft: 15,
      fontWeight: 'bold',
      color: esiColor.Text
    },


  })

  const getServiceListView = useMemo(() => {
    let numColumCount = Number((dimensions.width / 250).toFixed() == "1" ? 2 : (dimensions.width / 250).toFixed());

    return (
      <View style={styles.container}>
        <FlatList
          style={styles.list}
          contentContainerStyle={styles.listContainer}
          data={allServices}
          horizontal={false}
          numColumns={2}
          keyExtractor={item => {
            return item.Bidding_Service_Id + Math.random()
          }}
          ItemSeparatorComponent={() => {
            return <View style={styles.separator} />
          }}
          renderItem={({ item, index, separators }) => {
            return (
              <View style={[styles.card, { width: (dimensions.width / Number(numColumCount)) - 10, maxWidth: 230, }]}>
                <View style={styles.cardHeader}>
                  <View>
                    <Text style={[styles.title, { width: (dimensions.width / Number(numColumCount)) - 15, maxWidth: 220 }]}>{item.Service_Type}</Text>
                    {/* <Text style={styles.description} numberOfLines={1} ellipsizeMode='tail'>{item.Bidding_Service_Id}</Text> */}
                  </View>
                </View>

                <Image style={{ resizeMode: 'contain', width: (dimensions.width / Number(numColumCount)) - 20, maxWidth: 210, height: ((dimensions.width / Number(numColumCount)) - 20) * .7, maxHeight: 200, borderRadius: 8, backgroundColor: esiColor.BackgroundColor }}
                  source={{ cache: "force-cache", uri: item.Service_Image + `?w=210&h=200` }}></Image>
                <View style={styles.cardFooter}>
                  <View style={styles.socialBarContainer}>
                    <View style={styles.socialBarSection}>
                      <TouchableOpacity
                        style={styles.socialBarButton}
                        onPress={() => { addServiceTOBid(item) }}>
                        <Image
                          style={styles.icon}
                          source={{
                            uri: 'https://img.icons8.com/nolan/96/3498db/add-shopping-cart.png',
                          }}
                        />
                        <Text style={[styles.buyNow]}>  Add to Bid</Text>
                      </TouchableOpacity>
                    </View>

                  </View>
                </View>
              </View>
            )
          }}
        />
      </View>

    )
  }, [allServices, dimensions])

  const drawerContent = useCallback(() => {
    return (
      <ScrollView
        showsHorizontalScrollIndicator={false}
        style={{ marginVertical: 10 }} >
        <View style={{ marginTop: 5 }} >
        </View>
      </ScrollView >
    )
  }, [open])

  const [loadingForm, setloadingForm] = React.useState(false);
  const [formResults, setFormResults] = React.useState(false);
  const [formikSubmitType, setFormikSubmitType] = React.useState(0);
  let nDate = new Date();
  nDate.setDate(nDate.getDate() + 2);
  let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
  let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
  const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
    "-" + date);
  const [experyDate, setExperyDate] = useState();
  const [serviceHours, setserviceHours] = useState();
  const [serviceDays, setserviceDays] = useState();



  const formCallbackResponse = (status: boolean) => {
    setloadingForm(false);
    if (status) {
      setFormResults(true);
      callServices();
    }
  }

  const canGoBackCallback = useMemo(() => {
    return navigation.canGoBack();
  }, [navigation])


  const datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }


  return (
    <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1 }}>
      <Header {...props} />

      <Drawer
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        drawerStyle={{}}
        renderDrawerContent={drawerContent}
        drawerPosition={"left"}
      >
        <View>
          <View style={{ flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
            {canGoBackCallback &&
              <IconButton
                icon="arrow-left"
                size={27}
                color={"#27B6CC"}
                style={{ marginLeft: 1 }}
                onPress={() => {
                  navigation.goBack();
                }} />
            }
            <Searchbar style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, minWidth: "80%", maxWidth: "80%", borderWidth: 0.5, maxHeight: 30, marginTop: 10 }}
              inputStyle={{ fontSize: 12, marginLeft: -20 }}
              value={search}
              onChangeText={(value) => {
                setSearch(value);
              }}
              iconColor={esiColor.SIconColor}
              placeholderTextColor={esiColor.SPHTextColor}
              theme={{ colors: { text: esiColor.SBTextColor } }}
              multiline={false}
              placeholder="Search"
            />


          </View>

        </View>
        <ScrollView ref={scroll}
          refreshControl={
            <RefreshControl
              refreshing={loading}
            />
          }
        >
          <View style={{ alignItems: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'space-around' }}>
            {isEmpty(allServices) ? (loading ? <ActivityIndicator /> :
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                <Text style={{ color: esiColor.NoDataFound }}>No Services found!</Text>
              </View>
            ) : (
              getServiceListView
            )
            }
          </View>



          <View style={{ marginBottom: 5 }}>
            <Pagination pagination={pagination} setPagination={setPagination} />
          </View>

        </ScrollView>
        {(cartCount > 0 && logedInEmail) &&
          <TouchableOpacity onPress={() => {
            navigation.navigate("CartBidServices");
          }} style={{ position: 'absolute', right: 0, bottom: 20, backgroundColor: esiColor.esiMainColor, borderRadius: 8, flexDirection: "row", justifyContent: "center", padding: 5, marginRight: 10 }} >
            <Ionicons name="cart" size={22} style={{}} color="white" />

            <Badge style={{ position: "absolute", right: 0, top: -10, color: esiColor.esiMainColor, backgroundColor: "#efefef" }} >{cartCount}</Badge>
          </TouchableOpacity>}
      </Drawer>
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }}
        style={{ backgroundColor: "#FAFAFA" }} isVisible={isModalVisible}>

        <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: "#FAFAFA" }}>

          <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

        </View>

      </Modal>

      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={addCartPop}>
        <ScrollView style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10 }}>
          <Surface
            theme={{ colors: { surface: esiColor.BackgroundColor } }}
            style={{
              justifyContent: 'center',
              borderRadius: 10,
              top: Platform.OS === 'web' ? 10 : 20,
              elevation: 0
            }} >
            <View style={{ flexDirection: 'row', paddingVertical: 15 }}>
              <Text style={styles.model_title}>{addCartItem.Bidding_Service_Id}</Text>
              <TouchableRipple style={{ width: 50, alignSelf: "flex-end" }} onPress={() => {
                setAddCartPop((prop) => !prop);
                setloadingForm(false);
                setFormResults(false);
              }}>
                <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
              </TouchableRipple>
            </View>

            <Formik
              initialValues={
                {

                  Bidding_Expiry_Date: "",
                  Bidding_Service_Id: addCartItem.Bidding_Service_Id,
                  Description: "",
                  User_Email_Id: logedInEmail,
                  User_Address_Id: addCartItem.Partner_Address_Id,

                  Partner_Details_Id: addCartItem.Partner_Detail_Id,
                  Pincode: "",
                  Service_Sub_Type: "",
                  Service_Request_Total_Time_In_Hour: "",
                  Service_Request_Total_Time_In_Days: "",
                  Bidding_Service_Start_Date: ""
                }
              }
              validationSchema={Yup.object().shape({
                Bidding_Expiry_Date: Yup.string().required('Expiry Date of service is required.').test("date-of-bidding", "Service Date should be more than " + dos + ".", (value: any) => {
                  if (value) {
                    let date1 = new Date(value).getTime();
                    let date2 = new Date(dos).getTime();
                    if (datediff(date2, date1) > -1) {
                      return true;
                    }
                  }
                  return false;

                }),
                Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                Service_Sub_Type: Yup.string().required("Service Type is required."),

                Service_Request_Total_Time_In_Days: Yup.number().when('Service_Request_Total_Time_In_Hour', (Hour, schema) => {
                  if (Hour > 0) {
                    return schema;
                  } else {
                    return schema.min(1, "Required Days/Hours is required.").required('Required Days/Hours is required.');
                  }
                }),
                Bidding_Service_Start_Date: Yup.string().required('Start Date of service is required.').test("service start date", "start Date should be more than expiry date", (value: any) => {

                  if (value && experyDate) {
                    let date1 = new Date(value).getTime();
                    let date2 = new Date(experyDate).getTime();
                    if (date1 > date2) {
                      return true;
                    }
                  }
                  return false;
                })
              })}
              onSubmit={values => {

                setloadingForm(true);

                if (formikSubmitType == 0)
                  dispatch(createServiceBids([values], formCallbackResponse));
                else
                  dispatch(createServiceCartBids(values, formCallbackResponse));
              }}
            >
              {({ handleSubmit, setFieldValue, handleChange, handleBlur, values, errors, touched }) => (
                <View style={{
                  flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                }}>
                  {loadingForm ?

                    <ActivityIndicator size='large' style={{ justifyContent: 'center', alignItems: 'center' }} color={esiColor.esiMainColor} />
                    :
                    formResults ?
                      <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                        <Image source={require('../../../assets/images/GIF/cart-success.gif')}
                          style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                        <View style={{ width: "90%", margin: "auto", marginBottom: 40 }}>
                          <Text style={{ textAlign: "center", fontWeight: "bold" }} >
                            {formikSubmitType == 0 ? "Successfully placed your bid." : "Successfully added your item to cart."}
                          </Text>
                        </View>
                      </View>
                      :
                      <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                        <View style={{ width: "90%", margin: "auto" }}>
                          <ESITextInput
                            style={{ width: "100%" }}
                            label="Requried days*"
                            mode='outlined'
                            onChangeText={(e) => {
                              let value = e.replace(/\D/g, '')
                              setFieldValue("Service_Request_Total_Time_In_Days", value)
                            }}
                            onBlur={
                              handleBlur
                            }
                            value={values.Service_Request_Total_Time_In_Days}
                            error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                          />
                          <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}>
                            {touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                          </HelperText>
                        </View>
                        <View style={{ width: "90%", margin: "auto" }}>
                          <ESITextInput
                            style={{ width: "100%" }}
                            label="Requried Hours*"
                            mode='outlined'
                            onChangeText={(e) => {
                              let value = e.replace(/\D/g, '')
                              setFieldValue("Service_Request_Total_Time_In_Hour", value)
                            }}
                            onBlur={
                              handleBlur
                            }
                            value={values.Service_Request_Total_Time_In_Hour}
                            error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                          />
                          <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}>
                            {touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                          </HelperText>
                        </View>

                        <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                          <EsiAutocompleteDropdown

                            key={"size" + values.Service_Sub_Type}
                            loading={false}
                            data={addCartItem?.Service_Sub_Types?.split(",").map((type) => ({ id: type, title: type }))}
                            label={"Select sub type"}
                            onSelect={(item) => {
                              if (item?.id)
                                setFieldValue("Service_Sub_Type", item.id)
                            }}
                            onClear={() => {
                            }}
                            getSuggestions={(q) => {
                              // apiCall(q);
                            }}
                            selected={{ id: values.Service_Sub_Type, title: values.Service_Sub_Type }}

                          />
                          <HelperText type="error" visible={Boolean(touched.Service_Sub_Type && errors.Service_Sub_Type)}>
                            {touched.Service_Sub_Type && errors.Service_Sub_Type}
                          </HelperText>
                        </View>

                        <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                          <Text style={{ fontSize: 16, marginLeft: 5 }}>Description:</Text>
                          <EsiTextAreaInput
                            error={Boolean(touched.Description && errors.Description)}
                            containerStyle={{ height: 170 }}
                            style={{ height: 170 }}
                            mode='outlined'
                            value={values.Description}
                            textareavalueUpdate={value => {
                              setFieldValue("Description", value)
                            }}
                            placeholder={'Please provide additional information'}
                            underlineColorAndroid={'transparent'}
                            theme={{
                              colors: {
                                primary: "#27b6cc"
                              },
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Description && errors.Description)}>
                            {touched.Description && errors.Description}
                          </HelperText>
                        </View>

                        <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                          <EsiDatePicker style={{ margin: "auto" }}
                            name={'Bidding_Expiry_Date'}
                            label='Bidding Expiry Date'
                            value={values.Bidding_Expiry_Date}
                            variant=''
                            valueUpdate={item => {
                              setExperyDate(item)
                              setFieldValue("Bidding_Expiry_Date", item);
                            }}
                            error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                          />
                          <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                            {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                          </HelperText>
                        </View>

                        <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                          <EsiDatePicker style={{ margin: "auto" }}
                            name={'Bidding_Service_Start_Date'}
                            label='Bidding Service Start Date'
                            value={values.Bidding_Service_Start_Date}
                            variant='standard'
                            valueUpdate={item => {
                              setFieldValue("Bidding_Service_Start_Date", item);
                            }}
                            error={Boolean(touched.Bidding_Service_Start_Date && errors.Bidding_Service_Start_Date)}
                          />
                          <HelperText type="error" visible={Boolean(touched.Bidding_Service_Start_Date && errors.Bidding_Service_Start_Date)}>
                            {touched.Bidding_Service_Start_Date && errors.Bidding_Service_Start_Date}
                          </HelperText>
                        </View>

                        <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                          <ESITextInput
                            style={{ width: "100%" }}
                            label="Pincode*"
                            mode='outlined'
                            onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                            onBlur={
                              handleBlur
                            }
                            value={values.Pincode}
                            error={Boolean(touched.Pincode && errors.Pincode)}
                          />
                          <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                            {touched.Pincode && errors.Pincode}
                          </HelperText>
                        </View>

                        <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                          <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} uppercase={false} mode="contained" onPress={() => {
                            setFormikSubmitType(0);
                            handleSubmit()
                          }}>
                            Ask Quotation
                          </Button>
                          <Button style={{ marginTop: 15 }} mode="outlined" onPress={() => {
                            setFormikSubmitType(1);
                            handleSubmit()
                          }}>
                            <FontAwesome5 name={"cart-plus"} size={20} color={esiColor.esiMainColor} />
                          </Button>
                        </View>
                      </View>}
                </View>)}
            </Formik>
          </Surface>
        </ScrollView>
      </Modal>

    </Surface>
  )


}
