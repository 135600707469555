import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, Surface, Colors, ActivityIndicator, IconButton } from 'react-native-paper';
import { View, Dimensions, ScrollView, TouchableOpacity, Platform, FlatList, RefreshControl } from "react-native";
import Modal from "react-native-modal";
import Header from "../../screens/header";
import SignIN from "../../screens/auth/signin";
import ESICouponsCard from "../../components/custom/coupon-card";
import ESICopyCouponModel from "../../components/custom/copycode-modal";
import isEmpty from "../../state/validations/is-empty";
import { DrawerActions } from "@react-navigation/native";
import Pagination from "../../components/custom/pagination/pagination";
import { getCouponsAction } from "../../state/actions/node-actions/home-actions";
import { useToast } from "../../self_modules/react-native-paper-toast/src";


// api end
export default function AllCoupons(props) {
  const { navigation } = props;
  // api using redux
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);
  const [loading, setLoading] = React.useState(false);
  const [coupon, setcoupon] = React.useState([]);
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "COUPON_ID", SortOrder: "DESC" });
  const callBackGetData = async (status, data, pagination) => {
    setLoading(false);
    if (status) {
      setcoupon(data);
      setPageOld(true);
      if (pagination) {
        setPagination(pagination);
      } else {
        setPagination({ PageNo: 1, PageSize: 30, SortBy: "COUPON_ID", SortOrder: "DESC" });
      }
    }
  }
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  //open param is used to open the Copy Code Modal
  const [open, setOpen] = React.useState(false);
  //open param is used to assign the data to Copy Code Modal
  const [couponData, setCouponData] = React.useState('');
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

  const data = props?.route?.params;


  //displaying Signin pop up
  const signInModalResponse = () => {
    setModalVisible(!isModalVisible);
  };
  const openModal = (data) => {
    setCouponData(data)
    setOpen(true);
  };
  const toggleModal = async (data) => {
    if (isAuthenticated) {
      openModal(data)
    }
    else {
      setModalVisible(!isModalVisible);
    }
  }
  const closeModal = () => {
    setOpen(!open);
    setCouponData("")
  };


  // Condition for coupons expired before 3 days only will display at UI
  const couponLivedata = (coupons) => {
    let data = [];
    var currentdate = new Date(),
      date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
    if (coupons) {
      if (coupons[0]) {
        data = coupons.filter(coupon => {
          let currentDate = new Date(date);
          let couponExpDate = new Date(coupon.Valid_Through_Date.split("/").reverse().join("-"));
          return Math.floor((currentDate - couponExpDate) / (1000 * 60 * 60 * 24)) < 3
        })
      }
    } else {
      data = []
    }
    return data;
  }


  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  const goToTop = () => {
    scroll.scrollTo({ x: 0, y: 0, animated: true });
  }
  //Coupons filter
  const [testFill, setestFill] = React.useState({});
  let filters = useSelector((state) => state.filters);
  useEffect(() => {
    const data = props?.route?.params;
    let brands = [];
    let isTopBrand = "";
    let categoryId = '';
    let subcategory = [];
    let partners = [];
    let partnersType = '';
    let discountType = '';
    let esiCashbackType = '';

    filters.brands.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      item.children.filter(child => child.checked === 'checked').map(children => {
        brands.push(children.value);
      })
      isTopBrand = item.value === "Top_Brands" ? "1" : "0";
    })

    if (data?.type === "brand") {
      brands.push(data.id);
    }

    filters.partners.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      if (isEmpty(partnersType)) {
        partnersType = item.value
      }
      item.children.filter(child => child.checked === 'checked').map(child => {
        partners.push(child.value);
      })
    })

    if (data?.type === "partner") {
      partners.push(data.id);
    }

    filters.categories.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
      categoryId = item.value;
      item.children.filter((item => item.checked === 'checked')).map(item => {
        subcategory.push(item.value);
      })
    })
    const priceFilter = [];
    try {
      filters.price.filter(item => item.checked === 'checked')[0]?.value.split('-').map(item => priceFilter.push(item));
    } catch (error) {
    }
    const discountFilter = []
    try {
      filters.discount.filter(item => item.checked === 'checked')[0]?.value.split('-').map(item => discountFilter.push(item));
    } catch (error) {
    }

    let formData = {
      Records_Filter: "FILTER"
    }
    if (data?.cityName) {
      formData.City_Name = [data?.cityName];
    }
    if (!isEmpty(brands)) {
      formData.Brand_Id = brands
    }
    if (!isEmpty(partners)) {
      formData.Partner_Details_Id = partners
    }
    if (!isEmpty(categoryId)) {
      formData.Category_Id = categoryId
    }
    if (!isEmpty(subcategory)) {
      formData.Sub_Category_Id = subcategory
    }
    if (!isEmpty(discountFilter.length > 0 ? discountFilter[0] : '')) {
      formData.Min_Discount = discountFilter[0]
    }
    if (!isEmpty(discountFilter.length > 1 ? discountFilter[1] : '')) {
      formData.Max_Discount = discountFilter[1]
    }
    if (filters.cashback[0].checked == 'checked') {
      formData.Extra_Cashback = '1';
    }

    if (filters.main[1].children.filter((item) => item.checked === 'checked').length < 5) {
      if (filters.main[1].children.filter((item) => item.value === 'Is_Top_Coupon')[0]?.checked === 'checked') {
        formData.Is_Top_Coupon = '1';
      }
      if (filters.main[1].children.filter((item) => item.value === 'Exclusive')[0]?.checked === 'checked') {
        formData.Exclusive = '1';
      }
      if (filters.main[1].children.filter((item) => item.value === 'Verified')[0]?.checked === 'checked') {
        formData.Verified = '1';
      }
      if (filters.main[1].children.filter((item) => item.value === 'Whats_Hot')[0]?.checked === 'checked') {
        formData.Whats_Hot = '1';
      }
      if (filters.main[1].children.filter((item) => item.value === 'Is_Upcoming')[0]?.checked === 'checked') {
        formData.Is_Upcoming = '1';
      }
    }
    if (partnersType) {
      formData.Partner_Type = partnersType
    }
    if (String(isTopBrand)) {
      formData.Is_Top_Brand = String(isTopBrand);
    }
    if (discountType) {
      formData.Discount_Type = discountType;
    }
    if (esiCashbackType) {
      formData.Esi_Cashback_Type = esiCashbackType;
    }
    if (!isEmpty(discountFilter.length > 0 ? discountFilter[0] : '')) {
      formData.Min_Esi_Cashback = discountFilter[0]
    }
    if (!isEmpty(discountFilter.length > 1 ? discountFilter[1] : '')) {
      formData.Max_Esi_Cashback = discountFilter[1]
    }
    formData.Status = "Active";
    formData.condition = true;
    if (!(JSON.stringify(testFill) === JSON.stringify(formData))) {
      setestFill(formData);
      setPageOld(true);
      dispatch(getCouponsAction(formData, pagination, callBackGetData));
    }
  }, [filters]);

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      if (!isEmpty(testFill)) {
        setLoading(true);
        goToTop();
        dispatch(getCouponsAction(testFill, pagination, callBackGetData));
      }
    }
  }, [pagination])


  const checkChildChecked = (data: any) => {
    let count = 0;
    data?.map((item: any) => {
      if (item.checked === "checked") {
        count++
      }
    })
    return (count === data?.length && data?.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
  }


  // Tost message code.
  const toast = useToast();

  // Tost message code.
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>

      <Header {...props} isFilter={props.isFilter} />
      <View>

        <View style={{ flexDirection: 'row' }}>
          {navigation.canGoBack() &&
            <IconButton
              icon="arrow-left"
              size={27}
              color={"#27B6CC"}
              style={{ marginLeft: 1 }}
              onPress={() => {
                props.navigation.goBack()
              }} />
          }
        </View>
        <IconButton
          color={'#27b6cc'}
          icon="filter"
          size={24} style={{ marginTop: -45, alignSelf: 'flex-end' }}
          onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        />
        {/* </TouchableOpacity> */}

      </View>
      <ScrollView ref={(c) => { scroll = c }}
        refreshControl={
          <RefreshControl
            refreshing={loading}
          />
        }
      >
        {/* ActivityIndicator is user for reloading the page */}

        <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          {
            isEmpty(couponLivedata(coupon)) ? (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                <Text style={{color: esiColor.NoDataFound}}>No Coupons found!</Text>
              </View>
            ) : (
              <FlatList
                // horizontal={true}
                key={dimensions.width}
                columnWrapperStyle={{ justifyContent: "space-around" }}
                numColumns={(dimensions.width / 250).toFixed() == 1 ? 2 : (dimensions.width / 250).toFixed()}
                data={coupon}
                renderItem={({ item, index, separators }) => (
                  <ESICouponsCard data={item} toggleModal={toggleModal} />)}
              />
            )}
        </View>
        <View style={{ marginBottom: 5 }}>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>

      </ScrollView>
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={isModalVisible}>
        <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10,  backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
          <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
        </View>
      </Modal>
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={open}>
        <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 350, maxHeight: 400, borderRadius: 10,  backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7  }}>
          <ESICopyCouponModel navigation={navigation} openModal={openModal} data={couponData} closeModal={closeModal} />
        </View >
      </Modal>
    </Surface >
  );
}