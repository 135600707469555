import axios from 'axios';
import config from './config';
import { Dispatch } from "redux"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { addStatistics } from './dashboardActions';
import { Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';
import { boomiApi, nodeCudApi, nodeGetApi as meeapi } from './commonAxios';
import CryptoJS from 'crypto-js';

export enum ActionType {
    USER_SIGNIN = "USER_SIGNIN",
    USER_REQUEST = "USER_REQUEST",
    USER_SUCCESS = "USER_SUCCESS",
    USER_FAIL = "USER_FAIL",
    USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST",
    USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS",
    USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL",
    USER_LOGOUT = "USER_LOGOUT",
    USER_SESSION_REFRESH_DATE = "USER_SESSION_REFRESH_DATE"
}

export const getDataAsync = async () => {
    try {
        const jsonValue = await AsyncStorage.getItem('user');
        return jsonValue != null ? jsonValue : {};
    } catch (e) {
        // error reading value
    }
}

const removeValue = async () => {
    try {
        await AsyncStorage.removeItem('user')
    } catch (e) {
    }

}
export const getData = () => {
    return (dispatch: Dispatch) => {
        getDataAsync().then(value => {
            dispatch({
                type: ActionType.USER_SIGNIN,
                data: value
            })
        });
    }

}


export const loginCheckNew = (input: any, signinResponse: any) => {
    return function (dispatch: any) {
        dispatch(loginCheckRequest());
        // axios.post(`${config.url}/User_Signup_Flow_New/User_Signin`, input, {
        //     headers: config.headersCommon
        // })
        nodeCudApi.post(`/User_Signup_Flow_New/User_Signin`, input)
            .then(async response => {
                let data = response.data;
                if (data.Tokens) {
                    if (Platform.OS == 'web') {
                        const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(data.Tokens), config.cryptoKay).toString();
                        localStorage.setItem('Token', encryptedToken);
                    } else {
                        await SecureStore.setItemAsync('Token', JSON.stringify(data.Tokens));
                        dispatch({
                            type: ActionType.USER_SESSION_REFRESH_DATE,
                            expireDate: new Date().setSeconds(new Date().getSeconds() + (data.Tokens.exp - (5 * 60))),
                            expireTime: data.Tokens.exp - (5 * 60)
                        })
                    }
                }
                if (data.Response_Status == "Success") {
                    dispatch(getLogedInUser(data.User_EmailID, true, signinResponse, data.User_Session_Id, input));
                }
                else {
                    signinResponse(false, data.UI_Display_Message, data?.Active ? "activate" : "");
                    dispatch(loginCheckFail(data.UI_Display_Message, true));
                }
            })
            .catch(error => {
                console.log(error);
                signinResponse(false, "Currently server is not working. Please try again later.", "");
                dispatch(loginCheckFail("Currently server is not working. Please try again later.", true));
            })
    }
}

export const authTokenGenerate = (input: any, signinResponse: any) => {
    return function (dispatch: any) {
        nodeCudApi.post(`/User_Signup_Flow_New/access-token`, input)
            .then(response => {
                signinResponse(response.data);
            })
            .catch(error => {
                if (error.response)
                    signinResponse(error.response.data || {});
                else {
                    signinResponse({})
                }
            })
    }
}


export const getLogedInUser = (email: any, isLogin: any, signinResponse: any, User_Session_Id: any, input: any = {}) => {
    return function (dispatch: any) {
        // axios.post(`${config.url}/User/Get`, {
        //     "Records_Filter": "FILTER",
        //     "Email_Id": email,
        //     "Mobile_Number": ""
        // }, {
        //     headers: config.headersCommon
        // })
        nodeCudApi.post(`/User/Get`, {
            "Records_Filter": "FILTER",
            "Email_Id": email,
            "Mobile_Number": ""
        })
            .then(response => {
                let successResponse = response.data.Success_Response ? response.data.Success_Response : response.data;
                let user = response.data.Success_Response ? JSON.parse(JSON.stringify(response.data.User_Signup[0])) : false;
                if (successResponse.Response_Status == "Success" && user) {
                    user.User_Session_Id = User_Session_Id;
                    if (isLogin) {
                        signinResponse(true);
                        AsyncStorage.getItem('ExpoPushToken').then((value) => {
                            let payload = {
                                "name": user.User_Name,
                                "email": user.Email_Id,
                                "notificationToken": value
                            };
                            axios.post(`https://pushnotificationsapi.meepaisa.com/expo-notifications/api/v1/users`, payload)
                                .then(response => {
                                    let data = response.data;
                                })
                                .catch(error => {
                                })
                        });
                        let formdata = {
                            "Component": "signin",
                            "Email_Id": user?.Email_Id,
                            "Event_Context": "Signin",
                            "Event_Name": "Signin",
                            "Ip_Address": input?.IP_Address,
                            "Mobile_Number": user?.Mobile,
                            "User_Full_Name": user?.User_Name,
                            "Origin": Platform.OS
                        };
                        dispatch(addStatistics(formdata));
                    }
                    dispatch(loginCheckSuccess(user, isLogin));

                } else {
                    if (isLogin) {
                        signinResponse(false, successResponse.UI_Display_Message);
                        dispatch(loginCheckFail(successResponse.UI_Display_Message, isLogin));
                    }
                }
            })
            .catch(error => {
                console.log(error);
                if (isLogin) {
                    signinResponse(false, "Currently server is not working. Please try again later.");
                    dispatch(loginCheckFail("Currently server is not working. Please try again later.", isLogin));
                }
            })
    }
}


export const signUpNew = (input: any, signupResponse: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ActionType.USER_SIGNUP_REQUEST
        });
        // axios.post(`${config.url}/User_Signup_Flow_New/User_Signup`, input, {
        //     headers: config.headersCommon
        // })
        nodeCudApi.post(`/User_Signup_Flow_New/User_Signup`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    signupResponse(true, '', response.data);
                }
                else {
                    signupResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                signupResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const signUpEmailExist = (input: any, emailCheckResponse: any) => {
    return function (dispatch: any) {
        // axios.post(`${config.url}/User_Signup_Flow_New/Email_Check`, { Email_ID: input }, {
        //     headers: config.headersCommon
        // })
        nodeCudApi.post(`/User_Signup_Flow_New/Email_Check`, { Email_ID: input })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    emailCheckResponse(true, '', response.data, input);
                }
                else {
                    emailCheckResponse(false, data.UI_Display_Message, data, input);
                }
            })
            .catch(error => {
                // emailCheckResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const signUpMobileExist = (input: any, mobileCheckResponse: any) => {
    return function (dispatch: any) {
        // axios.post(`${config.url}/User_Signup_Flow_New/Mobile_OTP_Check`, { Mobile: "+91 " + input }, {
        //     headers: config.headersCommon
        // })
        nodeCudApi.post(`/User_Signup_Flow_New/Mobile_OTP_Check`, { Mobile: "+91 " + input })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    mobileCheckResponse(true, '', response.data, input);
                }
                else {
                    mobileCheckResponse(false, data.UI_Display_Message, data, input);
                }
            })
            .catch(error => {
                // emailCheckResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const signInMobileExist = (input: any, mobileCheckResponse: any) => {
    return function (dispatch: any) {
        // axios.post(`${config.url}/User_Signup_Flow_New/Login_Mobile_OTP`, { Mobile: "+91 " + input }, {
        //     headers: config.headersCommon
        // })
        nodeCudApi.post(`/User_Signup_Flow_New/Login_Mobile_OTP`, { Mobile: "+91 " + input })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    mobileCheckResponse(true, '', response.data, input);
                }
                else {
                    mobileCheckResponse(false, data.UI_Display_Message, data, input);
                }
            })
            .catch(error => {
                // emailCheckResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const setPasswordAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        boomiApi.post(`/User_Signup/Password_Creation`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, 'Your password has been updated successfully.', response.data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}
// signup password set
export const setPasswordSignUpAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        boomiApi.post(`/User_Signup/Pasword_Creation_New`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, 'Your password has been updated successfully.', response.data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const tokenVarivicationAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        boomiApi.post(`/User_Signup/Check_Password_URL`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, '', response.data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}
// sign up get otp API
export const tokenVarivicationSignUpAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        boomiApi.post(`/User_Signup/Password_URL_New`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, '', response.data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const tokenActivateAccountAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        boomiApi.post(`/User/User_Status_Active`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, '', response.data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const accountActiveActions = (input: any, responseFunction: any) => {
    return function (dispatch: any) {
        boomiApi.post(`/User/User_Active_URL_Generate`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    responseFunction(true, '');
                }
                else {
                    responseFunction(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                responseFunction(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const forgotCheck = (input: any, forgotResponse: any) => {
    return function (dispatch: any) {
        boomiApi.post(`/User_Signup/Forget_Password`, input)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    forgotResponse(true, '', data.OTP, data.Mobile, data.Is_Email_OTP);
                }
                else {
                    forgotResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                forgotResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const forgot = (input: any, forgotResponse: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ActionType.USER_SIGNUP_REQUEST
        });
        let payload = {
            "Email_Id": input.email,
            "Password": input.password
        };
        boomiApi.post(`${config.url}/User/Reset_Password`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    forgotResponse(true, '');
                }
                else {
                    forgotResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                forgotResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}


export const loginOut = (navigate: any, user: any, setLogoutDisabile: any) => {
    return function (dispatch: any) {
        AsyncStorage.getItem('ExpoPushToken').then((value) => {
            let payload = {
                "User_Session_Id": user.User_Session_Id,
                "User_Email_Id": user.Email_Id,
                "Device_Token": value
            };
            nodeCudApi.post(`/User_Signup_Flow_New/Log_Out`, payload)
                .then(async response => {
                    let data = response.data;
                    if (data.Response_Status == "Success") {
                        dispatch(loginOutSuccess());
                        setLogoutDisabile(true);
                        if (Platform.OS == "web") {
                            localStorage.removeItem('signIn');
                            localStorage.removeItem('Token');
                        } else {
                            await SecureStore.deleteItemAsync('Token');
                        }
                    }
                    else {
                        setLogoutDisabile(false, data.UI_Display_Message);
                    }
                })
                .catch(error => {
                    setLogoutDisabile(false, "Please try again later");
                })
        });

    }
}

// Boomi API
// export const sessionCheckAction = (user: any) => {
//     return function (dispatch: any) {
//         let payload = {
//             "User_Session_Id": user.User_Session_Id
//         };
//         axios.post(`${config.url}/User_Session/Get`, payload, {
//             headers: config.headersCommon
//         })
//             .then(response => {
//                 let data = response.data;
//                 if (data?.Success_Response?.Response_Status == "Success") {
//                     if (!(data?.User_Session[0].Is_Active == "1")) {
//                         removeValue().then((data) => {
//                             getDataAsync().then((data) => {
//                                 dispatch(loginOutSuccess());
//                             })
//                         });
//                     }
//                 }
//                 else {

//                 }
//             })
//             .catch(error => {
//             })
//     }
// }

// NodeAPI

export const sessionCheckAction = (user) => async (dispatch: any) => {
    meeapi.post('/Home/User_Session', { "Records_Filter": "FILTER", "User_Session_Id": user.User_Session_Id }
    ).then(async response => {
        let data = response.data;
        if (data?.Response_Status == "Failed") {
            if (Platform.OS == "web") {
                localStorage.removeItem('signIn');
                localStorage.removeItem('Token');
            } else {
                await SecureStore.deleteItemAsync('Token');
            }
            dispatch(loginOutSuccess());
        } else if (data?.Response_Status == "Success") {

        }
        else {
            if (!(data.Is_Active == 1)) {
                if (Platform.OS == "web") {
                    localStorage.removeItem('signIn');
                    localStorage.removeItem('Token');
                }
                dispatch(loginOutSuccess());
                removeValue().then((data) => {
                    getDataAsync().then((data) => {
                        dispatch(loginOutSuccess());
                    })
                });
            }
        }
    }).catch(error => {
        // if (error.message != "Network Error") {
        //     if (Platform.OS == "web") {
        //         localStorage.removeItem('signIn');
        //     }
        //     dispatch(loginOutSuccess());
        // }
    });
};

export const deleteAccountAction = (navigate: any, user: any, setLogoutDisabile: any) => {
    return function (dispatch: any) {
        let payload = {
            "Email_Id": user.Email_Id
        };
        boomiApi.post(`/User/Account_Active_InActive`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    dispatch(loginOutSuccess());
                    if (Platform.OS == "web") {
                        localStorage.removeItem('signIn');
                        localStorage.removeItem('Token');

                    }
                    // removeValue().then((data) => {
                    //     getDataAsync().then((data) => {
                    //         navigate.navigate("Home");
                    //         setLogoutDisabile(true);
                    //         dispatch(loginOutSuccess());
                    //     })
                    // });
                }
                else {
                    setLogoutDisabile(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                setLogoutDisabile(false, "Please try again later.");
            })



    }
}

export const loginCheckRequest = () => {
    return {
        type: ActionType.USER_REQUEST
    }
}
export const loginCheckSuccess = (user: any, isLogin: any) => {
    return {
        type: ActionType.USER_SUCCESS,
        data: user,
        login: isLogin
    }
}
export const loginCheckFail = (error: any, isLogin: any) => {
    return {
        type: ActionType.USER_FAIL,
        data: error,
        login: isLogin

    }
}
export const loginOutSuccess = () => {

    return {
        type: ActionType.USER_LOGOUT
    }
}