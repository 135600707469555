import * as React from 'react';
import { Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Card } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import Swiper from 'react-native-web-swiper';
import { useDispatch, useSelector } from 'react-redux';
import { eventsFilter, getEventsAction, updateEventsAction } from '../../state/actions/eventsActions';
import isEmpty from '../../state/validations/is-empty';
import Header from '../header';
import razorpayPay from '../../hooks/razorpay';
import useRazorpay from 'react-razorpay';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import { Ionicons } from '@expo/vector-icons';



export default function AllEvents(props) {
    const dispatch = useDispatch();
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }
    const toast = useToast();
    let halls = useSelector((state) => state.events.allevents.all);
    let eventMenu = useSelector(state => state.events.eventsfilter.select);
    let eventSearch = useSelector(state => state.events.eventsfilter.search);
    const user = useSelector((state) => state.auth.user);

    const [showDropDown, setShowDropDown] = React.useState(false);

    const filterOptions = [
        {
            value: '',
            label: 'All'
        },
        {
            value: 'NEW',
            label: 'New'
        },
        {
            value: 'PROCESSING',
            label: 'Live'
        },
        {
            value: 'UPCOMING',
            label: 'UpComing'
        },
        {
            value: 'COMPLETED',
            label: 'Completed'
        },
        {
            value: 'SETTLED',
            label: 'Settled'
        },
        {
            value: 'CANCELLED',
            label: 'Cancelled'
        },


    ];
    React.useEffect(() => {
        if (isEmpty(halls)) {
            let formData = {
                Records_Filter: "FILTER",
                User_Email_Id: user.Email_Id,
            }
            dispatch(getEventsAction(formData));
            dispatch(eventsFilter('search', ''));
            dispatch(eventsFilter('select', 'Event_Status'));
        }
    }, [halls]);

    React.useEffect(() => {
        if (isEmpty(user.Email_Id)) {
            setTimeout(() => {
                if (isEmpty(user.Email_Id)) {
                    props.navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [user.Email_Id])

    const handleSearch = value => {
        dispatch(eventsFilter('search', value));
    };
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimentions = dimensions / 1.06;
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    const payNow = (hall: any) => {
        let amount = (Math.round(Number(hall?.Price_Need_to_Pay) + Number.EPSILON));
        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    let formData = {
                        AC_Electricty_Price_Per_Hour: hall?.AC_Electricity_Price_Per_Hour,
                        Any_Extra_Charges: hall?.Any_Extra_Charges,
                        Booking_Status: hall?.Booking_Status,
                        Cashback_Amount: hall?.Cashback_Amount,
                        Cashback_Settled_To_User: hall?.Cashback_Settled_To_User,
                        Discount_Amount: hall?.Discount_Amount,
                        Discount_Percentage: hall?.Discount_Percentage,
                        Electricity_Price_Per_Hour: hall?.Electricity_Price_Per_Hour,
                        Event_Booking_Requested_Date: hall?.Event_Booking_Requested_Date,
                        Event_End_Date: hall?.Event_End_Date,
                        Event_Start_Date: hall?.Event_Start_Date,
                        Event_Status: hall?.Event_Status,
                        Event_Total_Members: hall?.Event_Total_Members,
                        Food_NonVeg_Price_Per_Plate: hall?.Food_NonVeg_Price_Per_Plate,
                        Food_Veg_Price_Per_Plate: hall?.Food_Veg_Price_Per_Plate,
                        Function_Hall_Id: hall?.Function_Hall_Id,
                        Hall_Decoration_Price: hall?.Hall_Decoration_Price,
                        IS_Event_Has_Legal_Permission: hall?.IS_Event_Has_Legal_Permission,
                        IS_Event_Required_Alchol: hall?.IS_Event_Required_Alchol,
                        Is_Food_Required: hall?.Is_Food_Required,
                        Is_Hall_Decoration_Required: hall?.Is_Hall_Decoration_Required,
                        Is_Rooms_Required: hall?.Is_Rooms_Required,
                        Meepaisa_Commission: hall?.Meepaisa_Commission,
                        Modified_By: user.Email_Id,
                        No_Of_AC_Rooms: hall?.No_Of_AC_Rooms,
                        No_Of_NonAC_Rooms: hall?.No_Of_NonAC_Rooms,
                        No_Of_NonVeg_Plates: hall?.No_Of_NonVeg_Plates,
                        No_Of_Veg_Plates: hall?.No_Of_Veg_Plates,
                        Partner_Total_Share: hall?.Partner_Total_Share,
                        Payment_Gateway_Amount: amount,
                        Payment_Method: "razorpay",
                        Payment_Reference_ID: response.razorpay_payment_id,
                        Payment_Status: "Success",
                        Price_Per_Day: hall?.Price_Per_Day,
                        Price_Per_Hour: hall?.Price_Per_Hour,
                        Room_Cost_Per_Night: hall?.Room_Cost_Per_Night,
                        Status: hall?.Status,
                        User_Email_ID: user.Email_Id,
                        User_Event_ID: hall?.User_Event_ID,
                        Vent_Date: hall?.Event_Date
                    }
                    let values = JSON.parse(JSON.stringify(formData));
                    dispatch(updateEventsAction(values, props.navigation.navigate, user.Email_Id, toast))
                } else {

                }
            });
        } else {

        }
    }

    const checkPartnerForTable = () => {

        if (eventSearch) {
            try {
                if (eventMenu === "Event_Status") {
                    return halls.filter(x => {
                        // let keys = Object.keys(x);
                        let colummns = ["Event_Status"];
                        for (let i = 0; i < colummns.length; i++) {
                            const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
                            const subString = typeof x[colummns[i]] === "string" ? eventSearch.toLowerCase() : eventSearch;
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(eventSearch.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString == eventSearch) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });

                } else {
                    return halls.filter(x => {
                        let mainString = typeof x[eventMenu] == "string" ? x[eventMenu].toLowerCase() : x[eventMenu];
                        let subString = typeof x[eventMenu] == "string" ? eventSearch.toLowerCase() : eventSearch;
                        if (typeof x[eventMenu] === "string") {
                            return mainString.includes(subString);
                        }
                        else {
                            if (mainString == eventSearch) {
                                return true;
                            }
                        }
                    })
                }
            } finally {
            }
        }
        else {
            return halls
        }
    }

    return (
        <View style={styles.container}>
            <Header {...props} />
            <View style={{ flexDirection: "column", flexWrap: "wrap", alignItems: 'center', alignContent: 'center' }}>
                <Text style={{ fontSize: 24, marginTop: 5, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>Booking History</Text>
            </View>
            <View style={{
                flexDirection: "row", paddingTop: 5, justifyContent: "space-between"
            }}>
                <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => { props.navigation.navigate("AllHalls") }}>
                        <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
                    </TouchableOpacity>
                <View style={{ width: 170 }} >
                    <EsiSearchDropdown
                        style={[styles.dropdown]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={filterOptions}
                        containerStyle={styles.containerstyle}
                        // search
                        maxHeight={180}
                        labelField="label"
                        valueField="value"
                        placeholder={!showDropDown ? 'All' : 'All'}
                        searchPlaceholder="Search..."
                        value={eventSearch}
                        onFocus={() => setShowDropDown(true)}
                        onBlur={() => setShowDropDown(false)}
                        onUpdateValue={item => {
                            setShowDropDown(false);
                            handleSearch(item.value)
                        }}
                    />
                </View>
            </View>
            <View ></View>
            <ScrollView>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto', }}>
                    {!isEmpty(halls) ? (
                        (halls.Is_Data_Exist === "0"||checkPartnerForTable().length<=0 )? (
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                                <Text> No Halls found. </Text>
                            </View>
                        ) : (
                            checkPartnerForTable().map((item: any, index: any) => (
                                <React.Fragment key={index}>
                                    <View style={{ maxWidth: Platform.OS === "web" ? 340 : (halfdimentions), width: Platform.OS === 'web' ? dimensions <= 1500 ? 770 : "90%" : "85%" && (halfdimentions) }}>
                                        <Card >
                                            <Card.Title style={{ color: "#27b6cc" }}>{item?.Function_Hall_Name?.toUpperCase()}</Card.Title>
                                            <Card.Divider />
                                            <View style={{ height: 200 }}>
                                                <Swiper
                                                    showsButtons={false} horizontal={true}
                                                    timeout={5}
                                                    springConfig={{ speed: 11 }}
                                                    minDistanceForAction={0.15}
                                                    loop={true}
                                                    controlsEnabled={false}
                                                >
                                                    {item?.Event_Document?.map((data: any, index: any) => (
                                                        <Image
                                                            key={index}
                                                            style={styles.tinyLogo}
                                                            resizeMode="cover"
                                                            source={{uri:data.Document_URL}}
                                                        />
                                                    ))}
                                                </Swiper>
                                            </View>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                    Paid Amount :
                                                </Text>
                                                <Text style={{ color: "blue", fontWeight: "bold" }}>
                                                    {item.Advance_Paid}/-
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                    Discount Amount :
                                                </Text>
                                                <Text style={{ color: "green", fontWeight: "bold" }}>
                                                    {item.Discount_Amount}/-
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                   Balance Amount:
                                                </Text>
                                                <View>
                                                    {item?.Price_Need_to_Pay > 0 ?
                                                        <View style={{flexDirection:"row"}}>
                                                            <TouchableOpacity onPress={() => { payNow(20) }}>
                                                                <Text style={{ color: "blue", fontWeight: "bold" }}>
                                                                    (pay...?)
                                                                </Text>
                                                            </TouchableOpacity>
                                                            <Text style={{ color: "red", fontWeight: "bold", }}>
                                                                {item?.Price_Need_to_Pay}/-
                                                            </Text>
                                                        </View>
                                                        :
                                                        <Text style={{ color: "red", fontWeight: "bold", }}>
                                                            0.00/-
                                                        </Text>
                                                    }

                                                </View>

                                            </View>

                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                    Cashback Amount :
                                                </Text>
                                                <Text style={{ color: "green", fontWeight: "bold", }}>
                                                    {item.Cashback_Amount}/-
                                                </Text>
                                            </View>

                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                    Event Status:
                                                </Text>
                                                <Text style={{ color: "#27b6cc" }}>
                                                    {item.Event_Status}
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                    Booking Status:
                                                </Text>
                                                <Text style={{ color: "#27b6cc" }}>
                                                    {item.Booking_Status}
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                    Event Start Date:
                                                </Text>
                                                <Text style={{ color: "#27b6cc" }}>
                                                    {item.Event_Start_Date}
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Text>
                                                    Event End Date:
                                                </Text>
                                                <Text style={{ color: "#27b6cc" }}>
                                                    {item.Event_End_Date}
                                                </Text>
                                            </View>
                                            {(item?.Event_Status === "NEW" || item?.Event_Status === "UPCOMING") ?
                                                <View style={{ flexDirection: "row", margin: 5, flexWrap: "wrap", flex: 1, justifyContent: "space-between" }}>
                                                    <TouchableOpacity
                                                        onPress={() => { props.navigation.navigate("EditEventDetails", { data: item.User_Event_ID }) }}
                                                    >
                                                        <Text style={{ color: "blue" }}> Update</Text>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        onPress={() => { props.navigation.navigate("Ticket") }}
                                                    >
                                                        <Text style={{ color: "red" }}>Cancel </Text>
                                                    </TouchableOpacity>
                                                </View>
                                                : null}
                                        </Card>
                                    </View>
                                </React.Fragment>
                            ))
                        )
                    ) : (<></>
                    )}
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    tinyLogo: {
        width: 500,
        height: 200,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    containerstyle: {
        maxWidth: 200,
    },
    dropdown: {
        height: 30,
        maxWidth: 150,
        borderColor: '#27b6cc',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
});
