import React, { Fragment, useEffect, useState } from "react";
import { Text, View } from "react-native";
import ESIDealsCard from "../../components/custom/deal-card";
import { useSelector } from "react-redux";
export default function DealsF({ dealfilter,toggleDModal,navigation}) {

    const esiColor = useSelector(state => state.theme);


    return (
        dealfilter.length > 0 ?
            (dealfilter?.map((data, index) => (
                <Fragment key={index}>
                    <ESIDealsCard data={data} navigation={navigation} toggleModal={toggleDModal} />
                </Fragment>
            ))
            )
            : (
                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                    <Text style={{color: esiColor.NoDataFound}}>No Deals found!</Text>
                </View>
            )
    );
}
