import React, { useState, useRef } from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  Animated,
  Easing,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
const Accordian = (props) => {
  const [open, setOpen] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(50);
  const esiColor = useSelector(state => state.theme);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });
  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });
  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false
      }).start();
    }
    setOpen(!open);
  };

  const styles = StyleSheet.create({
    bodyBackground: {
      backgroundColor: esiColor.BackgroundColor,
      overflow: 'hidden',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: '#EFEFEF',
      flex: 1
    },
    bodyContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%'
    },
    item: {
      margin: 16,
    },
    button: {
      opacity: 0.6,
    },
    badge: {
      position: 'absolute',
      top: 4,
      right: 0,
    },
  });
  return (
    <>
      <TouchableWithoutFeedback key={props?.data.Feq_Asked_Quest_Id} onPress={() => toggleListItem()}>
        <View style={styles.titleContainer}>
          <View style={{
            display: "flex",
            flexDirection: "row",
            flex:1
          }}>
            <Text style={{ marginTop: 20, marginLeft: 5, fontWeight: 'bold', marginBottom: 20,color:'#026473' }}>{props?.data?.Question}</Text></View>
          <View style={{
            display: "flex",
            flexDirection: "row",
          }}>
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }], }}>
              <MaterialIcons name="keyboard-arrow-down" style={{ fontWeight: 'bold' }} size={20} color="black" />
            </Animated.View>
          </View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          style={styles.bodyContainer}
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }>
          {props.children && props.children}
        </View>
      </Animated.View>
    </>
  );
};
export default Accordian;

