import React, { useState } from "react"
import { View, Text, Image, StyleSheet, Platform, Dimensions, ActivityIndicator, TouchableHighlight, ScrollView } from "react-native";
import getEnvVars from "../../../../../environment";
import icons from "../../../../constants/icons";
import isEmpty from "../../../../state/validations/is-empty";
import Map from "./web-plugin/map";
import noVehiclesFound from "../../../../assets/images/no-vehicle.png";
import { useSelector } from "react-redux";


const { GOOGLE_MAP_API_KEY, GOOGLE_MAP_WEB_API_KEY } = getEnvVars();

const TrackingMap = (props) => {
    const { origin, destination, isRide = false, setDistance = (value) => { }, price, confirmAction = (data) => { }, navigation,
        vehicalsLoading = false,
        vehicals = [],
        isBackRequired = true,
        driverFlag = false,
    } = props;
    const esiColor = useSelector(state => state.theme);
    // const { origin, destination } = {origin:{ latitude: 12.9559746, longitude: 77.6950884 },destination:{ latitude: 13.004625, longitude: 77.683904 }};
    const { width, height } = Dimensions.get("window");
    const [distance, setDistanceL] = React.useState('');
    const GOOGLE_MAP_API_KEY = GOOGLE_MAP_WEB_API_KEY;
    const [places, setPlaces] = useState([{}, {}]);
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    React.useEffect(() => {
        if ((JSON.stringify(places[0]) != JSON.stringify(origin)) || (JSON.stringify(places[1]) != JSON.stringify(destination))) {
            setPlaces([origin, destination]);
            setSeconds(2);
        }
    }, [origin, destination]);


    return (
        !isEmpty(places) ?
            <>
                {seconds <= 0 ?
                    <Map
                        googleMapURL={
                            'https://maps.googleapis.com/maps/api/js?key=' +
                            GOOGLE_MAP_API_KEY +
                            '&libraries=geometry,drawing,places'
                        }
                        markers={places}
                        loadingElement={<div
                            style={{ height: `100%` }}
                        />}
                        containerElement={<div style={{ height: '100%' }} />}
                        mapElement={<div
                            style={{ height: `100%` }}
                        />}
                        defaultCenter={{ lat: 25.798939, lng: -80.291409 }}
                        defaultZoom={11}
                        setDistance={(value) => { setDistance(value); setDistanceL(value); }}
                        isRide={isRide}
                        driverFlag={driverFlag}
                    />
                    : <ActivityIndicator color='#27b6cc' />
                }
                {
                    (seconds <= 0 && isRide && distance) &&

                    <ScrollView style={{ minHeight: 150, maxHeight: 200, paddingHorizontal: 30, overflowY: "scroll", zIndex: 99, backgroundColor: esiColor.BackgroundColor }}  >
                        {
                            (vehicals.length <= 0) ? (!vehicalsLoading ?
                                <View style={{ justifyContent: "center" }} >
                                    <ActivityIndicator color="#27b6cc" />
                                </View>
                                :
                                <View style={{ justifyContent: "center" }} >
                                    <Image style={{ resizeMode: 'contain', height: 200 }} source={noVehiclesFound} />
                                </View>) :
                                vehicals.map((data, index) => {
                                    return (
                                        <TouchableHighlight key={"key" + index} underlayColor="#27b6cc"
                                            onPress={() => {
                                                confirmAction(data);
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    width: "100%",
                                                    borderBottomColor: "gray",
                                                    borderBottomWidth: 1,
                                                    paddingVertical: 5,
                                                }}
                                            >
                                                <View style={{
                                                    flex: 2
                                                }} >
                                                    <Image
                                                        source={{
                                                            uri: data.Comfort_Type_Image
                                                        }}
                                                        style={{
                                                            width: 50,
                                                            height: 30
                                                        }}
                                                    />
                                                </View>
                                                <View
                                                    style={{
                                                        flex: 8,
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <View style={{ justifyContent: "center", flexDirection: "row" }} >
                                                        <Text style={{ color: esiColor.DescColor, fontFamily: "Poppins-Regular", fontSize: 14, lineHeight: 22 }}>{data?.Vehicle_Type + " " + data?.Vehicle_Comfort_Type}</Text>
                                                    </View>
                                                    <View style={{
                                                        flexDirection: "row",
                                                        justifyContent: "space-between"
                                                    }}>
                                                        <Text style={{ color: esiColor.DescColor }} > Seats : {data?.Vehicle_Seating_Capacity ? data?.Vehicle_Seating_Capacity : ""} </Text>
                                                        <Text style={{ color: esiColor.DescColor }} >{data?.Total_Fare_Before_Discount && <Text style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }} >₹{data?.Total_Fare_Before_Discount}</Text>} {data?.Total_Fare_Amount ? "₹" + data?.Total_Fare_Amount : ""} </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </TouchableHighlight>
                                    )
                                })}

                    </ScrollView>

                }
            </>
            :
            <></>
    )
}

export default TrackingMap