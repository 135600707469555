import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ScrollView, StyleSheet, TouchableOpacity, View, Image, Dimensions } from "react-native";
import { Surface, Text } from "react-native-paper";
import ESICouponsCard from "../../components/custom/coupon-card";

import Modal from "react-native-modal";
import SignIN from "../auth/signin";
import ESICopyCouponModel from "../../components/custom/copycode-modal";
import ESIDealModel from "../../components/custom/deal-modal";
import DealsF from "./dealFilter";
import ProductsF from "./productFilter";
import Header from "../header";
import { getBrandsAction, getCouponsAction, getDealsAction, getPartnersAction, getProductsAction } from "../../state/actions/node-actions/home-actions";

export default function AllCombinations(props) {
    const { navigation } = props;
    const searchKeyValue = props?.route?.params.searchValue;

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const esiColor = useSelector(state => state.theme);

    const window = Dimensions.get("window");
    //open param is used to open the Copy Code Modal
    const [open, setOpen] = React.useState(false);
    //open param is used to assign the data to Copy Code Modal
    const [couponData, setCouponData] = React.useState('');
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [Dopen, setDOpen] = React.useState(false);
    const [dealData, setDealData] = React.useState('');

    const signInModalResponse = (status) => {
        setModalVisible(!isModalVisible);
    };
    const openModal = (data) => {
        setCouponData(data)
        setOpen(true);
    };
    const toggleModal = async (data) => {
        if (isAuthenticated) {
            openModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeModal = () => {
        setOpen(!open);
        setCouponData("")
    };
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
    });

    const openDModal = (data) => {
        setDealData(data)
        setDOpen(true);
    };
    const toggleDModal = async (data) => {
        if (isAuthenticated) {
            openDModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeDModal = () => {
        setDOpen(!Dopen);
        setDealData("")
    };

    const dispatch = useDispatch();

    const [coupon, setCoupon] = useState([]);
    const callBackGetCouponData = (status, data) => {
        if (status) {
            setCoupon(data);
        }
    }

    const [deal, setDeal] = useState([]);
    const callBackGetDealData = (status, data) => {
        if (status) {
            setDeal(data);
        }
    }

    const [allproducts, setAllproducts] = useState([]);
    const callBackGetProductsData = (status, data) => {
        if (status) {
            setAllproducts(data);
        }
    }

    const [brands, setBrands] = useState([]);
    const callBackGetBrandsData = (status, data) => {
        if (status) {
            setBrands(data);
        }
    }

    const [partners, setPartners] = useState([]);
    const callBackGetPartnersData = (status, data) => {
        if (status) {
            setPartners(data);
        }
    }

    useEffect(() => {
        let formData = {
            Records_Filter: "FILTER",
            Search: searchKeyValue
        }
        dispatch(getCouponsAction(formData, { PageNo: 1, PageSize: 50, SortBy: "COUPON_ID", SortOrder: "DESC" }, callBackGetCouponData));
        let formDataD = {
            Records_Filter: "FILTER",
            Search: searchKeyValue
        }
        dispatch(getDealsAction(formDataD, { PageNo: 1, PageSize: 50, SortBy: "DEAL_ID", SortOrder: "DESC" }, callBackGetDealData));

        dispatch(getProductsAction({
            Records_Filter: "FILTER",
            Search: searchKeyValue
        }, { PageNo: 1, PageSize: 50, SortBy: "PRODUCT_NAME", SortOrder: "DESC" }, callBackGetProductsData));

        dispatch(getBrandsAction({
            "Records_Filter": "FILTER",
            Search_Brand_Name: searchKeyValue
        },
            { PageNo: 1, PageSize: 10, SortBy: "BRAND_ID", SortOrder: "DESC" },
            callBackGetBrandsData
        ));

        dispatch(getPartnersAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            Search_Partner_Name: searchKeyValue
        },
            { PageNo: 1, PageSize: 10, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" }
            , callBackGetPartnersData));

    }, [searchKeyValue]);

    const handlePartnerClick = async (data) => {
        navigation.navigate('PartnerDetails', { Partner_Details_Id: data.Partner_Details_Id })
    };

    const handleBrandClick = async (data) => {
        navigation.navigate('BrandDetails', { Brand_Id: data.Brand_Id })
    }

    const handleProductClick = async (item) => {
        navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id })
    };

    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 10,
            padding: 15,
            shadowColor: esiColor.brandShadowColor,
            // shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 5,
            shadowRadius: 7,
            // elevation: 10,
            marginBottom: 10,
            marginTop: 35
        },

    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <ScrollView>

                <View style={styles.content}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.LCHFontColor }}>Products</Text>
                        <TouchableOpacity onPress={(e) => navigation.navigate('Main', { screen: 'All', params: { page: 'products', filter: 'all' } })} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>View All</Text>
                        </TouchableOpacity>
                    </View>
                    <ScrollView contentContainerStyle={{ paddingVertical: 10 }} horizontal showsHorizontalScrollIndicator={false}>
                        <View style={{ flexDirection: 'row' }}>
                            <ProductsF productfilter={allproducts} navigate={navigation.navigate} handleProductClick={handleProductClick} />
                        </View>
                    </ScrollView>
                </View>

                <View style={styles.content}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.LCHFontColor }}>Coupons</Text>
                        <TouchableOpacity onPress={(e) => navigation.navigate('Main', { screen: 'All', params: { page: 'coupons', filter: 'all' } })} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 13, color: esiColor.itemColor }}>View All</Text>
                        </TouchableOpacity>
                    </View>
                    <ScrollView
                        contentContainerStyle={{ paddingVertical: 10 }}
                        horizontal
                        showsHorizontalScrollIndicator={false}>
                        <View style={{ flexDirection: 'row' }}>
                            {
                                coupon.length == 0 ? (
                                    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                                        <Text style={{ color: esiColor.NoDataFound }}>No Coupons found!</Text>
                                    </View>
                                ) : (
                                    coupon?.map((data, index) => (

                                        <Fragment key={index}>
                                            <ESICouponsCard data={data} toggleModal={toggleModal} />
                                        </Fragment>
                                    ))
                                )}
                        </View>
                    </ScrollView>
                </View>

                <View style={styles.content}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.LCHFontColor }}>Deals</Text>
                        <TouchableOpacity onPress={(e) => navigation.navigate('Main', { screen: 'All', params: { page: 'deals', filter: 'all' } })} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>View All</Text>
                        </TouchableOpacity>
                    </View>
                    <ScrollView
                        contentContainerStyle={{ paddingVertical: 10 }}
                        horizontal
                        showsHorizontalScrollIndicator={false}>
                        <View style={{ flexDirection: 'row' }}>
                            <DealsF dealfilter={deal} toggleDModal={toggleDModal} navigation={navigation} />
                        </View>
                    </ScrollView>
                </View>

                <View style={styles.content}>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.LCHFontColor }}>Partners</Text>
                        <TouchableOpacity onPress={() => navigation.navigate('Partners')} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>View All</Text>
                        </TouchableOpacity>
                    </View>
                    <ScrollView
                        contentContainerStyle={{ paddingVertical: 10 }}
                        horizontal
                        showsHorizontalScrollIndicator={false}>
                        <View style={{ flexDirection: 'row' }}>

                            {
                                partners.length == 0 ? (
                                    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 10 }}>
                                        <Text style={{ color: esiColor.NoDataFound }}>No Partners found!</Text>
                                    </View>
                                ) : (
                                    partners.map((data, index) => (
                                        <View key={data, index}>
                                            <View style={{ margin: 10 }}>
                                                <TouchableOpacity onPress={() => handlePartnerClick(data)} style={{ paddingVertical: 5, paddingHorizontal: 10, justifyContent: 'center', alignItems: 'center', width: 200, height: 100, borderWidth: 0.5, shadowColor: "#CACFD2", borderRadius: 5 }}>
                                                    <Image source={{ cache: "force-cache", uri: data?.Logo_Path + `?w=200&h=100` }} style={{ height: 100, flex: 1, resizeMode: 'contain', width: 200 }} />
                                                </TouchableOpacity>
                                                <View style={{ marginTop: 5, alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                                                    <Text style={{ fontSize: 11, textAlign: 'center', color: esiColor.itemColor }}>{data?.Name}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    ))
                                )
                            }
                        </View>
                    </ScrollView>
                </View>

                <View style={styles.content}>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.LCHFontColor }}>Brands</Text>
                        <TouchableOpacity onPress={() => navigation.navigate('Brands')} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>View All</Text>
                        </TouchableOpacity>
                    </View>
                    <ScrollView
                        contentContainerStyle={{ paddingVertical: 10 }}
                        horizontal
                        showsHorizontalScrollIndicator={false}>
                        <View style={{ flexDirection: 'row' }}>
                            {
                                brands.length == 0 ? (
                                    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 10 }}>
                                        <Text style={{ color: esiColor.NoDataFound }}>No Brands found!</Text>
                                    </View>
                                ) : (
                                    brands.map((data, index) => (
                                        <View key={data, index}>
                                            <View style={{ margin: 10 }}>
                                                <TouchableOpacity onPress={() => handleBrandClick(data)} style={{ paddingVertical: 5, paddingHorizontal: 10, justifyContent: 'center', alignItems: 'center', width: 200, height: 100, borderWidth: 0.5, shadowColor: "#CACFD2", borderRadius: 5 }}>
                                                    <Image source={{ cache: "force-cache", uri: data?.Brand_Image + `?w=200&h=100` }} style={{ height: 100, flex: 1, resizeMode: 'contain', width: 200 }} />
                                                </TouchableOpacity>
                                                <View style={{ marginTop: 5, alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                                                    <Text style={{ fontSize: 11, textAlign: 'center', color: esiColor.itemColor }}>{data?.Brand_Name}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    ))
                                )
                            }
                        </View>
                    </ScrollView>
                </View>

            </ScrollView>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: 'white' }}>
                    <SignIN navigation={navigation} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={open}>
                <View style={{ flex: 1, maxWidth: 600, minWidth: dimensions.width <= 400 ? 200 : dimensions.width <= 400 ? 200 : 350, minHeight: 400, maxHeight: 500, borderRadius: 10, backgroundColor: 'white' }}>
                    <ESICopyCouponModel navigation={navigation} openModal={openModal} data={couponData} closeModal={closeModal} />
                </View >
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: 'white' }}>
                    <SignIN navigation={navigation} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={Dopen}>
                <View style={{ flex: 1, maxWidth: 600, minWidth: dimensions.width <= 400 ? 200 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 400, borderRadius: 10, backgroundColor: 'white' }}>
                    <ESIDealModel navigation={navigation} openModal={openDModal} data={dealData} closeModal={closeDModal} />
                </View >
            </Modal>

        </Surface>

    )

}

