import React, { useState } from 'react';
import EsiSearchDropdown from '../searchable-dropdown';
import axios from 'axios';
import config from '../../../state/actions/config';
import { StyleSheet } from 'react-native';
const headers = config.headersCommon;
const EsiState = (props: any) => {
    // props variable consist all react text field properties along with valueUpdate function.
    // CountryCode value isrequired.
    const { CountryCode, ...rest } = props
    const [state, setState] = useState([]);
    React.useEffect(() => {
        if (CountryCode) {
            let formData={
                Country:CountryCode,
                Filter_Type: "STATE",
                Records_Filter: "All",
              }
            getStates(formData)
        }
    }, [CountryCode])

    const getStates = (statesData: any) => {
        axios.post(`${config.url}/Country_State_City/Get`, statesData,
            {
                headers: headers
            }
        )
            .then(response => {
                setState(response?.data?.State);
            })
            .catch(error => {
            })
    }
    let states = []
    if (state) {
        if (state[0]) {
            for (let i = 0; i < state.length; i++) {
                states.push({
                    id: [i],
                    label: state[i].NAME,
                    value: state[i].STATE_CODE
                })
            }
        }
    }

    return (
        <EsiSearchDropdown
            {...props}
            // data param here is used to assign the data from the function values that will be provided.
            data={state}
            style={styles.dropdown}
            // Providing styles for placeholder
            placeholderStyle={styles.placeholderStyle}
            // Providing styles for selectedText
            selectedTextStyle={styles.selectedTextStyle}
            // Providing styles for inputSearch
            inputSearchStyle={styles.inputSearchStyle}
            // Providing styles for icon
            iconStyle={styles.iconStyle}
            // valueField is used to get the value
            valueField={props.valueField}
            // labelField is used to get the label
            labelField={props.labelField}
            // search is used to get the value from the dropdown
            search={props.search}
            searchPlaceholder={props.searchPlaceholder}
            maxHeight={180}
            dropdownPosition="auto"
        />

    );
};

export default EsiState;


const styles = StyleSheet.create({
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
})
   