import React, { useState, useEffect } from "react";
import { Avatar, Button, Card, Colors, IconButton, Surface, Text, TouchableRipple } from "react-native-paper";
import { Image, TouchableOpacity, View, Dimensions, ActivityIndicator, Linking, Platform, StyleSheet, FlatList } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { apiActions } from "../../state/actions";
import isEmpty from "../../state/validations/is-empty";
import SignIN from "../../screens/auth/signin";
import Modal from "react-native-modal";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { adduservisitAction, getProductSizeActionWishList, productsizereset } from "../../state/actions/productsActions";
import * as Network from 'expo-network';
import { addCart, addItemMultipleCart } from "../../state/actions/cartActionsNew";
import { getSingleCartItemCheck } from "../../state/actions/node-actions/home-actions";
import QuentityField from "./QuentityField";
import EsiRating from "./Rating/rating";


export default function ProductHCard(props: any) {
    // handle click event is used to go to next page with IDs
    // data is a Product object as we use this object to display the info front-end
    const { data, handleClick, navigation, ProductId, PartnerId } = props;
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(false);
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const [checkWish, setCheckWish] = useState({});
    const [loading, setLoading] = useState(false);
    const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);
    const esiColor = useSelector(state => state.theme);
    const [openredirect, setRedirectionOpen] = React.useState(false);
    const onOpenModal = (data) => {
        let Url = data.Web_Redirection_Url;
        setTimeout(function () {
            Linking.openURL(Url);
            setRedirectionOpen(false);
        }, 2000);
    };
    const [imageURL, setImageURL] = useState("");
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [cartLoading, setCartLoading] = useState(false);
    const [productData, setProductData] = useState();

    const handleClickCart = async (data) => {
        if (EmailID) {
            if (data.Partner_Type === "Online") {
                setRedirectionOpen(true)
                onOpenModal(data);
                setImageURL(data.Logo_Path)
            }
            else {
                dispatch(getProductSizeActionWishList(data.Product_Id, data.Partner_Details_Id, data, sizeAddtoCart));
                setModalIsOpen(true);
                setCartLoading(true);
                setProductData(data);
            }
            let iPAddress = await Network.getIpAddressAsync();
            let formData = {
                User_Email_Id: EmailID,
                Ip_Address: iPAddress,
                Offer_Type: "Product",
                Offer_Id: data.Product_Id,
                Store_Type: data.Partner_Type,
                Store_Id: data.Partner_Details_Id,
                Status: "Active"
            }
            dispatch(adduservisitAction(formData));
        } else {
            setModalVisible(!isModalVisible);
        }
    };

    const handleSelect = (id) => {
        if (id === selected) {
            setSize("")
        }
        setSelected(id !== selected ? id : "")
    };
    const [cartSize, setCartSize] = useState([]);
    const [cartErrorMessage, setCartErrorMessage] = useState("");

    const sizeAddtoCart = (status, response, partnerData, message) => {
        setCartLoading(false);
        if (status && !isEmpty(response)) {
            if (response.length > 1) {
                setCartSize(response);
            } else {
                let payload = {
                    "Partner_Details_Id": partnerData.Partner_Details_Id,
                    "Partner_Product_Id": partnerData.Partner_Product_Id,
                    "Quantity": "1",
                    "Size": response[0]?.Product_Size,
                    "User_Email_Id": EmailID
                }

                dispatch(addCart(payload, EmailID, checkCartUpdateResponse));
                dispatch(productsizereset());
            }
        } else {
            setCartErrorMessage(message);
        }
    }
    const [size, setSize] = useState({ size: "", quantity: "" });

    const onCloseModal = () => {
        setSize({ size: "", quantity: "" })
        setModalIsOpen(false);
        setSelected(false);
    };

    useEffect(() => {
        getWishList();
        checkCart();
    }, [EmailID, ProductId])


    const [cart, setCart] = useState({ User_Cart_Id: 0, Quantity: 0 });

    const checkCartResponse = (status, data) => {
        if (status) {
            setCart(data);
        }
    }

    const checkCartUpdateResponse = (data) => {
        checkCart();
    }

    const checkCart = () => {
        if (EmailID && data?.Partner_Product_Id) {
            let filter = {
                "Partner_Product_ID": data?.Partner_Product_Id,
                "User_Email_Id": EmailID,
            }
            dispatch(getSingleCartItemCheck(filter, {}, checkCartResponse))
        }
    }
    const getWishList = () => {
        setCheckWish({});
        if (EmailID && ProductId && PartnerId && !loading) {
            setLoading(true);
            let filter = {
                "Records_Filter": "FILTER",
                "User_Email_Id": EmailID,
                "Product_Id": ProductId,
                "Partner_Details_Id": PartnerId,
                "Route_Filter_Key": '2'
            }
            apiActions.get_wish_list(filter)
                .then(async res => {
                    setLoading(false);
                    let wish_data = res.Wish[0]?.Wish_List;
                    if (!isEmpty(wish_data)) {
                        if (wish_data.length > 0) {
                            setCheckWish(wish_data[0])
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }
    const onLikeClicked = (is_like) => {
        setLoading(true);
        if (!is_like) {
            if (isAuthenticated) {
                var data = {
                    "User_Email_Id": EmailID,
                    "Product_Id": ProductId,
                    "Partner_Details_Id": PartnerId
                }
                apiActions.create_wish_list(data)

                    .then(async res => {
                        setLoading(false);
                        setCheckWish({});
                        getWishList();
                    })
                    .catch(err => {
                        setLoading(false);
                    })
            } else {
                setModalVisible(!isModalVisible);
                setLoading(false);
            }

        } else {

            var data = {
                Wish_Id: checkWish?.Wish_Id
            }

            apiActions.delete_wish_list(data)
                .then(async res => {

                    setLoading(false);
                    getWishList();
                })
                .catch(err => {
                    setLoading(false);
                })

        }
    }
    const toast = useToast();
    const [isModalVisible, setModalVisible] = React.useState(false);

    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
        if (status) {
            onLikeClicked(false);
        }
    };

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const halfdimensions = dimensions.width >= 800 ? (dimensions.width / 2) : dimensions.width;
    const [isNotProductAvailable, setIsNotProductAvailable] = React.useState(false);

    const cartData = () => {
        setModalIsOpen(!modalIsOpen);
        setSelected(false);
        let payload = {
            "Partner_Details_Id": productData.Partner_Details_Id,
            "Partner_Product_Id": productData.Partner_Product_Id,
            "Quantity": "1",
            "Size": size.size,
            "User_Email_Id": EmailID
        }

        dispatch(addCart(payload, EmailID, checkCartUpdateResponse));

        dispatch(productsizereset());
        setSize({ size: "", quantity: "" });
        setProductData();
    }

    return (
        <Card style={{ margin: 5, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), width: (halfdimensions - 20), borderWidth: 0.5, backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5 }}>
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 4, padding: 5, flexDirection: "column" }}>
                    <View style={{ flexDirection: "row" }} >
                        <View style={{ paddingHorizontal: 5 }}>
                            {data?.Is_Veg &&
                                <MaterialCommunityIcons name={"square-circle"} size={20} color={data?.Is_Veg == 1 ? "green" : "red"} />
                            }
                        </View>
                        <View style={{ flexDirection: "row", minWidth: "80%" }}>
                            <Image source={(data?.Esi_Cashback_Type != 'R') ? require("../../assets/images/img/cb.png") : require("../../assets/images/img/ep.png")} style={{ height: 30, width: "40%", resizeMode: 'contain' }} />
                            <Text style={{ color: esiColor.amountColor, fontWeight: "bold", marginTop: 8 }} >  {data?.Cashback_Label?.toLowerCase()} {data?.Esi_Cashback}{(data?.Esi_Cashback_Type == 'P' || data?.Esi_Cashback_Type == 'percentage' || data?.Esi_Cashback_Type == 'Percentage') ? '%' : (data?.Esi_Cashback_Type != 'R') ? '/-' : "*"}</Text>

                        </View>
                        <View style={{ position: "absolute", right: 0 }}>
                            <TouchableWithoutFeedback onPress={() => {
                                if (!loading)
                                    onLikeClicked(Boolean(checkWish?.Wish_Id))
                            }}>
                                {loading ? <ActivityIndicator color='#27b6cc' size="small" /> :
                                    <FontAwesome name={Boolean(checkWish?.Wish_Id) ? 'heart' : 'heart-o'} color='red' size={20}></FontAwesome>}
                            </TouchableWithoutFeedback>
                        </View>
                    </View>
                    <Text style={{ fontWeight: "bold", color: esiColor.brandFontColor }}>{data?.Product_Name.slice(0, 50)}</Text>
                    <View style={{ flexDirection: "row" }}>
                        {/* <Text style={{ paddingVertical: 3, fontSize: 14, color: "#51dff5", textDecorationLine: 'line-through' }}>₹{data?.Original_Price.toFixed(2)}</Text> */}
                        <Text style={{ paddingVertical: 3, marginLeft: 5, color: "#27b6cc", fontSize: 14, fontWeight: 'bold' }}>₹{parseFloat(data?.Partner_Selling_Price).toFixed(2)}
                        </Text>
                    </View>
                    <EsiRating
                        readonly={true}
                        defaultRating={data?.Esi_Rating ? parseInt(data?.Esi_Rating) : 0}
                        type='heart'
                        tintColor={esiColor.TintColor}
                        ratingBackgroundColor='#c8c7c8'
                        startingValue={data?.Esi_Rating}
                        ratingCount={5}
                        imageSize={14}
                        style={{ alignSelf: "flex-start" }}
                        minValue={1}
                        // onFinishRating={(rating) => ratingCompleted(rating)}
                        name="rating"
                    />
                    <Text style={{ color: esiColor.itemColor }}>
                        {data?.Product_Short_Description.slice(0, 50)}  <TouchableOpacity onPress={() => { handleClick(data) }} ><Text style={{ color: esiColor.readMoreColor }} >... read more</Text></TouchableOpacity>
                    </Text>
                </View>
                <View style={{ flex: 2, flexDirection: 'column', justifyContent: "center", width: '100%', padding: 5 }}>
                    <View style={{
                        borderRadius: 8,
                        shadowOffset: { width: 0, height: 0 },
                        shadowOpacity: 0.2,
                        shadowColor: "#171717",
                        shadowRadius: 8
                    }}>
                        <Image source={{ cache: "force-cache", uri: data?.Product_Image + `?w=200&h=70` }} style={{
                            height: 70, width: "100%", resizeMode: 'stretch',
                            borderRadius: 8
                        }}>
                        </Image>
                    </View>

                    {cart.User_Cart_Id ?
                        <View style={{ flexDirection: 'row', justifyContent: "center", alignSelf: "center", maxWidth: "70%", marginTop: -12, backgroundColor: "#fff", borderRadius: 8 }}>
                            <TouchableOpacity disabled={cart.Quantity <= 1 ? true : false} onPress={(e) => dispatch(addItemMultipleCart({
                                User_Cart_Id: cart.User_Cart_Id,
                                partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                            }, Number(cart.Quantity) - 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: '#27b6cc', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                                <MaterialIcons name="remove" size={22} color="#cccccc" />
                            </TouchableOpacity>
                            {
                                <QuentityField count={cart.Quantity} handleOnChangeCount={(count) => {
                                    dispatch(addItemMultipleCart({
                                        User_Cart_Id: cart.User_Cart_Id,
                                        partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                                    }, count, checkCartUpdateResponse))
                                }} />
                            }
                            <TouchableOpacity onPress={(e) => dispatch(addItemMultipleCart({
                                User_Cart_Id: cart.User_Cart_Id,
                                partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                            }, Number(cart.Quantity) + 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: '#27b6cc', borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
                                <MaterialIcons name="add" size={22} color="#cccccc" />
                            </TouchableOpacity>
                        </View> :
                        <TouchableOpacity onPress={() => {
                            if (data.Is_Open !== 1 || data.Is_Product_Available !== 1) {
                                setIsNotProductAvailable(!isNotProductAvailable);
                            } else {
                                handleClickCart(data);
                            }
                        }} style={{ alignSelf: "center", maxWidth: "50%", minWidth: "40%", marginTop: -16 }} >
                            <View style={{ flexDirection: "row", justifyContent: "center", backgroundColor: '#27b6cc', borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10,marginHorizontal:-10 }}>
                                <FontAwesome name={'cart-plus'} color='#ffffff' size={20}></FontAwesome>
                            </View>
                        </TouchableOpacity>
                    }
                </View>
            </View>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isNotProductAvailable}>
                <View style={{ flex: 1, maxWidth: 300, minWidth: 200, minHeight: 250, maxHeight: 300, borderRadius: 10, backgroundColor: Colors.white }}>

                    <View style={{ marginHorizontal: 10, justifyContent: "center", flexDirection: "column", height: "100%" }} >
                        <Text style={{ textAlign: "center", color: "#27b6cc", fontSize: 24 }} >
                            Oops..
                        </Text>
                        <Text style={{ marginVertical: 10 }} >
                            Currently this partner is not accepting orders, Please click okay to check this product's availability in other stores.
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-around" }} >
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    color="#27b6cc"
                                    mode="outlined"
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                handleClick(data);
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    color={esiColor.globalButtonColor}
                                    mode="contained"
                                    labelStyle={{ color: "#fff" }}
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                        handleClick(data);
                                    }}
                                >
                                    Okay
                                </Button>
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: Colors.white }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>

            {/* Partner Redirection Modal */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openredirect}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 300, maxHeight: 150, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <View style={{ alignItems: 'flex-end', marginTop: '-6%' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ color: "#27b6cc", textAlign: 'center', marginBottom: 10 }}>Prices may vary for this product. Redirecting to partner site. Please wait....</Text>
                    </View>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />
                            <Button color="#27b6cc" icon="arrow-right-bold" style={{ size: 15, marginTop: 10 }} />
                            {imageURL ? <Image source={{ cache: "force-cache", uri: imageURL + `?w=60&h=60` }} style={{ height: 60, width: 60, resizeMode: 'contain' }} /> : <></>}
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={modalIsOpen}>
                <View style={{ flex: 1, maxWidth: (dimensions.width * 0.9) > 500 ? 500 : (dimensions.width * 0.9), minWidth: 100, minHeight: 100, maxHeight: 200, height: 200, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>

                    <View style={{
                        alignItems: 'flex-end', marginTop: -30
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { onCloseModal(); setCartErrorMessage(""); }} >
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    {
                        !isEmpty(cartSize) ?
                            <View>
                                <Text style={{ textAlign: "center", marginTop: -10, marginBottom: 10, fontSize: 16, fontWeight: "bold", color: esiColor.DescColor }}>
                                    Select Size
                                </Text>

                                <View style={{ textAlign: "center", justifyContent: "center", flexDirection: 'row', width: (dimensions.width * 0.9) > 500 ? 400 : (dimensions.width * 0.7) }}>
                                    <FlatList
                                        // horizontal={true}
                                        showsHorizontalScrollIndicator={true}
                                        numColumns={cartSize.length}
                                        data={cartSize}
                                        contentContainerStyle={{ justifyContent: "space-around" }}
                                        renderItem={({ item, index, separators }) => (
                                            <View key={index}>
                                                <TouchableOpacity
                                                    style={selected === item.Product_Size ? styles.productDetailsSize : styles.productDetailsSizeSelect}
                                                    key={index}
                                                    onPress={() => { setSize({ size: item.Product_Size, quantity: item.Product_Available_Stock }); handleSelect(item.Product_Size) }}
                                                >
                                                    <View ><Text style={{ textAlign: "center", justifyContent: "center", marginTop: 16,color:"black" }} >{item.Product_Size}</Text>
                                                    </View>

                                                </TouchableOpacity>
                                                {
                                                    item.Product_Available_Stock ?
                                                        <Text >
                                                            {parseInt(item.Product_Available_Stock) < 11 ?
                                                                <Text style={styles.ribbon}>{item.Product_Available_Stock} Left</Text>
                                                                : null}
                                                        </Text> : null
                                                }
                                            </View>
                                        )}
                                    />
                                </View>

                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 10 }} >
                                    <Button
                                        color={size.size && size.quantity ? esiColor.actionColor : "gray"}
                                        type="submit"
                                        mode="contained"
                                        disabled={size.size && size.quantity ? false : true}
                                        style={{ color: size.size && size.quantity ? "#000000" : "#fff", width: "50%" }}
                                        onPress={cartData}
                                    >
                                        Proceed
                                    </Button>
                                </View>
                            </View>
                            :
                            cartLoading ? <ActivityIndicator color='#27b6cc' /> :
                                <View style={{ flexDirection: 'row', justifyContent: 'center', textAlign: "center" }}>
                                    {cartErrorMessage ? <Text style={{ color: "red" }} >{cartErrorMessage}</Text> :
                                        <View style={{ flexDirection: "column", alignItems: "center" }} >
                                            <Text style={{ fontSize: 16, fontWeight: "bold" }} >Item added to the cart.</Text>
                                            <IconButton icon={"cart-check"} color={"#27b6cc"} size={60} />
                                            <TouchableOpacity onPress={() => {
                                                onCloseModal(); setCartErrorMessage("");
                                            }}>
                                                <Button
                                                    mode="contained"
                                                    color="#27b6cc"
                                                    labelStyle={{ color: "#fff" }}
                                                    type="submit"
                                                    // style={{ : "#fff" }}
                                                    onPress={() => {
                                                        onCloseModal(); setCartErrorMessage("");
                                                    }}
                                                >
                                                    Done
                                                </Button>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                    }
                </View>
            </Modal >
        </Card>

    );
}
const styles = StyleSheet.create({

    productDetailsSize: {
        width: 50,
        height: 50,
        backgroundColor: 'rgb(42, 175, 82)',
        borderRadius: 50,
        borderColor: '#c4c4da',
        borderWidth: 1,
        color: 'white',
        flexDirection: "column",
        marginHorizontal: 4

    },

    productDetailsSizeSelect: {
        width: 50,
        height: 50,
        backgroundColor: 'white',
        borderRadius: 50,
        borderColor: '#c4c4da',
        borderWidth: 1,
        flexDirection: "column",
        marginHorizontal: 4

    },

    ribbon: {
        backgroundColor: 'rgb(207, 152, 49)',
        color: '#dddeda',
        fontSize: 15,
        textAlign: "center"
    }


});