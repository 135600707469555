import React, { useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, Image, Platform, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import { Avatar, Button, IconButton, Searchbar, Text, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getFarmersProdActions } from "../../state/actions/farmerActions";
import { FlatList } from "react-native";
import ProductFarmerCard from "../../components/custom/product-farmer-card";
import Pagination from "../../components/custom/pagination/pagination";
import isEmpty from "../../state/validations/is-empty"; 

export default function FarmerProducts(props) {
    const { navigation, partnerId, data, ProductId } = props;
    const dispatch = useDispatch();
    const [wishdata, setWishData] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);
    const [pageOld, setPageOld] = React.useState(false); 

    const callBackGetData = async (status, data, pagination) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            setPageOld(true);
            if (pagination) {
                setPagination(pagination);
            } else {
                setPagination({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
            }
        }
    }


    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
    const [search, setSearch] = React.useState(""); 

    useEffect(() => {
        if (pageOld) {
            setPageOld(!pageOld)
        } else {
            productsCall();
        }
    }, [partnerId, pagination, search]);
 

    const productsCall = () => {
        if (!loading && partnerId) {
            setLoading(true);
            dispatch(getFarmersProdActions({ "search_by_filter": "FILTER", "condition": true, "Partner_Detail_Id": partnerId, "search": search }, pagination, callBackGetData));
        }
    }

    const esiColor = useSelector(state => state.theme); 

    const handleClick = async (item) => {
        navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata })
    };

    const styles = StyleSheet.create({
        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            // shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 5,
            shadowRadius: 10,
            // elevation: 10
        } 

    });

 
    return (

        <View >
            <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                <Searchbar style={{
                    marginHorizontal: 10, borderColor: esiColor.SBorderColor,
                    backgroundColor: esiColor.BackgroundColor, flex: 2, minWidth: "95%", maxWidth: "95%",
                    borderWidth: 0.5, maxHeight: 30, marginTop: 10, alignSelf: "center"
                }}
                    inputStyle={{ fontSize: 12, marginLeft: -20 }}
                    value={search}
                    onChangeText={(value) => {
                        setSearch(value);
                    }}
                    iconColor={esiColor.SIconColor}
                    placeholderTextColor={esiColor.SPHTextColor}
                    theme={{ colors: { text: esiColor.SBTextColor } }}
                    multiline={false}
                    placeholder="Search products"
                />

            </View>
            <ScrollView
                contentContainerStyle={{ paddingVertical: 10 }}
                horizontal
                showsHorizontalScrollIndicator={false}>
                <View style={{ flexDirection: 'row', }}>

                    {isEmpty(allproducts) ? (
                        // {allproducts.length <= 0 ? (
                        <View style={{ width: dimensions.width - 50, flex: 1, justifyContent: "center" }}>
                            <Text style={{ alignSelf: "center", color: esiColor.NoDataFound }} >No Products found!</Text>
                        </View>

                    ) : (
                        <FlatList
                            // horizontal={true}
                            columnWrapperStyle={{ justifyContent: "space-around" }}
                            numColumns={(dimensions.width / 250).toFixed() == 1 ? 2 : (dimensions.width / 250).toFixed()}
                            data={allproducts}
                            renderItem={({ item, index, separators }) => (
                                <ProductFarmerCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />

                            )}

                        />

                    )}
                </View>

                <View style={{ marginBottom: 5 }}>
                    <Pagination pagination={pagination} setPagination={setPagination} />
                </View>
            </ScrollView>
        </View>
    )
 
}